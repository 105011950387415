import VectorProps from "./VectorProps";

export default function Profile(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Q85QW9IPew7lWUUb-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99902 1.56863C8.04978 1.56863 6.46961 3.1488 6.46961 5.09804C6.46961 7.04728 8.04978 8.62745 9.99902 8.62745C11.9483 8.62745 13.5284 7.04728 13.5284 5.09804C13.5284 3.1488 11.9483 1.56863 9.99902 1.56863ZM4.90098 5.09804C4.90098 2.28247 7.18345 0 9.99902 0C12.8146 0 15.0971 2.28247 15.0971 5.09804C15.0971 7.91361 12.8146 10.1961 9.99902 10.1961C7.18345 10.1961 4.90098 7.91361 4.90098 5.09804Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47059 11.8627C3.76552 11.8627 1.56863 14.0596 1.56863 16.7647V17.9412H18.4314V16.7647C18.4314 14.0596 16.2345 11.8627 13.5294 11.8627H6.47059ZM0 16.7647C0 13.1933 2.89919 10.2941 6.47059 10.2941H13.5294C17.1008 10.2941 20 13.1933 20 16.7647V18.7255C20 19.1587 19.6489 19.5098 19.2157 19.5098H0.784314C0.351149 19.5098 0 19.1587 0 18.7255V16.7647Z"
      />
    </svg>
  );
}
