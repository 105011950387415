import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SignUpRequestInput } from "../../../services/api/useSignUp";
import { useValidateEmailFormat } from "../../../util/formatValidation";
import StatusDisplay from "../../StatusDisplay";
import Form from "../../utils/Form";
import * as LabelledTextField from "../../utils/LabelledTextField";
import {
  PasswordStrengthResult,
  PasswordStrengthSuggestions,
  useCheckPasswordStrengthPromise,
} from "../../utils/passwordStrength";
import PasswordStrengthBar from "../../utils/PasswordStrengthBar";
import usePromise from "../../utils/usePromise";
import "./SignUpDetailsForm.css";

interface FormState {
  firstName: LabelledTextField.Value;
  lastName: LabelledTextField.Value;
  email: LabelledTextField.Value;
  emailConfirm: LabelledTextField.Value;
  organizationName: LabelledTextField.Value;
  password: LabelledTextField.Value;
}

function emptyState(): FormState {
  return {
    firstName: LabelledTextField.validValue(""),
    lastName: LabelledTextField.validValue(""),
    email: LabelledTextField.validValue(""),
    emailConfirm: LabelledTextField.validValue(""),
    organizationName: LabelledTextField.validValue(""),
    password: LabelledTextField.validValue(""),
  };
}

export type SignUpDetails = Pick<
  SignUpRequestInput,
  "email" | "firstName" | "lastName" | "organizationName" | "password"
>;

export interface SignUpDetailsFormProps {
  onComplete: (details: SignUpDetails) => void;
}

export default function SignUpDetailsForm(props: SignUpDetailsFormProps) {
  const { onComplete } = props;

  const intl = useIntl();
  const validateEmailFormat = useValidateEmailFormat();
  const [checkPasswordStrengthStatus] = usePromise(
    useCheckPasswordStrengthPromise
  );

  const [formState, setFormState] = useState<FormState>(emptyState());
  const updateFormState = (update: Partial<FormState>) =>
    setFormState((state) => ({ ...state, ...update }));

  const entryIsValid = (entry: LabelledTextField.Value) => {
    return entry.errorMessage === undefined;
  };

  const validateFormState = (
    checkPasswordStrength: Awaited<
      ReturnType<typeof useCheckPasswordStrengthPromise>
    >
  ): { validatedFormState: FormState; isValid: boolean } => {
    let {
      firstName,
      lastName,
      email,
      emailConfirm,
      organizationName,
      password,
    } = formState;

    if (firstName.text === "") {
      firstName = LabelledTextField.invalidValue(
        firstName.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:noFirstNameErrorMessage`,
          defaultMessage: "You need to enter a first name.",
        })
      );
    } else {
      firstName = LabelledTextField.validValue(firstName.text);
    }

    if (lastName.text === "") {
      lastName = LabelledTextField.invalidValue(
        lastName.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:noLastNameErrorMessage`,
          defaultMessage: "You need to enter a last name.",
        })
      );
    } else {
      lastName = LabelledTextField.validValue(lastName.text);
    }

    const validateEmailFormatResult = validateEmailFormat(email.text);
    if (email.text === "") {
      email = LabelledTextField.invalidValue(
        email.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:noEmailErrorMessage`,
          defaultMessage: "You need to enter an email address.",
        })
      );
    } else if (!validateEmailFormatResult.success) {
      email = LabelledTextField.invalidValue(
        email.text,
        validateEmailFormatResult.errorMessage
      );
    } else {
      email = LabelledTextField.validValue(email.text);
    }

    if (emailConfirm.text === "") {
      emailConfirm = LabelledTextField.invalidValue(
        emailConfirm.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:noEmailConfirmErrorMessage`,
          defaultMessage: "You need to confirm your email address.",
        })
      );
    } else if (emailConfirm.text !== email.text) {
      emailConfirm = LabelledTextField.invalidValue(
        emailConfirm.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:emailConfirmErrorMessage`,
          defaultMessage: "The email addresses do not match.",
        })
      );
    } else {
      emailConfirm = LabelledTextField.validValue(emailConfirm.text);
    }

    if (organizationName.text === "") {
      organizationName = LabelledTextField.invalidValue(
        organizationName.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:noOrganizationNameErrorMessage`,
          defaultMessage: "You need to enter an organisation name.",
        })
      );
    } else {
      organizationName = LabelledTextField.validValue(organizationName.text);
    }

    if (password.text === "") {
      password = LabelledTextField.invalidValue(
        password.text,
        intl.formatMessage({
          id: `components/account/sign-up/SignUpDetailsForm:noPasswordErrorMessage`,
          defaultMessage: "You need to enter a password.",
        })
      );
    } else {
      const passwordStrengthResult = checkPasswordStrength({
        password: password.text,
        userInputs: [
          firstName.text,
          lastName.text,
          email.text,
          organizationName.text,
        ],
      });
      if (!passwordStrengthResult.isValid) {
        password = LabelledTextField.invalidValue(
          password.text,
          passwordStrengthResult.warning ?? ""
        );
      } else {
        password = LabelledTextField.validValue(password.text);
      }
    }

    return {
      validatedFormState: {
        firstName,
        lastName,
        email,
        emailConfirm,
        organizationName,
        password,
      },
      isValid: [
        firstName,
        lastName,
        email,
        emailConfirm,
        organizationName,
        password,
      ].every(entryIsValid),
    };
  };

  async function handleSubmit(
    checkPasswordStrength: Awaited<
      ReturnType<typeof useCheckPasswordStrengthPromise>
    >
  ) {
    const { validatedFormState, isValid } = validateFormState(
      checkPasswordStrength
    );

    updateFormState(validatedFormState);

    if (isValid) {
      onComplete({
        email: validatedFormState.email.text,
        firstName: validatedFormState.firstName.text,
        lastName: validatedFormState.lastName.text,
        organizationName: validatedFormState.organizationName.text,
        password: validatedFormState.password.text,
      });
    }
  }

  return (
    <StatusDisplay status={checkPasswordStrengthStatus}>
      {(checkPasswordStrength) => (
        <Form onSubmit={() => handleSubmit(checkPasswordStrength)}>
          <LabelledTextField.LabelledTextField
            label={
              <FormattedMessage
                id="components/account/sign-up/SignUpDetailsForm:firstName"
                defaultMessage="First name"
              />
            }
            onChange={(firstName) => {
              updateFormState({ firstName });
            }}
            value={formState.firstName}
          />
          <LabelledTextField.LabelledTextField
            label={
              <FormattedMessage
                id="components/account/sign-up/SignUpDetailsForm:lastName"
                defaultMessage="Last name"
              />
            }
            onChange={(lastName) => updateFormState({ lastName })}
            value={formState.lastName}
          />
          <LabelledTextField.LabelledTextField
            label={
              <FormattedMessage
                id="components/account/sign-up/SignUpDetailsForm:email"
                defaultMessage="Email"
              />
            }
            onChange={(email) => updateFormState({ email })}
            value={formState.email}
          />
          <LabelledTextField.LabelledTextField
            label={
              <FormattedMessage
                id="components/account/sign-up/SignUpDetailsForm:confirmEmail"
                defaultMessage="Confirm email"
              />
            }
            onChange={(emailConfirm) => updateFormState({ emailConfirm })}
            value={formState.emailConfirm}
          />
          <LabelledTextField.LabelledTextField
            label={
              <FormattedMessage
                id="components/account/sign-up/SignUpDetailsForm:organisationName"
                defaultMessage="Organisation name"
              />
            }
            onChange={(organizationName) =>
              updateFormState({ organizationName })
            }
            value={formState.organizationName}
          />
          <PasswordField
            checkPasswordStrength={(password: string) =>
              checkPasswordStrength({
                password,
                userInputs: [
                  formState.firstName.text,
                  formState.lastName.text,
                  formState.email.text,
                  formState.emailConfirm.text,
                  formState.organizationName.text,
                ],
              })
            }
            onChange={(password) => updateFormState({ password })}
            password={formState.password}
          />
          <div className="mt-4">
            <Form.SubmitButton
              fullWidth
              submitLabel={intl.formatMessage({
                id: "components/account/sign-up/SignUpDetailsForm:nextButton/submitLabel",
                defaultMessage: "Next",
              })}
            />
          </div>
          <Form.ErrorAlert className="mt-4" />
        </Form>
      )}
    </StatusDisplay>
  );
}

interface PasswordFieldProps {
  checkPasswordStrength: (password: string) => PasswordStrengthResult;
  onChange: (value: LabelledTextField.Value) => void;
  password: LabelledTextField.Value;
}

function PasswordField(props: PasswordFieldProps) {
  const { checkPasswordStrength, onChange, password } = props;

  const [showPasswordStrengthIndicator, setShowPasswordStrengthIndicator] =
    useState<boolean>(false);

  const passwordStrength = checkPasswordStrength(password.text);

  return (
    <>
      <LabelledTextField.LabelledTextField
        label={
          <>
            <FormattedMessage
              id="components/account/sign-up/SignUpDetailsForm:password"
              defaultMessage="Password"
            />
            {showPasswordStrengthIndicator && (
              <PasswordStrengthBar
                barClassName="PasswordField__passwordStrengthIndicatorBar"
                strength={passwordStrength.strength}
              />
            )}
          </>
        }
        labelClassName="d-flex flex-row justify-content-between"
        onBlur={() =>
          password.text === "" && setShowPasswordStrengthIndicator(false)
        }
        onChange={onChange}
        onFocus={() => setShowPasswordStrengthIndicator(true)}
        type="password"
        value={password}
      />

      <PasswordStrengthSuggestions
        show={showPasswordStrengthIndicator}
        suggestions={passwordStrength.suggestions}
      />
    </>
  );
}
