import { useCallbackOne } from "use-memo-one";

import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";

export default function useExportUrl(scope3AssessmentId: string) {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportExportUrl(
      scope3AssessmentId,
      organizationId
    );
  }, [dataStore, scope3AssessmentId, organizationId]);

  return usePromise(load);
}
