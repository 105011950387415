import { ProgressBar } from "./ProgressBar";

const stories = {
  name: "ProgressBar",
  render: () => <Story />,
};

function Story() {
  return (
    <div className="m-2">
      <ProgressBar
        backgroundColour=""
        colour=""
        style={{ width: "65%" }}
        value={0.5}
      />
    </div>
  );
}

export default stories;
