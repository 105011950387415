import { useState } from "react";

import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import { LifeCycleStage } from "./LifeCycleImpacts";
import { getTestLifeCycleStageRoot } from "./LifeCycleImpactsTestData";
import LifeCycleImpactTable from "./LifeCycleImpactTable";

const stories = {
  name: "LifeCycleImpactTable",
  render: () => <Story />,
};

function Story() {
  const root = getTestLifeCycleStageRoot();
  const [activeStage, setActiveStage] = useState<LifeCycleStage>(root);

  const goBack = () => {
    if (activeStage.parent !== null) {
      setActiveStage(activeStage.parent);
    }
  };

  const handleActiveStageChange = (stage: LifeCycleStage) => {
    if (stage && stage.depth > activeStage.depth && stage.children.length > 0) {
      setActiveStage(stage);
    }
  };

  return (
    <div style={{ width: 500 }}>
      <LifeCycleImpactTable
        functionalUnit={FunctionalUnit.KG}
        activeStage={activeStage}
        goBack={goBack}
        impactCategory={ImpactCategory.GHG}
        onActiveStageChange={handleActiveStageChange}
        productWeightKgPerServing={2}
        root={root}
      />
    </div>
  );
}

export default stories;
