import gql from "graphql-tag";
import { useIntl } from "react-intl";

import { useTracking } from "../../tracking";
import useMutation from "../graphql/useMutation";
import DeleteModal from "../utils/DeleteModal";
import {
  DeleteRecipeModal_DeleteRecipe as DeleteRecipe,
  DeleteRecipeModal_DeleteRecipeVariables as DeleteRecipeVariables,
  DeleteRecipeModal_Recipe as Recipe,
} from "./DeleteRecipeModal.graphql";

interface DeleteRecipeModalProps {
  onDeleted: () => Promise<void>;
  onHide: () => void;
  recipe: Recipe;
}

export default function DeleteRecipeModal(props: DeleteRecipeModalProps) {
  const { onDeleted, onHide, recipe } = props;

  const intl = useIntl();
  const { trackRecipeDeleted } = useTracking();

  const [callDeleteRecipeMutation] = useMutation<
    DeleteRecipe,
    DeleteRecipeVariables
  >(deleteRecipeMutation);

  async function handleDelete() {
    trackRecipeDeleted({
      recipeId: recipe.id,
      recipeName: recipe.name,
    });

    await callDeleteRecipeMutation({ variables: { input: { id: recipe.id } } });
    await onDeleted();
  }

  return (
    <DeleteModal
      name={recipe.name}
      onDelete={handleDelete}
      onHide={onHide}
      show
      title={intl.formatMessage({
        id: "components/recipes/DeleteRecipeModal:title",
        defaultMessage: "Delete Product",
      })}
    />
  );
}

DeleteRecipeModal.fragments = {
  recipe: gql`
    fragment DeleteRecipeModal_Recipe on Recipe {
      id
      name
    }
  `,
};

const deleteRecipeMutation = gql`
  mutation DeleteRecipeModal_DeleteRecipe($input: DeleteRecipeInput!) {
    deleteRecipe(input: $input) {
      success
    }
  }
`;
