import { useHistory } from "react-router-dom";

import PackagingComponentCard from "./PackagingComponentCard";

interface EditPackagingComponentEditorProps {
  packagingComponentId: string;
  parentUrl: string;
}

export default function EditPackagingComponentEditor(
  props: EditPackagingComponentEditorProps
) {
  const { packagingComponentId, parentUrl } = props;

  const history = useHistory();

  const handleAfterSave = () => history.push(parentUrl);
  const handleDiscard = () => history.goBack();

  return (
    <PackagingComponentCard
      onAfterSave={handleAfterSave}
      onDiscard={handleDiscard}
      packagingComponentId={packagingComponentId}
    />
  );
}
