import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import useUserInfo from "../../data-store/useUserInfo";
import { usePages } from "../pages";
import ActionModal from "../utils/ActionModal";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import Select from "../utils/Select";
import { useOrganization, Organization } from "./OrganizationProvider";

interface SelectOrganizationModalProps {
  show: boolean;
  onHide: () => void;
}

export default function SelectOrganizationModal(
  props: SelectOrganizationModalProps
) {
  const { show, onHide } = props;
  const [{ organizationMemberships }] = useUserInfo();
  const [organization, setOrganizationId] = useOrganization();
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(organization);
  const intl = useIntl();
  const history = useHistory();
  const pages = usePages();

  const handleCancel = (): void => {
    onHide();
    setSelectedOrganization(organization);
  };

  const handleSubmit = (): void => {
    onHide();
    if (selectedOrganization.id !== organization.id) {
      setOrganizationId(selectedOrganization.id);
      history.push(pages.Dashboard().url);
    }
  };

  const handleChange = (organization: Organization | null): void => {
    if (organization !== null) {
      setSelectedOrganization(organization);
    }
  };

  return (
    <div>
      <ActionModal
        show={show}
        title={intl.formatMessage({
          id: "components/organizations/SelectOrganizationModal:title",
          defaultMessage: "Select Organisation",
        })}
      >
        <ActionModal.Body>
          <Select<Organization>
            options={organizationMemberships.map((om) => om.organization)}
            renderOption={(organization) => organization.name}
            onChange={(organization) => handleChange(organization)}
            optionKey={(organization) => organization.id}
            value={selectedOrganization}
            isClearable={false}
          />
        </ActionModal.Body>
        <ActionModal.Footer>
          <div className="d-flex w-100 justify-content-between">
            <div>
              <SecondaryButton onClick={handleCancel}>
                <FormattedMessage
                  id="components/organizations/SelectOrganizationModal:cancel"
                  defaultMessage="Cancel"
                />
              </SecondaryButton>
            </div>
            <div>
              <PrimaryButton onClick={handleSubmit}>
                <FormattedMessage
                  id="components/organizations/SelectOrganizationModal:select"
                  defaultMessage="Select"
                />
              </PrimaryButton>
            </div>
          </div>
        </ActionModal.Footer>
      </ActionModal>
    </div>
  );
}
