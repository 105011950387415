import gql from "graphql-tag";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useDataStore } from "../../data-store";
import { useTracking } from "../../tracking";
import useQuery from "../graphql/useQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import { recipesOwnedByOrganizationOrInUserGroupFilter } from "../utils/ownedByOrganizationOrInUserGroupFilter";
import Select from "../utils/Select";
import useId from "../utils/useId";
import {
  ComparisonRecipeSelect_Query as Query,
  ComparisonRecipeSelect_QueryVariables as QueryVariables,
  ComparisonRecipeSelect_Recipe as Recipe,
  ComparisonRecipeSelect_ComparisonRecipeQuery as ComparisonRecipeQuery,
  ComparisonRecipeSelect_ComparisonRecipeQueryVariables as ComparisonRecipeQueryVariables,
} from "./ComparisonRecipeSelect.graphql";
import ImpactComparison, { ComparisonImpactValue } from "./ImpactComparison";
import { useRecipeImpactIsPending } from "./useRecipeImpactIsPending";

interface ComparisonRecipeSelectProps {
  includePackaging: boolean;
  onChange: (value: ComparisonImpactValue) => void;
  selectedRecipe: Recipe;
}

export function ComparisonRecipeSelect(props: ComparisonRecipeSelectProps) {
  const { includePackaging, onChange, selectedRecipe } = props;

  const dataStore = useDataStore();
  const id = useId();
  const [organizationId] = useOrganizationId();
  const recipeImpactIsPending = useRecipeImpactIsPending;
  const { trackProductsCompared } = useTracking();

  const [comparisonRecipe, setComparisonRecipe] = useState<Recipe | null>(null);

  async function loadOptions(input: string) {
    if (!input) {
      return [];
    }
    const data = await dataStore.fetchGraphQL<Query, QueryVariables>({
      query,
      variables: {
        recipeFilter: {
          ...recipesOwnedByOrganizationOrInUserGroupFilter(organizationId),
          searchName: input,
        },
      },
    });
    return data.recipes.edges
      .map((edge) => edge.node)
      .filter((recipe) => recipe.id !== selectedRecipe.id);
  }

  const { status: comparisonRecipeQueryStatus } = useQuery<
    ComparisonRecipeQuery,
    ComparisonRecipeQueryVariables
  >(comparisonRecipeImpactQuery(), {
    recipeIds: comparisonRecipe ? [comparisonRecipe.id] : [],
  });

  const comparisonRecipeValue = useMemo((): ComparisonImpactValue => {
    if (comparisonRecipeQueryStatus.type === "loading") {
      return { type: "loading" };
    } else if (comparisonRecipeQueryStatus.type === "error") {
      throw new Error("There was an error loading the comparison recipe.");
    } else {
      const comparisonRecipe = comparisonRecipeQueryStatus.value.recipe;
      if (comparisonRecipe === null) {
        return { type: "none" };
      }
      const impact =
        (includePackaging
          ? comparisonRecipeQueryStatus.value.recipe?.impactWithPackaging
          : comparisonRecipeQueryStatus.value.recipe?.impactWithoutPackaging) ??
        null;
      if (impact !== null && recipeImpactIsPending(impact)) {
        return { type: "pending" };
      } else if (impact === null) {
        return { type: "unavailable" };
      } else {
        return { type: "success", value: impact };
      }
    }
  }, [comparisonRecipeQueryStatus, includePackaging, recipeImpactIsPending]);

  useEffect(() => {
    onChange(comparisonRecipeValue);
  }, [comparisonRecipeValue, onChange]);

  const handleChange = (newComparisonRecipe: Recipe | null) => {
    setComparisonRecipe(newComparisonRecipe);
    if (newComparisonRecipe !== null) {
      trackProductsCompared({
        productName: selectedRecipe.name,
        comparisonProductName: newComparisonRecipe.name,
      });
    }
  };

  return (
    <Select
      dropdownArrow={"none"}
      hideNoOptionsMessageIfSearchIsEmpty
      id={id}
      onChange={handleChange}
      options={loadOptions}
      optionKey={(recipe) => recipe.id.toString()}
      placeholder={
        <FormattedMessage
          id="components/recipes/ComparisonRecipeSelect.tsx"
          defaultMessage="Search for one of your own products"
        />
      }
      renderOption={(recipe) => recipe.name}
      value={comparisonRecipe}
    />
  );
}

export const fragments = {
  recipe: gql`
    fragment ComparisonRecipeSelect_Recipe on Recipe {
      id
      name
    }
  `,
};

export const query = gql`
  query ComparisonRecipeSelect_Query($recipeFilter: RecipeFilter!) {
    recipes(filter: $recipeFilter, first: 100) {
      edges {
        node {
          ...ComparisonRecipeSelect_Recipe
        }
      }
    }
  }

  ${fragments.recipe}
`;

const comparisonRecipeImpactQuery = () => gql`
  query ComparisonRecipeSelect_ComparisonRecipeQuery($recipeIds: [Int!]) {
    recipe(filter: { ids: $recipeIds }) {
      ...ComparisonRecipeSelect_ComparisonRecipe
    }
  }

  fragment ComparisonRecipeSelect_ComparisonRecipe on Recipe {
    impactWithPackaging: impact(
      excludePackaging: false
      fetchStaleImpacts: true
    ) {
      ...ComparisonRecipeSelect_RecipeImpact
    }
    impactWithoutPackaging: impact(
      excludePackaging: true
      fetchStaleImpacts: true
    ) {
      ...ComparisonRecipeSelect_RecipeImpact
    }
  }

  fragment ComparisonRecipeSelect_RecipeImpact on RecipeImpact {
    errors {
      kind
    }
    ...ImpactComparison_RecipeImpact
  }

  ${ImpactComparison.fragments.recipeImpact}
`;
