import classNames from "classnames";
import kebabCase from "lodash/kebabCase";
import { CSSProperties } from "react";

import assertNever from "../../util/assertNever";
import { PasswordStrength } from "./passwordStrength";
import "./PasswordStrengthBar.css";

interface PasswordStrengthBarProps {
  barClassName?: string;
  className?: string;
  strength: PasswordStrength;
  style?: CSSProperties;
}

export default function PasswordStrengthBar(props: PasswordStrengthBarProps) {
  const { barClassName, className, strength, style } = props;

  return (
    <div
      className={classNames("d-flex align-items-center flex-row", className)}
      style={style}
    >
      {strength !== PasswordStrength.None && (
        <div className="password-strength-bar-label mr-2">
          <small>{strength.toString()}</small>
        </div>
      )}
      <div
        className={classNames("progress password-strength-bar", barClassName)}
      >
        <div
          className={classNames(
            "progress-bar",
            `password-strength-bar--${kebabCase(strength.toString())}`
          )}
          role="progressbar"
          aria-valuenow={passwordStrengthToNumber(strength)}
          aria-valuemin={0}
          aria-valuemax={5}
        ></div>
      </div>
    </div>
  );
}

function passwordStrengthToNumber(strength: PasswordStrength): number {
  if (strength === PasswordStrength.None) {
    return 0;
  } else if (strength === PasswordStrength.VeryWeak) {
    return 1;
  } else if (strength === PasswordStrength.Weak) {
    return 2;
  } else if (strength === PasswordStrength.Average) {
    return 3;
  } else if (strength === PasswordStrength.Strong) {
    return 4;
  } else if (strength === PasswordStrength.VeryStrong) {
    return 5;
  } else {
    assertNever(strength, "unknown password strength");
  }
}
