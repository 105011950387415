export function formatNumber(value: number): string {
  /**
   * Numbers are formatted according to the following rules. Where they
   * conflict, earlier rules take precedence.
   * * at least one significant figure is shown
   * * digits before the decimal point are not rounded
   * * no more than three decimal places are shown
   * * values are shown to three significant figures
   */

  if (value >= 100) {
    return value.toFixed(0);
  } else if (value >= 10) {
    return trimDecimalPlaces(value.toFixed(1));
  } else if (value >= 1) {
    return trimDecimalPlaces(value.toFixed(2));
  } else if (value >= 0.001) {
    return trimDecimalPlaces(value.toFixed(3));
  } else {
    return value.toPrecision(1);
  }
}

function trimDecimalPlaces(value: string) {
  for (let index = value.length - 1; index > 0; index--) {
    const char = value.charAt(index);
    if (char === ".") {
      return value.substring(0, index);
    } else if (char !== "0") {
      return value.substring(0, index + 1);
    }
  }

  return value;
}
