import { ImpactRating } from "../../../../__generated__/globalTypes";

export const letterColor = (impactRating: ImpactRating, greyscale: boolean) => {
  if (greyscale) {
    return [ImpactRating.VLOW, ImpactRating.LOW].includes(impactRating)
      ? "black"
      : "white";
  } else {
    return [ImpactRating.LOW, ImpactRating.MEDIUM].includes(impactRating)
      ? "black"
      : "white";
  }
};
