import { LightDropdown, PrimaryDropdown } from "./Dropdown";

const stories = {
  name: "Dropdown",
  children: [
    {
      name: "Primary Dropdown",
      render: () => (
        <PrimaryDropdown title={"DropDropDrop"}>
          <PrimaryDropdown.Item>Action</PrimaryDropdown.Item>
          <PrimaryDropdown.Item>Another Action</PrimaryDropdown.Item>
          <PrimaryDropdown.Divider />
          <PrimaryDropdown.Item>Yet Another Action</PrimaryDropdown.Item>
        </PrimaryDropdown>
      ),
    },
    {
      name: "Light Dropdown",
      render: () => (
        <LightDropdown title={"DropDropDrop"}>
          <LightDropdown.Item>Action</LightDropdown.Item>
          <LightDropdown.Item>Another Action</LightDropdown.Item>
          <LightDropdown.Divider />
          <LightDropdown.Item>Yet Another Action</LightDropdown.Item>
        </LightDropdown>
      ),
    },
  ],
};

export default stories;
