import { LocationDescriptorObject } from "history";
import { useLocation } from "react-router-dom";

import { RecipesListPageQueryParams } from "../pages";

export function queryParamsToLocationDescriptor(
  pathname: string,
  queryParams: RecipesListPageQueryParams
): LocationDescriptorObject {
  return { pathname, state: queryParams };
}

export function useQueryParams(): RecipesListPageQueryParams | null {
  const location = useLocation<RecipesListPageQueryParams>();
  return location.state !== undefined ? location.state : null;
}
