import classNames from "classnames";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { useDataStore } from "../../data-store";
import UserVisibleError from "../../util/UserVisibleError";
import StatusDisplay from "../StatusDisplay";
import { PrimaryButton } from "../utils/Button";
import ErrorAlert from "../utils/ErrorAlert";
import {
  PasswordStrengthSuggestions,
  useCheckPasswordStrengthPromise,
  useIllegalAuthenticatedUserPasswordInputs,
  useYourPasswordIsTooWeakMessage,
} from "../utils/passwordStrength";
import PasswordStrengthBar from "../utils/PasswordStrengthBar";
import useId from "../utils/useId";
import usePromise from "../utils/usePromise";
import "./ChangePassword.css";

interface ChangePasswordProps {
  onHide: () => void;
  show: boolean;
  userid: number;
  username: string;
}

interface ChangePasswordState {
  changeSuccess: boolean;
  error: unknown;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default function ChangePassword(props: ChangePasswordProps) {
  const { onHide, show, userid, username } = props;

  const dataStore = useDataStore();
  const intl = useIntl();
  const validate = useValidate();
  const [checkPasswordStrengthStatus] = usePromise(
    useCheckPasswordStrengthPromise
  );
  const newPasswordComponentId = useId();
  const illegalPasswordInputs = useIllegalAuthenticatedUserPasswordInputs();
  const [showPasswordStrengthBar, setShowPasswordStrengthBar] = useState(false);

  const [state, setState] = useState<ChangePasswordState>({
    changeSuccess: false,
    confirmPassword: "",
    error: null,
    newPassword: "",
    oldPassword: "",
  });

  const updateState = (update: Partial<ChangePasswordState>) =>
    setState((state) => ({ ...state, ...update }));

  const { changeSuccess, confirmPassword, error, newPassword, oldPassword } =
    state;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      await validate(state);

      await dataStore.changePassword({
        userId: userid,
        username,
        oldPassword,
        newPassword,
      });
      updateState({
        error: null,
        changeSuccess: true,
      });
    } catch (error) {
      updateState({
        error,
      });
    }
  };

  return (
    <StatusDisplay status={checkPasswordStrengthStatus}>
      {(checkPasswordStrength) => {
        const passwordStrength = checkPasswordStrength({
          password: state.newPassword,
          userInputs: illegalPasswordInputs,
        });

        return (
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className="align-items-start border-0"
            onHide={onHide}
            show={show}
          >
            <Modal.Header
              className="justify-content-center medium-font border-0 py-2"
              closeButton
            >
              <h5 className="medium-font m-0 pt-3 text-center">
                <FormattedMessage
                  id="components/account/ChangePassword:title"
                  defaultMessage="Change Password"
                />
              </h5>
            </Modal.Header>
            {changeSuccess ? (
              <h6 className="py-5 text-center">
                <FormattedMessage
                  id="components/account/ChangePassword:success"
                  defaultMessage="Password Successfully Changed."
                />
              </h6>
            ) : (
              <form
                className="col-7 my-2 mx-auto p-0 pb-3"
                onSubmit={handleSubmit}
              >
                <div className="form-group m-0 pt-2">
                  <input
                    name={"oldPassword"}
                    placeholder={intl.formatMessage({
                      id: "components/account/ChangePassword:oldPasswordPlaceholder",
                      defaultMessage: "Current Password",
                    })}
                    type="password"
                    value={oldPassword}
                    className="form-control m-0 mb-3"
                    onChange={(e) =>
                      updateState({
                        oldPassword: e.target.value,
                      })
                    }
                  />

                  <label
                    className={classNames(
                      "new-password-label ChangePassword_PasswordStrengthBar float-right mb-2",
                      {
                        ChangePassword_PasswordStrengthBar__visible:
                          showPasswordStrengthBar,
                      }
                    )}
                    htmlFor={newPasswordComponentId}
                  >
                    <PasswordStrengthBar
                      barClassName="ChangePassword__passwordStrengthIndicatorBar"
                      strength={passwordStrength.strength}
                      style={{
                        minHeight: "2em",
                      }}
                    />
                  </label>
                  <input
                    className="form-control no-radius-bottom m-0"
                    id={newPasswordComponentId}
                    name={"newPassword"}
                    onBlur={() =>
                      state.newPassword === "" &&
                      setShowPasswordStrengthBar(false)
                    }
                    onChange={(e) =>
                      updateState({
                        newPassword: e.target.value,
                      })
                    }
                    onFocus={() => setShowPasswordStrengthBar(true)}
                    placeholder={intl.formatMessage({
                      id: "components/account/ChangePassword:newPasswordPlaceholder",
                      defaultMessage: "New Password",
                    })}
                    type="password"
                    value={newPassword}
                  />

                  <input
                    name={"confirmPassword"}
                    placeholder={intl.formatMessage({
                      id: "components/account/ChangePassword:confirmPasswordPlaceholder",
                      defaultMessage: "Confirm New Password",
                    })}
                    type="password"
                    value={confirmPassword}
                    className="form-control no-radius-top border-top-0 m-0"
                    onChange={(e) =>
                      updateState({
                        confirmPassword: e.target.value,
                      })
                    }
                  />

                  <PasswordStrengthSuggestions
                    className="mt-3"
                    show
                    suggestions={passwordStrength.suggestions}
                  />
                </div>
                <ErrorAlert className="mt-4" error={error} />
                <div className="mt-4">
                  <PrimaryButton className="w-100" type="submit">
                    <FormattedMessage
                      id="components/account/ChangePassword:saveButton"
                      defaultMessage="Save"
                    />
                  </PrimaryButton>
                </div>
              </form>
            )}
          </Modal>
        );
      }}
    </StatusDisplay>
  );
}

function useValidate() {
  const intl = useIntl();
  const illegalPasswordInputs = useIllegalAuthenticatedUserPasswordInputs();
  const yourPasswordIsTooWeakMessage = useYourPasswordIsTooWeakMessage();
  const checkPasswordStrengthPromise = useCheckPasswordStrengthPromise();

  return async function validate({
    oldPassword,
    newPassword,
    confirmPassword,
  }: ChangePasswordState) {
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/account/ChangePassword:missingFieldsErrorMessage",
          defaultMessage: "Please fill out all the fields.",
        })
      );
    } else if (newPassword !== confirmPassword) {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/account/ChangePassword:passwordMismatchErrorMessage",
          defaultMessage: "Passwords do not match.",
        })
      );
    } else {
      const checkPasswordStrengthResult = (await checkPasswordStrengthPromise)({
        password: newPassword,
        userInputs: illegalPasswordInputs,
      });

      if (!checkPasswordStrengthResult.isValid) {
        throw new UserVisibleError(
          checkPasswordStrengthResult.warning ?? yourPasswordIsTooWeakMessage
        );
      }
    }
  };
}
