import VectorProps from "./VectorProps";

export default function ImpactPendingCircle(props: Omit<VectorProps, "fill">) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?type=design&node-id=846-888&mode=design&t=AdebDTQfodLDu0GQ-0
  return (
    <svg
      className={className}
      fill="none"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 42 42"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.5"
        cy="21"
        r="19"
        fill="#DEE2E6"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M21.6909 30.6181C26.8209 30.6181 31 26.439 31 21.3091C31 16.1791 26.8209 12 21.6909 12C19.5681 12 17.5782 12.6854 15.9198 13.99C15.4996 14.3216 15.4333 14.9407 15.7649 15.3609C16.0965 15.781 16.7156 15.8473 17.1358 15.5157C18.4404 14.4765 20.0104 13.9457 21.6908 13.9457C25.7373 13.9457 29.0319 17.2404 29.0319 21.2868C29.0319 25.3332 25.7372 28.6279 21.6908 28.6279C17.7328 28.6279 14.5266 25.4881 14.3497 21.5964H15.9638C16.5609 21.5964 16.9368 20.933 16.605 20.4245L13.9962 16.4443C13.6866 15.9799 13.0232 15.9799 12.7358 16.4443L10.1266 20.4466C9.79503 20.9551 10.1487 21.6185 10.7678 21.6185H12.4041C12.559 26.6158 16.6717 30.618 21.6911 30.618L21.6909 30.6181Z"
        fill="black"
      />
    </svg>
  );
}
