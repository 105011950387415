import VectorProps from "./VectorProps";

export default function Dash(props: VectorProps) {
  const { className = "", fill = "currentColor", width } = props;

  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" width="14" height="2" fill={fill} />
    </svg>
  );
}
