import { useDataStore, SupportedRegion } from "../../../data-store";
import Select from "../../utils/Select";

interface SupportedRegionSelectProps {
  id?: string;
  onChange: (value: SupportedRegion | null) => void;
  value: SupportedRegion | null;
}

export function SupportedRegionSelect(props: SupportedRegionSelectProps) {
  const { id, onChange, value } = props;

  const dataStore = useDataStore();

  async function loadOptions() {
    return await dataStore.fetchSupportedRegions();
  }

  return (
    <Select
      id={id}
      onChange={onChange}
      options={loadOptions}
      value={value}
      optionKey={(location) => location.id.toString()}
      renderOption={(location) => location.name}
    />
  );
}
