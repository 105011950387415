import VectorProps from "./VectorProps";

interface InformationProps extends VectorProps {
  textFill?: string;
}

export default function Information(props: InformationProps) {
  const { fill = "currentColor", textFill = "white", height, width } = props;
  // from Foodsteps design library: https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?node-id=1501%3A1889&mode=dev
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z"
        fill={fill}
      />
      <path
        d="M7.182 12.867V5.964H8.872V12.867H7.182ZM8.0335 4.69C7.75617 4.69 7.5135 4.61633 7.3055 4.469C7.10183 4.31733 7 4.10717 7 3.8385C7 3.57417 7.10183 3.36833 7.3055 3.221C7.5135 3.07367 7.75617 3 8.0335 3C8.3065 3 8.54483 3.07367 8.7485 3.221C8.95217 3.36833 9.054 3.57417 9.054 3.8385C9.054 4.10717 8.95217 4.31733 8.7485 4.469C8.54483 4.61633 8.3065 4.69 8.0335 4.69Z"
        fill={textFill}
      />
    </svg>
  );
}
