import classNames from "classnames";
import React, { ReactElement, useState } from "react";

import useId from "./useId";
import "./RadioButtons.css";

interface RadioButtonProps<T> {
  buttonStyle?: "square" | "minimal";
  className?: string;
  hoverValue?: T | null;
  id: string;
  inline?: boolean;
  onChange: (value: T) => void;
  optionKey: (option: T) => string;
  option: T;
  renderOptionLabel: (option: T) => React.ReactNode;
  setHoverLabel?: (option: T | null) => void;
  value: T | null;
}

function RadioButton<T>(props: RadioButtonProps<T>) {
  const {
    buttonStyle = "minimal",
    className,
    id,
    inline = false,
    hoverValue,
    onChange,
    option,
    optionKey,
    renderOptionLabel,
    setHoverLabel,
    value,
  } = props;

  const key = optionKey(option);

  const label = renderOptionLabel(option);
  const checked = value !== null && optionKey(value) === key;
  // we use this instead of the hover pseudo element
  // because we want both the input element and outer div element's hover states to trigger
  // when the outer div is hovered over

  const [hover, setHover] = useState(false);

  const squareClassNames = classNames("radio-button-square", {
    "w-100": inline,
    "radio-button-square-checked": checked,
  });

  const inputClassNames = classNames("radio-button-input", {
    "radio-button-input-inline": inline,
    "radio-button-input-hover":
      hoverValue === undefined
        ? hover
        : hoverValue !== null && optionKey(hoverValue) === key,
  });

  return (
    <div
      className={classNames("d-flex form-check", className, {
        [`${squareClassNames}`]: buttonStyle === "square",
        "form-check-inline mr-0": inline,
        "mb-1": !inline,
      })}
      key={key}
      onClick={() => onChange(option)}
      onMouseOver={() =>
        setHoverLabel ? setHoverLabel(option) : setHover(true)
      }
      onMouseLeave={() =>
        setHoverLabel ? setHoverLabel(null) : setHover(false)
      }
    >
      <input
        checked={checked}
        className={inputClassNames}
        type="radio"
        id={id + "_" + key}
        value={key}
        name={id}
        onChange={() => onChange(option)}
      />

      <label className="form-check-label ml-1" htmlFor={id + "_" + key}>
        {label}
      </label>
    </div>
  );
}

interface RadioButtonsProps<T> {
  buttonStyle?: "square" | "minimal";
  className?: string;
  hoverValue?: T | null;
  inline?: boolean;
  onChange: (value: T) => void;
  optionKey: (option: T) => string;
  options: Array<T>;
  renderOptionLabel: (option: T) => React.ReactNode;
  setHoverLabel?: (option: T | null) => void;
  value: T | null;
}

export function RadioButtonElements<T>(
  props: RadioButtonsProps<T>
): ReactElement[] {
  const {
    buttonStyle = "minimal",
    className,
    hoverValue = null,
    inline = false,
    onChange,
    optionKey,
    options,
    renderOptionLabel,
    setHoverLabel,
    value,
  } = props;

  const id = useId();

  return options.map((option) => (
    <RadioButton<T>
      buttonStyle={buttonStyle}
      className={className}
      hoverValue={hoverValue}
      id={id}
      inline={inline}
      key={optionKey(option)}
      option={option}
      onChange={onChange}
      optionKey={optionKey}
      renderOptionLabel={renderOptionLabel}
      setHoverLabel={setHoverLabel}
      value={value}
    />
  ));
}

export default function RadioButtons<T>(props: RadioButtonsProps<T>) {
  const { buttonStyle = "minimal", inline = false } = props;

  const wrap = (chunks: React.ReactNode) =>
    buttonStyle === "square" && inline ? (
      <div className="d-flex justify-content-around flex-row">{chunks}</div>
    ) : (
      <>{chunks}</>
    );

  return wrap(
    <div
      className={classNames("d-flex", {
        "flex-column": !inline,
        "flex-row": inline,
        "radio-buttons-inline-wrapper": inline,
        "w-100": inline,
      })}
    >
      {RadioButtonElements(props)}
    </div>
  );
}
