import VectorProps from "./VectorProps";

export default function FilledSquare(props: VectorProps) {
  const { className, fill = "var(--foodsteps-turquoise)", width } = props;
  return (
    <div
      className={className}
      style={{
        width,
        height: width,
        backgroundColor: fill,
        borderRadius: 2,
      }}
    />
  );
}
