import { CarbonLabelVectorProps } from "../../CarbonLabelVectorProps";
import {
  RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
  RATING_SCALE_HEIGHT_TO_WIDTH_RATIO,
} from "../../constants";

export default function RatingScaleLabelVHigh(props: CarbonLabelVectorProps) {
  const {
    className = "",
    handleClick = () => null,
    strokeWidth,
    width,
  } = props;
  // Design in https://www.figma.com/file/uUnr9LO10uWP7AQcqxb50z/Foodsteps-Carbon-Labels?type=design&node-id=148-4&t=Q6gbQFlQqTUhBThB-0
  const scaleFactor = width / RATING_SCALE_BASE_SVG_WIDTH_PIXELS;
  const height = width * RATING_SCALE_HEIGHT_TO_WIDTH_RATIO;
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      onClick={handleClick}
      viewBox={`0 0 ${width / scaleFactor} ${height / scaleFactor}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2093 40.772C31.9114 40.772 40.5872 32.0962 40.5872 21.3941C40.5872 10.692 31.9114 2.01617 21.2093 2.01617C10.5071 2.01617 1.83134 10.692 1.83134 21.3941C1.83134 32.0962 10.5071 40.772 21.2093 40.772Z"
        fill="#00A68F"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M37.6327 17.6721C37.0188 17.3651 36.4048 17.3651 35.4839 18.2861C35.0925 18.6775 35.1769 19.0535 35.1769 19.8209C35.1769 20.0604 34.9339 21.1681 34.65 21.3558C33.6 22.05 31.8048 21.7058 31.0327 21.3558C30.3328 21.0381 29.2645 21.1486 28.8839 21.8163C28.2699 22.8907 28.7304 23.9651 29.8048 24.4256C30.4356 24.6957 31.8824 24.675 32.025 26.25C32.1365 27.4817 30.1118 27.6488 29.9583 29.3372C29.8048 31.0256 28.5938 31.7746 28.0474 33.0885C27.5009 34.4023 27.2922 35.1299 26.6368 35.9848C25.7542 37.1344 24.1641 37.687 22.7582 37.3324C22.2332 37.1989 21.7282 36.9441 21.3629 36.5435C20.2747 35.354 20.7551 33.4845 20.5679 31.8821C20.4159 30.5928 19.7636 29.3679 18.7797 28.5222C17.7007 27.5951 16.3009 27.15 15.0622 26.4516C13.8236 25.7533 13.0945 24.7725 13.0761 23.3496C13.0577 21.913 13.5749 20.174 14.918 19.6659C16.261 19.1579 19.0499 19.0105 20.4436 19.3589C22.2854 19.8194 24.7658 19.9668 25.8156 18.5915C26.2562 18.0144 26.3667 17.0597 25.6622 16.4427C24.4342 15.3682 22.7459 16.7496 21.518 16.2892C20.6093 15.9484 20.3837 14.4274 18.9087 14.4473C17.4336 14.4673 16.5142 14.5394 15.5319 15.5217C14.611 16.4427 12.8198 16.4242 12.3087 15.0613C11.8482 13.8334 12.763 13.2133 13.3831 12.7589C14.0032 12.3046 14.4263 11.7039 14.609 10.9564C14.7087 10.5466 14.4575 8.76824 15.6854 8.15429C17.5933 7.20113 17.7299 10.1496 19.3691 9.53569C20.597 9.07522 20.2532 7.83504 20.597 7.23336C21.211 6.15894 21.9692 6.02848 22.7459 5.69848C24.3943 4.99704 25.4933 4.34625 26.2792 2.6855C34.1102 4.80211 39.9749 11.7029 40.5429 20.0604C40.5429 20.0604 38.2482 17.9775 37.6342 17.6706L37.6327 17.6721Z"
        fill="#E3F8E5"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.56836 13.3698C3.60059 13.3667 3.63436 13.3652 3.66659 13.3606C5.26748 13.1549 6.60436 13.0106 7.85836 11.993C9.13078 10.96 9.85678 9.56787 10.3142 8.30927C10.6319 7.43439 10.1238 6.65159 9.62501 5.86113C7.02492 7.80429 4.9298 10.3844 3.56989 13.3698H3.56836Z"
        fill="#E3F8E5"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.47437 29.7977C6.27637 28.843 6.05535 28.0986 5.70693 27.1884C5.30632 26.1447 5.43372 24.8354 5.41069 23.718C5.39995 23.1977 5.24646 21.9698 4.786 21.3558C3.99093 20.2952 3.25111 19.6675 1.97409 19.0397C1.88046 19.8117 1.83134 20.5961 1.83134 21.3942C1.83134 26.0326 3.46293 30.2904 6.18121 33.6272C6.18121 33.6272 6.26102 33.5213 6.41604 33.3095C7.16967 32.2366 6.74144 31.0809 6.47437 29.7977Z"
        fill="#E3F8E5"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="27" y="1" width="76" height="40" rx="7" fill="white" />
      <path
        d="M34 17C34 14.7909 35.7909 13 38 13H42V29H38C35.7909 29 34 27.2091 34 25V17Z"
        fill="#348041"
      />
      <rect x="44" y="13" width="8" height="16" fill="#BBD64E" />
      <rect x="54" y="13" width="8" height="16" fill="#F9CB27" />
      <path d="M64 13H72V29H64V13Z" fill="#EF6125" />
      frontend/src/components/utils/Vectors/carbon-labels/colour/letter-rating
      <rect
        x="75"
        y="9"
        width="20"
        height="24"
        rx="5"
        fill="#D11707"
        stroke="black"
        strokeWidth={strokeWidth}
      />
      <path
        d="M81.3973 26V14.56H88.8533V16.576H83.9733V19.248H87.8773V21.136H83.9733V23.984H89.1093V26H81.3973Z"
        fill="white"
      />
      <rect
        x="27"
        y="1"
        width="76"
        height="40"
        rx="7"
        stroke="black"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
