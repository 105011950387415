import classNames from "classnames";
import gql from "graphql-tag";
import { MutableRefObject, useEffect, useReducer } from "react";
import { FormattedMessage } from "react-intl";

import { useIsOverflowing } from "../utils/useIsOverflowing";
import { PackagingComponentMaterialNames__PackagingComponentV2 as PackagingComponentV2 } from "./PackagingComponentMaterialNames.graphql";

interface State {
  more: number;
  visibleNames: Array<string>;
}

function initialState(names: Array<string>): State {
  return {
    more: 0,
    visibleNames: names,
  };
}

function transitionState(state: State): State {
  if (state.visibleNames.length <= 1) {
    return state;
  } else {
    return {
      more: state.more + 1,
      visibleNames: state.visibleNames.slice(0, -1),
    };
  }
}

interface PackagingComponentMaterialNamesProps<T extends HTMLElement | null> {
  overflowRef: MutableRefObject<T>;
  packagingComponent: PackagingComponentV2;
}

export function PackagingComponentMaterialNames<T extends HTMLElement | null>(
  props: PackagingComponentMaterialNamesProps<T>
) {
  const { overflowRef, packagingComponent } = props;

  const [state, nextState] = useReducer(
    transitionState,
    packagingComponent.componentMaterials.map(
      (componentMaterial) => componentMaterial.material.name
    ),
    initialState
  );

  const [, layout] = useIsOverflowing(overflowRef, (hasOverflow: boolean) => {
    if (!hasOverflow) {
      return;
    }

    // Because we do not ever reset the state, there's a small bug here. If the
    // user reduces the size of the window, then expands it again, we will continue
    // to show the furthest state reached, which means we might be showing fewer
    // material names than we feasibly could in the available space.
    nextState();
  });

  // Trigger layout on first render.
  useEffect(() => {
    layout();
  });

  return (
    <>
      <div
        className={classNames({
          PackagingComponentTable__column__materials__singleMaterial:
            state.visibleNames.length <= 1,
        })}
      >
        {state.visibleNames.length === 0 ? null : state.visibleNames.join(", ")}{" "}
        <strong>
          {state.more !== 0 && (
            <FormattedMessage
              id="components/packaging/PackagingComponentMaterialNames:plusMore"
              defaultMessage="+{numberMore} more"
              values={{ numberMore: state.more }}
            />
          )}
        </strong>
      </div>
    </>
  );
}

export const exportedForTesting = {
  initialState,
  transitionState,
};

PackagingComponentMaterialNames.fragments = {
  packagingComponent: gql`
    fragment PackagingComponentMaterialNames__PackagingComponentV2 on PackagingComponentV2 {
      componentMaterials {
        material {
          name
        }
      }
    }
  `,
};
