import classNames from "classnames";
import React, { useState } from "react";

import * as comparators from "../../util/comparators";
import UserVisibleError from "../../util/UserVisibleError";
import {
  DownArrow as DownArrowIcon,
  DownUpArrow as DownUpArrowIcon,
  Private as PrivateIcon,
  UpArrow as UpArrowIcon,
} from "../utils/Vectors/";
import LoadMoreButton from "./LoadMoreButton";
import "./Table.css";
import TooltipOverlay from "./TooltipOverlay";

type RowKey = number | string;

export interface TableColumn<T> {
  align?: "center" | "right";
  className?: string;
  key: string;
  label: React.ReactNode;
  labelPrefix?: React.ReactNode;
  locked?: boolean;
  lockedMessage?: React.ReactNode;
  // We could instead define onLockClick (or a general onIconClick) on the table rather than the column if this ends up being
  // repeated, but for now this gives up the flexibility to have different functionality by column
  onLockClick?: () => void;
  renderCell: (
    value: T,
    renderOptions: {
      className?: string;
      isChildRow: boolean;
      isExpanded: boolean;
      toggleExpanded: (() => void) | null;
    }
  ) => React.ReactNode;
  sortComparator?: comparators.Comparator<T>;
  verticalAlign?: "middle";
  units?: React.ReactNode;
}

interface SortProps {
  defaultSort?: string;
  sort?: TableSort | null;
  sortDisabled?: boolean;
  sortRequired?: boolean;
  onSortChange?: (sort: TableSort | null) => void;
}

interface TableProps<T> extends SortProps {
  activeRows?: Array<T>;
  className?: string;
  columns: Array<TableColumn<T>>;
  expandedRows?: (row: T) => Array<T>;
  fullWidth?: boolean;
  isExpandable?: (row: T) => boolean;
  onClickRow?: (row: T) => void;
  onExpand?: (row: T) => void;
  rowClassName?: string;
  rowKey: (row: T) => RowKey;
  rows: Array<T>;
  setHoverRow?: (row: T | null) => void;
}

export interface TableSort {
  columnKey: string;
  descending: boolean;
}

export function Table<T>(props: TableProps<T>) {
  const {
    activeRows = [],
    className,
    columns,
    expandedRows,
    fullWidth,
    isExpandable = () => false,
    onClickRow = () => {},
    onExpand,
    rowClassName,
    rowKey,
    rows: rootRows,
    setHoverRow,
    sortDisabled = false,
    sortRequired = true,
  } = props;

  const { sort, handleSortChange } = useSort(props);

  const [expandedRowKeys, setExpandedRowKeys] = useState<Set<RowKey>>(
    new Set()
  );

  function collapse(row: T) {
    setExpandedRowKeys((expandedRowKeys) => {
      const newExpandedRowKeys = new Set(expandedRowKeys);
      newExpandedRowKeys.delete(rowKey(row));
      return newExpandedRowKeys;
    });
  }

  function expand(row: T) {
    setExpandedRowKeys((expandedRowKeys) => {
      const newExpandedRowKeys = new Set(expandedRowKeys);
      newExpandedRowKeys.add(rowKey(row));
      return newExpandedRowKeys;
    });

    if (onExpand !== undefined) {
      onExpand(row);
    }
  }

  const widthClassName = fullWidth ? "w-100" : "w-auto";

  const sortRows = (rows: Array<T>) => {
    if (sort === null) {
      return rows;
    }
    const column = columns.find((column) => column.key === sort.columnKey);
    if (column === undefined || column.sortComparator === undefined) {
      return rows;
    }
    const comparator = sort.descending
      ? comparators.reversed(column.sortComparator)
      : column.sortComparator;

    return Array.from(rows).sort(comparator);
  };

  const sortedRootRows = sortRows(rootRows);

  const rows = sortedRootRows.flatMap((rootRow) => {
    const isExpanded = expandedRowKeys.has(rowKey(rootRow));
    const highlight = isExpanded;
    const active = activeRows.includes(rootRow);

    const childRows =
      isExpanded && expandedRows !== undefined
        ? sortRows(expandedRows(rootRow)).map((row) => ({
            isChildRow: true,
            row,
            highlight,
            active,
          }))
        : [];

    return [
      { isChildRow: false, row: rootRow, highlight, active },
      ...childRows,
    ];
  });

  const tableHasUnits = columns.some((column) => column.units !== undefined);

  return (
    <>
      <table
        className={classNames(
          "mb-0 table border-collapse",
          widthClassName,
          className
        )}
      >
        <thead>
          <tr>
            {columns.map((column, index) => (
              <HeaderCell
                column={column}
                columnPlacement={columnPlacement(index, columns.length)}
                key={column.key}
                locked={column.locked}
                lockedMessage={column.lockedMessage}
                onLockClick={column.onLockClick}
                sortDisabled={sortDisabled}
                sortRequired={sortRequired}
                sortState={sort}
                onSortStateChange={handleSortChange}
                tableHasUnits={tableHasUnits}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ isChildRow, row, highlight, active }, index) => (
            <tr
              className={classNames(rowClassName)}
              key={rowKey(row)}
              onClick={() => onClickRow(row)}
              onMouseOver={setHoverRow ? () => setHoverRow(row) : undefined}
              onMouseLeave={setHoverRow ? () => setHoverRow(null) : undefined}
            >
              {columns.map((column, index) => (
                <td
                  className={classNames(
                    cellClassNames(
                      column,
                      false,
                      columnPlacement(index, columns.length)
                    ),
                    column.className,
                    { "table-active-row": active }
                  )}
                  key={column.key}
                >
                  {column.renderCell(row, {
                    isChildRow,
                    isExpanded: expandedRowKeys.has(rowKey(row)),
                    toggleExpanded: isExpandable(row)
                      ? () => {
                          if (expandedRowKeys.has(rowKey(row))) {
                            collapse(row);
                          } else {
                            expand(row);
                          }
                        }
                      : null,
                  })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

interface PagedTableProps<T> extends TableProps<T> {
  onLoadMore: null | (() => Promise<void>);
}

export function PagedTable<T>(props: PagedTableProps<T>) {
  const { onLoadMore, ...tableProps } = props;
  return (
    <div>
      <Table {...tableProps} />
      <LoadMoreButton onClick={onLoadMore} />
    </div>
  );
}

function useSort(props: SortProps) {
  const { defaultSort, sort: sortProp, onSortChange } = props;

  const [sortState, setSortState] = useState<TableSort | null>(() => {
    if (defaultSort === undefined) {
      return null;
    } else {
      return { columnKey: defaultSort, descending: false };
    }
  });

  const sort = sortProp === undefined ? sortState : sortProp;

  function handleSortChange(sort: TableSort | null) {
    if (onSortChange !== undefined) {
      onSortChange(sort);
    }
    setSortState(sort);
  }

  return { sort, handleSortChange };
}

enum ColumnSortState {
  UNSORTED,
  ASCENDING,
  DESCENDING,
}

function sortStateToColumnSortState<T>(
  sortState: TableSort | null,
  column: TableColumn<T>
): ColumnSortState {
  if (sortState === null || sortState.columnKey !== column.key) {
    return ColumnSortState.UNSORTED;
  } else if (sortState.descending) {
    return ColumnSortState.DESCENDING;
  } else {
    return ColumnSortState.ASCENDING;
  }
}

function columnSortStateToSortState<T>(
  columnSortState: ColumnSortState,
  column: TableColumn<T>
): TableSort | null {
  if (columnSortState === ColumnSortState.ASCENDING) {
    return { columnKey: column.key, descending: false };
  } else if (columnSortState === ColumnSortState.DESCENDING) {
    return { columnKey: column.key, descending: true };
  } else {
    return null;
  }
}

enum ColumnPlacement {
  FIRST = "FIRST",
  MIDDLE = "MIDDLE",
  LAST = "LAST",
}

interface HeaderCellProps {
  column: TableColumn<never>;
  columnPlacement: ColumnPlacement;
  locked?: boolean;
  lockedMessage?: React.ReactNode;
  onLockClick?: () => void;
  sortDisabled?: boolean;
  sortRequired?: boolean;
  sortState: TableSort | null;
  onSortStateChange: (sortState: TableSort | null) => void;
  tableHasUnits: boolean;
}

function HeaderCell(props: HeaderCellProps) {
  const {
    column,
    columnPlacement,
    locked,
    lockedMessage,
    onLockClick,
    sortDisabled,
    sortRequired,
    sortState,
    onSortStateChange,
    tableHasUnits,
  } = props;

  const columnSortState = sortStateToColumnSortState(sortState, column);

  const handleClick = () => {
    if (!locked) {
      onSortStateChange(
        columnSortStateToSortState(sorting.nextSortState, column)
      );
    }
  };

  const sorting = headerSorting(columnSortState);

  let body;

  if (tableHasUnits) {
    body = (
      <>
        {column.label}
        <br />
        <span
          className={`small ${column.units === undefined ? "invisible" : ""}`}
        >
          {column.units === undefined ? "\u2002" : <>({column.units})</>}
        </span>
      </>
    );
  } else {
    body = column.label;
  }

  return (
    <th
      className={classNames(
        cellClassNames(column, true, columnPlacement),
        column.className
      )}
    >
      <div
        className={classNames("d-flex flex-row", {
          "justify-content-end": column.align === "right",
          "justify-content-center": column.align === "center",
        })}
      >
        {column.labelPrefix && <div className="pr-1">{column.labelPrefix}</div>}
        <div
          className={classNames("d-flex", {
            "action-link action-link-info": !locked,
          })}
          onClick={sortDisabled ? () => null : handleClick}
        >
          <div className="pr-1">{body}</div>
          {locked ? (
            <TooltipOverlay
              id="components/utils/Table/HeaderCell:privateIconTooltip"
              overlay={lockedMessage}
              placement="top"
              style={{ maxWidth: "173px" }}
            >
              <PrivateIcon
                className="table-header-private-icon"
                width={16}
                handleClick={onLockClick}
              />
            </TooltipOverlay>
          ) : (
            (column.sortComparator !== undefined || sortRequired === false) && (
              <div
                className={sorting.className}
                style={{ top: 0, right: -16 }}
                onClick={sortDisabled ? () => null : handleClick}
              >
                {sorting.body}
              </div>
            )
          )}
        </div>
      </div>
    </th>
  );
}

function headerSorting(sortState: ColumnSortState) {
  if (sortState === ColumnSortState.ASCENDING) {
    return {
      body: <DownArrowIcon width={12} />,
      nextSortState: ColumnSortState.DESCENDING,
    };
  } else if (sortState === ColumnSortState.DESCENDING) {
    return {
      body: <UpArrowIcon width={12} />,
      nextSortState: ColumnSortState.UNSORTED,
    };
  } else {
    return {
      body: <DownUpArrowIcon width={12} />,
      className: "text-muted",
      nextSortState: ColumnSortState.ASCENDING,
    };
  }
}

function columnPlacement(index: number, length: number) {
  if (index === 0) {
    return ColumnPlacement.FIRST;
  } else if (index === length - 1) {
    return ColumnPlacement.LAST;
  } else if (0 < index && index < length - 1) {
    return ColumnPlacement.MIDDLE;
  } else {
    throw new UserVisibleError(
      "Index must fall between 0 and the length of the array."
    );
  }
}

function cellClassNames(
  column: TableColumn<never>,
  isHeader: boolean,
  columnPlacement: ColumnPlacement
): string | undefined {
  return classNames({
    "medium-font": isHeader,
    "border-0": isHeader,
    "align-middle": column.verticalAlign === "middle",
    "text-nowrap": isHeader,
    "text-right": column.align === "right",
    "text-center": column.align === "center",
    "pl-0":
      column.key === EXPAND_COLUMN_KEY ||
      columnPlacement === ColumnPlacement.FIRST,
    "pr-0": columnPlacement === ColumnPlacement.LAST,
  });
}

const EXPAND_COLUMN_KEY = "_Table_expand";
