import gql from "graphql-tag";

import { useTags } from "../../services/useOrganizationFeatures";
import useQuery from "../graphql/useQuery";
import Page from "../Page";
import { usePages } from "../pages";
import RecipePageContent from "../recipes/RecipePageContent";
import * as RecipePageControls from "../recipes/RecipePageControls";
import * as RecipesListPageLocationState from "../recipes/RecipesListPageLocationState";
import StatusDisplay from "../StatusDisplay";
import {
  SharedProductPage_Query,
  SharedProductPage_QueryVariables,
} from "./SharedProductPage.graphql";

const SharedProductPage = { pageName: "shared-product" };

export default function RecipePage() {
  const pages = usePages();
  const hasFeatureTags = useTags();

  const { recipeId } = pages.SharedProduct(hasFeatureTags).useParams();

  const recipeQuery = RecipesListPageLocationState.useQueryParams();

  const { refresh: refreshRecipe, status: queryStatus } = useQuery<
    SharedProductPage_Query,
    SharedProductPage_QueryVariables
  >(recipeGraphqlQuery, {
    recipeId,
  });

  const [controlsState, setControlsState] = RecipePageControls.useState();

  return (
    <Page tracking={SharedProductPage} contentHasPadding={false}>
      <StatusDisplay status={queryStatus}>
        {({ recipe }) => (
          <RecipePageContent
            breadcrumb={pages
              .SharedProduct(hasFeatureTags)
              .breadcrumb(recipe, recipeQuery)}
            recipe={recipe}
            recipePageControlsState={[controlsState, setControlsState]}
            refreshRecipe={refreshRecipe}
          />
        )}
      </StatusDisplay>
    </Page>
  );
}

const recipeGraphqlQuery = gql`
  query SharedProductPage_Query($recipeId: Int!) {
    recipe(filter: { ids: [$recipeId] }) {
      ...RecipePageContent_Recipe
    }
  }

  ${RecipePageContent.fragments.recipe}
`;
