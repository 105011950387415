import Card from "../../utils/Card";
import ConfirmSignUpForm, { ConfirmSignUpFormProps } from "./ConfirmSignUpForm";

function Story(props: Pick<ConfirmSignUpFormProps, "parentOrganizationName">) {
  return (
    <Card style={{ maxWidth: "400px", margin: "20px" }}>
      <ConfirmSignUpForm
        onComplete={async () => alert("Complete")}
        parentOrganizationName={props.parentOrganizationName}
      />
    </Card>
  );
}

const stories = {
  name: "ConfirmSignUpForm",
  children: [
    { name: "normal", render: () => <Story parentOrganizationName={null} /> },
    {
      name: "with parent organization",
      render: () => <Story parentOrganizationName="Acme Co." />,
    },
  ],
};

export default stories;
