import VectorProps from "./VectorProps";

export default function Tick(props: VectorProps) {
  const { fill = "currentColor", width } = props;
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.02 4.19006C16.4397 4.50486 16.5248 5.10032 16.21 5.52006L8.71 15.5201C8.54476 15.7404 8.29205 15.8781 8.01734 15.8977C7.74264 15.9172 7.47298 15.8165 7.27825 15.6218L3.27825 11.6218C2.90725 11.2508 2.90725 10.6493 3.27825 10.2783C3.64925 9.90731 4.25075 9.90731 4.62175 10.2783L7.84722 13.5038L14.69 4.38006C15.0048 3.96032 15.6003 3.87525 16.02 4.19006Z"
        fill={fill}
      />
    </svg>
  );
}
