import VectorProps from "./VectorProps";

export default function Plus(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=8gtiAD7I2qV8BIpM-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9.8C2 9.35818 2.35817 9 2.8 9H17.2C17.6418 9 18 9.35818 18 9.8C18 10.2418 17.6418 10.6 17.2 10.6H2.8C2.35817 10.6 2 10.2418 2 9.8Z"
        fill={fill}
      />
      <path
        d="M9.99988 17.8C9.55805 17.8 9.19988 17.4418 9.19988 17V2.6C9.19988 2.15818 9.55805 1.8 9.99988 1.8C10.4417 1.8 10.7999 2.15818 10.7999 2.6V17C10.7999 17.4418 10.4417 17.8 9.99988 17.8Z"
        fill={fill}
      />
    </svg>
  );
}
