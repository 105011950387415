import gql from "graphql-tag";
import { useLocation } from "react-router-dom";

import { useTags } from "../../services/useOrganizationFeatures";
import useQuery from "../graphql/useQuery";
import Page from "../Page";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import {
  RecipePage_RecipeQuery as RecipeQuery,
  RecipePage_RecipeQueryVariables as RecipeQueryVariables,
} from "./RecipePage.graphql";
import RecipePageContent from "./RecipePageContent";
import * as RecipePageControls from "./RecipePageControls";
import * as RecipesListPageLocationState from "./RecipesListPageLocationState";

export const recipePageTracking = { pageName: "recipe" };

export default function RecipePage() {
  const pages = usePages();
  const hasFeatureTags = useTags();
  const { recipeId } = pages.Recipe(hasFeatureTags).useParams();
  const { refresh: refreshRecipe, status: queryStatus } = useQuery<
    RecipeQuery,
    RecipeQueryVariables
  >(recipeGraphqlQuery(), {
    recipeId,
  });
  const recipeQuery = RecipesListPageLocationState.useQueryParams();
  const [controlsState, setControlsState] = RecipePageControls.useState();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  if (searchParams.get("state") === "edit" && controlsState !== "edit") {
    setControlsState("edit");
  } else if (searchParams.get("state") === "view" && controlsState !== "view") {
    setControlsState("view");
  } else if (
    searchParams.get("state") === "delete" &&
    controlsState !== "delete"
  ) {
    setControlsState("delete");
  }

  return (
    <Page tracking={recipePageTracking} contentHasPadding={false}>
      <StatusDisplay status={queryStatus}>
        {({ recipe }) => (
          <RecipePageContent
            breadcrumb={pages
              .Recipe(hasFeatureTags)
              .breadcrumb(recipe, recipeQuery)}
            recipe={recipe}
            recipePageControlsState={[controlsState, setControlsState]}
            refreshRecipe={refreshRecipe}
          />
        )}
      </StatusDisplay>
    </Page>
  );
}

export const recipeGraphqlQuery = () => gql`
  query RecipePage_RecipeQuery($recipeId: Int!) {
    recipe(filter: { ids: [$recipeId] }) {
      ...RecipePageContent_Recipe
    }
  }

  ${RecipePageContent.fragments.recipe}
`;
