import Card from "../../../../../Card";
import * as DescriptiveImpactAndScaleLabels from "./index";

const stories = {
  name: "Per Kilogram",
  render: () => (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
      {Object.entries(DescriptiveImpactAndScaleLabels).map(
        ([vectorName, Vector], index) => (
          <Card
            className="d-flex flex-column justify-content-between m-2 p-2"
            key={index}
            style={{ border: "1px solid" }}
          >
            <Vector className="mb-4" width={150} impactMagnitude={1.27} />
            <p className="small">{vectorName}</p>
          </Card>
        )
      )}
    </div>
  ),
};

export default stories;
