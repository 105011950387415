import React, { useState } from "react";

import AvailableIngredient from "../../domain/AvailableIngredient";
import IngredientMatcher from "./IngredientMatcher";
import { IngredientNameStatus } from "./UploadRecipesPage";

const stories = {
  name: "IngredientMatcher",
  render: () => <Story />,
};

const availableIngredients: Array<AvailableIngredient> = [
  { name: "Available ingredient 1", id: 1, supportsEach: true, synonyms: [] },
  { name: "Available ingredient 2", id: 2, supportsEach: true, synonyms: [] },
  { name: "Food class 1", id: 2, supportsEach: true, synonyms: [] },
  { name: "Food class 2", id: 2, supportsEach: true, synonyms: [] },
];

const initialIngredientNameStatuses: Array<IngredientNameStatus> = [
  {
    foodClassId: 1,
    foodClassName: "Food class 1",
    hasError: false,
    ingredientName: "Ingredient 1",
    isAccepted: true,
    isExact: false,
    numOccurrences: 1,
    selectedAlternativeName: "Food class 1",
    selectedAlternativeFoodClassId: 1,
  },
  {
    foodClassId: 2,
    foodClassName: "Food class 2",
    hasError: false,
    ingredientName: "Ingredient 2",
    isAccepted: false,
    isExact: false,
    numOccurrences: 1,
    selectedAlternativeName: "Food class 2",
    selectedAlternativeFoodClassId: 2,
  },
];

function Story() {
  const [ingredientNameStatuses, setIngredientNameStatuses] = useState<
    Array<IngredientNameStatus>
  >(initialIngredientNameStatuses);

  const handleIsAcceptedChange = (
    status: IngredientNameStatus,
    isAccepted: boolean
  ) => {
    setIngredientNameStatuses(
      ingredientNameStatuses.map((existingStatus) =>
        existingStatus.ingredientName === status.ingredientName
          ? {
              ...existingStatus,
              isAccepted,
            }
          : existingStatus
      )
    );
  };

  const handleSelectAlternativeChange = ({
    status,
    alternativeIngredientName,
    alternativeIngredientFoodClassId,
  }: {
    status: IngredientNameStatus;
    alternativeIngredientName: string;
    alternativeIngredientFoodClassId: number | null;
  }) => {
    setIngredientNameStatuses(
      ingredientNameStatuses.map((existingStatus) =>
        existingStatus.ingredientName === status.ingredientName
          ? {
              ...existingStatus,
              hasError: false,
              selectedAlternativeName:
                alternativeIngredientName !== ""
                  ? alternativeIngredientName
                  : null,
              selectedAlternativeFoodClassId: alternativeIngredientFoodClassId,
            }
          : existingStatus
      )
    );
  };

  const handleSubmit = async () => {
    alert("Submit matches");
  };

  const submitButtonLabel = {
    loadingLabel: "Uploading",
    submitLabel: "Upload",
  };

  return (
    <IngredientMatcher
      availableIngredients={availableIngredients}
      canRequestImpactAnalysis={true}
      onSubmit={handleSubmit}
      ingredientNameStatuses={ingredientNameStatuses}
      onIsAcceptedChange={handleIsAcceptedChange}
      onSelectAlternativeChange={handleSelectAlternativeChange}
      submitButtonLabel={submitButtonLabel}
    />
  );
}

export default stories;
