import classNames from "classnames";

import * as FloatInput from "./FloatInput";

export type Value = FloatInput.Value;

export function initialValue(value: number | null) {
  return FloatInput.initialValue(value);
}

export const read = FloatInput.read;

interface FloatEditorProps {
  className?: string;
  disabled?: boolean;
  value: Value;
  onChange: (value: Value) => void;
  unitLabel: React.ReactNode;
}

export function FloatEditor(props: FloatEditorProps) {
  const {
    className = "",
    disabled = false,
    onChange,
    value,
    unitLabel,
  } = props;
  return (
    <div className={classNames(className, "input-group")}>
      <FloatInput.FloatInput
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <div className="input-group-append" style={{ lineHeight: "1rem" }}>
        <span className="input-group-text w-100 d-flex justify-content-center">
          {unitLabel}
        </span>
      </div>
    </div>
  );
}
