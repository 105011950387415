import React, { useEffect, useRef } from "react";
import fromKapsule from "react-kapsule";
import SunburstChart from "sunburst-chart";
import "./Sunburst.css";

import { changeOpacity, interfaceGrey } from "../graphs/colors";
import { LifeCycleStage } from "../recipes/LifeCycleImpacts";
import LargeNumber from "../typography/LargeNumber";
import BackButton from "./BackButton";

export const ROOT_NODE_NAME = "root";
// Keep this the same as animation-duration in Sunburst_CenterContent in Sunburst.css
export const TRANSITION_DURATION_MILLISECONDS = 500;

const ReactSunburst = fromKapsule<{
  color: (node: LifeCycleStage) => string;
  data: LifeCycleStage;
  excludeRoot: boolean;
  height: number;
  onClick: (stage: LifeCycleStage) => void;
  onHover: (stage: LifeCycleStage | null) => void;
  radiusScaleExponent: number;
  showLabels: boolean;
  size: (node: LifeCycleStage) => number | null;
  transitionDuration: number;
  width: number;
}>(SunburstChart, {
  methodNames: ["focusOnNode"],
});

interface SunburstProps {
  activeStage: LifeCycleStage;
  goBack: () => void;
  hoverStage: LifeCycleStage | null;
  onActiveStageChange: (stage: LifeCycleStage) => void;
  onHover: (stage: LifeCycleStage | null) => void;
  root: LifeCycleStage;
  transitioning: boolean;
}

export default function Sunburst(props: SunburstProps) {
  const {
    activeStage,
    goBack,
    hoverStage,
    onActiveStageChange,
    onHover,
    root,
    transitioning,
  } = props;

  let chartRef = useRef<any>(null);

  useEffect(() => {
    chartRef.current.focusOnNode(activeStage);
  }, [activeStage]);

  function childIsHoverNode(node: LifeCycleStage): boolean {
    if (node.id === hoverStage?.id) {
      return true;
    } else if (node.children === undefined) {
      return false;
    } else {
      return node.children
        .map((child: LifeCycleStage) => childIsHoverNode(child))
        .includes(true);
    }
  }

  function parentIsHoverNode(node: LifeCycleStage): boolean {
    if (node.id === hoverStage?.id) {
      return true;
    } else if (node.parent === undefined || node.parent === null) {
      return false;
    } else {
      return parentIsHoverNode(node.parent);
    }
  }

  function nodeColor(baseColor: string | null, depth: number) {
    return baseColor === null
      ? "none"
      : changeOpacity(baseColor, (0.7 ** (depth - 1)).toString());
  }

  function color(node: LifeCycleStage): string {
    if (activeStage.depth >= node.depth) {
      return "none";
    } else if (hoverStage === null) {
      return nodeColor(node.baseColor, node.depth);
    } else {
      if (
        hoverStage.id === node.id ||
        childIsHoverNode(node) ||
        parentIsHoverNode(node)
      ) {
        return nodeColor(node.baseColor, node.depth);
      } else {
        return nodeColor(interfaceGrey, node.depth);
      }
    }
  }

  function centerContent() {
    if (transitioning) {
      return null;
    }
    if (hoverStage !== null) {
      return (
        <HoverNodeCenterContent
          name={hoverStage.name}
          percentage={hoverStage.percentage}
          Icon={hoverStage.Icon}
        />
      );
    } else if (activeStage.name !== ROOT_NODE_NAME) {
      return (
        <ActiveNodeCenterContent
          name={activeStage.name}
          onBack={goBack}
          Icon={activeStage.Icon}
        />
      );
    }
  }

  const handleHover = (node: LifeCycleStage | null) => {
    if (node?.name !== ROOT_NODE_NAME) {
      onHover(node);
    }
  };

  const size = (stage: LifeCycleStage) => {
    // The ReactSunburst chart only has the correct arc sizes if only leaf "nodes" have non-null sizes.
    return stage.children.length > 0 ? null : stage.impactMagnitude;
  };

  const containerWidthInPixels = () =>
    document.getElementById("sunburst-container")?.clientWidth ?? 483;

  const widthInPixels = () => {
    if (LifeCycleStage.getHeight(root) === 1) {
      return containerWidthInPixels() * 0.75;
    } else {
      return containerWidthInPixels();
    }
  };

  return (
    <div id="sunburst-container" className="Sunburst_Container">
      <ReactSunburst
        color={color}
        data={root}
        excludeRoot={false}
        height={widthInPixels()}
        onClick={onActiveStageChange}
        onHover={handleHover}
        radiusScaleExponent={0.8}
        ref={chartRef}
        showLabels={false}
        size={size}
        transitionDuration={TRANSITION_DURATION_MILLISECONDS}
        width={widthInPixels()}
      />
      <div className="Sunburst_CenterContainer">{centerContent()}</div>
    </div>
  );
}

interface ActiveNodeCenterContentProps {
  name: string;
  onBack: () => void;
  Icon?: (props: { width: number }) => JSX.Element;
}

function ActiveNodeCenterContent(props: ActiveNodeCenterContentProps) {
  const { Icon, name, onBack } = props;
  return (
    <div className="Sunburst_CenterContent">
      {Icon !== undefined && <Icon width={40} />}
      <h4>{name}</h4>
      <BackButton back={onBack} />
    </div>
  );
}

interface HoverNodeCenterContentProps {
  name: string;
  percentage: number;
  Icon?: (props: { width: number }) => JSX.Element;
}

function HoverNodeCenterContent(props: HoverNodeCenterContentProps) {
  const { Icon, name, percentage } = props;
  return (
    <div className="Sunburst_CenterContent">
      {Icon !== undefined && <Icon width={40} />}
      <div>
        <LargeNumber>{percentage.toFixed(0)}%</LargeNumber>
        <h4>{name}</h4>
      </div>
    </div>
  );
}
