import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useTracking } from "../../../tracking";
import { PrimaryButton } from "../../utils/Button";
import QuestionMarkTooltipOverlay from "../../utils/QuestionMarkTooltipOverlay";
import RadioButtons from "../../utils/RadioButtons";

export enum EndMileTypeDecisionTreeResponse {
  MEDIUM_SHOP_RETAILER = "Medium shop retailer",
  SMALL_SHOP_RETAILER = "Small shop retailer",
  LARGE_SHOP_RETAILER = "Large shop retailer",
  MIXED_SHOP_RETAILER = "Mixed shop retailer",
  TABLE_SERVICE = "Table service",
  BUFFET_SERVICE = "Buffet service",
  GRAB_AND_GO = "Grab-and-go",
  MARKET_STALL = "Market stall",
  DELIVERED_BY_COURIER = "Delivered by courier",
  SUPERMARKET_DELIVERY = "Supermarket delivery",
  TAKEAWAY_DELIVERY_AVERAGE = "Takeaway delivery (average)",
}

interface Question {
  answers: Array<Answer>;
  text: string;
}

type Answer =
  | { text: string; nextQuestion: Question }
  | {
      text: string;
      nextQuestion: null;
      endMileTypeDecisionTreeResponse: EndMileTypeDecisionTreeResponse;
    };

interface EndMileTypeQuestionsFormProps {
  onComplete: (endMileTypeDecisionTreeResponse: string) => void;
}

export default function EndMileTypeQuestionsForm(
  props: EndMileTypeQuestionsFormProps
) {
  const { onComplete } = props;
  const intl = useIntl();

  const { trackSignUpEndMileTypeQuestionAnswered } = useTracking();

  const questionDeliveryType: Question = {
    text: intl.formatMessage({
      defaultMessage: "How is your food delivered?",
      id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/deliveryType",
    }),
    answers: [
      {
        text: intl.formatMessage({
          defaultMessage: "Ordered online and delivered from a supermarket",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/deliveryType/answers/supermarket",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.SUPERMARKET_DELIVERY,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Delivered by courier (retail product delivery)",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/deliveryType/answers/courierRetail",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.DELIVERED_BY_COURIER,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Delivered by courier (restaurant delivery)",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/deliveryType/answers/courierRestaurant",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.TAKEAWAY_DELIVERY_AVERAGE,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Collected for takeaway by customers",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/deliveryType/answers/takeaway",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.TAKEAWAY_DELIVERY_AVERAGE,
        nextQuestion: null,
      },
    ],
  };

  const questionServingMethod: Question = {
    text: intl.formatMessage({
      defaultMessage: "How are your products served to customers?",
      id: "component/account/sign-up/EndMileTypeQuestionsForm:questions/servingMethod",
    }),
    answers: [
      {
        text: intl.formatMessage({
          defaultMessage: "Table service",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/servingMethod/answers/tableService",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.TABLE_SERVICE,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Buffet service (e.g. from a hot plate)",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/servingMethod/answers/buffetService",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.BUFFET_SERVICE,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Grab-and-go",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/servingMethod/answers/grabAndGo",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.GRAB_AND_GO,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Market stall",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/servingMethod/answers/marketStall",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.MARKET_STALL,
        nextQuestion: null,
      },
    ],
  };

  const questionStoreSize: Question = {
    text: intl.formatMessage({
      defaultMessage: "What size is your typical store?",
      id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/storeSize",
    }),
    answers: [
      {
        text: intl.formatMessage({
          defaultMessage: "Large (e.g. supermarket)",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/storeSize/answers/large",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.LARGE_SHOP_RETAILER,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Medium",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/storeSize/answers/medium",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.MEDIUM_SHOP_RETAILER,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Small (e.g. convenience store)",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/storeSize/answers/small",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.SMALL_SHOP_RETAILER,
        nextQuestion: null,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Different stores have different sizes",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/storeSize/answers/mixed",
        }),
        endMileTypeDecisionTreeResponse:
          EndMileTypeDecisionTreeResponse.MIXED_SHOP_RETAILER,
        nextQuestion: null,
      },
    ],
  };

  const questionProductSaleType: Question = {
    text: intl.formatMessage({
      defaultMessage: "How do your products get to your customers?",
      id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/productSaleType",
    }),
    answers: [
      {
        text: intl.formatMessage({
          defaultMessage: "Delivery",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/productSaleType/answers/delivery",
        }),
        nextQuestion: questionDeliveryType,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Consumed on site or nearby",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/productSaleType/answers/consumedAtSite",
        }),
        nextQuestion: questionServingMethod,
      },
      {
        text: intl.formatMessage({
          defaultMessage: "Purchased in a store",
          id: "components/account/sign-up/EndMileTypeQuestionsForm:questions/productSaleType/answers/purchasedInStore",
        }),
        nextQuestion: questionStoreSize,
      },
    ],
  };

  const [currentQuestion, setCurrentQuestion] = useState<Question>(
    questionProductSaleType
  );
  const [selectedAnswer, setSelectedAnswer] = useState<Answer | null>(null);
  const [hoverValue, setHoverValue] = useState<Answer | null>(null);

  function handleNext() {
    if (selectedAnswer === null) {
      throw new Error("no answer has been selected");
    }
    trackSignUpEndMileTypeQuestionAnswered({
      question: currentQuestion.text,
      answer: selectedAnswer.text,
    });
    if (selectedAnswer.nextQuestion !== null) {
      setCurrentQuestion(selectedAnswer.nextQuestion);
      setSelectedAnswer(null);
    } else {
      onComplete(selectedAnswer.endMileTypeDecisionTreeResponse);
    }
  }

  return (
    <>
      <div className="form-group">
        <label className="medium-font">
          {currentQuestion.text}
          {currentQuestion !== questionStoreSize && (
            <QuestionMarkTooltipOverlay
              id="components/account/sign-up/EndMileTypeQuestionsForm:questionHelpTooltip"
              withMargin
            >
              <FormattedMessage
                id="components/account/sign-up/EndMileTypeQuestionsForm:questionHelp"
                defaultMessage="Choose the most used method."
              />
            </QuestionMarkTooltipOverlay>
          )}
        </label>
        <div>
          <RadioButtons
            hoverValue={hoverValue}
            onChange={(answer) => setSelectedAnswer(answer)}
            optionKey={(answer: Answer) =>
              currentQuestion.answers.indexOf(answer).toString()
            }
            options={currentQuestion.answers}
            setHoverLabel={setHoverValue}
            renderOptionLabel={(answer: Answer) => answer.text}
            value={selectedAnswer}
          />
        </div>
      </div>
      <div className="form-group">
        <PrimaryButton
          disabled={selectedAnswer == null}
          onClick={() => {
            handleNext();
          }}
        >
          <FormattedMessage
            id="components/account/sign-up/EndMileTypeQuestionsForm:next"
            defaultMessage="Next"
          />
        </PrimaryButton>
      </div>
    </>
  );
}
