import { useState } from "react";

import { FunctionalUnit } from "../../domain/functionalUnits";
import FunctionalUnitDropdown from "./FunctionalUnitDropdown";

const stories = {
  name: "FunctionalUnitDropdown",
  render: () => <Story />,
};

function Story() {
  const [functionalUnit, setFunctionalUnit] = useState<FunctionalUnit>(
    FunctionalUnit.KG
  );
  return (
    <FunctionalUnitDropdown
      selectedFunctionalUnit={functionalUnit}
      onChange={setFunctionalUnit}
    />
  );
}

export default stories;
