import { SystemBoundary } from "../../__generated__/globalTypes";
import SystemBoundaryDisplay from "./SystemBoundaryDisplay";

const stories = {
  name: "SystemBoundaryDisplay",
  render: () => (
    <SystemBoundaryDisplay
      className="m-4"
      systemBoundary={SystemBoundary.CRADLE_TO_GRAVE}
    />
  ),
};

export default stories;
