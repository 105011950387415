import { Category, ProcuredItem, Supplier } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import CategoriesTable from "./CategoriesTable";
import ProcuredItemsTable from "./ProcuredItemsTable";
import ReportContent from "./ReportContent";

const categoriesDummyData: Array<Category> = [...Array(100).keys()].map((i) => {
  return {
    name: `Category ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const itemsDummyData: Array<ProcuredItem> = [...Array(100).keys()].map((i) => {
  return {
    categoryName: "Category",
    ghg: 1,
    landUse: 2,
    internalId: i.toString(),
    name: `Item ${i}`,
    massKg: 2,
    quantity: 3,
    spend: "4.50",
    unit: "kg",
    waterUse: 3,
  };
});

const suppliersDummyData: Array<Supplier> = [...Array(100).keys()].map((i) => {
  return {
    name: `Supplier ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const stories = {
  name: "Reports",
  children: [
    {
      name: "ReportContent",
      render: () => (
        <ReportContent
          impactCategory={ImpactCategory.GHG}
          categories={categoriesDummyData}
          foodstepsCategories={categoriesDummyData}
          procuredItems={itemsDummyData}
          suppliers={suppliersDummyData}
          totalImpact={2000}
          totalSpend={"1000"}
        />
      ),
    },
    {
      name: "CategoriesTable",
      render: () => (
        <CategoriesTable
          data={categoriesDummyData}
          categoryColumnLabel={"Category"}
          impactCategory={ImpactCategory.GHG}
          unspecifiedCategoryLabel="Unspecified"
        />
      ),
    },
    {
      name: "ProcuredItemsTable",
      render: () => (
        <ProcuredItemsTable
          data={itemsDummyData}
          impactCategory={ImpactCategory.GHG}
        />
      ),
    },
  ],
};

export default stories;
