import VectorProps from "./VectorProps";

export default function IngredientsNeedAttention(props: VectorProps) {
  const { className = "", width } = props;

  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2536_2650)">
        <path
          d="M36.83 8H18.17C16.9715 8 16 8.97154 16 10.17V36.83C16 38.0285 16.9715 39 18.17 39H36.83C38.0285 39 39 38.0285 39 36.83V10.17C39 8.97154 38.0285 8 36.83 8Z"
          fill="#D3FFCC"
        />
        <path
          d="M36.8299 39.8H18.1599C16.5199 39.8 15.1899 38.47 15.1899 36.83V10.17C15.1899 8.53001 16.5199 7.20001 18.1599 7.20001H36.8299C38.4699 7.20001 39.7999 8.53001 39.7999 10.17V36.84C39.7999 38.48 38.4699 39.81 36.8299 39.81V39.8ZM18.1699 8.80001C17.4199 8.80001 16.7999 9.41001 16.7999 10.17V36.84C16.7999 37.59 17.4099 38.21 18.1699 38.21H36.8399C37.5899 38.21 38.2099 37.6 38.2099 36.84V10.17C38.2099 9.42001 37.5999 8.80001 36.8399 8.80001H18.1699Z"
          fill="black"
        />
        <path d="M37.25 4H17.75V8H37.25V4Z" fill="#00A98E" />
        <path
          d="M37.25 8.80001H17.75C17.31 8.80001 16.95 8.44001 16.95 8.00001V4.00001C16.95 3.56001 17.31 3.20001 17.75 3.20001H37.25C37.69 3.20001 38.0499 3.56001 38.0499 4.00001V8.00001C38.0499 8.44001 37.69 8.80001 37.25 8.80001ZM18.5499 7.20001H36.45V4.80001H18.5499V7.20001Z"
          fill="black"
        />
        <path
          d="M21.5 8.80001C21.06 8.80001 20.7 8.44001 20.7 8.00001V4.00001C20.7 3.56001 21.06 3.20001 21.5 3.20001C21.94 3.20001 22.2999 3.56001 22.2999 4.00001V8.00001C22.2999 8.44001 21.94 8.80001 21.5 8.80001Z"
          fill="black"
        />
        <path
          d="M25.5 8.80001C25.06 8.80001 24.7 8.44001 24.7 8.00001V4.00001C24.7 3.56001 25.06 3.20001 25.5 3.20001C25.94 3.20001 26.2999 3.56001 26.2999 4.00001V8.00001C26.2999 8.44001 25.94 8.80001 25.5 8.80001Z"
          fill="black"
        />
        <path
          d="M29.5 8.80001C29.06 8.80001 28.7 8.44001 28.7 8.00001V4.00001C28.7 3.56001 29.06 3.20001 29.5 3.20001C29.94 3.20001 30.2999 3.56001 30.2999 4.00001V8.00001C30.2999 8.44001 29.94 8.80001 29.5 8.80001Z"
          fill="black"
        />
        <path
          d="M33.5 8.80001C33.06 8.80001 32.7 8.44001 32.7 8.00001V4.00001C32.7 3.56001 33.06 3.20001 33.5 3.20001C33.94 3.20001 34.2999 3.56001 34.2999 4.00001V8.00001C34.2999 8.44001 33.94 8.80001 33.5 8.80001Z"
          fill="black"
        />
        <path d="M39 17.5H16V28.5H39V17.5Z" fill="#00695B" />
        <path
          d="M39 29.3H16C15.56 29.3 15.2 28.94 15.2 28.5V17.5C15.2 17.06 15.56 16.7 16 16.7H39C39.44 16.7 39.7999 17.06 39.7999 17.5V28.5C39.7999 28.94 39.44 29.3 39 29.3ZM16.8 27.7H38.2V18.3H16.8V27.7Z"
          fill="black"
        />
        <path
          d="M3.69991 37.87L11.1399 34.93L11.7399 31.89L4.43991 26.61C3.93991 26.25 3.30991 26.83 3.62991 27.36L5.26991 30.05C5.48991 30.41 5.24991 30.88 4.81991 30.91L1.54991 31.11C0.959914 31.15 0.819914 31.96 1.35991 32.19L4.59991 33.58C4.92991 33.72 5.04991 34.13 4.83991 34.42L3.01991 37.02C2.69991 37.48 3.16991 38.07 3.68991 37.87H3.69991Z"
          fill="#D3FFCC"
        />
        <path
          d="M3.48998 38.71C3.07998 38.71 2.68998 38.53 2.41998 38.19C2.03998 37.71 2.01998 37.06 2.36998 36.56L4.01998 34.19L1.03998 32.92C0.449975 32.67 0.119975 32.07 0.229975 31.43C0.339975 30.79 0.849975 30.34 1.48998 30.3L4.36998 30.12L2.93998 27.77C2.59998 27.21 2.69998 26.5 3.17998 26.06C3.65998 25.61 4.36998 25.57 4.89998 25.96L12.2 31.24C12.45 31.42 12.58 31.74 12.52 32.04L11.92 35.08C11.87 35.35 11.68 35.57 11.43 35.67L3.98998 38.61C3.81998 38.68 3.64998 38.71 3.47998 38.71H3.48998ZM2.59998 31.85L4.92998 32.85C5.30998 33.01 5.58997 33.33 5.69998 33.73C5.80998 34.13 5.73998 34.55 5.50998 34.89L4.13998 36.85L10.44 34.36L10.86 32.25L4.92998 27.96L5.95998 29.65C6.20998 30.06 6.22998 30.56 5.99998 30.99C5.77998 31.42 5.35998 31.69 4.87998 31.72L2.60998 31.86L2.59998 31.85Z"
          fill="black"
        />
        <path
          d="M34.3 38.89L13.43 38.91C10.74 38.84 8.68997 36.45 9.03997 33.78L9.25997 32.13C9.60997 29.44 12.23 27.66 14.86 28.31L34.86 34.75C37.29 35.36 36.8 38.95 34.3 38.89Z"
          fill="#00A98E"
        />
        <path
          d="M13.4299 39.71C11.8999 39.67 10.4699 38.99 9.48995 37.84C8.50995 36.69 8.05995 35.17 8.24995 33.68L8.46995 32.03C8.66995 30.52 9.49995 29.17 10.7599 28.31C12.0199 27.45 13.5899 27.17 15.0599 27.54L35.1099 33.99C36.5399 34.35 37.4399 35.66 37.2299 37.18C37.0199 38.69 35.8199 39.72 34.2899 39.69L13.4299 39.71ZM13.7699 28.98C13.0399 28.98 12.2899 29.2 11.6599 29.63C10.7799 30.23 10.1899 31.18 10.0599 32.24L9.83995 33.89C9.69995 34.94 10.0199 36 10.7099 36.81C11.3999 37.61 12.3999 38.09 13.4599 38.12L34.3099 38.1C34.3099 38.1 34.3199 38.1 34.3299 38.1C35.1899 38.12 35.5799 37.51 35.6499 36.98C35.7199 36.45 35.5099 35.75 34.6799 35.54L14.6299 29.09C14.3499 29.02 14.0699 28.99 13.7799 28.99L13.7699 28.98Z"
          fill="black"
        />
        <path
          d="M12.1199 2L1.26991 20.79C0.499906 22.12 1.45991 23.79 2.99991 23.79H24.6899C26.2299 23.79 27.1899 22.12 26.4199 20.79L15.5799 2C14.8099 0.670002 12.8899 0.670002 12.1199 2Z"
          fill="#FBEC00"
        />
        <path
          d="M24.7 24.59H3.00001C1.99001 24.59 1.08001 24.07 0.58001 23.19C0.0700098 22.31 0.0700098 21.27 0.58001 20.39L11.42 1.60001C11.93 0.720012 12.83 0.200012 13.84 0.200012C14.85 0.200012 15.76 0.720012 16.26 1.60001L27.11 20.39C27.62 21.27 27.62 22.31 27.11 23.19C26.6 24.07 25.7 24.59 24.69 24.59H24.7ZM12.81 2.40001L1.96001 21.19C1.74001 21.57 1.74001 22.01 1.96001 22.39C2.18001 22.77 2.57001 22.99 3.00001 22.99H24.69C25.12 22.99 25.51 22.77 25.73 22.39C25.95 22.01 25.95 21.57 25.73 21.19L14.89 2.40001C14.46 1.65001 13.25 1.65001 12.81 2.40001Z"
          fill="black"
        />
        <path
          d="M12.3 18.94C12.3 18.11 13 17.46 13.85 17.46C14.7 17.46 15.4 18.11 15.4 18.94C15.4 19.77 14.7 20.4 13.85 20.4C13 20.4 12.3 19.75 12.3 18.94ZM12.49 8.10001H15.24L14.87 16.46H12.87L12.5 8.10001H12.49Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2536_2650">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
