import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useTracking } from "../../../tracking";
import * as comparators from "../../../util/comparators";
import sort from "../../../util/sort";
import AddCollectionModal from "../../collections/AddCollectionModal";
import { useHasRecipeCollectionPermissionAdd } from "../../permissions";
import { AddButton } from "../../utils/Button";
import { MultiSelect } from "../../utils/Select";
import { CollectionSelect_Collection as Collection } from "./CollectionSelect.graphql";

interface CollectionSelectProps {
  collections: Array<Collection>;
  id?: string;
  onChange: (selected: ReadonlyArray<Collection>) => void;
  placeholder?: string;
  refreshCollections: () => void;
  value: Array<Collection>;
}

export default function CollectionSelect(props: CollectionSelectProps) {
  const { collections, id, onChange, placeholder, refreshCollections, value } =
    props;

  const hasRecipeCollectionPermissionAdd =
    useHasRecipeCollectionPermissionAdd();
  const [showModal, setShowModal] = useState(false);

  const handleChange = (values: ReadonlyArray<Collection>) => {
    onChange(values);
  };

  const sortedCollections = sort(
    collections.filter((collection) => collection.viewerHasPermissionUpdate),
    comparators.map(
      (location) => location.name,
      comparators.stringSensitivityBase
    )
  );

  const { trackCollectionCreationStarted } = useTracking();

  return (
    <>
      <MultiSelect<Collection>
        id={id}
        onChange={(value) => handleChange(value)}
        optionKey={(collection) => collection.id.toString()}
        options={sortedCollections}
        placeholder={placeholder}
        renderOption={(collection) => collection.name}
        value={value}
      />

      {hasRecipeCollectionPermissionAdd && (
        <AddButton
          className="mt-2"
          onClick={() => {
            trackCollectionCreationStarted();
            setShowModal(true);
          }}
        >
          <FormattedMessage
            id="components/recipes/RecipeEditor/CollectionSelect:newCollection"
            defaultMessage="Create A New Collection"
          />
        </AddButton>
      )}

      <AddCollectionModal
        onCreated={(newValue: Collection) => onChange([newValue, ...value])}
        onHide={() => setShowModal(false)}
        refreshCollections={refreshCollections}
        show={showModal}
      />
    </>
  );
}

CollectionSelect.fragments = {
  collection: gql`
    fragment CollectionSelect_Collection on RecipeCollection {
      id
      name
      viewerHasPermissionUpdate
    }
  `,
};
