import classNames from "classnames";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import React, { ReactElement, useRef } from "react";
import { useIntl } from "react-intl";
import ReactSelect, {
  components as reactSelectComponents,
  IndicatorProps,
  OptionProps,
} from "react-select";
import AsyncSelect from "react-select/async";
import { ControlProps } from "react-select/src/components/Control";
import { MenuPlacement } from "react-select/src/types";
import { useMemoOne } from "use-memo-one";

import assertNever from "../../util/assertNever";
import RotatingChevron from "./RotatingChevron";
import { DownUpArrow } from "./Vectors";

interface BaseSelectProps<T> {
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  iconForOption?: (option: T) => React.ReactNode;
  id?: string;
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  optionKey: (option: T) => string;
  options: Array<T> | ((input: string) => Promise<Array<T>>);
  placeholder?: React.ReactNode;
  renderOption: (option: T) => string;
  styles?: Record<string, any>;
  menuIsOpen?: boolean;
  setMenuIsOpen?: (isOpen: boolean) => void;
}

let nextRequestId = 1;

function useBaseProps<T>(props: BaseSelectProps<T>) {
  const {
    className,
    disabled = false,
    hasError = false,
    id,
    noOptionsMessage,
    optionKey,
    options,
    placeholder,
    renderOption,
    styles,
    menuIsOpen,
    setMenuIsOpen,
  } = props;

  const currentRequestId = useRef<number | null>(null);
  const intl = useIntl();

  const loadOptions = useMemoOne(
    () =>
      isFunction(options)
        ? async (input: string) => {
            const requestId = nextRequestId++;
            currentRequestId.current = requestId;
            await sleep(500);
            if (currentRequestId.current === requestId) {
              return options(input);
            } else {
              throw new Error("outdated request");
            }
          }
        : undefined,
    [options]
  );

  return {
    className: classNames("react-select-container", className, {
      "is-invalid": hasError,
    }),
    classNamePrefix: "react-select",
    defaultOptions: loadOptions === undefined ? undefined : true,
    getOptionLabel: renderOption,
    getOptionValue: optionKey,
    inputId: id,
    isDisabled: disabled,
    loadOptions,
    menuPlacement: "auto" as const,
    noOptionsMessage,
    options: isArray(options) ? options : undefined,
    placeholder:
      placeholder ??
      intl.formatMessage({
        id: "components/utils/Select:defaultPlaceholder",
        defaultMessage: "Select...",
      }),
    styles,
    menuIsOpen,
    setMenuIsOpen,
    onMenuOpen: () => setMenuIsOpen?.(true),
    onMenuClose: () => setMenuIsOpen?.(false),
  };
}

interface SingleSelectProps<T> extends BaseSelectProps<T> {
  hideNoOptionsMessageIfSearchIsEmpty?: boolean;
  isClearable?: boolean;
  onChange: (value: T | null) => void;
  value: T | null;
  dropdownArrow?: "normal" | "upDown" | "none";
}

export default function SingleSelect<T extends {}>(
  props: SingleSelectProps<T>
) {
  const {
    dropdownArrow = "normal",
    iconForOption,
    hideNoOptionsMessageIfSearchIsEmpty = false,
    isClearable = true,
    onChange,
    options,
    renderOption,
    value,
  } = props;

  const intl = useIntl();

  const IconOption = (props: OptionProps<T, false>) => {
    return (
      <reactSelectComponents.Option
        className="justify-content-between"
        {...props}
      >
        {renderOption(props.data)}
        {iconForOption !== undefined ? iconForOption(props.data) : null}
      </reactSelectComponents.Option>
    );
  };

  const components = () => {
    if (dropdownArrow === "upDown") {
      return {
        DropdownIndicator: () => (
          <DownUpArrow className="mr-2 ml-1" width={"0.75rem"} />
        ),
        IndicatorSeparator: () => null,
        Option: IconOption,
      };
    } else if (dropdownArrow === "none") {
      return {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: IconOption,
      };
    } else if (dropdownArrow === "normal") {
      return {
        DropdownIndicator: ChevronDropdownIndicator,
        Option: IconOption,
      };
    } else {
      assertNever(dropdownArrow, "invalid dropdown arrow setting");
    }
  };

  const baseProps = useBaseProps(props);

  const noOptionsMessage = (e: { inputValue: string }) =>
    hideNoOptionsMessageIfSearchIsEmpty && !e.inputValue
      ? null
      : intl.formatMessage({
          id: "components/utils/Select:noOptionsMessage",
          defaultMessage: "No options",
        });
  const selectProps = {
    ...baseProps,
    components: components(),
    isClearable,
    noOptionsMessage,
    onChange: (value: T | null) => onChange(value),
    // Wrapping the value in an array works around an issue where the value is not shown if it's a
    // string (as opposed to an object)
    value: [value] as unknown as T,
  };
  return isFunction(options) ? (
    <AsyncSelect<T> {...selectProps} />
  ) : (
    <ReactSelect<T> {...selectProps} />
  );
}

interface MultiSelectProps<T> extends BaseSelectProps<T> {
  hideSelectedOptions?: boolean;
  menuPlacement?: MenuPlacement;
  onChange: (value: ReadonlyArray<T>) => void;
  value: ReadonlyArray<T>;
  components?: Record<string, any>;
}

export function MultiSelect<T extends {}>(props: MultiSelectProps<T>) {
  const {
    hideSelectedOptions = true,
    menuPlacement,
    onChange,
    options,
    value,
    components,
  } = props;

  const baseProps = useBaseProps(props);

  const selectProps = {
    ...baseProps,
    closeMenuOnSelect: false,
    isClearable: true,
    isMulti: true as const,
    onChange: (values: ReadonlyArray<T>) => onChange(values),
    value,
    components: { ...components, DropdownIndicator: ChevronDropdownIndicator },
    hideSelectedOptions,
    menuPlacement,
  };

  return isFunction(options) ? (
    <AsyncSelect<T, true> {...selectProps} />
  ) : (
    <ReactSelect<T, true> {...selectProps} />
  );
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(() => resolve(undefined), ms));
}

export interface Option<T> {
  value: T;
  label: string;
}

export interface Group<T> {
  label: string;
  options: Array<Option<T>>;
}

const formatGroupLabel = (group: Group<any>) => (
  <span className="GroupedSelect__group_label">{group.label}</span>
);

const renderOptionLabel = (option: Option<any>) => option.label;

interface GroupSelectProps<T> {
  groups: Array<Group<T>>;
  hasError?: boolean;
  icon?: ReactElement;
  onChange: (value: Option<T> | null) => void;
  placeholder?: ReactElement;
  value: Option<T> | null;
}

export function GroupedSelect<T extends {}>(props: GroupSelectProps<T>) {
  const { groups, hasError, icon, onChange, placeholder, value } = props;

  function Control({
    children,
    ...props
  }: ControlProps<Option<T>, false, Group<T>>) {
    return (
      <reactSelectComponents.Control {...props}>
        <span>{icon ? <div className="m-auto pl-3">{icon}</div> : null}</span>
        {children}
      </reactSelectComponents.Control>
    );
  }

  return (
    <ReactSelect<Option<T>, false, Group<T>>
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      className={classNames("react-select-container", {
        "is-invalid": hasError,
      })}
      classNamePrefix={"react-select"}
      placeholder={placeholder}
      options={groups}
      onChange={onChange}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={renderOptionLabel}
      value={value}
    />
  );
}

const ChevronDropdownIndicator = (props: IndicatorProps<any, boolean>) => {
  return (
    <reactSelectComponents.DropdownIndicator {...props}>
      <RotatingChevron expanded={props.selectProps.menuIsOpen!} />
    </reactSelectComponents.DropdownIndicator>
  );
};
