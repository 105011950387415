import { useState } from "react";
import { FormattedMessage } from "react-intl";

import ActionModal from "./ActionModal";
import { PrimaryButton } from "./Button";
import { InformationOutlined } from "./Vectors";
import "./InformationModalTooltip.css";

interface InformationModalTooltipProps {
  body: React.ReactNode;
  size?: "lg" | "sm" | "xl";
  title: React.ReactNode;
}

export default function InformationModalTooltip(
  props: InformationModalTooltipProps
) {
  const { body, size, title } = props;

  const [showInformationModal, setShowInformationModal] =
    useState<boolean>(false);
  return (
    <>
      <InformationOutlined
        handleClick={() => setShowInformationModal(true)}
        className="action-link"
        width={16}
      />
      <ActionModal show={showInformationModal} size={size} title={title}>
        <ActionModal.Body>
          <div className="InformationModalTooltip_Body">{body}</div>
          <PrimaryButton onClick={() => setShowInformationModal(false)}>
            <FormattedMessage
              id="components/utils/InformationModalTooltip:gotIt"
              defaultMessage="Got it"
            />
          </PrimaryButton>
        </ActionModal.Body>
      </ActionModal>
    </>
  );
}
