import ButterflyChart from "./ButterflyChart";

const stories = {
  name: "Graphs",
  children: [
    {
      name: "ButterflyChart",
      children: [
        {
          name: "Normal",
          render: () => (
            <ButterflyChart
              data={[
                {
                  value1: 1,
                  value2: { type: "success", value: 2.25 },
                  key: "Test 1",
                  pendingMessage: "Pending...",
                },
                {
                  value1: 5,
                  value2: { type: "none" },
                  key: "Test 2",
                  pendingMessage: "Pending...",
                },
                {
                  value1: 5,
                  value2: { type: "pending" },
                  key: "Test 3",
                  pendingMessage: "Pending...",
                },
                {
                  value1: null,
                  value2: { type: "success", value: 2.25 },
                  key: "Test 4",
                  pendingMessage: "Pending...",
                },
                {
                  value1: 1.0,
                  value2: { type: "unavailable" },
                  key: "Test 5",
                  pendingMessage: "Pending...",
                },
              ]}
              header={{
                left: <h4>This thing</h4>,
                center: <h4>vs.</h4>,
                right: <h4>That thing</h4>,
              }}
              unavailableValueMessage={"Unavailable"}
            />
          ),
        },
        {
          name: "Loading",
          render: () => (
            <ButterflyChart
              data={[
                {
                  value1: 1,
                  value2: { type: "loading" },
                  key: "Loading",
                  pendingMessage: "Pending...",
                },
              ]}
              header={{
                left: <h4>This thing</h4>,
                center: <h4>vs.</h4>,
                right: <h4>That thing</h4>,
              }}
              unavailableValueMessage={"Unavailable"}
            />
          ),
        },
      ],
    },
  ],
};

export default stories;
