export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: string;
};

export type AddOrganization = {
  __typename?: "AddOrganization";
  success: Scalars["Boolean"];
};

export type AddOrganizationInput = {
  endMileTypeId?: InputMaybe<Scalars["Int"]>;
  endOfLifeType?: InputMaybe<EndOfLifeType>;
  name: Scalars["String"];
  parentId?: InputMaybe<Scalars["UUID"]>;
  parentProjectOptionsId?: InputMaybe<Scalars["Int"]>;
  postPreparationStorageScenarioId: Scalars["Int"];
};

export type AddOrganizationMembership = {
  __typename?: "AddOrganizationMembership";
  success: Scalars["Boolean"];
};

export type AddOrganizationMembershipInput = {
  organizationId: Scalars["UUID"];
  userId: Scalars["Int"];
};

export type AddPackagingComponentV2 = {
  __typename?: "AddPackagingComponentV2";
  success: Scalars["Boolean"];
};

export type AddPackagingComponentV2Input = {
  materials: Array<PackagingComponentMaterialV2Input>;
  name: Scalars["String"];
  ownerOrganizationId: Scalars["UUID"];
};

export type AddRecipe = {
  __typename?: "AddRecipe";
  recipe: Recipe;
  success: Scalars["Boolean"];
};

export type AddRecipeCollection = {
  __typename?: "AddRecipeCollection";
  recipeCollection: RecipeCollection;
  success: Scalars["Boolean"];
};

export type AddRecipeCollectionInput = {
  name: Scalars["String"];
  ownerOrganizationId: Scalars["UUID"];
};

export type AddRecipeInput = {
  clientId?: InputMaybe<Scalars["UUID"]>;
  coProducts: Array<RecipeCoProductInput>;
  code?: InputMaybe<Scalars["String"]>;
  collectionIds: Array<Scalars["Int"]>;
  cookingPenalties: Array<CookingPenaltyInput>;
  endMileTypeId?: InputMaybe<Scalars["Int"]>;
  hasCompleteCookingPenalties: Scalars["Boolean"];
  hasCompletePostPreparationStoragePenalties: Scalars["Boolean"];
  ingredients: Array<IngredientInput>;
  isCooked: Scalars["Boolean"];
  isHotDrink: Scalars["Boolean"];
  isStored: Scalars["Boolean"];
  name: Scalars["String"];
  numServings: Scalars["Float"];
  ownerOrganizationId: Scalars["UUID"];
  packagingComponents?: InputMaybe<Array<RecipePackagingComponentInput>>;
  packagingComponentsV2: Array<RecipePackagingComponentV2Input>;
  postPreparationStoragePenalties?: InputMaybe<
    Array<PostPreparationStoragePenaltyInput>
  >;
  productMassQuantity?: InputMaybe<Scalars["Float"]>;
  productMassUnit?: InputMaybe<Scalars["String"]>;
  productProcessingMassQuantity?: InputMaybe<Scalars["Float"]>;
  productProcessingMassUnit?: InputMaybe<Scalars["String"]>;
  servingsPerCookingBatch?: InputMaybe<Scalars["Float"]>;
  unitProcess?: InputMaybe<UnitProcessInput>;
};

export type AddRecipesToCollections = {
  __typename?: "AddRecipesToCollections";
  success: Scalars["Boolean"];
};

export type AddRecipesToCollectionsInput = {
  collectionIds: Array<Scalars["Int"]>;
  recipeIds: Array<Scalars["Int"]>;
};

export type AddUser = {
  __typename?: "AddUser";
  success: Scalars["Boolean"];
};

export type AddUserInput = {
  administeredByOrganizationId: Scalars["UUID"];
  emailAddress: Scalars["String"];
  emailMarketingConsent: Scalars["Boolean"];
  firstName: Scalars["String"];
  isExternalUser: Scalars["Boolean"];
  isReadonly: Scalars["Boolean"];
  lastName: Scalars["String"];
};

export enum BillingInterval {
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export type CachedRecipeImpact = {
  __typename?: "CachedRecipeImpact";
  ghgPerKg?: Maybe<Scalars["Float"]>;
  ghgPerServing?: Maybe<Scalars["Float"]>;
  impactRating?: Maybe<ImpactRating>;
  landUsePerKg?: Maybe<Scalars["Float"]>;
  waterUsePerKg?: Maybe<Scalars["Float"]>;
};

export type CachedRecipeIngredientImpact = {
  __typename?: "CachedRecipeIngredientImpact";
  weightKgPerRecipe?: Maybe<Scalars["Float"]>;
  weightKgPerServing?: Maybe<Scalars["Float"]>;
};

export enum CannotCreateSubscriptionErrorKind {
  CUSTOMER_TAX_LOCATION_INVALID = "CUSTOMER_TAX_LOCATION_INVALID",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export type CookingImpact = {
  __typename?: "CookingImpact";
  id: Scalars["Int"];
  type: Scalars["String"];
  unit: CookingImpactUnit;
  unitLabel: CookingImpactUnitLabel;
};

export type CookingImpactConnection = {
  __typename?: "CookingImpactConnection";
  edges: Array<CookingImpactEdge>;
  pageInfo: PageInfo;
};

export type CookingImpactEdge = {
  __typename?: "CookingImpactEdge";
  node: CookingImpact;
};

export enum CookingImpactUnit {
  MINUTE = "MINUTE",
  USE = "USE",
}

export enum CookingImpactUnitLabel {
  mins = "mins",
  uses = "uses",
}

export type CookingPenalty = {
  __typename?: "CookingPenalty";
  cookingImpact: CookingImpact;
  cookingImpactId: Scalars["Int"];
  id: Scalars["Int"];
  quantity: Scalars["Float"];
};

export type CookingPenaltyInput = {
  cookingImpactId: Scalars["Int"];
  id?: InputMaybe<Scalars["Int"]>;
  quantity: Scalars["Float"];
};

export type CopyRecipes = {
  __typename?: "CopyRecipes";
  recipes: Array<Recipe>;
  success: Scalars["Boolean"];
};

export type CopyRecipesInput = {
  recipeIds: Array<Scalars["Int"]>;
  shallowCopyPackaging: Scalars["Boolean"];
  shallowCopyRecipes: Scalars["Boolean"];
  toCollectionIds: Array<Scalars["Int"]>;
  toOrganizationId: Scalars["UUID"];
  updateExistingComponents: Scalars["Boolean"];
  updateExistingRecipes: Scalars["Boolean"];
};

export enum CountryCode {
  UNITED_KINGDOM = "UNITED_KINGDOM",
  UNITED_STATES_OF_AMERICA = "UNITED_STATES_OF_AMERICA",
}

export type CreateSubscription = {
  __typename?: "CreateSubscription";
  errorKind?: Maybe<CannotCreateSubscriptionErrorKind>;
  paymentRequest?: Maybe<PaymentRequest>;
};

export type CreateSubscriptionInput = {
  address: StripeAddressInput;
  billingInterval: BillingInterval;
  currency: Currency;
  organizationId: Scalars["UUID"];
  promotionId?: InputMaybe<Scalars["String"]>;
  recipeAllowance: RecipeAllowance;
  tier: Tier;
};

export enum Currency {
  GBP = "GBP",
  USD = "USD",
}

export type DataQualityScore = {
  __typename?: "DataQualityScore";
  ghg: Scalars["Float"];
  land_use: Scalars["Float"];
  water_use: Scalars["Float"];
};

export type DeletePackagingComponentV2 = {
  __typename?: "DeletePackagingComponentV2";
  success: Scalars["Boolean"];
};

export type DeletePackagingComponentV2Input = {
  id: Scalars["UUID"];
};

export type DeleteRecipe = {
  __typename?: "DeleteRecipe";
  success: Scalars["Boolean"];
};

export type DeleteRecipeCollection = {
  __typename?: "DeleteRecipeCollection";
  success: Scalars["Boolean"];
};

export type DeleteRecipeCollectionInput = {
  id: Scalars["Int"];
};

export type DeleteRecipeInput = {
  id: Scalars["Int"];
};

export type DeleteRecipes = {
  __typename?: "DeleteRecipes";
  success: Scalars["Boolean"];
};

export type DeleteRecipesInput = {
  filter?: InputMaybe<RecipeFilter>;
};

export type DeleteWeightedLocationOption = {
  __typename?: "DeleteWeightedLocationOption";
  success: Scalars["Boolean"];
};

export type DeleteWeightedLocationOptionInput = {
  foodClassId: Scalars["Int"];
  organizationId: Scalars["UUID"];
};

export enum DistanceUnit {
  KM = "KM",
  MILES = "MILES",
}

export enum EffectType {
  GHG_PER_KG = "GHG_PER_KG",
  GHG_PER_SERVING = "GHG_PER_SERVING",
}

export type EffectTypeImpactRatingInfo = {
  __typename?: "EffectTypeImpactRatingInfo";
  ghgLowerBound?: Maybe<Scalars["Float"]>;
  impactRating: ImpactRating;
  label: Scalars["String"];
};

export type Effects = {
  __typename?: "Effects";
  ghgPerKg?: Maybe<Scalars["Float"]>;
  ghgPerRecipe?: Maybe<Scalars["Float"]>;
  ghgPerRootRecipeServing?: Maybe<Scalars["Float"]>;
  ghgPerServing?: Maybe<Scalars["Float"]>;
  landUsePerKg?: Maybe<Scalars["Float"]>;
  landUsePerRecipe?: Maybe<Scalars["Float"]>;
  landUsePerRootRecipeServing?: Maybe<Scalars["Float"]>;
  landUsePerServing?: Maybe<Scalars["Float"]>;
  stages?: Maybe<Array<StageImpact>>;
  waterUsePerKg?: Maybe<Scalars["Float"]>;
  waterUsePerRootRecipeServing?: Maybe<Scalars["Float"]>;
};

export type EndMileType = {
  __typename?: "EndMileType";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type EndMileTypeConnection = {
  __typename?: "EndMileTypeConnection";
  edges: Array<EndMileTypeEdge>;
  pageInfo: PageInfo;
};

export type EndMileTypeEdge = {
  __typename?: "EndMileTypeEdge";
  node: EndMileType;
};

export enum EndOfLifeType {
  FOOD_CONSUMED_AT_HOME = "FOOD_CONSUMED_AT_HOME",
  FOOD_CONSUMED_AT_SITE = "FOOD_CONSUMED_AT_SITE",
}

export type EnergyUse = {
  amountKwh: Scalars["Float"];
  id?: InputMaybe<Scalars["Int"]>;
};

export type FoodClass = {
  __typename?: "FoodClass";
  id: Scalars["Int"];
  impact: FoodClassImpact;
  ingredients: Array<RecipeIngredient>;
  name: Scalars["String"];
  recipeCount: Scalars["Int"];
  supportsEach: Scalars["Boolean"];
  synonyms: Array<FoodClassSynonym>;
};

export type FoodClassimpactArgs = {
  fetchStaleImpacts: Scalars["Boolean"];
  organizationId: Scalars["UUID"];
};

export type FoodClassrecipeCountArgs = {
  organizationId: Scalars["UUID"];
};

export type FoodClassConnection = {
  __typename?: "FoodClassConnection";
  edges: Array<FoodClassEdge>;
  pageInfo: PageInfo;
};

export type FoodClassEdge = {
  __typename?: "FoodClassEdge";
  node: FoodClass;
};

export type FoodClassFilter = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  search?: InputMaybe<Scalars["String"]>;
  usedAsIngredientInOrganizationId?: InputMaybe<Scalars["UUID"]>;
};

export type FoodClassImpact = {
  __typename?: "FoodClassImpact";
  dataQualityScore?: Maybe<DataQualityScore>;
  ghgPerKg?: Maybe<Scalars["Float"]>;
  impactRating?: Maybe<ImpactRating>;
  isStale: Scalars["Boolean"];
  landUsePerKg?: Maybe<Scalars["Float"]>;
  waterUsePerKg?: Maybe<Scalars["Float"]>;
};

export type FoodClassLookupResult = {
  __typename?: "FoodClassLookupResult";
  foodClass?: Maybe<FoodClass>;
  name: Scalars["String"];
};

export enum FoodClassOrder {
  DQS_ASC = "DQS_ASC",
  DQS_DESC = "DQS_DESC",
  IMPACT_PER_KG_ASC = "IMPACT_PER_KG_ASC",
  IMPACT_PER_KG_DESC = "IMPACT_PER_KG_DESC",
  IMPACT_RATING_ASC = "IMPACT_RATING_ASC",
  IMPACT_RATING_DESC = "IMPACT_RATING_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  ORIGIN_ASC = "ORIGIN_ASC",
  ORIGIN_DESC = "ORIGIN_DESC",
  RECIPE_COUNT_ASC = "RECIPE_COUNT_ASC",
  RECIPE_COUNT_DESC = "RECIPE_COUNT_DESC",
}

export type FoodClassSynonym = {
  __typename?: "FoodClassSynonym";
  isDefaultForLocale: Scalars["Boolean"];
  locale: Scalars["String"];
  name: Scalars["String"];
};

export type ImpactCalculatorError = {
  __typename?: "ImpactCalculatorError";
  kind: ImpactCalculatorErrorKind;
  originalError: Scalars["String"];
};

export enum ImpactCalculatorErrorKind {
  COOKING_IMPACT_ESTIMATOR = "COOKING_IMPACT_ESTIMATOR",
  CYCLE_ERROR = "CYCLE_ERROR",
  DQS_AGGREGATION = "DQS_AGGREGATION",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  PACKAGING_IMPACT_NOT_FOUND = "PACKAGING_IMPACT_NOT_FOUND",
  PARENT_IS_TOO_GENERIC = "PARENT_IS_TOO_GENERIC",
  RECIPE_HAS_CIRCULAR_REFERENCE = "RECIPE_HAS_CIRCULAR_REFERENCE",
  RECIPE_HAS_INCOMPLETE_COOKING_PENALTIES = "RECIPE_HAS_INCOMPLETE_COOKING_PENALTIES",
  RECIPE_HAS_INCOMPLETE_POST_PREPARATION_STORAGE_PENALTIES = "RECIPE_HAS_INCOMPLETE_POST_PREPARATION_STORAGE_PENALTIES",
  RECIPE_INGREDIENT_HAS_NO_QUANTITY = "RECIPE_INGREDIENT_HAS_NO_QUANTITY",
  RECIPE_INGREDIENT_HAS_NO_UNIT = "RECIPE_INGREDIENT_HAS_NO_UNIT",
  RECIPE_INGREDIENT_LINKED_TO_UNVALIDATED_FOOD_CLASS = "RECIPE_INGREDIENT_LINKED_TO_UNVALIDATED_FOOD_CLASS",
  RECIPE_INGREDIENT_MISSING_SUB_RECIPE = "RECIPE_INGREDIENT_MISSING_SUB_RECIPE",
  RECIPE_INGREDIENT_MISSING_WEIGHT_OF_EACH = "RECIPE_INGREDIENT_MISSING_WEIGHT_OF_EACH",
  RECIPE_INGREDIENT_NOT_LINKED_TO_FOOD_CLASS = "RECIPE_INGREDIENT_NOT_LINKED_TO_FOOD_CLASS",
}

export enum ImpactCategory {
  GHG = "GHG",
  LAND_USE = "LAND_USE",
  WATER_USE = "WATER_USE",
}

export enum ImpactRating {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  VHIGH = "VHIGH",
  VLOW = "VLOW",
}

export type ImpactRatingInfoFilter = {
  effectTypes?: InputMaybe<Array<EffectType>>;
};

export type ImpactRatingInfos = {
  __typename?: "ImpactRatingInfos";
  effectType: EffectType;
  impactRatingInfo: Array<EffectTypeImpactRatingInfo>;
  label: Scalars["String"];
};

export enum ImpactUnit {
  CO2E = "CO2E",
  KG_PO43_MINUS_E_EUTROPHICATION = "KG_PO43_MINUS_E_EUTROPHICATION",
  KG_SO2E_ACIDIFICATION = "KG_SO2E_ACIDIFICATION",
  LITRES_WATER_USE = "LITRES_WATER_USE",
  M2_YEAR_LAND_USE = "M2_YEAR_LAND_USE",
}

export type IngredientInput = {
  foodClassId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  prePreparationStoragePenalties?: InputMaybe<
    Array<IngredientPrePreparationStoragePenaltyInput>
  >;
  quantity: Scalars["Float"];
  unit: Scalars["String"];
  unitProcess?: InputMaybe<IngredientInputUnitProcess>;
  useRecipeId?: InputMaybe<Scalars["Int"]>;
  useRecipeWithClientId?: InputMaybe<Scalars["UUID"]>;
};

export type IngredientInputUnitProcess = {
  processLoss?: InputMaybe<Scalars["Float"]>;
};

export type IngredientPrePreparationStoragePenaltyInput = {
  daysInStorage: Scalars["Float"];
  id?: InputMaybe<Scalars["Int"]>;
  storageMethodId: Scalars["Int"];
};

export type Invoice = {
  __typename?: "Invoice";
  currency: Currency;
  customerCountry: CountryCode;
  discountAmount: Scalars["Int"];
  items: Array<InvoiceItem>;
  recipeAllowanceProductId: Scalars["String"];
  taxAmount: Scalars["Int"];
  totalCost: Scalars["Int"];
};

export type InvoiceItem = {
  __typename?: "InvoiceItem";
  currency: Currency;
  productId: Scalars["String"];
  productName: Scalars["String"];
  quantity: Scalars["Int"];
  totalCost: Scalars["Int"];
};

export type LinkIngredientsToFoodClass = {
  __typename?: "LinkIngredientsToFoodClass";
  recipes: Array<Recipe>;
  success: Scalars["Boolean"];
};

export type LinkIngredientsToFoodClassInput = {
  foodClassId: Scalars["Int"];
  ingredientName: Scalars["String"];
};

export type Location = {
  __typename?: "Location";
  id: Scalars["Int"];
  name: Scalars["String"];
  type: Scalars["String"];
};

export type LocationConnection = {
  __typename?: "LocationConnection";
  edges: Array<LocationEdge>;
  pageInfo: PageInfo;
};

export type LocationEdge = {
  __typename?: "LocationEdge";
  node: Location;
};

export type LocationProportion = {
  __typename?: "LocationProportion";
  locationId: Scalars["Int"];
  proportion: Scalars["Float"];
};

export type LocationProportionInput = {
  locationId: Scalars["Int"];
  proportion: Scalars["Float"];
};

export type Mutation = {
  __typename?: "Mutation";
  addOrganization: AddOrganization;
  addOrganizationMembership: AddOrganizationMembership;
  addPackagingComponentV2: AddPackagingComponentV2;
  addRecipe: AddRecipe;
  addRecipeCollection: AddRecipeCollection;
  addRecipesToCollections: AddRecipesToCollections;
  addUser: AddUser;
  copyRecipes: CopyRecipes;
  createSubscription: CreateSubscription;
  deletePackagingComponentV2: DeletePackagingComponentV2;
  deleteRecipe: DeleteRecipe;
  deleteRecipeCollection: DeleteRecipeCollection;
  deleteRecipes: DeleteRecipes;
  deleteWeightedLocationOption: DeleteWeightedLocationOption;
  linkIngredientsToFoodClass: LinkIngredientsToFoodClass;
  removeRecipesFromCollections: RemoveRecipesFromCollections;
  sendUserMessage: SendUserMessage;
  updateOrganization: UpdateOrganization;
  updatePackagingComponentV2: UpdatePackagingComponentV2;
  updateRecipe: UpdateRecipe;
  updateRecipeCollection: UpdateRecipeCollection;
  updateUser: UpdateUser;
  updateWeightedLocationOption: UpdateWeightedLocationOption;
};

export type MutationaddOrganizationArgs = {
  input?: InputMaybe<AddOrganizationInput>;
};

export type MutationaddOrganizationMembershipArgs = {
  input?: InputMaybe<AddOrganizationMembershipInput>;
};

export type MutationaddPackagingComponentV2Args = {
  input?: InputMaybe<AddPackagingComponentV2Input>;
};

export type MutationaddRecipeArgs = {
  input?: InputMaybe<AddRecipeInput>;
};

export type MutationaddRecipeCollectionArgs = {
  input?: InputMaybe<AddRecipeCollectionInput>;
};

export type MutationaddRecipesToCollectionsArgs = {
  input?: InputMaybe<AddRecipesToCollectionsInput>;
};

export type MutationaddUserArgs = {
  input?: InputMaybe<AddUserInput>;
};

export type MutationcopyRecipesArgs = {
  input?: InputMaybe<CopyRecipesInput>;
};

export type MutationcreateSubscriptionArgs = {
  input?: InputMaybe<CreateSubscriptionInput>;
};

export type MutationdeletePackagingComponentV2Args = {
  input?: InputMaybe<DeletePackagingComponentV2Input>;
};

export type MutationdeleteRecipeArgs = {
  input?: InputMaybe<DeleteRecipeInput>;
};

export type MutationdeleteRecipeCollectionArgs = {
  input?: InputMaybe<DeleteRecipeCollectionInput>;
};

export type MutationdeleteRecipesArgs = {
  input?: InputMaybe<DeleteRecipesInput>;
};

export type MutationdeleteWeightedLocationOptionArgs = {
  input?: InputMaybe<DeleteWeightedLocationOptionInput>;
};

export type MutationlinkIngredientsToFoodClassArgs = {
  input?: InputMaybe<LinkIngredientsToFoodClassInput>;
};

export type MutationremoveRecipesFromCollectionsArgs = {
  input?: InputMaybe<RemoveRecipesFromCollectionsInput>;
};

export type MutationsendUserMessageArgs = {
  input?: InputMaybe<SendUserMessageInput>;
};

export type MutationupdateOrganizationArgs = {
  input?: InputMaybe<UpdateOrganizationInput>;
};

export type MutationupdatePackagingComponentV2Args = {
  input?: InputMaybe<UpdatePackagingComponentV2Input>;
};

export type MutationupdateRecipeArgs = {
  input?: InputMaybe<UpdateRecipeInput>;
};

export type MutationupdateRecipeCollectionArgs = {
  input?: InputMaybe<UpdateRecipeCollectionInput>;
};

export type MutationupdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type MutationupdateWeightedLocationOptionArgs = {
  input?: InputMaybe<UpdateWeightedLocationOptionInput>;
};

export type Organization = {
  __typename?: "Organization";
  defaultCurrency: Currency;
  defaultTaxCountry: CountryCode;
  features: OrganizationFeatures;
  hasReachedRecipeCountLimit: Scalars["Boolean"];
  id: Scalars["UUID"];
  localeForFoodClasses: Scalars["String"];
  memberships: Array<OrganizationMembership>;
  name: Scalars["String"];
  parentId?: Maybe<Scalars["UUID"]>;
  parentName?: Maybe<Scalars["String"]>;
  productTier: Tier;
  projectOptions: ProjectOptions;
  recipeCountLimit?: Maybe<Scalars["Int"]>;
  signUpOverrideId?: Maybe<Scalars["UUID"]>;
  viewerHasRecipeCollectionPermissionAdd: Scalars["Boolean"];
  viewerHasRecipePermissionAdd: Scalars["Boolean"];
};

export type OrganizationConnection = {
  __typename?: "OrganizationConnection";
  edges: Array<OrganizationEdge>;
  pageInfo: PageInfo;
};

export type OrganizationEdge = {
  __typename?: "OrganizationEdge";
  node: Organization;
};

export type OrganizationFeatures = {
  __typename?: "OrganizationFeatures";
  accurateCookingImpacts: Scalars["Boolean"];
  accuratePostPreparationStorageImpacts: Scalars["Boolean"];
  addUsers: Scalars["Boolean"];
  brandedIngredients: Scalars["Boolean"];
  carbonLabels: Scalars["Boolean"];
  collections: Scalars["Boolean"];
  countryOfOriginSourcing: Scalars["Boolean"];
  dashboard: Scalars["Boolean"];
  dataQualityScore: Scalars["Boolean"];
  detailedLifeCycleAnalysis: Scalars["Boolean"];
  editProducts: Scalars["Boolean"];
  exportIngredientImpactsByStage: Scalars["Boolean"];
  foodManufacturerOrganization: Scalars["Boolean"];
  landUse: Scalars["Boolean"];
  newLifeCycleImpactChart: Scalars["Boolean"];
  productPackaging: Scalars["Boolean"];
  requestImpactAnalysis: Scalars["Boolean"];
  scope3Report: Scalars["Boolean"];
  shareProducts: Scalars["Boolean"];
  subproducts: Scalars["Boolean"];
  systemBoundary: SystemBoundary;
  tags: Scalars["Boolean"];
  viewSharedProducts: Scalars["Boolean"];
  waterUse: Scalars["Boolean"];
};

export type OrganizationFilter = {
  id?: InputMaybe<Scalars["UUID"]>;
  parentId?: InputMaybe<Scalars["UUID"]>;
};

export type OrganizationMembership = {
  __typename?: "OrganizationMembership";
  isAdministrator: Scalars["Boolean"];
  organization: Organization;
  user: User;
};

export type OrganizationProduct = {
  __typename?: "OrganizationProduct";
  carbonLabels: Scalars["Boolean"];
  id: Scalars["String"];
  name: Scalars["String"];
  tagline: Scalars["String"];
  tier: Tier;
};

export type PackagingComponentMaterialV2 = {
  __typename?: "PackagingComponentMaterialV2";
  id: Scalars["UUID"];
  material: PackagingMaterialV2;
  recycledContentFraction?: Maybe<Scalars["Float"]>;
  weightKg: Scalars["Float"];
};

export type PackagingComponentMaterialV2Input = {
  id?: InputMaybe<Scalars["UUID"]>;
  materialId: Scalars["UUID"];
  recycledContentFraction?: InputMaybe<Scalars["Float"]>;
  weightKg: Scalars["Float"];
};

export type PackagingComponentV2 = {
  __typename?: "PackagingComponentV2";
  componentMaterials: Array<PackagingComponentMaterialV2>;
  ghgMagnitude?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  ownerOrganizationId?: Maybe<Scalars["UUID"]>;
};

export type PackagingComponentV2Connection = {
  __typename?: "PackagingComponentV2Connection";
  edges: Array<PackagingComponentV2Edge>;
  pageInfo: PageInfo;
};

export type PackagingComponentV2Edge = {
  __typename?: "PackagingComponentV2Edge";
  node: PackagingComponentV2;
};

export type PackagingComponentV2Filter = {
  anyOf?: InputMaybe<Array<InputMaybe<PackagingComponentV2Filter>>>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  isStandardComponent?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export enum PackagingComponentV2Order {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export type PackagingMaterial = {
  __typename?: "PackagingMaterial";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type PackagingMaterialConnection = {
  __typename?: "PackagingMaterialConnection";
  edges: Array<PackagingMaterialEdge>;
  pageInfo: PageInfo;
};

export type PackagingMaterialEdge = {
  __typename?: "PackagingMaterialEdge";
  node: PackagingMaterial;
};

export type PackagingMaterialV2 = {
  __typename?: "PackagingMaterialV2";
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type PackagingMaterialV2Connection = {
  __typename?: "PackagingMaterialV2Connection";
  edges: Array<PackagingMaterialV2Edge>;
  pageInfo: PageInfo;
};

export type PackagingMaterialV2Edge = {
  __typename?: "PackagingMaterialV2Edge";
  node: PackagingMaterialV2;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
};

export type PaymentRequest = {
  __typename?: "PaymentRequest";
  clientSecret: Scalars["String"];
  invoice: Invoice;
};

export type PostPreparationStoragePenalty = {
  __typename?: "PostPreparationStoragePenalty";
  daysInStorage: Scalars["Float"];
  id: Scalars["Int"];
  storageMethod: StorageMethod;
  storageMethodId: Scalars["Int"];
};

export type PostPreparationStoragePenaltyInput = {
  daysInStorage: Scalars["Float"];
  id?: InputMaybe<Scalars["Int"]>;
  storageMethodId: Scalars["Int"];
};

export type PostPreparationStorageScenario = {
  __typename?: "PostPreparationStorageScenario";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type PostPreparationStorageScenarioConnection = {
  __typename?: "PostPreparationStorageScenarioConnection";
  edges: Array<PostPreparationStorageScenarioEdge>;
  pageInfo: PageInfo;
};

export type PostPreparationStorageScenarioEdge = {
  __typename?: "PostPreparationStorageScenarioEdge";
  node: PostPreparationStorageScenario;
};

export type ProjectOptions = {
  __typename?: "ProjectOptions";
  endMileType?: Maybe<EndMileType>;
  endOfLifeType?: Maybe<EndOfLifeType>;
  id: Scalars["Int"];
  name: Scalars["String"];
  parentId?: Maybe<Scalars["Int"]>;
  postPreparationStorageScenario?: Maybe<PostPreparationStorageScenario>;
};

export type ProjectOptionsConnection = {
  __typename?: "ProjectOptionsConnection";
  edges: Array<ProjectOptionsEdge>;
  pageInfo: PageInfo;
};

export type ProjectOptionsEdge = {
  __typename?: "ProjectOptionsEdge";
  node: ProjectOptions;
};

export type Promotion = {
  __typename?: "Promotion";
  code?: Maybe<Scalars["String"]>;
  couponId: Scalars["String"];
  couponName: Scalars["String"];
  id: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  allFoodServiceTierProducts: Array<OrganizationProduct>;
  availableTierProducts: Array<TierProduct>;
  consumptionLocationName?: Maybe<Scalars["String"]>;
  cookingImpacts: CookingImpactConnection;
  endMileTypes: EndMileTypeConnection;
  existingPackagingComponentNamesInOrganization: Array<Scalars["String"]>;
  existingRecipeNamesInOrganizationCollections: Array<Scalars["String"]>;
  foodClassLookup: Array<FoodClassLookupResult>;
  foodClasses: FoodClassConnection;
  impactRatingInfos: Array<ImpactRatingInfos>;
  locations: LocationConnection;
  organization?: Maybe<Organization>;
  organizations: OrganizationConnection;
  packagingComponents: PackagingComponentV2Connection;
  packagingMaterials: PackagingMaterialConnection;
  packagingMaterialsV2: PackagingMaterialV2Connection;
  postPreparationStorageScenarios: PostPreparationStorageScenarioConnection;
  projectOptions: ProjectOptionsConnection;
  promotion?: Maybe<Promotion>;
  recipe?: Maybe<Recipe>;
  recipeCollection?: Maybe<RecipeCollection>;
  recipeCollections: RecipeCollectionConnection;
  recipeCount: Scalars["Int"];
  recipes: RecipeConnection;
  recipesImpacts?: Maybe<Scalars["String"]>;
  storageMethods: StorageMethodConnection;
  testErrorReporting?: Maybe<TestErrorReporting>;
  transportModes: TransportModeConnection;
  upcomingInvoice: Invoice;
  user?: Maybe<User>;
  users: UserConnection;
  viewer: User;
  weightedLocationOptions: WeightedLocationOptionConnection;
};

export type QueryconsumptionLocationNameArgs = {
  organizationId: Scalars["UUID"];
};

export type QuerycookingImpactsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryendMileTypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryexistingPackagingComponentNamesInOrganizationArgs = {
  organizationId: Scalars["UUID"];
  recipeIds: Array<Scalars["Int"]>;
};

export type QueryexistingRecipeNamesInOrganizationCollectionsArgs = {
  collectionIds: Array<Scalars["Int"]>;
  organizationId: Scalars["UUID"];
  recipeIds: Array<Scalars["Int"]>;
};

export type QueryfoodClassLookupArgs = {
  locale: Scalars["String"];
  names: Array<Scalars["String"]>;
};

export type QueryfoodClassesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<FoodClassFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  impactCategory?: InputMaybe<ImpactCategory>;
  orderBy?: InputMaybe<FoodClassOrder>;
  organizationId: Scalars["UUID"];
};

export type QueryimpactRatingInfosArgs = {
  filter: ImpactRatingInfoFilter;
};

export type QuerylocationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryorganizationArgs = {
  filter: OrganizationFilter;
};

export type QueryorganizationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QuerypackagingComponentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PackagingComponentV2Filter>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PackagingComponentV2Order>;
};

export type QuerypackagingMaterialsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QuerypackagingMaterialsV2Args = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QuerypostPreparationStorageScenariosArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryprojectOptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QuerypromotionArgs = {
  promotionCode: Scalars["String"];
};

export type QueryrecipeArgs = {
  filter: RecipeFilter;
};

export type QueryrecipeCollectionArgs = {
  filter: RecipeCollectionFilter;
};

export type QueryrecipeCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<RecipeCollectionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryrecipeCountArgs = {
  filter?: InputMaybe<RecipeFilter>;
};

export type QueryrecipesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<RecipeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<RecipeOrder>;
};

export type QueryrecipesImpactsArgs = {
  filter?: InputMaybe<RecipeFilter>;
  organizationId: Scalars["UUID"];
};

export type QuerystorageMethodsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QuerytransportModesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryupcomingInvoiceArgs = {
  billingInterval: BillingInterval;
  couponId?: InputMaybe<Scalars["String"]>;
  currency: Currency;
  defaultTaxCountry: CountryCode;
  organizationId: Scalars["UUID"];
  recipeAllowance: RecipeAllowance;
  subscribableTier: SubscribableTier;
};

export type QueryuserArgs = {
  filter: UserFilter;
};

export type QueryusersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryweightedLocationOptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  filter: WeightedLocationOptionFilter;
  first?: InputMaybe<Scalars["Int"]>;
};

export type Recipe = {
  __typename?: "Recipe";
  coProducts: Array<RecipeCoProduct>;
  code?: Maybe<Scalars["String"]>;
  collections: Array<RecipeCollection>;
  cookingPenalties: Array<CookingPenalty>;
  dietaryCategories: Array<Scalars["String"]>;
  endMileType?: Maybe<EndMileType>;
  endMileTypeId?: Maybe<Scalars["Int"]>;
  hasCompleteCookingPenalties: Scalars["Boolean"];
  hasCompletePostPreparationStoragePenalties: Scalars["Boolean"];
  id: Scalars["Int"];
  impact: RecipeImpact;
  impactCached: CachedRecipeImpact;
  ingredients: Array<RecipeIngredient>;
  isHotDrink: Scalars["Boolean"];
  name: Scalars["String"];
  ownerOrganizationId: Scalars["UUID"];
  ownerOrganizationName?: Maybe<Scalars["String"]>;
  packagingComponents: Array<RecipePackagingComponent>;
  packagingComponentsV2: Array<RecipePackagingComponentV2>;
  postPreparationStoragePenalties: Array<PostPreparationStoragePenalty>;
  productMassQuantity?: Maybe<Scalars["Float"]>;
  productMassUnit: Scalars["String"];
  productProcessingMassQuantity?: Maybe<Scalars["Float"]>;
  productProcessingMassUnit: Scalars["String"];
  serves?: Maybe<Scalars["Float"]>;
  servingsPerCookingBatch?: Maybe<Scalars["Float"]>;
  transportSteps: Array<RecipeTransportStep>;
  unitProcess?: Maybe<RecipeUnitProcess>;
  viewerHasPermissionUpdate: Scalars["Boolean"];
};

export type RecipeimpactArgs = {
  excludePackaging: Scalars["Boolean"];
  fetchStaleImpacts: Scalars["Boolean"];
};

export enum RecipeAllowance {
  FIFTY = "FIFTY",
  FIVE = "FIVE",
  FIVE_HUNDRED = "FIVE_HUNDRED",
  ONE_HUNDRED = "ONE_HUNDRED",
  ONE_THOUSAND = "ONE_THOUSAND",
  TEN = "TEN",
}

export type RecipeAllowanceProduct = {
  __typename?: "RecipeAllowanceProduct";
  price: RecurringPrices;
  recipeAllowance: RecipeAllowance;
};

export type RecipeCoProduct = {
  __typename?: "RecipeCoProduct";
  economicValuePerKg: Scalars["Float"];
  id: Scalars["Int"];
  massQuantity: Scalars["Float"];
  massUnit: Scalars["String"];
  name: Scalars["String"];
};

export type RecipeCoProductInput = {
  economicValuePerKg: Scalars["Float"];
  id?: InputMaybe<Scalars["Int"]>;
  massQuantity: Scalars["Float"];
  massUnit: Scalars["String"];
  name: Scalars["String"];
};

export type RecipeCollection = {
  __typename?: "RecipeCollection";
  id: Scalars["Int"];
  name: Scalars["String"];
  recipeCount: Scalars["Int"];
  viewerHasPermissionUpdate: Scalars["Boolean"];
};

export type RecipeCollectionConnection = {
  __typename?: "RecipeCollectionConnection";
  edges: Array<RecipeCollectionEdge>;
  pageInfo: PageInfo;
};

export type RecipeCollectionEdge = {
  __typename?: "RecipeCollectionEdge";
  node: RecipeCollection;
};

export type RecipeCollectionFilter = {
  anyOf?: InputMaybe<Array<InputMaybe<RecipeCollectionFilter>>>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  includeCollectionsInUserGroup?: InputMaybe<Scalars["Boolean"]>;
  ownerOrganizations?: InputMaybe<OrganizationFilter>;
};

export type RecipeConnection = {
  __typename?: "RecipeConnection";
  edges: Array<RecipeEdge>;
  pageInfo: PageInfo;
};

export type RecipeEdge = {
  __typename?: "RecipeEdge";
  node: Recipe;
};

export type RecipeEnergyUse = {
  __typename?: "RecipeEnergyUse";
  amount_kwh: Scalars["Float"];
  id: Scalars["Int"];
};

export type RecipeFilter = {
  allOf?: InputMaybe<Array<InputMaybe<RecipeFilter>>>;
  anyOf?: InputMaybe<Array<InputMaybe<RecipeFilter>>>;
  cachedRequiresClientAttention?: InputMaybe<Scalars["Boolean"]>;
  collections?: InputMaybe<RecipeCollectionFilter>;
  excludedIds?: InputMaybe<Array<Scalars["Int"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isInSomeCollection?: InputMaybe<Scalars["Boolean"]>;
  onlyAccessibleViaCollection?: InputMaybe<Scalars["Boolean"]>;
  ownerOrganizations?: InputMaybe<OrganizationFilter>;
  searchCodeOrName?: InputMaybe<Scalars["String"]>;
  searchName?: InputMaybe<Scalars["String"]>;
  usableAsIngredientForOrganizationId?: InputMaybe<Scalars["UUID"]>;
  usedAsIngredient?: InputMaybe<Scalars["Boolean"]>;
};

export type RecipeImpact = {
  __typename?: "RecipeImpact";
  dataQualityScore?: Maybe<DataQualityScore>;
  effects?: Maybe<Effects>;
  errors: Array<ImpactCalculatorError>;
  impactRating?: Maybe<ImpactRating>;
  ingredients: Array<RecipeIngredientImpact>;
  isStale: Scalars["Boolean"];
  requiresClientAttention: Scalars["Boolean"];
  unavailableLandUse: Scalars["Boolean"];
  unavailableWaterUse: Scalars["Boolean"];
  weightKgPerServing?: Maybe<Scalars["Float"]>;
};

export type RecipeIngredient = {
  __typename?: "RecipeIngredient";
  cachedImpact: CachedRecipeIngredientImpact;
  foodClass?: Maybe<FoodClass>;
  foodClassId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  ignorable: Scalars["Boolean"];
  name: Scalars["String"];
  prePreparationStoragePenalties: Array<RecipeIngredientPrePreparationStoragePenalty>;
  quantity?: Maybe<Scalars["Float"]>;
  transportSteps: Array<RecipeIngredientTransportStep>;
  unit?: Maybe<Scalars["String"]>;
  unitProcess?: Maybe<RecipeIngredientUnitProcess>;
  useRecipe?: Maybe<Recipe>;
  useRecipeId?: Maybe<Scalars["Int"]>;
};

export type RecipeIngredientImpact = {
  __typename?: "RecipeIngredientImpact";
  dataQualityScore?: Maybe<DataQualityScore>;
  effects?: Maybe<Effects>;
  foodClassId?: Maybe<Scalars["Int"]>;
  impactRating?: Maybe<ImpactRating>;
  ingredientId: Scalars["Int"];
  name: Scalars["String"];
  quantity?: Maybe<Scalars["Float"]>;
  quantityPerRootRecipeServing?: Maybe<Scalars["Float"]>;
  unit?: Maybe<Scalars["String"]>;
  useRecipe?: Maybe<RecipeImpact>;
  weightKgPerServing?: Maybe<Scalars["Float"]>;
};

export type RecipeIngredientPrePreparationStoragePenalty = {
  __typename?: "RecipeIngredientPrePreparationStoragePenalty";
  daysInStorage: Scalars["Int"];
  id: Scalars["Int"];
  storageMethod: StorageMethod;
  storageMethodId: Scalars["Int"];
};

export type RecipeIngredientTransportStep = {
  __typename?: "RecipeIngredientTransportStep";
  ghg: Scalars["Float"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type RecipeIngredientUnitProcess = {
  __typename?: "RecipeIngredientUnitProcess";
  processLoss?: Maybe<Scalars["Float"]>;
};

export enum RecipeOrder {
  CACHED_GHG_PER_KG_ASC = "CACHED_GHG_PER_KG_ASC",
  CACHED_GHG_PER_KG_DESC = "CACHED_GHG_PER_KG_DESC",
  CACHED_GHG_PER_SERVING_ASC = "CACHED_GHG_PER_SERVING_ASC",
  CACHED_GHG_PER_SERVING_DESC = "CACHED_GHG_PER_SERVING_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  SERVES_ASC = "SERVES_ASC",
  SERVES_DESC = "SERVES_DESC",
}

export type RecipePackagingComponent = {
  __typename?: "RecipePackagingComponent";
  id: Scalars["Int"];
  material: PackagingMaterial;
  name: Scalars["String"];
  weightKg: Scalars["Float"];
};

export type RecipePackagingComponentInput = {
  id?: InputMaybe<Scalars["Int"]>;
  materialId: Scalars["Int"];
  name: Scalars["String"];
  weightKg: Scalars["Float"];
};

export type RecipePackagingComponentV2 = {
  __typename?: "RecipePackagingComponentV2";
  amountOfProductsPackaged?: Maybe<Scalars["Float"]>;
  packagingComponent: PackagingComponentV2;
};

export type RecipePackagingComponentV2Input = {
  amountOfProductsPackaged?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  packagingComponentId: Scalars["UUID"];
};

export type RecipeTransportStep = {
  __typename?: "RecipeTransportStep";
  distanceMagnitude: Scalars["Float"];
  distanceUnit: DistanceUnit;
  id: Scalars["Int"];
  name: Scalars["String"];
  storageMethod: TransportStorageMethod;
  transportMode: TransportMode;
};

export type RecipeUnitProcess = {
  __typename?: "RecipeUnitProcess";
  economicValuePerKg?: Maybe<Scalars["Float"]>;
  energyUses: Array<Maybe<RecipeEnergyUse>>;
  finalProductLoss?: Maybe<Scalars["Float"]>;
};

export type RecurringPrice = {
  __typename?: "RecurringPrice";
  gbp: Scalars["Int"];
  usd: Scalars["Int"];
};

export type RecurringPrices = {
  __typename?: "RecurringPrices";
  month: RecurringPrice;
  year: RecurringPrice;
};

export type RemoveRecipesFromCollections = {
  __typename?: "RemoveRecipesFromCollections";
  success: Scalars["Boolean"];
};

export type RemoveRecipesFromCollectionsInput = {
  collectionIds: Array<Scalars["Int"]>;
  recipeIds: Array<Scalars["Int"]>;
};

export type SendUserMessage = {
  __typename?: "SendUserMessage";
  success: Scalars["Boolean"];
};

export type SendUserMessageInput = {
  body: Scalars["String"];
  subject: Scalars["String"];
};

export type StageImpact = {
  __typename?: "StageImpact";
  codename: Scalars["String"];
  impactPerKg?: Maybe<Scalars["Float"]>;
  impactPerRecipe?: Maybe<Scalars["Float"]>;
  impactPerRootRecipeServing?: Maybe<Scalars["Float"]>;
  impactPerServing?: Maybe<Scalars["Float"]>;
  impactUnit: ImpactUnit;
};

export type StorageMethod = {
  __typename?: "StorageMethod";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type StorageMethodConnection = {
  __typename?: "StorageMethodConnection";
  edges: Array<StorageMethodEdge>;
  pageInfo: PageInfo;
};

export type StorageMethodEdge = {
  __typename?: "StorageMethodEdge";
  node: StorageMethod;
};

export type StripeAddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  line1: Scalars["String"];
  line2?: InputMaybe<Scalars["String"]>;
  postalCode: Scalars["String"];
  state: Scalars["String"];
};

export enum SubscribableTier {
  FOOD_SERVICE_BASIC = "FOOD_SERVICE_BASIC",
  FOOD_SERVICE_PREMIUM = "FOOD_SERVICE_PREMIUM",
}

export enum SystemBoundary {
  CRADLE_TO_GRAVE = "CRADLE_TO_GRAVE",
  CRADLE_TO_PROCESSING_GATE = "CRADLE_TO_PROCESSING_GATE",
  CRADLE_TO_RETAIL_DOORSTEP = "CRADLE_TO_RETAIL_DOORSTEP",
}

export type TestErrorReporting = {
  __typename?: "TestErrorReporting";
  AtLeastOneFieldMustBeDefined: Scalars["Boolean"];
};

export enum Tier {
  FOOD_SERVICE_BASIC = "FOOD_SERVICE_BASIC",
  FOOD_SERVICE_ENTERPRISE = "FOOD_SERVICE_ENTERPRISE",
  FOOD_SERVICE_FREE = "FOOD_SERVICE_FREE",
  FOOD_SERVICE_LEGACY = "FOOD_SERVICE_LEGACY",
  FOOD_SERVICE_PREMIUM = "FOOD_SERVICE_PREMIUM",
  MANUFACTURER_ENTERPRISE = "MANUFACTURER_ENTERPRISE",
  MANUFACTURER_FREE = "MANUFACTURER_FREE",
  MANUFACTURER_PREMIUM = "MANUFACTURER_PREMIUM",
}

export type TierProduct = {
  __typename?: "TierProduct";
  carbonLabels: Scalars["Boolean"];
  id: Scalars["String"];
  name: Scalars["String"];
  price: RecurringPrices;
  recipeAllowanceProducts: Array<RecipeAllowanceProduct>;
  tagline: Scalars["String"];
  tier: Tier;
};

export type TransportMode = {
  __typename?: "TransportMode";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type TransportModeConnection = {
  __typename?: "TransportModeConnection";
  edges: Array<TransportModeEdge>;
  pageInfo: PageInfo;
};

export type TransportModeEdge = {
  __typename?: "TransportModeEdge";
  node: TransportMode;
};

export enum TransportStorageMethod {
  AMBIENT = "AMBIENT",
  FROZEN = "FROZEN",
  REFRIGERATED = "REFRIGERATED",
}

export type UnitProcessInput = {
  economicValuePerKg?: InputMaybe<Scalars["Float"]>;
  energyUses: Array<EnergyUse>;
  finalProductLoss?: InputMaybe<Scalars["Float"]>;
};

export type UpdateOrganization = {
  __typename?: "UpdateOrganization";
  success: Scalars["Boolean"];
};

export type UpdateOrganizationInput = {
  endMileTypeId?: InputMaybe<Scalars["Int"]>;
  endOfLifeType?: InputMaybe<EndOfLifeType>;
  name?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  parentProjectOptionsId?: InputMaybe<Scalars["Int"]>;
  postPreparationStorageScenarioId?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePackagingComponentV2 = {
  __typename?: "UpdatePackagingComponentV2";
  success: Scalars["Boolean"];
};

export type UpdatePackagingComponentV2Input = {
  id: Scalars["UUID"];
  materials: Array<PackagingComponentMaterialV2Input>;
  name: Scalars["String"];
  ownerOrganizationId: Scalars["UUID"];
};

export type UpdateRecipe = {
  __typename?: "UpdateRecipe";
  recipe: Recipe;
  success: Scalars["Boolean"];
};

export type UpdateRecipeCollection = {
  __typename?: "UpdateRecipeCollection";
  success: Scalars["Boolean"];
};

export type UpdateRecipeCollectionInput = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type UpdateRecipeInput = {
  clientId?: InputMaybe<Scalars["UUID"]>;
  coProducts: Array<RecipeCoProductInput>;
  code?: InputMaybe<Scalars["String"]>;
  collectionIds: Array<Scalars["Int"]>;
  cookingPenalties: Array<CookingPenaltyInput>;
  endMileTypeId?: InputMaybe<Scalars["Int"]>;
  hasCompleteCookingPenalties: Scalars["Boolean"];
  hasCompletePostPreparationStoragePenalties: Scalars["Boolean"];
  id: Scalars["Int"];
  ingredients: Array<IngredientInput>;
  isCooked: Scalars["Boolean"];
  isHotDrink: Scalars["Boolean"];
  isStored: Scalars["Boolean"];
  name: Scalars["String"];
  numServings: Scalars["Float"];
  ownerOrganizationId: Scalars["UUID"];
  packagingComponents?: InputMaybe<Array<RecipePackagingComponentInput>>;
  packagingComponentsV2: Array<RecipePackagingComponentV2Input>;
  postPreparationStoragePenalties?: InputMaybe<
    Array<PostPreparationStoragePenaltyInput>
  >;
  productMassQuantity?: InputMaybe<Scalars["Float"]>;
  productMassUnit?: InputMaybe<Scalars["String"]>;
  productProcessingMassQuantity?: InputMaybe<Scalars["Float"]>;
  productProcessingMassUnit?: InputMaybe<Scalars["String"]>;
  servingsPerCookingBatch?: InputMaybe<Scalars["Float"]>;
  unitProcess?: InputMaybe<UnitProcessInput>;
};

export type UpdateUser = {
  __typename?: "UpdateUser";
  success: Scalars["Boolean"];
};

export type UpdateUserInput = {
  externalId: Scalars["UUID"];
  isExternalUser: Scalars["Boolean"];
};

export type UpdateWeightedLocationOption = {
  __typename?: "UpdateWeightedLocationOption";
  success: Scalars["Boolean"];
};

export type UpdateWeightedLocationOptionInput = {
  foodClassId: Scalars["Int"];
  locationProportions: Array<LocationProportionInput>;
  organizationId: Scalars["UUID"];
};

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  externalId: Scalars["UUID"];
  featureFlags: Array<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["Int"];
  isExternalUser: Scalars["Boolean"];
  isReadonly: Scalars["Boolean"];
  lastName: Scalars["String"];
  locale?: Maybe<Scalars["String"]>;
  organizationMemberships: Array<OrganizationMembership>;
  username: Scalars["String"];
  viewerHasPermissionImpersonate: Scalars["Boolean"];
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: "UserEdge";
  node: User;
};

export type UserFilter = {
  externalId?: InputMaybe<Scalars["UUID"]>;
  id?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type WeightedLocationOption = {
  __typename?: "WeightedLocationOption";
  foodClassId: Scalars["Int"];
  id: Scalars["Int"];
  locationProportions: Array<LocationProportion>;
};

export type WeightedLocationOptionConnection = {
  __typename?: "WeightedLocationOptionConnection";
  edges: Array<WeightedLocationOptionEdge>;
  pageInfo: PageInfo;
};

export type WeightedLocationOptionEdge = {
  __typename?: "WeightedLocationOptionEdge";
  node: WeightedLocationOption;
};

export type WeightedLocationOptionFilter = {
  organizationId?: InputMaybe<Scalars["UUID"]>;
};
