import ManageTagsButton from "./ManageTagsButton";

const stories = {
  name: "ManageTagsButton",
  render: () => <Story />,
};

function Story() {
  return (
    <div className="d-flex m-2">
      <ManageTagsButton
        onCollectionDeleted={(id) => alert(`Collection '${id}' deleted`)}
        withText={true}
      />
    </div>
  );
}

export default stories;
