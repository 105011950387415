import { FormattedMessage, useIntl } from "react-intl";

import ActionModal from "./ActionModal";
import Form from "./Form";
import "./DeleteModal.css";

export interface DeleteModalProps {
  message?: React.ReactNode;
  name: string;
  onDelete: () => Promise<void>;
  onHide: () => void;
  show: boolean;
  title: string;
}

export default function DeleteModal(props: DeleteModalProps) {
  const { message, name, onDelete, onHide, show, title } = props;

  const intl = useIntl();

  return (
    <ActionModal className="DeleteModal" show={show} title={title}>
      <ActionModal.Body>
        <p className="text-muted">
          {message !== undefined ? (
            message
          ) : (
            <FormattedMessage
              id="components/utils/DeleteModal:body"
              defaultMessage="Are you sure you would like to permanently delete {name}?"
              values={{ name }}
            />
          )}
        </p>
      </ActionModal.Body>
      <ActionModal.Footer>
        <Form className="DeleteModal_Form" onSubmit={() => onDelete()}>
          <div className="DeleteModal_Buttons">
            <Form.SecondaryButton onClick={() => onHide()}>
              <FormattedMessage
                id="components/utils/DeleteModal:cancelButton/label"
                defaultMessage="Cancel"
              />
            </Form.SecondaryButton>
            <Form.SubmitButton
              loadingLabel={intl.formatMessage({
                id: "components/utils/DeleteModal:deleteButton/loadingLabel",
                defaultMessage: "Deleting",
              })}
              submitLabel={intl.formatMessage({
                id: "components/utils/DeleteModal:deleteButton/submitLabel",
                defaultMessage: "Delete",
              })}
              variant="danger"
            />
          </div>
          <Form.ErrorAlert className="DeleteModal_ErrorAlert" />
        </Form>
      </ActionModal.Footer>
    </ActionModal>
  );
}
