import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import useId from "./useId";

interface FileInputButtonProps {
  onChange: (file: File) => void;
  accept?: string;
}

export function FileInputButton(props: FileInputButtonProps) {
  const { onChange, accept } = props;
  const id = useId();
  const [hasFocus, setHasFocus] = useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files === null || event.target.files.length < 1) {
      return;
    }
    const file = event.target.files[0];
    onChange(file);
  }

  return (
    <div className="file-input-button">
      <input
        accept={accept}
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
        id={id}
        type="file"
        onChange={handleChange}
        style={{ opacity: 0 }}
      />
      <label
        htmlFor={id}
        className={classNames("btn btn-primary", { focus: hasFocus })}
      >
        <FormattedMessage
          id="components/utils/FileInputButton:text"
          defaultMessage="Select a file"
        />
      </label>
    </div>
  );
}

export default FileInputButton;
