import VectorProps from "./VectorProps";

export default function Share(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library
  // https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?type=design&node-id=1-67&mode=design&t=wrgn1h8yYZ8J1aJj-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.54546C1 1.14826 2.14825 0 3.54545 0C4.94266 0 6.09091 1.14826 6.09091 2.54546C6.09091 2.66422 6.08422 2.78312 6.06818 2.89773L9.88636 6.07955C10.3338 5.69095 10.9117 5.45455 11.5455 5.45455C12.9427 5.45455 14.0909 6.60279 14.0909 8C14.0909 9.3972 12.9427 10.5455 11.5455 10.5455C10.906 10.5455 10.3239 10.3039 9.875 9.90909L6.05682 13.0909C6.07408 13.2097 6.09091 13.3312 6.09091 13.4545C6.09091 14.8517 4.94266 16 3.54545 16C2.14825 16 1 14.8517 1 13.4545C1 12.0573 2.14825 10.9091 3.54545 10.9091C4.27782 10.9091 4.94303 11.2258 5.40909 11.7273L9.09091 8.65909C9.03336 8.44774 9 8.22862 9 8C9 7.76744 9.03144 7.54416 9.09091 7.32955L5.42045 4.26136C4.95389 4.76955 4.28345 5.09091 3.54545 5.09091C2.14825 5.09091 1 3.94266 1 2.54546Z"
        fill={fill}
      />
    </svg>
  );
}
