import classNames from "classnames";

interface LargeNumberProps {
  children: React.ReactNode;
  className?: string;
}

export default function LargeNumber(props: LargeNumberProps) {
  const { children, className = "" } = props;
  return <div className={classNames(className, "LargeNumber")}>{children}</div>;
}
