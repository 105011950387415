import React, { useContext, useEffect } from "react";

export interface ImpersonatedUser {
  id: number;
  externalId: string;
}

export interface ResetPasswordData {
  newPassword: string;
  newPasswordConfirm: string;
  userIdToken: string;
  challengeToken: string;
}

export interface MarshalledResetPasswordData {
  new_password: string;
  new_password_again: string;
  uid: string;
  token: string;
}

export function marshallResetPasswordData(
  data: ResetPasswordData
): MarshalledResetPasswordData {
  return {
    new_password: data.newPassword,
    new_password_again: data.newPasswordConfirm,
    uid: data.userIdToken,
    token: data.challengeToken,
  };
}

export type LogoutListener = () => void;

export interface Session {
  addLogoutListener: (listener: LogoutListener) => () => void;
  impersonate: (impersonatedUser: ImpersonatedUser | null) => void;
  impersonatedUser: ImpersonatedUser | null;
  setToken: (token: string) => Promise<void>;
  logout: () => void;
  resetPassword: (newPasswordData: ResetPasswordData) => Promise<void>;
  userId: number | null;
  externalUserId: string | null;
  userIsStaff: boolean | null;
  isExternalUser: boolean | null;
}

const SessionContext = React.createContext<null | Session>(null);

export const SessionProvider = SessionContext.Provider;

export function useSession(): Session {
  const session = useContext(SessionContext);
  if (session === null) {
    throw new Error("missing provider for SessionContext");
  } else {
    return session;
  }
}

export function useLogout(): () => void {
  const session = useSession();
  return session.logout;
}

export function useLogoutListener(listener: LogoutListener) {
  const session = useSession();
  const addLogoutListener = session.addLogoutListener;
  useEffect(() => {
    const remove = addLogoutListener(listener);
    return remove;
  }, [listener, addLogoutListener]);
}

export function useImpersonate(): (user: ImpersonatedUser) => void {
  const { impersonate } = useSession();
  return impersonate;
}

export function useImpersonatedUserId(): number | null {
  const { impersonatedUser } = useSession();
  return impersonatedUser?.id ?? null;
}
