import { useState } from "react";

import Card from "../../utils/Card";
import { IngredientType } from "../RecipeEditor/RecipeIngredientsEditor";
import * as CoProductEditor from "./CoProductEditor";
import * as CoProductsEditor from "./CoProductsEditor";
import * as EconomicValueEditor from "./EconomicValueEditor";
import * as LossPercentageEditor from "./LossPercentageEditor";
import Packaging, { PackagingState } from "./Packaging";
import * as PackagingComponentEditor from "./PackagingComponentEditor";
import * as PackagingComponentsEditor from "./PackagingComponentsEditor";
import PhysicalInputEditor, { Value } from "./PhysicalInputEditor";
import PhysicalInputsEditor from "./PhysicalInputsEditor";
import Processing, { ProcessingState } from "./Processing";
import { ProductDetails, ProductDetailsState } from "./ProductDetails";
import ProductEditor from "./ProductEditor";
import ProductEditorHeader from "./ProductEditorHeader";

const stories = {
  name: "ProductEditor",
  children: [
    {
      name: "ProductEditorHeader",
      render: () => (
        <ProductEditorHeader
          editOrCreate="create"
          packaging={<div>Packaging form goes here</div>}
          productDetails={<div>Details form goes here</div>}
          processing={() => <div>Processing form goes here</div>}
          onBack={() => alert("Back")}
          onSubmit={async () => alert("Create")}
          stepsWithError={[]}
        />
      ),
    },
    {
      name: "ProductDetails",
      render: () => <ProductDetailsStory />,
    },
    {
      name: "ProductEditor",
      render: () => (
        <ProductEditor contentContainerRef={null} parentUrl={"/_stories"} />
      ),
    },
    {
      name: "Processing",
      render: () => <ProcessingStory />,
    },
    {
      name: "PhysicalInputsEditor",
      render: () => <PhysicalInputsEditorStory />,
    },
    {
      name: "PhysicalInputEditor",
      render: () => <PhysicalInputEditorStory />,
    },
    {
      name: "EconomicValueEditor",
      render: () => <EconomicValueEditorStory />,
    },
    {
      name: "LossPercentageEditor",
      render: () => <LossPercentageEditorStory />,
    },
    {
      name: "CoProductEditor",
      render: () => <CoProductEditorStory />,
    },
    {
      name: "CoProductsEditor",
      render: () => <CoProductsEditorStory />,
    },
    {
      name: "Packaging",
      render: () => <PackagingStory />,
    },
    {
      name: "PackagingComponentEditor",
      render: () => <PackagingComponentEditorStory />,
    },
    {
      name: "PackagingComponentsEditor",
      render: () => <PackagingComponentsEditorStory />,
    },
  ],
};

const productDetailsInitialState: ProductDetailsState = {
  name: { code: "", name: { hasError: false, text: "Chicken Tea" } },
  ingredients: [],
  collectionIds: [],
  productWeight: {
    quantity: { isInvalid: false, text: "12.34" },
    unit: "g",
  },
};

function ProductDetailsStory() {
  const [state, setState] = useState<ProductDetailsState>(
    productDetailsInitialState
  );

  return (
    <ProductDetails
      availableIngredients={[]}
      collections={[]}
      contentContainerRef={null}
      refreshCollections={async () => {
        console.log("refreshing collections");
      }}
      state={state}
      onChange={setState}
    />
  );
}

const processingInitialState: ProcessingState = {
  amountKwh: { isInvalid: false, text: "2.2" },
  physicalOutput: {
    finalEconomicValue: { isInvalid: false, text: "10" },
    finalProductLoss: { isInvalid: false, text: "20" },
    finalProductWeightQuantity: { isInvalid: false, text: "" },
    finalProductWeightUnit: "gram",
    coProducts: [],
  },
  ingredients: [],
  siteName: { hasError: false, text: "Chicken Tea Factory" },
};

function ProcessingStory() {
  const [state, setState] = useState<ProcessingState>(processingInitialState);

  return (
    <Processing
      contentContainerRef={null}
      onBack={() => alert("back")}
      showSiteNameSection={true}
      state={state}
      onChange={setState}
    />
  );
}

function PhysicalInputsEditorStory() {
  const [state, setState] = useState<Array<Value>>([
    {
      existingIngredient: null,
      foodClassId: null,
      ingredientType: IngredientType.SIMPLE,
      invalidName: false,
      invalidUseRecipe: false,
      key: "mykey1",
      name: "Test 1",
      unitProcess: { processLoss: { isInvalid: false, text: "0.1" } },
      quantity: { isInvalid: false, text: "12.3" },
      unit: "g",
      useRecipe: null,
    },
    {
      existingIngredient: null,
      foodClassId: null,
      ingredientType: IngredientType.SIMPLE,
      invalidName: false,
      invalidUseRecipe: false,
      key: "mykey2",
      name: "Test 2",
      unitProcess: { processLoss: { isInvalid: true, text: "-12.12" } },
      quantity: { isInvalid: true, text: "Hello" },
      unit: "g",
      useRecipe: null,
    },
  ]);

  return (
    <Card className="w-75 m-4 p-4">
      <PhysicalInputsEditor
        physicalInputs={state}
        setPhysicalInputs={setState}
      />
    </Card>
  );
}

function PhysicalInputEditorStory() {
  const [state, setState] = useState<Value>({
    existingIngredient: null,
    foodClassId: null,
    ingredientType: IngredientType.SIMPLE,
    invalidName: false,
    invalidUseRecipe: false,
    key: "mykey",
    name: "Test 1",
    unitProcess: { processLoss: { isInvalid: false, text: "45.2" } },
    quantity: { isInvalid: false, text: "12.3" },
    unit: "g",
    useRecipe: null,
  });

  return (
    <Card className="w-75 m-4 p-4">
      <PhysicalInputEditor
        value={state}
        onChange={setState}
        onDelete={() => alert("delete")}
      />
    </Card>
  );
}

function EconomicValueEditorStory() {
  const [value, setValue] = useState<EconomicValueEditor.Value>(
    EconomicValueEditor.initialValue(null)
  );
  return (
    <EconomicValueEditor.EconomicValueEditor
      value={value}
      onChange={setValue}
    />
  );
}

function LossPercentageEditorStory() {
  const [value, setValue] = useState<LossPercentageEditor.Value>(
    EconomicValueEditor.initialValue(null)
  );
  return (
    <LossPercentageEditor.LossPercentageEditor
      value={value}
      onChange={setValue}
    />
  );
}

function CoProductEditorStory() {
  const [value, setValue] = useState<CoProductEditor.Value>(
    CoProductEditor.blankValue()
  );

  return (
    <CoProductEditor.CoProductEditor
      onChange={setValue}
      value={value}
      onDelete={() => alert("Delete")}
    />
  );
}

function CoProductsEditorStory() {
  const [value, setValue] = useState<CoProductsEditor.Value>(
    CoProductsEditor.initialValue(null)
  );

  return (
    <CoProductsEditor.CoProductsEditor onChange={setValue} value={value} />
  );
}

const testPackagingComponents = [
  { id: "1", name: "Packaging component 1" },
  { id: "2", name: "Packaging component 2" },
  { id: "3", name: "Packaging component 3" },
];

function PackagingComponentEditorStory() {
  const [value, setValue] = useState<PackagingComponentEditor.Value>(
    PackagingComponentEditor.blankValue()
  );
  return (
    <PackagingComponentEditor.PackagingComponentEditor
      value={value}
      onChange={setValue}
      packagingComponents={testPackagingComponents}
      onDelete={() => alert("Delete")}
    />
  );
}

function PackagingComponentsEditorStory() {
  const [value, setValue] = useState<PackagingComponentsEditor.Value>(
    PackagingComponentsEditor.initialValue(undefined)
  );

  return (
    <PackagingComponentsEditor.PackagingComponentsEditor
      value={value}
      onChange={setValue}
      packagingComponents={testPackagingComponents}
    />
  );
}

const packagingInitialState: PackagingState = {
  packagingComponents: [],
};

function PackagingStory() {
  const [state, setState] = useState<PackagingState>(packagingInitialState);
  return (
    <Packaging
      packagingComponents={testPackagingComponents}
      state={state}
      onChange={setState}
    />
  );
}

export default stories;
