import * as ColourRatingScaleLabels from "../../utils/Vectors/carbon-labels/colour/rating-scale";
import * as MonochromeRatingScaleLabels from "../../utils/Vectors/carbon-labels/monochrome/rating-scale";
import { labelSizeToDimensions } from "../labelDimensions";
import { LabelSizeOption } from "../labelTypes";
import { BaseCarbonLabelProps } from "./CarbonLabel";

export interface RatingScaleLabelProps extends BaseCarbonLabelProps {
  size: LabelSizeOption;
}

export default function RatingScaleLabel(props: RatingScaleLabelProps) {
  const { className, impactRating, monochrome, size, width } = props;
  const RatingScaleLabels = monochrome
    ? MonochromeRatingScaleLabels
    : ColourRatingScaleLabels;

  const labelDimensions = labelSizeToDimensions("ratingScale", size);
  const labelWidth = width ?? labelDimensions.width;
  switch (impactRating) {
    case "VLOW":
      return (
        <RatingScaleLabels.RatingScaleLabelVLow
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "LOW":
      return (
        <RatingScaleLabels.RatingScaleLabelLow
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "MEDIUM":
      return (
        <RatingScaleLabels.RatingScaleLabelMedium
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "HIGH":
      return (
        <RatingScaleLabels.RatingScaleLabelHigh
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
    case "VHIGH":
      return (
        <RatingScaleLabels.RatingScaleLabelVHigh
          className={className}
          strokeWidth={labelDimensions.strokeWidth}
          width={labelWidth}
        />
      );
  }
}
