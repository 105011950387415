import { useState } from "react";

import { PrimaryButton } from "./Button";
import UpgradeRequestModal from "./UpgradeRequestModal";

const stories = {
  name: "UpgradeRequestModal",
  render: () => <Story />,
};

function Story() {
  const [show, setShow] = useState<boolean>(true);

  return (
    <div>
      <PrimaryButton onClick={() => setShow(true)}>Show</PrimaryButton>
      <UpgradeRequestModal onHide={() => setShow(false)} show={show} />
    </div>
  );
}

export default stories;
