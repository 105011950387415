// Letter Rating Label constants
export const LETTER_RATING_BASE_SVG_WIDTH_PIXELS = 46;
export const LETTER_RATING_HEIGHT_TO_WIDTH_RATIO = 256 / 368;

// Descriptive Impact and Scale Per Kg Label constants
export const DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS = 98;
export const DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_HEIGHT_TO_WIDTH_RATIO =
  208 / DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS;

// Descriptive Impact and Scale Per Serving Label constants
export const DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS = 98;
export const DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_HEIGHT_TO_WIDTH_RATIO =
  228 / DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS;

// Rating Scale Label constants
export const RATING_SCALE_BASE_SVG_WIDTH_PIXELS = 104;
export const RATING_SCALE_HEIGHT_TO_WIDTH_RATIO = 336 / 832;

// Descriptive Rating Label constants
export const DESCRIPTIVE_RATING_BASE_SVG_WIDTH_PIXELS = 127;
export const DESCRIPTIVE_RATING_HEIGHT_TO_WIDTH_RATIO = 336 / 1016;

// Titled Rating Scale Label constants
export const TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS = 130;
export const TITLED_RATING_SCALE_HEIGHT_TO_WIDTH_RATIO =
  62 / TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS;

// Descriptive Rating Scale Label constants
export const DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS = 98;
export const DESCRIPTIVE_RATING_SCALE_HEIGHT_TO_WIDTH_RATIO =
  158 / DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS;

// Dimensional scale factors
export const MEDIUM_SCALE_FACTOR = 2;
export const LARGE_SCALE_FACTOR = 4;
export const X_LARGE_SCALE_FACTOR = 8;
