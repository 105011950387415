import VectorProps from "./VectorProps";

export default function Products(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=djPELc7GvHaLzkcp-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.3399 9.98C14.2699 9.98 14.1999 9.98 14.1299 9.95C13.6999 9.84 13.4499 9.4 13.5599 8.97L14.8199 4.3H13.0599L12.0499 8.07C11.9399 8.5 11.4999 8.75 11.0699 8.64C10.6399 8.53 10.3899 8.09 10.4999 7.66L11.6699 3.3C11.7599 2.95 12.0799 2.71 12.4399 2.71H15.8599C16.1099 2.71 16.3399 2.83 16.4899 3.02C16.6399 3.21 16.6899 3.47 16.6299 3.71L15.0999 9.39C14.9999 9.75 14.6799 9.98 14.3299 9.98H14.3399Z" />
      <path d="M14.4199 19.78C14.3499 19.78 14.2799 19.78 14.2099 19.75C13.7799 19.64 13.5299 19.2 13.6399 18.77L18.1599 1.82H11.2899L9.87994 7.13C9.76994 7.56 9.32994 7.81 8.89994 7.7C8.46994 7.59 8.21994 7.15 8.32994 6.72L9.89994 0.810001C9.98994 0.460001 10.3099 0.220001 10.6699 0.220001H19.1999C19.4499 0.220001 19.6799 0.340001 19.8299 0.530001C19.9799 0.720001 20.0299 0.980001 19.9699 1.22L15.1899 19.18C15.0899 19.54 14.7699 19.77 14.4199 19.77V19.78Z" />
      <path d="M18.4599 19.78H14.1199C13.6799 19.78 13.3199 19.42 13.3199 18.98C13.3199 18.54 13.6799 18.18 14.1199 18.18H17.6599V3.93C17.6599 3.49 18.0199 3.13 18.4599 3.13C18.8999 3.13 19.2599 3.49 19.2599 3.93V18.99C19.2599 19.43 18.8999 19.79 18.4599 19.79V19.78Z" />
      <path d="M2.13994 12.54C1.76994 12.54 1.43994 12.29 1.35994 11.92C1.32994 11.78 1.30994 11.64 1.29994 11.49C1.25994 10.73 1.53994 10 2.06994 9.46C2.59994 8.93 3.33994 8.64 4.08994 8.69C4.35994 8.7 4.62994 8.76 4.88994 8.86C5.36994 8.51 5.92994 8.32 6.51994 8.3C7.00994 7.06 8.22994 6.27 9.57994 6.34C10.5099 6.39 11.3599 6.85 11.9099 7.6C12.1299 7.89 12.2199 8.13 12.3299 8.44L12.3599 8.51C12.7499 8.4 13.1499 8.37 13.5499 8.41C15.0699 8.59 16.2499 9.92 16.2399 11.45C16.2399 11.89 15.8799 12.25 15.4299 12.24C14.9899 12.24 14.6299 11.88 14.6399 11.43C14.6399 10.71 14.0899 10.07 13.3699 9.99C13.1199 9.96 12.8799 9.99 12.6499 10.09C12.3099 10.23 11.9299 10.22 11.5999 10.06C11.2699 9.9 11.0299 9.6 10.9299 9.24L10.8299 8.99C10.7299 8.73 10.6999 8.65 10.6199 8.54C10.3499 8.18 9.93994 7.95 9.48994 7.93C8.79994 7.9 8.16994 8.33 7.96994 8.99C7.79994 9.55 7.25994 9.92 6.67994 9.89C6.33994 9.87 6.01994 9.98 5.75994 10.2C5.37994 10.52 4.84994 10.59 4.40994 10.37C4.28994 10.31 4.15994 10.28 4.01994 10.27C3.72994 10.26 3.42994 10.37 3.21994 10.58C3.00994 10.79 2.89994 11.08 2.91994 11.39C2.91994 11.45 2.92994 11.5 2.93994 11.55C3.03994 11.98 2.76994 12.41 2.33994 12.51C2.27994 12.52 2.21994 12.53 2.15994 12.53L2.13994 12.54Z" />
      <path d="M12.9399 19.78H4.70994C2.92994 19.78 1.41994 18.5 1.12994 16.75L0.0199404 12.01C-0.0400596 11.77 0.0199404 11.52 0.16994 11.33C0.31994 11.14 0.54994 11.03 0.79994 11.03H16.2899C16.5299 11.03 16.7599 11.14 16.9199 11.33C17.0699 11.52 17.1299 11.77 17.0699 12.01L15.9599 16.8C15.5299 19.39 13.8799 19.78 12.9299 19.78H12.9399ZM1.80994 12.63L2.68994 16.44C2.85994 17.47 3.70994 18.19 4.70994 18.19H12.9399C13.5199 18.19 14.1499 17.99 14.3999 16.49L15.2899 12.63H1.80994Z" />
    </svg>
  );
}
