import VectorProps from "../VectorProps";

export default function Processing(props: VectorProps) {
  const { className = "", width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=9G2APmKSJ08dQcJ1-0
  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15510_1093)">
        <path
          d="M18.8998 26.4C26.1347 26.4 31.9998 20.5349 31.9998 13.3C31.9998 6.06508 26.1347 0.200012 18.8998 0.200012C11.6649 0.200012 5.7998 6.06508 5.7998 13.3C5.7998 20.5349 11.6649 26.4 18.8998 26.4Z"
          fill="#67DCC2"
        />
        <path
          d="M3.9343 3.6064L3.84052 3.33833C3.71109 2.96831 3.9056 2.56126 4.2775 2.43116L4.54557 2.33739C7.5208 1.29665 10.7825 2.8678 11.8232 5.84303L11.917 6.1111C12.0464 6.48111 11.8519 6.88817 11.48 7.01826L11.212 7.11203C8.23674 8.15277 4.97504 6.58162 3.9343 3.6064Z"
          fill="#DCFFD1"
        />
        <path
          d="M9.33188 8.534C6.51788 8.534 3.87788 6.774 2.89588 3.97L2.80188 3.702C2.47188 2.76 2.97188 1.724 3.91388 1.394L4.17988 1.3C7.72788 0.0619993 11.6199 1.938 12.8599 5.482L12.9539 5.75C13.2839 6.692 12.7839 7.728 11.8419 8.058L11.5739 8.152C10.8319 8.412 10.0759 8.534 9.33188 8.534ZM5.00988 3.344C5.87388 5.646 8.40988 6.868 10.7479 6.108C9.88388 3.806 7.34988 2.586 5.00988 3.344Z"
          fill="black"
        />
        <path
          d="M18.5972 1.58157L18.8653 1.4878C19.2353 1.35837 19.6424 1.55288 19.7724 1.92478L19.8662 2.19285C20.907 5.16807 19.3358 8.42977 16.3606 9.47052L16.0925 9.56429C15.7225 9.69372 15.3154 9.49922 15.1854 9.12731L15.0916 8.85924C14.0508 5.88402 15.622 2.62232 18.5972 1.58157Z"
          fill="#DCFFD1"
        />
        <path
          d="M15.8579 10.704C15.1099 10.704 14.4079 10.236 14.1459 9.48998L14.0519 9.22198C13.4519 7.50398 13.5559 5.65598 14.3459 4.01798C15.1359 2.37998 16.5159 1.14598 18.2339 0.543978L18.4999 0.449978C19.4419 0.119978 20.4779 0.617978 20.8079 1.56198L20.9019 1.82998C21.5019 3.54798 21.3979 5.39598 20.6079 7.03398C19.8179 8.67398 18.4379 9.90598 16.7199 10.508L16.4519 10.602C16.2539 10.67 16.0539 10.704 15.8559 10.704H15.8579ZM18.8599 2.65798C17.7439 3.07598 16.8479 3.89598 16.3279 4.97198C15.8079 6.04998 15.7279 7.25998 16.0959 8.39398C18.3979 7.52998 19.6179 4.99598 18.8599 2.65598V2.65798Z"
          fill="black"
        />
        <path d="M36.7003 33.1H4.30029V38.7H36.7003V33.1Z" fill="#10675B" />
        <path
          d="M36.7002 39.8H4.3002C3.6922 39.8 3.2002 39.308 3.2002 38.7V33.1C3.2002 32.492 3.6922 32 4.3002 32H36.7002C37.3082 32 37.8002 32.492 37.8002 33.1V38.7C37.8002 39.308 37.3082 39.8 36.7002 39.8ZM5.4002 37.6H35.6002V34.2H5.4002V37.6Z"
          fill="black"
        />
        <path
          d="M2.44201 13.13L8.10801 16.044C8.45601 16.334 8.65801 16.764 8.65801 17.216V18.536C8.65801 19.38 9.34401 20.064 10.188 20.062L15.514 20.044C16.354 20.042 17.034 19.36 17.034 18.518V17.142C17.034 16.694 17.23 16.27 17.572 15.98L23.058 13.118C24.14 12.2 23.49 10.43 22.07 10.43H3.41801C1.99201 10.43 1.34601 12.214 2.44201 13.128V13.13Z"
          fill="#00A68F"
        />
        <path
          d="M10.184 21.162C9.484 21.162 8.826 20.89 8.332 20.396C7.834 19.9 7.56 19.24 7.56 18.536V17.216C7.56 17.118 7.526 17.024 7.466 16.95L1.942 14.108C1.87 14.072 1.804 14.026 1.742 13.976C0.882002 13.26 0.572002 12.118 0.952002 11.066C1.332 10.014 2.302 9.33398 3.42 9.33398H22.074C23.186 9.33398 24.154 10.01 24.538 11.054C24.922 12.098 24.622 13.24 23.774 13.96C23.712 14.014 23.644 14.06 23.57 14.098L18.228 16.884C18.17 16.958 18.138 17.05 18.138 17.146V18.522C18.138 19.964 16.964 21.142 15.522 21.146L10.196 21.164H10.186L10.184 21.162ZM3.084 12.224L8.612 15.068C8.684 15.104 8.75 15.15 8.812 15.2C9.414 15.7 9.758 16.436 9.758 17.218V18.538C9.758 18.692 9.836 18.792 9.882 18.838C9.928 18.884 10.03 18.962 10.182 18.962L15.508 18.944C15.742 18.944 15.932 18.752 15.932 18.52V17.144C15.932 16.372 16.27 15.644 16.858 15.144C16.92 15.09 16.988 15.044 17.062 15.006L22.408 12.218C22.532 12.066 22.502 11.902 22.468 11.812C22.43 11.708 22.328 11.534 22.068 11.534H3.418C3.158 11.534 3.056 11.71 3.018 11.814C2.984 11.906 2.954 12.072 3.084 12.224Z"
          fill="black"
        />
        <path
          d="M4.5002 38.7C6.04659 38.7 7.3002 37.4464 7.3002 35.9C7.3002 34.3536 6.04659 33.1 4.5002 33.1C2.9538 33.1 1.7002 34.3536 1.7002 35.9C1.7002 37.4464 2.9538 38.7 4.5002 38.7Z"
          fill="#10675B"
        />
        <path
          d="M4.5001 34.2C5.4381 34.2 6.2001 34.962 6.2001 35.9C6.2001 36.838 5.4381 37.6 4.5001 37.6C3.5621 37.6 2.8001 36.838 2.8001 35.9C2.8001 34.962 3.5621 34.2 4.5001 34.2ZM4.5001 32C2.3461 32 0.600098 33.746 0.600098 35.9C0.600098 38.054 2.3461 39.8 4.5001 39.8C6.6541 39.8 8.4001 38.054 8.4001 35.9C8.4001 33.746 6.6541 32 4.5001 32Z"
          fill="black"
        />
        <path
          d="M36.5002 38.7C38.0466 38.7 39.3002 37.4464 39.3002 35.9C39.3002 34.3536 38.0466 33.1 36.5002 33.1C34.9538 33.1 33.7002 34.3536 33.7002 35.9C33.7002 37.4464 34.9538 38.7 36.5002 38.7Z"
          fill="#10675B"
        />
        <path
          d="M36.5001 34.2C37.4381 34.2 38.2001 34.962 38.2001 35.9C38.2001 36.838 37.4381 37.6 36.5001 37.6C35.5621 37.6 34.8001 36.838 34.8001 35.9C34.8001 34.962 35.5621 34.2 36.5001 34.2ZM36.5001 32C34.3461 32 32.6001 33.746 32.6001 35.9C32.6001 38.054 34.3461 39.8 36.5001 39.8C38.6541 39.8 40.4001 38.054 40.4001 35.9C40.4001 33.746 38.6541 32 36.5001 32Z"
          fill="black"
        />
        <path
          d="M12.9002 24.6H12.8982C12.8982 24.6 12.8982 24.6 12.9002 24.6ZM12.9002 22.4C11.6842 22.4 10.7002 23.384 10.7002 24.6C10.7002 25.816 11.6842 26.8 12.9002 26.8C14.1162 26.8 15.1002 25.816 15.1002 24.6C15.1002 23.384 14.1162 22.4 12.9002 22.4Z"
          fill="black"
        />
        <path
          d="M19.4002 29.8H19.3982C19.3982 29.8 19.3982 29.8 19.4002 29.8ZM19.4002 27.6C18.1842 27.6 17.2002 28.584 17.2002 29.8C17.2002 31.016 18.1842 32 19.4002 32C20.6162 32 21.6002 31.016 21.6002 29.8C21.6002 28.584 20.6162 27.6 19.4002 27.6Z"
          fill="black"
        />
        <path
          d="M26.5999 29.8H26.5979C26.5979 29.8 26.5979 29.8 26.5999 29.8ZM26.5999 27.6C25.3839 27.6 24.3999 28.584 24.3999 29.8C24.3999 31.016 25.3839 32 26.5999 32C27.8159 32 28.7999 31.016 28.7999 29.8C28.7999 28.584 27.8159 27.6 26.5999 27.6Z"
          fill="black"
        />
        <path
          d="M33.8001 29.8H33.7981C33.7981 29.8 33.7981 29.8 33.8001 29.8ZM33.8001 27.6C32.5841 27.6 31.6001 28.584 31.6001 29.8C31.6001 31.016 32.5841 32 33.8001 32C35.0161 32 36.0001 31.016 36.0001 29.8C36.0001 28.584 35.0161 27.6 33.8001 27.6Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_15510_1093">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
