import VectorProps from "./VectorProps";

export default function CarbonLabelsIcon(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_94)">
        <g clipPath="url(#clip1_1_94)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7504 4.24241C15.752 4.26001 15.7536 4.27761 15.7536 4.29521H15.7528V4.30481V4.31441V13.6128C15.7528 14.8624 14.736 15.8792 13.4864 15.8792H2.60719C1.35759 15.8792 0.34079 14.8624 0.34079 13.6128V2.46641C0.34079 1.21681 1.35759 0.200012 2.60799 0.200012H11.7128C12.0824 0.200012 12.4424 0.352012 12.7 0.616812L15.364 3.35361C15.5984 3.59521 15.7336 3.90881 15.7504 4.24241ZM13.7576 3.85521H14.6232V3.85441L12.0976 1.25921V2.19521C12.0976 3.11041 12.8424 3.85521 13.7576 3.85521ZM2.60799 15H13.4864H13.488C14.252 15 14.8744 14.3784 14.8744 13.6136V4.73601H13.7584C12.3576 4.73601 11.2184 3.59601 11.2184 2.19601V1.08001H2.60799C1.84399 1.08001 1.22159 1.70161 1.22159 2.46641V13.6136C1.22159 14.3776 1.84319 15 2.60799 15ZM6.8024 11.6008H9.2032H9.204C9.768 11.6008 10.2272 11.1416 10.2272 10.5776V10.352H12C12.4976 10.352 12.9024 9.94719 12.9024 9.44959V6.45119C12.9024 5.95359 12.4976 5.54879 12 5.54879H10.2264C10.204 5.00399 9.7536 4.56799 9.204 4.56799H6.8032C6.2528 4.56799 5.8032 5.00399 5.7808 5.54879H4.0072C3.5096 5.54879 3.1048 5.95359 3.1048 6.45119V9.44959C3.1048 9.94719 3.5096 10.352 4.0072 10.352H5.7792V10.5776C5.7792 11.1416 6.2384 11.6008 6.8024 11.6008ZM6.6592 5.59199C6.6592 5.51279 6.724 5.44879 6.8024 5.44879H9.2032C9.2824 5.44879 9.3464 5.51359 9.3464 5.59199V10.5784C9.3464 10.6576 9.2816 10.7216 9.2032 10.7216H6.8024C6.7232 10.7216 6.6592 10.6568 6.6592 10.5784V5.59199ZM11.9984 9.47279H10.2256V6.42959H11.9984C12.0112 6.42959 12.0208 6.43999 12.0208 6.45199V9.45039C12.0208 9.46319 12.0104 9.47279 11.9984 9.47279ZM3.9848 6.45199C3.9848 6.43919 3.9952 6.42959 4.0072 6.42959H5.7792V9.47279H4.0072C3.9944 9.47279 3.9848 9.46239 3.9848 9.45039V6.45199Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_94">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_1_94">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
