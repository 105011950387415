import Card from "./Card";

const stories = {
  name: "Card",
  children: [
    {
      name: "Single card",
      render: () => (
        <div className="h-100 container-fluid">
          <Card>
            <LoremIpsum />
          </Card>
        </div>
      ),
    },
    {
      name: "Row of cards",
      render: () => (
        <div className="h-100 container-fluid">
          <div className="row">
            <div className="col-4">
              <Card>
                <LoremIpsum />
              </Card>
            </div>

            <div className="col-4">
              <Card>
                <LoremIpsum />
              </Card>
            </div>

            <div className="col-4">
              <Card>
                <LoremIpsum />
              </Card>
            </div>
          </div>
        </div>
      ),
    },
  ],
};

export default stories;

function LoremIpsum() {
  return (
    <>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </>
  );
}
