import gql from "graphql-tag";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage, useIntl } from "react-intl";

import "./UpgradeRequestModal.css";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import Form from "./Form";
import {
  UpgradeRequestModal_SendUserMessage as SendUserMessage,
  UpgradeRequestModal_SendUserMessageVariables as SendUserMessageVariables,
} from "./UpgradeRequestModal.graphql";
import useId from "./useId";
import { Plans } from "./Vectors";

interface UpgradeRequestModalProps {
  onHide: () => void;
  show: boolean;
}

export default function UpgradeRequestModal(props: UpgradeRequestModalProps) {
  const { onHide, show } = props;

  const intl = useIntl();
  const subjectInputId = useId();
  const messageInputId = useId();

  const defaultSubject = intl.formatMessage({
    id: "components/utils/UpgradeRequestModal:defaultSubject",
    defaultMessage: "Foodsteps Upgrade Request",
  });
  const [subject, setSubject] = useState(defaultSubject);
  const [message, setMessage] = useState("");
  const [hasSent, setHasSent] = useState(false);

  const [sendUserMessage] = useMutation<
    SendUserMessage,
    SendUserMessageVariables
  >(sendUserMessageMutation);

  function handleHide() {
    setHasSent(false);
    setSubject(defaultSubject);
    setMessage("");
    onHide();
  }

  async function handleSubmit() {
    if (subject === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/utils/UpgradeRequestModal:subjectIsRequired",
          defaultMessage: "Subject is required.",
        })
      );
    } else if (message === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/utils/UpgradeRequestModal:messageIsRequired",
          defaultMessage: "Message is required.",
        })
      );
    }

    await sendUserMessage({
      variables: { input: { subject, body: message } },
    });

    setHasSent(true);
  }

  return (
    <Modal
      centered
      className="UpgradeRequestModal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      show={show}
      size="xl"
    >
      <div className="header-and-copy">
        <Modal.Header className="p-0" closeButton={false}>
          <div className="title-and-icon">
            <Plans className="plans-icon" width={20} />
            <h2 className="mb-0">
              <FormattedMessage
                id="components/utils/UpgradeRequestModal:header"
                defaultMessage="Upgrade Request"
              />
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="components/utils/UpgradeRequestModal:contactUsMessage"
            defaultMessage="Contact us to find out about and unlock Foodsteps features.{br}Your message will be picked up by one of our team and we will get back to you as soon as we can."
            values={{
              br: <br />,
            }}
          />
        </Modal.Body>
      </div>
      <Form className="modal-form-group" onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="modal-form-group">
            <div className="modal-input-group">
              <label className="medium-font mb-0" htmlFor={subjectInputId}>
                <FormattedMessage
                  id="components/utils/UpgradeRequestModal:subjectInputLabel"
                  defaultMessage="Subject"
                />
              </label>
              <textarea
                className="small-form-input"
                disabled={hasSent}
                id={subjectInputId}
                onChange={(event) => setSubject(event.target.value)}
                value={subject}
              />
            </div>
            <div className="modal-input-group">
              <label className="medium-font mb-0" htmlFor={messageInputId}>
                <FormattedMessage
                  id="components/utils/UpgradeRequestModal:messageInputLabel"
                  defaultMessage="Message"
                />
              </label>
              <textarea
                className="large-form-input"
                disabled={hasSent}
                id={messageInputId}
                onChange={(event) => setMessage(event.target.value)}
                placeholder={intl.formatMessage({
                  id: "components/utils/UpgradeRequestModal:messageInputLabel:placeholder",
                  defaultMessage: "Type your message here",
                })}
                value={message}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {hasSent ? (
            <>
              <Alert className="mr-4" variant="success">
                <FormattedMessage
                  id="components/utils/UpgradeRequestModal:messageSent"
                  defaultMessage="Message sent."
                />
              </Alert>
              <Form.SecondaryButton onClick={handleHide}>
                <FormattedMessage
                  id="components/utils/UpgradeRequestModal:closeButton"
                  defaultMessage="Close"
                />
              </Form.SecondaryButton>
            </>
          ) : (
            <div className="buttons">
              <div className="d-flex">
                <Form.ErrorAlert className="mr-4" />
                <div>
                  <Form.SubmitButton
                    loadingLabel={intl.formatMessage({
                      id: "components/utils/UpgradeRequestModal:sendButton/loadingLabel",
                      defaultMessage: "Sending",
                    })}
                    submitLabel={intl.formatMessage({
                      id: "components/utils/UpgradeRequestModal:sendButton/submitLabel",
                      defaultMessage: "Send",
                    })}
                  />
                </div>
              </div>
              <div>
                <Form.SecondaryButton onClick={handleHide}>
                  <FormattedMessage
                    id="components/utils/UpgradeRequestModal:cancelButton"
                    defaultMessage="Cancel"
                  />
                </Form.SecondaryButton>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const sendUserMessageMutation = gql`
  mutation UpgradeRequestModal_SendUserMessage($input: SendUserMessageInput!) {
    sendUserMessage(input: $input) {
      success
    }
  }
`;
