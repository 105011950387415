import VectorProps from "./VectorProps";

export default function Dashboard(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=djPELc7GvHaLzkcp-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.97977 2.95305C1.74987 2.95305 1.56349 3.13942 1.56349 3.36933V8.95783C1.56349 9.18774 1.74987 9.37411 1.97977 9.37411H6.03313C6.26303 9.37411 6.44941 9.18774 6.44941 8.95783V3.36933C6.44941 3.13942 6.26303 2.95305 6.03313 2.95305H1.97977ZM0 3.36933C0 2.27593 0.886374 1.38955 1.97977 1.38955H6.03313C7.12653 1.38955 8.0129 2.27593 8.0129 3.36933V8.95783C8.0129 10.0512 7.12653 10.9376 6.03313 10.9376H1.97977C0.886374 10.9376 0 10.0512 0 8.95783V3.36933Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9914 13.4363C10.7496 13.4363 10.5536 13.6323 10.5536 13.874V17.9987C10.5536 18.2405 10.7496 18.4365 10.9914 18.4365H17.9333C18.175 18.4365 18.371 18.2405 18.371 17.9987V13.874C18.371 13.6323 18.175 13.4363 17.9333 13.4363H10.9914ZM8.99008 13.874C8.99008 12.7688 9.88608 11.8728 10.9914 11.8728H17.9333C19.0385 11.8728 19.9345 12.7688 19.9345 13.874V17.9987C19.9345 19.104 19.0385 20 17.9333 20H10.9914C9.88608 20 8.99008 19.104 8.99008 17.9987V13.874Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.79313 13.4363C1.66631 13.4363 1.56349 13.5391 1.56349 13.6659V18.2069C1.56349 18.3337 1.6663 18.4365 1.79313 18.4365H6.21977C6.3466 18.4365 6.44941 18.3337 6.44941 18.2069V13.6659C6.44941 13.5391 6.34659 13.4363 6.21977 13.4363H1.79313ZM0 13.6659C0 12.6756 0.802811 11.8728 1.79313 11.8728H6.21977C7.21009 11.8728 8.0129 12.6756 8.0129 13.6659V18.2069C8.0129 19.1972 7.21009 20 6.21977 20H1.79313C0.802813 20 0 19.1972 0 18.2069V13.6659Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2913 1.56349C12.0651 1.56349 10.2604 3.36818 10.2604 5.59437C10.2604 7.82056 12.0651 9.62525 14.2913 9.62525C16.5175 9.62525 18.3222 7.82056 18.3222 5.59437C18.3222 3.36818 16.5175 1.56349 14.2913 1.56349ZM8.69693 5.59437C8.69693 2.50469 11.2016 0 14.2913 0C17.381 0 19.8857 2.50469 19.8857 5.59437C19.8857 8.68406 17.381 11.1887 14.2913 11.1887C11.2016 11.1887 8.69693 8.68406 8.69693 5.59437Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2913 0C14.723 0 15.073 0.35 15.073 0.781746V5.32964L17.8359 8.94114C18.0983 9.28405 18.0329 9.77469 17.69 10.037C17.3471 10.2994 16.8565 10.234 16.5941 9.89113L13.6704 6.06937C13.5661 5.933 13.5096 5.76607 13.5096 5.59437V0.781746C13.5096 0.35 13.8596 0 14.2913 0Z"
      />
    </svg>
  );
}
