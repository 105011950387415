import { PasswordStrength } from "./passwordStrength";
import PasswordStrengthBar from "./PasswordStrengthBar";
import "./PasswordStrengthBar.stories.css";

const strengths = [
  PasswordStrength.None,
  PasswordStrength.VeryWeak,
  PasswordStrength.Weak,
  PasswordStrength.Average,
  PasswordStrength.Strong,
  PasswordStrength.VeryStrong,
];

const stories = {
  name: "PasswordStrengthBar",
  children: strengths.map((strength) => ({
    name: strength.toString(),
    render: () => (
      <div className="d-flex w-100 justify-content-end mt-3">
        <PasswordStrengthBar
          barClassName="PasswordStrengthBar__story"
          className="mr-4"
          strength={strength}
        />
      </div>
    ),
  })),
};

export default stories;
