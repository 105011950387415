import packagingComponentEditorStories from "./PackagingComponentEditor/PackagingComponentEditor.stories";
import packagingComponentSelectStories from "./PackagingComponentSelect.stories";
import packagingComponentTableStories from "./PackagingComponentTable.stories";
import packagingSwitchStories from "./PackagingSwitch.stories";

const stories = {
  name: "packaging",
  children: [
    packagingSwitchStories,
    packagingComponentEditorStories,
    packagingComponentTableStories,
    packagingComponentSelectStories,
  ],
};

export default stories;
