import classNames from "classnames";
import React, { useState } from "react";
import BaseDropdown from "react-bootstrap/Dropdown";

import RotatingChevron from "./RotatingChevron";
import "./Dropdown.css";

export interface DropdownProps {
  alignRight?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  menuClassName?: string;
  title: React.ReactNode;
  variant: "primary" | "secondary" | "light" | "link";
}

export default function Dropdown(props: DropdownProps) {
  const {
    alignRight,
    children,
    className,
    disabled = false,
    icon,
    menuClassName,
    title,
    variant,
  } = props;

  const [show, setShow] = useState(false);

  return (
    <BaseDropdown
      alignRight={alignRight}
      show={show}
      onToggle={(show) => setShow(show)}
      className="foodsteps-dropdown"
    >
      <BaseDropdown.Toggle
        className={classNames("medium-font", className)}
        variant={variant}
        disabled={disabled}
      >
        <div className="Dropdown_ToggleContent">
          {icon}
          {title}
          <RotatingChevron expanded={show} />
        </div>
      </BaseDropdown.Toggle>
      <BaseDropdown.Menu
        className={classNames("border-0 py-0 shadow", menuClassName)}
      >
        {children}
      </BaseDropdown.Menu>
    </BaseDropdown>
  );
}

Dropdown.Item = BaseDropdown.Item;
Dropdown.Divider = BaseDropdown.Divider;

type VariantDropdownProps = Omit<DropdownProps, "variant">;

export function PrimaryDropdown(props: VariantDropdownProps) {
  return <Dropdown {...props} variant="primary" />;
}

PrimaryDropdown.Item = Dropdown.Item;
PrimaryDropdown.Divider = Dropdown.Divider;

export function LightDropdown(props: VariantDropdownProps) {
  return <Dropdown {...props} variant="light" />;
}

LightDropdown.Item = Dropdown.Item;
LightDropdown.Divider = Dropdown.Divider;
