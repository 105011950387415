import { FormattedMessage } from "react-intl";

import useDataQualityScoreToRatingString from "./useDataQualityScoreToRatingString";

export interface DQS {
  rating: string;
  score: number;
}

interface DQSTextProps {
  dataQualityScore: number | null;
}

export default function DQSText(props: DQSTextProps) {
  const { dataQualityScore } = props;
  return (
    <div className="small">
      {dataQualityScore ? (
        <NonNullDQSText dataQualityScore={dataQualityScore} />
      ) : (
        <NullDQSText />
      )}
    </div>
  );
}

interface NonNullDQSTextProps {
  dataQualityScore: number;
}

function NonNullDQSText(props: NonNullDQSTextProps) {
  const { dataQualityScore } = props;

  const dataQualityScoreToRatingString = useDataQualityScoreToRatingString();

  return (
    <>
      <span className="medium-font">
        {dataQualityScoreToRatingString(dataQualityScore)}{" "}
        <FormattedMessage
          id="components/recipes/DQSText:nonNullDQSText"
          defaultMessage="DQS"
        />
      </span>
      <span className="text-muted">
        {" | "}
        {dataQualityScore.toFixed(1)}/5
      </span>
    </>
  );
}

function NullDQSText() {
  return (
    <div className="text-muted">
      <FormattedMessage
        id="components/recipes/DQSText:nullDQSText"
        defaultMessage="DQS Unavailable"
      />
    </div>
  );
}
