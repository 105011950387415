import { FormattedMessage } from "react-intl";

import {
  allUnitStringsForParsing,
  simpleIngredientUnitStringsForParsing,
} from "../../domain/units";
import UserVisibleError from "../../util/UserVisibleError";

export class InvalidUnitError extends UserVisibleError {
  invalidUnit: string;
  columnName: string;
  recipeName: string;
  rowNumber: number;

  constructor(
    invalidUnit: string,
    columnName: string,
    recipeName: string,
    rowNumber: number
  ) {
    super(
      "Invalid unit found in " + columnName + " column: " + invalidUnit,
      <FormattedMessage
        id="components/recipes/recipes-csv-errors:invalidUnitErrorMessage"
        defaultMessage="We did not recognise the unit in the <medium>{columnName}</medium> column for product <medium>{recipeName}</medium> on <medium>row {rowNumber}</medium>: <em>{invalidUnit}</em>. Please use one of the following units instead: {validOptions}."
        values={{
          invalidUnit,
          columnName,
          recipeName,
          rowNumber,
          validOptions: Array.from(allUnitStringsForParsing).join(", "),
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
        }}
      />
    );
    this.invalidUnit = invalidUnit;
    this.columnName = columnName;
    this.recipeName = recipeName;
    this.rowNumber = rowNumber;
  }
}

export class SubrecipeOnlyInvalidUnitError extends UserVisibleError {
  ingredientName: string;
  invalidUnit: string;
  recipeName: string;
  rowNumber: number;

  constructor(
    ingredientName: string,
    invalidUnit: string,
    recipeName: string,
    rowNumber: number
  ) {
    super(
      "Unit " +
        invalidUnit +
        " found for ingredient " +
        ingredientName +
        " can only be used for ingredients which are themselves products.",
      <FormattedMessage
        id="components/recipes/recipes-csv-errors:subrecipeOnlyInvalidUnitErrorMessage"
        defaultMessage="Unit <em>{invalidUnit}</em> for ingredient <medium>{ingredientName}</medium> in product <medium>{recipeName}</medium> on <medium>row {rowNumber}</medium> can only be used for ingredients which are themselves products. Please use one of the following units instead: {validOptions}."
        values={{
          ingredientName,
          invalidUnit,
          recipeName,
          rowNumber,
          validOptions: Array.from(simpleIngredientUnitStringsForParsing).join(
            ", "
          ),
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
        }}
      />
    );
    this.ingredientName = ingredientName;
    this.invalidUnit = invalidUnit;
    this.recipeName = recipeName;
    this.rowNumber = rowNumber;
  }
}

export class MissingColumnError extends UserVisibleError {
  columnName: string;

  constructor(columnName: string) {
    super(
      "Could not find a " + columnName + " column.",
      <FormattedMessage
        id="components/recipes/recipes-csv-errors:missingColumnErrorMessage"
        defaultMessage="Could not find a <medium>{columnName}</medium> column."
        values={{
          columnName,
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
        }}
      />
    );
    this.columnName = columnName;
  }
}

export class ConflictingRecipeDataError extends UserVisibleError {
  columnName: string;
  recipeName: string;
  value1: Array<string> | string | number | null;
  value2: Array<string> | string | number | null;
  constructor(
    value1: Array<string> | string | number | null,
    value2: Array<string> | string | number | null,
    columnName: string,
    recipeName: string
  ) {
    super(
      `The product ${recipeName} had conflicting values in the ${columnName} column: ${value1}, ${value2}.`,
      <FormattedMessage
        id="components/recipes/recipes-csv-errors:conflictingRecipeDataErrorMessage"
        defaultMessage="The product <medium>{recipeName}</medium> had conflicting values in the <medium>{columnName}</medium> column: <em>{value1}</em> and <em>{value2}</em>."
        values={{
          columnName,
          recipeName,
          value1: `"${value1 ? value1 : ""}"`,
          value2: `"${value2 ? value2 : ""}"`,
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
        }}
      />
    );
    this.columnName = columnName;
    this.recipeName = recipeName;
    this.value1 = value1;
    this.value2 = value2;
  }
}

export class RecipeWithDuplicateNameHasMissingInformationError extends UserVisibleError {
  columnName: string;
  recipeName: string;
  rowIndex: number;
  constructor(columnName: string, recipeName: string, rowIndex: number) {
    super(
      `The product ${recipeName} on row ${rowIndex} had missing value in the ${columnName} column. Products with duplicate names cannot have an empty value for ${columnName}.`,
      <FormattedMessage
        id="components/recipes/recipes-csv-errors:recipeWithDuplicateNameHasMissingInformationError"
        defaultMessage="The product <medium>{recipeName}</medium> on <medium>row {rowIndex}</medium> had missing value in the <medium>{columnName}</medium> column. Products with duplicate names cannot have an empty value for <medium>{columnName}</medium>."
        values={{
          columnName,
          recipeName,
          rowIndex,
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
        }}
      />
    );
    this.columnName = columnName;
    this.recipeName = recipeName;
    this.rowIndex = rowIndex;
  }
}

export class DuplicateRecipesConflictingDataError extends UserVisibleError {
  columnName: string;
  recipeName: string;
  rowIndex1: number;
  rowIndex2: number;
  value: string;
  constructor(
    columnName: string,
    recipeName: string,
    rowIndex1: number,
    rowIndex2: number,
    value: string
  ) {
    super(
      `A product with name ${recipeName} appears on rows ${rowIndex1} and ${rowIndex2} with duplicate value in column ${columnName}: ${value}. Products with duplicate names must have a distinct value in the ${columnName} column.`,
      <FormattedMessage
        id="components/recipes/recipes-csv-errors:duplicateRecipesConflictingDataError"
        defaultMessage="A product with name <medium>{recipeName}</medium> appears on <medium>rows {rowIndex1} and {rowIndex2}</medium> with duplicate value in column <medium>{columnName}</medium>: <em>{value}</em>. Products with duplicate names must have a distinct value in the <medium>{columnName}</medium> column."
        values={{
          columnName,
          recipeName,
          rowIndex1,
          rowIndex2,
          value,
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
        }}
      />
    );
    this.columnName = columnName;
    this.recipeName = recipeName;
    this.rowIndex1 = rowIndex1;
    this.rowIndex2 = rowIndex2;
    this.value = value;
  }
}
