import { useState } from "react";

import * as PackagingMaterialSelect from "./PackagingMaterialSelect";

const stories = {
  name: "PackagingMaterialSelect",
  children: [
    {
      name: "initially blank",
      render: () => <Story defaultValue={null} />,
    },
    {
      name: "initially set",
      render: () => <Story defaultValue={packagingMaterials[0]} />,
    },
    {
      name: "error state",
      render: () => <Story defaultValue={null} hasError />,
    },
  ],
};

interface StoryProps {
  defaultValue: PackagingMaterialSelect.Value;
  hasError?: boolean;
}

function Story(props: StoryProps) {
  const { defaultValue, hasError = false } = props;

  const [value, setValue] = useState(defaultValue);
  return (
    <PackagingMaterialSelect.PackagingMaterialSelect
      hasError={hasError}
      onChange={(value) => setValue(value)}
      options={packagingMaterials}
      value={value}
    />
  );
}

const packagingMaterials = [
  { id: 1, name: "Cardboard" },
  { id: 2, name: "HDPE" },
];

export default stories;
