import gql from "graphql-tag";
import { useMemo } from "react";

import * as comparators from "../../util/comparators";
import sort from "../../util/sort";
import Select from "../utils/Select";
import { PackagingMaterialSelect_PackagingMaterial as PackagingMaterial } from "./PackagingMaterialSelect.graphql";

export type Value = PackagingMaterial | null;

interface PackagingMaterialSelectProps {
  hasError: boolean;
  onChange: (value: Value) => void;
  options: Array<PackagingMaterial>;
  value: Value;
}

export function PackagingMaterialSelect(props: PackagingMaterialSelectProps) {
  const { hasError, onChange, options, value } = props;

  const sortedOptions = useMemo(
    () =>
      sort(
        options,
        comparators.map(
          (option) => option.name,
          comparators.stringSensitivityBase
        )
      ),
    [options]
  );

  return (
    <Select
      hasError={hasError}
      onChange={(value) => onChange(value)}
      optionKey={packagingMaterialKey}
      options={sortedOptions}
      renderOption={(packagingMaterial) => packagingMaterial.name}
      value={value}
    />
  );
}

function packagingMaterialKey(packagingMaterial: PackagingMaterial) {
  return packagingMaterial.id.toString();
}

export const fragments = {
  packagingMaterial: gql`
    fragment PackagingMaterialSelect_PackagingMaterial on PackagingMaterial {
      id
      name
    }
  `,
};
