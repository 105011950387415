import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import scrollToTop from "../../../util/scrollToTop";
import { LightButton } from "../../utils/Button";
import * as FloatInput from "../../utils/FloatInput";
import { Panel } from "../../utils/Panel";
import TooltipOverlay from "../../utils/TooltipOverlay";
import { ChevronLeft, Information } from "../../utils/Vectors";
import PhysicalInputsEditor from "./PhysicalInputsEditor";
import PhysicalOutputsEditor from "./PhysicalOutputEditor";
import { ProductEditorState } from "./ProductEditor";
import SiteLocation from "./SiteLocation";
import * as SiteNameEditor from "./SiteNameEditor";

import "./Processing.css";

export type ProcessingState = Pick<
  ProductEditorState,
  "amountKwh" | "physicalOutput" | "ingredients" | "siteName"
>;

interface ProcessingProps {
  contentContainerRef?: any;
  showSiteNameSection: boolean;
  state: ProcessingState;
  onChange: (newState: ProcessingState) => void;
  onBack: () => void;
}

export default function Processing(props: ProcessingProps) {
  const { contentContainerRef, showSiteNameSection, state, onBack, onChange } =
    props;

  useEffect(() => {
    scrollToTop(contentContainerRef);
  }, [contentContainerRef]);

  return (
    <Panel className="Processing__Panel">
      <div className="Processing__Panel__Section">
        <h2 className="mb-0">
          <FormattedMessage
            id="components/recipes/ProductEditor/Processing:processing"
            defaultMessage="Processing"
          />
        </h2>
      </div>
      <div className="Processing__Panel__Section Processing__Panel__Section__Border">
        {showSiteNameSection && (
          <SiteNameEditor.SiteNameEditor
            value={state.siteName}
            onChange={(siteName) => onChange({ ...state, siteName })}
          />
        )}
        <SiteLocation />
      </div>
      <div className="Processing__Panel__Section Processing__Panel__Section__Border">
        <div className="Processing__IngredientQuantityInstructions">
          <IngredientQuantityInstructions />
        </div>
        <PhysicalInputsEditor
          physicalInputs={state.ingredients}
          setPhysicalInputs={(physicalInputs) => {
            onChange({ ...state, ingredients: physicalInputs });
          }}
        />
        <LightButton
          {...props}
          className="Processing_AddIngredientButton"
          onClick={onBack}
          icon={
            <ChevronLeft
              className="Processing_AddIngredientChevron"
              width={"12px"}
            />
          }
        >
          <FormattedMessage
            id="components/recipes/ProductEditor/Processing:addMoreIngredients"
            defaultMessage="Add more ingredients"
          />
        </LightButton>
      </div>
      <div className="Processing__Panel__Section Processing__Panel__Section__Border">
        <EnergyUse
          amountKwh={state.amountKwh}
          onChange={(amountKwh) => onChange({ ...state, amountKwh })}
        />
      </div>
      <div className="Processing__Panel__Section">
        <PhysicalOutputsEditor
          onChange={(value) => onChange({ ...state, physicalOutput: value })}
          value={state.physicalOutput}
        />
      </div>
    </Panel>
  );
}

interface EnergyUseProps {
  amountKwh: FloatInput.Value;
  onChange: (amountKwh: FloatInput.Value) => void;
}

function EnergyUse(props: EnergyUseProps) {
  const { amountKwh, onChange } = props;

  return (
    <div className="EnergyUseEditor">
      <div>
        <label htmlFor="processing-energy-use">
          <h4 className="mb-0">
            <FormattedMessage
              id="components/recipes/ProductEditor/Processing:energyUse"
              defaultMessage="Energy Usage"
            />
          </h4>
        </label>
        <div className="text-muted">
          <FormattedMessage
            id="components/recipes/ProductEditor/Processing:energyUseHelp"
            defaultMessage="Specify the amount of energy used"
          />
        </div>
      </div>
      <div className="input-group" style={{ width: "160px" }}>
        <FloatInput.FloatInput
          id="processing-energy-use"
          onChange={(amountKwh) => onChange(amountKwh)}
          value={amountKwh}
        />
        <div className="input-group-append" style={{ lineHeight: "1rem" }}>
          <span className="input-group-text">
            <FormattedMessage
              id="components/recipes/ProductEditor/Processing:energyUseUnit"
              defaultMessage="kWh"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function ProductScaleTooltipOverlayText() {
  return (
    <div className="text-left">
      <div className="medium-font">
        <FormattedMessage
          id="components/recipes/ProductEditor/PhysicalInputsEditor:forExample"
          defaultMessage="This could be, for example:"
        />
      </div>
      <div>
        <div>
          •{" "}
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalInputsEditor:singleProduct"
            defaultMessage="a single product"
          />
        </div>
        <div>
          •{" "}
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalInputsEditor:knownQuantity"
            defaultMessage="a known quantity of product (e.g. 1 kg)"
          />
        </div>
        <div>
          •{" "}
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalInputsEditor:singleBatchRun"
            defaultMessage="a single batch run"
          />
        </div>
        <div>
          •{" "}
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalInputsEditor:dayOfProcessing"
            defaultMessage="a day of processing"
          />
        </div>
      </div>
    </div>
  );
}

function IngredientQuantityInstructions() {
  return (
    <div className="d-flex flex-row">
      <Information width={20} textFill="black" fill="var(--interface-grey)" />
      <div className="d-flex my-auto ml-2 flex-row">
        <FormattedMessage
          id="components/recipes/ProductEditor/PhysicalInputsEditor:ingredientQuantityInstructions"
          defaultMessage="Enter quantities below at <Tooltip>any scale</Tooltip> you have data for."
          values={{
            Tooltip: (chunks: React.ReactNode) => (
              <TooltipOverlay
                id="productScaleTooltip"
                overlay={<ProductScaleTooltipOverlayText />}
                placement="top"
              >
                <div className="action-link px-1">{chunks}</div>
              </TooltipOverlay>
            ),
          }}
        />
      </div>
    </div>
  );
}
