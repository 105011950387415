import { useState } from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import ImpactCategoryToggle, {
  ImpactCategoryToggleType,
} from "./ImpactCategoryToggle";

const stories = {
  name: "ImpactCategoryToggle",
  children: [
    {
      name: "Title toggle",
      render: () => <Story type={ImpactCategoryToggleType.TITLE} />,
    },
    {
      name: "Page toggle",
      render: () => <Story type={ImpactCategoryToggleType.PAGE} />,
    },
    {
      name: "Unavailable land and water use",
      render: () => <Story type={ImpactCategoryToggleType.PAGE} disabled />,
    },
  ],
};

interface StoryProps {
  disabled?: boolean;
  type: ImpactCategoryToggleType;
}

function Story(props: StoryProps) {
  const { disabled = false, type } = props;
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  return (
    <ImpactCategoryToggle
      onChange={setImpactCategory}
      options={Object.values(ImpactCategory)}
      selectedImpactCategory={impactCategory}
      type={type}
      disabled={() => disabled}
    />
  );
}

export default stories;
