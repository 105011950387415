import RegionSelectionForm from "./RegionSelectionForm";

const stories = {
  name: "RegionSelectionForm",
  render: () => (
    <RegionSelectionForm
      onComplete={(selectedRegion) => {
        alert(selectedRegion.name);
      }}
    />
  ),
};

export default stories;
