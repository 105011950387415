import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useCallbackOne } from "use-memo-one";

import {
  changeOpacity,
  infoCol,
  foodstepsTurquoiseCol,
  interfaceGrey,
} from "../graphs/colors";
import StatusDisplay from "../StatusDisplay";
import usePromise from "../utils/usePromise";

const publishableKey: string = (window as any).FOODSTEPS_STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(publishableKey);
// Define appearanceTheme as below to avoid typescript error
// Type 'string' not assignable to "none" | "stripe" | "night" | "flat" | undefined'
let appearanceTheme = "none" as const;
/**
 * Pass custom font stylesheet URL to the Element object https://stripe.com/docs/js/appendix/css_font_source_object
 * so that it can load the fontFamily specified in the appearance constant below.
 */

const lightPrimaryCol = changeOpacity(foodstepsTurquoiseCol, "0.5");
const veryLightPrimaryCol = changeOpacity(foodstepsTurquoiseCol, "0.25");

const thinLightGreyBorder = `0.0625rem solid ${interfaceGrey}`;

const fonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=Epilogue",
  },
];

const appearance = {
  theme: appearanceTheme,
  variables: {
    /**
     * This is the font family we use in the rest of the app and needs to be updated if that font family changes
     */
    fontFamily: "Epilogue",
    colorIcon: infoCol,
    colorText: infoCol,
    borderRadius: "0.375rem",
    // Size of label text above input elements
    fontSizeSm: "1rem",
    // Size of TermsText below elements
    fontSize3Xs: "0.8rem",
    spacingGridColumn: "1rem",
  },
  rules: {
    ".Tab": {
      border: thinLightGreyBorder,
      fontSize: "1rem",
      fontWeight: "500",
    },
    ".Tab--selected": {
      borderColor: foodstepsTurquoiseCol,
      backgroundColor: veryLightPrimaryCol,
    },
    ".Tab:hover": {
      borderColor: foodstepsTurquoiseCol,
    },
    ".TabLabel": {
      color: infoCol,
    },
    ".Label": {
      marginBottom: "0.5rem",
      marginTop: "1rem",
    },
    // Rules for input elements
    ".Input": {
      border: thinLightGreyBorder,
      paddingBottom: "0.5rem",
      paddingTop: "0.5rem",
    },
    ".Input:focus": {
      borderColor: lightPrimaryCol,
      boxShadow: `0 0 0 0.2rem ${veryLightPrimaryCol}`,
      outline: "0",
    },
    ".Error": {
      fontSize: "0.8rem",
    },
    ".Block": {
      border: thinLightGreyBorder,
    },
  },
};

interface StripeElementsPaymentProviderProps {
  children: React.ReactNode;
  clientSecret?: string;
}

export default function StripeElementsPaymentProvider(
  props: StripeElementsPaymentProviderProps
) {
  const { clientSecret, children } = props;

  const [stripeStatus] = usePromise(
    useCallbackOne(() => {
      return stripePromise;
    }, [])
  );

  return (
    <StatusDisplay status={stripeStatus}>
      {(stripe) => (
        <Elements stripe={stripe} options={{ clientSecret, appearance, fonts }}>
          {children}
        </Elements>
      )}
    </StatusDisplay>
  );
}
