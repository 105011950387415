import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import * as statuses from "../../../util/statuses";
import useQuery from "../../graphql/useQuery";
import { useOrganizationId } from "../../organizations/OrganizationProvider";
import StatusDisplay from "../../StatusDisplay";
import { Location } from "../../utils/Vectors";
import {
  SiteLocation_ConsumptionLocationNameQuery,
  SiteLocation_ConsumptionLocationNameQueryVariables,
} from "./SiteLocation.graphql";

export default function SiteLocation() {
  const consumptionLocationNameStatus = useConsumptionLocationName();

  return (
    <StatusDisplay status={consumptionLocationNameStatus}>
      {(data) => (
        <>
          <label htmlFor="location">
            <h4>
              <FormattedMessage
                id="components/recipes/ProductEditor/SiteLocation.tsx"
                defaultMessage="Location"
              />
            </h4>
          </label>
          <div className="d-flex h-100 flex-row">
            <Location width={14} fill="var(--interface-grey)" />
            <div className="my-auto pl-2">{data.consumptionLocationName}</div>
          </div>
        </>
      )}
    </StatusDisplay>
  );
}

function useConsumptionLocationName(): statuses.Status<SiteLocation_ConsumptionLocationNameQuery> {
  const [organizationId] = useOrganizationId();

  const { status } = useQuery<
    SiteLocation_ConsumptionLocationNameQuery,
    SiteLocation_ConsumptionLocationNameQueryVariables
  >(consumptionLocationNameQuery, { organizationId });

  return status;
}

const consumptionLocationNameQuery = gql`
  query SiteLocation_ConsumptionLocationNameQuery($organizationId: UUID!) {
    consumptionLocationName(organizationId: $organizationId)
  }
`;
