import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import TooltipOverlay from "./TooltipOverlay";
import useId from "./useId";
import { Grid, Table } from "./Vectors";

import "./GridListToggle.css";

export type State = "grid" | "list";

interface GridListToggleProps {
  setState: (state: State) => void;
  state: State;
}

export function GridListToggle(props: GridListToggleProps) {
  const { setState, state } = props;

  const intl = useIntl();

  const iconWidth = "1.25em";

  return (
    <div className="d-flex GridListToggle" role="group">
      <GridListToggleLabel
        accessibilityLabel={intl.formatMessage({
          id: "components/utils/GridListToggle:gridView",
          defaultMessage: "Grid view",
        })}
        active={state === "grid"}
        onClick={() => setState("grid")}
        overlay={
          <FormattedMessage
            id="components/utils/GridListToggle:gridViewTooltipMessage"
            defaultMessage="Grid view"
          />
        }
      >
        <Grid width={iconWidth} />
      </GridListToggleLabel>
      <GridListToggleLabel
        accessibilityLabel={intl.formatMessage({
          id: "components/utils/GridListToggle:listViewAccessibilityLabel",
          defaultMessage: "List view",
        })}
        active={state === "list"}
        onClick={() => setState("list")}
        overlay={
          <FormattedMessage
            id="components/utils/GridListToggle:listViewTooltipMessage"
            defaultMessage="List view"
          />
        }
      >
        <Table width={iconWidth} />
      </GridListToggleLabel>
    </div>
  );
}

interface GridListToggleLabelProps {
  accessibilityLabel: string;
  active: boolean;
  children: React.ReactNode;
  onClick: () => void;
  overlay: React.ReactNode;
}

function GridListToggleLabel(props: GridListToggleLabelProps) {
  const { accessibilityLabel, active, children, onClick, overlay } = props;

  const overlayId = useId();

  return (
    <TooltipOverlay id={overlayId} overlay={overlay} placement="top">
      <label
        aria-label={accessibilityLabel}
        className={classNames("btn btn-link GridListToggle__label", {
          GridListToggle__label__active: active,
          GridListToggle__label__inactive: !active,
        })}
        onClick={onClick}
      >
        {children}
      </label>
    </TooltipOverlay>
  );
}
