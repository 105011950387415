import VectorProps from "../VectorProps";

export default function Cooking(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Ksp5K23jLgLje03Y-0
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_1959)">
        <g clipPath="url(#clip1_2079_1959)">
          <path
            d="M21.28 29.6C27.068 29.6 31.76 24.908 31.76 19.12C31.76 13.3321 27.068 8.64001 21.28 8.64001C15.4921 8.64001 10.8 13.3321 10.8 19.12C10.8 24.908 15.4921 29.6 21.28 29.6Z"
            fill="#67DCC2"
          />
          <path
            d="M5.83995 11.28C7.29798 11.28 8.47995 10.098 8.47995 8.64C8.47995 7.18197 7.29798 6 5.83995 6C4.38192 6 3.19995 7.18197 3.19995 8.64C3.19995 10.098 4.38192 11.28 5.83995 11.28Z"
            fill="#DCFFD1"
          />
          <path
            d="M5.84007 6.87999C6.81127 6.87999 7.60007 7.66879 7.60007 8.63999C7.60007 9.61119 6.81127 10.4 5.84007 10.4C4.86887 10.4 4.08007 9.61119 4.08007 8.63999C4.08007 7.66879 4.86887 6.87999 5.84007 6.87999ZM5.84007 5.12C3.89607 5.12 2.32007 6.69599 2.32007 8.63999C2.32007 10.584 3.89607 12.16 5.84007 12.16C7.78407 12.16 9.36007 10.584 9.36007 8.63999C9.36007 6.69599 7.78407 5.12 5.84007 5.12Z"
            fill="black"
          />
          <path
            d="M12.5599 6.32001C14.018 6.32001 15.1999 5.13804 15.1999 3.68001C15.1999 2.22198 14.018 1.04001 12.5599 1.04001C11.1019 1.04001 9.91992 2.22198 9.91992 3.68001C9.91992 5.13804 11.1019 6.32001 12.5599 6.32001Z"
            fill="#DCFFD1"
          />
          <path
            d="M12.56 1.92C13.5312 1.92 14.32 2.7088 14.32 3.68C14.32 4.6512 13.5312 5.44 12.56 5.44C11.5888 5.44 10.8 4.6512 10.8 3.68C10.8 2.7088 11.5888 1.92 12.56 1.92ZM12.56 0.160004C10.616 0.160004 9.04004 1.736 9.04004 3.68C9.04004 5.624 10.616 7.2 12.56 7.2C14.504 7.2 16.08 5.624 16.08 3.68C16.08 1.736 14.504 0.160004 12.56 0.160004Z"
            fill="black"
          />
          <path
            d="M20.9999 17.5824C20.9503 17.5824 20.9007 17.5776 20.8495 17.5696C20.3711 17.4864 20.0495 17.032 20.1311 16.5536C20.2367 15.9424 20.0975 15.328 19.7407 14.8208C19.3839 14.3136 18.8495 13.9776 18.2399 13.872C17.6287 13.7664 17.0143 13.9056 16.5071 14.2624C16.0015 14.6192 15.6639 15.1536 15.5583 15.7632C15.4751 16.2416 15.0207 16.5648 14.5423 16.4816C14.0639 16.3984 13.7423 15.944 13.8239 15.4656C14.0095 14.392 14.6015 13.4544 15.4911 12.8256C16.3823 12.1968 17.4639 11.9536 18.5375 12.1376C19.6111 12.3232 20.5487 12.9152 21.1775 13.8048C21.8063 14.6944 22.0495 15.7776 21.8655 16.8512C21.7919 17.28 21.4191 17.5824 20.9999 17.5824Z"
            fill="black"
          />
          <path
            d="M11.4221 14.6434L24.4082 16.8818C26.0686 17.168 27.1827 18.7482 26.8968 20.407L26.8443 20.7113L7.84454 17.4363L7.89699 17.1319C8.18318 15.4716 9.7634 14.3575 11.4221 14.6434Z"
            fill="#10675B"
          />
          <path
            d="M26.8433 21.5936C26.7937 21.5936 26.7441 21.5888 26.6945 21.5808L7.69448 18.304C7.46408 18.264 7.25928 18.1344 7.12488 17.944C6.99048 17.7536 6.93768 17.5168 6.97608 17.2864L7.02888 16.9824C7.20808 15.9472 7.77768 15.0448 8.63529 14.4384C9.49289 13.8336 10.5361 13.5984 11.5697 13.776L24.5553 16.016C25.5905 16.1936 26.4929 16.7648 27.0993 17.6224C27.7041 18.48 27.9393 19.5232 27.7617 20.5568L27.7089 20.8608C27.6689 21.0912 27.5393 21.296 27.3489 21.4304C27.2001 21.536 27.0225 21.592 26.8417 21.592L26.8433 21.5936ZM8.93448 16.7328L26.0513 19.6848C26.0161 19.312 25.8849 18.9536 25.6625 18.64C25.3281 18.1664 24.8289 17.8512 24.2577 17.752L11.2721 15.512C10.7009 15.4128 10.1249 15.544 9.65128 15.8784C9.33768 16.0992 9.09288 16.3936 8.93448 16.7328Z"
            fill="black"
          />
          <path
            d="M7.68009 24.4688C7.56809 24.4688 7.45449 24.448 7.34409 24.4016L3.69449 22.8928C3.24489 22.7072 3.03209 22.192 3.21769 21.744C3.40329 21.2944 3.91849 21.0816 4.36649 21.2672L8.01609 22.776C8.46569 22.9616 8.67849 23.4768 8.49289 23.9248C8.35209 24.264 8.02409 24.4688 7.68009 24.4688Z"
            fill="black"
          />
          <path
            d="M27.2304 24.5344C26.8896 24.5344 26.5648 24.336 26.4224 24.0032C26.2304 23.5568 26.4352 23.0384 26.8816 22.8464L30.5312 21.272C30.9776 21.0784 31.496 21.2848 31.688 21.7312C31.88 22.1776 31.6752 22.696 31.2288 22.888L27.5792 24.4624C27.4656 24.512 27.3472 24.5344 27.2304 24.5344Z"
            fill="black"
          />
          <path
            d="M15.84 30.24C15.5936 30.24 15.3472 30.136 15.1728 29.9344L1.09441 13.6144C0.777613 13.2464 0.817613 12.6912 1.18561 12.3728C1.55361 12.0544 2.10881 12.096 2.42721 12.464L16.5072 28.784C16.824 29.152 16.784 29.7072 16.416 30.0256C16.2496 30.1696 16.0448 30.24 15.8416 30.24H15.84Z"
            fill="black"
          />
          <path
            d="M7.74707 22.32H27.0271V28.6096C27.0271 29.9072 25.9743 30.96 24.6767 30.96H10.0975C8.79987 30.96 7.74707 29.9072 7.74707 28.6096V22.32Z"
            fill="#00A68F"
          />
          <path
            d="M24.6768 31.84H10.0976C8.31679 31.84 6.86719 30.3904 6.86719 28.6096V22.32C6.86719 21.8336 7.26079 21.44 7.74719 21.44H27.0272C27.5136 21.44 27.9072 21.8336 27.9072 22.32V28.6096C27.9072 30.3904 26.4576 31.84 24.6768 31.84ZM8.62719 23.2V28.6096C8.62719 29.4208 9.28639 30.08 10.0976 30.08H24.6768C25.488 30.08 26.1472 29.4208 26.1472 28.6096V23.2H8.62719Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2079_1959">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_2079_1959">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
