import { FormattedMessage } from "react-intl";

import { IngredientsNeedAttention } from "../utils/Vectors";
import "./UpdateIngredientsBanner.css";

interface UpdateIngredientsBannerProps {
  numUnlinkedIngredientNames: number;
  onClick: () => void;
}

export default function UpdateIngredientsBanner(
  props: UpdateIngredientsBannerProps
) {
  const { numUnlinkedIngredientNames, onClick } = props;
  return (
    <div className="UpdateIngredientsBanner_container d-flex flex-row">
      <div className="position-relative mr-2">
        <IngredientsNeedAttention width={40} />
      </div>
      <div className="small">
        <div className="semi-bold-font">
          <FormattedMessage
            id="components/ingredients/UpdateIngredientsBanner:title"
            defaultMessage="Update ingredients"
          />
        </div>
        <>
          <FormattedMessage
            id="components/ingredients/UpdateIngredientsBanner:body"
            defaultMessage="Your products contain <medium>{numUnlinkedIngredientNames, plural, one {# ingredient} other {# ingredients}}</medium> that {numUnlinkedIngredientNames, plural, one {is} other {are}} not in the Foodsteps database. <link>Update now.</link>"
            values={{
              link: (chunks: React.ReactNode) => (
                <span className="action-link medium-font" onClick={onClick}>
                  {" "}
                  {chunks}
                </span>
              ),
              numUnlinkedIngredientNames,
              medium: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
            }}
          />
        </>
      </div>
    </div>
  );
}
