import { LightButton } from "./Button";
import BackArrow from "./Vectors/BackArrow";
import "./BackButton.css";

type BackButtonProps = { back: () => void };

export default function BackButton(props: BackButtonProps) {
  return (
    <LightButton
      className="BackButton"
      icon={<BackArrow className="BackButton__Icon" width={"16"} />}
      onClick={props.back}
    >
      {null}
    </LightButton>
  );
}
