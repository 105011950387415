import gql from "graphql-tag";
import { createContext, useContext } from "react";

import {
  extractNodesFromPagedQueryResult,
  usePagedQueryFetchAll,
} from "../components/graphql/usePagedQuery";
import { useOrganizationOrNull } from "../components/organizations/OrganizationProvider";
import ProductFilterSelect from "../components/product-filter/ProductFilterSelect";
import * as statuses from "../util/statuses";
import {
  useParentCollections_Collection,
  useParentCollections_CollectionsQuery,
  useParentCollections_CollectionsQueryVariables,
} from "./useParentCollections.graphql";

const ParentCollectionsContext = createContext<
  | {
      collectionsStatus: statuses.Status<useParentCollections_Collection[]>;
      refreshCollections: () => Promise<void>;
    }
  | undefined
>(undefined);

interface ParentCollectionsProviderProps {
  children: React.ReactNode;
  parentOrganizationId: string;
}

function Provider(props: ParentCollectionsProviderProps) {
  const { children, parentOrganizationId } = props;

  const { status, refresh } = usePagedQueryFetchAll<
    useParentCollections_CollectionsQuery,
    useParentCollections_CollectionsQueryVariables,
    useParentCollections_Collection
  >(
    collectionsQuery(),
    { organizationId: parentOrganizationId },
    (data) => data.recipeCollections
  );

  const collectionsStatus = statuses.map(
    status,
    extractNodesFromPagedQueryResult
  );

  return (
    <ParentCollectionsContext.Provider
      value={{
        collectionsStatus,
        refreshCollections: refresh,
      }}
    >
      {children}
    </ParentCollectionsContext.Provider>
  );
}

export function ParentCollectionsProvider(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  const [organization] = useOrganizationOrNull();

  if (organization === null) {
    return <>{children}</>;
  } else if (organization.parentId === null) {
    throw new Error(
      "ParentCollectionsProvider cannot be used when organization has no parent"
    );
  } else {
    return (
      <Provider parentOrganizationId={organization.parentId}>
        {children}
      </Provider>
    );
  }
}

export function useParentCollections(): {
  collectionsStatus: statuses.Status<useParentCollections_Collection[]>;
  refreshCollections: () => Promise<void>;
} {
  const result = useContext(ParentCollectionsContext);

  if (result === undefined) {
    throw new Error("ParentCollectionsProvider not present in component tree");
  } else {
    return result;
  }
}

const collectionsQuery = () => gql`
  query useParentCollections_CollectionsQuery(
    $after: String
    $organizationId: UUID!
  ) {
    recipeCollections(
      after: $after
      first: 1000
      filter: { ownerOrganizations: { id: $organizationId } }
    ) {
      edges {
        node {
          ...useParentCollections_Collection
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment useParentCollections_Collection on RecipeCollection {
    ...ProductFilterSelect_Collection
  }

  ${ProductFilterSelect.fragments.collection}
`;
