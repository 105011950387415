import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import { BillingInterval, Currency } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import useQuery from "../graphql/useQuery";
import { useOrganizationWithRefresh } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { BillingAddressPaymentStep } from "./BillingAddressPaymentStep";
import { BillSummary } from "./BillSummary";
import ConfirmPlan from "./ConfirmPlan";
import ConfirmPlanPaymentStep from "./ConfirmPlanPaymentStep";
import Payment from "./Payment";
import PaymentDetailsPaymentStep, {
  EXPECTED_TIER_SEARCH_PARAMETER_NAME,
} from "./PaymentDetailsPaymentStep";
import Plans from "./Plans";
import {
  PlansPage_ProductsQuery as ProductsQuery,
  PlansPage_RecipeAllowanceProduct as RecipeAllowanceProduct,
  PlansPage_TierProduct as TierProduct,
} from "./PlansPage.graphql";
import { deserializeTier } from "./utils";

interface Promotion {
  appliedPromotionId: string;
  couponId: string;
}

export interface PromotionDetails {
  appliedPromotionCode: string;
  promotion: Promotion | null;
}

export type State =
  | {
      type: "plans";
      selectedRecipeAllowanceProducts: Map<TierProduct, RecipeAllowanceProduct>;
    }
  | {
      type: "payment";
      selectedTierProduct: TierProduct;
      selectedRecipeAllowanceProducts: Map<TierProduct, RecipeAllowanceProduct>;
    };

export default function PlansPage() {
  const pages = usePages();
  const [
    { defaultCurrency, productTier: organizationTier },
    refreshOrganizationTier,
  ] = useOrganizationWithRefresh();
  const location = useLocation();

  const [
    showAwaitingPaymentConfirmationBanner,
    setShowAwaitingPaymentConfirmationBanner,
  ] = useState<boolean>(false);
  const [billingInterval, setBillingInterval] = React.useState<BillingInterval>(
    BillingInterval.MONTH
  );
  const [currency, setCurrency] = useState<Currency>(defaultCurrency);

  useEffect(() => {
    const callback = (abort: () => void) => {
      const searchParams = new URLSearchParams(location.search);
      const serializedExpectedTier = searchParams.get(
        EXPECTED_TIER_SEARCH_PARAMETER_NAME
      );

      if (serializedExpectedTier === null) {
        abort();
        return;
      }

      const expectedTier = deserializeTier(serializedExpectedTier);
      if (expectedTier === undefined) {
        abort();
        throw new Error(
          `unrecognised expected tier value: ${serializedExpectedTier}`
        );
      }

      if (expectedTier !== organizationTier) {
        setShowAwaitingPaymentConfirmationBanner(true);
        refreshOrganizationTier();
      } else {
        setShowAwaitingPaymentConfirmationBanner(false);
        abort();
      }
    };

    // Call immediately the first time.
    callback(() => {});
    const interval = setInterval(() => {
      callback(() => {
        clearInterval(interval);
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [location, organizationTier, refreshOrganizationTier]);

  const [state, setState] = useState<State>({
    selectedRecipeAllowanceProducts: new Map(),
    type: "plans",
  });

  const { status: dataStatus } = useQuery<ProductsQuery>(productsQuery, null);

  const initialStateRef = useRef(state);

  useEffect(() => {
    if (dataStatus.type === "success") {
      const availableTierProducts = dataStatus.value.availableTierProducts;

      setState({
        ...initialStateRef.current,
        selectedRecipeAllowanceProducts: new Map(
          availableTierProducts.map((tierProduct) => [
            tierProduct,
            tierProduct.recipeAllowanceProducts[0],
          ])
        ),
      });
    }
  }, [dataStatus]);

  const handleRecipeAllowanceProductSelect = (
    tierProduct: TierProduct,
    recipeAllowanceProduct: RecipeAllowanceProduct
  ) => {
    setState({
      ...state,
      selectedRecipeAllowanceProducts: new Map(
        state.selectedRecipeAllowanceProducts
      ).set(tierProduct, recipeAllowanceProduct),
    });
  };

  const handleChangePlanClicked = () => {
    setState({ ...state, type: "plans" });
  };

  const handleUpgradePlanClicked = (selectedTierProduct: TierProduct) => {
    if (
      state.selectedRecipeAllowanceProducts.get(selectedTierProduct) ===
      undefined
    ) {
      throw new Error("selectedRecipeAllowanceProduct cannot be null");
    }

    setState({
      type: "payment",
      selectedRecipeAllowanceProducts: state.selectedRecipeAllowanceProducts,
      selectedTierProduct,
    });
  };

  const tierProductsByTier = (tierProducts: Array<TierProduct>) => {
    return new Map(
      tierProducts.map((tierProduct) => [tierProduct.tier, tierProduct])
    );
  };

  return (
    <Page>
      {state.type === "plans" ? (
        <Page.Title breadcrumb={pages.Plans.breadcrumb()} title="" />
      ) : null}
      <StatusDisplay status={dataStatus}>
        {({ allFoodServiceTierProducts, availableTierProducts }) => {
          if (state.type === "plans") {
            return (
              <Plans
                billingInterval={billingInterval}
                currency={currency}
                onRecipeAllowanceProductSelect={
                  handleRecipeAllowanceProductSelect
                }
                onUpgradePlan={handleUpgradePlanClicked}
                organizationProducts={allFoodServiceTierProducts}
                selectedRecipeAllowanceProducts={
                  state.selectedRecipeAllowanceProducts
                }
                setBillingInterval={setBillingInterval}
                setCurrency={setCurrency}
                showAwaitingPaymentConfirmationBanner={
                  showAwaitingPaymentConfirmationBanner
                }
                tierProductsByTier={tierProductsByTier(availableTierProducts)}
              />
            );
          } else if (state.type === "payment") {
            return (
              <Payment
                billingInterval={billingInterval}
                currency={currency}
                handleChangePlanClicked={handleChangePlanClicked}
                handleRecipeAllowanceProductSelect={
                  handleRecipeAllowanceProductSelect
                }
                selectedRecipeAllowanceProduct={
                  state.selectedRecipeAllowanceProducts.get(
                    state.selectedTierProduct
                  )!
                }
                selectedTierProduct={state.selectedTierProduct}
                setBillingInterval={setBillingInterval}
              />
            );
          } else {
            assertNever(state, "Invalid state");
          }
        }}
      </StatusDisplay>
    </Page>
  );
}

const recipeAllowanceProduct = gql`
  fragment PlansPage_RecipeAllowanceProduct on RecipeAllowanceProduct {
    ...BillingAddressPaymentStep_RecipeAllowanceProduct
    ...ConfirmPlan_RecipeAllowanceProduct
    ...ConfirmPlanPaymentStep_RecipeAllowanceProduct
    ...Plans_RecipeAllowanceProduct
  }

  ${BillingAddressPaymentStep.fragments.recipeAllowanceProduct}
  ${ConfirmPlan.fragments.recipeAllowanceProduct}
  ${ConfirmPlanPaymentStep.fragments.recipeAllowanceProduct}
  ${Plans.fragments.recipeAllowanceProduct}
`;

const tierProduct = gql`
  fragment PlansPage_TierProduct on TierProduct {
    recipeAllowanceProducts {
      ...PlansPage_RecipeAllowanceProduct
    }

    ...BillingAddressPaymentStep_TierProduct
    ...BillSummary_TierProduct
    ...ConfirmPlan_TierProduct
    ...ConfirmPlanPaymentStep_TierProduct
    ...PaymentDetailsPaymentStep_TierProduct
  }

  ${BillingAddressPaymentStep.fragments.tierProduct}
  ${BillSummary.fragments.tierProduct}
  ${ConfirmPlan.fragments.tierProduct}
  ${ConfirmPlanPaymentStep.fragments.tierProduct}
  ${PaymentDetailsPaymentStep.fragments.tierProduct}
  ${recipeAllowanceProduct}
`;

PlansPage.fragments = { tierProduct, recipeAllowanceProduct };

const productsQuery = gql`
  query PlansPage_ProductsQuery {
    allFoodServiceTierProducts {
      ...Plans_OrganizationProduct
    }

    availableTierProducts {
      ...PlansPage_TierProduct
    }
  }

  ${Plans.fragments.organizationProduct}
  ${PlansPage.fragments.tierProduct}
`;
