import { Tier } from "../../__generated__/globalTypes";
import useIsAdmin from "../../data-store/useIsAdmin";
import { useOrganization } from "../organizations/OrganizationProvider";
import useOrganizationHasSignUpOverride from "./useOrganizationHasSignUpOverride";

export default function usePlansEnabled(): boolean {
  const hasSignUpOverride = useOrganizationHasSignUpOverride();
  const isAdmin = useIsAdmin();

  const [organization] = useOrganization();
  const hasQualifyingTier = [
    Tier.FOOD_SERVICE_BASIC,
    Tier.FOOD_SERVICE_FREE,
    Tier.FOOD_SERVICE_PREMIUM,
  ].includes(organization.productTier);

  return !hasSignUpOverride && isAdmin && hasQualifyingTier;
}
