import { useState } from "react";

import Toast, { ErrorToast } from "../utils/Toast";
import { FilledCheckmark } from "./Vectors";

const stories = {
  name: "Toast",
  children: [
    { name: "normal", render: () => <ToastStory /> },
    {
      name: "error toast",
      render: () => <ErrorToastStory />,
    },
  ],
};

function ToastStory() {
  const [show, setShow] = useState<boolean>(true);

  return (
    <div>
      {show ? null : <button onClick={() => setShow(true)}>Show toast</button>}
      <Toast
        message={"The message text goes over here."}
        onClose={() => setShow(false)}
        show={show}
        symbol={<FilledCheckmark width={20} />}
        title={"Title"}
      />
    </div>
  );
}

function ErrorToastStory() {
  const [show, setShow] = useState<boolean>(true);

  return (
    <div>
      {show ? null : <button onClick={() => setShow(true)}>Show toast</button>}
      <ErrorToast
        message={"The message text goes over here."}
        onClose={() => setShow(false)}
        show={show}
        title={"Title"}
      />
    </div>
  );
}

export default stories;
