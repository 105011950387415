import gql from "graphql-tag";

import { RecipeFilter } from "../../__generated__/globalTypes";
import { useDataStore } from "../../data-store";
import Select from "../utils/Select";
import {
  RecipeSelect_Query as Query,
  RecipeSelect_QueryVariables as QueryVariables,
  RecipeSelect_Recipe as Recipe,
} from "./RecipeSelect.graphql";

export type { Recipe };

interface RecipeSelectProps {
  className?: string;
  hasError?: boolean;
  hideDropdownArrow?: boolean;
  filter?: RecipeFilter;
  id?: string;
  onChange: (value: Recipe | null) => void;
  value: Recipe | null;
}

export default function RecipeSelect(props: RecipeSelectProps) {
  const {
    className = "",
    hasError,
    hideDropdownArrow,
    filter,
    id,
    onChange,
    value,
  } = props;

  const dataStore = useDataStore();

  async function loadOptions(input: string) {
    const data = await dataStore.fetchGraphQL<Query, QueryVariables>({
      query,
      variables: { recipeFilter: { ...filter, searchName: input } },
    });
    return data.recipes.edges.map((edge) => edge.node);
  }

  return (
    <Select
      className={className}
      hasError={hasError}
      dropdownArrow={hideDropdownArrow ? "none" : "normal"}
      id={id}
      onChange={onChange}
      options={loadOptions}
      optionKey={(recipe) => recipe.id.toString()}
      renderOption={(recipe) => recipe.name}
      value={value}
    />
  );
}

export const fragments = {
  recipe: gql`
    fragment RecipeSelect_Recipe on Recipe {
      id
      name
    }
  `,
};

export const query = gql`
  query RecipeSelect_Query($recipeFilter: RecipeFilter!) {
    recipes(filter: $recipeFilter, first: 20) {
      edges {
        node {
          ...RecipeSelect_Recipe
        }
      }
    }
  }

  ${fragments.recipe}
`;
