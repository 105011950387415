import { gql } from "graphql-tag";
import React from "react";
import { useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { AddPackagingComponentV2Input } from "../../../__generated__/globalTypes";
import UserVisibleError from "../../../util/UserVisibleError";
import { SecondaryButton } from "../../utils/Button";
import Form from "../../utils/Form";
import { combineReadResults } from "../../utils/ReadResult";
import {
  PackagingComponentEditor_PackagingComponentV2 as PackagingComponentV2,
  PackagingComponentEditor_PackagingMaterialV2 as PackagingMaterialV2,
} from "./PackagingComponentEditor.graphql";
import * as PackagingComponentMaterialsEditor from "./PackagingComponentMaterialsEditor";
import * as PackagingComponentNameEditor from "./PackagingComponentNameEditor";

interface PackagingComponentEditorState {
  componentMaterials: PackagingComponentMaterialsEditor.Value;
  name: PackagingComponentNameEditor.Value;
}

export type PackagingComponentInput = Omit<
  AddPackagingComponentV2Input,
  "ownerOrganizationId"
>;

export interface PackagingComponentEditorProps {
  availableMaterials: Array<PackagingMaterialV2>;
  onDiscard: () => void;
  onSave: (packagingComponent: PackagingComponentInput) => Promise<void>;
  packagingComponent?: PackagingComponentV2;
}

export default function PackagingComponentEditor(
  props: PackagingComponentEditorProps
) {
  const { availableMaterials, onDiscard, onSave, packagingComponent } = props;

  const intl = useIntl();
  const [state, setState] = useState<PackagingComponentEditorState>(() => {
    return {
      componentMaterials:
        PackagingComponentMaterialsEditor.initialValue(packagingComponent),
      name: PackagingComponentNameEditor.initialValue(packagingComponent),
    };
  });

  async function handleSubmit() {
    const sectionReadResults = {
      componentMaterials: PackagingComponentMaterialsEditor.read(
        state.componentMaterials
      ),
      name: PackagingComponentNameEditor.read(state.name),
    };

    const readResult = combineReadResults(sectionReadResults);

    setState(readResult.value);

    if (readResult.hasError) {
      throw missingInformationError(intl);
    } else {
      const newPackagingComponent: PackagingComponentInput = {
        materials: readResult.input.componentMaterials.map(
          (componentMaterialInput) => componentMaterialInput
        ),
        name: readResult.input.name.name,
      };
      return onSave(newPackagingComponent);
    }
  }

  const sections: Array<Section> = [
    {
      render: () => (
        <PackagingComponentNameEditor.PackagingComponentNameEditor
          onChange={(name: PackagingComponentNameEditor.Value) =>
            setState({ ...state, name })
          }
          value={state.name}
        />
      ),
    },
    {
      render: () => (
        <PackagingComponentMaterialsEditor.PackagingComponentMaterialsEditor
          availableMaterials={availableMaterials}
          onChange={(
            componentMaterials: PackagingComponentMaterialsEditor.Value
          ) => setState({ ...state, componentMaterials })}
          value={state.componentMaterials}
        />
      ),
    },
  ];

  return (
    <div className="RecipeEditor__root" style={{ maxWidth: "50rem" }}>
      {sections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>{section.render()}</React.Fragment>
      ))}

      <Form className="d-flex flex-column" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between flex-row">
          <DiscardChangesButton onClick={onDiscard} />
          <SaveButton packagingComponent={packagingComponent} />
        </div>
        <div className="ml-auto mr-0" style={{ width: "fit-content" }}>
          <Form.ErrorAlert className="mt-3" />
        </div>
      </Form>
    </div>
  );
}

function missingInformationError(intl: IntlShape) {
  return new UserVisibleError(
    intl.formatMessage({
      id: "components/packaging/PackagingComponentEditor/PackagingComponentEditor:missingInformationMessage",
      defaultMessage: "Please enter all the required information",
    })
  );
}

interface DiscardChangesButtonProps {
  onClick: () => void;
}

function DiscardChangesButton(props: DiscardChangesButtonProps) {
  const { onClick } = props;

  return (
    <SecondaryButton onClick={onClick}>
      <FormattedMessage
        id="components/packaging/PackagingComponentEditor/PackagingComponentEditor:discardChanges"
        defaultMessage="Cancel"
      />
    </SecondaryButton>
  );
}

interface SaveButtonProps {
  packagingComponent?: PackagingComponentV2;
}

function SaveButton(props: SaveButtonProps) {
  const { packagingComponent } = props;
  const intl = useIntl();

  return packagingComponent === undefined ? (
    <Form.SubmitButton
      loadingLabel={intl.formatMessage({
        id: "components/packaging/PackagingComponentEditor/PackagingComponentEditor:saveNew/loadingLabel",
        defaultMessage: "Creating",
      })}
      submitLabel={intl.formatMessage({
        id: "components/packaging/PackagingComponentEditor/PackagingComponentEditor:saveNew/submitLabel",
        defaultMessage: "Create Component",
      })}
    />
  ) : (
    <Form.SubmitButton
      loadingLabel={intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeEditor:save/loadingLabel",
        defaultMessage: "Saving",
      })}
      submitLabel={intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeEditor:save/submitLabel",
        defaultMessage: "Save",
      })}
    />
  );
}

interface Section {
  render: () => React.ReactNode;
}

PackagingComponentEditor.fragments = {
  packagingComponentV2: gql`
    fragment PackagingComponentEditor_PackagingComponentV2 on PackagingComponentV2 {
      ...PackagingComponentMaterialEditorSelect_PackagingComponentV2
    }

    ${PackagingComponentMaterialsEditor.fragments.packagingComponentV2}
  `,

  packagingMaterialV2: gql`
    fragment PackagingComponentEditor_PackagingMaterialV2 on PackagingMaterialV2 {
      ...PackagingComponentMaterialEditorSelect_PackagingMaterialV2
    }

    ${PackagingComponentMaterialsEditor.fragments.packagingMaterialV2}
  `,
};
