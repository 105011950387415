import VectorProps from "./VectorProps";

export default function PromoCodeIcon(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M4.11259 5.38355C4.27876 5.54949 4.5041 5.64267 4.739 5.64267C4.97379 5.64255 5.19901 5.54915 5.36506 5.38309C5.53112 5.21692 5.62441 4.9917 5.62441 4.75679C5.62441 4.52201 5.53112 4.29668 5.36506 4.13062C5.19901 3.96445 4.97379 3.87115 4.739 3.87104C4.5041 3.87092 4.27877 3.9641 4.11259 4.13016C3.94665 4.29644 3.85348 4.5219 3.85348 4.7568C3.85348 4.99182 3.94665 5.21729 4.11259 5.38355ZM6.07322 3.4234C6.42688 3.77706 6.62559 4.25678 6.62559 4.75705C6.62559 5.25717 6.4269 5.73689 6.0731 6.0907C5.71944 6.44437 5.23972 6.64308 4.73945 6.64308C4.23933 6.64308 3.75961 6.44438 3.40579 6.0907C3.05213 5.73692 2.85342 5.2572 2.85342 4.75705C2.85342 4.25681 3.05211 3.77709 3.40579 3.4234C3.7597 3.07006 4.23942 2.8717 4.73945 2.8717C5.23957 2.8717 5.7192 3.07005 6.07322 3.4234ZM1.18627 7.23164C1.0457 7.09857 0.978349 6.90564 1.00568 6.71399L1.5546 1.91589C1.56993 1.7699 1.63624 1.63406 1.74187 1.53212C1.84751 1.43018 1.98577 1.36883 2.13212 1.35868L6.70053 1.00316C6.88481 0.984244 7.0676 1.05182 7.19525 1.18605L14.7985 8.78837C14.9165 8.9068 14.9828 9.0672 14.9828 9.23429C14.9828 9.4015 14.9165 9.56191 14.7985 9.6802L9.66264 14.816C9.54409 14.9338 9.38392 14.9998 9.21681 14.9998C9.0497 14.9998 8.88954 14.9338 8.77098 14.816L1.18627 7.23164ZM2.05449 0.362042L6.6229 0.00651752H6.62279C7.09894 -0.0367252 7.56909 0.136827 7.90269 0.479094L15.5056 8.08141C15.811 8.38746 15.9824 8.80203 15.9824 9.23437C15.9824 9.66659 15.8109 10.0813 15.5056 10.3872L10.3698 15.5236C10.0644 15.8298 9.64928 16.0014 9.21682 16C8.78428 16.0014 8.36924 15.8298 8.06387 15.5234L0.47897 7.93877C0.123445 7.58959 -0.0491962 7.09487 0.0121784 6.60039L0.561097 1.8023C0.600998 1.42499 0.772476 1.07383 1.04566 0.810441C1.31873 0.547055 1.67588 0.388257 2.05434 0.362072L2.05449 0.362042Z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
