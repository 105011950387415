import { useState } from "react";

import { ingredientQuantityUnits } from "../../domain/units";
import * as WeightInput from "./WeightInput";

const stories = {
  name: "WeightInput",
  render: () => <Story />,
};

function Story() {
  const [value, setValue] = useState<WeightInput.Value>(
    WeightInput.initialValue(10, "g")
  );
  return (
    <WeightInput.WeightInput
      value={value}
      onChange={setValue}
      units={ingredientQuantityUnits({ isSubrecipe: false })}
    />
  );
}

export default stories;
