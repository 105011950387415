import VectorProps from "./VectorProps";

export default function Packaging(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from Foodsteps design library: https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?node-id=1-67&t=fK7PVc1de8AaJeL8-0
  return (
    <svg
      className={className}
      fill="currentColor"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_564_671)">
        <g clipPath="url(#clip1_564_671)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1982 0.46338C12.1648 0.176062 11.9049 -0.0298015 11.6175 0.003541L10.202 0.167806C10.064 0.183824 9.93796 0.254033 9.85171 0.36298C9.76545 0.471927 9.72602 0.610683 9.7421 0.748709L10.0151 3.09344H7.34082C7.19733 3.09344 7.06012 3.1523 6.96124 3.25629C6.86236 3.36027 6.81046 3.50026 6.81768 3.64357L6.98191 6.90672H3.58618C2.98022 6.90672 2.45956 7.26787 2.22625 7.78672H1.89659C1.42912 7.78672 0.996776 8.03839 0.766989 8.44693L0.0675248 9.68754C0.0189354 9.77372 -0.00340479 9.87017 0.000419118 9.96575C-0.0010113 10.0015 0.00121002 10.0375 0.00723258 10.0734L0.686084 14.1172C0.86843 15.2044 1.80942 16 2.91152 16H7.9346C7.93586 16 7.93712 16 7.93838 16H14.6749C14.954 16 15.184 15.7813 15.1981 15.5026L15.7965 3.64364C15.8037 3.50032 15.7518 3.36031 15.6529 3.25631C15.5541 3.15231 15.4168 3.09344 15.2733 3.09344H12.5039L12.1982 0.46338ZM12.2781 14.9524H14.1769L14.7224 4.14105H7.89165L8.03085 6.90672H9.69668C10.3026 6.90672 10.8233 7.26787 11.0566 7.78672H11.3863C11.8419 7.78672 12.2633 8.02611 12.4975 8.41539C12.5041 8.42544 12.5104 8.43575 12.5164 8.44633L13.2162 9.68754C13.2647 9.77368 13.2871 9.87007 13.2833 9.96561C13.2847 10.0014 13.2825 10.0375 13.2765 10.0734L12.5976 14.1171C12.5465 14.4222 12.4356 14.7043 12.2781 14.9524ZM7.52681 7.95433C7.53047 7.95437 7.53414 7.95437 7.53781 7.95433H9.69668C9.92724 7.95433 10.1176 8.13097 10.138 8.35747C10.1622 8.62747 10.3885 8.83433 10.6596 8.83433H11.3863C11.4754 8.83433 11.5587 8.88249 11.6033 8.96143C11.6078 8.9693 11.6124 8.97703 11.6172 8.98461L11.8632 9.42099H1.42045L1.68023 8.96024C1.72371 8.88288 1.80603 8.83433 1.89659 8.83433H2.62322C2.89432 8.83433 3.12062 8.62747 3.14491 8.35747C3.1653 8.13097 3.35563 7.95433 3.58618 7.95433H7.52681ZM11.0698 3.09343L10.8433 1.14803L11.2181 1.10454L11.4492 3.09343H11.0698ZM1.71924 13.9438L1.14288 10.5105H12.1408L11.5645 13.9439C11.4668 14.5261 10.963 14.9524 10.3722 14.9524H2.91152C2.32072 14.9524 1.81686 14.526 1.71924 13.9438Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_564_671">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_564_671">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
