import Card from "../../../../Card";
import * as DescriptiveRatingLabels from "./index";

const stories = {
  name: "Descriptive Rating Labels",
  render: () => (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
      {Object.entries(DescriptiveRatingLabels).map(
        ([vectorName, Vector], index) => (
          <Card
            className="d-flex flex-column justify-content-between m-2 p-2"
            key={index}
            style={{ border: "1px solid" }}
          >
            <Vector className="mb-4" width={127} />
            {vectorName}
          </Card>
        )
      )}
    </div>
  ),
};

export default stories;
