import VectorProps from "./VectorProps";

export default function Search(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=45-91&mode=design&t=AR3AgqqIbKUXPhVu-0
  return (
    <svg
      className={className}
      height={height ?? width}
      fill={fill}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.09089 0C14.0908 0 18.1818 4.09096 18.1818 9.09128C18.1818 11.2731 17.4091 13.273 16.1363 14.8186L19.7273 18.4096C20.0909 18.7732 20.0909 19.3187 19.7273 19.6824C19.5454 19.9095 19.3182 20.0005 19.0908 20.0005C18.8636 20.0005 18.6362 19.9097 18.4545 19.7278L14.8635 16.1368C13.2726 17.4096 11.2727 18.1823 9.09097 18.1823C4.09103 18.1823 7.05719e-05 14.0913 7.05719e-05 9.09137C-8.2016e-05 4.09104 4.09103 8.18189e-05 9.09097 8.18189e-05L9.09089 0ZM9.09089 16.3638C13.0911 16.3638 16.3638 13.0911 16.3638 9.09089C16.3637 5.0911 13.0909 1.81833 9.09089 1.81833C5.09071 1.81833 1.81794 5.0911 1.81794 9.09128C1.81794 13.0911 5.09071 16.3638 9.09089 16.3638Z"
        fill={fill}
      />
    </svg>
  );
}
