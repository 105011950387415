import { gql } from "graphql-tag";
import { FormattedMessage, useIntl } from "react-intl";

import { UpdateRecipeInput } from "../../../__generated__/globalTypes";
import { useAccuratePostPreparationStorageImpacts } from "../../../services/useOrganizationFeatures";
import assertNever from "../../../util/assertNever";
import { PickRequiredNonNullable } from "../../../util/types";
import Checkbox from "../../utils/Checkbox";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import RadioButtons from "../../utils/RadioButtons";
import ReadResult from "../../utils/ReadResult";
import useRecipeLabel from "../useRecipeLabel";
import RecipeEditorLabel from "./RecipeEditorLabel";
import {
  RecipePostPreparationStorageEditor_Recipe as Recipe,
  RecipePostPreparationStorageEditor_StorageMethod as StorageMethod,
} from "./RecipePostPreparationStorageEditor.graphql";
import * as RecipeStorageEditor from "./RecipeStorageEditor";

export const label = (
  <FormattedMessage
    id="components/recipes/RecipeEditor/RecipePostPreparationStorageEditor:label"
    defaultMessage="Storage"
  />
);

export interface AccurateValue {
  type: "accurate";
  postPreparationStoragePenalties: RecipeStorageEditor.Value;
  invalidPostPreparationStoragePenalties: boolean;
  isStored: boolean;
}

export interface EstimatedValue {
  type: "estimated";
  isStored: boolean;
}

export type Value = AccurateValue | EstimatedValue;

export function initialValue(
  recipe: Recipe | undefined,
  accuratePostPreparationStorageImpacts: boolean,
  isFoodManufacturerOrganization: boolean
): Value {
  if (isFoodManufacturerOrganization) {
    if (accuratePostPreparationStorageImpacts) {
      return {
        type: "accurate",
        postPreparationStoragePenalties: [],
        invalidPostPreparationStoragePenalties: false,
        isStored: false,
      };
    } else {
      return {
        type: "estimated",
        isStored: false,
      };
    }
  }

  if (accuratePostPreparationStorageImpacts) {
    if (recipe === undefined) {
      return {
        type: "accurate",
        postPreparationStoragePenalties: RecipeStorageEditor.initialValue([]),
        invalidPostPreparationStoragePenalties: false,
        isStored: true,
      };
    } else {
      return {
        type: "accurate",
        postPreparationStoragePenalties: RecipeStorageEditor.initialValue(
          recipe.postPreparationStoragePenalties
        ),
        invalidPostPreparationStoragePenalties: false,
        // if the recipe's initial state was incomplete we don't want the user to be able to save it
        // without considering whether to add storage penalties
        // so make isStored true to cause a validation error by default
        isStored:
          !recipe.hasCompletePostPreparationStoragePenalties ||
          recipe.postPreparationStoragePenalties.length > 0,
      };
    }
  } else {
    return {
      type: "estimated",
      // when using storage estimator, isStored is persisted as !recipe.hasCompleteStoragePenalties in the backend
      isStored:
        recipe === undefined
          ? true
          : !recipe.hasCompletePostPreparationStoragePenalties,
    };
  }
}

type ReadPostPreparationStoragePenaltiesResult = ReadResult<
  Value,
  PickRequiredNonNullable<
    UpdateRecipeInput,
    "postPreparationStoragePenalties" | "isStored"
  >
>;

export function read(value: Value): ReadPostPreparationStoragePenaltiesResult {
  const { type } = value;
  if (type === "accurate") {
    return readAccuratePostPreparationStorageImpacts(value);
  } else if (type === "estimated") {
    return readEstimatedPostPreparationStorageImpacts(value);
  } else {
    assertNever(type, "invalid type");
  }
}

function readAccuratePostPreparationStorageImpacts(
  value: AccurateValue
): ReadPostPreparationStoragePenaltiesResult {
  if (!value.isStored) {
    return {
      value: {
        type: "accurate",
        postPreparationStoragePenalties: [],
        invalidPostPreparationStoragePenalties: false,
        isStored: value.isStored,
      },
      input: {
        postPreparationStoragePenalties: [],
        isStored: value.isStored,
      },
      hasError: false,
    };
  } else {
    const recipeStorageEditorReadResult = RecipeStorageEditor.read(
      value.postPreparationStoragePenalties
    );

    const postPreparationStoragePenaltyInputs =
      recipeStorageEditorReadResult.hasError
        ? []
        : recipeStorageEditorReadResult.input;

    const validPostPreparationStoragePenalties =
      postPreparationStoragePenaltyInputs.length > 0;

    return {
      hasError: validPostPreparationStoragePenalties
        ? recipeStorageEditorReadResult.hasError
        : true,
      value: {
        type: "accurate",
        postPreparationStoragePenalties: recipeStorageEditorReadResult.value,
        invalidPostPreparationStoragePenalties:
          !validPostPreparationStoragePenalties,
        isStored: value.isStored,
      },
      input: {
        postPreparationStoragePenalties: value.isStored
          ? postPreparationStoragePenaltyInputs
          : [],
        isStored: value.isStored,
      },
    };
  }
}

function readEstimatedPostPreparationStorageImpacts(
  value: EstimatedValue
): ReadPostPreparationStoragePenaltiesResult {
  return {
    hasError: false,
    value,
    input: {
      postPreparationStoragePenalties: [],
      isStored: value.isStored,
    },
  };
}

interface RecipePostPreparationStorageEditorProps {
  onChange: (value: Value) => void;
  storageMethods: Array<StorageMethod>;
  value: Value;
}

export function RecipePostPreparationStorageEditor(
  props: RecipePostPreparationStorageEditorProps
) {
  const { onChange, storageMethods, value } = props;

  const hasAccuratePostPreparationStorageImpacts =
    useAccuratePostPreparationStorageImpacts();
  const recipeLabel = useRecipeLabel();

  return (
    <div className="form-group">
      <RecipeEditorLabel>{label}</RecipeEditorLabel>
      {hasAccuratePostPreparationStorageImpacts && (
        <PostPreparationStorageHelpModal />
      )}
      <p className="text-muted">
        <FormattedMessage
          defaultMessage="How is your {recipeLabel} stored after preparation?"
          id="components/recipes/RecipeEditor/RecipeIngredientStorageEditor:subheading"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      </p>
      {value.type === "accurate" ? (
        <AccurateRecipePostPreparationStorageEditor
          onChange={onChange}
          storageMethods={storageMethods}
          value={value}
        />
      ) : (
        <EstimatedRecipePostPreparationStorageEditor
          onChange={onChange}
          value={value}
        />
      )}
    </div>
  );
}

interface AccurateRecipePostPreparationStorageEditorProps {
  onChange: (value: AccurateValue) => void;
  storageMethods: Array<StorageMethod>;
  value: AccurateValue;
}

function AccurateRecipePostPreparationStorageEditor(
  props: AccurateRecipePostPreparationStorageEditorProps
) {
  const { onChange, storageMethods, value } = props;

  const resetErrorState = (value: AccurateValue) => ({
    ...value,
    postPreparationStoragePenalties: resetPenaltiesErrorState(
      value.postPreparationStoragePenalties
    ),
    invalidPostPreparationStoragePenalties: false,
  });

  const resetPenaltiesErrorState = (penalties: Array<any>) => {
    return penalties.map((penalty) => {
      return {
        ...penalty,
        invalidStorageMethod: false,
        daysInStorage: { ...penalty.daysInStorage, isInvalid: false },
      };
    });
  };

  return (
    <>
      <RecipeIsStoredCheckbox
        className={
          value.invalidPostPreparationStoragePenalties ? "is-invalid" : ""
        }
        isStored={value.isStored}
        onChange={(isStored) => {
          onChange({
            ...resetErrorState(value),
            isStored,
          });
        }}
      />

      {value.isStored && (
        <RecipeStorageEditor.RecipeStorageEditor
          onChange={(postPreparationStoragePenaltiesEditorValue) =>
            onChange({
              ...resetErrorState(value),
              postPreparationStoragePenalties:
                postPreparationStoragePenaltiesEditorValue,
            })
          }
          storageMethods={storageMethods}
          value={value.postPreparationStoragePenalties}
        />
      )}
    </>
  );
}

interface EstimatedRecipePostPreparationStorageEditorProps {
  onChange: (value: EstimatedValue) => void;
  value: EstimatedValue;
}

function EstimatedRecipePostPreparationStorageEditor(
  props: EstimatedRecipePostPreparationStorageEditorProps
) {
  const { onChange, value } = props;

  return (
    <>
      <RecipeIsStoredRadioButtons
        isStored={value.isStored}
        onChange={(hasPostPreparationStorage) => {
          onChange({
            ...value,
            isStored: hasPostPreparationStorage,
          });
        }}
      />
    </>
  );
}

interface RecipeIsStoredRadioButtonsProps {
  isStored: boolean;
  onChange: (isStored: boolean) => void;
}

function RecipeIsStoredRadioButtons(props: RecipeIsStoredRadioButtonsProps) {
  const { isStored, onChange } = props;

  const intl = useIntl();
  const recipeLabel = useRecipeLabel();

  const yes = intl.formatMessage({
    defaultMessage: "Yes",
    id: "components/recipes/RecipeEditor/RecipePostPreparationStorageEditor:yes",
  });

  const no = intl.formatMessage({
    defaultMessage: "No",
    id: "components/recipes/RecipeEditor/RecipePostPreparationStorageEditor:no",
  });

  const renderHasPostPreparationStorage = (answer: boolean) => {
    return answer ? yes : no;
  };

  return (
    <div id="isStoredEditor">
      <label>
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipePostPreparationStorageEditor:isRecipeStored"
          defaultMessage="Is this {recipeLabel} stored (e.g. in a fridge, freezer, or on a hot counter) before it is consumed?"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      </label>
      <br />
      <RadioButtons
        inline
        onChange={onChange}
        optionKey={(answer) => renderHasPostPreparationStorage(answer)}
        options={[true, false]}
        renderOptionLabel={(answer) => renderHasPostPreparationStorage(answer)}
        value={isStored}
      />
    </div>
  );
}

interface RecipeIsStoredCheckboxProps {
  className?: string;
  isStored: boolean;
  onChange: (hasNoPostPreparationStoragePenalties: boolean) => void;
}

function RecipeIsStoredCheckbox(props: RecipeIsStoredCheckboxProps) {
  const { className, isStored, onChange } = props;

  const recipeLabel = useRecipeLabel();

  return (
    <div id="isStoredEditor" className="mb-3">
      <Checkbox
        className={className}
        defaultChecked={!isStored}
        label={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipePostPreparationStoragePenaltiesEditor/RecipeIsStoredCheckbox:label"
            defaultMessage="This {recipeLabel} is not stored"
            values={{ recipeLabel: recipeLabel.singularLowercase }}
          />
        }
        onChange={(isNotStored) => onChange(!isNotStored)}
      />
    </div>
  );
}

function PostPreparationStorageHelpModal() {
  return (
    <HelpModalTooltip
      title={
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:storageHelpModal/title"
          defaultMessage="Storage FAQs"
        />
      }
    >
      <HelpModalTooltip.Question
        answer={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipePostPreparationStorageEditor:storageHelpModal/whatIsPostPreparationStorage/answer"
            defaultMessage="
                This refers to storage of the item after it has been prepared. For
                example, pre-prepared sandwiches in a café that are kept in a
                display fridge.
              "
          />
        }
        question={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipePostPreparationStorageEditor:storageHelpModal/whatIsPostPreparationStorage/question"
            defaultMessage="What is meant by post-preparation storage?"
          />
        }
      />
    </HelpModalTooltip>
  );
}

const storageMethodFragment = gql`
  fragment RecipePostPreparationStorageEditor_StorageMethod on StorageMethod {
    ...RecipeStorageEditor_StorageMethod
  }

  ${RecipeStorageEditor.fragments.storageMethod}
`;

export const fragments = {
  recipe: gql`
    fragment RecipePostPreparationStorageEditor_Recipe on Recipe {
      postPreparationStoragePenalties {
        ...RecipePostPreparationStorageEditor_PostPreparationStoragePenalty
      }
      hasCompletePostPreparationStoragePenalties
    }

    fragment RecipePostPreparationStorageEditor_PostPreparationStoragePenalty on PostPreparationStoragePenalty {
      daysInStorage
      id
      storageMethod {
        ...RecipePostPreparationStorageEditor_StorageMethod
      }
    }

    ${storageMethodFragment}
  `,

  storageMethod: storageMethodFragment,
};
