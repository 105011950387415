import { v4 as uuidv4 } from "uuid";

import UploadedRecipesTable from "./UploadedRecipesTable";
import { UploadedRecipe } from "./UploadRecipesPage";

const dummyParsedRecipe = (name: string) => ({
  clientId: uuidv4(),
  code: null,
  collectionNames: null,
  ingredients: [],
  name,
  notCooked: {
    rawValue: null,
    parsedValue: true,
  },
  notStored: {
    rawValue: null,
    parsedValue: true,
  },
  rowIndex: 1,
  servings: null,
});

const recipes: Array<UploadedRecipe> = [
  {
    ghgPerRootRecipeServing: 9.123,
    id: 1,
    impactTooHigh: true,
    impactTooLow: false,
    parsedRecipe: dummyParsedRecipe("Heavy with High Impact"),
    weightKgPerServing: 4.321,
    weightTooHigh: true,
    weightTooLow: false,
  },
  {
    ghgPerRootRecipeServing: 0.123,
    id: 2,
    impactTooHigh: false,
    impactTooLow: true,
    parsedRecipe: dummyParsedRecipe("Light with Low Impact"),
    weightKgPerServing: 0.021,
    weightTooHigh: false,
    weightTooLow: true,
  },
  {
    ghgPerRootRecipeServing: null,
    id: 3,
    impactTooHigh: true,
    impactTooLow: false,
    parsedRecipe: dummyParsedRecipe("Impact Pending"),
    weightKgPerServing: 0.321,
    weightTooHigh: false,
    weightTooLow: false,
  },
  {
    ghgPerRootRecipeServing: 0.123,
    id: 4,
    impactTooHigh: false,
    impactTooLow: false,
    parsedRecipe: dummyParsedRecipe("Weight Pending"),
    weightKgPerServing: null,
    weightTooHigh: true,
    weightTooLow: false,
  },
  {
    ghgPerRootRecipeServing: null,
    id: 5,
    impactTooHigh: false,
    impactTooLow: false,
    parsedRecipe: dummyParsedRecipe("Both Pending"),
    weightKgPerServing: null,
    weightTooHigh: true,
    weightTooLow: false,
  },
  {
    ghgPerRootRecipeServing: 0.123,
    id: 6,
    impactTooHigh: false,
    impactTooLow: false,
    parsedRecipe: dummyParsedRecipe("Goldilocks Recipe"),
    weightKgPerServing: 0.321,
    weightTooHigh: false,
    weightTooLow: false,
  },
];

const stories = {
  name: "UploadedRecipesTable",
  render: () => (
    <UploadedRecipesTable
      recipePageUrl={(recipe: { id: number }) => `/recipes/${recipe.id}`}
      recipes={recipes}
    />
  ),
};

export default stories;
