import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { IngredientInput } from "../../../__generated__/globalTypes";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult, { combineReadResultsArray } from "../../utils/ReadResult";
import TableEditor from "../../utils/TableEditor";
import {
  IngredientType,
  blankIngredient,
} from "../RecipeEditor/RecipeIngredientsEditor";
import PhysicalInputEditor, {
  Value as PhysicalInputValue,
  initialValue as physicalInputInitialValue,
  fragments as physicalInputEditorFragments,
  readPhysicalInput,
  PhysicalInputsEditorHeader,
} from "./PhysicalInputEditor";
import { ProductEditor_Recipe } from "./ProductEditor.graphql";
import "./PhysicalInputsEditor.css";

export type Value = Array<PhysicalInputValue>;

const blankInput: PhysicalInputValue = {
  unitProcess: { processLoss: FloatInput.initialValue(null) },
  ...blankIngredient(),
};

export function initialValue(recipe: ProductEditor_Recipe | undefined): Value {
  return recipe?.ingredients.map(physicalInputInitialValue) ?? [blankInput];
}

export function read(value: Value): ReadResult<Value, Array<IngredientInput>> {
  return combineReadResultsArray(value.map(readPhysicalInput));
}

function HelpModal() {
  return (
    <HelpModalTooltip
      title={
        <FormattedMessage
          id="components/recipes/ProductEditor/PhysicalInputsEditor:helpTitle"
          defaultMessage="Physical Inputs"
        />
      }
    >
      <FormattedMessage
        id="components/recipes/ProductEditor/PhysicalInputsEditor:helpText"
        defaultMessage="
        <p>Inputs are the Ingredients or your own products that you have defined in the Product Details section.</p>
        <p>Enter quantities input before any is lost.</p>
        <p>Add the proportion that is lost (e.g. due to spillage or spoilage). If you don’t know this, leave blank and we’ll use an estimate based on your location.</p>"
        values={{
          p: (chunks: React.ReactNode) => <p>{chunks}</p>,
        }}
      />
    </HelpModalTooltip>
  );
}

interface PhysicalInputsEditorProps {
  physicalInputs: Value;
  setPhysicalInputs: (physicalInputs: Value) => void;
}

export default function PhysicalInputsEditor(props: PhysicalInputsEditorProps) {
  const { physicalInputs, setPhysicalInputs } = props;

  return (
    <div className="PhysicalInputsEditor">
      <div>
        <label className="d-flex mb-0 flex-row" htmlFor="physicalInputsEditor">
          <h4>
            <FormattedMessage
              id="components/recipes/ProductEditor/PhysicalInputsEditor:label"
              defaultMessage="Physical Inputs"
            />
          </h4>
          <HelpModal />
        </label>
        <div className="text-muted">
          <FormattedMessage
            id="components/recipes/ProductEditor/PhysicalInputsEditor:instructions"
            defaultMessage="Add quantities and percentage lost where known."
          />
        </div>
      </div>
      <div className="PhysicalInputsEditor_Table">
        <PhysicalInputsEditorHeader />
        <TableEditor
          blank={() => ({
            ...blankIngredient(),
            unitProcess: { processLoss: FloatInput.initialValue(null) },
          })}
          onChange={setPhysicalInputs}
          renderRow={({ onChange, onDelete, rowIndex, value }) =>
            ((value.ingredientType === IngredientType.SIMPLE &&
              value.name !== "") ||
              value.useRecipe) && (
              <PhysicalInputEditor
                value={value}
                onChange={(value) => onChange({ ...value })}
                onDelete={
                  rowIndex === 0 && physicalInputs.length === 1
                    ? undefined
                    : onDelete
                }
              />
            )
          }
          showAddButton={false}
          value={physicalInputs}
        />
      </div>
    </div>
  );
}

export const fragments = {
  recipe: gql`
    fragment PhysicalInputsEditor_Recipe on Recipe {
      ingredients {
        ...PhysicalInputEditor_Ingredient
      }
    }

    ${physicalInputEditorFragments.ingredient}
  `,
};
