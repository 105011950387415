import { useState } from "react";

import { TransportStorageMethod } from "../../__generated__/globalTypes";
import * as TransportStorageMethodSelect from "./TransportStorageMethodSelect";

const stories = {
  name: "TransportStorageMethodSelect",
  children: [
    {
      name: "initially blank",
      render: () => <Story defaultValue={null} />,
    },
    {
      name: "initially set",
      render: () => <Story defaultValue={TransportStorageMethod.AMBIENT} />,
    },
    {
      name: "error state",
      render: () => <Story defaultValue={null} hasError />,
    },
  ],
};

interface StoryProps {
  defaultValue: TransportStorageMethodSelect.Value;
  hasError?: boolean;
}

function Story(props: StoryProps) {
  const { defaultValue, hasError = false } = props;

  const [value, setValue] = useState(defaultValue);
  return (
    <TransportStorageMethodSelect.TransportStorageMethodSelect
      hasError={hasError}
      onChange={(value) => setValue(value)}
      value={value}
    />
  );
}

export default stories;
