import { LocationDescriptorObject } from "history";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { createOAuth2Client } from "../../services/oauth2Client";
import { useSession } from "../../sessions";
import ErrorAlert from "../utils/ErrorAlert";

const clientId: string = (window as any).FOODSTEPS_PLATFORM_OAUTH2_CLIENT_ID;
const platformUri: string = (window as any).FOODSTEPS_PLATFORM_URI;

interface ExtraState {
  location: LocationDescriptorObject;
}

const oauth2Client = createOAuth2Client<ExtraState>({
  authorizeUri: `${platformUri}oauth2/authorize`,
  clientId,
  tokenUri: `${platformUri}oauth2/token`,
});

export function OAuth2AuthorizeRedirect() {
  useEffect(() => {
    oauth2Client.authorizeRedirect({
      location: {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      },
    });
  }, []);

  return null;
}

export function OAuth2AuthorizedPage() {
  const [error, setError] = useState<unknown>(null);

  const history = useHistory();
  const session = useSession();

  useEffect(() => {
    (async function () {
      try {
        const { accessToken, state } =
          await oauth2Client.exchangeCodeForToken();
        await session.setToken(accessToken);
        history.replace(state.location);
      } catch (error: unknown) {
        setError(error);
      }
    })();
  }, [history, session]);

  return error === null ? null : <ErrorAlert error={error} />;
}
