import { BillingInterval } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import { Month, Year } from "../../util/currencyFormatting";
import "./BillingIntervalToggleLabel.css";

interface BillingIntervalToggleLabelProps {
  billingInterval: BillingInterval;
}

export default function BillingIntervalToggleLabel(
  props: BillingIntervalToggleLabelProps
) {
  const { billingInterval } = props;

  if (billingInterval === BillingInterval.MONTH) {
    return (
      <span className="text-capitalize medium-font">
        <Month type="adjective" />
      </span>
    );
  } else if (billingInterval === BillingInterval.YEAR) {
    return (
      <div className="d-flex align-items-center">
        <span className="text-capitalize medium-font">
          <Year type="adjective" />
        </span>{" "}
        <div className="BillingIntervalToggleLabel__pill small medium-font ml-2 px-2 py-1">
          -10%
        </div>
      </div>
    );
  } else {
    assertNever(billingInterval, "unknown billing interval");
  }
}
