import EndMileTypeQuestionsForm from "./EndMileTypeQuestionsForm";

function handleComplete(answer: string) {
  alert(answer);
}

const stories = {
  name: "EndMileTypeQuestionsForm",
  render: () => <EndMileTypeQuestionsForm onComplete={handleComplete} />,
};

export default stories;
