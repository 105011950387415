import VectorProps from "./VectorProps";

export default function Plans(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Q85QW9IPew7lWUUb-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0453 1.15898C10.4166 1.15608 10.739 1.41414 10.8175 1.77708L11.6806 5.76924C11.9536 7.03306 12.9404 8.01993 14.2042 8.29296L18.1965 9.15607C18.5594 9.23454 18.8175 9.55692 18.8146 9.92823C18.8117 10.2995 18.5486 10.6179 18.1845 10.6906L14.2004 11.4871C12.896 11.7484 11.8767 12.7679 11.6165 14.0707L10.82 18.0552C10.7472 18.4193 10.4289 18.6824 10.0575 18.6852C9.68624 18.6881 9.36385 18.4301 9.28539 18.0672L8.4223 14.075C8.14928 12.8112 7.16249 11.8243 5.89867 11.5513L1.90639 10.6882C1.54345 10.6097 1.28539 10.2873 1.28829 9.91599C1.29119 9.54468 1.55426 9.22637 1.91837 9.15358L5.90245 8.35716C7.20687 8.09585 8.22614 7.07637 8.4864 5.77349L9.2829 1.78906C9.35568 1.42494 9.674 1.16188 10.0453 1.15898ZM10.0815 5.79056L10.0235 6.08054C9.63916 8.00461 8.13466 9.50877 6.21021 9.89412L5.91987 9.95216L6.22966 10.0191C8.09485 10.4221 9.55146 11.8787 9.9544 13.7439L10.0214 14.0537L10.0793 13.7637C10.4637 11.8396 11.9682 10.3355 13.8926 9.9501L14.183 9.89206L13.8732 9.82509C12.008 9.42215 10.5515 7.96566 10.1485 6.10047L10.0815 5.79056Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3657 0.000543985C16.6687 -0.0107249 16.9511 0.153849 17.0906 0.423093L17.7705 1.73487C17.8778 1.94195 18.0455 2.10977 18.2502 2.21615L18.2513 2.21674L19.5788 2.90937C19.8001 3.02481 19.9531 3.23872 19.9909 3.48539C20.0288 3.73206 19.9469 3.98201 19.7704 4.15845L19.7684 4.16041C19.7089 4.21995 19.6401 4.26951 19.5648 4.30717L18.3245 4.9273C18.116 5.03158 17.9451 5.19765 17.8358 5.40125L17.0839 6.7981C16.9436 7.05886 16.6682 7.21825 16.3722 7.21009C16.0762 7.20192 15.8101 7.02761 15.6843 6.75951L15.0742 5.45894C14.961 5.21847 14.7676 5.02575 14.5282 4.91442L13.2425 4.31567C12.9642 4.18607 12.7872 3.90596 12.7896 3.599C12.7921 3.29204 12.9735 3.01476 13.2538 2.88958L14.5649 2.30402C14.8253 2.18739 15.032 1.97773 15.1443 1.71569L15.6741 0.475766C15.7933 0.196902 16.0626 0.0118128 16.3657 0.000543985ZM16.4558 2.59461C16.2141 3.02398 15.8595 3.37865 15.4309 3.62082C15.8586 3.87536 16.2083 4.24407 16.4403 4.68665L16.4547 4.65998C16.6953 4.21189 17.0584 3.84171 17.4987 3.59188C17.0657 3.36018 16.7058 3.01476 16.4558 2.59461Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57607 12.7706C3.87912 12.7593 4.16147 12.9239 4.30102 13.1931L4.98091 14.5049C5.08822 14.712 5.25589 14.8798 5.46058 14.9862L5.46174 14.9868L6.78919 15.6794C7.01042 15.7948 7.16346 16.0087 7.20131 16.2553C7.23917 16.502 7.15731 16.7519 6.98088 16.9284L6.97892 16.9303C6.91935 16.9899 6.85055 17.0395 6.77518 17.0772L5.53492 17.6973C5.32635 17.8016 5.15546 17.9677 5.04618 18.1713L4.29433 19.5681C4.15398 19.8289 3.87863 19.9883 3.58261 19.9801C3.28659 19.9719 3.02044 19.7976 2.89467 19.5295L2.28454 18.229C2.17145 17.9883 1.97811 17.7958 1.73857 17.6844L0.45287 17.0857C0.174598 16.9561 -0.00240188 16.676 2.4636e-05 16.369C0.00245121 16.0621 0.183857 15.7848 0.464143 15.6596L1.77529 15.074C2.03563 14.9574 2.24234 14.7478 2.35463 14.4857L2.8845 13.2458C3.00367 12.9669 3.27302 12.7818 3.57607 12.7706ZM3.66622 15.3646C3.42446 15.794 3.06992 16.1487 2.64125 16.3908C3.06895 16.6454 3.41867 17.0141 3.6507 17.4567L3.66506 17.43C3.90569 16.9819 4.26875 16.6117 4.70908 16.3619C4.2761 16.1302 3.9162 15.7848 3.66622 15.3646Z"
      />
    </svg>
  );
}
