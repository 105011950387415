import {
  packagingComponentMaterialV2Stub,
  packagingMaterialV2Stub,
} from "../stubs";
import PackagingComponentEditor, {
  PackagingComponentEditorProps,
} from "./PackagingComponentEditor";

const packagingMaterialNames = [
  "Aluminium",
  "Bamboo",
  "Cardboard",
  "Glass",
  "Paper",
  "Plastic",
  "Wood",
];

function Component(
  props: Pick<PackagingComponentEditorProps, "packagingComponent">
) {
  const { packagingComponent } = props;

  return (
    <PackagingComponentEditor
      availableMaterials={packagingMaterialNames.map((name, nameIndex) =>
        packagingMaterialV2Stub({ id: nameIndex.toString(), name })
      )}
      onDiscard={() => alert("Discard")}
      onSave={(input) =>
        new Promise((resolve) =>
          // Use a timeout so the loading state is visible
          setTimeout(() => {
            alert(`Saved: ${JSON.stringify(input, null, 2)}`);
            resolve();
          }, 1000)
        )
      }
      packagingComponent={packagingComponent}
    />
  );
}

const stories = {
  name: "PackagingComponentEditor",
  children: [
    {
      name: "New Packaging Component",
      render: () => <Component />,
    },
    {
      name: "Edit Packaging Component",
      render: () => (
        <Component
          packagingComponent={{
            id: "1",
            componentMaterials: [
              packagingComponentMaterialV2Stub({
                material: packagingMaterialV2Stub({ name: "Cardboard" }),
                recycledContentFraction: 0.203,
              }),
              packagingComponentMaterialV2Stub({
                material: packagingMaterialV2Stub({ name: "Plastic" }),
                recycledContentFraction: 0.02,
                weightKg: 0.001,
              }),
            ],
            name: "Coffee Cup",
          }}
        />
      ),
    },
  ],
};

export default stories;
