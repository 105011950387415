import gql from "graphql-tag";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage, useIntl } from "react-intl";

import assertNever from "../../util/assertNever";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import ActionModal from "../utils/ActionModal";
import { LinkButton, PrimaryButton, SecondaryButton } from "../utils/Button";
import Form from "../utils/Form";
import useId from "../utils/useId";
import {
  ContactUsButton_SendUserMessage as SendUserMessage,
  ContactUsButton_SendUserMessageVariables as SendUserMessageVariables,
  ContactUsButton_Recipe as Recipe,
} from "./ContactUsButton.graphql";
import useRecipeLabel from "./useRecipeLabel";

interface ContactUsButtonProps {
  buttonType?: "link" | "secondary" | "primary";
  children: React.ReactNode;
  className?: string;
  messageSubject: string;
  modalTitle?: React.ReactNode;
  modalSubject: React.ReactNode;
}

export default function ContactUsButton(props: ContactUsButtonProps) {
  const {
    buttonType = "link",
    children,
    className,
    messageSubject,
    modalTitle,
    modalSubject,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const Button =
    buttonType === "link"
      ? LinkButton
      : buttonType === "secondary"
      ? SecondaryButton
      : buttonType === "primary"
      ? PrimaryButton
      : assertNever(buttonType, `invalid buttonType: ${buttonType}`);

  return (
    <>
      <Button className={className} onClick={handleClick}>
        {children}
      </Button>
      <ContactUsModal
        messageSubject={messageSubject}
        onHide={() => setShowModal(false)}
        show={showModal}
        subject={modalSubject}
        title={modalTitle}
      />
    </>
  );
}

type ContactUsButtonRecipeProps = {
  recipe: Recipe;
} & Omit<ContactUsButtonProps, "messageSubject" | "modalSubject">;

function ContactUsButtonRecipe(props: ContactUsButtonRecipeProps) {
  const { children, className, modalTitle, recipe } = props;

  const recipeLabel = useRecipeLabel();

  return (
    <ContactUsButton
      className={className}
      messageSubject={`Recipe Question: ${recipe.name} (ID: ${recipe.id})`}
      modalTitle={modalTitle}
      modalSubject={
        <FormattedMessage
          id="components/recipes/ContactUsButton:recipeName"
          defaultMessage="{recipeLabel}: <medium>{recipeName}</medium>"
          values={{
            recipeLabel: recipeLabel.singularUppercase,
            recipeName: recipe.name,
            medium: (chunks: React.ReactNode) => (
              <span className="medium-font">{chunks}</span>
            ),
          }}
        />
      }
    >
      {children}
    </ContactUsButton>
  );
}

ContactUsButton.Recipe = ContactUsButtonRecipe;

interface ContactUsModalProps {
  messageSubject: string;
  onHide: () => void;
  show: boolean;
  subject: React.ReactNode;
  title?: React.ReactNode;
}

export function ContactUsModal(props: ContactUsModalProps) {
  const { messageSubject, onHide, show, subject, title } = props;

  const intl = useIntl();

  const messageInputId = useId();
  const [message, setMessage] = useState("");
  const [hasSent, setHasSent] = useState(false);

  const [sendUserMessage] = useMutation<
    SendUserMessage,
    SendUserMessageVariables
  >(sendUserMessageMutation);

  function handleHide() {
    setHasSent(false);
    setMessage("");
    onHide();
  }

  async function handleSubmit() {
    if (message === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/recipes/ContactUsButton:questionIsRequired",
          defaultMessage: "Question is required.",
        })
      );
    }

    await sendUserMessage({
      variables: { input: { subject: messageSubject, body: message } },
    });

    setHasSent(true);
  }

  return (
    <ActionModal
      show={show}
      title={
        title ??
        intl.formatMessage({
          id: "components/recipes/ContactUsButton:modalTitle",
          defaultMessage: "Contact Us",
        })
      }
    >
      <Form onSubmit={handleSubmit}>
        <ActionModal.Body>
          <div className="form-group">{subject}</div>

          <div className="form-group">
            <label htmlFor={messageInputId}>
              <FormattedMessage
                id="components/recipes/ContactUsButton:questionInputLabel"
                defaultMessage="Question"
              />
            </label>
            <textarea
              className="form-control"
              disabled={hasSent}
              id={messageInputId}
              onChange={(event) => setMessage(event.target.value)}
              value={message}
            />
          </div>
        </ActionModal.Body>
        <ActionModal.Footer>
          {hasSent ? (
            <>
              <Alert className="mr-4" variant="success">
                <FormattedMessage
                  id="components/recipes/ContactUsButton:questionSent"
                  defaultMessage="Question sent."
                />
              </Alert>
              <Form.SecondaryButton onClick={handleHide}>
                <FormattedMessage
                  id="components/recipes/ContactUsButton:closeButton"
                  defaultMessage="Close"
                />
              </Form.SecondaryButton>
            </>
          ) : (
            <div className="d-flex w-100 justify-content-between">
              <div>
                <Form.SecondaryButton onClick={handleHide}>
                  <FormattedMessage
                    id="components/recipes/ContactUsButton:cancelButton"
                    defaultMessage="Cancel"
                  />
                </Form.SecondaryButton>
              </div>
              <div className="d-flex">
                <Form.ErrorAlert className="mr-4" />
                <div>
                  <Form.SubmitButton
                    loadingLabel={intl.formatMessage({
                      id: "components/recipes/ContactUsButton:sendButton/loadingLabel",
                      defaultMessage: "Sending",
                    })}
                    submitLabel={intl.formatMessage({
                      id: "components/recipes/ContactUsButton:sendButton/submitLabel",
                      defaultMessage: "Send",
                    })}
                  />
                </div>
              </div>
            </div>
          )}
        </ActionModal.Footer>
      </Form>
    </ActionModal>
  );
}

ContactUsButton.fragments = {
  recipe: gql`
    fragment ContactUsButton_Recipe on Recipe {
      id
      name
    }
  `,
};

const sendUserMessageMutation = gql`
  mutation ContactUsButton_SendUserMessage($input: SendUserMessageInput!) {
    sendUserMessage(input: $input) {
      success
    }
  }
`;
