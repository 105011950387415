import classNames from "classnames";

import QuestionMarkTooltip from "./QuestionMarkTooltip";
import TooltipOverlay, { TooltipOverlayProps } from "./TooltipOverlay";
import useId from "./useId";

interface QuestionMarkTooltipOverlayProps
  extends Pick<TooltipOverlayProps, "placement"> {
  children: React.ReactNode;
  id?: string;
  withMargin?: boolean;
}

export default function QuestionMarkTooltipOverlay(
  props: QuestionMarkTooltipOverlayProps
) {
  const { children, id, placement = "right", withMargin = false } = props;

  const overlayId = useId();

  return (
    <TooltipOverlay
      placement={placement}
      id={id ?? overlayId}
      overlay={children}
    >
      <span
        className={classNames({ "ml-2": withMargin })}
        style={withMargin ? { position: "relative", bottom: "0.125rem" } : {}}
      >
        <QuestionMarkTooltip />
      </span>
    </TooltipOverlay>
  );
}
