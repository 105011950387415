import VectorProps from "./VectorProps";

export default function AllInSelectionX(props: VectorProps) {
  const { fill = "currentColor", width } = props;
  // from https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=45-91&t=yBclT2e8L8nisfXl-0
  // which is this without the surrounding space:
  // https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=1501-1661&t=vibej67hkuOnIxz3-0
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M6.96024 7.72944C6.59949 8.09019 6.0149 8.09019 5.65492 7.72944L3.61579 5.39878L1.57665 7.72867C1.2159 8.08942 0.631316 8.08942 0.271333 7.72867C-0.0894188 7.36791 -0.0894188 6.78333 0.271333 6.42334L2.39277 4.00038L0.270564 1.57589C-0.090188 1.21513 -0.090188 0.631316 0.270564 0.270564C0.631316 -0.090188 1.21513 -0.090188 1.57589 0.270564L3.61579 2.60199L5.65492 0.270564C6.01567 -0.090188 6.59949 -0.090188 6.96024 0.270564C7.32099 0.631316 7.32099 1.2159 6.96024 1.57589L4.83881 4.00038L6.96024 6.42334C7.32099 6.7841 7.32099 7.36868 6.96024 7.72944Z"
        fill={fill}
      />
    </svg>
  );
}
