import { effectTypes } from "../../domain/EffectType";
import PackagingComponentImpactSection from "./PackagingComponentImpactSection";

const packagingComponentsGhgPerServingValue = 3.12345;
const packagingImpactPercentageOfTotalRecipe = 4.125;

const stories = {
  name: "PackagingComponentImpactSection",
  children: [
    {
      name: "default",
      render: () => (
        <PackagingComponentImpactSection
          disabled={false}
          effectType={effectTypes.ghgPerServing}
          ghgPerServingValue={packagingComponentsGhgPerServingValue}
          impactPercentageOfTotalRecipe={packagingImpactPercentageOfTotalRecipe}
        />
      ),
    },
    {
      name: "disabled",
      render: () => (
        <PackagingComponentImpactSection
          disabled={true}
          effectType={effectTypes.ghgPerServing}
          ghgPerServingValue={packagingComponentsGhgPerServingValue}
          impactPercentageOfTotalRecipe={packagingImpactPercentageOfTotalRecipe}
        />
      ),
    },
  ],
};

export default stories;
