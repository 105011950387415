import PackagingComponentTable from "./PackagingComponentTable";
import {
  packagingComponentMaterialV2Stub,
  packagingComponentV2Stub,
  packagingMaterialV2Stub,
} from "./stubs";

const stories = {
  name: "PackagingComponentTable",
  render: () => (
    <div className="mx-4 mt-4">
      <PackagingComponentTable
        handleDelete={(component) => alert(`Delete ${component.name}`)}
        handleEdit={(component) => alert(`Edit ${component.name}`)}
        packagingComponents={[
          packagingComponentV2Stub({
            name: "Coffee Cup",
            componentMaterials: Array(10).fill(
              packagingComponentMaterialV2Stub({
                material: packagingMaterialV2Stub({ name: "Cardboard" }),
                weightKg: 0.012,
              })
            ),
            ghgMagnitude: 0.2,
          }),
          packagingComponentV2Stub({
            name: "Coffee Lid",
            componentMaterials: [
              packagingComponentMaterialV2Stub({
                material: packagingMaterialV2Stub({
                  name: "O" + "verflo".repeat(1000),
                }),
                weightKg: 0.005,
              }),
            ],
            ghgMagnitude: 0.888888888,
          }),
          packagingComponentV2Stub({
            name: "Coffee Whistle",
            componentMaterials: [
              packagingComponentMaterialV2Stub({
                material: packagingMaterialV2Stub({
                  name: "Non-overflowing material name",
                }),
                weightKg: 0.105,
              }),
            ],
            ghgMagnitude: 101.101,
          }),
        ]}
      />
    </div>
  ),
};

export default stories;
