import QuestionMarkTooltipOverlay from "./QuestionMarkTooltipOverlay";

const stories = {
  name: "QuestionMarkTooltipOverlay",
  render: () => (
    <QuestionMarkTooltipOverlay>Tooltip content!</QuestionMarkTooltipOverlay>
  ),
};

export default stories;
