import { useHistory } from "react-router-dom";

import PackagingComponentCard from "./PackagingComponentCard";

interface NewPackagingComponentEditorProps {
  parentUrl: string;
}

export default function NewPackagingComponentEditor(
  props: NewPackagingComponentEditorProps
) {
  const { parentUrl } = props;

  const history = useHistory();

  const handleAfterSave = () => history.push(parentUrl);
  const handleDiscard = () => history.goBack();

  return (
    <PackagingComponentCard
      onAfterSave={handleAfterSave}
      onDiscard={handleDiscard}
    />
  );
}
