import gql from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";

import { gramsUnit, productMassUnits } from "../../../domain/units";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import { combineReadResults } from "../../utils/ReadResult";
import { ProductWeightEditor_Recipe } from "./ProductWeightEditor.graphql";

export interface Value {
  quantity: FloatInput.Value;
  unit: string;
}

export function initialValue(
  recipe: ProductWeightEditor_Recipe | undefined
): Value {
  return {
    quantity: FloatInput.initialValue(recipe?.productMassQuantity ?? null),
    unit: recipe?.productMassUnit ?? gramsUnit.value,
  };
}

export function read(value: Value) {
  const readResults = {
    quantity: FloatInput.read({
      value: value.quantity,
      validationCondition: (value) => value > 0,
    }),
    unit: {
      hasError: false,
      input: value.unit,
      value: value.unit,
    },
  };
  return combineReadResults(readResults);
}

interface ProductWeightEditorProps {
  onChange: (value: Value) => void;
  value: Value;
}

export function ProductWeightEditor(props: ProductWeightEditorProps) {
  const { onChange, value } = props;

  return (
    <div>
      <div className="ProductWeightEditor_TitleAndInstructionsContainer">
        <label className="h5 medium-font" htmlFor="productWeightQuantity">
          <FormattedMessage
            id="components/recipes/ProductEditor/ProductDetails:productWeight"
            defaultMessage="Product Weight"
          />
          <HelpModalTooltip
            title={
              <FormattedMessage
                id="components/recipes/RecipeEditor/ProductWeightEditor:tooltipModal/title"
                defaultMessage="Product Weight"
              />
            }
          >
            <FormattedMessage
              id="components/recipes/RecipeEditor/ProductWeightEditor:tooltipModal/text"
              defaultMessage="Enter the weight for a quantity of product that you would like Foodsteps to assess. This could be, for example:
            <ul>
              <li>A quantity that is packaged and sold to a consumer (via a retail outlet or online)</li>
              <li>A bulk quantity that is sold to a food service organisation</li>
              <li>A quantity that a food service organisation serves to a consumer</li>
              <li>A quantity that is sold to a food manufacturer for inclusion in another food product</li>
            </ul>
            Foodsteps reports the impact of both:
            <ul>
              <li>The quantity of product that you define</li>
              <li>1 kg of product</li>
            </ul>
            "
              values={{
                br: (chunks: React.ReactNode) => <br>{chunks}</br>,
                ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
                li: (chunks: React.ReactNode) => <li>{chunks}</li>,
              }}
            />
          </HelpModalTooltip>
        </label>
        <p className="text-muted">
          <FormattedMessage
            defaultMessage="Enter the net weight of product you would like to assess, e.g. 800g of Cauli Bites that will be packaged and sold to retail."
            id="components/recipes/ProductEditor/ProductWeightEditor:description"
          />
        </p>
      </div>
      <div
        className="d-flex flex-row"
        style={{ paddingRight: "16px", width: "174px" }}
      >
        <FloatInput.FloatInput
          id="productWeightQuantity"
          onChange={(quantity) => onChange({ ...value, quantity })}
          value={value.quantity}
        />
        <select
          className="form-control ml-2 text-right"
          id="productWeightUnit"
          onChange={(event) => onChange({ ...value, unit: event.target.value })}
          value={value.unit}
        >
          {productMassUnits.map((unit) => (
            <option key={unit.value} value={unit.value}>
              {unit.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export const fragments = {
  recipe: () => gql`
    fragment ProductWeightEditor_Recipe on Recipe {
      productMassQuantity
      productMassUnit
    }
  `,
};
