import { useIntl } from "react-intl";

export type ValidationResult =
  | { success: true }
  | { success: false; errorMessage: string };

export function useValidateEmailFormat(): (email: string) => ValidationResult {
  const intl = useIntl();

  return (email) => {
    const isValidEmailFormat = email.includes("@");
    if (isValidEmailFormat) {
      return { success: true };
    } else {
      return {
        success: false,
        errorMessage: intl.formatMessage({
          id: "util/formatValidation:invalidEmailErrorMessage",
          defaultMessage: "This email address is invalid.",
        }),
      };
    }
  };
}
