import addTagModalStories from "./AddTagModal.stories";
import editTagsButtonStories from "./EditTagsButton.stories";
import manageTagsButtonStories from "./ManageTagsButton.stories";
import tagStories from "./Tag.stories";
import tagMultiSelectStories from "./TagMultiSelect.stories";

const stories = {
  name: "Tags",
  children: [
    addTagModalStories,
    editTagsButtonStories,
    manageTagsButtonStories,
    tagMultiSelectStories,
    tagStories,
  ],
};

export default stories;
