import LifeCycleImpacts from "./LifeCycleImpacts";
import { getTestImpact } from "./LifeCycleImpactsTestData";

const stories = {
  name: "LifeCycleImpacts",
  render: () => (
    <LifeCycleImpacts
      impact={getTestImpact()}
      productWeightKgPerServing={2}
      nullProductWeightErrorMessage={"Error"}
    />
  ),
};

export default stories;
