import VectorProps from "./VectorProps";

export default function SaveCopy(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?type=design&node-id=1-67&mode=design&t=UQvqLMzFQnZ7fNQP-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4 12.8C1.04479 12.8 0 11.5811 0 10.1333V2.66667C0 1.21893 1.04479 -1.90735e-06 2.4 -1.90735e-06H10.4C11.7552 -1.90735e-06 12.8 1.21893 12.8 2.66667H11.2C11.2 2.05254 10.8121 1.6 10.4 1.6H2.4C1.98789 1.6 1.6 2.05254 1.6 2.66667V10.1333C1.6 10.7475 1.98789 11.2 2.4 11.2V12.8ZM5.6 16C4.32185 16 3.27705 15.0009 3.20405 13.741L3.2 13.6V5.6C3.2 4.32185 4.19914 3.27707 5.45898 3.20408L5.6 3.2H13.6C14.8781 3.2 15.923 4.19914 15.9959 5.45898L16 5.6V10.2C16 10.6418 15.6418 11 15.2 11C14.7897 11 14.4516 10.6912 14.4054 10.2933L14.4 10.2V5.6C14.4 5.18973 14.0912 4.85159 13.6933 4.80538L13.6 4.8H5.6C5.18973 4.8 4.85158 5.10883 4.80537 5.5067L4.8 5.6V13.6C4.8 14.0103 5.10882 14.3484 5.50669 14.3946L5.6 14.4H10.2C10.6418 14.4 11 14.7582 11 15.2C11 15.6103 10.6912 15.9484 10.2933 15.9946L10.2 16H5.6ZM12 8C12.4418 8 12.8 8.35817 12.8 8.8C12.8 9.21027 12.4912 9.5484 12.0933 9.59461L12 9.6H10.732L15.7657 14.6343C16.0781 14.9467 16.0781 15.4533 15.7657 15.7657C15.4773 16.0541 15.0235 16.0763 14.7097 15.8322L14.6343 15.7657L9.6 10.732V12C9.6 12.4418 9.24183 12.8 8.8 12.8C8.38973 12.8 8.05158 12.4912 8.00537 12.0933L8 12V8.8C8 8.38973 8.30882 8.05159 8.70669 8.00538L8.8 8H12Z"
        fill={fill}
      />
    </svg>
  );
}
