import CarbonLabelsSalesPrompt from "./CarbonLabelsSalesPrompt";

const stories = {
  name: "CarbonLabelsSalesPrompt",
  render: () => (
    <div className="p-4">
      <CarbonLabelsSalesPrompt />
    </div>
  ),
};

export default stories;
