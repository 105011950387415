import sum from "lodash/sum";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Category, ProcuredItem, Supplier } from "../../data-store";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { kilogramsUnit } from "../../domain/units";
import assertNever from "../../util/assertNever";
import { Panel } from "../utils/Panel";
import Select from "../utils/Select";
import {
  Apple,
  GhgEmissions,
  LandUse,
  Spend,
  TotalMass,
  WaterUse,
} from "../utils/Vectors/illustrations";
import CategoriesTable from "./CategoriesTable";
import ProcuredItemsTable from "./ProcuredItemsTable";
import "./ReportContent.css";
import ReportSummaryCard from "./ReportSummaryCard";
import SuppliersTable from "./SuppliersTable";

enum Option {
  CATEGORY = "CATEGORY",
  FOOD_TYPE = "FOOD_TYPE",
  PROCURED_ITEM = "PROCURED_ITEM",
  SUPPLIER = "SUPPLIER",
}
type TableOption =
  | Option.CATEGORY
  | Option.PROCURED_ITEM
  | Option.SUPPLIER
  | Option.FOOD_TYPE;

const tableOptions: Array<TableOption> = [
  Option.CATEGORY,
  Option.PROCURED_ITEM,
  Option.SUPPLIER,
  Option.FOOD_TYPE,
];

interface ReportContentProps {
  categories: Array<Category>;
  foodstepsCategories: Array<Category>;
  impactCategory: ImpactCategory;
  procuredItems: Array<ProcuredItem>;
  totalImpact: number | null;
  totalSpend: string | null;
  suppliers: Array<Supplier>;
}

export default function ReportContent(props: ReportContentProps) {
  const {
    categories,
    foodstepsCategories,
    impactCategory,
    procuredItems,
    suppliers,
    totalImpact,
    totalSpend,
  } = props;

  const effectType = impactCategoryToEffectType(impactCategory);
  const intl = useIntl();

  const [tableType, setTableType] = useState<TableOption>(Option.CATEGORY);

  const renderTableType = (option: TableOption) => {
    if (option === Option.CATEGORY) {
      return intl.formatMessage({
        id: "components/scope-3/ReportContent:tableType/categories",
        defaultMessage: "Categories",
      });
    } else if (option === Option.FOOD_TYPE) {
      return intl.formatMessage({
        id: "components/scope-3/ReportContent:tableType/foodTypes",
        defaultMessage: "Food Types",
      });
    } else if (option === Option.PROCURED_ITEM) {
      return intl.formatMessage({
        id: "components/scope-3/ReportContent:tableType/procuredItems",
        defaultMessage: "ProcuredItems",
      });
    } else if (option === Option.SUPPLIER) {
      return intl.formatMessage({
        id: "components/scope-3/ReportContent:tableType/suppliers",
        defaultMessage: "Suppliers",
      });
    } else {
      assertNever(option, "Unsupported table type");
    }
  };

  const table = () => {
    if (tableType === Option.CATEGORY) {
      return (
        <CategoriesTable
          data={categories}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:category",
            defaultMessage: "Category",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedCategory",
            defaultMessage: "Unspecified category",
          })}
        />
      );
    } else if (tableType === Option.FOOD_TYPE) {
      return (
        <CategoriesTable
          data={foodstepsCategories}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:foodType",
            defaultMessage: "Food Type",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedFoodType",
            defaultMessage: "Unspecified food type",
          })}
        />
      );
    } else if (tableType === Option.PROCURED_ITEM) {
      return (
        <ProcuredItemsTable
          data={procuredItems}
          impactCategory={impactCategory}
        />
      );
    } else if (tableType === Option.SUPPLIER) {
      return (
        <SuppliersTable data={suppliers} impactCategory={impactCategory} />
      );
    } else {
      assertNever(tableType, "Unsupported table type");
    }
  };

  const handleTableTypeChange = (selectedTableType: TableOption | null) => {
    if (selectedTableType === null) {
      throw new Error("Cannot have null table type");
    }
    setTableType(selectedTableType);
  };

  const icon = () => {
    if (impactCategory === ImpactCategory.GHG) {
      return GhgEmissions;
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return LandUse;
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return WaterUse;
    } else {
      assertNever(impactCategory, "Unsupported ImpactCategory");
    }
  };

  return (
    <div className="ReportContent">
      <Panel className="ReportContent_Panel">
        <h3>
          <FormattedMessage
            id="components/scope-3/ReportContent:summary"
            defaultMessage="Summary"
          />
        </h3>
        <div className="ReportContent_SummaryCards">
          {totalImpact !== null && (
            <ReportSummaryCard
              Icon={icon()}
              title={
                <>
                  <FormattedMessage
                    id="components/scope-3/ReportContent:total"
                    defaultMessage="Total"
                  />{" "}
                  {effectType.title(intl)}
                </>
              }
              value={totalImpact.toFixed(2)}
              unit={effectType.unit}
            />
          )}
          <ReportSummaryCard
            Icon={Apple}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:procuredItems"
                defaultMessage="Procured Items"
              />
            }
            value={procuredItems.length}
          />
          <ReportSummaryCard
            Icon={TotalMass}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:totalMass"
                defaultMessage="Total Mass"
              />
            }
            value={sum(procuredItems.map((item) => item.massKg)).toFixed(2)}
            unit={kilogramsUnit.label}
          />
          <ReportSummaryCard
            Icon={Spend}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:totalSpend"
                defaultMessage="Total Spend"
              />
            }
            value={totalSpend ?? "-"}
            unit={
              totalSpend ? (
                <FormattedMessage
                  id="components/scope-3/ReportContent:£"
                  defaultMessage="£"
                />
              ) : null
            }
          />
        </div>
      </Panel>
      <div>
        <Panel>
          <div className="ReportContent_TableTypeSelect_Container">
            <div className="medium-font">
              <FormattedMessage
                id="components/scope-3/ReportContent:breakdownBy"
                defaultMessage="Breakdown by"
              />
            </div>
            <Select<TableOption>
              className="ReportContent_TableTypeSelect"
              isClearable={false}
              options={tableOptions}
              onChange={handleTableTypeChange}
              value={tableType}
              optionKey={renderTableType}
              renderOption={renderTableType}
            />
          </div>
          {table()}
        </Panel>
      </div>
    </div>
  );
}
