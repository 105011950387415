import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { RecipeCoProductInput } from "../../../__generated__/globalTypes";
import { productMassUnits } from "../../../domain/units";
import * as FloatInput from "../../utils/FloatInput";
import ReadResult from "../../utils/ReadResult";
import TableEditor from "../../utils/TableEditor";
import * as TextField from "../../utils/TextField";
import { WeightInput } from "../../utils/WeightInput";
import { EconomicValueEditor } from "./EconomicValueEditor";
import "./PhysicalOutputEditor.css";

export interface Value {
  economicValuePerKg: FloatInput.Value;
  key: string;
  name: TextField.Value;
  weightQuantity: FloatInput.Value;
  weightUnit: string;
}

export function blankValue(): Value {
  return {
    economicValuePerKg: FloatInput.initialValue(null),
    key: `new_${uuidv4()}`,
    name: TextField.initialValue(""),
    weightQuantity: FloatInput.initialValue(null),
    weightUnit: "kg",
  };
}

export function readCoProductInput(
  value: Value
): ReadResult<Value, RecipeCoProductInput> {
  const economicValuePerKgResult = FloatInput.readAllowEmpty({
    value: value.economicValuePerKg,
    validationCondition: (value) => value > 0,
  });
  const weightQuantityResult = FloatInput.read({
    value: value.weightQuantity,
    validationCondition: (value) => value >= 0,
  });
  const nameResult = TextField.read(value.name);
  if (
    economicValuePerKgResult.hasError ||
    weightQuantityResult.hasError ||
    nameResult.hasError
  ) {
    return {
      hasError: true,
      value: {
        ...value,
        economicValuePerKg: economicValuePerKgResult.value,
        weightQuantity: weightQuantityResult.value,
        name: nameResult.value,
      },
    };
  } else {
    return {
      hasError: false,
      value,
      input: {
        economicValuePerKg: economicValuePerKgResult.input ?? 0,
        massQuantity: weightQuantityResult.input,
        massUnit: value.weightUnit,
        name: nameResult.input,
      },
    };
  }
}

interface CoProductEditorProps {
  onChange: (value: Value) => void;
  onDelete: () => void;
  value: Value;
}

export function CoProductEditor(props: CoProductEditorProps) {
  const { value, onChange, onDelete } = props;

  const intl = useIntl();

  return (
    <div className="PhysicalOutputsEditor_RowContainer">
      <div className="PhysicalOutputsEditor_Inputs">
        <WeightInput
          className="PhysicalOutputEditor_WeightInput"
          value={{ quantity: value.weightQuantity, unit: value.weightUnit }}
          onChange={(weightValue) =>
            onChange({
              ...value,
              weightQuantity: weightValue.quantity,
              weightUnit: weightValue.unit,
            })
          }
          units={productMassUnits}
        />
        <TextField.TextField
          className="PhysicalOutputEditor_TextInput"
          onChange={(name) => onChange({ ...value, name })}
          value={value.name}
          placeholder={intl.formatMessage({
            defaultMessage: "e.g. Cauli Rice",
            id: "components/recipes/ProductEditor/CoProductEditor:nameInputPlaceholder",
          })}
        />
        <EconomicValueEditor
          className="PhysicalOutputEditor_EconomicValueEditor"
          value={value.economicValuePerKg}
          onChange={(economicValuePerKg) =>
            onChange({ ...value, economicValuePerKg })
          }
        />
      </div>
      <div className="PhysicalOutputEditor_DeleteColumn">
        <TableEditor.DeleteCell onDelete={onDelete} />
      </div>
    </div>
  );
}
