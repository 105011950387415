import { useState } from "react";

import { Currency } from "../../__generated__/globalTypes";
import { CurrencySelect } from "./CurrencySelection";

const stories = {
  name: "CurrencySelect",
  render: () => <Story />,
};

function Story() {
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    Currency.GBP
  );

  const handleCurrencySelect = (currency: Currency | null) => {
    if (currency !== null) {
      setSelectedCurrency(currency);
    }
  };

  return (
    <CurrencySelect onChange={handleCurrencySelect} value={selectedCurrency} />
  );
}

export default stories;
