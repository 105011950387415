import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { components, MenuProps, OptionProps } from "react-select";
import { ControlProps } from "react-select/src/components/Control";
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from "react-select/src/components/MultiValue";
import { MenuPlacement } from "react-select/src/types";

import assertNever from "../../util/assertNever";
import { MultiSelect } from "../utils/Select";
import TooltipOverlay from "../utils/TooltipOverlay";
import useId from "../utils/useId";
import {
  Close2,
  DoubleTick,
  Plus,
  PlusBold,
  Search,
  Tick,
} from "../utils/Vectors";
import "./TagMultiSelect.css";

export enum TagMultiSelectOptionType {
  PARTIAL = "PARTIAL",
  ALL = "ALL",
  SIMPLE = "SIMPLE",
}

export type TagMultiSelectOption =
  | {
      type: TagMultiSelectOptionType.PARTIAL;
      id: number;
      name: string;
      productCount: number;
      onAdd: (
        currentOptions: Array<TagMultiSelectOption>,
        tag: { id: number; name: string }
      ) => void;
    }
  | { type: TagMultiSelectOptionType.ALL; id: number; name: string }
  | { type: TagMultiSelectOptionType.SIMPLE; id: number; name: string };

interface TagMultiSelectProps {
  id?: string;
  menuPlacement?: MenuPlacement;
  options: Array<TagMultiSelectOption>;
  onNewTagClicked: () => void;
  onChange: (ids: ReadonlyArray<number>) => void;
  placeholder?: React.ReactNode;
  selectedIds: ReadonlyArray<number>;
  menuIsOpen?: boolean;
  setMenuIsOpen?: (isOpen: boolean) => void;
}

export default function TagMultiSelect(props: TagMultiSelectProps) {
  const {
    id,
    menuPlacement,
    options,
    onChange,
    onNewTagClicked,
    placeholder,
    selectedIds,
    menuIsOpen,
    setMenuIsOpen,
  } = props;

  const intl = useIntl();

  const value: ReadonlyArray<TagMultiSelectOption> = options.filter((option) =>
    selectedIds.includes(option.id)
  );

  const handleChange = (value: ReadonlyArray<TagMultiSelectOption>) => {
    onChange(value.map((option) => option.id));
  };

  const noOptionsMessage = () => {
    return intl.formatMessage({
      id: "components/tags/TagMultiSelect:noOptionsMessage",
      defaultMessage: "You don't have any tags yet.",
    });
  };

  const Menu = (
    props: MenuProps<
      TagMultiSelectOption,
      true,
      { options: Array<TagMultiSelectOption> }
    >
  ) => {
    return (
      <>
        <components.Menu {...props}>
          <>
            <div
              className="TagMultiSelect_NewTagContainer action-link"
              onClick={onNewTagClicked}
            >
              <div className="TagMultiSelect_NewTagTextContainer ">
                <Plus width={20} />
                <FormattedMessage
                  id="components/tags/TagMultiSelect:newTag"
                  defaultMessage="New Tag"
                />
              </div>
            </div>
            <hr className="TagMultiSelect_Divider" />
            {props.children}
          </>
        </components.Menu>
      </>
    );
  };

  return (
    <MultiSelect
      id={id}
      className="TagMultiSelect"
      components={{
        Control,
        Menu,
        MultiValueContainer,
        MultiValueLabel,
        MultiValueRemove,
        Option,
      }}
      menuPlacement={menuPlacement}
      onChange={handleChange}
      value={value}
      noOptionsMessage={noOptionsMessage}
      optionKey={(option) => option.id.toString()}
      options={options}
      placeholder={
        placeholder ?? (
          <FormattedMessage
            id="frontend/src/components/tags/TagMultiSelect:defaultPlaceholder"
            defaultMessage="Search tags to add"
          />
        )
      }
      renderOption={(option) => option.name}
      hideSelectedOptions={false}
      menuIsOpen={menuIsOpen}
      setMenuIsOpen={setMenuIsOpen}
    />
  );
}

const MultiValueRemove = (
  props: MultiValueRemoveProps<TagMultiSelectOption>
) => {
  const overlayId = useId();
  return (
    <div className="TagMultiSelect_Chip_Close action-link">
      <TooltipOverlay
        id={overlayId}
        overlay={
          <FormattedMessage
            id="components/tags/TagMultiSelect:removeTooltip"
            defaultMessage="Remove"
          />
        }
        placement="top"
      >
        <components.MultiValueRemove {...props}>
          <Close2 width={8} />
        </components.MultiValueRemove>
      </TooltipOverlay>
    </div>
  );
};

const MultiValueLabel = (
  props: MultiValueGenericProps<TagMultiSelectOption>
) => {
  return (
    <components.MultiValueLabel {...props}>
      <Label allOptions={props.selectProps.options} option={props.data} />
    </components.MultiValueLabel>
  );
};

interface LabelProps {
  allOptions: Array<TagMultiSelectOption>;
  option: TagMultiSelectOption;
}

function Label(props: LabelProps) {
  const { allOptions, option } = props;
  const overlayId = useId();

  if (option.type === TagMultiSelectOptionType.SIMPLE) {
    return <>{option.name}</>;
  } else if (option.type === TagMultiSelectOptionType.ALL) {
    return (
      <>
        {option.name} (
        <FormattedMessage
          id="components/tags/TagMultiSelect:all"
          defaultMessage="All"
        />
        )
      </>
    );
  } else if (option.type === TagMultiSelectOptionType.PARTIAL) {
    const handleClick = () => {
      option.onAdd(allOptions, { ...option });
    };
    return (
      <TooltipOverlay
        id={overlayId}
        overlay={
          <FormattedMessage
            id="components/tags/TagMultiSelect:addToAllTooltip"
            defaultMessage="Add to all"
          />
        }
        placement="top"
      >
        <div
          onClick={handleClick}
          className="TagMultiSelect_AddLabel action-link multi-select-prevent-menu-open"
        >
          <PlusBold width={10} className="multi-select-prevent-menu-open" />
          {option.name} ({option.productCount})
        </div>
      </TooltipOverlay>
    );
  } else {
    assertNever(option, "Invalid type");
  }
}

const MultiValueContainer = (
  props: MultiValueGenericProps<TagMultiSelectOption>
) => {
  return (
    <div className="TagMultiSelect_MultiValueContainer">
      <div className="TagMultiSelect_MultiValueContainer_Arrow" />
      <components.MultiValueContainer {...props} />
    </div>
  );
};

const Option = (
  props: OptionProps<
    TagMultiSelectOption,
    true,
    { options: Array<TagMultiSelectOption> }
  >
) => {
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (props.data.type === TagMultiSelectOptionType.PARTIAL) {
      props.data.onAdd(props.selectProps.options, { ...props.data });
    } else {
      props.innerProps.onClick(event);
    }
  };

  const tickWidth = 20;
  const tickFill = "var(--foodsteps-turquoise";

  const tickOrNull = () => {
    if (props.isSelected) {
      return props.data.type === TagMultiSelectOptionType.ALL ? (
        <DoubleTick width={tickWidth} fill={tickFill} />
      ) : (
        <Tick width={tickWidth} fill={tickFill} />
      );
    } else {
      return null;
    }
  };
  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, onClick: handleClick }}
    >
      {props.data.name}
      <div className="TagMultiSelect_TickContainer">{tickOrNull()}</div>
    </components.Option>
  );
};

function Control({
  children,
  ...props
}: ControlProps<
  TagMultiSelectOption,
  true,
  { options: Array<TagMultiSelectOption> }
>) {
  const isEmpty = () => {
    return (
      Array.isArray(props.selectProps.value) &&
      props.selectProps.value.length === 0
    );
  };
  return (
    <components.Control {...props}>
      {isEmpty() ? <Search className="ml-2" width={20} /> : null}
      {children}
    </components.Control>
  );
}
