import { GhgEmissions } from "../utils/Vectors/illustrations";
import ImpactCategoryCard from "./ImpactCategoryCard";

const stories = {
  name: "ImpactCategoryCard",
  render: () => (
    <div className="m-3" style={{ width: "20%" }}>
      <ImpactCategoryCard
        impact={{
          magnitude: 12.3,
          dataQualityScore: 4.1,
          unit: "kg CO2e / kg",
        }}
        symbol={<GhgEmissions width={32} />}
        impactCategory={<>Carbon Emissions</>}
      />
    </div>
  ),
};

export default stories;
