import { EffectType, ImpactRating } from "../../../__generated__/globalTypes";
import DescriptiveImpactAndScaleLabel from "./DescriptiveImpactAndScaleLabel";
import DescriptiveRatingLabel from "./DescriptiveRatingLabel";
import DescriptiveRatingScaleLabel from "./DescriptiveRatingScaleLabel";
import LetterRatingLabel from "./LetterRatingLabel";
import RatingScaleLabel from "./RatingScaleLabel";
import TitledRatingScaleLabel from "./TitledRatingScaleLabel";

const stories = {
  name: "CarbonLabel",
  children: [
    {
      name: "Colour",
      children: [
        {
          name: "DescriptiveImpactAndScaleLabel",
          children: [
            {
              name: "Per kg",
              render: () => (
                <div className="d-flex flex-row">
                  {Object.values(ImpactRating).map((impactRating) => {
                    return (
                      <DescriptiveImpactAndScaleLabel
                        className="mx-4 mt-4"
                        impactRating={impactRating}
                        effectType={EffectType.GHG_PER_KG}
                        impactMagnitude={1.99}
                        width={150}
                      />
                    );
                  })}
                </div>
              ),
            },
            {
              name: "Per serving",
              render: () => (
                <div className="d-flex flex-row">
                  {Object.values(ImpactRating).map((impactRating) => {
                    return (
                      <DescriptiveImpactAndScaleLabel
                        className="mx-4 mt-4"
                        impactRating={impactRating}
                        effectType={EffectType.GHG_PER_SERVING}
                        impactMagnitude={1.99}
                        width={150}
                      />
                    );
                  })}
                </div>
              ),
            },
          ],
        },
        {
          name: "DescriptiveRatingLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <DescriptiveRatingLabel
                    className="mx-2 mt-4"
                    size="large"
                    impactRating={impactRating}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "DescriptiveRatingScaleLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <DescriptiveRatingScaleLabel
                    className="mx-2 mt-4"
                    impactRating={impactRating}
                    width={300}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "LetterRatingLabel",
          render: () => (
            <div className="d-flex flex-row">
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <LetterRatingLabel
                    className="mx-2 mt-2"
                    size="large"
                    impactRating={impactRating}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "RatingScaleLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <RatingScaleLabel
                    className="mx-2 mt-4"
                    size="large"
                    impactRating={impactRating}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "TitledRatingScaleLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <TitledRatingScaleLabel
                    className="mx-2 mt-4"
                    impactRating={impactRating}
                    width={300}
                  />
                );
              })}
            </div>
          ),
        },
      ],
    },
    {
      name: "Monochrome",
      children: [
        {
          name: "DescriptiveImpactAndScaleLabel",
          children: [
            {
              name: "Per kg",
              render: () => (
                <div className="d-flex flex-row">
                  {Object.values(ImpactRating).map((impactRating) => {
                    return (
                      <DescriptiveImpactAndScaleLabel
                        className="mx-4 mt-4"
                        impactRating={impactRating}
                        effectType={EffectType.GHG_PER_KG}
                        impactMagnitude={1.99}
                        monochrome={true}
                        width={150}
                      />
                    );
                  })}
                </div>
              ),
            },
            {
              name: "Per serving",
              render: () => (
                <div className="d-flex flex-row">
                  {Object.values(ImpactRating).map((impactRating) => {
                    return (
                      <DescriptiveImpactAndScaleLabel
                        className="mx-4 mt-4"
                        impactRating={impactRating}
                        effectType={EffectType.GHG_PER_SERVING}
                        impactMagnitude={1.99}
                        monochrome={true}
                        width={150}
                      />
                    );
                  })}
                </div>
              ),
            },
          ],
        },
        {
          name: "DescriptiveRatingLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <DescriptiveRatingLabel
                    className="mx-2 mt-4"
                    size="large"
                    impactRating={impactRating}
                    monochrome={true}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "DescriptiveRatingScaleLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <DescriptiveRatingScaleLabel
                    className="mx-2 mt-4"
                    impactRating={impactRating}
                    monochrome={true}
                    width={300}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "LetterRatingLabel",
          render: () => (
            <div className="d-flex flex-row">
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <LetterRatingLabel
                    className="mx-2 mt-2"
                    size="large"
                    impactRating={impactRating}
                    monochrome={true}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "RatingScaleLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <RatingScaleLabel
                    className="mx-2 mt-4"
                    size="large"
                    impactRating={impactRating}
                    monochrome={true}
                  />
                );
              })}
            </div>
          ),
        },
        {
          name: "TitledRatingScaleLabel",
          render: () => (
            <div>
              {Object.values(ImpactRating).map((impactRating) => {
                return (
                  <TitledRatingScaleLabel
                    className="mx-2 mt-4"
                    impactRating={impactRating}
                    monochrome={true}
                    width={300}
                  />
                );
              })}
            </div>
          ),
        },
      ],
    },
  ],
};

export default stories;
