import { useUserInfoOrNull } from "../data-store/useUserInfo";
import { useSession } from "../sessions";
import { PrimaryButton } from "./utils/Button";

interface ImpersonationContainerProps {
  children: React.ReactNode;
}

export function ImpersonationContainer(props: ImpersonationContainerProps) {
  const { children } = props;
  const { impersonate, impersonatedUser } = useSession();
  const impersonatedUserId = impersonatedUser?.id ?? null;
  const userInfo = useUserInfoOrNull();
  const impersonationSucceeded =
    userInfo !== null && userInfo.id === impersonatedUserId;

  if (impersonatedUserId === null) {
    return <>{children}</>;
  } else {
    return (
      <div className="h-100 d-flex flex-column">
        <div>
          <ImpersonationBanner
            impersonate={impersonate}
            impersonatedUserId={impersonatedUserId}
            impersonationSucceeded={impersonationSucceeded}
          />
        </div>
        <div className="flex-fit">
          {impersonationSucceeded ? children : null}
        </div>
      </div>
    );
  }
}

interface ImpersonationBannerProps {
  impersonate: (userId: null) => void;
  impersonatedUserId: number;
  impersonationSucceeded: boolean;
}

function ImpersonationBanner(props: ImpersonationBannerProps) {
  const { impersonate, impersonatedUserId, impersonationSucceeded } = props;

  return (
    <div
      className="d-flex justify-content-between align-items-center flex-row p-4"
      style={{ background: "var(--danger)", color: "white" }}
    >
      <div>
        {impersonationSucceeded ? (
          <>Impersonating user ID {impersonatedUserId}</>
        ) : (
          <>Failed to impersonate user ID {impersonatedUserId}</>
        )}
      </div>

      <div>
        <PrimaryButton onClick={() => impersonate(null)}>
          Stop impersonation
        </PrimaryButton>
      </div>
    </div>
  );
}
