import UserVisibleError from "../../util/UserVisibleError";
import ErrorAlert from "./ErrorAlert";

const stories = {
  name: "ErrorAlert",
  children: [
    {
      name: "Error",
      render: () => <ErrorAlert error={new Error("Invalid input.")} />,
    },
    {
      name: "UserVisibleError",
      render: () => (
        <ErrorAlert error={new UserVisibleError("Invalid input.")} />
      ),
    },
    {
      name: "UserVisibleError with ReactNode",
      render: () => (
        <ErrorAlert
          error={
            new UserVisibleError(
              "Invalid input: grams",
              (
                <>
                  Invalid input: <strong>grams</strong>
                </>
              )
            )
          }
        />
      ),
    },
    {
      name: "dismissable",
      render: () => (
        <ErrorAlert
          error={new UserVisibleError("Invalid input.")}
          onClose={() => alert("dismissed!")}
        />
      ),
    },
  ],
};

export default stories;
