import VectorProps from "./VectorProps";

export default function PlusBold(props: VectorProps) {
  const { fill = "currentColor", width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1-67&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M0 5.09995C0 4.6029 0.402944 4.19995 0.9 4.19995H9.1C9.59706 4.19995 10 4.6029 10 5.09995C10 5.59701 9.59706 5.99995 9.1 5.99995H0.9C0.402944 5.99995 0 5.59701 0 5.09995Z"
        fill={fill}
      />
      <path
        d="M5.09922 10C4.60216 10 4.19922 9.59706 4.19922 9.1V0.9C4.19922 0.402944 4.60216 0 5.09922 0C5.59627 0 5.99922 0.402944 5.99922 0.9V9.1C5.99922 9.59706 5.59627 10 5.09922 10Z"
        fill={fill}
      />
    </svg>
  );
}
