export function toMultiMap<K, V>(values: Array<[K, V]>): Map<K, Array<V>> {
  const results = new Map<K, Array<V>>();

  for (const [key, value] of values) {
    let valuesForKey = results.get(key);
    if (valuesForKey === undefined) {
      valuesForKey = [];
      results.set(key, valuesForKey);
    }
    valuesForKey.push(value);
  }

  return results;
}
