import VectorProps from "./VectorProps";

export default function DownUpArrow(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from icons 8
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 8 10"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 0L7.4641 3.75H0.535898L4 0Z" />
      <path d="M4 10L0.535898 6.25H7.4641L4 10Z" />
    </svg>
  );
}
