import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import useAvailableIngredients from "../../data-store/useAvailableIngredients";
import { ImpactCategory } from "../../domain/impactCategories";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
  useRequestImpactAnalysis,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import { mapNotNull } from "../../util/arrays";
import assertNever from "../../util/assertNever";
import UserVisibleError from "../../util/UserVisibleError";
import {
  linkIngredientsToFoodClass,
  MutationField,
  useExecuteCompoundMutation,
} from "../graphql/mutations";
import useQuery from "../graphql/useQuery";
import {
  useOrganization,
  useOrganizationId,
} from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import ImpactCategoryToggle, {
  ImpactCategoryToggleType,
} from "../recipes/ImpactCategoryToggle";
import IngredientMatcher from "../recipes/IngredientMatcher";
import {
  IngredientNameStatus,
  setIngredientNameStatusErrors,
  useCreateIngredientNameToFoodClassIdMap,
  useCreateIngredientStatuses,
} from "../recipes/UploadRecipesPage";
import StatusDisplay from "../StatusDisplay";
import { getNameForLocale } from "../utils/getNameForLocale";
import InformationModalTooltip from "../utils/InformationModalTooltip";
import { recipesOwnedByOrganizationOrInUserGroupFilter } from "../utils/ownedByOrganizationOrInUserGroupFilter";
import Spinner from "../utils/Spinner";
import IngredientOriginModal from "./IngredientOriginModal";
import {
  IngredientsPage_LinkIngredientsToFoodClass as LinkIngredientsToFoodClass,
  IngredientsPage_Query as Query,
  IngredientsPage_QueryVariables as QueryVariables,
  IngredientsPage_Recipe as Recipe,
  IngredientsPage_RecipeIngredient as RecipeIngredient,
  IngredientsPage_WeightedLocationOption as WeightedLocationOption,
} from "./IngredientsPage.graphql";
import { IngredientsQueryControls } from "./IngredientsQueryControls";
import IngredientsTable from "./IngredientsTable";
import UpdateIngredientsBanner from "./UpdateIngredientsBanner";
import "./IngredientsPage.css";

const tracking = { pageName: "ingredients" };

type State =
  | {
      type: "list";
      selectedFoodClassId: number | null;
      loadingIngredientNameStatuses: boolean;
      impactCategory: ImpactCategory;
      search: string;
    }
  | { type: "update"; ingredientNameStatuses: Array<IngredientNameStatus> };

export default function IngredientsPage() {
  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();
  const canRequestImpactAnalysis = useRequestImpactAnalysis();
  const createIngredientStatuses = useCreateIngredientStatuses();
  const executeCompoundMutation = useExecuteCompoundMutation();
  const [organizationId] = useOrganizationId();
  const [availableIngredientsStatus] = useAvailableIngredients(organizationId);
  const recipesToFoodClassNamesById = useRecipesToFoodClassNamesById;

  const pages = usePages();
  const [queryParams, setQueryParams] =
    pages.IngredientsListPage.useQueryParams();

  const createIngredientNameToFoodClassIdMap =
    useCreateIngredientNameToFoodClassIdMap();
  const { trackImpactCategorySet } = useTracking();
  const [fetchingUpdatedFoodClasses, setFetchingUpdatedFoodClasses] =
    useState<boolean>(false);

  const [state, setState] = useState<State>({
    type: "list",
    selectedFoodClassId: null,
    loadingIngredientNameStatuses: false,
    impactCategory: ImpactCategory.GHG,
    search: "",
  });

  const { status: queryStatus, refresh: refreshQuery } = useQuery<
    Query,
    QueryVariables
  >(pageQuery, {
    weightedLocationOptionFilter: { organizationId },
    recipeFilter: recipesOwnedByOrganizationOrInUserGroupFilter(organizationId),
  });

  const findWeightedLocationOptionForSelectedFoodClassId = (
    weightedLocationOptions: Array<WeightedLocationOption>
  ) => {
    if (state.type !== "list") {
      throw new Error('Invalid state. Expected state to have type "list"');
    }
    return (
      weightedLocationOptions.find(
        ({ foodClassId }) => foodClassId === state.selectedFoodClassId
      ) ?? null
    );
  };

  const handleUpdateLinkClicked = async (allRecipes: Array<Recipe>) => {
    if (state.type !== "list") {
      throw new Error("Invalid state type");
    }
    setState({ ...state, loadingIngredientNameStatuses: true });
    const { ingredientNameStatuses } = await createIngredientStatuses(
      new Map(),
      new Map(),
      recipesToIngredientsAwaitingLinking(allRecipes).map((ingredient) => ({
        name: ingredient.name,
      })),
      false
    );
    setState({ type: "update", ingredientNameStatuses });
  };

  const handleIsAcceptedChange = (
    status: IngredientNameStatus,
    isAccepted: boolean
  ) => {
    if (state.type !== "update") {
      throw new Error("Invalid state");
    }
    setState({
      ...state,
      ingredientNameStatuses: state.ingredientNameStatuses.map(
        (existingStatus) =>
          existingStatus.ingredientName === status.ingredientName
            ? {
                ...existingStatus,
                isAccepted,
              }
            : existingStatus
      ),
    });
  };

  const handleSelectAlternativeChange = ({
    status,
    alternativeIngredientName,
    alternativeIngredientFoodClassId,
  }: {
    status: IngredientNameStatus;
    alternativeIngredientName: string;
    alternativeIngredientFoodClassId: number | null;
  }) => {
    if (state.type !== "update") {
      throw new Error("Invalid state");
    }
    setState({
      ...state,
      ingredientNameStatuses: state.ingredientNameStatuses.map(
        (existingStatus) =>
          existingStatus.ingredientName === status.ingredientName
            ? {
                ...existingStatus,
                hasError: false,
                selectedAlternativeName:
                  alternativeIngredientName !== ""
                    ? alternativeIngredientName
                    : null,
                selectedAlternativeFoodClassId:
                  alternativeIngredientFoodClassId,
              }
            : existingStatus
      ),
    });
  };

  const clearState = () =>
    setState({
      type: "list",
      selectedFoodClassId: null,
      loadingIngredientNameStatuses: false,
      impactCategory: ImpactCategory.GHG,
      search: "",
    });

  async function handleUpdate() {
    if (state.type !== "update") {
      throw new Error("Invalid state");
    }

    const { updatedStatuses, hasError } = setIngredientNameStatusErrors(
      state.ingredientNameStatuses
    );
    setState({ ...state, ingredientNameStatuses: updatedStatuses });
    if (hasError) {
      throw new UserVisibleError(
        "Please select a valid Foodsteps Ingredient for all updated matches."
      );
    }

    const ingredientNameToFoodClassId = createIngredientNameToFoodClassIdMap(
      state.ingredientNameStatuses,
      "Ingredients"
    );

    const linkIngredientToFoodClassMutations: Array<
      MutationField<LinkIngredientsToFoodClass>
    > = [];

    for (const status of state.ingredientNameStatuses) {
      const foodClassId = ingredientNameToFoodClassId.get(
        status.ingredientName
      );
      if (foodClassId === undefined) {
        throw new Error(
          `ingredientNameToFoodClassId does not have an entry for the ingredient name ${status.ingredientName}`
        );
      }
      if (foodClassId !== null) {
        linkIngredientToFoodClassMutations.push(
          linkIngredientsToFoodClass<LinkIngredientsToFoodClass>({
            input: {
              ingredientName: status.ingredientName,
              foodClassId,
            },
            outputFragment: gql`
              fragment IngredientsPage_LinkIngredientsToFoodClass on LinkIngredientsToFoodClass {
                success
              }
            `,
          })
        );
      }
    }

    if (linkIngredientToFoodClassMutations.length > 0) {
      await executeCompoundMutation(linkIngredientToFoodClassMutations).then(
        async () => {
          await refreshQuery();
          clearState();
        }
      );
    } else {
      clearState();
    }
  }

  const submitButtonLabel = {
    loadingLabel: (
      <FormattedMessage
        id="components/recipes/IngredientsPage:updating"
        defaultMessage="Updating"
      />
    ),
    submitLabel: (
      <FormattedMessage
        id="components/recipes/IngredientsPage:update"
        defaultMessage="Update"
      />
    ),
  };

  const handleCancelUpdate = () => clearState();

  const handleImpactCategoryChange = (impactCategory: ImpactCategory) => {
    if (state.type !== "list") {
      throw Error("Must be in the list state to change impact category");
    }
    setState({ ...state, impactCategory });
    trackImpactCategorySet({
      pageName: pages.Ingredients.title,
      impactCategory,
    });
  };

  return (
    <Page tracking={tracking}>
      <div className="h-100 d-flex flex-column">
        <Page.Title breadcrumb={pages.Ingredients.breadcrumb()} />
        <StatusDisplay status={queryStatus}>
          {(query: Query) => {
            if (state.type === "list") {
              if (state.loadingIngredientNameStatuses) {
                return <Spinner />;
              }
              const numUnlinkedIngredientNames = getNumUnlinkedIngredientNames(
                queryFieldToNodes(query.recipes)
              );
              return (
                <div className="IngredientsPageContent">
                  <div className="d-flex justify-content-between w-100 mb-3 flex-row">
                    <div className="IngredientsPage_SearchBoxContainer">
                      <IngredientsQueryControls
                        disabled={fetchingUpdatedFoodClasses}
                        onChange={setQueryParams}
                        value={queryParams}
                      />
                    </div>
                    {additionalImpactCategories && (
                      <ImpactCategoryToggle
                        onChange={handleImpactCategoryChange}
                        options={availableImpactCategories}
                        selectedImpactCategory={state.impactCategory}
                        type={ImpactCategoryToggleType.PAGE}
                      />
                    )}
                  </div>
                  {numUnlinkedIngredientNames > 0 ? (
                    <div className="w-100 mb-4">
                      <UpdateIngredientsBanner
                        numUnlinkedIngredientNames={numUnlinkedIngredientNames}
                        onClick={() =>
                          handleUpdateLinkClicked(
                            queryFieldToNodes(query.recipes)
                          )
                        }
                      />
                    </div>
                  ) : null}
                  <IngredientsTable
                    impactCategory={state.impactCategory}
                    locations={queryFieldToNodes(query.locations)}
                    selectFoodClassId={(selectedFoodClassId) =>
                      setState({ ...state, selectedFoodClassId })
                    }
                    weightedLocationOptions={queryFieldToNodes(
                      query.weightedLocationOptions
                    )}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    setFetchingUpdatedFoodClasses={
                      setFetchingUpdatedFoodClasses
                    }
                  />
                  <IngredientOriginModal
                    foodClassId={state.selectedFoodClassId}
                    foodClassNamesById={recipesToFoodClassNamesById(
                      queryFieldToNodes(query.recipes)
                    )}
                    locations={queryFieldToNodes(query.locations)}
                    onHide={() =>
                      setState({ ...state, selectedFoodClassId: null })
                    }
                    weightedLocationOption={findWeightedLocationOptionForSelectedFoodClassId(
                      queryFieldToNodes(query.weightedLocationOptions)
                    )}
                    refreshWeightedLocationOptions={refreshQuery}
                  />
                </div>
              );
            } else if (state.type === "update") {
              return (
                <StatusDisplay status={availableIngredientsStatus}>
                  {(availableIngredients) => (
                    <IngredientMatcher
                      availableIngredients={availableIngredients}
                      canRequestImpactAnalysis={canRequestImpactAnalysis}
                      onSubmit={handleUpdate}
                      ingredientNameStatuses={state.ingredientNameStatuses}
                      onCancel={handleCancelUpdate}
                      onIsAcceptedChange={handleIsAcceptedChange}
                      onSelectAlternativeChange={handleSelectAlternativeChange}
                      submitButtonLabel={submitButtonLabel}
                    />
                  )}
                </StatusDisplay>
              );
            } else {
              assertNever(state, "Invalid state type");
            }
          }}
        </StatusDisplay>
      </div>
    </Page>
  );
}

function useRecipesToFoodClassNamesById(
  recipes: Array<Recipe>
): Map<number, string> {
  const [organization] = useOrganization();

  return new Map(
    recipes.flatMap((recipe) =>
      mapNotNull(recipe.ingredients, (ingredient) =>
        ingredient.foodClass == null
          ? null
          : [
              ingredient.foodClass.id,
              getNameForLocale(
                ingredient.foodClass,
                organization.localeForFoodClasses
              ),
            ]
      )
    )
  );
}

function recipesToIngredientsAwaitingLinking(recipes: Array<Recipe>) {
  return recipes
    .flatMap((recipe) => recipe.ingredients)
    .filter(ingredientIsUnlinked);
}

function ingredientIsUnlinked(ingredient: RecipeIngredient) {
  return (
    ingredient.foodClass === null &&
    !ingredient.ignorable &&
    ingredient.useRecipeId === null
  );
}

function getNumUnlinkedIngredientNames(recipes: Array<Recipe>): number {
  const unlinkedIngredients = recipesToIngredientsAwaitingLinking(recipes);
  return [...new Set(unlinkedIngredients.map((ingredient) => ingredient.name))]
    .length;
}

export function IngredientOriginInformationModalTooltip() {
  return (
    <InformationModalTooltip
      body={
        <FormattedMessage
          id="components/ingredients/IngredientsTable:body"
          defaultMessage="
              <p>An ingredient’s origin is the location from which it was sourced for use in your products. Foodsteps uses this location to calculate the impact of the ingredient up until it is used in your products. It then uses the ingredient’s impact when calculating the impact of all of your products that use that ingredient.</p>
              <p>If an ingredient’s origin is set to “Average Sourcing”, Foodsteps uses international trade and production data to determine the breakdown of where that ingredient is commonly sourced from when used in products that are made in your consumption country.</p>
              <p>If you are on a Foodsteps plan you can set the ingredient origin, and doing this increases the accuracy of the impact assessment.</p>
            "
          values={{
            p: (chunks: React.ReactNode) => <p>{chunks}</p>,
          }}
        />
      }
      title={
        <FormattedMessage
          id="components/ingredients/IngredientsTable:title"
          defaultMessage="Ingredient Origin"
        />
      }
    />
  );
}

function queryFieldToNodes<NodeType>(field: {
  edges: Array<{ node: NodeType }>;
}): Array<NodeType> {
  return field.edges.map((edge) => edge.node);
}

const pageQuery = gql`
  query IngredientsPage_Query(
    $weightedLocationOptionFilter: WeightedLocationOptionFilter!
    $recipeFilter: RecipeFilter!
  ) {
    recipes(first: 10000, filter: $recipeFilter) {
      edges {
        node {
          ...IngredientsPage_Recipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }

    locations(first: 1000) {
      edges {
        node {
          ...IngredientsPage_Location
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    weightedLocationOptions(
      filter: $weightedLocationOptionFilter
      first: 10000
    ) {
      edges {
        node {
          ...IngredientsPage_WeightedLocationOption
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment IngredientsPage_Location on Location {
    id

    ...IngredientsTable_Location
    ...IngredientOriginModal_Location
  }

  fragment IngredientsPage_Recipe on Recipe {
    ingredients {
      ...IngredientsPage_RecipeIngredient
    }
  }

  fragment IngredientsPage_RecipeIngredient on RecipeIngredient {
    foodClass {
      id
      name
      synonyms {
        name
        locale
        isDefaultForLocale
      }
    }
    name
    ignorable
    useRecipeId
  }

  fragment IngredientsPage_WeightedLocationOption on WeightedLocationOption {
    foodClassId

    ...IngredientsTable_WeightedLocationOption
    ...IngredientOriginModal_WeightedLocationOption
  }

  ${IngredientsTable.fragments.location}
  ${IngredientOriginModal.fragments.location}
  ${IngredientsTable.fragments.weightedLocationOption}
  ${IngredientOriginModal.fragments.weightedLocationOption}
`;
