import SplitButton from "./SplitButton";

const stories = {
  name: "SplitButton",
  children: [
    {
      name: "Multiple items",
      render: () => (
        <SplitButton
          items={[
            { label: "Edit Recipe", onClick: () => alert("Edit recipe") },
            { label: "Copy Recipe", onClick: () => alert("Copy recipe") },
          ]}
          variant="secondary"
        />
      ),
    },
    {
      name: "Single item",
      render: () => (
        <SplitButton
          items={[
            { label: "Edit Recipe", onClick: () => alert("Edit recipe") },
          ]}
          variant="secondary"
        />
      ),
    },
    {
      name: "No items",
      render: () => <SplitButton items={[]} variant="secondary" />,
    },
  ],
};

export default stories;
