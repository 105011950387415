import { buildPath } from "../components/pages";

interface OAuth2Client<TExtraState> {
  authorizeRedirect: (extraState: TExtraState) => void;
  exchangeCodeForToken: () => Promise<{
    accessToken: string;
    state: TExtraState;
  }>;
}

interface LocalState<TExtraState> {
  codeVerifier: string;
  extraState: TExtraState;
}

export function createOAuth2Client<TExtraState>({
  authorizeUri,
  clientId,
  tokenUri,
}: {
  authorizeUri: string;
  clientId: string;
  tokenUri: string;
}): OAuth2Client<TExtraState> {
  async function authorizeRedirect(extraState: TExtraState) {
    const state = generateStateParam();
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const urlParams = new URLSearchParams({
      client_id: clientId,
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
      response_type: "code",
      scope: "read write",
      state,
    });

    storeState(state, {
      codeVerifier,
      extraState,
    });

    window.location.href = buildPath(authorizeUri, urlParams.toString());
  }

  async function exchangeCodeForToken() {
    const params = new URL(window.location.href).searchParams;
    const code = params.get("code");
    const stateKey = params.get("state");

    if (stateKey === null) {
      throw new Error("invalid state");
    }

    const state = loadState<TExtraState>(stateKey);

    if (state === null) {
      throw new Error("invalid state");
    }

    if (code === null) {
      throw new Error("invalid code");
    }

    const response = await fetch(tokenUri, {
      body: new URLSearchParams({
        client_id: clientId,
        grant_type: "authorization_code",
        code,
        code_verifier: state.codeVerifier,
      }).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    });

    if (!response.ok) {
      throw new Error("bad response");
    }

    const json = await response.json();
    const accessToken: string = json["access_token"];

    return { accessToken, state: state.extraState };
  }

  return { authorizeRedirect, exchangeCodeForToken };
}

function storeState(stateKey: string, state: LocalState<unknown>) {
  window.sessionStorage.setItem(
    stateKeyToSessionStorageKey(stateKey),
    JSON.stringify(state)
  );
}

function loadState<TExtraState>(
  stateKey: string
): LocalState<TExtraState> | null {
  const value = window.sessionStorage.getItem(
    stateKeyToSessionStorageKey(stateKey)
  );
  if (value === null) {
    return null;
  } else {
    window.sessionStorage.removeItem(stateKeyToSessionStorageKey(stateKey));
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }
}

function stateKeyToSessionStorageKey(stateKey: string): string {
  return `oauth2_state_${stateKey}`;
}

function generateStateParam(): string {
  return generateRandomString(32);
}

function generateCodeVerifier(): string {
  return generateRandomString(50);
}

async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  return base64UrlEncode(
    await crypto.subtle.digest("SHA-256", encoder.encode(codeVerifier))
  );
}

function base64UrlEncode(value: ArrayBuffer) {
  let str = "";
  const bytes = new Uint8Array(value);
  for (let i = 0; i < bytes.byteLength; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function generateRandomString(length: number): string {
  const bytes = window.crypto.getRandomValues(new Uint8Array(length));
  const alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

  return Array.from(bytes, (byte) => alphabet.charAt(byte & 63)).join("");
}
