import { ImpactRating } from "../../../__generated__/globalTypes";

// Source https://docs.google.com/spreadsheets/d/1Ue7D2Ph6gtA86wXU4QeqcNkwLQEhWKqA7LTA0Nsn8Qs/edit?gid=2141913433#gid=2141913433
const LOW = 1.4498;
const MEDIUM = 3.2218;
const HIGH = 9.6654;
const VERY_HIGH = 12.0874;

export default function getImpactRating(impactMagnitude: number) {
  if (impactMagnitude <= LOW) {
    return ImpactRating.VLOW;
  } else if (impactMagnitude > LOW && impactMagnitude <= MEDIUM) {
    return ImpactRating.LOW;
  } else if (impactMagnitude > MEDIUM && impactMagnitude <= HIGH) {
    return ImpactRating.MEDIUM;
  } else if (impactMagnitude > HIGH && impactMagnitude <= VERY_HIGH) {
    return ImpactRating.HIGH;
  } else {
    return ImpactRating.VHIGH;
  }
}
