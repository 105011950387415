import classNames from "classnames";
import { useEffect, useState } from "react";
import ReactAutoSuggest from "react-autosuggest";

import * as comparators from "../../util/comparators";

import "./AutoSuggest.css";

const normalizeStringForComparison = (str: string) =>
  removeAccents(str).toUpperCase();

const removeAccents = (str: string) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

function filterBySubStringMatch<T>(
  suggestions: Array<T>,
  getValue: (suggestion: T) => string,
  term: string
): Array<T> {
  const normalizedTerm = normalizeStringForComparison(term);
  return suggestions.filter((suggestion) =>
    normalizeStringForComparison(getValue(suggestion)).includes(normalizedTerm)
  );
}

interface AutoSuggestProps<T> {
  allSuggestions: Array<T>;
  className?: string;
  disabled?: boolean;
  forceSelection?: boolean;
  inputError?: boolean;
  onChange: (value: string) => void;
  suggestionsAppearAfter?: number;
  getSuggestionValue: (suggestion: T) => string;
  placeholder: string;
  value: string;
  render?: ReactAutoSuggest.RenderInputComponent;
}

const defaultTheme = {
  container: "react_autosuggest__container",
  containerOpen: "react-autosuggest__container--open",
  input: "form-control",
  suggestionsContainerOpen: "react-autosuggest__suggestions-container--open",
  suggestionsList: "react-autosuggest__suggestions-list",
  suggestion: "react-autosuggest__suggestion",
  suggestionFocused: "react-autosuggest__suggestion--focused",
  suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
};

export default function AutoSuggest<T>(props: AutoSuggestProps<T>) {
  const [suggestions, setSuggestions] = useState<Array<T>>([]);
  const [valueChecked, setValueChecked] = useState<boolean>(true);
  const {
    allSuggestions,
    className,
    placeholder,
    disabled,
    forceSelection,
    inputError,
    onChange,
    suggestionsAppearAfter = 0,
    getSuggestionValue,
    value,
    render,
  } = props;

  const allSuggestionValues = allSuggestions.map((suggestion) =>
    getSuggestionValue(suggestion)
  );

  useEffect(() => {
    // If the user is forced to select an option from the dropdown, reset the value if
    // the entered value is not an exact match for one of the dropdown suggestions.
    if (
      forceSelection &&
      !allSuggestionValues.includes(value) &&
      !valueChecked
    ) {
      onChange("");
    }
    setValueChecked(true);
  }, [allSuggestionValues, forceSelection, onChange, value, valueChecked]);

  function getSuggestions(value: string): Array<T> {
    return value.length < suggestionsAppearAfter
      ? []
      : filterBySubStringMatch(allSuggestions, getSuggestionValue, value).sort(
          comparators.map(
            (suggestion) => getSuggestionValue(suggestion),
            comparators.many(
              comparators.stringMatchingPrefixFirstSensitivityBase(value),
              comparators.stringLengthAscending,
              comparators.stringSensitivityBase
            )
          )
        );
  }

  return (
    <div className={className}>
      <ReactAutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
          // Tell React to check the value after re-render in case we enforce validation on the value
          setValueChecked(false);
        }}
        onSuggestionsFetchRequested={({ value }: { value: string }) => {
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestion }: { suggestion: T }) => {
          onChange(getSuggestionValue(suggestion));
        }}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(suggestion: T) => (
          <span>{getSuggestionValue(suggestion)}</span>
        )}
        theme={defaultTheme}
        inputProps={{
          placeholder,
          value,
          disabled,
          onChange: (_, { newValue }) => onChange(newValue),
          className: classNames("form-control", { "is-invalid": inputError }),
        }}
        multiSection={false}
        renderInputComponent={render}
      />
    </div>
  );
}
