import classNames from "classnames";
import React from "react";

import { PlusButton } from "./Button";
import { Delete } from "./Vectors";
import "./TableEditor.css";

interface TableEditorProps<TValue> {
  showAddButton: boolean;
  blank: () => TValue;
  onChange: (value: Array<TValue>) => void;
  renderRow: (options: {
    onChange: (row: TValue) => void;
    onDelete: () => void;
    rowIndex: number;
    value: TValue;
  }) => React.ReactNode;
  value: Array<TValue>;
}

export default function TableEditor<TValue extends { key: string }>(
  props: TableEditorProps<TValue>
) {
  const { showAddButton, blank, onChange, renderRow, value } = props;

  function handleAdd() {
    onChange([...value, blank()]);
  }

  function handleChange(updatedRow: TValue) {
    onChange(
      value.map((existingRow) =>
        existingRow.key === updatedRow.key ? updatedRow : existingRow
      )
    );
  }

  function handleDelete(deletedRow: TValue) {
    onChange(value.filter((existingRow) => existingRow.key !== deletedRow.key));
  }

  return (
    <div className="TableEditor_Container">
      {value.length > 0 && (
        <table className="w-100" style={{ tableLayout: "fixed" }}>
          <tbody>
            {value.map((row, rowIndex) => (
              <div className="TableEditor_RowContainer" key={row.key}>
                {renderRow({
                  onChange: handleChange,
                  onDelete: () => handleDelete(row),
                  rowIndex,
                  value: row,
                })}
              </div>
            ))}
          </tbody>
        </table>
      )}
      {showAddButton && <PlusButton onClick={handleAdd} />}
    </div>
  );
}

interface DeleteCellProps {
  className?: string;
  fill?: string;
  iconWidth?: string;
  onDelete: () => void;
  width?: string;
}

function DeleteCell(props: DeleteCellProps) {
  const {
    className = "",
    fill,
    iconWidth = "20px",
    onDelete,
    width = "2em",
  } = props;

  return (
    <td className={classNames(className, "text-center")} style={{ width }}>
      <Delete fill={fill} handleClick={() => onDelete()} width={iconWidth} />
    </td>
  );
}

TableEditor.DeleteCell = DeleteCell;
