import { AddressElement } from "@stripe/react-stripe-js";
import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  BillingInterval,
  CannotCreateSubscriptionErrorKind,
  CountryCode,
  Currency,
} from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import { useOrganization } from "../organizations/OrganizationProvider";
import useLoading from "../utils/useLoading";
import {
  BillingAddressPaymentStep_CreateSubscription as CreateSubscription,
  BillingAddressPaymentStep_CreateSubscriptionVariables as CreateSubscriptionVariables,
  BillingAddressPaymentStep_RecipeAllowanceProduct as RecipeAllowanceProduct,
  BillingAddressPaymentStep_TierProduct as TierProduct,
} from "./BillingAddressPaymentStep.graphql";
import PaymentStep, { PaymentStepButton } from "./PaymentStep";
import StripeElementsPaymentProvider from "./StripeElementsProvider";

export interface BillingAddress {
  name: string;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

interface BillingAddressStepProps {
  appliedPromotionId: string | null;
  billingInterval: BillingInterval;
  currency: Currency;
  PaymentStep: ({
    button,
    content,
  }: Pick<
    Parameters<typeof PaymentStep>[0],
    "button" | "content"
  >) => JSX.Element;
  recipeAllowanceProduct: RecipeAllowanceProduct;
  setClientSecret: React.Dispatch<React.SetStateAction<string | null>>;
  tierProduct: TierProduct;
  onNext: (() => void) | null;
  refreshInvoice: () => Promise<void>;
}

const getErrorMessage = (
  errorKind: CannotCreateSubscriptionErrorKind
): React.ReactNode => {
  if (
    errorKind ===
    CannotCreateSubscriptionErrorKind.CUSTOMER_TAX_LOCATION_INVALID
  ) {
    return (
      <FormattedMessage
        id="components/subscriptions/BillingAddressPaymentStep:invalidAddress"
        defaultMessage="Could not continue to payment details. Please enter a valid address."
      />
    );
  } else {
    return (
      <FormattedMessage
        id="components/subscriptions/BillingAddressPaymentStep:unknownError"
        defaultMessage="An unknown error occurred."
      />
    );
  }
};

class BillingAddressError extends UserVisibleError {
  constructor(errorKind: CannotCreateSubscriptionErrorKind) {
    super(errorKind, getErrorMessage(errorKind));
  }
}

export function BillingAddressPaymentStep(props: BillingAddressStepProps) {
  const {
    appliedPromotionId,
    billingInterval,
    currency,
    PaymentStep,
    recipeAllowanceProduct,
    tierProduct,
    setClientSecret,
    onNext,
    refreshInvoice,
  } = props;

  const [organization] = useOrganization();
  const [billingAddress, setBillingAddress] = useState<BillingAddress | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>(
    null
  );

  const [createSubscription] = useMutation<
    CreateSubscription,
    CreateSubscriptionVariables
  >(createSubscriptionMutation);

  const [createSubscriptionHandler, fetchingClientSecret] = useLoading(
    async () => {
      if (billingAddress === null) {
        throw new Error(
          "Cannot create a subscription without a billing address."
        );
      }

      const response = await createSubscription({
        variables: {
          input: {
            address: billingAddress.address,
            billingInterval,
            currency,
            organizationId: organization.id,
            promotionId: appliedPromotionId,
            recipeAllowance: recipeAllowanceProduct.recipeAllowance,
            tier: tierProduct.tier,
          },
        },
      });

      const { paymentRequest, errorKind } = response.createSubscription;
      if (errorKind !== null) {
        throw new BillingAddressError(errorKind);
      } else if (paymentRequest === null) {
        throw new Error("No payment request found.");
      } else {
        setClientSecret(paymentRequest.clientSecret);
      }
    }
  );

  const button = (onNext: (() => void) | null): PaymentStepButton => {
    if (onNext === null) {
      throw new Error(
        "Billing address payment step should have a non-null onNext value."
      );
    }
    return {
      text: (
        <FormattedMessage
          id="components/subscriptions/BillingAddressPaymentStep:continue"
          defaultMessage="Continue"
        />
      ),
      loading: fetchingClientSecret,
      onClick: async () => {
        try {
          await createSubscriptionHandler();
          await refreshInvoice();
          onNext();
        } catch (error) {
          if (error instanceof BillingAddressError) {
            setErrorMessage(error.messageNode);
          }
        }
      },
      disabled: billingAddress === null,
    };
  };

  const getStripeCountryCodeString = (countryCode: CountryCode): string => {
    if (countryCode === CountryCode.UNITED_KINGDOM) {
      return "GB";
    } else if (countryCode === CountryCode.UNITED_STATES_OF_AMERICA) {
      return "US";
    } else {
      assertNever(countryCode, "Invalid country code");
    }
  };

  const content = (
    <>
      <h2 className="mb-4">
        <FormattedMessage
          id="components/subscriptions/BillingAddressPaymentStep:enterYourBillingDetails"
          defaultMessage="Enter your billing address"
        />
      </h2>
      <StripeElementsPaymentProvider>
        <AddressElement
          options={{
            allowedCountries: [
              getStripeCountryCodeString(CountryCode.UNITED_KINGDOM),
              getStripeCountryCodeString(CountryCode.UNITED_STATES_OF_AMERICA),
            ],
            mode: "billing",
            display: {
              name: "organization",
            },
            defaultValues: {
              name: billingAddress?.name ?? organization.name,
              address: {
                line1: billingAddress?.address.line1,
                line2: billingAddress?.address.line2,
                city: billingAddress?.address.city,
                state: billingAddress?.address.state,
                country:
                  billingAddress?.address.country ??
                  getStripeCountryCodeString(organization.defaultTaxCountry),
                postal_code: billingAddress?.address.postalCode,
              },
            },
          }}
          onChange={async (event) => {
            if (event.complete) {
              setBillingAddress({
                name: event.value.name,
                address: {
                  line1: event.value.address.line1,
                  line2: event.value.address.line2,
                  city: event.value.address.city,
                  state: event.value.address.state,
                  postalCode: event.value.address.postal_code,
                  country: event.value.address.country,
                },
              });
            } else {
              setBillingAddress(null);
            }
          }}
        />
      </StripeElementsPaymentProvider>
      {errorMessage && (
        <div className="text-danger small semi-bold-font pt-3">
          {errorMessage}
        </div>
      )}
    </>
  );

  return <PaymentStep button={button(onNext)} content={content} />;
}

const createSubscriptionMutation = gql`
  mutation BillingAddressPaymentStep_CreateSubscription(
    $input: CreateSubscriptionInput!
  ) {
    createSubscription(input: $input) {
      errorKind
      paymentRequest {
        clientSecret
      }
    }
  }
`;

BillingAddressPaymentStep.fragments = {
  recipeAllowanceProduct: gql`
    fragment BillingAddressPaymentStep_RecipeAllowanceProduct on RecipeAllowanceProduct {
      recipeAllowance
    }
  `,

  tierProduct: gql`
    fragment BillingAddressPaymentStep_TierProduct on TierProduct {
      tier
    }
  `,
};
