import { FormattedMessage } from "react-intl";

import { useDataQualityScore } from "../../services/useOrganizationFeatures";
import LargeNumber from "../typography/LargeNumber";
import DQSText from "../utils/DQS/DQSText";
import SummaryCard from "../utils/SummaryCard";
import "./ImpactCategoryCard.scss";

interface ImpactCategoryCardProps {
  impactCategory: React.ReactNode;
  impact: {
    magnitude: number;
    dataQualityScore: number;
    unit: React.ReactNode;
  } | null;
  symbol: React.ReactNode;
}

export default function ImpactCategoryCard(props: ImpactCategoryCardProps) {
  const { impact, impactCategory, symbol } = props;

  const hasFeatureDataQualityScore = useDataQualityScore();

  // Design: https://www.figma.com/file/rHZ9MQPqlK3q4kRa0DdBkd/Impact-Categories?node-id=15054%3A3343&mode=dev

  return (
    <SummaryCard>
      {symbol}
      <div>
        <div className="medium-font">{impactCategory}</div>
        {impact !== null ? (
          <div className="ImpactCategoryCard__ImpactContainer">
            <LargeNumber>{impact.magnitude.toFixed(2)}</LargeNumber>
            <small className="text-nowrap">{impact.unit}</small>
          </div>
        ) : (
          <div className="small ImpactCategoryCard_Unavailable">
            <FormattedMessage
              id="components/recipes/ImpactCategoryCard:impactUnavailable"
              defaultMessage="Impact Unavailable"
            />
          </div>
        )}
      </div>
      {hasFeatureDataQualityScore && impact && (
        <DQSText dataQualityScore={impact.dataQualityScore} />
      )}
    </SummaryCard>
  );
}
