import { EffectType } from "../../../__generated__/globalTypes";
import assertNever from "../../../util/assertNever";
import * as ColourDescriptiveImpactAndScalePerKg from "../../utils/Vectors/carbon-labels/colour/descriptive-impact-and-scale/per-kg/index";
import * as ColourDescriptiveImpactAndScalePerServing from "../../utils/Vectors/carbon-labels/colour/descriptive-impact-and-scale/per-serving/index";
import {
  DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
  DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
} from "../../utils/Vectors/carbon-labels/constants";
import * as MonochromeDescriptiveImpactAndScalePerKg from "../../utils/Vectors/carbon-labels/monochrome/descriptive-impact-and-scale/per-kg/index";
import * as MonochromeDescriptiveImpactAndScalePerServing from "../../utils/Vectors/carbon-labels/monochrome/descriptive-impact-and-scale/per-serving/index";
import { BaseCarbonLabelProps } from "./CarbonLabel";

export interface DescriptiveImpactAndScaleLabelProps
  extends BaseCarbonLabelProps {
  effectType: EffectType;
  impactMagnitude: number;
}

export default function DescriptiveImpactAndScaleLabel(
  props: DescriptiveImpactAndScaleLabelProps
) {
  const {
    className,
    effectType,
    impactMagnitude,
    impactRating,
    monochrome,
    width,
  } = props;
  const DescriptiveImpactAndScalePerKg = monochrome
    ? MonochromeDescriptiveImpactAndScalePerKg
    : ColourDescriptiveImpactAndScalePerKg;
  const DescriptiveImpactAndScalePerServing = monochrome
    ? MonochromeDescriptiveImpactAndScalePerServing
    : ColourDescriptiveImpactAndScalePerServing;

  const labelWidth = width ?? defaultWidth(effectType);

  if (effectType === EffectType.GHG_PER_KG) {
    switch (impactRating) {
      case "VLOW":
        return (
          <DescriptiveImpactAndScalePerKg.DescriptiveImpactAndScalePerKgVLow
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "LOW":
        return (
          <DescriptiveImpactAndScalePerKg.DescriptiveImpactAndScalePerKgLow
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "MEDIUM":
        return (
          <DescriptiveImpactAndScalePerKg.DescriptiveImpactAndScalePerKgMedium
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "HIGH":
        return (
          <DescriptiveImpactAndScalePerKg.DescriptiveImpactAndScalePerKgHigh
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "VHIGH":
        return (
          <DescriptiveImpactAndScalePerKg.DescriptiveImpactAndScalePerKgVHigh
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
    }
  } else if (effectType === EffectType.GHG_PER_SERVING) {
    switch (impactRating) {
      case "VLOW":
        return (
          <DescriptiveImpactAndScalePerServing.DescriptiveImpactAndScalePerServingVLow
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "LOW":
        return (
          <DescriptiveImpactAndScalePerServing.DescriptiveImpactAndScalePerServingLow
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "MEDIUM":
        return (
          <DescriptiveImpactAndScalePerServing.DescriptiveImpactAndScalePerServingMedium
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "HIGH":
        return (
          <DescriptiveImpactAndScalePerServing.DescriptiveImpactAndScalePerServingHigh
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
      case "VHIGH":
        return (
          <DescriptiveImpactAndScalePerServing.DescriptiveImpactAndScalePerServingVHigh
            className={className}
            impactMagnitude={impactMagnitude}
            width={labelWidth}
          />
        );
    }
  } else {
    assertNever(effectType, "Invalid effect type.");
  }
}

function defaultWidth(effectType: EffectType) {
  switch (effectType) {
    case EffectType.GHG_PER_KG:
      return DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS;
    case EffectType.GHG_PER_SERVING:
      return DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS;
  }
}
