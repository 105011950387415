import SignUpDetailsForm from "./SignUpDetailsForm";

const stories = {
  name: "SignUpDetailsForm",
  render: () => (
    <SignUpDetailsForm onComplete={async () => alert("FINISHED")} />
  ),
};

export default stories;
