import { useCallbackOne } from "use-memo-one";

import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";
export default function useProcuredItems(assessmentId: string) {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportItems(assessmentId, organizationId);
  }, [dataStore, assessmentId, organizationId]);

  return usePromise(load);
}
