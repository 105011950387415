import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useIsStaff } from "../../services/useFeatureFlags";

export function useAdminPages() {
  const intl = useIntl();
  const isStaff = useIsStaff();

  const AdminIndex = {
    key: "adminIndex" as const,
    title: intl.formatMessage({
      id: "components/admin/adminPages:AdminIndex/title",
      defaultMessage: "Admin",
    }),
    url: "/admin",
  };

  const Organization = {
    key: "organization" as const,
    url: (organizationId: string) => `/admin/organizations/${organizationId}`,
    urlTemplate: "/admin/organizations/:organizationId",
    useParams: () => {
      return useParams<{ organizationId: string }>();
    },
    breadcrumb: (organization: { id: string; name: string }) => [
      ...Organizations.breadcrumb(),
      { title: organization.name, url: Organization.url(organization.id) },
    ],
  };

  const OrganizationAdd = {
    key: "organizationAdd" as const,
    title: intl.formatMessage({
      id: "components/admin/adminPages:OrganizationAdd/title",
      defaultMessage: "Add Organisation",
    }),
    url: "/admin/add-organization",
    breadcrumb: () => [
      ...Organizations.breadcrumb(),
      { title: OrganizationAdd.title, url: OrganizationAdd.url },
    ],
  };

  const OrganizationAddSubOrganization = {
    key: "organizationAddSubOrganization" as const,
    url: (organizationId: string) =>
      `/admin/organizations/${organizationId}/add-suborganization`,
    urlTemplate: "/admin/organizations/:organizationId/add-suborganization",
    useParams: () => {
      return useParams<{ organizationId: string }>();
    },
    breadcrumb: (organization: { id: string; name: string }) => [
      ...Organization.breadcrumb(organization),
      {
        title: intl.formatMessage({
          id: "components/admin/adminPages:OrganizationAddSubOrganization/title",
          defaultMessage: "Add Suborganisation",
        }),
        url: OrganizationAddSubOrganization.url(organization.id),
      },
    ],
  };

  const OrganizationEdit = {
    key: "organizationEdit" as const,
    title: intl.formatMessage({
      id: "components/admin/adminPages:OrganizationEdit/title",
      defaultMessage: "Edit Organisation",
    }),
    url: (organizationId: string) =>
      `/admin/organizations/${organizationId}/edit`,
    urlTemplate: "/admin/organizations/:organizationId/edit",
    useParams: () => {
      return useParams<{ organizationId: string }>();
    },
    breadcrumb: (organization: { id: string; name: string }) => [
      ...Organization.breadcrumb(organization),
      {
        title: OrganizationEdit.title,
        url: OrganizationEdit.url(organization.id),
      },
    ],
  };

  const Organizations = {
    key: "organizations" as const,
    title: intl.formatMessage({
      id: "components/admin/adminPages:Organizations/title",
      defaultMessage: "Organisations",
    }),
    url: "/admin/organizations",
    breadcrumb: () => [{ title: Organizations.title, url: Organizations.url }],
  };

  const User = {
    key: "user" as const,
    url: (externalId: string) => `/admin/users/${externalId}`,
    urlTemplate: "/admin/users/:externalId",
    useParams: () => {
      return useParams<{ externalId: string }>();
    },
    breadcrumb: (user: { externalId: string; username: string }) => [
      ...Users.breadcrumb(),
      { title: user.username, url: User.url(user.externalId) },
    ],
  };

  const UserEdit = {
    key: "userEdit" as const,
    title: intl.formatMessage({
      id: "components/admin/adminPages:UserEdit/title",
      defaultMessage: "Edit User",
    }),
    url: (externalId: string) => `/admin/users/${externalId}/edit`,
    urlTemplate: "/admin/users/:externalId/edit",
    useParams: () => {
      return useParams<{ externalId: string }>();
    },
    breadcrumb: (user: { externalId: string; username: string }) => [
      ...User.breadcrumb(user),
      {
        title: UserEdit.title,
        url: UserEdit.url(user.externalId),
      },
    ],
  };

  const Users = {
    key: "users" as const,
    title: intl.formatMessage({
      id: "components/admin/adminPages:Users/title",
      defaultMessage: "Users",
    }),
    url: "/admin/users",
    breadcrumb: () => [{ title: Users.title, url: Users.url }],
  };

  const all = isStaff
    ? [
        AdminIndex,
        Organization,
        OrganizationAdd,
        OrganizationAddSubOrganization,
        OrganizationEdit,
        Organizations,
        User,
        UserEdit,
        Users,
      ]
    : [AdminIndex, Users];

  const navItems = [Organizations, Users].filter((page) => all.includes(page));

  return {
    AdminIndex,
    Organization,
    OrganizationAdd,
    OrganizationAddSubOrganization,
    OrganizationEdit,
    Organizations,
    User,
    UserEdit,
    Users,
    all,
    navItems,
  };
}
