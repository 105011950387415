import { useIntl } from "react-intl";

import {
  greyscaleVeryLowA,
  greyScaleHighD,
  greyScaleVeryHighE,
  greyscaleLowB,
  greyscaleMediumC,
  highD,
  lowB,
  mediumC,
  noRating,
  veryHighE,
  veryLowA,
} from "../components/graphs/colors";
import * as comparators from "../util/comparators";

export type ImpactRating = "VLOW" | "LOW" | "MEDIUM" | "HIGH" | "VHIGH";

export const allImpactRatings: ReadonlyArray<ImpactRating> = [
  "VLOW",
  "LOW",
  "MEDIUM",
  "HIGH",
  "VHIGH",
];

export const comparator: comparators.Comparator<ImpactRating> = comparators.map(
  (rating) => allImpactRatings.indexOf(rating),
  comparators.number
);

export function useImpactRatingToLongName(impactRating: ImpactRating): string {
  const intl = useIntl();

  switch (impactRating) {
    case "VLOW":
      return intl.formatMessage({
        defaultMessage: "Very Low",
        id: "domain/impactRatings:useImpactRatingToLongName/veryLow",
      });
    case "LOW":
      return intl.formatMessage({
        defaultMessage: "Low",
        id: "domain/impactRatings:useImpactRatingToLongName/low",
      });
    case "MEDIUM":
      return intl.formatMessage({
        defaultMessage: "Medium",
        id: "domain/impactRatings:useImpactRatingToLongName/medium",
      });
    case "HIGH":
      return intl.formatMessage({
        defaultMessage: "High",
        id: "domain/impactRatings:useImpactRatingToLongName/high",
      });
    case "VHIGH":
      return intl.formatMessage({
        defaultMessage: "Very High",
        id: "domain/impactRatings:useImpactRatingToLongName/veryHigh",
      });
  }
}

export function useImpactRatingToLetter(impactRating: ImpactRating): string {
  const intl = useIntl();

  switch (impactRating) {
    case "VLOW":
      return intl.formatMessage({
        defaultMessage: "A",
        id: "domain/impactRatings:useImpactRatingToLetter/a",
      });
    case "LOW":
      return intl.formatMessage({
        defaultMessage: "B",
        id: "domain/impactRatings:useImpactRatingToLetter/b",
      });
    case "MEDIUM":
      return intl.formatMessage({
        defaultMessage: "C",
        id: "domain/impactRatings:useImpactRatingToLetter/c",
      });
    case "HIGH":
      return intl.formatMessage({
        defaultMessage: "D",
        id: "domain/impactRatings:useImpactRatingToLetter/d",
      });
    case "VHIGH":
      return intl.formatMessage({
        defaultMessage: "E",
        id: "domain/impactRatings:useImpactRatingToLetter/e",
      });
  }
}

export function impactRatingToColorRgb(
  impactRating: ImpactRating | null
): string {
  switch (impactRating) {
    case "VLOW":
      return veryLowA;
    case "LOW":
      return lowB;
    case "MEDIUM":
      return mediumC;
    case "HIGH":
      return highD;
    case "VHIGH":
      return veryHighE;
    case null:
      return noRating;
  }
}

function impactRatingToColorGreyscale(
  impactRating: ImpactRating | null
): string {
  switch (impactRating) {
    case "VLOW":
      return greyscaleVeryLowA;
    case "LOW":
      return greyscaleLowB;
    case "MEDIUM":
      return greyscaleMediumC;
    case "HIGH":
      return greyScaleHighD;
    case "VHIGH":
      return greyScaleVeryHighE;
    case null:
      return noRating;
  }
}

export function useImpactRatingToColor(greyscale: boolean) {
  return greyscale ? impactRatingToColorGreyscale : impactRatingToColorRgb;
}
