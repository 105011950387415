import classNames from "classnames";

import useId from "./useId";
import "./Checkbox.css";

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  containerClassName?: string;
  defaultChecked?: boolean;
  propagateOnClick?: boolean;
  disabled?: boolean;
  hover?: boolean;
  id?: string;
  label?: React.ReactNode;
  onChange?: (checked: boolean) => void;
}

export default function Checkbox(props: CheckboxProps) {
  const fallbackId = useId();

  const {
    className = "",
    containerClassName,
    disabled,
    hover = false,
    id = fallbackId,
    label = null,
    onChange,
    propagateOnClick = true,
    ...inputProps
  } = props;

  const handleChange =
    onChange === undefined
      ? undefined
      : (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.checked);
        };

  return (
    <div
      className={classNames(
        "custom-control custom-checkbox",
        { "custom-checkbox-hover": hover },
        containerClassName
      )}
    >
      <input
        className={classNames("custom-control-input", className)}
        disabled={disabled}
        type="checkbox"
        id={id}
        onChange={handleChange}
        onClick={
          propagateOnClick
            ? undefined
            : (e) => {
                e.stopPropagation();
              }
        }
        readOnly={handleChange === undefined}
        {...inputProps}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
        onClick={
          propagateOnClick
            ? undefined
            : (e) => {
                e.stopPropagation();
              }
        }
      >
        {label}
      </label>
    </div>
  );
}
