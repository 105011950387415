import { useEffect, useState } from "react";

export default function useStateWithDebouncedUpdate<T>({
  initialValue,
  update,
}: {
  initialValue: T;
  update: (newValue: T) => void;
}): [T, (newValue: T) => void] {
  const [state, setState] = useState<T>(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      update(state);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [update, state]);

  return [state, setState];
}
