import { gql } from "graphql-tag";
import { FormattedMessage } from "react-intl";

import HelpModalTooltip from "../../utils/HelpModalTooltip";
import { Panel } from "../../utils/Panel";
import "./Packaging.css";
import { Packaging_PackagingComponent as PackagingComponent } from "./Packaging.graphql";
import * as PackagingComponentsEditor from "./PackagingComponentsEditor";
import { ProductEditorState } from "./ProductEditor";

export type PackagingState = Pick<ProductEditorState, "packagingComponents">;

interface PackagingProps {
  onChange: (state: PackagingState) => void;
  packagingComponents: Array<PackagingComponent>;
  state: PackagingState;
}

export default function Packaging(props: PackagingProps) {
  const { packagingComponents, state, onChange } = props;
  return (
    <Panel className="Packaging_Panel">
      <div>
        <div className="Packaging_Panel_Title">
          <h3 className="mb-0">
            <FormattedMessage
              id="components/recipes/ProductEditor/Packaging:title"
              defaultMessage="Packaging"
            />
          </h3>
          <PackagingHelpModalTooltip />
        </div>
        <div className="Packaging_InstructionsContainer text-muted">
          <FormattedMessage
            id="components/recipes/ProductEditor/Packaging:instructions"
            defaultMessage="If your product involves packaging, add all packaging components used and specify how many multiples of product (as defined in your Product Overview)  each component applies to."
          />
        </div>
      </div>
      <PackagingComponentsEditor.PackagingComponentsEditor
        packagingComponents={packagingComponents}
        value={state.packagingComponents}
        onChange={(packagingComponents) =>
          onChange({ ...state, packagingComponents })
        }
      />
    </Panel>
  );
}

function PackagingHelpModalTooltip() {
  return (
    <HelpModalTooltip
      title={
        <FormattedMessage
          id="components/recipes/ProductEditor/Packaging:PackagingHelpModalTooltip/helpTitle"
          defaultMessage="Packaging"
        />
      }
    >
      <FormattedMessage
        id="components/recipes/ProductEditor/Packaging:PackagingHelpModalTooltip/helpText"
        defaultMessage="
        <p>A packaging component is an item of packaging that consists of one or more materials that can’t be easily separated into multiple components. Create packaging components (available via the Packaging menu) and then add them to your product.</p>
        <p>Your impact assessment may include primary packaging (packaging immediately surrounding the product), as well as additional layers of packaging used for retail display, storage, transport, or any other purpose. For example, if 800g bags of cauli bites are displayed on retail freezer shelves in boxes of 10 bags and transported in crates of 10 boxes, you would add:</p>
        <ul>
            <li>A bag component associated with 1 product</li>
            <li>A box component associated with 10 products</li>
            <li>A crate component associated with 100 products</li>
        </ul>
        "
        values={{
          p: (chunks: React.ReactNode) => <p>{chunks}</p>,
          ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
          li: (chunks: React.ReactNode) => <li>{chunks}</li>,
        }}
      />
    </HelpModalTooltip>
  );
}

Packaging.fragments = {
  packagingComponent: gql`
    fragment Packaging_PackagingComponent on PackagingComponentV2 {
      ...PackagingComponentsEditor_PackagingComponent
    }

    ${PackagingComponentsEditor.fragments.packagingComponent}
  `,
};
