import { useIntl } from "react-intl";

import { FoodClassOrder } from "../../__generated__/globalTypes";
import { IngredientsListPageQueryParams } from "../pages";
import SearchBox from "../utils/SearchBox";
import { TableSort } from "../utils/Table";
import useStateWithDebouncedUpdate from "../utils/useStateWithDebouncedUpdate";

interface IngredientsQueryControlsProps {
  disabled: boolean;
  onChange: (value: Partial<IngredientsListPageQueryParams>) => void;
  value: IngredientsListPageQueryParams;
}

export enum ColumnKey {
  INGREDIENT = "ingredient",
  ORIGIN = "origin",
  RECIPE_COUNT = "recipeCount",
  DQS = "dqs",
  IMPACT_PER_KG = "impactPerKg",
  IMPACT_RATING = "impactRating",
}

type IngredientTableSort = TableSort & {
  columnKey: ColumnKey;
};

export function IngredientsQueryControls(props: IngredientsQueryControlsProps) {
  const { disabled, onChange, value } = props;

  const [searchTermText, setSearchTermText] = useStateWithDebouncedUpdate({
    initialValue: value.searchTerm,
    update: (newValue: string) => onChange({ searchTerm: newValue }),
  });

  return (
    <IngredientsSearchBox
      disabled={disabled}
      onChange={(searchTerm) => setSearchTermText(searchTerm)}
      value={searchTermText}
    />
  );
}

interface IngredientsSearchBoxProps {
  disabled: boolean;
  onChange: (value: string) => void;
  value: string;
}

function IngredientsSearchBox(props: IngredientsSearchBoxProps) {
  const { disabled, onChange, value } = props;

  const intl = useIntl();

  const searchPlaceholder = intl.formatMessage({
    id: "components/ingredients/IngredientsPage:IngredientsSearchBox/searchPlaceholder",
    defaultMessage: "Search ingredients",
  });

  return (
    <SearchBox
      disabled={disabled}
      onChange={onChange}
      placeholder={searchPlaceholder}
      value={value}
    />
  );
}

export type OrderBy =
  | FoodClassOrder.NAME_ASC
  | FoodClassOrder.NAME_DESC
  | FoodClassOrder.ORIGIN_ASC
  | FoodClassOrder.ORIGIN_DESC
  | FoodClassOrder.RECIPE_COUNT_ASC
  | FoodClassOrder.RECIPE_COUNT_DESC
  | FoodClassOrder.DQS_ASC
  | FoodClassOrder.DQS_DESC
  | FoodClassOrder.IMPACT_PER_KG_ASC
  | FoodClassOrder.IMPACT_PER_KG_DESC
  | FoodClassOrder.IMPACT_RATING_ASC
  | FoodClassOrder.IMPACT_RATING_DESC;

const orderByOptions: Array<OrderBy> = [
  FoodClassOrder.NAME_ASC,
  FoodClassOrder.NAME_DESC,
  FoodClassOrder.ORIGIN_ASC,
  FoodClassOrder.ORIGIN_DESC,
  FoodClassOrder.RECIPE_COUNT_ASC,
  FoodClassOrder.RECIPE_COUNT_DESC,
  FoodClassOrder.DQS_ASC,
  FoodClassOrder.DQS_DESC,
  FoodClassOrder.IMPACT_PER_KG_ASC,
  FoodClassOrder.IMPACT_PER_KG_DESC,
  FoodClassOrder.IMPACT_RATING_ASC,
  FoodClassOrder.IMPACT_RATING_DESC,
];

export function parseIngredientsOrder(rawOrderBy: string): OrderBy | null {
  return (orderByOptions as Array<string>).includes(rawOrderBy)
    ? (rawOrderBy as OrderBy)
    : null;
}

export function tableSortToIngredientsOrderBy(
  sort: IngredientTableSort | null
): FoodClassOrder {
  if (sort === null) {
    return FoodClassOrder.NAME_ASC;
  }
  switch (sort.columnKey) {
    case ColumnKey.INGREDIENT:
      return sort.descending
        ? FoodClassOrder.NAME_DESC
        : FoodClassOrder.NAME_ASC;
    case ColumnKey.ORIGIN:
      return sort.descending
        ? FoodClassOrder.ORIGIN_DESC
        : FoodClassOrder.ORIGIN_ASC;
    case ColumnKey.RECIPE_COUNT:
      return sort.descending
        ? FoodClassOrder.RECIPE_COUNT_DESC
        : FoodClassOrder.RECIPE_COUNT_ASC;
    case ColumnKey.DQS:
      return sort.descending ? FoodClassOrder.DQS_DESC : FoodClassOrder.DQS_ASC;
    case ColumnKey.IMPACT_PER_KG:
      return sort.descending
        ? FoodClassOrder.IMPACT_PER_KG_DESC
        : FoodClassOrder.IMPACT_PER_KG_ASC;
    case ColumnKey.IMPACT_RATING:
      return sort.descending
        ? FoodClassOrder.IMPACT_RATING_DESC
        : FoodClassOrder.IMPACT_RATING_ASC;
  }
}

export function ingredientsOrderByToTableSort(
  orderBy: FoodClassOrder
): TableSort | null {
  switch (orderBy) {
    case FoodClassOrder.DQS_ASC:
      return { columnKey: "dqs", descending: false };
    case FoodClassOrder.DQS_DESC:
      return { columnKey: "dqs", descending: true };
    case FoodClassOrder.IMPACT_PER_KG_ASC:
      return { columnKey: "impactPerKg", descending: false };
    case FoodClassOrder.IMPACT_PER_KG_DESC:
      return { columnKey: "impactPerKg", descending: true };
    case FoodClassOrder.IMPACT_RATING_ASC:
      return { columnKey: "impactRating", descending: false };
    case FoodClassOrder.IMPACT_RATING_DESC:
      return { columnKey: "impactRating", descending: true };
    case FoodClassOrder.NAME_ASC:
      return { columnKey: "name", descending: false };
    case FoodClassOrder.NAME_DESC:
      return { columnKey: "name", descending: true };
    case FoodClassOrder.ORIGIN_ASC:
      return { columnKey: "origin", descending: false };
    case FoodClassOrder.ORIGIN_DESC:
      return { columnKey: "origin", descending: true };
    case FoodClassOrder.RECIPE_COUNT_ASC:
      return { columnKey: "recipeCount", descending: false };
    case FoodClassOrder.RECIPE_COUNT_DESC:
      return { columnKey: "recipeCount", descending: true };
  }
}
