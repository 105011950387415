import { useRef } from "react";

import {
  useFoodManufacturerOrganization,
  useTags,
} from "../../services/useOrganizationFeatures";
import Page from "../Page";
import { buildPath, usePages } from "../pages";
import NewRecipeEditor from "./NewRecipeEditor";
import ProductEditor from "./ProductEditor/ProductEditor";
import * as RecipesListPageLocationState from "./RecipesListPageLocationState";

const newRecipePageTracking = { pageName: "new-recipe" };

export default function NewRecipePage() {
  const pageContentContainerRef = useRef<any>(null);
  const pages = usePages();
  const hasFeatureTags = useTags();
  const recipeQuery = RecipesListPageLocationState.useQueryParams();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();

  const parentUrl = buildPath(
    pages.Recipes(hasFeatureTags).url,
    pages.RecipesListPage.stringifyQueryParams(recipeQuery)
  );

  return (
    <Page
      contentContainerRef={pageContentContainerRef}
      tracking={newRecipePageTracking}
      showNav={!foodManufacturerOrganization}
    >
      {foodManufacturerOrganization ? (
        <ProductEditor
          contentContainerRef={pageContentContainerRef}
          parentUrl={parentUrl}
        />
      ) : (
        <NewRecipeEditor parentUrl={parentUrl} />
      )}
    </Page>
  );
}
