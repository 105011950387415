import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import Page from "../Page";
import { usePages } from "../pages";
import { LinkButton } from "../utils/Button";
import ChangePassword from "./ChangePassword";

const tracking = { pageName: "account-details" };

export default function AccountDetailsPage() {
  const pages = usePages();

  return (
    <Page tracking={tracking}>
      <Page.Title breadcrumb={pages.AccountDetails.breadcrumb()} />
      <AccountDetails />
    </Page>
  );
}

function AccountDetails() {
  const [showModal, setShowModal] = useState(false);
  const [userInfo, refreshUserInfo] = useUserInfo();

  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  return (
    <div>
      <h4 className="mt-5 pt-5 pl-5">
        <FormattedMessage
          id="components/account/AccountDetails:nameLabel"
          defaultMessage="Name"
        />
      </h4>
      <p className="pl-5">
        <FormattedMessage
          id="components/account/AccountDetails:name"
          defaultMessage="{firstName} {lastName}"
          values={{
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          }}
        />
      </p>
      <h4 className="mt-5 pt-5 pl-5">
        <FormattedMessage
          id="components/account/AccountDetails:emailAddressLabel"
          defaultMessage="Email"
        />
      </h4>
      <p className=" pl-5 pb-5">
        {userInfo.email != null ? userInfo.email : "--"}
      </p>
      <LinkButton onClick={() => setShowModal(true)} className="ml-5 p-0">
        <FormattedMessage
          id="components/account/AccountDetails:changePasswordLabel"
          defaultMessage="Change Password"
        />
      </LinkButton>
      <ChangePassword
        show={showModal}
        onHide={() => setShowModal(false)}
        userid={userInfo.id}
        username={userInfo.username}
      />
    </div>
  );
}
