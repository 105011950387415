import { ImpactRating } from "../../../../__generated__/globalTypes";
import useUserInfo from "../../../../data-store/useUserInfo";
import { FunctionalUnit } from "../../../../domain/functionalUnits";
import DescriptiveImpactAndScale from "./DescriptiveImpactAndScale";
import DescriptiveRating from "./DescriptiveRating";
import DescriptiveRatingScale from "./DescriptiveRatingScale";
import LetterRating from "./LetterRating";
import RatingScale from "./RatingScale";
import TitledRatingScale from "./TitledRatingScale";

const stories = {
  name: "Land use labels",
  children: [
    {
      name: "DescriptiveRating",
      children: [
        { name: "Colour", render: () => <DescriptiveRatingStory /> },
        {
          name: "Greyscale",
          render: () => <DescriptiveRatingStory greyscale />,
        },
      ],
    },
    {
      name: "LetterRating",
      children: [
        { name: "Colour", render: () => <LetterRatingStory /> },
        { name: "Greyscale", render: () => <LetterRatingStory greyscale /> },
      ],
    },
    {
      name: "RatingScale",
      children: [
        { name: "Colour", render: () => <RatingScaleStory /> },
        { name: "Greyscale", render: () => <RatingScaleStory greyscale /> },
      ],
    },
    {
      name: "TitledRatingScale",
      children: [
        { name: "Colour", render: () => <TitledRatingScaleStory /> },
        {
          name: "Greyscale",
          render: () => <TitledRatingScaleStory greyscale />,
        },
      ],
    },
    {
      name: "DescriptiveRatingScale",
      children: [
        { name: "Colour", render: () => <DescriptiveRatingScaleStory /> },
        {
          name: "Greyscale",
          render: () => <DescriptiveRatingScaleStory greyscale />,
        },
      ],
    },
    {
      name: "DescriptiveImpactAndScale",
      children: [
        {
          name: "Colour",
          children: [
            {
              name: "Per Kg",
              render: () => (
                <DescriptiveImpactAndScaleStory
                  functionalUnit={FunctionalUnit.KG}
                  greyscale={false}
                />
              ),
            },
            {
              name: "Per Serving",
              render: () => (
                <DescriptiveImpactAndScaleStory
                  functionalUnit={FunctionalUnit.PORTION}
                  greyscale={false}
                />
              ),
            },
          ],
        },
        {
          name: "Greyscale",
          children: [
            {
              name: "Per Kg",
              render: () => (
                <DescriptiveImpactAndScaleStory
                  functionalUnit={FunctionalUnit.KG}
                  greyscale
                />
              ),
            },
            {
              name: "Per Serving",
              render: () => (
                <DescriptiveImpactAndScaleStory
                  functionalUnit={FunctionalUnit.PORTION}
                  greyscale
                />
              ),
            },
          ],
        },
      ],
    },
  ],
};

interface DescriptiveRatingStoryProps {
  greyscale?: boolean;
}

function DescriptiveRatingStory(props: DescriptiveRatingStoryProps) {
  const { greyscale } = props;

  const [{ locale }] = useUserInfo();

  return (
    <div className="d-flex flex-column" style={{ gap: "8px" }}>
      <DescriptiveRating
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.VLOW}
      />
      <DescriptiveRating
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.LOW}
      />
      <DescriptiveRating
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.MEDIUM}
      />
      <DescriptiveRating
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.HIGH}
      />
      <DescriptiveRating
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.VHIGH}
      />
    </div>
  );
}

interface LetterRatingStoryProps {
  greyscale?: boolean;
}

function LetterRatingStory(props: LetterRatingStoryProps) {
  const { greyscale } = props;

  return (
    <div className="d-flex flex-column" style={{ gap: "8px" }}>
      <LetterRating
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.VLOW}
      />
      <LetterRating
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.LOW}
      />
      <LetterRating
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.MEDIUM}
      />
      <LetterRating
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.HIGH}
      />
      <LetterRating
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.VHIGH}
      />
    </div>
  );
}

interface RatingScaleStoryProps {
  greyscale?: boolean;
}

function RatingScaleStory(props: RatingScaleStoryProps) {
  const { greyscale } = props;
  return (
    <div className="d-flex flex-column" style={{ gap: "8px" }}>
      <RatingScale
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.VLOW}
      />
      <RatingScale
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.LOW}
      />
      <RatingScale
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.MEDIUM}
      />
      <RatingScale
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.HIGH}
      />
      <RatingScale
        greyscale={greyscale}
        width={300}
        impactRating={ImpactRating.VHIGH}
      />
    </div>
  );
}

interface TitledRatingScaleStoryProps {
  greyscale?: boolean;
}

function TitledRatingScaleStory(props: TitledRatingScaleStoryProps) {
  const { greyscale } = props;

  const [{ locale }] = useUserInfo();

  return (
    <div className="d-flex flex-column" style={{ gap: "8px" }}>
      <TitledRatingScale
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.VLOW}
      />
      <TitledRatingScale
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.LOW}
      />
      <TitledRatingScale
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.MEDIUM}
      />
      <TitledRatingScale
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.HIGH}
      />
      <TitledRatingScale
        greyscale={greyscale}
        locale={locale}
        width={300}
        impactRating={ImpactRating.VHIGH}
      />
    </div>
  );
}

interface DescriptiveRatingScaleStoryProps {
  greyscale?: boolean;
}

function DescriptiveRatingScaleStory(props: DescriptiveRatingScaleStoryProps) {
  const { greyscale } = props;

  const [{ locale }] = useUserInfo();

  return (
    <div className="d-flex flex-row" style={{ gap: "8px" }}>
      <DescriptiveRatingScale
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactRating={ImpactRating.VLOW}
      />
      <DescriptiveRatingScale
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactRating={ImpactRating.LOW}
      />
      <DescriptiveRatingScale
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactRating={ImpactRating.MEDIUM}
      />
      <DescriptiveRatingScale
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactRating={ImpactRating.HIGH}
      />
      <DescriptiveRatingScale
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactRating={ImpactRating.VHIGH}
      />
    </div>
  );
}

interface DescriptiveImpactAndScaleStoryProps {
  functionalUnit: FunctionalUnit;
  greyscale: boolean;
}

function DescriptiveImpactAndScaleStory(
  props: DescriptiveImpactAndScaleStoryProps
) {
  const { functionalUnit, greyscale } = props;

  const [{ locale }] = useUserInfo();

  return (
    <div className="d-flex align-items-start flex-row" style={{ gap: "8px" }}>
      <DescriptiveImpactAndScale
        functionalUnit={functionalUnit}
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactMagnitude={1.23}
        impactRating={ImpactRating.VLOW}
      />
      <DescriptiveImpactAndScale
        functionalUnit={functionalUnit}
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactMagnitude={10.23}
        impactRating={ImpactRating.LOW}
      />
      <DescriptiveImpactAndScale
        functionalUnit={functionalUnit}
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactMagnitude={100.23}
        impactRating={ImpactRating.MEDIUM}
      />
      <DescriptiveImpactAndScale
        functionalUnit={functionalUnit}
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactMagnitude={1.23}
        impactRating={ImpactRating.HIGH}
      />
      <DescriptiveImpactAndScale
        functionalUnit={functionalUnit}
        greyscale={greyscale}
        locale={locale}
        width={200}
        impactMagnitude={0.1}
        impactRating={ImpactRating.VHIGH}
      />
    </div>
  );
}

export default stories;
