import gql from "graphql-tag";
import { FormattedMessage, useIntl } from "react-intl";

import { UpdateRecipeInput } from "../../../__generated__/globalTypes";
import { PickRequiredNonNullable } from "../../../util/types";
import * as PackagingComponentSelect from "../../packaging/PackagingComponentSelect";
import Checkbox from "../../utils/Checkbox";
import ReadResult from "../../utils/ReadResult";
import useRecipeLabel from "../useRecipeLabel";
import RecipeEditorLabel from "./RecipeEditorLabel";
import {
  RecipePackagingV2Editor_PackagingComponentV2 as PackagingComponentV2,
  RecipePackagingV2Editor_Recipe as Recipe,
  RecipePackagingV2Editor_RecipePackagingComponentV2 as RecipePackagingComponentV2,
} from "./RecipePackagingV2Editor.graphql";

export const label = (
  <FormattedMessage
    id="components/recipes/RecipeEditor/RecipePackagingV2Editor:label"
    defaultMessage="Packaging"
  />
);

export type Value = {
  selectedPackagingComponents: Array<PackagingComponentV2>;
  hasNoPackaging: boolean;
  isInvalid: boolean;
};

type RecipePackagingEditorReadResult = ReadResult<
  Value,
  PickRequiredNonNullable<UpdateRecipeInput, "packagingComponentsV2">
>;

export function read(value: Value): RecipePackagingEditorReadResult {
  const hasError = isInvalid(value);
  return {
    value: { ...value, isInvalid: hasError },
    input: getInput(value),
    hasError,
  };
}

export function isInvalid(
  value: {
    selectedPackagingComponents: Array<Pick<PackagingComponentV2, "id">>;
  } & Pick<Value, "hasNoPackaging">
): boolean {
  return (
    !value.hasNoPackaging && value.selectedPackagingComponents.length === 0
  );
}

function getInput(value: Value) {
  if (value.hasNoPackaging) {
    return { packagingComponentsV2: [] };
  } else {
    return {
      packagingComponentsV2: value.selectedPackagingComponents.map(
        (component) => {
          return {
            packagingComponentId: component.id,
            amountOfProductsPackaged: null,
          };
        }
      ),
    };
  }
}

export function initialValue(
  isFoodManufacturerOrganization: boolean,
  recipe: Recipe | undefined,
  packagingComponents: Array<PackagingComponentV2>
): Value {
  if (isFoodManufacturerOrganization) {
    return {
      selectedPackagingComponents: [],
      hasNoPackaging: true,
      isInvalid: false,
    };
  }

  if (recipe === undefined) {
    return {
      selectedPackagingComponents: [],
      hasNoPackaging: false,
      isInvalid: false,
    };
  } else {
    return {
      selectedPackagingComponents: recipe.packagingComponentsV2.map(
        (component) => findPackagingComponentV2(component, packagingComponents)
      ),
      isInvalid: false,
      hasNoPackaging: recipe.packagingComponentsV2.length === 0,
    };
  }
}

function findPackagingComponentV2(
  recipePackagingComponent: RecipePackagingComponentV2,
  packagingComponents: Array<PackagingComponentV2>
) {
  const result = packagingComponents.find(
    (component) =>
      component.id === recipePackagingComponent.packagingComponent.id
  );
  if (result === undefined) {
    throw new Error(
      `Could not find packaging component with ID ${recipePackagingComponent.packagingComponent.id}.`
    );
  }
  return result;
}

interface RecipePackagingV2EditorProps {
  packagingComponents: Array<PackagingComponentV2>;
  value: Value;
  onChange: (value: Value) => void;
}

export function RecipePackagingV2Editor(props: RecipePackagingV2EditorProps) {
  const { packagingComponents, value, onChange } = props;

  const { hasNoPackaging, isInvalid, selectedPackagingComponents } = value;

  const intl = useIntl();

  const handleChange = (
    packagingComponentSelectValue: PackagingComponentSelect.Value
  ) => {
    onChange({
      ...value,
      selectedPackagingComponents:
        packagingComponentSelectValue.selectedPackagingComponents,
      isInvalid: packagingComponentSelectValue.hasError,
    });
  };

  const handleHasNoPackagingChange = (hasNoPackaging: boolean): void => {
    onChange({
      ...value,
      hasNoPackaging,
      isInvalid: false, // Reset the error state
    });
  };

  return (
    <div className="form-group" style={{ width: "70%" }}>
      <RecipeEditorLabel>{label}</RecipeEditorLabel>
      <p className="text-muted">
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipePackagingV2Editor:subheader"
          defaultMessage="What packaging components are used per serving?"
        />
      </p>
      <div className="my-3">
        <RecipeHasNoPackagingCheckbox
          className={isInvalid ? "is-invalid" : ""}
          onChange={handleHasNoPackagingChange}
          hasNoPackaging={hasNoPackaging}
        />
      </div>
      {hasNoPackaging ? null : (
        <PackagingComponentSelect.PackagingComponentSelect
          hasError={isInvalid}
          packagingComponents={packagingComponents}
          onChange={handleChange}
          placeholder={intl.formatMessage({
            defaultMessage: "Search Packaging Components",
            id: "components/recipes/RecipeEditor/RecipePackagingV2Editor/PackagingComponentSelect:placeholder",
          })}
          value={{
            selectedPackagingComponents,
            hasError: isInvalid,
          }}
        />
      )}
    </div>
  );
}

interface RecipeHasNoPackagingCheckboxProps {
  className?: string;
  onChange: (hasNoPackaging: boolean) => void;
  hasNoPackaging: boolean;
}

function RecipeHasNoPackagingCheckbox(
  props: RecipeHasNoPackagingCheckboxProps
) {
  const { className, hasNoPackaging, onChange } = props;

  const recipeLabel = useRecipeLabel();

  return (
    <Checkbox
      className={className}
      id="hasNoPackagingEditor"
      defaultChecked={hasNoPackaging}
      label={
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipePackagingEditor:recipeHasNoPackaging"
          defaultMessage="This {recipeLabel} isn't packaged"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      }
      onChange={onChange}
    />
  );
}

export const fragments = {
  packagingComponent: gql`
    fragment RecipePackagingV2Editor_PackagingComponentV2 on PackagingComponentV2 {
      ...PackagingComponentSelect_PackagingComponentV2
    }
    ${PackagingComponentSelect.PackagingComponentSelect.fragments
      .packagingComponent}
  `,
  recipe: gql`
    fragment RecipePackagingV2Editor_Recipe on Recipe {
      packagingComponentsV2 {
        ...RecipePackagingV2Editor_RecipePackagingComponentV2
      }
    }

    fragment RecipePackagingV2Editor_RecipePackagingComponentV2 on RecipePackagingComponentV2 {
      packagingComponent {
        id
      }
    }
  `,
};
