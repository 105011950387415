import { ImpactRating } from "../../__generated__/globalTypes";
import EffectType, { effectTypes } from "../../domain/EffectType";
import ImpactRatingExplanation from "./ImpactRatingExplanation";

const impactRatingInfos = [
  { impactRating: ImpactRating.VLOW, ghgLowerBound: null },
  { impactRating: ImpactRating.LOW, ghgLowerBound: 1.848 },
  { impactRating: ImpactRating.MEDIUM, ghgLowerBound: 3.235 },
  { impactRating: ImpactRating.HIGH, ghgLowerBound: 5.545 },
  { impactRating: ImpactRating.VHIGH, ghgLowerBound: 9.242 },
];

const stories = {
  name: "recipes/ImpactRatingExplanation",
  children: [
    {
      name: "ghgPerKg",
      render: () => (
        <div className="d-flex flex-row flex-wrap">
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Negative"
            ghgPerKg={-1.2235256}
            ghgPerServing={-1.01}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Very low"
            ghgPerKg={1.2235256}
            ghgPerServing={1.23}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Low"
            ghgPerKg={1.9422551}
            ghgPerServing={2.33}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Medium"
            ghgPerKg={4.8275945}
            ghgPerServing={3.89}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="High"
            ghgPerKg={7.24585}
            ghgPerServing={4.72}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Very high"
            ghgPerKg={11.5696}
            ghgPerServing={10.101}
          />
        </div>
      ),
    },
    {
      name: "ghgPerServing",
      render: () => (
        <div className="d-flex flex-row flex-wrap">
          <Example
            effectType={effectTypes.ghgPerServing}
            name="Medium"
            ghgPerKg={4.8275945}
            ghgPerServing={3.89}
          />
        </div>
      ),
    },
  ],
};

interface ExampleProps {
  effectType: EffectType;
  ghgPerKg: number;
  ghgPerServing: number;
  name: string;
}

function Example(props: ExampleProps) {
  const { effectType, ghgPerKg, ghgPerServing, name } = props;

  return (
    <div className="m-4 p-4" style={{ border: "1px solid #999" }}>
      <h6 className="mb-4 text-center">{name}</h6>
      <ImpactRatingExplanation
        effectType={effectType}
        impactRatingInfos={impactRatingInfos}
        impact={{
          effects: {
            ghgPerKg,
            ghgPerRootRecipeServing: ghgPerServing,
          },
        }}
      />
    </div>
  );
}

export default stories;
