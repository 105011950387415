import VectorProps from "./VectorProps";

export default function ImpactUnavailableDash(
  props: Omit<VectorProps, "fill">
) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/design/rHZ9MQPqlK3q4kRa0DdBkd/Impact-Categories?node-id=15950-28883&t=Rg5BU09iyPTCC3O1-0
  return (
    <svg
      className={className}
      fill="none"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 42 42"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21"
        cy="21"
        r="19"
        fill="#DEE2E6"
        stroke="black"
        strokeWidth="2"
      />
      <rect x="14" y="20" width="10" height="2" rx="1" fill="black" />
    </svg>
  );
}
