import * as ColourDescriptiveRatingScaleLabels from "../../utils/Vectors/carbon-labels/colour/descriptive-rating-scale";
import { DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS } from "../../utils/Vectors/carbon-labels/constants";
import * as MonochromeDescriptiveRatingScaleLabels from "../../utils/Vectors/carbon-labels/monochrome/descriptive-rating-scale";
import { BaseCarbonLabelProps } from "./CarbonLabel";

export interface DescriptiveRatingScaleLabelProps
  extends BaseCarbonLabelProps {}

export default function DescriptiveRatingScaleLabel(
  props: DescriptiveRatingScaleLabelProps
) {
  const { className, impactRating, monochrome, width } = props;
  const DescriptiveRatingScaleLabels = monochrome
    ? MonochromeDescriptiveRatingScaleLabels
    : ColourDescriptiveRatingScaleLabels;

  const labelWidth = width ?? DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS;
  switch (impactRating) {
    case "VLOW":
      return (
        <DescriptiveRatingScaleLabels.DescriptiveRatingScaleLabelVLow
          className={className}
          width={labelWidth}
        />
      );
    case "LOW":
      return (
        <DescriptiveRatingScaleLabels.DescriptiveRatingScaleLabelLow
          className={className}
          width={labelWidth}
        />
      );
    case "MEDIUM":
      return (
        <DescriptiveRatingScaleLabels.DescriptiveRatingScaleLabelMedium
          className={className}
          width={labelWidth}
        />
      );
    case "HIGH":
      return (
        <DescriptiveRatingScaleLabels.DescriptiveRatingScaleLabelHigh
          className={className}
          width={labelWidth}
        />
      );
    case "VHIGH":
      return (
        <DescriptiveRatingScaleLabels.DescriptiveRatingScaleLabelVHigh
          className={className}
          width={labelWidth}
        />
      );
  }
}
