import { useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { allDietaryCategories } from "../../domain/dietaryCategories";
import { ImpactCategory } from "../../domain/impactCategories";
import { toMultiMap } from "../../util/maps";
import { dietColourLists } from "../graphs/colors";
import { createRecipeGroup, RecipeGroupRecipe } from "../graphs/RecipeGroup";
import { getImpactPerKg } from "./DashboardPage";
import GraphCard, { GraphType } from "./GraphCard";
import { Recipe } from "./useDashboardPageData";

interface DietaryCategoriesImpactChartCardProps {
  impactCategory: ImpactCategory;
  meanImpactPerKg: string;
  recipes: Array<Recipe>;
}

export default function DietaryCategoriesImpactChartCard(
  props: DietaryCategoriesImpactChartCardProps
) {
  const { meanImpactPerKg, recipes, impactCategory } = props;

  const intl = useIntl();

  const recipeGroups = useMemoOne(
    () => sortByDiet(recipes, impactCategory),
    [recipes, impactCategory]
  );

  return recipeGroups.length > 0 ? (
    <div className="col">
      <GraphCard
        colorLists={dietColourLists}
        defaultGraphType={GraphType.BAR}
        impactCategory={impactCategory}
        mean={meanImpactPerKg}
        recipeCount={recipes.length}
        recipeGroups={recipeGroups}
        title={intl.formatMessage({
          id: "components/dashboard/DietaryCategoriesImpactChartCard:title",
          defaultMessage: "Diet",
        })}
      />
    </div>
  ) : null;
}

function sortByDiet(recipes: Array<Recipe>, impactCategory: ImpactCategory) {
  const recipesByDietaryCategory = toMultiMap(
    recipes.flatMap((recipe) => {
      const impactPerKg = getImpactPerKg(recipe, impactCategory);
      if (impactPerKg === null) {
        return [];
      } else {
        return recipe.dietaryCategories.map(
          (dietaryCategory): [string, RecipeGroupRecipe] => [
            dietaryCategory,
            { name: recipe.name, impactPerKg },
          ]
        );
      }
    })
  );

  return allDietaryCategories.map((dietaryCategory, dietaryCategoryIndex) => {
    return createRecipeGroup({
      id: dietaryCategoryIndex,
      name: dietaryCategory,
      recipes: recipesByDietaryCategory.get(dietaryCategory) ?? [],
    });
  });
}
