import VectorProps from "./VectorProps";

export default function Share(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library
  //
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 7.5C4.20924 7.5 4.05027 7.56585 3.93306 7.68306C3.81585 7.80027 3.75 7.95924 3.75 8.125V18.125C3.75 18.2908 3.81585 18.4497 3.93306 18.5669C4.05027 18.6842 4.20924 18.75 4.375 18.75H15.625C15.7908 18.75 15.9497 18.6842 16.0669 18.5669C16.1842 18.4497 16.25 18.2908 16.25 18.125V8.125C16.25 7.95924 16.1842 7.80027 16.0669 7.68306C15.9497 7.56585 15.7908 7.5 15.625 7.5H13.125C12.9592 7.5 12.8003 7.43415 12.6831 7.31694C12.5658 7.19973 12.5 7.04076 12.5 6.875C12.5 6.70924 12.5658 6.55027 12.6831 6.43306C12.8003 6.31585 12.9592 6.25 13.125 6.25H15.625C16.1223 6.25 16.5992 6.44754 16.9508 6.79917C17.3025 7.15081 17.5 7.62772 17.5 8.125V18.125C17.5 18.6223 17.3025 19.0992 16.9508 19.4508C16.5992 19.8025 16.1223 20 15.625 20H4.375C3.87772 20 3.40081 19.8025 3.04917 19.4508C2.69754 19.0992 2.5 18.6223 2.5 18.125V8.125C2.5 7.62772 2.69754 7.15081 3.04917 6.79917C3.40081 6.44754 3.87772 6.25 4.375 6.25H6.875C7.04076 6.25 7.19973 6.31585 7.31694 6.43306C7.43415 6.55027 7.5 6.70924 7.5 6.875C7.5 7.04076 7.43415 7.19973 7.31694 7.31694C7.19973 7.43415 7.04076 7.5 6.875 7.5H4.375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4417 0.433721C10.3837 0.375517 10.3147 0.329338 10.2388 0.29783C10.1628 0.266322 10.0814 0.250104 9.99921 0.250104C9.917 0.250104 9.8356 0.266322 9.75967 0.29783C9.68374 0.329338 9.61477 0.375517 9.55671 0.433721L5.80671 4.18372C5.68935 4.30108 5.62342 4.46025 5.62342 4.62622C5.62342 4.79219 5.68935 4.95136 5.80671 5.06872C5.92407 5.18608 6.08324 5.25201 6.24921 5.25201C6.41518 5.25201 6.57435 5.18608 6.69171 5.06872L9.37421 2.38497L9.37421 13.3762C9.37421 13.542 9.44006 13.701 9.55727 13.8182C9.67448 13.9354 9.83345 14.0012 9.99921 14.0012C10.165 14.0012 10.3239 13.9354 10.4412 13.8182C10.5584 13.701 10.6242 13.542 10.6242 13.3762L10.6242 2.38497L13.3067 5.06872C13.4241 5.18608 13.5832 5.25201 13.7492 5.25201C13.9152 5.25201 14.0744 5.18608 14.1917 5.06872C14.3091 4.95136 14.375 4.79219 14.375 4.62622C14.375 4.46025 14.3091 4.30108 14.1917 4.18372L10.4417 0.433721Z"
      />
    </svg>
  );
}
