import VectorProps from "./VectorProps";

export default function SelectedCarbonLabelsIcon(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24027_36853)">
        <path
          d="M11.54 15.6H8.61998C7.78998 15.6 7.10998 14.92 7.10998 14.09H5.21998C4.46998 14.09 3.85998 13.48 3.85998 12.73V11.37C3.85998 10.93 4.21998 10.57 4.65998 10.57C5.09998 10.57 5.45998 10.93 5.45998 11.37V12.5H7.10998V11.37C7.10998 10.93 7.46998 10.57 7.90998 10.57C8.34998 10.57 8.70998 10.93 8.70998 11.37V14.01H11.45V8.11997C11.05 8.06997 10.74 7.73997 10.74 7.32997C10.74 6.88997 11.1 6.52997 11.54 6.52997H11.65C11.75 6.52997 11.85 6.54997 11.94 6.57997C12.48 6.72997 12.9 7.16997 13.01 7.71997H14.93C15.68 7.71997 16.29 8.32997 16.29 9.07997V12.73C16.29 13.48 15.68 14.09 14.93 14.09H13.04C13.04 14.93 12.36 15.61 11.53 15.61L11.54 15.6ZM13.05 12.48H14.7V9.30997H13.05V12.48Z"
          fill={fill}
        />
        <path
          d="M16.75 19.8H3.51998C1.84998 19.8 0.49998 18.44 0.49998 16.78V11.35C0.49998 10.91 0.85998 10.55 1.29998 10.55C1.73998 10.55 2.09998 10.91 2.09998 11.35V16.78C2.09998 17.56 2.73998 18.2 3.51998 18.2H16.75C17.53 18.2 18.17 17.56 18.17 16.78V5.46997C18.17 5.37997 18.14 5.29997 18.07 5.22997L14.83 1.89997C14.77 1.82997 14.68 1.79997 14.59 1.79997H11.65C11.21 1.79997 10.85 1.43997 10.85 0.999973C10.85 0.559973 11.21 0.199973 11.65 0.199973H14.59C15.11 0.199973 15.62 0.409973 15.98 0.789973L19.22 4.11997C19.57 4.47997 19.77 4.95997 19.77 5.46997V16.78C19.77 18.45 18.41 19.8 16.75 19.8Z"
          fill={fill}
        />
        <path
          d="M18.97 6.24997H17.08C15.23 6.24997 13.73 4.74997 13.73 2.89997V1.00997C13.73 0.569973 14.09 0.209973 14.53 0.209973C14.97 0.209973 15.33 0.569973 15.33 1.00997V2.89997C15.33 3.86997 16.12 4.64997 17.08 4.64997H18.97C19.41 4.64997 19.77 5.00997 19.77 5.44997C19.77 5.88997 19.41 6.24997 18.97 6.24997Z"
          fill={fill}
        />
        <path
          d="M7.80998 9.53997H2.02998C1.03998 9.53997 0.22998 8.72997 0.22998 7.73997V1.95997C0.22998 0.959973 1.03998 0.159973 2.02998 0.159973H7.80998C8.79998 0.159973 9.60998 0.969973 9.60998 1.95997V7.73997C9.60998 8.72997 8.79998 9.53997 7.80998 9.53997ZM2.02998 1.75997C1.91998 1.75997 1.82998 1.84997 1.82998 1.95997V7.73997C1.82998 7.84997 1.91998 7.93997 2.02998 7.93997H7.80998C7.91998 7.93997 8.00998 7.84997 8.00998 7.73997V1.95997C8.00998 1.84997 7.91998 1.75997 7.80998 1.75997H2.02998Z"
          fill={fill}
        />
        <path
          d="M4.28998 6.91997C4.28998 6.91997 4.28998 6.91997 4.27998 6.91997C4.04998 6.91997 3.83998 6.80997 3.68998 6.63997L2.51998 5.26997C2.22998 4.92997 2.26998 4.42997 2.60998 4.13997C2.94998 3.84997 3.44998 3.88997 3.73998 4.22997L4.31998 4.90997L6.08998 2.97997C6.38998 2.64997 6.89998 2.62997 7.21998 2.92997C7.54998 3.22997 7.56998 3.73997 7.26998 4.05997L4.88998 6.64997C4.73998 6.81997 4.51998 6.90997 4.29998 6.90997L4.28998 6.91997Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_24027_36853">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
