import VectorProps from "./VectorProps";

export default function ContactSupport(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Q85QW9IPew7lWUUb-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1632_1487)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.81452 1.56304C8.70346 1.56304 8.61328 1.65325 8.61328 1.76428V6.18571C8.61328 6.61734 8.26338 6.96723 7.83176 6.96723C7.40014 6.96723 7.05025 6.61734 7.05025 6.18571V1.76428C7.05025 0.788996 7.84123 0 8.81452 0H18.2338C19.209 0 19.998 0.790984 19.998 1.76428V7.17336C19.998 8.14864 19.2071 8.93764 18.2338 8.93764H14.2715C13.8399 8.93764 13.49 8.58774 13.49 8.15612C13.49 7.7245 13.8399 7.3746 14.2715 7.3746H18.2338C18.3448 7.3746 18.435 7.28439 18.435 7.17336V1.76428C18.435 1.65321 18.3448 1.56304 18.2338 1.56304H8.81452Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.306 3.38853C14.5695 3.04665 15.0602 2.98309 15.4021 3.24656L19.6955 6.55531C19.8875 6.70327 20 6.93194 20 7.17434C20 8.14962 19.209 8.93861 18.2357 8.93861H14.2734C13.8418 8.93861 13.4919 8.58871 13.4919 8.15709C13.4919 7.72547 13.8418 7.37558 14.2734 7.37558H18.1993L14.448 4.48461C14.1061 4.22114 14.0425 3.73041 14.306 3.38853Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7463 3.38664C13.0097 3.72854 12.9461 4.21926 12.6042 4.4827L9.59045 6.80478C9.24855 7.06822 8.75782 7.00461 8.49439 6.66271C8.23095 6.32081 8.29456 5.83008 8.63646 5.56665L11.6502 3.24456C11.9921 2.98112 12.4828 3.04473 12.7463 3.38664Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2437 1.56304L13.5273 3.9028L16.7994 1.56304H10.2437ZM7.63363 0.457041C7.946 0.174344 8.36242 0 8.81941 0H18.2387C18.5123 0 18.7715 0.0633098 19.0012 0.172926L19.0074 0.17588C19.1518 0.246351 19.2869 0.336236 19.4079 0.443157C19.5862 0.600838 19.683 0.83122 19.6707 1.06897C19.6584 1.30672 19.5385 1.52591 19.3448 1.66439L13.9826 5.49871C13.7111 5.69284 13.3463 5.69314 13.0745 5.49946L7.70452 1.67295C7.51176 1.53559 7.39163 1.31827 7.37785 1.08198C7.36408 0.845679 7.45814 0.615871 7.63363 0.457041Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.88412 6.96723C3.33787 6.96723 2.89453 7.41058 2.89453 7.95683V15.4496C2.89453 15.4493 2.89453 15.45 2.89453 15.4496C2.89431 16.406 2.58115 17.3472 1.9803 18.1053C2.48715 17.9397 2.9491 17.7227 3.41318 17.4866C4.04772 17.1634 4.7487 16.997 5.45888 16.997H12.5004C13.0466 16.997 13.49 16.5537 13.49 16.0074V7.95683C13.49 7.41058 13.0466 6.96723 12.5004 6.96723H3.88412ZM1.32719 18.2816C1.32767 18.2815 1.32816 18.2814 1.32865 18.2813L1.32719 18.2816ZM1.33149 15.4496C1.33149 15.45 1.33149 15.4493 1.33149 15.4496V7.95683C1.33149 6.54733 2.47463 5.4042 3.88412 5.4042H12.5004C13.9099 5.4042 15.053 6.54733 15.053 7.95683V16.0074C15.053 17.4169 13.9099 18.5601 12.5004 18.5601H5.45888C4.99288 18.5601 4.53525 18.6692 4.12256 18.8794C3.42958 19.2319 2.6343 19.6009 1.65476 19.8099C0.395254 20.0798 -0.492624 18.617 0.301186 17.6208C0.329863 17.5848 0.361664 17.5514 0.396216 17.521L0.394903 17.5221C0.395016 17.522 0.395176 17.5219 0.396216 17.521L0.40998 17.5083C0.423522 17.4957 0.445275 17.4751 0.473356 17.4472C0.529716 17.3912 0.61011 17.3075 0.700071 17.2018C1.10904 16.7201 1.33127 16.097 1.33149 15.4496Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1632_1487">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
