import * as Sentry from "@sentry/react";
import React from "react";

import ErrorAlert from "./ErrorAlert";

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

export default function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children } = props;

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <ErrorAlert error={error} />}
      showDialog
    >
      {children ?? null}
    </Sentry.ErrorBoundary>
  );
}
