import React, { useEffect } from "react";

import { EndOfLifeType, Tier } from "../__generated__/globalTypes";
import {
  CarbonLabelDisplayedSizeOption,
  CarbonLabelDisplayedType,
} from "../components/labels/helperFunctions";
import { LabelColourSetting } from "../components/labels/labelTypes";
import { useOrganizationOrNull } from "../components/organizations/OrganizationProvider";
import { useUserInfoOrNull } from "../data-store/useUserInfo";
import { FunctionalUnit } from "../domain/functionalUnits";
import { ImpactCategory } from "../domain/impactCategories";
import { useSession } from "../sessions";

export interface Dict {
  [key: string]: string | number | boolean | null;
}

export interface UserAttributes {
  email: string | null;
  endMileTypeName: string | null;
  endOfLifeType: EndOfLifeType | null;
  firstName: string | null;
  foodManufacturer: boolean | null;
  impersonatedExternalUserId: string | null;
  isStaff: boolean;
  isExternalUser: boolean;
  lastName: string | null;
  organizationId: string | null;
  organizationName: string | null;
  postPreparationStorageScenarioName: string | null;
  productTier: Tier | null;
  recipeCountLimit: number | null;
  userId: string | null;
}

export type UserAttributesBeforeSignUp = Pick<
  UserAttributes,
  "email" | "firstName" | "lastName" | "organizationName"
>;

export interface RecipeAttributes {
  recipeId: number;
  recipeName: string;
}

export type RecipeEditStartedAttributes =
  | ({ type: "edit" } & RecipeAttributes)
  | { type: "add" };

export type FilterTagsApplied = {
  needsAttention: boolean;
  numberOfCollections: number;
  intersectCollections: boolean;
};

export interface AddTagsTracking {
  tagName: string;
  numberOfProducts: number;
}

export interface RemoveTagsTracking {
  tagName: string;
  numberOfProducts: number;
}

export interface PageViewTracking {
  pageName: string;
  pageAttributes?: Dict;
}

export interface RecipeLabelExportTracking {
  ghgPerServing: number;
  impactRating: string;
}

export type LabelsDownloadPage = RecipePageName | "Carbon Labels" | "Recipe";

export interface LabelsDownloadTracking {
  colourSetting: LabelColourSetting;
  includePackaging: boolean;
  labelType: CarbonLabelDisplayedType;
  labelSize: CarbonLabelDisplayedSizeOption | null;
  numLabels: number;
  page: LabelsDownloadPage;
}

export interface RecipeViewTracking extends RecipeAttributes {
  pageParent: string;
}

export interface RecipeIngredientExpandedTracking extends RecipeAttributes {
  ingredientId: number;
  ingredientName: string;
}

export interface CollectionAttributes {
  collectionId: number;
  collectionName: string;
}

export interface FunctionalUnitSetAttributes {
  chart: string;
  functionalUnit: FunctionalUnit;
  foodManufacturerOrganization: boolean;
}

export interface ImpactCategorySetAttributes {
  chart?: string;
  impactCategory: ImpactCategory;
  pageName: string;
}

type LinkIngredientPage = "Ingredients" | "Upload Recipes";

export interface IngredientLinkedAttributes {
  ingredientName: string;
  numOccurrences: number;
  page: LinkIngredientPage;
  recommendedFoodClass: string | null;
  recommendedFoodClassSelected: boolean;
  selectedFoodClass: string | null;
}

export interface IngredientOriginChangedAttributes {
  ingredientName: string;
  ingredientOrigin: string;
}

export interface ProductsComparedAttributes {
  productName: string;
  comparisonProductName: string;
}

export interface RecipeEditIngredientTypeChangedTracking {
  recipeId: number | null;
  recipeName: string | null;
  newIngredientType: string;
}

export interface RecipeEditSubmittedTracking extends RecipeAttributes {
  newRecipe: boolean;
  simpleIngredientCount: number;
  subrecipeIngredientCount: number;
}

export type RecipePageName =
  | "Collections"
  | "Products"
  | "Recipes"
  | "Shared Products";

export interface RecipeImpactsExportedAttributes {
  page: RecipePageName;
  recipesExported: number;
  success: boolean;
}

export interface RecipesUploadCompletedAttributes {
  recipes: number;
  exactFoodClassMatches: number;
  exactPackagingComponentMatches: number;
  fuzzyMatchedFoodClasses: number;
  acceptedIngredientMatches: number;
  rejectedIngredientMatches: number;
  duplicateRecipeHandler: string;
}

export type DuplicateProcessing =
  | "Keep Both"
  | "Replace"
  | "Skip Duplicates"
  | "Stop";

export interface RecipesUploadDuplicatesProcessedAttributes {
  duplicateProcessing: DuplicateProcessing;
}

export interface RecipesUploadFileParsedAttributes {
  fileType: string;
  recipes: number;
}

export interface RecipesUploadServingsEnteredAttributes {
  servingsPerRecipe: number;
}

export interface PackagingComponentDeletedTracking {}

export interface PackagingComponentSubmittedTracking {
  isNew: boolean;
}

export interface PromotionCodeAppliedAttributes {
  couponId: string | null;
  couponName: string | null;
  promotionCode: string | null;
  promotionId: string | null;
  success: boolean;
}

export interface SignUpConsumptionCountrySubmittedAttributes {
  countryName: string;
}

export interface SignUpEndMileTypeQuestionAnsweredAttributes {
  question: string;
  answer: string;
}

export interface TagCreatedAttributes {
  tagName: string;
  tagId: number;
  workflow: string;
}

export interface TagDeletedAttributes {
  tagName: string;
  tagId: number;
}

export interface TagRenamedAttributes {
  newTagName: string;
  previousTagName: string;
  tagId: number;
}

export interface UserCreatedAttributes {
  email: string;
  firstName: string;
  lastName: string;
}

export interface Tracking {
  getDistinctId: () => string | undefined;
  identifyUser: (userAttributes: UserAttributes) => void;
  identifyUserBeforeSignUp: (
    UserAttributes: UserAttributesBeforeSignUp
  ) => void;
  trackAccessCommunityPlatform: () => void;
  trackAddTags: (tracking: AddTagsTracking) => void;
  trackEditTagsStarted: () => void;
  trackEditTagsCompleted: () => void;
  trackFilterTagsStarted: () => void;
  trackFilterTagsApplied: (tracking: FilterTagsApplied) => void;
  trackRemoveTags: (tracking: RemoveTagsTracking) => void;
  trackPackagingComponentDeleted: (
    tracking: PackagingComponentDeletedTracking
  ) => void;
  trackPackagingComponentSubmitted: (
    tracking: PackagingComponentSubmittedTracking
  ) => void;
  trackPageViewed: (tracking: PageViewTracking) => void;
  trackRecipeLabelExported: (tracking: RecipeLabelExportTracking) => void;
  trackLabelsDownloaded: (tracking: LabelsDownloadTracking) => void;
  trackRecipeViewed: (tracking: RecipeViewTracking) => void;
  trackRecipeIngredientExpanded: (
    tracking: RecipeIngredientExpandedTracking
  ) => void;
  trackCollectionViewed: (tracking: CollectionAttributes) => void;
  trackCollectionDeleted: (tracking: CollectionAttributes) => void;
  trackCollectionCreationCompleted: (tracking: CollectionAttributes) => void;
  trackCollectionCreationStarted: () => void;
  trackFunctionalUnitSet: (tracking: FunctionalUnitSetAttributes) => void;
  trackImpactCategorySet: (tracking: ImpactCategorySetAttributes) => void;
  trackIngredientLinked: (tracking: IngredientLinkedAttributes) => void;
  trackIngredientOriginChanged: (
    tracking: IngredientOriginChangedAttributes
  ) => void;
  trackManageTagsCompleted: () => void;
  trackManageTagsStarted: () => void;
  trackTagCreated: (tracking: TagCreatedAttributes) => void;
  trackTagDeleted: (tracking: TagDeletedAttributes) => void;
  trackTagRenamed: (tracking: TagRenamedAttributes) => void;
  trackProductsCompared: (tracking: ProductsComparedAttributes) => void;
  trackRecipeDeleted: (tracking: RecipeAttributes) => void;
  trackRecipeEditStarted: (tracking: RecipeEditStartedAttributes) => void;
  trackRecipeEditIngredientTypeChanged: (
    tracking: RecipeEditIngredientTypeChangedTracking
  ) => void;
  trackRecipeEditSubmitted: (tracking: RecipeEditSubmittedTracking) => void;
  trackRecipeCopyStarted: (tracking: RecipeAttributes) => void;
  trackRecipeImpactsExported: (
    tracking: RecipeImpactsExportedAttributes
  ) => void;
  trackBulkRecipeUploadCompleted: (
    tracking: RecipesUploadCompletedAttributes
  ) => void;
  trackBulkRecipeUploadDuplicatesProcessed: (
    tracking: RecipesUploadDuplicatesProcessedAttributes
  ) => void;
  trackBulkRecipeUploadFileParsed: (
    tracking: RecipesUploadFileParsedAttributes
  ) => void;
  trackBulkRecipeUploadServingsEntered: (
    tracking: RecipesUploadServingsEnteredAttributes
  ) => void;
  trackBulkRecipeUploadStarted: () => void;
  trackPromotionCodeApplied: (tracking: PromotionCodeAppliedAttributes) => void;
  trackSignUpPersonalDetailsSubmitted: () => void;
  trackSignUpConsumptionCountrySubmitted: (
    tracking: SignUpConsumptionCountrySubmittedAttributes
  ) => void;
  trackSignUpEndMileTypeQuestionAnswered: (
    tracking: SignUpEndMileTypeQuestionAnsweredAttributes
  ) => void;
  trackSignUpAcknowledgementSubmitted: () => void;
  trackUserCreated: (tracking: UserCreatedAttributes) => void;
}

const TrackingContext = React.createContext<Tracking | null>(null);

export function useTracking(): Tracking {
  const tracking = React.useContext(TrackingContext);
  if (tracking === null) {
    throw new Error("missing provider for TrackingContext");
  } else {
    return tracking;
  }
}

export const RawTrackingProvider = TrackingContext.Provider;

interface TrackingProviderProps {
  children: React.ReactNode;
  value: Tracking;
}

export function TrackingProvider(props: TrackingProviderProps) {
  const { children, value } = props;

  const session = useSession();
  const [organization] = useOrganizationOrNull();
  const userInfo = useUserInfoOrNull();

  useEffect(() => {
    if (session.externalUserId !== null) {
      value.identifyUser({
        email: userInfo?.email ?? null,
        endMileTypeName: organization?.projectOptions.endMileType?.name ?? null,
        endOfLifeType: organization?.projectOptions.endOfLifeType ?? null,
        firstName: userInfo?.firstName ?? null,
        foodManufacturer:
          organization?.features.foodManufacturerOrganization ?? null,
        impersonatedExternalUserId:
          session.impersonatedUser?.externalId ?? null,
        isStaff: session.userIsStaff!,
        isExternalUser: session.isExternalUser!,
        lastName: userInfo?.lastName ?? null,
        organizationId: organization?.id ?? null,
        organizationName: organization?.name ?? null,
        postPreparationStorageScenarioName:
          organization?.projectOptions.postPreparationStorageScenario?.name ??
          null,
        productTier: organization?.productTier ?? null,
        recipeCountLimit: organization?.recipeCountLimit ?? null,
        userId: session.externalUserId,
      });
    }
  }, [value, organization, session, userInfo]);

  return <RawTrackingProvider value={value}>{children}</RawTrackingProvider>;
}
