import VectorProps from "../VectorProps";

export default function Transport(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Ksp5K23jLgLje03Y-0
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_1767)">
        <g clipPath="url(#clip1_2079_1767)">
          <path
            d="M21.44 21.12C27.2279 21.12 31.92 16.4279 31.92 10.64C31.92 4.85206 27.2279 0.160004 21.44 0.160004C15.652 0.160004 10.96 4.85206 10.96 10.64C10.96 16.4279 15.652 21.12 21.44 21.12Z"
            fill="#67DCC2"
          />
          <path
            d="M26.4496 26.5504C26.4496 26.5504 8.40318 26.5792 8.25758 26.5504C8.11198 26.5216 8.25758 25.1824 8.25758 25.1824V22.032C8.25758 21.1712 8.95518 20.472 9.81758 20.472H15.4192C16.2816 20.472 16.9792 19.7728 16.9792 18.9104L16.96 7.27678C16.96 6.41438 17.6576 5.71518 18.52 5.71518H22.2672C23.1872 5.71518 24.0416 6.18718 24.5312 6.96158C24.5616 7.00958 24.5872 7.05918 24.6128 7.10878L26.5264 10.6048L26.5776 13.056H28.2496V25.4416C28.2496 26.0528 27.7536 26.5488 27.1424 26.5488H26.4512L26.4496 26.5504Z"
            fill="#00A68F"
          />
          <path
            d="M13.6175 27.4432C8.23988 27.4432 8.16468 27.4288 8.08948 27.4144C7.16468 27.2352 7.26548 26.1744 7.37748 25.1344V22.032C7.37748 20.6864 8.47188 19.592 9.81748 19.592H15.4191C15.5999 19.592 15.7711 19.5216 15.8991 19.392C16.0271 19.264 16.0975 19.0928 16.0975 18.9104L16.0783 7.27679C16.0783 6.62399 16.3311 6.01119 16.7919 5.54879C17.2527 5.08799 17.8655 4.83359 18.5183 4.83359H22.2655C23.4927 4.83359 24.6175 5.45279 25.2735 6.48799C25.3247 6.56799 25.3647 6.64479 25.3919 6.70079L27.2959 10.1808C27.3631 10.3056 27.4015 10.4432 27.4031 10.5856L27.4367 12.176H28.2463C28.7327 12.176 29.1263 12.5696 29.1263 13.056V25.4416C29.1263 26.5376 28.2351 27.4288 27.1391 27.4288H26.4495C20.3775 27.4384 16.3247 27.4416 13.6159 27.4416L13.6175 27.4432ZM9.09588 25.6784C11.7487 25.688 20.4191 25.6784 26.4479 25.6704H27.1407C27.2655 25.6704 27.3679 25.568 27.3679 25.4432V13.9376H26.5759C26.0975 13.9376 25.7055 13.5552 25.6959 13.0752L25.6495 10.8384L23.8399 7.53119C23.8335 7.51999 23.8271 7.50879 23.8223 7.49599C23.8111 7.47519 23.8015 7.45279 23.7887 7.43359C23.4559 6.90719 22.8863 6.59519 22.2671 6.59519H18.5199C18.3375 6.59519 18.1679 6.66559 18.0399 6.79519C17.9119 6.92319 17.8415 7.09439 17.8415 7.27679L17.8607 18.9104C17.8607 19.5632 17.6079 20.176 17.1471 20.6384C16.6863 21.0992 16.0735 21.3536 15.4207 21.3536H9.81908C9.44468 21.3536 9.13908 21.6576 9.13908 22.0336V25.184C9.13908 25.216 9.13748 25.248 9.13428 25.2784C9.11988 25.4112 9.10708 25.5488 9.09748 25.68L9.09588 25.6784Z"
            fill="black"
          />
          <path
            d="M1.27979 22.072H4.40619V18.3648H14.1758V4.48001H1.27979V22.072Z"
            fill="#DCFFD1"
          />
          <path
            d="M4.4063 22.952H1.2799C0.793502 22.952 0.399902 22.5584 0.399902 22.072V4.48001C0.399902 3.99361 0.793502 3.60001 1.2799 3.60001H14.1759C14.6623 3.60001 15.0559 3.99361 15.0559 4.48001V18.3648C15.0559 18.8512 14.6623 19.2448 14.1759 19.2448H5.2863V22.072C5.2863 22.5584 4.8927 22.952 4.4063 22.952ZM2.1599 21.192H3.5263V18.3648C3.5263 17.8784 3.9199 17.4848 4.4063 17.4848H13.2959V5.36001H2.1599V21.192Z"
            fill="black"
          />
          <path
            d="M11.0209 15.392C10.8881 15.392 10.7537 15.3616 10.6273 15.2992C8.97286 14.472 8.03526 13.9008 6.88006 13.0384C5.94886 14.04 4.94726 15.0368 4.93126 15.0544C4.58406 15.3952 4.02726 15.3888 3.68646 15.0416C3.34566 14.6944 3.35206 14.1376 3.69926 13.7968C4.09766 13.4064 4.83366 12.6512 5.49766 11.9408C4.85286 11.3312 4.43366 10.5776 4.30406 9.784C4.12166 8.672 4.68486 7.3216 5.94086 6.9856C7.01926 6.6992 8.21127 7.3424 8.65447 8.4544C8.98727 9.2896 8.89286 10.2976 8.39366 11.2192C8.32647 11.344 8.20486 11.5136 8.04647 11.7136C9.07207 12.4704 9.92006 12.9792 11.4161 13.7264C11.8513 13.944 12.0273 14.472 11.8097 14.9072C11.6561 15.216 11.3441 15.3936 11.0225 15.3936L11.0209 15.392ZM6.46726 8.6768C6.44166 8.6768 6.41767 8.68 6.39527 8.6864C6.16007 8.7488 5.98406 9.152 6.04166 9.4992C6.10566 9.8912 6.32966 10.2896 6.67046 10.6256C6.75526 10.52 6.81606 10.4352 6.84806 10.3792C7.09286 9.9264 7.15847 9.4496 7.02087 9.1056C6.92647 8.8672 6.67046 8.6768 6.46886 8.6768H6.46726Z"
            fill="black"
          />
          <path
            d="M23.2559 30.744C25.0373 30.744 26.4815 29.2999 26.4815 27.5184C26.4815 25.737 25.0373 24.2928 23.2559 24.2928C21.4744 24.2928 20.0303 25.737 20.0303 27.5184C20.0303 29.2999 21.4744 30.744 23.2559 30.744Z"
            fill="#10675B"
          />
          <path
            d="M23.256 31.624C20.992 31.624 19.1504 29.7824 19.1504 27.5184C19.1504 25.2544 20.992 23.4128 23.256 23.4128C25.52 23.4128 27.3616 25.2544 27.3616 27.5184C27.3616 29.7824 25.52 31.624 23.256 31.624ZM23.256 25.1712C21.9616 25.1712 20.9104 26.224 20.9104 27.5168C20.9104 28.8096 21.9632 29.8624 23.256 29.8624C24.5488 29.8624 25.6016 28.8096 25.6016 27.5168C25.6016 26.224 24.5488 25.1712 23.256 25.1712Z"
            fill="black"
          />
          <path
            d="M14.6126 30.744C16.394 30.744 17.8382 29.2999 17.8382 27.5184C17.8382 25.737 16.394 24.2928 14.6126 24.2928C12.8311 24.2928 11.387 25.737 11.387 27.5184C11.387 29.2999 12.8311 30.744 14.6126 30.744Z"
            fill="#10675B"
          />
          <path
            d="M14.6127 31.624C12.3487 31.624 10.5071 29.7824 10.5071 27.5184C10.5071 25.2544 12.3487 23.4128 14.6127 23.4128C16.8767 23.4128 18.7183 25.2544 18.7183 27.5184C18.7183 29.7824 16.8767 31.624 14.6127 31.624ZM14.6127 25.1712C13.3183 25.1712 12.2671 26.224 12.2671 27.5168C12.2671 28.8096 13.3199 29.8624 14.6127 29.8624C15.9055 29.8624 16.9583 28.8096 16.9583 27.5168C16.9583 26.224 15.9055 25.1712 14.6127 25.1712Z"
            fill="black"
          />
          <path
            d="M6.03517 30.744C7.81662 30.744 9.26077 29.2999 9.26077 27.5184C9.26077 25.737 7.81662 24.2928 6.03517 24.2928C4.25372 24.2928 2.80957 25.737 2.80957 27.5184C2.80957 29.2999 4.25372 30.744 6.03517 30.744Z"
            fill="#10675B"
          />
          <path
            d="M6.03529 31.624C3.77129 31.624 1.92969 29.7824 1.92969 27.5184C1.92969 25.2544 3.77129 23.4128 6.03529 23.4128C8.29929 23.4128 10.1409 25.2544 10.1409 27.5184C10.1409 29.7824 8.29929 31.624 6.03529 31.624ZM6.03529 25.1712C4.74249 25.1712 3.68969 26.224 3.68969 27.5168C3.68969 28.8096 4.74249 29.8624 6.03529 29.8624C7.32809 29.8624 8.38089 28.8096 8.38089 27.5168C8.38089 26.224 7.32809 25.1712 6.03529 25.1712Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2079_1767">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_2079_1767">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
