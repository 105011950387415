import Page from "../Page";
import { buildPath, usePages } from "../pages";
import EditPackagingComponentEditor from "./EditPackagingComponentEditor";
import * as PackagingPageLocationState from "./PackagingPageLocationState";

const editPackagingComponentPageTracking = {
  pageName: "edit-packaging-component",
};

export default function EditPackagingComponentPage() {
  const pages = usePages();
  const packagingComponentQuery = PackagingPageLocationState.useQueryParams();
  const { componentId } = pages.PackagingComponentsEdit.useParams();

  return (
    <Page tracking={editPackagingComponentPageTracking}>
      <EditPackagingComponentEditor
        packagingComponentId={componentId}
        parentUrl={buildPath(
          pages.Packaging().url,
          pages.PackagingComponentsListPage.stringifyQueryParams(
            packagingComponentQuery
          )
        )}
      />
    </Page>
  );
}
