import classNames from "classnames";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";

import "./PlansPageBanner.css";

import ContactUsButton from "../recipes/ContactUsButton";
import { Feedback } from "../utils/Vectors";

interface PlansPageBannerProps {
  children: React.ReactNode;
  className?: string;
}

export function PlansPageBanner(props: PlansPageBannerProps) {
  const { children, className } = props;

  return (
    <div
      className={classNames("plans-page-banner-container px-3 py-1", className)}
    >
      {children}
    </div>
  );
}

interface CommonBannerProps {
  className?: string;
}

type ChangePlanPromptProps = {} & CommonBannerProps;

export function ChangePlanPrompt(props: ChangePlanPromptProps) {
  const { className } = props;

  return (
    <PlansPageBanner className={className}>
      <Feedback width={16} className="mr-2" />
      <small>
        <FormattedMessage
          id="components/subscriptions/PlansPagePrompt/ChangePlanPrompt:message"
          defaultMessage="If you would like to change or cancel your plan, <link>get in contact</link> with us."
          values={{
            link: (chunks: React.ReactNode) => (
              <ContactUsButton
                className="p-0"
                messageSubject="Change or cancel plan enquiry"
                modalSubject={
                  <FormattedMessage
                    id="components/subscriptions/ChangePlanPrompt:modalSubject"
                    defaultMessage="Ask about changing or cancelling your plan"
                  />
                }
                buttonType="link"
              >
                <small>{chunks}</small>
              </ContactUsButton>
            ),
          }}
        />
      </small>
    </PlansPageBanner>
  );
}

type AwaitingPaymentConfirmationBannerProps = {} & CommonBannerProps;

export function AwaitingPaymentConfirmationBanner(
  props: AwaitingPaymentConfirmationBannerProps
) {
  const { className } = props;

  return (
    <PlansPageBanner
      className={classNames("awaiting-payment-confirmation-banner", className)}
    >
      <small>
        <FormattedMessage
          id="components/subscriptions/PlansPagePrompt/AwaitingPaymentConfirmationBanner:message"
          defaultMessage="We're waiting to receive confirmation of your payment before we update your plan"
        />
      </small>{" "}
      <Spinner animation="border" size="sm" />
    </PlansPageBanner>
  );
}
