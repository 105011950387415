type ReadResult<TValue, TInput> =
  | { hasError: true; value: TValue }
  | { hasError: false; input: TInput; value: TValue };

export default ReadResult;

export function combineReadResults<
  T extends { [key: string]: ReadResult<unknown, unknown> }
>(
  results: T
): ReadResult<
  {
    [Property in keyof T]: T[Property] extends ReadResult<infer TValue, unknown>
      ? TValue
      : never;
  },
  {
    [Property in keyof T]: T[Property] extends ReadResult<unknown, infer TInput>
      ? TInput
      : never;
  }
> {
  let hasError = false;
  let input: { [key: string]: unknown } = {};
  let value: { [key: string]: unknown } = {};

  for (const [key, result] of Object.entries(results)) {
    hasError = hasError || result.hasError;
    if (!result.hasError) {
      input[key] = result.input;
    }
    value[key] = result.value;
  }

  if (hasError) {
    return { hasError, value: value as any };
  } else {
    return { hasError, input: input as any, value: value as any };
  }
}

export function combineReadResultsArray<TValue, TInput>(
  results: Array<ReadResult<TValue, TInput>>
): ReadResult<Array<TValue>, Array<TInput>> {
  let hasError = false;
  const input: Array<TInput> = [];
  const value: Array<TValue> = [];

  for (const result of results) {
    hasError = hasError || result.hasError;
    if (!result.hasError) {
      input.push(result.input);
    }
    value.push(result.value);
  }

  if (hasError) {
    return { hasError, value };
  } else {
    return { hasError, input, value };
  }
}

export function combineReadResultsMap<K, TValue, TInput>(
  results: Map<K, ReadResult<TValue, TInput>>
): ReadResult<Map<K, TValue>, Map<K, TInput>> {
  let hasError = false;
  let input = new Map();
  let value = new Map();

  for (const [key, result] of Array.from(results.entries())) {
    hasError = hasError || result.hasError;
    if (!result.hasError) {
      input.set(key, result.input);
    }
    value.set(key, result.value);
  }

  if (hasError) {
    return { hasError, value };
  } else {
    return { hasError, input, value };
  }
}
