import { useState } from "react";
import { Link } from "react-router-dom";

import useUserInfo from "../data-store/useUserInfo";
import { useLocalStorageState } from "../services/LocalStorageService";
import "./Header.css";
import AccountDropdown from "./navigation/AccountDropdown";
import QuarterlyDataUpdateBanner from "./QuarterlyDataUpdateBanner";
import SplitRow from "./SplitRow";
import FoodstepsLogo from "./utils/FoodstepsLogo";

interface HeaderProps {
  showNavbarBorder: boolean;
}

export default function Header(props: HeaderProps) {
  const { showNavbarBorder } = props;
  const [{ email, firstName, lastName }] = useUserInfo();
  const [quarterlyDataUpdateBannerClosed, setQuarterlyDataUpdateBannerClosed] =
    useLocalStorageState(`quarterly_data_update_banner_closed`);

  const [showQuarterlyDataUpdateBanner, setShowQuarterlyDataUpdateBanner] =
    useState<boolean>(quarterlyDataUpdateBannerClosed === null);

  const handleQuarterlyDataUpdateBannerClose = () => {
    setShowQuarterlyDataUpdateBanner(false);
    setQuarterlyDataUpdateBannerClosed("closed");
  };

  return (
    <>
      {showQuarterlyDataUpdateBanner && (
        <QuarterlyDataUpdateBanner
          onClose={handleQuarterlyDataUpdateBannerClose}
        />
      )}
      <SplitRow
        showBorder={showNavbarBorder}
        className="Header_SplitRow"
        left={
          <div className="h-100 p-2 pl-4">
            <Link className="d-flex h-100" to={"/"}>
              <div className="my-auto">
                <FoodstepsLogo width={147} />
              </div>
            </Link>
          </div>
        }
        right={
          <>
            {process.env.REACT_APP_MODE !== "product-report" && (
              <div className="h-100 d-flex pr-4">
                <div className="name-and-email small my-auto pr-2">
                  <div className="medium-font">{`${firstName} ${lastName}`}</div>
                  <div>{`${email}`}</div>
                </div>
                <div className="my-auto">
                  <AccountDropdown />
                </div>
              </div>
            )}
          </>
        }
      />
    </>
  );
}
