import max from "lodash/max";
import range from "lodash/range";
import sum from "lodash/sum";

import { impactCategoryToEffectTypePerKg } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { padArrayBothEnds } from "../../util/arrays";
import { ColorLists } from "./colors";
import RecipeGroup, { RecipeGroupRecipe } from "./RecipeGroup";

const valuesForChart = (
  impactCategory: ImpactCategory,
  recipeGroups: Array<RecipeGroup>,
  colorLists: ColorLists
) => {
  recipeGroups = recipeGroups.filter(
    (recipeGroup) => recipeGroup.recipes.length > 0
  );

  let labels = recipeGroups.map((x) => {
    return x.name;
  });

  const groupAveragesData = {
    dataset: {
      data: recipeGroups.map((recipeGroup) =>
        calculateMeanGhgPerKg(recipeGroup)
      ),
      backgroundColor: colorLists.colours,
      hoverBackgroundColor: colorLists.colours,
      borderWidth: 0,
    },
    labels,
  };

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);

  return {
    effectType,
    groupAveragesData,
    recipesData: fillRecipeData(recipeGroups, labels, colorLists),
  };
};

function calculateMeanGhgPerKg(recipeGroup: RecipeGroup) {
  return (
    sum(recipeGroup.recipes.map((recipe) => recipe.impactPerKg)) /
    recipeGroup.recipes.length
  );
}

const fillRecipeData = (
  collections: Array<RecipeGroup>,
  labels: Array<string>,
  colorLists: ColorLists
) => {
  const maximumRecipeCount =
    max(collections.map((collection) => collection.recipes.length)) ?? 0;

  const recipes = collections.map((collection) => {
    const collectionRecipes = Array.from(collection.recipes).sort(
      (a, b) => a.impactPerKg - b.impactPerKg
    );
    return padArrayBothEnds(collectionRecipes, maximumRecipeCount, null);
  });

  const datasets = range(maximumRecipeCount).map((recipeIndex) => {
    const getRecipeValues = <TValue>(
      getRecipeValue: (recipe: RecipeGroupRecipe) => TValue,
      defaultValue: TValue
    ) =>
      recipes.map((collectionRecipes) => {
        const recipe = collectionRecipes[recipeIndex];
        return recipe === null ? defaultValue : getRecipeValue(recipe);
      });

    return {
      borderWidth: 0,
      barPercentage: 0.95,
      backgroundColor: colorLists.colours,
      hoverBackgroundColor: colorLists.hover,
      data: getRecipeValues((recipe) => recipe.impactPerKg, 0),
      labels: getRecipeValues((recipe) => recipe.name, ""),
    };
  });
  return {
    labels,
    datasets,
  };
};

export { valuesForChart };
