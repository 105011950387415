import { useState } from "react";

import RadioToggleButtonGroup from "./RadioToggleButtonGroup";

const stories = {
  name: "RadioToggleButtonGroup",
  children: [
    {
      name: "Default Radio Toggle Group",
      render: () => <RadioToggleButtonGroupStory />,
    },
    {
      name: "Small Radio Toggle Group",
      render: () => <RadioToggleButtonGroupStory size="sm" />,
    },
    {
      name: "Large Radio Toggle Group",
      render: () => <RadioToggleButtonGroupStory size="lg" />,
    },
  ],
};

const options = [
  { key: "left", label: "Left", value: "Left is selected" },
  { key: "middle", label: "Middle", value: "Middle is selected" },
  { key: "right", label: "Right", value: "Right is selected" },
];

interface RadioToggleButtonGroupStoryProps {
  size?: "lg" | "sm";
}

function RadioToggleButtonGroupStory(props: RadioToggleButtonGroupStoryProps) {
  const [value, setValue] = useState(options[0].value);

  return (
    <div>
      <RadioToggleButtonGroup
        onChange={(newValue) => setValue(newValue)}
        options={options}
        size={props.size}
        value={value}
      />
      <p>{value}</p>
    </div>
  );
}

export default stories;
