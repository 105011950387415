import React, { ReactElement, useState } from "react";
import { Navbar } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { useProductPackaging } from "../../../services/useOrganizationFeatures";
import BackButton from "../../utils/BackButton";
import { PrimaryButton, SecondaryButton } from "../../utils/Button";
import Form from "../../utils/Form";
import Stepper, { Step } from "../../utils/Stepper";
import UpgradeRequestModal from "../../utils/UpgradeRequestModal";
import useRecipeLabel from "../useRecipeLabel";
import "./ProductEditorHeader.css";

export type StepName = "productDetails" | "processing" | "packaging";

interface ProductEditorHeaderProps {
  editOrCreate: "edit" | "create";
  onBack: () => void;
  onSubmit: () => Promise<void>;
  packaging: ReactElement;
  productDetails: ReactElement;
  processing: (onBack: () => void) => ReactElement;
  stepsWithError: Array<StepName>;
}

export default function ProductEditorHeader(props: ProductEditorHeaderProps) {
  const {
    onBack,
    onSubmit,
    editOrCreate,
    packaging,
    productDetails,
    processing,
    stepsWithError,
  } = props;

  const canUseProductPackaging = useProductPackaging();
  const history = useHistory();
  const recipeLabel = useRecipeLabel();
  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);

  const goBack = (
    <div className="d-flex flex-row">
      <BackButton back={onBack} />
      <div className="medium-font my-auto ml-3">
        {editOrCreate === "create" ? (
          <FormattedMessage
            defaultMessage="Create a New {recipeLabel}"
            id="components/recipes/ProductEditor/ProductEditorHeader/createNewProduct"
            values={{ recipeLabel: recipeLabel.singularUppercase }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Edit {recipeLabel}"
            id="components/recipes/ProductEditor/ProductEditorHeader/editProduct"
            values={{ recipeLabel: recipeLabel.singularUppercase }}
          />
        )}
      </div>
    </div>
  );

  const submitButton = (
    <Form.SubmitButton
      className="ProductEditorHeader__SaveButton"
      loadingLabel={
        editOrCreate === "create" ? (
          <FormattedMessage
            defaultMessage="Creating"
            id="components/recipes/ProductEditor/ProductEditorHeader/creating"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Saving"
            id="components/recipes/ProductEditor/ProductEditorHeader/saving"
          />
        )
      }
      submitLabel={
        editOrCreate === "create" ? (
          <FormattedMessage
            defaultMessage="Create"
            id="components/recipes/ProductEditor/ProductEditorHeader/create"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Save"
            id="components/recipes/ProductEditor/ProductEditorHeader/save"
          />
        )
      }
    />
  );

  const saveButton = (
    <Form onSubmit={onSubmit}>
      <div className="justify-content-between d-flex">
        <Form.ErrorAlert className="LeftErrorAlert mr-3" />
        {submitButton}
      </div>
    </Form>
  );

  const cancelOrSave = (
    <Form
      className="flex-column ProductEditorHeader__CancelOrSaveWrapper"
      onSubmit={onSubmit}
    >
      <div className="ProductEditorHeader__Header">
        <SecondaryButton onClick={() => history.goBack()} className="mr-3">
          <FormattedMessage
            defaultMessage="Cancel"
            id="components/recipes/ProductEditor/ProductEditorHeader/cancel"
          />
        </SecondaryButton>
        <div className="justify-content-between d-flex">{submitButton}</div>
      </div>
      <Form.ErrorAlert className="ProductEditorHeader__BelowErrorAlert mt-4" />
    </Form>
  );

  const nextButton = (onNext: () => void) => (
    <PrimaryButton onClick={onNext}>
      <FormattedMessage
        id="components/recipes/ProductEditor/ProductEditorHeader:next"
        defaultMessage="Next"
      />
    </PrimaryButton>
  );

  const steps: Array<Step> = [
    {
      label: (
        <FormattedMessage
          id="components/recipes/ProductEditor/ProductEditorHeader/ProductEditorStepper:productOverview"
          defaultMessage="{recipeLabel} Overview"
          values={{ recipeLabel: recipeLabel.singularUppercase }}
        />
      ),
      render: () => productDetails,
      renderControls: ({ onNext }) => (
        <div className="ProductEditorHeader__ControlsContainer text-right">
          {nextButton(onNext!)}
        </div>
      ),
      hasError: stepsWithError.includes("productDetails"),
    },
    {
      label: (
        <FormattedMessage
          id="components/recipes/ProductEditor/ProductEditorHeader/ProductEditorStepper:processing"
          defaultMessage="Processing"
        />
      ),
      render: ({ onBack }) => processing(onBack!),
      renderControls: ({ onBack, onNext }) => (
        <div className="ProductEditorHeader__ControlsContainer d-flex flex-row">
          <div className="mr-auto">
            <SecondaryButton onClick={onBack!}>
              <FormattedMessage
                id="components/recipes/ProductEditor/ProductEditorHeader:previous"
                defaultMessage="Previous"
              />
            </SecondaryButton>
          </div>
          {/*
              TODO: This works because packaging in the last step - would be good to allow
               flexibility to skip until the next unlocked step, or show the save button
          */}
          {canUseProductPackaging ? nextButton(onNext!) : saveButton}
        </div>
      ),
      hasError: stepsWithError.includes("processing"),
    },
    {
      label: (
        <FormattedMessage
          id="components/recipes/ProductEditor/ProductEditorHeader/ProductEditorStepper:packaging"
          defaultMessage="Packaging"
        />
      ),
      render: () => packaging,
      renderControls: ({ onBack }) => (
        <div className="ProductEditorHeader__ControlsContainer d-flex flex-row">
          <div className="mr-auto">
            <SecondaryButton onClick={onBack!}>
              <FormattedMessage
                id="components/recipes/ProductEditor/ProductEditorHeader:previous"
                defaultMessage="Previous"
              />
            </SecondaryButton>
          </div>
          {saveButton}
        </div>
      ),
      hasError: stepsWithError.includes("packaging"),
      locked: !canUseProductPackaging,
      lockedMessage: (
        <FormattedMessage
          id="components/recipes/ProductEditorHeader:privateIconPackagingTooltipMessage"
          defaultMessage="Add packaging and measure its impact"
        />
      ),
    },
  ];

  return (
    <div className="h-100 my-auto">
      <Stepper
        contentClassNames="ProductEditorHeader__StepContentContainer"
        enforceOrder={false}
        navContainer={(stepperNav) => (
          <Navbar className="ProductEditorHeader__Navbar">
            {goBack}
            {stepperNav}
            {cancelOrSave}
          </Navbar>
        )}
        steps={steps}
        onLockClick={() => setShowUpgradeRequestModal(true)}
      />
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </div>
  );
}
