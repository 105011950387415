import { useMemo } from "react";

import { TransportStorageMethod } from "../../__generated__/globalTypes";
import {
  allTransportStorageMethods,
  formatTransportStorageMethod,
} from "../../domain/transportStorageMethods";
import Select from "../utils/Select";

export type Value = TransportStorageMethod | null;

interface TransportStorageMethodSelectProps {
  hasError: boolean;
  onChange: (value: Value) => void;
  placeholder?: string;
  value: Value;
}

export function TransportStorageMethodSelect(
  props: TransportStorageMethodSelectProps
) {
  const { hasError, onChange, placeholder, value } = props;

  // There seems to be some strange behaviour with react-select where using
  // the enum value (i.e. a string) directly as the value results in the
  // Select always rendering as empty. Therefore, we wrap the value in an
  // object to fix the rendering.

  const options = useMemo(
    () =>
      allTransportStorageMethods.map((storageMethod) => ({ storageMethod })),
    []
  );

  return (
    <Select
      hasError={hasError}
      onChange={(value) =>
        onChange(value === null ? value : value.storageMethod)
      }
      options={options}
      optionKey={transportStorageMethodKey}
      placeholder={placeholder}
      renderOption={({ storageMethod }) =>
        formatTransportStorageMethod(storageMethod)
      }
      value={value === null ? null : { storageMethod: value }}
    />
  );
}

function transportStorageMethodKey({
  storageMethod,
}: {
  storageMethod: TransportStorageMethod;
}) {
  return storageMethod;
}
