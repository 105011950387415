import VectorProps from "./VectorProps";

export default function AttentionNeededWithoutTextbox(
  props: Omit<VectorProps, "fill">
) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?type=design&node-id=846-888&mode=design&t=vhCxptW20kMnqOWa-0
  return (
    <svg
      className={className}
      fill="none"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 46 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_863_1110)">
        <path
          d="M16.1596 31.0644C24.3136 31.0644 30.9237 24.4543 30.9237 16.3003C30.9237 8.14625 24.3136 1.53613 16.1596 1.53613C8.00563 1.53613 1.39551 8.14625 1.39551 16.3003C1.39551 24.4543 8.00563 31.0644 16.1596 31.0644Z"
          fill="#00A68F"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M28.6729 13.4644C28.2052 13.2306 27.7374 13.2306 27.0357 13.9322C26.7375 14.2304 26.8018 14.5169 26.8018 15.1017C26.8018 15.2841 26.6167 16.1281 26.4004 16.2711C25.6004 16.8 24.2326 16.5377 23.6444 16.2711C23.1111 16.029 22.2972 16.1132 22.0072 16.6219C21.5394 17.4405 21.8902 18.2591 22.7088 18.61C23.1895 18.8158 24.2917 18.8 24.4004 20C24.4854 20.9384 22.9427 21.0658 22.8258 22.3522C22.7088 23.6385 21.7861 24.2092 21.3698 25.2103C20.9535 26.2113 20.7945 26.7656 20.2951 27.417C19.6227 28.2929 18.4111 28.7139 17.3399 28.4437C16.94 28.342 16.5553 28.1479 16.2769 27.8427C15.4478 26.9363 15.8138 25.512 15.6712 24.2911C15.5554 23.3088 15.0584 22.3755 14.3088 21.7312C13.4867 21.0248 12.4201 20.6857 11.4764 20.1536C10.5327 19.6215 9.97718 18.8743 9.96314 17.7902C9.94911 16.6956 10.3432 15.3706 11.3665 14.9835C12.3897 14.5965 14.5146 14.4842 15.5764 14.7497C16.9798 15.1005 18.8696 15.2128 19.6695 14.1649C20.0051 13.7252 20.0893 12.9978 19.5525 12.5277C18.617 11.7091 17.3306 12.7616 16.395 12.4108C15.7027 12.1512 15.5308 10.9923 14.407 11.0075C13.2832 11.0227 12.5827 11.0776 11.8342 11.8261C11.1326 12.5277 9.76785 12.5137 9.37843 11.4752C9.0276 10.5397 9.72458 10.0672 10.197 9.72108C10.6695 9.37493 10.9919 8.91723 11.131 8.34771C11.207 8.03547 11.0156 6.68055 11.9512 6.21278C13.4048 5.48656 13.5089 7.73304 14.7578 7.26527C15.6934 6.91444 15.4314 5.96954 15.6934 5.51112C16.1612 4.69251 16.7389 4.59311 17.3306 4.34168C18.5866 3.80725 19.4239 3.31141 20.0226 2.04608C25.9891 3.65873 30.4575 8.91651 30.8902 15.2841C30.8902 15.2841 29.1419 13.6972 28.6741 13.4633L28.6729 13.4644Z"
          fill="#E3F8E5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.71875 10.1865C2.74331 10.1842 2.76904 10.183 2.79359 10.1795C4.01331 10.0228 5.03189 9.91287 5.98732 9.13753C6.95678 8.3505 7.50993 7.28982 7.85842 6.33089C8.10049 5.66431 7.71341 5.0679 7.33334 4.46564C5.35232 5.94614 3.75604 7.91196 2.71992 10.1865H2.71875Z"
          fill="#E3F8E5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.93305 22.703C4.78219 21.9756 4.61379 21.4084 4.34833 20.715C4.04311 19.9198 4.14017 18.9222 4.12263 18.0709C4.11445 17.6744 3.9975 16.7389 3.64667 16.2711C3.0409 15.463 2.47724 14.9847 1.50427 14.5064C1.43293 15.0947 1.39551 15.6922 1.39551 16.3004C1.39551 19.8344 2.63862 23.0784 4.70969 25.6208C4.70969 25.6208 4.7705 25.5401 4.88861 25.3787C5.4628 24.5612 5.13653 23.6807 4.93305 22.703Z"
          fill="#E3F8E5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M26.5639 4.03035C28.091 1.32322 31.909 1.32322 33.4361 4.03034L45.4625 25.349C46.9897 28.0561 45.0807 31.44 42.0264 31.44H17.9736C14.9193 31.44 13.0103 28.0561 14.5375 25.349L26.5639 4.03035Z"
        fill="var(--accent-yellow)"
      />
      <path
        d="M27.4349 4.52168C28.5794 2.49277 31.4206 2.49277 32.5651 4.52168L44.5915 25.8403C45.7622 27.9154 44.276 30.44 42.0264 30.44H17.9736C15.724 30.44 14.2378 27.9154 15.4085 25.8403L27.4349 4.52168Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M29.6195 24.314C28.7823 24.314 28.0957 23.6777 28.0957 22.8739C28.0957 22.0534 28.7823 21.4171 29.6195 21.4171C30.4568 21.4171 31.1433 22.0534 31.1433 22.8739C31.1433 23.6777 30.4568 24.314 29.6195 24.314ZM28.6483 20.4291L28.2799 12.1905H30.9926L30.6242 20.4291H28.6483Z"
        fill="black"
      />
      <defs>
        <clipPath id="clip0_863_1110">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
