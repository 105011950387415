type Success<T> = { type: "success"; value: T };

export type Status<T> =
  | { type: "loading" }
  | { type: "error"; error: unknown }
  | Success<T>;

export const loading: Status<never> = { type: "loading" };

export function error(error: unknown): Status<never> {
  return { type: "error", error };
}

export function success<T>(value: T): Status<T> {
  return { type: "success", value };
}

export function flattenStatuses<T extends Array<unknown>>(statuses: {
  [K in keyof T]: Status<T[K]>;
}): Status<T> {
  for (const status of statuses) {
    if (status.type === "error") {
      return status;
    }
  }
  for (const status of statuses) {
    if (status.type === "loading") {
      return loading;
    }
  }
  return success(
    statuses.map((status) => {
      if (status.type === "success") {
        return status.value;
      } else {
        throw new Error("unexpected type: " + status.type);
      }
    })
  ) as Status<T>;
}

export function map<T, R>(status: Status<T>, func: (value: T) => R): Status<R> {
  if (status.type === "success") {
    return success(func(status.value));
  } else {
    return status;
  }
}

export function valueOrElse<T>(status: Status<T>, orElse: T): T {
  if (status.type === "success") {
    return status.value;
  } else {
    return orElse;
  }
}

export function isSuccess<T>(status: Status<T>): status is Success<T> {
  return status.type === "success";
}
