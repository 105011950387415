import "./Tag.css";

interface TagProps {
  text: React.ReactNode;
}

export function Tag(props: TagProps) {
  const { text } = props;
  return (
    <div className="TagContainer">
      <div className="TagTextContainer small-copy">{text}</div>
      <div className="TagArrow"></div>
    </div>
  );
}
