import { FormattedMessage } from "react-intl";

import UserVisibleError from "../../util/UserVisibleError";

export const FailedToParseError = (
  <FormattedMessage
    id="components/recipes/recipes-json-errors:failedToParseErrorMessage"
    defaultMessage="Failed to parse JSON file."
  />
);

export class MissingFieldError extends UserVisibleError {
  columnName: string;
  rowIndex: number;

  constructor(columnName: string, rowIndex: number) {
    super(
      `Unable to find ${columnName} field on product with index ${rowIndex}.`,
      <FormattedMessage
        id="components/recipes/recipes-json-errors:missingFieldErrorMessage"
        defaultMessage="Unable to find <b>{columnName}</b> field on product with <b>index {rowIndex}</b>."
        values={{
          columnName,
          rowIndex,
          b: (chunks: React.ReactNode) => (
            <span className="semi-bold-font">{chunks}</span>
          ),
        }}
      />
    );
    this.columnName = columnName;
    this.rowIndex = rowIndex;
  }
}

export class UnexpectedDataTypeError extends UserVisibleError {
  columnName: string;
  expectedDataType: string;
  rowIndex: number;

  constructor(columnName: string, expectedDataType: string, rowIndex: number) {
    super(
      `Unexpected data type for ${columnName} field on product with index ${rowIndex}. Expected data of type ${expectedDataType}.`,
      <FormattedMessage
        id="components/recipes/recipes-json-errors:unexpectedDataTypeMessage"
        defaultMessage="Unexpected data type for <b>{columnName}</b> field on product with <b>index {rowIndex}</b>. Expected data of type <b>{expectedDataType}</b>."
        values={{
          columnName,
          expectedDataType,
          rowIndex,
          b: (chunks: React.ReactNode) => (
            <span className="semi-bold-font">{chunks}</span>
          ),
        }}
      />
    );
    this.columnName = columnName;
    this.expectedDataType = expectedDataType;
    this.rowIndex = rowIndex;
  }
}

export class MissingFieldOnNamedRecipeError extends UserVisibleError {
  columnName: string;
  recipeName: string;
  rowIndex: number;

  constructor(columnName: string, recipeName: string, rowIndex: number) {
    super(
      `Unable to find ${columnName} field on product ${recipeName} with index ${rowIndex}.`,
      <FormattedMessage
        id="components/recipes/recipes-json-errors:missingFieldOnNamedRecipeErrorMessage"
        defaultMessage="Unable to find <b>{columnName}</b> field on product <b>{recipeName}</b> with <b>index {rowIndex}</b>."
        values={{
          columnName,
          recipeName,
          rowIndex,
          b: (chunks: React.ReactNode) => (
            <span className="semi-bold-font">{chunks}</span>
          ),
        }}
      />
    );
    this.columnName = columnName;
    this.recipeName = recipeName;
    this.rowIndex = rowIndex;
  }
}
