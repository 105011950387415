import { useState } from "react";

import FileInputButton from "./FileInputButton";

const stories = {
  name: "FileInputButton",
  children: [
    {
      name: "all file types",
      render: () => <Story />,
    },
    {
      name: "accept .csv files only",
      render: () => <Story accept={".csv"} />,
    },
  ],
};

interface StoryProps {
  accept?: string;
}

function Story(props: StoryProps) {
  const { accept } = props;
  const [fileName, setFileName] = useState<string | null>(null);

  function onChange(file: File) {
    setFileName(file.name);
  }

  return (
    <>
      <FileInputButton onChange={onChange} accept={accept} />
      <p>{fileName === null ? "No file." : `File name: ${fileName}`}</p>
    </>
  );
}

export default stories;
