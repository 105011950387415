import gql from "graphql-tag";
import { useMemo } from "react";

import * as comparators from "../../util/comparators";
import sort from "../../util/sort";
import Select from "../utils/Select";
import { TransportModeSelect_TransportMode as TransportMode } from "./TransportModeSelect.graphql";

interface TransportModeSelectProps {
  hasError: boolean;
  onChange: (value: TransportMode | null) => void;
  options: Array<TransportMode>;
  placeholder?: string;
  value: TransportMode | null;
}

export type { TransportMode };

export function TransportModeSelect(props: TransportModeSelectProps) {
  const { hasError, onChange, options, placeholder, value } = props;

  const sortedOptions = useMemo(
    () =>
      sort(
        options,
        comparators.map(
          (option) => option.name,
          comparators.stringSensitivityBase
        )
      ),
    [options]
  );

  return (
    <Select
      hasError={hasError}
      onChange={(value) => onChange(value)}
      optionKey={transportModeKey}
      options={sortedOptions}
      placeholder={placeholder}
      renderOption={(transportMode) => transportMode.name}
      value={value}
    />
  );
}

function transportModeKey(transportMode: TransportMode) {
  return transportMode.id.toString();
}

export const fragments = {
  transportMode: gql`
    fragment TransportModeSelect_TransportMode on TransportMode {
      id
      name
    }
  `,
};
