import * as graphql from "graphql";
import gql from "graphql-tag";
import { Dictionary, fromPairs } from "lodash";

import {
  AddRecipeInput,
  LinkIngredientsToFoodClassInput,
  UpdateRecipeInput,
} from "../../__generated__/globalTypes";
import { useGraphQL } from "./GraphQLProvider";

export interface MutationField<TOutput> {
  fieldName: string;
  input: object;
  outputFragment: graphql.DocumentNode;
  // Use flavouring here instead of branding.
  // Because branding utilises a non-nullable field, it
  // won't work for our case because we wouldn't always know
  // at compile time what the brand value needs to be.
  // Flavouring provides us the same safety from coercion between
  // otherwise structurally identical types as branding, and only
  // has the downside of allowing downcasting to the type without
  // the nullable field. For this use-case, that should not mean
  // any loss of type safety.
  __flavour?: TOutput;
}

interface _MutationField {
  alias: string;
  fieldName: string;
  fragment: string;
  fragmentName: string;
  input: object;
  inputName: string;
  inputTypeName: string;
}

export function useExecuteCompoundMutation() {
  const graphQL = useGraphQL();

  return async <TOutput,>(
    mutationFields: Array<MutationField<TOutput>>
  ): Promise<Array<TOutput>> => {
    const fields = generateFields(mutationFields);
    const query = gql(generateQuery(fields));
    const variables = generateVariables(fields);

    const response = await graphQL.fetch<any, {}>({ query, variables });

    return fields.map((field) => response[field.alias]);
  };
}

export function generateFields<_T>(
  fields: Array<MutationField<_T>>
): Array<_MutationField> {
  return fields.map((mutationField, mutationFieldIndex) => ({
    alias: `${mutationField.fieldName}${mutationFieldIndex + 1}`,
    fieldName: mutationField.fieldName,
    fragment: graphql.print(mutationField.outputFragment),
    fragmentName: findFragmentName(mutationField.outputFragment),
    input: mutationField.input,
    inputName: `${mutationField.fieldName}Input${mutationFieldIndex + 1}`,
    inputTypeName:
      mutationField.fieldName.substring(0, 1).toUpperCase() +
      mutationField.fieldName.substring(1) +
      "Input",
  }));
}

export function generateVariables(
  fields: Array<_MutationField>
): Dictionary<object> {
  return fromPairs(fields.map((field) => [field.inputName, field.input]));
}

export function generateQuery(fields: Array<_MutationField>): string {
  const inputParams = generateInputParams(fields);
  const serializedFields = generateSerializedMutationFields(fields);

  return (
    "mutation(" +
    inputParams +
    ") {\n" +
    serializedFields +
    "}" +
    fields.map((field) => "\n" + field.fragment).join("")
  );
}

function generateInputParams(fields: Array<_MutationField>): string {
  return fields
    .map((field) => `$${field.inputName}: ${field.inputTypeName}`)
    .join(", ");
}

function generateSerializedMutationFields(
  fields: Array<_MutationField>
): Array<string> {
  return fields.map(
    (field) =>
      `${field.alias}: ${field.fieldName}(input: $${field.inputName}) { ...${field.fragmentName} }\n`
  );
}

function findFragmentName(node: graphql.DocumentNode): string {
  if (node.definitions.length !== 1) {
    throw new Error("expected exactly one definition");
  }

  const definition = node.definitions[0];

  if (definition.kind !== "FragmentDefinition") {
    throw new Error("expected fragment definition");
  }

  return definition.name.value;
}

export function updateRecipe<TOutput>({
  input,
  outputFragment,
}: {
  input: UpdateRecipeInput;
  outputFragment: graphql.DocumentNode;
}): MutationField<TOutput> {
  return {
    fieldName: "updateRecipe",
    input,
    outputFragment,
  };
}

export function addRecipe<TOutput>({
  input,
  outputFragment,
}: {
  input: AddRecipeInput;
  outputFragment: graphql.DocumentNode;
}): MutationField<TOutput> {
  return {
    fieldName: "addRecipe",
    input,
    outputFragment,
  };
}

export function linkIngredientsToFoodClass<TOutput>({
  input,
  outputFragment,
}: {
  input: LinkIngredientsToFoodClassInput;
  outputFragment: graphql.DocumentNode;
}): MutationField<TOutput> {
  return {
    fieldName: "linkIngredientsToFoodClass",
    input,
    outputFragment,
  };
}
