import classNames from "classnames";
import { LocationDescriptorObject } from "history";
import isString from "lodash/isString";
import React, { CSSProperties, MouseEventHandler } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { SetRequired } from "../../util/types";
import "./Button.css";
import { Plus } from "./Vectors";
import Add from "./Vectors/Add";

type VariantButtonProps = Omit<ButtonProps, "variant">;

export function DangerButton(props: VariantButtonProps) {
  return <Button {...props} variant="danger" />;
}

export function PrimaryButton(props: VariantButtonProps) {
  return <Button {...props} variant="primary" />;
}

export type SecondaryButtonProps = VariantButtonProps;

export function SecondaryButton(props: SecondaryButtonProps) {
  return <Button {...props} variant="secondary" />;
}

export function PlusButton(
  props: Omit<VariantButtonProps, "children" | "icon">
) {
  return (
    <SecondaryButton
      {...props}
      className={classNames(["pl-3 pr-3", props.className])}
      icon={<Plus width={"20px"} />}
    >
      <FormattedMessage
        id="components/utils/Button:PlusButton"
        defaultMessage="Add"
      />
    </SecondaryButton>
  );
}

export function LinkButton(props: VariantButtonProps) {
  return (
    <Button
      {...props}
      className={classNames(["medium-font LinkButton", props.className])}
      variant="link"
    />
  );
}

interface ButtonStyleProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  size?: "sm";
  variant: "primary" | "secondary" | "light" | "danger" | "link";
}

export interface ButtonProps extends ButtonStyleProps {
  onClick?: MouseEventHandler<HTMLElement>;
  type?: "button" | "submit";
}

export function Button(props: ButtonProps) {
  const { children, disabled, icon, loading, onClick, type = "button" } = props;

  const className = buttonClassName(props);

  return (
    <button
      className={classNames("medium-font", className)}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {loading ? (
        <>
          <Spinner animation="border" size="sm" />{" "}
        </>
      ) : icon ? (
        <>{icon} </>
      ) : null}
      {children}
    </button>
  );
}

export interface LightButtonProps
  extends Pick<ButtonProps, "children" | "className" | "onClick"> {
  icon?: React.ReactNode;
}

export function LightButton(props: LightButtonProps) {
  const { children, className, icon, onClick } = props;

  return (
    <Button
      className={classNames("medium-font LightButton p-0", className)}
      icon={icon}
      onClick={onClick}
      variant="light"
    >
      {children}
    </Button>
  );
}

export interface AddButtonProps
  extends Pick<ButtonProps, "children" | "className" | "onClick"> {}

export function AddButton(props: AddButtonProps) {
  return <LightButton {...props} icon={<Add width={"1em"} />} />;
}

export type IconButtonProps = SetRequired<
  Pick<ButtonProps, "className" | "disabled" | "icon" | "onClick">,
  "icon"
> &
  Pick<CSSProperties, "display">;

export function IconButton(props: IconButtonProps) {
  const {
    className,
    disabled = false,
    display = "inline",
    icon,
    onClick,
  } = props;

  return (
    <div
      aria-pressed="false"
      aria-disabled={disabled ? "true" : "false"}
      className={classNames(className, {
        IconButton__disabled: disabled,
      })}
      onClick={onClick}
      role="button"
      style={{ display }}
      tabIndex={disabled ? -1 : undefined}
    >
      {icon}
    </div>
  );
}

type PrimaryButtonLinkProps = Omit<ButtonLinkProps, "variant">;

export function PrimaryButtonLink(props: PrimaryButtonLinkProps) {
  return <ButtonLink {...props} variant="primary" />;
}

type SecondaryButtonLinkProps = Omit<ButtonLinkProps, "variant">;

export function SecondaryButtonLink(props: SecondaryButtonLinkProps) {
  return <ButtonLink {...props} variant="secondary" />;
}

export interface ButtonLinkProps extends ButtonStyleProps {
  external?: boolean;
  to: string | LocationDescriptorObject;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export function ButtonLink(props: ButtonLinkProps) {
  const { children, disabled = false, external, to, icon, target } = props;

  const className = buttonClassName(props);

  const body = (
    <div
      className="medium-font d-flex flex-row"
      style={{ gap: "8px", alignItems: "center" }}
    >
      {icon ? icon : null}
      {children}
    </div>
  );

  const rel = target === "_blank" ? "noopener noreferrer" : undefined;
  const accessibilityProps = disabled
    ? {
        "aria-disabled": true,
        tabIndex: -1,
        onClick: (e: { preventDefault: () => void }) => {
          e.preventDefault();
        },
      }
    : {};

  if (external) {
    if (!isString(to)) {
      throw new Error("to prop must be string for external links");
    }
    return (
      <a
        className={className}
        href={to}
        target={target}
        rel={rel}
        {...accessibilityProps}
      >
        {body}
      </a>
    );
  } else {
    return (
      <Link
        className={className}
        to={to}
        target={target}
        rel={rel}
        {...accessibilityProps}
      >
        {body}
      </Link>
    );
  }
}

function buttonClassName(props: ButtonStyleProps): string {
  const { className, disabled = false, fullWidth, size, variant } = props;

  return classNames(
    "btn",
    `btn-${variant}`,
    { "w-100": fullWidth },
    size == null ? null : `btn-${size}`,
    disabled ? "disabled" : null,
    className
  );
}

interface TrustedExternalLinkProps {
  children: React.ReactNode;
  to: string;
}

export function TrustedExternalLink(props: TrustedExternalLinkProps) {
  const { children, to } = props;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={to} target="_blank" rel="noopener">
      {children}
    </a>
  );
}
