import { useState } from "react";

import TagMultiSelect, {
  TagMultiSelectOption,
  TagMultiSelectOptionType,
} from "./TagMultiSelect";

const stories = {
  name: "TagMultiSelect",
  render: () => <Story />,
};

const options: Array<TagMultiSelectOption> = [
  {
    type: TagMultiSelectOptionType.SIMPLE,
    id: 1,
    name: "Simple Tag",
  },
  {
    type: TagMultiSelectOptionType.PARTIAL,
    id: 2,
    name: "Partial Tag",
    onAdd: () => alert("Add Partial Tag"),
    productCount: 12,
  },
  { type: TagMultiSelectOptionType.ALL, id: 3, name: "All Selected Tag" },
  {
    type: TagMultiSelectOptionType.PARTIAL,
    id: 4,
    name: "Partial Tag 2",
    onAdd: () => alert("Add Partial Tag 2"),
    productCount: 5,
  },
  {
    type: TagMultiSelectOptionType.ALL,
    id: 5,
    name: "Tag With a Very Very Very Very Very Very Very Very Very Very Very Very Very Very Very Very Long Name",
  },
  {
    type: TagMultiSelectOptionType.ALL,
    id: 6,
    name: "Another Tag With a Very Very Very Very Very Very Very Very Very Very Very Very Very Very Very Very Long Name",
  },
];

const extraOptions: Array<TagMultiSelectOption> = [
  7, 8, 9, 10, 11, 12, 13, 14,
].map((i) => {
  return { type: TagMultiSelectOptionType.ALL, id: i, name: `Tag ${i}` };
});

function Story() {
  const [value, setValue] = useState<ReadonlyArray<number>>([]);
  return (
    <div className="m-3">
      <TagMultiSelect
        options={[...options, ...extraOptions]}
        onNewTagClicked={() => alert("New tag clicked")}
        onChange={setValue}
        selectedIds={value}
      />
    </div>
  );
}

export default stories;
