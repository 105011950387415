import descriptiveImpactAndScaleLabelStories from "./descriptive-impact-and-scale/stories";
import descriptiveRatingScaleLabelStories from "./descriptive-rating-scale/stories";
import descriptiveRatingLabelStories from "./descriptive-rating/stories";
import letterRatingLabelStories from "./letter-rating/stories";
import ratingScaleLabelStories from "./rating-scale/stories";
import titledRatingScaleLabelStories from "./titled-rating-scale/stories";

const stories = {
  name: "Colour",
  children: [
    descriptiveImpactAndScaleLabelStories,
    descriptiveRatingLabelStories,
    descriptiveRatingScaleLabelStories,
    letterRatingLabelStories,
    ratingScaleLabelStories,
    titledRatingScaleLabelStories,
  ],
};

export default stories;
