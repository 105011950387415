import classNames from "classnames";

interface NumberProps {
  children: React.ReactNode;
  className?: string;
}

export default function Number(props: NumberProps) {
  const { children, className = "" } = props;
  return <div className={classNames(className, "Number")}>{children}</div>;
}
