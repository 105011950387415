import { gql } from "graphql-tag";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  CookingImpactUnitLabel,
  CookingPenaltyInput,
  UpdateRecipeInput,
} from "../../../__generated__/globalTypes";
import assertNever from "../../../util/assertNever";
import * as comparators from "../../../util/comparators";
import sort from "../../../util/sort";
import { PickRequiredNonNullable } from "../../../util/types";
import Checkbox from "../../utils/Checkbox";
import * as FloatInput from "../../utils/FloatInput";
import RadioButtons from "../../utils/RadioButtons";
import ReadResult from "../../utils/ReadResult";
import Select from "../../utils/Select";
import TableEditor from "../../utils/TableEditor";
import useRecipeLabel from "../useRecipeLabel";
import {
  RecipeCookingPenaltiesEditor_CookingImpact as CookingImpact,
  RecipeCookingPenaltiesEditor_CookingPenalty as CookingPenalty,
  RecipeCookingPenaltiesEditor_Recipe as Recipe,
} from "./RecipeCookingPenaltiesEditor.graphql";

type AccurateValue = {
  type: "accurate cooking impacts";
  cookingPenalties: Array<EditorCookingPenalty>;
  isCooked: boolean;
  invalidCookingPenalties: boolean;
};

type EstimatedValue = {
  type: "estimated cooking impacts";
  isCooked: boolean;
};

export type Value = AccurateValue | EstimatedValue;

interface EditorCookingPenalty {
  key: string;
  cookingImpact: CookingImpact | null;
  existingCookingPenalty: CookingPenalty | null;
  quantity: FloatInput.Value;
  invalidCookingImpact: boolean;
}

let nextNewPenaltyId = 1;

function blankPenalty(): EditorCookingPenalty {
  return {
    key: "new_" + nextNewPenaltyId++,
    cookingImpact: null,
    existingCookingPenalty: null,
    quantity: FloatInput.initialValue(null),
    invalidCookingImpact: false,
  };
}

export function initialValue(
  recipe: Recipe | undefined,
  accurateCookingImpacts: boolean,
  isFoodManufacturerOrganization: boolean
): Value {
  if (isFoodManufacturerOrganization) {
    if (accurateCookingImpacts) {
      return {
        type: "accurate cooking impacts",
        cookingPenalties: [],
        isCooked: false,
        invalidCookingPenalties: false,
      };
    } else {
      return {
        type: "estimated cooking impacts",
        isCooked: false,
      };
    }
  }

  if (accurateCookingImpacts) {
    if (recipe === undefined) {
      return {
        type: "accurate cooking impacts",
        cookingPenalties: [blankPenalty()],
        isCooked: true,
        invalidCookingPenalties: false,
      };
    } else {
      const cookingPenalties =
        recipe.cookingPenalties.length > 0
          ? recipe.cookingPenalties.map((penalty) => ({
              key: "existing_" + penalty.id,
              cookingImpact: penalty.cookingImpact,
              existingCookingPenalty: penalty,
              quantity: FloatInput.initialValue(penalty.quantity),
              invalidCookingImpact: false,
            }))
          : [blankPenalty()];
      return {
        type: "accurate cooking impacts",
        cookingPenalties,
        // if the recipe's initial state was incomplete we don't want the user to be able to save it
        // without considering whether to add cooking penalties
        // so make isCooked true to cause a validation error by default
        isCooked:
          !recipe.hasCompleteCookingPenalties ||
          recipe.cookingPenalties.length > 0,
        invalidCookingPenalties: false,
      };
    }
  } else {
    return {
      type: "estimated cooking impacts",
      // when using cooking estimator, isCooked is persisted as !recipe.hasCompleteCookingPenalties in the backend
      isCooked:
        recipe === undefined ? true : !recipe.hasCompleteCookingPenalties,
    };
  }
}

type ReadCookingPenaltiesResult = ReadResult<
  Value,
  PickRequiredNonNullable<UpdateRecipeInput, "cookingPenalties" | "isCooked">
>;

export function readAccurateCookingPenalties(
  value: AccurateValue
): ReadCookingPenaltiesResult {
  let hasError = false;
  const cookingPenaltyInputs: Array<CookingPenaltyInput> = [];

  const newValue: Value = {
    type: "accurate cooking impacts",
    cookingPenalties: [],
    isCooked: value.isCooked,
    invalidCookingPenalties: false,
  };

  if (!value.isCooked) {
    return {
      value: newValue,
      input: {
        cookingPenalties: [],
        isCooked: false,
      },
      hasError: false,
    };
  }

  for (const penalty of value.cookingPenalties) {
    const quantity = FloatInput.read({ value: penalty.quantity });

    if (penalty.cookingImpact === null) {
      newValue.cookingPenalties.push({
        ...penalty,
        invalidCookingImpact: true,
        quantity: quantity.value,
      });
      hasError = true;
    } else {
      newValue.cookingPenalties.push({
        ...penalty,
        quantity: quantity.value,
      });

      if (quantity.hasError) {
        hasError = true;
      } else {
        cookingPenaltyInputs.push({
          cookingImpactId: penalty.cookingImpact.id,
          id:
            penalty.existingCookingPenalty === null
              ? null
              : penalty.existingCookingPenalty.id,
          quantity: quantity.input,
        });
      }
    }
  }

  const validCookingPenalties = cookingPenaltyInputs.length > 0;

  if (!validCookingPenalties) {
    hasError = true;
    newValue.invalidCookingPenalties = true;
  }

  return {
    hasError,
    value: newValue,
    input: {
      cookingPenalties: cookingPenaltyInputs,
      isCooked: cookingPenaltyInputs.length > 0,
    },
  };
}

function readEstimatedCookingPenalties(
  value: EstimatedValue
): ReadCookingPenaltiesResult {
  return {
    hasError: false,
    value,
    input: {
      cookingPenalties: [],
      isCooked: value.isCooked,
    },
  };
}

export function read(value: Value): ReadCookingPenaltiesResult {
  const { type } = value;
  if (type === "accurate cooking impacts") {
    return readAccurateCookingPenalties(value);
  } else if (type === "estimated cooking impacts") {
    return readEstimatedCookingPenalties(value);
  } else {
    assertNever(type, "invalid type");
  }
}

interface RecipeCookingPenaltiesEditorProps {
  cookingImpacts: Array<CookingImpact>;
  onChange: (value: Value) => void;
  value: Value;
}

export function RecipeCookingPenaltiesEditor(
  props: RecipeCookingPenaltiesEditorProps
) {
  const { cookingImpacts, onChange, value } = props;

  const resetCookingPenaltiesErrorState = (
    cookingPenalties: Array<EditorCookingPenalty>
  ) => {
    return cookingPenalties.map((penalty) => {
      return {
        ...penalty,
        invalidCookingImpact: false,
        quantity: { ...penalty.quantity, isInvalid: false },
      };
    });
  };

  const handleHasIsCookedChange = (value: AccurateValue, isCooked: boolean) => {
    onChange({
      ...value,
      isCooked,
      cookingPenalties: resetCookingPenaltiesErrorState(value.cookingPenalties),
      invalidCookingPenalties: false,
    });
  };

  const handleCookingPenaltyChange = (
    value: AccurateValue,
    cookingPenalties: Array<EditorCookingPenalty>
  ) => {
    const newValue: AccurateValue = {
      ...value,
      cookingPenalties: resetCookingPenaltiesErrorState(cookingPenalties),
      invalidCookingPenalties: false,
    };
    onChange(newValue);
  };

  if (value.type === "accurate cooking impacts") {
    return (
      <div className="w-75">
        <div className="mb-3">
          <RecipeIsCookedCheckbox
            className={value.invalidCookingPenalties ? "is-invalid" : ""}
            isCooked={value.isCooked}
            onChange={(isCooked) => handleHasIsCookedChange(value, isCooked)}
          />
        </div>

        {value.isCooked && (
          <TableEditor
            blank={blankPenalty}
            onChange={(cookingPenalties) =>
              handleCookingPenaltyChange(value, cookingPenalties)
            }
            renderRow={({ onChange, onDelete, rowIndex, value }) => (
              <RecipeCookingPenaltyEditor
                canDelete={rowIndex !== 0 || cookingImpacts.length > 1}
                cookingImpacts={cookingImpacts}
                onChange={onChange}
                onDelete={onDelete}
                value={value}
              />
            )}
            showAddButton={true}
            value={value.cookingPenalties}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="mb-3">
        <RecipeIsCookedRadioButtons
          onChange={(isCooked) => onChange({ ...value, isCooked })}
          isCooked={value.isCooked}
        />
      </div>
    );
  }
}

interface RecipeIsCookedRadioButtonsProps {
  isCooked: boolean;
  onChange: (isCooked: boolean) => void;
}

function RecipeIsCookedRadioButtons(props: RecipeIsCookedRadioButtonsProps) {
  const { isCooked, onChange } = props;

  const intl = useIntl();

  const yes = intl.formatMessage({
    defaultMessage: "Yes",
    id: "components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:yes",
  });

  const no = intl.formatMessage({
    defaultMessage: "No",
    id: "components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:no",
  });

  function renderIsCooked(isCooked: boolean) {
    return isCooked ? yes : no;
  }

  return (
    <div id="isCookedEditor">
      <label>
        <FormattedMessage
          defaultMessage="Does the preparation of this product involve cooking?"
          id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:isRecipeCooked"
        />
      </label>
      <br />
      <RadioButtons
        inline
        onChange={onChange}
        optionKey={(answer) => renderIsCooked(answer)}
        options={[true, false]}
        renderOptionLabel={(answer) => renderIsCooked(answer)}
        value={isCooked}
      />
    </div>
  );
}

interface RecipeIsCookedCheckboxProps {
  className?: string;
  isCooked: boolean;
  onChange: (isCooked: boolean) => void;
}

function RecipeIsCookedCheckbox(props: RecipeIsCookedCheckboxProps) {
  const { className, isCooked, onChange } = props;

  const recipeLabel = useRecipeLabel();

  return (
    <Checkbox
      id="isCookedEditor"
      className={className}
      defaultChecked={!isCooked}
      label={
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor/RecipeIsCookedCheckbox:label"
          defaultMessage="This {recipeLabel} is not cooked"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      }
      onChange={(notIsCooked) => onChange(!notIsCooked)}
    />
  );
}

interface RecipeCookingPenaltyEditorProps {
  canDelete: boolean;
  cookingImpacts: Array<CookingImpact>;
  onChange: (value: EditorCookingPenalty) => void;
  onDelete: () => void;
  value: EditorCookingPenalty;
}

function RecipeCookingPenaltyEditor(props: RecipeCookingPenaltyEditorProps) {
  const { canDelete, cookingImpacts, onChange, onDelete, value } = props;

  const deleteColumnWidth = "20px";

  return (
    <tr>
      <td style={{ width: "384px" }}>
        <CookingImpactSelect
          isInvalid={value.invalidCookingImpact}
          cookingImpacts={cookingImpacts}
          onChange={(cookingImpact) => onChange({ ...value, cookingImpact })}
          value={value.cookingImpact}
        />
      </td>
      <td className="pl-3" style={{ width: "160px" }}>
        <div className="input-group">
          <FloatInput.FloatInput
            onChange={(quantity) => onChange({ ...value, quantity })}
            value={value.quantity}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {value.cookingImpact?.unitLabel ?? CookingImpactUnitLabel.mins}
            </span>
          </div>
        </div>
      </td>
      {canDelete ? (
        <TableEditor.DeleteCell
          className="pl-2"
          onDelete={onDelete}
          width={deleteColumnWidth}
        />
      ) : (
        <td style={{ width: deleteColumnWidth }}></td>
      )}
    </tr>
  );
}

interface CookingImpactSelectProps {
  isInvalid: boolean;
  cookingImpacts: Array<CookingImpact>;
  onChange: (value: CookingImpact | null) => void;
  value: CookingImpact | null;
}

function CookingImpactSelect(props: CookingImpactSelectProps) {
  const { cookingImpacts, isInvalid, onChange, value } = props;

  const sortedCookingImpacts = useMemo(
    () =>
      sort(
        cookingImpacts,
        comparators.map(
          (impact) => impact.type,
          comparators.stringSensitivityBase
        )
      ),
    [cookingImpacts]
  );

  return (
    <Select
      className={isInvalid ? "is-invalid" : ""}
      onChange={(value) => onChange(value)}
      optionKey={cookingImpactKey}
      options={sortedCookingImpacts}
      renderOption={(cookingImpact) => cookingImpact.type}
      value={value}
    />
  );
}

function cookingImpactKey(cookingImpact: CookingImpact) {
  return cookingImpact.id.toString();
}

const cookingImpactFragment = gql`
  fragment RecipeCookingPenaltiesEditor_CookingImpact on CookingImpact {
    id
    type
    unit
    unitLabel
  }
`;

export const fragments = {
  cookingImpact: cookingImpactFragment,

  recipe: gql`
    fragment RecipeCookingPenaltiesEditor_Recipe on Recipe {
      cookingPenalties {
        ...RecipeCookingPenaltiesEditor_CookingPenalty
      }
      hasCompleteCookingPenalties
    }

    fragment RecipeCookingPenaltiesEditor_CookingPenalty on CookingPenalty {
      cookingImpact {
        ...RecipeCookingPenaltiesEditor_CookingImpact
      }
      id
      quantity
    }

    ${cookingImpactFragment}
  `,
};
