interface ProgressBarProps {
  backgroundColour: string;
  className?: string;
  colour: string;
  style: React.CSSProperties;
  value: number;
}

export function ProgressBar(props: ProgressBarProps) {
  const { backgroundColour, colour, className, style, value } = props;

  const containerStyles = {
    backgroundColor: backgroundColour,
    borderRadius: 50,
    height: 10,
    ...style,
  };

  const fillerStyles: React.CSSProperties = {
    transition: "width 0.7s ease-in-out",
    height: "100%",
    width: `${value * 100}%`,
    backgroundColor: colour,
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div className={className} style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
}
