interface Page<T> {
  edges: Array<{ node: T }>;
  pageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
  };
}

type FetchPage<T> = (options: { after: string | null }) => Promise<Page<T>>;

export async function* fetchPages<T>({
  fetchPage,
}: {
  fetchPage: FetchPage<T>;
}) {
  let after: string | null = null;

  while (true) {
    const response: Page<T> = await fetchPage({
      after,
    });

    yield response.edges.map((edge) => edge.node);

    if (response.pageInfo.hasNextPage) {
      after = response.pageInfo.endCursor;
    } else {
      return;
    }
  }
}

export async function fetchAll<T>({
  fetchPage,
}: {
  fetchPage: FetchPage<T>;
}): Promise<Array<T>> {
  const result: Array<T> = [];

  for await (const page of fetchPages({ fetchPage })) {
    for (const value of page) {
      result.push(value);
    }
  }

  return result;
}
