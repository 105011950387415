interface PlainTextProps {
  value: string;
}

export default function PlainText(props: PlainTextProps) {
  const { value } = props;
  const normalisedValue = collapseNewLines(normaliseNewLines(value));
  return (
    <>
      {normalisedValue.split("\n\n").map((line, lineIndex) => (
        <p key={lineIndex}>{line}</p>
      ))}
    </>
  );
}

function normaliseNewLines(value: string) {
  return value.replace(/\r\n|\r/g, "\n");
}

function collapseNewLines(value: string) {
  return value.replace(/\n{3,}/g, "\n\n");
}
