import classNames from "classnames";
import { gql } from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";

import * as FloatInput from "../../utils/FloatInput";
import ReadResult from "../../utils/ReadResult";
import useId from "../../utils/useId";
import useRecipeLabel from "../useRecipeLabel";
import RecipeEditorLabel from "./RecipeEditorLabel";
import { RecipeServingsEditor_Recipe as Recipe } from "./RecipeServingsEditor.graphql";

export const defaultLabel = (
  <FormattedMessage
    id="components/recipes/RecipeEditor/RecipeServingsEditor:label"
    defaultMessage="Servings"
  />
);

export interface Value {
  hasError: boolean;
  serves: FloatInput.Value;
}

interface RecipeServingsEditorProps {
  label?: React.ReactNode;
  onChange: (value: Value) => void;
  value: Value;
}

export function RecipeServingsEditor(props: RecipeServingsEditorProps) {
  const { label, onChange, value } = props;

  const id = useId();
  const recipeLabel = useRecipeLabel();

  return (
    <div className="form-group">
      <RecipeEditorLabel htmlFor={id}>
        {label ?? defaultLabel}
      </RecipeEditorLabel>
      <p className="text-muted">
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeServingsEditor:helpText"
          defaultMessage="How many portions does this {recipeLabel} serve?"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      </p>
      <FloatInput.FloatInput
        id={id}
        onChange={(servings) => onChange({ ...value, serves: servings })}
        value={value.serves}
        className={classNames("servings-input", "text-center")}
      />
    </div>
  );
}

export function initialValue(recipe: Recipe | undefined): Value {
  const serves = FloatInput.initialValue(recipe?.serves ?? null);
  return { hasError: false, serves };
}

export function read(value: Value): ReadResult<Value, number> {
  const servingsReadResult = FloatInput.read({ value: value.serves });
  if (servingsReadResult.hasError) {
    return {
      hasError: true,
      value: { hasError: true, serves: servingsReadResult.value },
    };
  } else {
    return {
      hasError: false,
      value: { hasError: false, serves: servingsReadResult.value },
      input: servingsReadResult.input,
    };
  }
}

export const fragments = {
  recipe: gql`
    fragment RecipeServingsEditor_Recipe on Recipe {
      serves
    }
  `,
};
