import { useState } from "react";
import { useCallbackOne } from "use-memo-one";

import useIsUnmounted from "./useIsUnmounted";

export default function useLoading(
  f: () => Promise<void>
): [() => Promise<void>, boolean] {
  const [loading, setLoading] = useState(false);
  const isUnmounted = useIsUnmounted();

  const wrappedF = useCallbackOne(async () => {
    setLoading(true);
    try {
      await f();
    } finally {
      if (!isUnmounted.current) {
        setLoading(false);
      }
    }
  }, [f, isUnmounted, setLoading]);

  return [wrappedF, loading];
}
