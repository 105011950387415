export type Comparator<T> = (left: T, right: T) => number;

export function map<T, R>(
  mapper: (value: T) => R,
  comparator: Comparator<R>
): Comparator<T> {
  return (left, right) => comparator(mapper(left), mapper(right));
}

export function number(left: number, right: number): number {
  return left - right;
}

export function nullsLast<T>(comparator: Comparator<T>): Comparator<T | null> {
  return (left: T | null, right: T | null) => {
    if (left !== null && right !== null) {
      return comparator(left, right);
    } else if (left === null && right === null) {
      return 0;
    } else if (left === null) {
      return 1;
    } else {
      return -1;
    }
  };
}

export function reversed<T>(comparator: Comparator<T>): Comparator<T> {
  return (left: T, right: T) => -comparator(left, right);
}

export const stringSensitivityBase = new Intl.Collator(undefined, {
  sensitivity: "base",
}).compare;

export const stringLengthAscending = map(
  (value: string) => value.length,
  number
);

export function stringMatchingPrefixFirstSensitivityBase(
  prefix: string
): Comparator<string> {
  return (left, right) => {
    const leftMatches = startsWith(left, prefix, stringSensitivityBase);
    const rightMatches = startsWith(right, prefix, stringSensitivityBase);
    if (leftMatches === rightMatches) {
      return 0;
    } else if (leftMatches) {
      return -1;
    } else {
      return 1;
    }
  };
}

export function many<T>(...comparators: Array<Comparator<T>>): Comparator<T> {
  return (left: T, right: T) => {
    for (const comparator of comparators) {
      const comparisonValue = comparator(left, right);
      if (comparisonValue !== 0) {
        return comparisonValue;
      }
    }
    return 0;
  };
}

function startsWith(
  value: string,
  prefix: string,
  comparator: Comparator<string>
): boolean {
  return comparator(value.substring(0, prefix.length), prefix) === 0;
}
