import Card from "../utils/Card";

interface InfoCardProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

export default function InfoCard(props: InfoCardProps) {
  const { title, children } = props;
  return (
    <Card fullHeight shadow={false}>
      <div className="d-flex h-100 py-2">
        <h4 className="medium-font my-auto mr-auto">{title}</h4>
        {children}
      </div>
    </Card>
  );
}
