import classNames from "classnames";

import ReadResult from "./ReadResult";

export interface Value {
  isInvalid: boolean;
  text: string;
}

interface FloatInputProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange: (value: Value) => void;
  placeholder?: string;
  render?: (props: React.InputHTMLAttributes<HTMLInputElement>) => JSX.Element;
  style?: React.CSSProperties;
  value: Value;
}

export function FloatInput(props: FloatInputProps) {
  const {
    className,
    disabled,
    id,
    onChange,
    placeholder,
    render,
    style,
    value,
  } = props;

  // We use type="text" inputMode="decimal" instead of type="number" to avoid
  // rendering the spin buttons, and for the reasons described by the GDS
  // https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/

  const resetErrorState = (value: Value) => ({
    ...value,
    isInvalid: false,
  });

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    type: "text",
    inputMode: "decimal",
    disabled,
    autoComplete: "off",
    id,
    placeholder,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange({ ...resetErrorState(value), text: event.target.value }),
    className: classNames(
      {
        "is-invalid": value.isInvalid,
      },
      "form-control",
      "text-right",
      className
    ),
    style,
    value: value.text,
  };

  return render !== undefined ? render(inputProps) : <input {...inputProps} />;
}

export function initialValue(value: number | null): Value {
  return {
    isInvalid: false,
    text: value === null ? "" : value.toString(),
  };
}

interface ReadProps {
  value: Value;
  validationCondition?: (floatValue: number) => boolean;
}

export function read(props: ReadProps): ReadResult<Value, number> {
  const { value, validationCondition } = props;

  const input = parseFloat(value.text);
  const isInvalid =
    !/^[0-9]+(?:\.[0-9]+)?$/.test(value.text) ||
    Boolean(validationCondition && !validationCondition(input));

  const newValue = { ...value, isInvalid };

  return {
    hasError: isInvalid,
    value: newValue,
    input,
  };
}

interface ReadAllowEmptyProps extends ReadProps {}

export function readAllowEmpty(
  props: ReadAllowEmptyProps
): ReadResult<Value, number | null> {
  const { value, validationCondition } = props;

  const readResult = read({ value, validationCondition });

  return value.text === ""
    ? {
        hasError: false,
        value: {
          ...readResult.value,
          isInvalid: false,
        },
        input: null,
      }
    : readResult;
}
