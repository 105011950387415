import { foodstepsTurquoiseCol } from "../../graphs/colors";
import VectorProps from "./VectorProps";

export default function FilledCheckmark(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from bootstrap-icons
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Carbon-label-(with-illustrations)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Label-promo2" transform="translate(-1050.000000, -296.000000)">
          <g id="Group-3" transform="translate(254.000000, 170.000000)">
            <g id="Tick" transform="translate(796.000000, 126.000000)">
              <circle
                id="Oval"
                fill={foodstepsTurquoiseCol}
                cx="12"
                cy="12"
                r="12"
              ></circle>
              <path
                d="M16.1915778,5.94589605 C16.6845886,5.28013941 17.6239551,5.14010119 18.2897118,5.63311193 C18.9554684,6.12612267 19.0955066,7.06548924 18.6024959,7.73124589 L11.2549911,17.6532408 C10.7199369,18.3757725 9.67382298,18.4685406 9.01997174,17.8514396 L4.97043965,14.0295145 C4.36796673,13.460904 4.34051579,12.5115533 4.90912628,11.9090804 C5.47773676,11.3066075 6.42708743,11.2791565 7.02956035,11.847767 L9.85,14.509 L16.1915778,5.94589605 Z"
                id="Path-2"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
