import { FormattedMessage } from "react-intl";

import * as FloatEditor from "../../utils/FloatEditor";

export type Value = FloatEditor.Value;

export function initialValue(value: number | null) {
  return FloatEditor.initialValue(value);
}

interface EconomicValueEditorProps {
  className?: string;
  disabled?: boolean;
  value: Value;
  onChange: (value: Value) => void;
}

export function EconomicValueEditor(props: EconomicValueEditorProps) {
  const { className = "", disabled, onChange, value } = props;
  return (
    <FloatEditor.FloatEditor
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      unitLabel={
        <FormattedMessage
          id="components/recipes/ProductEditor/EconomicValueEditor:unitCurrencyPerKg"
          defaultMessage="£/kg"
        />
      }
    />
  );
}
