import { Link } from "react-router-dom";

export interface BreadcrumbPage {
  title: string;
  url?: string;
}

interface BreadcrumbProps {
  pages: Array<BreadcrumbPage>;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { pages } = props;

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {pages.map((page, pageIndex) =>
          pageIndex === pages.length - 1 ? (
            <li
              aria-current="page"
              className="breadcrumb-item active medium-font"
              key={pageIndex}
            >
              {page.title}
            </li>
          ) : (
            <li className="breadcrumb-item" key={pageIndex}>
              {page.url === undefined ? (
                page.title
              ) : (
                <Link to={page.url}>{page.title}</Link>
              )}
            </li>
          )
        )}
      </ol>
    </nav>
  );
}
