import { useIntl } from "react-intl";

interface RecipeLabel {
  singularLowercase: string;
  singularUppercase: string;
  pluralLowercase: string;
  pluralUppercase: string;
}

export default function useRecipeLabel(): RecipeLabel {
  const intl = useIntl();

  return {
    singularUppercase: intl.formatMessage({
      id: "components/recipes/useRecipeTypeLabel:product/singularUppercase",
      defaultMessage: "Product",
    }),
    singularLowercase: intl.formatMessage({
      id: "components/recipes/useRecipeTypeLabel:product/singularLowercase",
      defaultMessage: "product",
    }),
    pluralUppercase: intl.formatMessage({
      id: "components/recipes/useRecipeTypeLabel:product/pluralUppercase",
      defaultMessage: "Products",
    }),
    pluralLowercase: intl.formatMessage({
      id: "components/recipes/useRecipeTypeLabel:product/pluralLowercase",
      defaultMessage: "products",
    }),
  };
}
