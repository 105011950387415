import React from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import { usePages } from "../pages";
import ReportPage from "./ReportPage";

export function Scope3Page() {
  const pages = usePages();

  return (
    <ReportPage
      breadcrumb={pages.Scope3.breadcrumb()}
      impactCategory={ImpactCategory.GHG}
      title={pages.Scope3.title}
    />
  );
}
