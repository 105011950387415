import gql from "graphql-tag";

import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import { useRecipeImpactIsUnavailable_RecipeImpact } from "./useRecipeImpactIsUnavailable.graphql";

export function useRecipeImpactIsUnavailable(
  impact: useRecipeImpactIsUnavailable_RecipeImpact,
  impactCategory: ImpactCategory
): boolean {
  if (impactCategory === ImpactCategory.GHG) {
    return false;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.unavailableLandUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.unavailableWaterUse;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory");
  }
}

export const fragments = {
  recipeImpact: gql`
    fragment useRecipeImpactIsUnavailable_RecipeImpact on RecipeImpact {
      unavailableLandUse
      unavailableWaterUse
    }
  `,
};
