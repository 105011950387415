import { useState } from "react";

import * as comparators from "../../util/comparators";
import { Table, TableColumn, TableSort } from "./Table";
import { House, Products } from "./Vectors";

const stories = {
  name: "Table",
  children: [
    createSimpleTableStory({ name: "Simple table" }),
    createSimpleTableStory({ name: "Full width", fullWidth: true }),
    createAlignmentStory(),
    createFillCellStory(),
    createSortingStory(),
    createDefaultSortStory(),
    createControlledSortingStory(),
    createUnitsStory(),
    createExpandableRowsStory(),
    createActiveRowsStory(),
    createLabelPrefixTable(),
  ],
};

function createSimpleTableStory({
  name,
  fullWidth,
}: {
  name: string;
  fullWidth?: boolean;
}) {
  interface User {
    name: string;
    emailAddress: string;
    enabled: boolean;
  }

  const users: Array<User> = [
    { name: "Alice", emailAddress: "alice@example.com", enabled: true },
    { name: "Bob", emailAddress: "bob@example.com", enabled: false },
    { name: "Charlie", emailAddress: "charlie@example.com", enabled: true },
  ];

  const userKey = (user: User) => user.emailAddress;

  const userColumns: Array<TableColumn<User>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (user) => user.name,
    },
    {
      key: "emailAddress",
      label: "E-mail address",
      renderCell: (user) => user.emailAddress,
    },
    {
      key: "enabled",
      label: "Enabled",
      renderCell: (user) => (user.enabled ? "true" : "false"),
    },
  ];

  return {
    name,
    render: () => (
      <Table<User>
        columns={userColumns}
        fullWidth={fullWidth}
        rowKey={userKey}
        rows={users}
      />
    ),
  };
}

function createAlignmentStory() {
  interface Item {
    name: string;
    rating: string;
    quantity: number;
    pricePence: number;
  }

  const items: Array<Item> = [
    { name: "Apples", rating: "A", quantity: 4, pricePence: 96 },
    { name: "Bananas", rating: "B", quantity: 5, pricePence: 44 },
    { name: "Coconuts", rating: "C", quantity: 1, pricePence: 204 },
    { name: "Durian", rating: "A", quantity: 100, pricePence: 30600 },
  ];

  const itemKey = (item: Item) => item.name;

  const columns: Array<TableColumn<Item>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (item) => item.name,
    },
    {
      key: "rating",
      label: "Rating",
      renderCell: (item) => item.rating,
      align: "center",
    },
    {
      key: "quantity",
      label: "Quantity",
      renderCell: (item) => item.quantity,
      align: "right",
    },
    {
      key: "price",
      label: "Price",
      renderCell: (item) => "£" + (item.pricePence / 100).toFixed(2),
      align: "right",
    },
  ];

  return {
    name: "Alignment",
    render: () => (
      <Table<Item> columns={columns} rowKey={itemKey} rows={items} />
    ),
  };
}

function createFillCellStory() {
  interface Item {
    name: string;
    rating: string;
  }

  const items: Array<Item> = [
    { name: "Apples", rating: "A" },
    { name: "Bananas", rating: "B" },
    { name: "Coconuts", rating: "C" },
    { name: "Durian", rating: "A" },
  ];

  const itemKey = (item: Item) => item.name;

  const columns: Array<TableColumn<Item>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (item, { className }) => (
        <div className={`action-link action-link-info ${className ?? ""}`}>
          {item.name}
        </div>
      ),
    },
    {
      key: "rating",
      label: "Rating",
      renderCell: (item) => item.rating,
      align: "center",
    },
  ];

  return {
    name: "Fill cell",
    render: () => (
      <Table<Item> columns={columns} rowKey={itemKey} rows={items} />
    ),
  };
}

function createSortingStory() {
  interface Item {
    name: string;
    rating: string;
    quantity: number;
    pricePence: number;
  }

  const items: Array<Item> = [
    { name: "Apples", rating: "A", quantity: 4, pricePence: 96 },
    { name: "Bananas", rating: "B", quantity: 5, pricePence: 44 },
    { name: "Coconuts", rating: "C", quantity: 1, pricePence: 204 },
    { name: "Durian", rating: "A", quantity: 100, pricePence: 30600 },
  ];

  const itemKey = (item: Item) => item.name;

  const columns: Array<TableColumn<Item>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (item) => item.name,
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "rating",
      label: "Rating",
      renderCell: (item) => item.rating,
      align: "center",
      sortComparator: comparators.map(
        (item) => item.rating,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "quantity",
      label: "Quantity",
      renderCell: (item) => item.quantity,
      align: "right",
      sortComparator: comparators.map(
        (item) => item.quantity,
        comparators.number
      ),
    },
    {
      key: "price",
      label: "Price",
      renderCell: (item) => "£" + (item.pricePence / 100).toFixed(2),
      sortComparator: comparators.map(
        (item) => item.pricePence,
        comparators.number
      ),
      align: "right",
    },
  ];

  return {
    name: "Sorting",
    render: () => (
      <Table<Item> columns={columns} rowKey={itemKey} rows={items} />
    ),
  };
}

function createDefaultSortStory() {
  interface Item {
    name: string;
    quantity: number;
  }

  const items: Array<Item> = [
    { name: "Apples", quantity: 4 },
    { name: "Bananas", quantity: 5 },
    { name: "Coconuts", quantity: 1 },
    { name: "Durian", quantity: 100 },
  ];

  const itemKey = (item: Item) => item.name;

  const columns: Array<TableColumn<Item>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (item) => item.name,
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "quantity",
      label: "Quantity",
      renderCell: (item) => item.quantity,
      align: "right",
      sortComparator: comparators.map(
        (item) => item.quantity,
        comparators.number
      ),
    },
  ];

  return {
    name: "Default sort",
    render: () => (
      <Table<Item>
        columns={columns}
        defaultSort="quantity"
        rowKey={itemKey}
        rows={items}
      />
    ),
  };
}

function createControlledSortingStory() {
  interface Item {
    name: string;
    rating: string;
    quantity: number;
    pricePence: number;
  }

  const items: Array<Item> = [
    { name: "Apples", rating: "A", quantity: 4, pricePence: 96 },
    { name: "Bananas", rating: "B", quantity: 5, pricePence: 44 },
    { name: "Coconuts", rating: "C", quantity: 1, pricePence: 204 },
    { name: "Durian", rating: "A", quantity: 100, pricePence: 30600 },
  ];

  const itemKey = (item: Item) => item.name;

  const columns: Array<TableColumn<Item>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (item) => item.name,
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "rating",
      label: "Rating",
      renderCell: (item) => item.rating,
      align: "center",
      sortComparator: comparators.map(
        (item) => item.rating,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "quantity",
      label: "Quantity",
      renderCell: (item) => item.quantity,
      align: "right",
      sortComparator: comparators.map(
        (item) => item.quantity,
        comparators.number
      ),
    },
    {
      key: "price",
      label: "Price",
      renderCell: (item) => "£" + (item.pricePence / 100).toFixed(2),
      sortComparator: comparators.map(
        (item) => item.pricePence,
        comparators.number
      ),
      align: "right",
    },
  ];

  function ControlledSorting() {
    const [sort, setSort] = useState<null | TableSort>(null);
    return (
      <>
        <Table<Item>
          columns={columns}
          rowKey={itemKey}
          rows={items}
          sort={sort}
          onSortChange={(sort) => setSort(sort)}
        />
        <button onClick={() => setSort(null)}>Reset sorting</button>
        <p>Sort: {JSON.stringify(sort)}</p>
      </>
    );
  }

  return {
    name: "Controlled sorting",
    render: () => <ControlledSorting />,
  };
}

function createUnitsStory() {
  interface Item {
    name: string;
    weight: number;
  }

  const items: Array<Item> = [
    { name: "Apples", weight: 4 },
    { name: "Bananas", weight: 5 },
    { name: "Coconuts", weight: 1 },
    { name: "Durian", weight: 100 },
  ];

  const itemKey = (item: Item) => item.name;

  const columns: Array<TableColumn<Item>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (item) => item.name,
    },
    {
      key: "weight",
      label: "Weight",
      units: "kg",
      renderCell: (item) => item.weight,
      align: "right",
    },
  ];

  return {
    name: "Units",
    render: () => (
      <Table<Item> columns={columns} rowKey={itemKey} rows={items} />
    ),
  };
}

function createExpandableRowsStory() {
  interface Item {
    children?: Array<Item>;
    name: string;
    weight: number;
  }

  const items: Array<Item> = [
    {
      name: "Apples",
      weight: 4,
      children: [
        { name: "Acklam Russet", weight: 2 },
        { name: "Granny Smith", weight: 5 },
      ],
    },
    { name: "Bananas", weight: 5 },
    {
      name: "Coconuts",
      weight: 1,
      children: [
        { name: "Fiji Dwarf", weight: 0.5 },
        { name: "Golden Malay", weight: 2 },
      ],
    },
    { name: "Durian", weight: 100 },
  ];

  interface Row extends Item {
    isSubItem: boolean;
  }

  const itemKey = (item: Item) => item.name;

  function ExpandableRowsStory() {
    const columns: Array<TableColumn<Row>> = [
      {
        key: "name",
        label: "Name",
        renderCell: (item) => (
          <>
            {item.isSubItem ? (
              <span style={{ display: "inline-block", width: "2em" }}></span>
            ) : null}
            {item.name}
          </>
        ),
      },
      {
        key: "weight",
        label: "Weight",
        units: "kg",
        renderCell: (item) => item.weight,
        align: "right",
        sortComparator: comparators.map(
          (item) => item.weight,
          comparators.number
        ),
      },
    ];

    const rows = items.map((item) => ({ ...item, isSubItem: false }));

    function expandRow(item: Row) {
      return undefined !== item.children
        ? item.children.map((subItem) => ({
            ...subItem,
            isSubItem: true,
            rootItemName: item.name,
          }))
        : [];
    }

    return (
      <Table<Row>
        columns={columns}
        expandedRows={expandRow}
        isExpandable={(row) => row.children !== undefined}
        rowKey={itemKey}
        rows={rows}
      />
    );
  }

  return {
    name: "Expandable rows",
    render: () => <ExpandableRowsStory />,
  };
}

function createActiveRowsStory() {
  interface User {
    name: string;
    emailAddress: string;
    enabled: boolean;
  }

  const users: Array<User> = [
    { name: "Alice", emailAddress: "alice@example.com", enabled: true },
    { name: "Bob", emailAddress: "bob@example.com", enabled: false },
    { name: "Charlie", emailAddress: "charlie@example.com", enabled: true },
  ];

  const userKey = (user: User) => user.emailAddress;

  const userColumns: Array<TableColumn<User>> = [
    {
      key: "name",
      label: "Name",
      renderCell: (user) => user.name,
    },
    {
      key: "emailAddress",
      label: "E-mail address",
      renderCell: (user) => user.emailAddress,
    },
    {
      key: "enabled",
      label: "Enabled",
      renderCell: (user) => (user.enabled ? "true" : "false"),
    },
  ];

  return {
    name: "Active rows",
    render: () => (
      <Table<User>
        activeRows={[users[1]]}
        columns={userColumns}
        rowKey={userKey}
        rows={users}
      />
    ),
  };
}

function createLabelPrefixTable() {
  interface Habitat {
    animal: string;
    house: string;
  }

  const habitats: Array<Habitat> = [
    { animal: "Bear", house: "Den" },
    { animal: "Bat", house: "Cave" },
    { animal: "Otter", house: "Dam" },
  ];

  const key = (habitat: Habitat) => habitat.animal;

  const columns: Array<TableColumn<Habitat>> = [
    {
      key: "animal",
      label: "Animal",
      labelPrefix: <Products width={16} />,
      renderCell: (user) => user.animal,
      sortComparator: comparators.map(
        (item) => item.animal,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "house",
      label: "House",
      labelPrefix: <House width={16} />,
      renderCell: (user) => user.house,
    },
  ];

  return {
    name: "Label prefix",
    render: () => (
      <Table<Habitat> columns={columns} rowKey={key} rows={habitats} />
    ),
  };
}

export default stories;
