import ExtraLargeNumber from "./ExtraLargeNumber";
import LargeNumber from "./LargeNumber";
import Number from "./Number";

const stories = {
  name: "Numbers",
  children: [
    {
      name: "ExtraLargeNumber",
      render: () => <ExtraLargeNumber>12.34</ExtraLargeNumber>,
    },
    {
      name: "LargeNumber",
      render: () => <LargeNumber>12.34</LargeNumber>,
    },
    {
      name: "Number",
      render: () => <Number>12.34</Number>,
    },
  ],
};

export default stories;
