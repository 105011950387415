import { useState } from "react";

import Form from "./Form";
import * as TextField from "./TextField";

const stories = {
  name: "TextField",
  render: () => <Story />,
};

function Story() {
  const [value, setValue] = useState(TextField.initialValue(""));

  async function handleSubmit() {
    const readResult = TextField.read(value);
    setValue(readResult.value);
    alert("Read result:\n\n" + JSON.stringify(readResult));
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField.TextField
          onChange={(value) => setValue(value)}
          value={value}
        />
      </div>

      <div className="form-group">
        <Form.SubmitButton submitLabel="Read" />
      </div>
    </Form>
  );
}

export default stories;
