import ReadResult from "./ReadResult";

export interface Value<T> {
  hasError: boolean;
  selected: T | null;
}

export function blankValue(): Value<never> {
  return initialValue<never>(null);
}

export function initialValue<T>(selected: T | null) {
  return value(selected, { hasError: false });
}

export function value<T>(
  selected: T | null,
  { hasError }: { hasError: boolean }
): Value<T> {
  return { hasError, selected };
}

export function read<T>(value: Value<T>): ReadResult<Value<T>, T> {
  if (value.selected === null) {
    return {
      hasError: true,
      value: { hasError: true, selected: value.selected },
    };
  } else {
    return {
      hasError: false,
      value: { hasError: false, selected: value.selected },
      input: value.selected,
    };
  }
}

interface SelectProps<T> {
  hasError: boolean;
  onChange: (value: T | null) => void;
  value: T | null;
}

interface SelectFieldProps<T> {
  onChange: (value: Value<T>) => void;
  renderSelect: (selectProps: SelectProps<T>) => React.ReactNode;
  value: Value<T>;
}

export function SelectField<T>(props: SelectFieldProps<T>) {
  const { onChange, renderSelect, value } = props;

  return (
    <>
      {renderSelect({
        hasError: value.hasError,
        onChange: (selected) => onChange({ ...value, selected }),
        value: value.selected,
      })}
    </>
  );
}
