import { useHistory } from "react-router-dom";

import RecipeCard from "./RecipeCard";

interface NewRecipeEditorProps {
  parentUrl: string;
}

export default function NewRecipeEditor(props: NewRecipeEditorProps) {
  const { parentUrl } = props;

  const history = useHistory();

  const handleAfterSave = () => history.push(parentUrl);
  const handleDiscard = () => history.goBack();

  return <RecipeCard onAfterSave={handleAfterSave} onDiscard={handleDiscard} />;
}
