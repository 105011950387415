import { useIntl } from "react-intl";

import { DataQualityScoreRating } from "./useDataQualityScoreToRatingString";

export default function useDataQualityRatingToString(): (
  rating: DataQualityScoreRating
) => string {
  const intl = useIntl();

  return (rating: DataQualityScoreRating) => {
    if (rating === DataQualityScoreRating.VERY_GOOD) {
      return intl.formatMessage({
        id: "components/utils/DQS/useDataQualityScoreRatingToString:veryGood",
        defaultMessage: "Very Good",
      });
    } else if (rating === DataQualityScoreRating.GOOD) {
      return intl.formatMessage({
        id: "components/utils/DQS/useDataQualityScoreRatingToString:good",
        defaultMessage: "Good",
      });
    } else if (rating === DataQualityScoreRating.FAIR) {
      return intl.formatMessage({
        id: "components/utils/DQS/useDataQualityScoreRatingToString:fair",
        defaultMessage: "Fair",
      });
    } else if (rating === DataQualityScoreRating.POOR) {
      return intl.formatMessage({
        id: "components/utils/DQS/useDataQualityScoreRatingToString:poor",
        defaultMessage: "Poor",
      });
    } else if (rating === DataQualityScoreRating.UNSUITABLE) {
      return intl.formatMessage({
        id: "components/utils/DQS/useDataQualityScoreRatingToString:unsuitable",
        defaultMessage: "Unsuitable",
      });
    } else {
      throw new Error("Invalid data quality score");
    }
  };
}
