import VectorProps from "./VectorProps";

export default function ImpactPendingWithoutTextbox(
  props: Omit<VectorProps, "fill">
) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?type=design&node-id=846-888&mode=design&t=vhCxptW20kMnqOWa-0
  return (
    <svg
      className={className}
      fill="none"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 46 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_863_1044)">
        <circle
          cx="15.9998"
          cy="16"
          r="14.2381"
          fill="#DEE2E6"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M16.1457 23.3281C20.0542 23.3281 23.2383 20.144 23.2383 16.2355C23.2383 12.3269 20.0542 9.14285 16.1457 9.14285C14.5283 9.14285 13.0121 9.66508 11.7486 10.659C11.4285 10.9117 11.3779 11.3834 11.6306 11.7035C11.8832 12.0236 12.355 12.0742 12.6751 11.8215C13.669 11.0297 14.8652 10.6253 16.1456 10.6253C19.2286 10.6253 21.7388 13.1355 21.7388 16.2185C21.7388 19.3015 19.2286 21.8118 16.1456 21.8118C13.13 21.8118 10.6871 19.4195 10.5524 16.4544H11.7822C12.2371 16.4544 12.5235 15.9489 12.2707 15.5615L10.283 12.529C10.0472 12.1752 9.54171 12.1752 9.32267 12.529L7.33477 15.5783C7.08211 15.9658 7.35158 16.4712 7.82328 16.4712H9.06999C9.18798 20.2787 12.3215 23.328 16.1458 23.328L16.1457 23.3281Z"
          fill="black"
        />
      </g>
      <rect
        x="21"
        y="1"
        width="24"
        height="30"
        rx="5"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path d="M30.4374 18.52V16.22H35.5574V18.52H30.4374Z" fill="black" />
      <defs>
        <clipPath id="clip0_863_1044">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
