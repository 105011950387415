import UpdateIngredientsBanner from "./UpdateIngredientsBanner";

const stories = {
  name: "UpdateIngredientsBanner",
  render: () => (
    <UpdateIngredientsBanner
      numUnlinkedIngredientNames={3}
      onClick={() => alert("Update ingredients")}
    />
  ),
};

export default stories;
