import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as Chartjs,
  ChartConfiguration,
  DoughnutController,
  LinearScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState } from "react";
import { useCallback } from "use-memo-one";

import FitToSpace from "../utils/FitToSpace";

Chartjs.defaults.font.family = "Graphik Web";
Chartjs.defaults.font.size = 16;

Chartjs.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  ChartDataLabels,
  DoughnutController,
  LinearScale,
  Tooltip
);
interface ChartProps {
  chartConfig: ChartConfiguration;
}

export default function Chart(props: ChartProps) {
  const { chartConfig } = props;

  return (
    <FitToSpace>
      {({ width, height }) => (
        <ChartCanvas chartConfig={chartConfig} width={width} height={height} />
      )}
    </FitToSpace>
  );
}

interface ChartCanvasProps {
  chartConfig: ChartConfiguration;
  width: number;
  height: number;
}

function ChartCanvas(props: ChartCanvasProps) {
  const { width, height, chartConfig } = props;
  const [chartCanvas, setChartCanvas] = useState<HTMLCanvasElement | null>(
    null
  );

  const chartCanvasRef = useCallback((node) => setChartCanvas(node), []);

  useEffect(() => {
    if (chartCanvas) {
      chartCanvas.width = width;
      chartCanvas.height = height;
      const chartInstance = new Chartjs(chartCanvas, chartConfig);

      return () => {
        chartInstance.destroy();
      };
    }
  }, [chartCanvas, width, height, chartConfig]);

  return (
    <canvas
      className="mx-auto"
      width={width}
      height={height}
      ref={chartCanvasRef}
    />
  );
}
