import { gql } from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";

import Checkbox from "../../utils/Checkbox";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult from "../../utils/ReadResult";
import useId from "../../utils/useId";
import RecipeEditorLabel from "./RecipeEditorLabel";
import { RecipeServingsPerCookingBatchEditor_Recipe as Recipe } from "./RecipeServingsPerCookingBatchEditor.graphql";

import "./RecipeServingsPerCookingBatchEditor.css";

export type AccurateValue = {
  type: "accurate cooking impacts";
  sameAsServings: boolean;
  servingsPerCookingBatch: FloatInput.Value;
};

type EstimatedValue = {
  type: "estimated cooking impacts";
};

export type Value = AccurateValue | EstimatedValue;

interface RecipeServingsPerCookingBatchEditorProps {
  onChange: (value: Value) => void;
  servings: FloatInput.Value;
  value: AccurateValue;
}

export function RecipeServingsPerCookingBatchEditor(
  props: RecipeServingsPerCookingBatchEditorProps
) {
  const { onChange, servings, value } = props;

  const id = useId();

  return (
    <div className="form-group">
      <RecipeEditorLabel
        className="RecipeServingsPerCookingBatchEditor__label"
        htmlFor={id}
      >
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeServingsPerCookingBatchEditor:label"
          defaultMessage="Batches"
        />
      </RecipeEditorLabel>
      <ServingsPerCookingBatchHelpModal />

      <p className="text-muted">
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeServingsPerCookingBatchEditor:helpText"
          defaultMessage="How many servings are cooked per batch?"
        />
      </p>

      <Checkbox
        checked={value.sameAsServings}
        label={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeServingsPerCookingBatchEditor:sameAsServings"
            defaultMessage="All servings are cooked in the same batch"
          />
        }
        onChange={(sameAsServings) => onChange({ ...value, sameAsServings })}
      />

      {!value.sameAsServings && (
        <FloatInput.FloatInput
          className={"servings-input mt-3 text-center"}
          id={id}
          onChange={(servingsPerCookingBatch) =>
            onChange({
              ...value,
              servingsPerCookingBatch,
            })
          }
          value={
            value.sameAsServings ? servings : value.servingsPerCookingBatch
          }
        />
      )}
    </div>
  );
}

export function initialValue(
  recipe: Recipe | undefined,
  accurateCookingImpacts: boolean
): Value {
  if (!accurateCookingImpacts) {
    return {
      type: "estimated cooking impacts",
    };
  } else if (recipe === undefined) {
    return {
      type: "accurate cooking impacts",
      sameAsServings: true,
      servingsPerCookingBatch: FloatInput.initialValue(null),
    };
  } else {
    return {
      type: "accurate cooking impacts",
      sameAsServings: recipe.servingsPerCookingBatch === null,
      servingsPerCookingBatch: FloatInput.initialValue(
        recipe.servingsPerCookingBatch
      ),
    };
  }
}

export function read(value: Value): ReadResult<Value, number | null> {
  if (value.type === "estimated cooking impacts") {
    return {
      hasError: false,
      value: { ...value },
      input: null,
    };
  }
  if (value.sameAsServings) {
    return {
      hasError: false,
      value: { ...value },
      input: null,
    };
  }

  const servingsPerCookingBatchReadResult = FloatInput.read({
    value: value.servingsPerCookingBatch,
  });
  if (servingsPerCookingBatchReadResult.hasError) {
    return { hasError: true, value: { ...value } };
  } else {
    return {
      hasError: false,
      value: { ...value },
      input: servingsPerCookingBatchReadResult.input,
    };
  }
}

export const fragments = {
  recipe: gql`
    fragment RecipeServingsPerCookingBatchEditor_Recipe on Recipe {
      servingsPerCookingBatch
    }
  `,
};

function ServingsPerCookingBatchHelpModal() {
  return (
    <HelpModalTooltip>
      <HelpModalTooltip.Question
        answer={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeServingsPerCookingBatchEditor:batchesHelpModal/whatIsServingsPerCookingBatch/answer"
            defaultMessage="Servings per cooking batch is the number of portions that are cooked at a time using the cooking steps in the product. To allocate accurate cooking impacts per serving we need to know when your product requires multiple rounds of cooking to produce the full amount of servings."
          />
        }
        question={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeServingsPerCookingBatchEditor:batchesHelpModal/whatIsServingsPerCookingBatch/question"
            defaultMessage="What is the number of servings per cooking batch?"
          />
        }
      />
    </HelpModalTooltip>
  );
}
