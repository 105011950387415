import range from "lodash/range";
import unzip from "lodash/unzip";

export function mapNotNull<T, R>(
  array: Array<T>,
  f: (element: T, index: number) => R | null
): Array<R> {
  return array.flatMap((element, index) => {
    const result = f(element, index);
    if (result === null) {
      return [];
    } else {
      return [result];
    }
  });
}

export function padArrayBothEnds<T>(
  array: Array<T>,
  length: number,
  padding: T
): Array<T> {
  const delta = length - array.length;
  if (delta > 0) {
    const startCount = Math.floor(delta / 2);
    const endCount = delta - startCount;
    return [
      ...repeat(padding, startCount),
      ...array,
      ...repeat(padding, endCount),
    ];
  } else {
    return array;
  }
}

function repeat<T>(element: T, length: number): Array<T> {
  return range(length).map(() => element);
}

export const unzip2: <T1, T2>(
  array: Array<[T1, T2]>
) => [Array<T1>, Array<T2>] = unzip as any;
