import VectorProps from "../VectorProps";

export default function Eutrophication(props: VectorProps) {
  const { width } = props;

  // from https://www.figma.com/file/Z5JvdIZfbok8AxNw5lmpCU/DQS?node-id=15663%3A7005&mode=dev

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.08 20.16C15.647 20.16 20.16 15.647 20.16 10.08C20.16 4.51297 15.647 0 10.08 0C4.51297 0 0 4.51297 0 10.08C0 15.647 4.51297 20.16 10.08 20.16Z"
        fill="#67DBC1"
      />
      <path
        d="M25.5694 2.36H18.5902C17.115 2.36 15.9198 3.5552 15.9198 5.0304V8.88H7.14857C5.27657 8.88 3.75977 10.3968 3.75977 12.2688V29.3856C3.75977 30.2544 4.46377 30.96 5.33417 30.96H26.6654C27.5342 30.96 28.2398 30.256 28.2398 29.3856V5.0304C28.2398 3.5552 27.0446 2.36 25.5694 2.36ZM19.1022 5.8464C19.1022 5.5552 19.3118 5.32 19.5694 5.32H24.5902C24.8478 5.32 25.0574 5.5552 25.0574 5.8464V8.84H19.1022V5.8464Z"
        fill="#00A68F"
      />
      <path
        d="M26.6655 31.84H5.33428C3.98068 31.84 2.87988 30.7392 2.87988 29.3856V12.2688C2.87988 9.9152 4.79508 8 7.14868 8H15.0399V5.0304C15.0399 3.072 16.6335 1.48 18.5903 1.48H25.5695C27.5279 1.48 29.1199 3.0736 29.1199 5.0304V29.3856C29.1199 30.7392 28.0191 31.84 26.6655 31.84ZM7.14868 9.76C5.76468 9.76 4.63988 10.8848 4.63988 12.2688V29.3856C4.63988 29.768 4.95188 30.08 5.33428 30.08H26.6655C27.0479 30.08 27.3599 29.768 27.3599 29.3856V5.0304C27.3599 4.0432 26.5567 3.24 25.5695 3.24H18.5903C17.6031 3.24 16.7999 4.0432 16.7999 5.0304V8.88C16.7999 9.3664 16.4063 9.76 15.9199 9.76H7.14868ZM25.0575 9.72H19.1023C18.6159 9.72 18.2223 9.3264 18.2223 8.84V5.8464C18.2223 5.0704 18.8271 4.44 19.5695 4.44H24.5903C25.3327 4.44 25.9375 5.0704 25.9375 5.8464V8.84C25.9375 9.3264 25.5439 9.72 25.0575 9.72ZM19.9823 7.96H24.1775V6.2H19.9823V7.96Z"
        fill="black"
      />
      <path d="M12.9501 3.28H6.87012V6.8H12.9501V3.28Z" fill="#DCFFD1" />
      <path
        d="M12.9502 7.67999H6.87023C6.38383 7.67999 5.99023 7.28639 5.99023 6.79999V3.27999C5.99023 2.79359 6.38383 2.39999 6.87023 2.39999H12.9502C13.4366 2.39999 13.8302 2.79359 13.8302 3.27999V6.79999C13.8302 7.28639 13.4366 7.67999 12.9502 7.67999ZM7.75023 5.91999H12.0702V4.15999H7.75023V5.91999Z"
        fill="black"
      />
      <path
        d="M9.91027 7.67999C9.42387 7.67999 9.03027 7.28639 9.03027 6.79999V3.27999C9.03027 2.79359 9.42387 2.39999 9.91027 2.39999C10.3967 2.39999 10.7903 2.79359 10.7903 3.27999V6.79999C10.7903 7.28639 10.3967 7.67999 9.91027 7.67999Z"
        fill="black"
      />
      <path
        d="M15.9999 13.6L8.59351 26.4288H23.4063L15.9999 13.6Z"
        fill="#DCFFD1"
      />
      <path
        d="M23.4064 27.3088H8.59356C8.27996 27.3088 7.98876 27.1408 7.83196 26.8688C7.67516 26.5968 7.67516 26.2608 7.83196 25.9888L15.2384 13.16C15.3952 12.888 15.6864 12.72 16 12.72C16.3136 12.72 16.6048 12.888 16.7616 13.16L24.168 25.9888C24.3248 26.2608 24.3248 26.5968 24.168 26.8688C24.0112 27.1408 23.72 27.3088 23.4064 27.3088ZM10.1184 25.5488H21.8832L16.0016 15.36L10.12 25.5488H10.1184Z"
        fill="black"
      />
      <path
        d="M15.2878 23.8384C15.2878 23.408 15.6478 23.0736 16.0878 23.0736C16.5278 23.0736 16.8878 23.408 16.8878 23.8384C16.8878 24.2688 16.5278 24.5952 16.0878 24.5952C15.6478 24.5952 15.2878 24.2608 15.2878 23.8384ZM15.3854 18.2288H16.8094L16.6158 22.5536H15.579L15.3854 18.2288Z"
        fill="black"
      />
    </svg>
  );
}
