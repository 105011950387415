import React from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import { usePages } from "../pages";
import ReportPage from "./ReportPage";

export function LandUsePage() {
  const pages = usePages();

  return (
    <ReportPage
      breadcrumb={pages.LandUse.breadcrumb()}
      impactCategory={ImpactCategory.LAND_USE}
      title={pages.LandUse.title}
    />
  );
}
