import VectorProps from "./VectorProps";

export default function ChevronLeft(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // From https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=45-78&mode=design&t=AR3AgqqIbKUXPhVu-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65225 0.316587C9.09825 0.752587 9.13325 1.35959 8.65225 1.89259L4.90525 5.80059L8.65225 9.70859C9.13325 10.2416 9.09825 10.8496 8.65225 11.2826C8.20725 11.7186 7.45525 11.6906 7.03725 11.2826C6.61925 10.8766 2.53525 6.58759 2.53525 6.58759C2.31225 6.37059 2.20025 6.08559 2.20025 5.80059C2.20025 5.51559 2.31225 5.23059 2.53525 5.01159C2.53525 5.01159 6.61925 0.724587 7.03725 0.316587C7.45525 -0.091413 8.20725 -0.119413 8.65225 0.316587Z"
        fill={fill}
      />
    </svg>
  );
}
