import VectorProps from "./VectorProps";

export default function ShieldWithCheckmark(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from bootstrap-icons
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      onClick={handleClick}
      viewBox="0 0 14 17"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg x="22%" y="26%">
        <path
          id="Vector"
          d="M7.29866 0.310616C7.18161 0.207755 7.02916 0.156595 6.87508 0.168424C6.72072 0.18025 6.57773 0.254239 6.47717 0.373604L3.15983 4.31655L1.41979 2.67235C1.17974 2.49714 0.848613 2.53345 0.650208 2.75623C0.452066 2.97929 0.449637 3.31814 0.644801 3.54369L3.29145 6.00008L7.36051 1.14265C7.56838 0.895115 7.54081 0.52326 7.29861 0.310686L7.29866 0.310616Z"
        />
      </svg>
      <path
        id="Vector"
        d="M7.01138 16.8328C7.08048 16.8334 7.14926 16.8227 7.21489 16.8012C9.18563 16.1865 10.9078 14.9563 12.1289 13.2913C13.35 11.6262 14.0056 9.61395 14 7.54908V1.17883C14 0.803913 13.6961 0.5 13.3216 0.5H0.678731C0.303888 0.5 3.65886e-05 0.803913 3.65886e-05 1.17883V7.54908C-0.00564292 9.61403 0.650023 11.6262 1.8711 13.2913C3.09217 14.9563 4.81437 16.1865 6.78511 16.8012C6.858 16.8252 6.93467 16.836 7.01135 16.8328H7.01138ZM1.37516 7.54905V1.85737H12.6384V7.54905C12.6441 9.28792 12.1042 10.9845 11.0945 12.4C10.0852 13.8157 8.65676 14.8787 7.0113 15.4395C5.3639 14.8802 3.93394 13.818 2.92272 12.4022C1.91142 10.9865 1.36999 9.28889 1.37508 7.54905H1.37516Z"
      />
    </svg>
  );
}
