import { Chart as Chartjs, TooltipModel } from "chart.js";
import { useState } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { EffectTypeAbsoluteValue } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import BarChart, { BarChartData } from "../graphs/BarChart";
import { ColorLists } from "../graphs/colors";
import DoughnutChart, { DoughnutChartData } from "../graphs/DoughnutChart";
import { valuesForChart } from "../graphs/graphDataAndStyles";
import RecipeGroup from "../graphs/RecipeGroup";
import Card from "../utils/Card";
import RadioToggleButtonGroup from "../utils/RadioToggleButtonGroup";
import { Bar, Doughnut } from "../utils/Vectors/";

interface GraphCardProps {
  colorLists: ColorLists;
  defaultGraphType: GraphType;
  impactCategory: ImpactCategory;
  mean: string;
  recipeCount: number;
  recipeGroups: Array<RecipeGroup>;
  title: React.ReactNode;
}

interface GraphCardChartData {
  effectType: EffectTypeAbsoluteValue;
  groupAveragesData: DoughnutChartData;
  recipesData: BarChartData;
}

export enum GraphType {
  BAR,
  DOUGHNUT,
}

export default function GraphCard(props: GraphCardProps) {
  const {
    colorLists,
    defaultGraphType,
    impactCategory,
    mean,
    recipeCount,
    recipeGroups,
    title,
  } = props;

  const intl = useIntl();

  const chartData = useMemoOne(
    () => valuesForChart(impactCategory, recipeGroups, colorLists),
    [impactCategory, recipeGroups, colorLists]
  );

  const enableBarChart = recipeCount < 100;

  const [graphType, setGraphType] = useState(
    !enableBarChart && defaultGraphType === GraphType.BAR
      ? GraphType.DOUGHNUT
      : defaultGraphType
  );

  return (
    <Card fullHeight shadow={false}>
      <div className="d-flex flex-column h-100">
        <h4 className="medium-font m-0 text-center">
          <FormattedMessage
            id="components/dashboard/GraphCard:title"
            defaultMessage="{effectType} by {title}"
            values={{
              effectType: chartData.effectType.title(intl),
              title,
            }}
          />
        </h4>
        <div className="flex-grow-1">
          <Graphs chartData={chartData} graphType={graphType} mean={mean} />
        </div>
        {enableBarChart ? (
          <GraphControls
            graphType={graphType}
            onGraphTypeChange={(graphType) => setGraphType(graphType)}
          />
        ) : null}
      </div>
    </Card>
  );
}

function externalTooltipHandler(context: {
  chart: Chartjs;
  tooltip: TooltipModel<any>;
}): void {
  const { chart, tooltip } = context;
  let tooltipElement = chart.canvas.parentNode!.querySelector("div");
  if (!tooltipElement) {
    tooltipElement = document.createElement("div");
    tooltipElement.classList.add("custom-tooltip");
    chart.canvas.parentNode!.appendChild(tooltipElement);
  }

  tooltipElement.style.opacity = tooltip.opacity.toString();

  if (tooltip.opacity === 0) {
    return;
  } else {
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipElement.style.left = positionX + tooltip.caretX + "px";
    tooltipElement.style.top =
      positionY + tooltip.caretY - tooltip.height + "px";
  }

  ReactDOM.render(
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ fontSize: "16px", lineHeight: "24px" }}>
        {tooltip.body[0].lines[0]}
      </div>
      <div>
        <span
          style={{
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 500,
            paddingRight: "4px",
          }}
        >
          {tooltip.footer[0]}
        </span>
        <span
          style={{
            fontSize: "13px",
            lineHeight: "20px",
            alignContent: "baseline",
          }}
        >
          {tooltip.footer[1]}
        </span>
      </div>
    </div>,
    tooltipElement
  );
}

interface GraphsProps {
  chartData: GraphCardChartData;
  graphType: GraphType;
  mean: string;
}

function Graphs(props: GraphsProps) {
  const { chartData, graphType, mean } = props;
  const effectType = chartData.effectType;

  const intl = useIntl();

  const averageLabel = intl.formatMessage({
    id: "components/dashboard/GraphCard:average",
    defaultMessage: "Average",
  });

  const unitString = effectType.unitString(intl);

  const centerValue = useMemoOne(
    () => ({
      label: averageLabel,
      value: mean,
      unit: unitString,
    }),
    [averageLabel, mean, unitString]
  );

  switch (graphType) {
    case GraphType.BAR:
      return (
        <BarChart
          externalTooltipHandler={externalTooltipHandler}
          chartData={chartData.recipesData}
          effectType={effectType}
        />
      );
    case GraphType.DOUGHNUT:
      return (
        <DoughnutChart
          externalTooltipHandler={externalTooltipHandler}
          centerValue={centerValue}
          chartData={chartData.groupAveragesData}
          effectType={effectType}
          formatValue={() => ""}
        />
      );
  }
}

interface GraphControlsProps {
  graphType: GraphType;
  onGraphTypeChange: (graphType: GraphType) => void;
}

function GraphControls(props: GraphControlsProps) {
  const { graphType, onGraphTypeChange } = props;
  return (
    <div className="d-flex justify-content-end">
      <SwapChart graphType={graphType} onGraphTypeChange={onGraphTypeChange} />
    </div>
  );
}

interface SwapChartProps {
  graphType: GraphType;
  onGraphTypeChange: (graphType: GraphType) => void;
}

function SwapChart(props: SwapChartProps) {
  const { graphType, onGraphTypeChange } = props;
  return (
    <div className="d-flex ml-auto">
      <RadioToggleButtonGroup
        onChange={(graphType) => onGraphTypeChange(graphType)}
        options={[
          {
            key: "donut",
            label: <Doughnut width={20} />,
            value: GraphType.DOUGHNUT,
          },
          { key: "bar", label: <Bar width={20} />, value: GraphType.BAR },
        ]}
        size="sm"
        value={graphType}
      />
    </div>
  );
}
