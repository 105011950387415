import ReadResult from "./ReadResult";
import TextInput, { TextInputType } from "./TextInput";

export interface Value {
  hasError: boolean;
  text: string;
}

export function blankValue(): Value {
  return initialValue("");
}

export function initialValue(text: string): Value {
  return value(text, { hasError: false });
}

export function value(
  text: string,
  { hasError }: { hasError: boolean }
): Value {
  return {
    hasError,
    text,
  };
}

export function read(value: Value): ReadResult<Value, string> {
  if (value.text.trim() === "") {
    return {
      hasError: true,
      value: { ...value, hasError: true },
    };
  } else {
    return {
      hasError: false,
      value: {
        ...value,
        hasError: false,
      },
      input: value.text,
    };
  }
}

interface TextFieldProps {
  autoComplete?: "off";
  autoFocus?: boolean;
  className?: string;
  id?: string;
  onBlur?: () => void;
  onChange: (value: Value) => void;
  onFocus?: () => void;
  placeholder?: string;
  type?: TextInputType;
  value: Value;
}

export function TextField(props: TextFieldProps) {
  const {
    autoComplete,
    autoFocus,
    className,
    id,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    type,
    value,
  } = props;

  return (
    <TextInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      className={className}
      hasError={value.hasError}
      id={id}
      onBlur={onBlur}
      onChange={(text) => onChange({ ...value, text })}
      onFocus={onFocus}
      placeholder={placeholder}
      type={type}
      value={value.text}
    />
  );
}
