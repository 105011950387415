import Card from "../../../../Card";
import * as RatingScaleLabels from "./index";

const stories = {
  name: "RatingScaleLabels",
  render: () => (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
      {Object.entries(RatingScaleLabels).map(([vectorName, Vector], index) => (
        <Card
          className="d-flex flex-column justify-content-between m-2 p-2"
          key={index}
          style={{ border: "1px solid" }}
        >
          <Vector className="mb-4" height={64} width={92} />
          {vectorName}
        </Card>
      ))}
    </div>
  ),
};

export default stories;
