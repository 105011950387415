import gql from "graphql-tag";

import { useOrganization } from "../components/organizations/OrganizationProvider";
import { useIsStaff } from "../services/useFeatureFlags";
import useUserInfo from "./useUserInfo";

export default function useIsAdmin(): boolean {
  const [userInfo] = useUserInfo();
  const [activeOrganization] = useOrganization();
  const isStaff = useIsStaff();

  return (
    isStaff ||
    userInfo.organizationMemberships.some(
      ({ isAdministrator, organization }) =>
        organization.id === activeOrganization.id && isAdministrator
    )
  );
}

export const fragments = {
  organizationMembership: gql`
    fragment useIsAdmin_OrganizationMembership on OrganizationMembership {
      isAdministrator
    }
  `,
};
