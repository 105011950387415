import { useState } from "react";
import { useCallbackOne } from "use-memo-one";

export function useLocalStorageState(
  key: string
): [string | null, (value: string | null) => void] {
  const [value, setValue] = useState<string | null>(() =>
    localStorage.getItem(key)
  );

  const set = useCallbackOne(
    (value: string | null) => {
      setValue(value);
      try {
        if (value === null) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, value);
        }
      } catch {}
    },
    [key]
  );

  return [value, set];
}
