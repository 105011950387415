import gql from "graphql-tag";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import {
  BillingInterval,
  CountryCode,
  Currency as GraphqlCurrency,
} from "../../__generated__/globalTypes";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import {
  BillingIntervalDisplay,
  Currency,
} from "../../util/currencyFormatting";
import { dateToDayAndMonth } from "../../util/date";
import { useGraphQL } from "../graphql/GraphQLProvider";
import {
  BillSummary_PromotionQuery as PromotionQuery,
  BillSummary_PromotionQueryVariables as PromotionQueryVariables,
  BillSummary_TierProduct as TierProduct,
  BillSummary_Invoice as Invoice,
} from "./BillSummary.graphql";
import { PromotionDetails } from "./PlansPage";
import PromotionCodeSection from "./PromotionCodeSection";

interface BillSummaryProps {
  billingInterval: BillingInterval;
  currency: GraphqlCurrency;
  onApplyPromotionCode: ((promotionDetails: PromotionDetails) => void) | null;
  appliedPromotionCode: string;
  couponId: string | null;
  tierProduct: TierProduct;
  invoice: Invoice;
}

export function BillSummary(props: BillSummaryProps) {
  const {
    billingInterval,
    currency,
    onApplyPromotionCode,
    appliedPromotionCode,
    couponId,
    tierProduct,
    invoice,
  } = props;

  const date = new Date();

  const billingFrequencyDisplay = (
    billingInterval: BillingInterval
  ): React.ReactNode => {
    if (billingInterval === BillingInterval.MONTH) {
      return (
        <BillingIntervalDisplay
          billingInterval={BillingInterval.MONTH}
          type="noun"
        />
      );
    } else if (billingInterval === BillingInterval.YEAR) {
      return (
        <FormattedMessage
          id="components/subscriptions/BillSummary:billingFrequency"
          defaultMessage="12 {months}"
          values={{
            months: (
              <BillingIntervalDisplay
                billingInterval={BillingInterval.MONTH}
                type="pluralNoun"
              />
            ),
          }}
        />
      );
    }
  };

  return (
    <>
      <h6 className="semi-bold-font mb-3">
        <FormattedMessage
          id="components/subscriptions/BillSummary:title"
          defaultMessage="Bill Summary"
        />
      </h6>
      <p className="mb-4">
        <FormattedMessage
          id="components/subscriptions/BillSummary:subtitle"
          defaultMessage="What you will pay {adjectivalBillingInterval}"
          values={{
            adjectivalBillingInterval: (
              <BillingIntervalDisplay
                billingInterval={billingInterval}
                type="adjective"
              />
            ),
          }}
        />
      </p>
      <BillTable
        applyPromotionCode={onApplyPromotionCode}
        appliedPromotionCode={appliedPromotionCode}
        couponId={couponId}
        currency={currency}
        tierProduct={tierProduct}
        upcomingInvoice={invoice}
      />
      <p className="small text-muted pb-3">
        <FormattedMessage
          id="components/subscriptions/BillSummary:dueDate"
          defaultMessage="Due on {billingDate}, then every {billingInterval}"
          values={{
            billingDate: dateToDayAndMonth(date),
            billingInterval: billingFrequencyDisplay(billingInterval),
          }}
        />
      </p>
    </>
  );
}

interface BillTableProps {
  applyPromotionCode: ((promotionDetails: PromotionDetails) => void) | null;
  appliedPromotionCode: string;
  couponId?: string | null;
  currency: GraphqlCurrency;
  tierProduct: TierProduct;
  upcomingInvoice: Invoice;
}

function BillTable(props: BillTableProps) {
  const {
    applyPromotionCode,
    appliedPromotionCode,
    couponId,
    currency,
    tierProduct,
    upcomingInvoice,
  } = props;

  const { trackPromotionCodeApplied } = useTracking();
  const graphql = useGraphQL();

  const [invalidPromotionCode, setInvalidPromotionCode] =
    useState<boolean>(false);

  function removePromotionCode() {
    if (applyPromotionCode === null) {
      throw new Error("Cannot remove promotion code on payment details step");
    }
    applyPromotionCode({
      appliedPromotionCode: "",
      promotion: null,
    });
  }

  async function handlePromotionCodeApply(promotionCode: string | null) {
    if (promotionCode === null) {
      throw new Error("No promo code entered");
    }

    if (applyPromotionCode === null) {
      throw new Error("Cannot apply promotion code on payment details step");
    }

    // Set invalid to false to hide any visible error state
    // whilst the query is running.
    setInvalidPromotionCode(false);

    const { promotion } = await graphql.fetch<
      PromotionQuery,
      PromotionQueryVariables
    >({
      query: promotionQuery,
      variables: {
        promotionCode,
      },
    });

    const promotionCouponId = promotion?.couponId;

    if (promotion && promotionCouponId && promotionCouponId !== couponId) {
      trackPromotionCodeApplied({
        couponId: promotionCouponId,
        couponName: promotion.couponName,
        promotionCode: promotionCode.toUpperCase(),
        promotionId: promotion.id,
        success: true,
      });

      applyPromotionCode({
        appliedPromotionCode: promotionCode,
        promotion: {
          appliedPromotionId: promotion.id,
          couponId: promotionCouponId,
        },
      });
    } else if (promotion === null) {
      trackPromotionCodeApplied({
        couponId: null,
        couponName: null,
        promotionCode: promotionCode.toUpperCase(),
        promotionId: null,
        success: false,
      });

      if (couponId !== null) {
        removePromotionCode();
      }
    }

    setInvalidPromotionCode(promotion === null);
  }

  const getTaxRowText = (country: CountryCode): React.ReactNode => {
    if (country === CountryCode.UNITED_STATES_OF_AMERICA) {
      return (
        <FormattedMessage
          id="components/subscriptions/BillSummary:salesTax"
          defaultMessage="Sales Tax"
        />
      );
    } else if (country === CountryCode.UNITED_KINGDOM) {
      return (
        <FormattedMessage
          id="components/subscriptions/BillSummary:vatAmount"
          defaultMessage="VAT Amount"
        />
      );
    } else {
      assertNever(country, "Unsupported country");
    }
  };

  const tierProductCost = upcomingInvoice.items.find(
    (invoiceItem) => invoiceItem.productId === tierProduct.id
  )?.totalCost;
  if (tierProductCost === undefined) {
    throw new Error(`Could not find tier product cost for ${tierProduct.id}`);
  }

  const recipeAllowanceQuantity = upcomingInvoice.items.find(
    (invoiceItem) =>
      invoiceItem.productId === upcomingInvoice.recipeAllowanceProductId &&
      invoiceItem.quantity !== 0
  )?.quantity;
  if (recipeAllowanceQuantity === undefined) {
    throw new Error(
      `Could not find product allowance quantity for ${upcomingInvoice.recipeAllowanceProductId}`
    );
  }

  const recipeAllowanceCost = upcomingInvoice.items.find(
    (invoiceItem) =>
      invoiceItem.productId === upcomingInvoice.recipeAllowanceProductId &&
      invoiceItem.quantity === 0
  )?.totalCost;
  if (recipeAllowanceCost === undefined) {
    throw new Error(
      `Could not find product allowance cost for ${upcomingInvoice.recipeAllowanceProductId}`
    );
  }

  const discountAmount = upcomingInvoice.discountAmount;

  return (
    <div
      className="d-flex align-items-center m-0 p-0"
      style={{ minHeight: "191.39px" }}
    >
      <Container className="pl-0">
        <Row className="mb-3">
          <Col className="align-self-end">
            <p className="semi-bold-font mb-1">
              <FormattedMessage
                id="components/subscriptions/BillSummary:tierProductPlan"
                defaultMessage="{tierProduct} plan"
                values={{
                  tierProduct: tierProduct.name,
                }}
              />
            </p>
          </Col>
          <Col>
            <h4 className="mb-0 text-right">
              <Currency
                currency={upcomingInvoice.currency}
                pence={tierProductCost}
              />
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="align-self-end">
            <p className="semi-bold-font mb-1">
              <FormattedMessage
                id="components/subscriptions/BillSummary:recipeAllowanceDescription"
                defaultMessage="{recipeAllowance} Recipes"
                values={{
                  recipeAllowance: recipeAllowanceQuantity,
                }}
              />
            </p>
          </Col>
          <Col>
            <h4 className="mb-0 text-right">
              <Currency
                currency={upcomingInvoice.currency}
                pence={recipeAllowanceCost}
              />
            </h4>
          </Col>
        </Row>
        {!couponId && !applyPromotionCode ? null : (
          <PromotionCodeSection
            appliedPromotionCode={appliedPromotionCode}
            currency={currency}
            discountAmount={discountAmount}
            handleRemove={
              applyPromotionCode === null ? null : removePromotionCode
            }
            handleSubmit={handlePromotionCodeApply}
            hasError={invalidPromotionCode}
            type={couponId ? "applied" : "entry"}
          />
        )}
        <hr />
        <Row className="mb-3 pt-3">
          <Col className="align-self-end">
            <p className="semi-bold-font mb-2">
              <FormattedMessage
                id="components/subscriptions/BillSummary:subtotal"
                defaultMessage="Subtotal"
              />
            </p>
          </Col>
          <Col>
            <h4 className="mb-0 text-right">
              <Currency
                currency={upcomingInvoice.currency}
                pence={tierProductCost + recipeAllowanceCost - discountAmount}
              />
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="align-self-end">
            <p className="semi-bold-font mb-0">
              {getTaxRowText(upcomingInvoice.customerCountry)}
            </p>
          </Col>
          <Col>
            <h4 className="mb-0 text-right">
              <Currency
                currency={upcomingInvoice.currency}
                pence={upcomingInvoice?.taxAmount}
              />
            </h4>
          </Col>
        </Row>
        <hr className="bg-dark mb-4" />
        <Row className="mb-4 pt-2">
          <Col className="align-self-end">
            <p className="semi-bold-font mb-2">
              <FormattedMessage
                id="components/subscriptions/BillSummary:total"
                defaultMessage="Total"
              />
            </p>
          </Col>
          <Col>
            <h2 className="mb-0 text-right">
              <Currency
                currency={upcomingInvoice.currency}
                pence={upcomingInvoice?.totalCost}
              />
            </h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

BillSummary.fragments = {
  tierProduct: gql`
    fragment BillSummary_TierProduct on TierProduct {
      id
      name
      price {
        month {
          gbp
          usd
        }
        year {
          gbp
          usd
        }
      }
      tier
    }
  `,
  invoice: gql`
    fragment BillSummary_Invoice on Invoice {
      currency
      customerCountry
      discountAmount
      items {
        currency
        productId
        productName
        quantity
        totalCost
      }
      recipeAllowanceProductId
      taxAmount
      totalCost
    }
  `,
};

const promotionQuery = gql`
  query BillSummary_PromotionQuery($promotionCode: String!) {
    promotion(promotionCode: $promotionCode) {
      code
      couponId
      couponName
      id
    }
  }
`;
