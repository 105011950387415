import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div id="ft" className="small-copy">
        &#169; {new Date().getFullYear()} Foodsteps
      </div>
    </div>
  );
}
