import { gql } from "graphql-tag";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import {
  useEditProducts,
  useTags,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import useQuery from "../graphql/useQuery";
import Page from "../Page";
import { usePages } from "../pages";
import * as RecipesListPageLocationState from "../recipes/RecipesListPageLocationState";
import RecipesPanel from "../recipes/RecipesPanel";
import useRecipeLabel from "../recipes/useRecipeLabel";
import StatusDisplay from "../StatusDisplay";
import {
  CollectionPage_CollectionQuery as CollectionQuery,
  CollectionPage_CollectionQueryVariables as CollectionQueryVariables,
} from "./CollectionPage.graphql";

const collectionPageTracking = { pageName: "collection" };

export default function CollectionPage() {
  const pages = usePages();
  const recipeLabel = useRecipeLabel();
  const editProducts = useEditProducts();
  const hasFeatureTags = useTags();

  const { collectionId } = pages.Collection.useParams();

  const collectionFilter = { ids: [collectionId] };

  const { status: collectionDataStatus } = useQuery<
    CollectionQuery,
    CollectionQueryVariables
  >(collectionQuery, { collectionFilter });

  const recipeQueryParams = RecipesListPageLocationState.useQueryParams();
  const { trackCollectionViewed } = useTracking();

  useEffect(() => {
    if (collectionDataStatus.type !== "success") {
      return;
    }

    const collectionName =
      collectionDataStatus.value.recipeCollection?.name ?? "";

    trackCollectionViewed({ collectionId, collectionName });
  }, [trackCollectionViewed, collectionId, collectionDataStatus]);

  return (
    <Page tracking={collectionPageTracking}>
      <div className="h-100 d-flex flex-column">
        <StatusDisplay status={collectionDataStatus}>
          {({ recipeCollection }) => {
            if (recipeCollection === null) {
              return (
                <>
                  <Page.Title
                    breadcrumb={pages.Collection.breadcrumbNotFound()}
                  />
                  <p className="mt-4">
                    <FormattedMessage
                      id="components/collections/CollectionPage:collectionNotFound"
                      defaultMessage="Collection not found."
                    />
                  </p>
                </>
              );
            } else {
              return (
                <>
                  <Page.Title
                    breadcrumb={pages.Collection.breadcrumb(
                      recipeCollection,
                      recipeQueryParams
                    )}
                  />
                  <RecipesPanel
                    newRecipeUrl={
                      pages.CollectionRecipesNew.url === null ||
                      !(
                        recipeCollection.viewerHasPermissionUpdate &&
                        editProducts
                      )
                        ? undefined
                        : pages.CollectionRecipesNew.url(recipeCollection)
                    }
                    noRecipesMessage={
                      <FormattedMessage
                        id="components/collections/CollectionPage:noRecipesMessage"
                        defaultMessage="
                        There are no {recipeLabel} in this collection.
                      "
                        values={{ recipeLabel: recipeLabel.pluralLowercase }}
                      />
                    }
                    noRecipesDueToSearchMessage={
                      <FormattedMessage
                        id="components/collections/CollectionPage:noRecipesDueToSearchMessage"
                        defaultMessage="
                        There are no {recipeLabel} in this collection.
                      "
                        values={{ recipeLabel: recipeLabel.pluralLowercase }}
                      />
                    }
                    pageName="Collections"
                    recipeFilter={{ collections: collectionFilter }}
                    recipeUrl={(recipe) =>
                      pages
                        .CollectionRecipe(hasFeatureTags)
                        .url(recipeCollection, recipe)
                    }
                    showAddDropdown={true}
                    showCopyToButton={false}
                    showExportDropdown={true}
                    showFilterToRequiresAttention={true}
                    showManageTagsButton={false}
                    selectCollection={null}
                    includeRecipesUsedAsIngredient={true}
                    includeRecipesOnlyAccessibleViaCollection={true}
                  />
                </>
              );
            }
          }}
        </StatusDisplay>
      </div>
    </Page>
  );
}

const collectionQuery = gql`
  query CollectionPage_CollectionQuery(
    $collectionFilter: RecipeCollectionFilter!
  ) {
    recipeCollection(filter: $collectionFilter) {
      id
      name
      viewerHasPermissionUpdate
    }
  }
`;
