import confirmSignUpFormStories from "./sign-up/ConfirmSignUpForm.stories";
import endMileTypeQuestionsFormStories from "./sign-up/EndMileTypeQuestionsForm.stories";
import regionSelectionFormStories from "./sign-up/RegionSelectionForm.stories";
import signUpDetailsFormStories from "./sign-up/SignUpDetailsForm.stories";

const stories = {
  name: "account",
  children: [
    confirmSignUpFormStories,
    endMileTypeQuestionsFormStories,
    regionSelectionFormStories,
    signUpDetailsFormStories,
  ],
};

export default stories;
