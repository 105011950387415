import gql from "graphql-tag";
import React from "react";
import { useIntl } from "react-intl";

import { BillingInterval, Currency } from "../../__generated__/globalTypes";
import { useFormatPricePerBillingInterval } from "../../util/currencyFormatting";
import Select from "../utils/Select";
import useId from "../utils/useId";
import { RecipeAllowanceProductSelect_RecipeAllowanceProduct as RecipeAllowanceProduct } from "./RecipeAllowanceProductSelect.graphql";
import { priceForBillingInterval, recipeAllowanceToNumber } from "./utils";

interface RecipeAllowanceProductSelectProps {
  billingInterval: BillingInterval;
  currency: Currency;
  onChange: (value: RecipeAllowanceProduct) => void;
  recipeAllowanceProducts: Array<RecipeAllowanceProduct>;
  value: RecipeAllowanceProduct;
}

export function RecipeAllowanceProductSelect(
  props: RecipeAllowanceProductSelectProps
) {
  const {
    billingInterval,
    currency,
    onChange,
    recipeAllowanceProducts,
    value,
  } = props;
  const id = useId();
  const intl = useIntl();
  const formatPricePerBillingInterval = useFormatPricePerBillingInterval();

  const renderRecipeAllowanceProduct = (
    recipeAllowanceProduct: RecipeAllowanceProduct
  ) => {
    const price = formatPricePerBillingInterval(
      billingInterval,
      currency,
      priceForBillingInterval({
        billingInterval,
        prices: recipeAllowanceProduct.price,
        currency,
      }),
      {
        currency,
        maximumFractionDigits: 0,
      }
    );
    const recipeAllowance = intl.formatNumber(
      recipeAllowanceToNumber(recipeAllowanceProduct.recipeAllowance)
    );
    return intl.formatMessage(
      {
        id: "components/subscriptions/RecipeAllowanceProductSelect:selection",
        defaultMessage: `{recipeAllowance} Products (+{price})`,
      },
      {
        price,
        recipeAllowance,
      }
    );
  };

  const handleChange = (value: RecipeAllowanceProduct | null) => {
    if (value === null) {
      throw new Error("A RecipeAllowanceProduct must be selected.");
    } else {
      onChange(value);
    }
  };

  return (
    <Select
      className="small text-left"
      id={id}
      isClearable={false}
      onChange={handleChange}
      optionKey={recipeAllowanceProductKey}
      options={recipeAllowanceProducts}
      renderOption={renderRecipeAllowanceProduct}
      value={value}
    />
  );
}

const recipeAllowanceProductKey = (
  recipeAllowanceProduct: RecipeAllowanceProduct
) => recipeAllowanceProduct.recipeAllowance.toString();

RecipeAllowanceProductSelect.fragments = {
  recipeAllowanceProduct: gql`
    fragment RecipeAllowanceProductSelect_RecipeAllowanceProduct on RecipeAllowanceProduct {
      price {
        month {
          gbp
          usd
        }
        year {
          gbp
          usd
        }
      }
      recipeAllowance
    }
  `,
};
