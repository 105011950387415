import { gql } from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { RecipePackagingComponentV2Input } from "../../../__generated__/globalTypes";
import * as FloatInput from "../../utils/FloatInput";
import ReadResult from "../../utils/ReadResult";
import Select from "../../utils/Select";
import TableEditor from "../../utils/TableEditor";
import useRecipeLabel from "../useRecipeLabel";
import {
  PackagingComponentEditor_PackagingComponent as PackagingComponent,
  PackagingComponentEditor_RecipePackagingComponent as RecipePackagingComponent,
} from "./PackagingComponentEditor.graphql";
import "./PackagingComponentEditor.css";

export interface Value {
  hasError: boolean;
  key: string;
  packagingComponent: PackagingComponent | null;
  multiplesOfProduct: FloatInput.Value;
}

export const blankValue = () => {
  return {
    hasError: false,
    key: `new_${uuidv4()}`,
    packagingComponent: null,
    multiplesOfProduct: FloatInput.initialValue(1),
  };
};

export const existingValue = (
  recipePackagingComponent: RecipePackagingComponent
): Value => {
  return {
    hasError: false,
    key: `existing_${uuidv4()}`,
    packagingComponent: recipePackagingComponent.packagingComponent,
    multiplesOfProduct: FloatInput.initialValue(
      recipePackagingComponent.amountOfProductsPackaged
    ),
  };
};

export function read(
  value: Value
): ReadResult<Value, RecipePackagingComponentV2Input> {
  const multiplesOfProductResult = FloatInput.read({
    value: value.multiplesOfProduct,
    validationCondition: (value) => value >= 0,
  });
  if (multiplesOfProductResult.hasError || value.packagingComponent === null) {
    return {
      hasError: true,
      value: {
        ...value,
        hasError: true,
        multiplesOfProduct: multiplesOfProductResult.value,
      },
    };
  } else {
    return {
      hasError: false,
      value,
      input: {
        packagingComponentId: value.packagingComponent.id,
        amountOfProductsPackaged: multiplesOfProductResult.input,
        id: null,
      },
    };
  }
}

interface PackagingComponentEditorProps {
  onChange: (value: Value) => void;
  onDelete: () => void;
  packagingComponents: Array<PackagingComponent>;
  value: Value;
}

export function PackagingComponentEditor(props: PackagingComponentEditorProps) {
  const { onChange, packagingComponents, value, onDelete } = props;

  const recipeLabel = useRecipeLabel();

  const multiplesOfProductUnitLabel =
    value.multiplesOfProduct.text === "1"
      ? recipeLabel.singularLowercase
      : recipeLabel.pluralLowercase;

  return (
    <div className="PackagingComponentEditor_Container">
      <Select
        className="PackagingComponentEditor_Select"
        hasError={value.hasError}
        options={packagingComponents}
        onChange={(packagingComponent) =>
          onChange({ ...value, packagingComponent, hasError: false })
        }
        value={value.packagingComponent}
        optionKey={(packagingComponent) => packagingComponent.id}
        renderOption={(packagingComponent) => packagingComponent.name}
      />
      <div className="PackagingComponentEditor_Text">
        <FormattedMessage
          id="components/recipes/ProductEditor/PackagingComponentEditor:appliesTo"
          defaultMessage="applies to"
        />
      </div>
      <div className="PackagingComponentEditor_FloatEditorAndDeleteContainer">
        <div className="PackagingComponentEditor_MultiplesOfProductEditor input-group">
          <FloatInput.FloatInput
            onChange={(multiplesOfProduct) =>
              onChange({ ...value, multiplesOfProduct })
            }
            value={value.multiplesOfProduct}
          />
          <div
            className="input-group-append"
            style={{ lineHeight: "1rem", width: "94px" }}
          >
            <span className="input-group-text w-100 d-flex justify-content-center">
              {multiplesOfProductUnitLabel}
            </span>
          </div>
        </div>
        <div className="PhysicalOutputEditor_DeleteColumn">
          <TableEditor.DeleteCell onDelete={onDelete} />
        </div>
      </div>
    </div>
  );
}

export function PackagingComponentEditorHeader() {
  return (
    <div className="PackagingComponentEditor_Container">
      <div className="PackagingComponentEditor_Select medium-font">
        <FormattedMessage
          id="components/recipes/ProductEditor/PackagingComponentEditor:packagingComponents"
          defaultMessage="Packaging Components"
        />
      </div>
      <div className="PackagingComponentEditor_Text" />
      <div className="PackagingComponentEditor_FloatEditorAndDeleteContainer medium-font">
        <FormattedMessage
          id="components/recipes/ProductEditor/PackagingComponentEditor:multiplesOfProduct"
          defaultMessage="Multiples of Product"
        />
      </div>
    </div>
  );
}

export const fragments = {
  packagingComponent: gql`
    fragment PackagingComponentEditor_PackagingComponent on PackagingComponentV2 {
      id
      name
    }
  `,
  recipePackagingComponent: gql`
    fragment PackagingComponentEditor_RecipePackagingComponent on RecipePackagingComponentV2 {
      amountOfProductsPackaged
      packagingComponent {
        id
        name
      }
    }
  `,
};
