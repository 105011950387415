import TextInput from "./TextInput";
import useId from "./useId";

interface LabelledTextInputProps {
  label: React.ReactNode;
  onChange: (value: string) => void;
  value: string;
}

export function LabelledTextInput(props: LabelledTextInputProps) {
  const { label, onChange, value } = props;
  const id = useId();

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <TextInput id={id} onChange={(value) => onChange(value)} value={value} />
    </div>
  );
}
