import classNames from "classnames";
import "./Card.css";

interface CardProps {
  children: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
  selected?: boolean;
  shadow?: boolean;
  style?: React.CSSProperties;
  setHover?: (hover: boolean) => void;
  setSelected?: (selected: boolean) => void;
  styleCardOnHover?: boolean;
}

export default function Card(props: CardProps) {
  const {
    children,
    className,
    fullHeight,
    selected = false,
    setHover,
    setSelected = () => {},
    shadow = true,
    style,
    styleCardOnHover = true,
  } = props;

  return (
    <div
      className={classNames("card rounded p-3", className, {
        "h-100": fullHeight,
        card__hover: setHover && styleCardOnHover,
        card__selected: selected,
        shadow,
        card_no_shadow_border: !shadow,
      })}
      onMouseOver={setHover ? () => setHover(true) : undefined}
      onMouseLeave={setHover ? () => setHover(false) : undefined}
      onClick={() => setSelected(!selected)}
      style={style}
    >
      {children}
    </div>
  );
}
