import { useState } from "react";

import { PackagingComponentSelect, Value } from "./PackagingComponentSelect";
import { PackagingComponentSelect_PackagingComponentV2 as PackagingComponentV2 } from "./PackagingComponentSelect.graphql";
import { packagingComponentV2Stub } from "./stubs";

const stories = {
  name: "PackagingComponentSelect",
  children: [
    {
      name: "PackagingComponentSelect",
      render: () => <Story />,
    },
    {
      name: "PackagingComponentSelect in error state",
      render: () => <Story defaultHasError={true} />,
    },
  ],
};

interface StoryProps {
  defaultHasError?: boolean;
}

function Story(props: StoryProps) {
  const { defaultHasError } = props;
  const [selectedPackagingComponents, setSelectedPackagingComponents] =
    useState<Array<PackagingComponentV2>>([]);
  const [hasError, setHasError] = useState<boolean | undefined>(
    defaultHasError
  );

  const handleChange = (value: Value) => {
    setSelectedPackagingComponents(value.selectedPackagingComponents);
    setHasError(value.hasError);
  };

  return (
    <div className="w-50">
      <PackagingComponentSelect
        hasError={hasError}
        placeholder="Search Packaging Components"
        packagingComponents={[
          packagingComponentV2Stub({
            id: "1",
            name: "Component 1",
            ownerOrganizationId: "1",
          }),
          packagingComponentV2Stub({
            id: "2",
            name: "Custom 2",
            ownerOrganizationId: "1",
          }),
          packagingComponentV2Stub({
            id: "3",
            name: "Component New 3",
            ownerOrganizationId: "1",
          }),
          packagingComponentV2Stub({
            id: "4",
            name: "Standard 4",
            ownerOrganizationId: null,
          }),
          packagingComponentV2Stub({
            id: "5",
            name: "Shop 5",
            ownerOrganizationId: null,
          }),
        ]}
        onChange={handleChange}
        value={{ selectedPackagingComponents, hasError: hasError ?? false }}
      />
    </div>
  );
}

export default stories;
