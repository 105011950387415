import gql from "graphql-tag";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { IconButton } from "../utils/Button";
import { Group, GroupedSelect, Option } from "../utils/Select";
import { Delete, Search } from "../utils/Vectors";
import { PackagingComponentSelect_PackagingComponentV2 as PackagingComponentV2 } from "./PackagingComponentSelect.graphql";

export type Value = {
  selectedPackagingComponents: Array<PackagingComponentV2>;
  hasError: boolean;
};

interface PackagingComponentSelectProps {
  hasError?: boolean;
  packagingComponents: Array<PackagingComponentV2>;
  onChange: (value: Value) => void;
  placeholder: string;
  value: Value;
}

export function PackagingComponentSelect(props: PackagingComponentSelectProps) {
  const { packagingComponents, onChange, value } = props;

  const { selectedPackagingComponents, hasError } = value;

  const customPackagingComponents = () => {
    return packagingComponents.filter(
      (component) => component.ownerOrganizationId !== null
    );
  };

  const standardPackagingComponents = () => {
    return packagingComponents.filter(
      (component) => component.ownerOrganizationId === null
    );
  };

  const customOptions: Array<Option<PackagingComponentV2>> =
    customPackagingComponents().map((component) => ({
      value: component,
      label: component.name,
    }));
  const standardOptions: Array<Option<PackagingComponentV2>> =
    standardPackagingComponents().map((component) => ({
      value: component,
      label: component.name,
    }));

  const intl = useIntl();

  const handleChange = (
    selectedPackagingComponents: Array<PackagingComponentV2>
  ) => {
    onChange({
      hasError: false, // Reset the error state
      selectedPackagingComponents,
    });
  };

  const handleSelect = (component: PackagingComponentV2) => {
    handleChange([...selectedPackagingComponents, component]);
  };

  const handleDeselect = (indexToRemove: number) => {
    handleChange(
      selectedPackagingComponents.filter((_, index) => index !== indexToRemove)
    );
  };

  const groups: Array<Group<PackagingComponentV2>> = [
    {
      label: intl.formatMessage({
        defaultMessage: "My Custom Components",
        id: "components/packaging/PackagingComponentSelect:myCustomComponents",
      }),
      options: customOptions,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Standard Components",
        id: "components/packaging/PackagingComponentSelect:standardComponents",
      }),
      options: standardOptions,
    },
  ];

  return (
    <>
      {selectedPackagingComponents.map((component, index) => (
        <span key={index} className="w-100 d-flex mb-2">
          <div className="medium-font flex-grow-1">{component.name}</div>
          <div>
            <IconButton
              icon={<Delete fill="var(--dark-turquoise)" width="20px" />}
              onClick={() => handleDeselect(index)}
            />
          </div>
        </span>
      ))}
      <GroupedSelect<PackagingComponentV2>
        hasError={hasError}
        icon={<Search width={"1rem"} fill="grey" />}
        placeholder={
          <FormattedMessage
            defaultMessage="Search Packaging Components"
            id="components/packaging/PackagingComponentSelect:placeholder"
          />
        }
        groups={groups}
        value={null}
        onChange={(option) => option !== null && handleSelect(option.value)}
      />
    </>
  );
}

PackagingComponentSelect.fragments = {
  packagingComponent: gql`
    fragment PackagingComponentSelect_PackagingComponentV2 on PackagingComponentV2 {
      id
      name
      ownerOrganizationId
    }
  `,
};
