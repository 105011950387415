import { effectTypes } from "../../domain/EffectType";
import RecipePackagingComponentTable from "./RecipePackagingComponentTable";
import { RecipePackagingComponentTable_PackagingComponentV2 as PackagingComponentV2 } from "./RecipePackagingComponentTable.graphql";

const packagingComponents: Array<PackagingComponentV2> = [
  {
    ghgMagnitude: 0.52,
    id: "hi",
    name: "Component 1",
    componentMaterials: [{ weightKg: 0.03 }, { weightKg: 0.04 }],
  },
  {
    ghgMagnitude: 0.12,
    id: "hfi",
    name: "Component 2",
    componentMaterials: [{ weightKg: 0.13 }],
  },
  {
    ghgMagnitude: 5.0,
    id: "hgi",
    name: "Component 3",
    componentMaterials: [{ weightKg: 9.03 }],
  },
];

const stories = {
  name: "RecipePackagingComponentTable",
  children: [
    {
      name: "default",
      render: () => (
        <RecipePackagingComponentTable
          disabled={false}
          effectType={effectTypes.ghgPerServing}
          packagingComponents={packagingComponents}
        />
      ),
    },
    {
      name: "disabled",
      render: () => (
        <RecipePackagingComponentTable
          disabled={true}
          effectType={effectTypes.ghgPerServing}
          packagingComponents={packagingComponents}
        />
      ),
    },
  ],
};

export default stories;
