import VectorProps from "./VectorProps";

export default function Inputs(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=djPELc7GvHaLzkcp-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.32403 14.8368C2.35315 14.8368 1.56609 15.6238 1.56609 16.5947C1.56609 17.5656 2.35315 18.3527 3.32403 18.3527C4.29492 18.3527 5.08198 17.5656 5.08198 16.5947C5.08198 15.6238 4.29492 14.8368 3.32403 14.8368ZM0 16.5947C0 14.7589 1.48822 13.2707 3.32403 13.2707C5.15985 13.2707 6.64807 14.7589 6.64807 16.5947C6.64807 18.4305 5.15985 19.9188 3.32403 19.9188C1.48822 19.9188 0 18.4305 0 16.5947Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.32403 8.20144C2.35315 8.20144 1.56609 8.98849 1.56609 9.95938C1.56609 10.9303 2.35315 11.7173 3.32403 11.7173C4.29492 11.7173 5.08198 10.9303 5.08198 9.95938C5.08198 8.9885 4.29492 8.20144 3.32403 8.20144ZM0 9.95938C0 8.12357 1.48822 6.63534 3.32403 6.63534C5.15985 6.63534 6.64807 8.12357 6.64807 9.95938C6.64807 11.7952 5.15985 13.2834 3.32403 13.2834C1.48822 13.2834 0 11.7952 0 9.95938Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.32403 1.56609C2.35315 1.56609 1.56609 2.35315 1.56609 3.32403C1.56609 4.29492 2.35315 5.08198 3.32403 5.08198C4.29492 5.08198 5.08198 4.29492 5.08198 3.32403C5.08198 2.35315 4.29492 1.56609 3.32403 1.56609ZM0 3.32403C0 1.48822 1.48822 0 3.32403 0C5.15985 0 6.64807 1.48822 6.64807 3.32403C6.64807 5.15985 5.15985 6.64807 3.32403 6.64807C1.48822 6.64807 0 5.15985 0 3.32403Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08198 3.32403C5.08198 2.89157 5.43256 2.54099 5.86502 2.54099H9.43963C10.9909 2.54099 12.2478 3.79795 12.2478 5.34919V9.17633H18.4574C18.8899 9.17633 19.2404 9.52691 19.2404 9.95938C19.2404 10.3918 18.8899 10.7424 18.4574 10.7424H11.4648C11.0323 10.7424 10.6817 10.3918 10.6817 9.95938V5.34919C10.6817 4.66288 10.1259 4.10708 9.43963 4.10708H5.86502C5.43256 4.10708 5.08198 3.7565 5.08198 3.32403Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6739 9.94763C10.6739 9.51517 11.0245 9.16459 11.457 9.16459H18.4496C18.882 9.16459 19.2326 9.51517 19.2326 9.94763C19.2326 10.3801 18.882 10.7307 18.4496 10.7307H12.24V14.5578C12.24 16.1091 10.983 17.366 9.4318 17.366H5.85719C5.42473 17.366 5.07415 17.0154 5.07415 16.583C5.07415 16.1505 5.42473 15.7999 5.85719 15.7999H9.4318C10.1181 15.7999 10.6739 15.2441 10.6739 14.5578V9.94763Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08198 9.95938C5.08198 9.52691 5.43256 9.17633 5.86502 9.17633H16.5272C16.9597 9.17633 17.3102 9.52691 17.3102 9.95938C17.3102 10.3918 16.9597 10.7424 16.5272 10.7424H5.86502C5.43256 10.7424 5.08198 10.3918 5.08198 9.95938Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9391 6.63086C16.2449 6.325 16.7407 6.32492 17.0465 6.63066L19.7706 9.35371C19.9175 9.50056 20 9.69978 20 9.9075C20 10.1152 19.9175 10.3144 19.7706 10.4613L17.0465 13.1843C16.7407 13.4901 16.2449 13.49 15.9391 13.1841C15.6334 12.8783 15.6335 12.3825 15.9393 12.0768L18.1094 9.9075L15.9393 7.73825C15.6335 7.43251 15.6334 6.93671 15.9391 6.63086Z"
      />
    </svg>
  );
}
