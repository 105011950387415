import { Currency } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import Select from "../utils/Select";
import useId from "../utils/useId";

function getCurrencySymbol(currency: Currency): string {
  if (currency === Currency.GBP) {
    return "£";
  } else if (currency === Currency.USD) {
    return "$";
  }
  assertNever(currency, "unexpected currency");
}

interface CurrencyProps {
  onChange: (value: Currency) => void;
  value: Currency;
}

export function CurrencySelect(props: CurrencyProps) {
  const { onChange, value } = props;

  const currencies = Object.values(Currency);

  const id = useId();

  const renderCurrency = (currency: Currency) => {
    const symbol = getCurrencySymbol(currency);
    return `${symbol} ${currency}`;
  };

  const handleChange = (value: Currency | null) => {
    if (value === null) {
      throw new Error("A currency must be selected.");
    } else {
      onChange(value);
    }
  };

  return (
    <Select
      className="small text-left"
      id={id}
      isClearable={false}
      onChange={handleChange}
      optionKey={currencyKey}
      options={currencies}
      renderOption={renderCurrency}
      value={value}
    />
  );
}

const currencyKey = (currency: Currency) => currency.toString();
