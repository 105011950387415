import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import classNames from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { usePages } from "../pages";
import { ContactUsModal } from "../recipes/ContactUsButton";
import Form from "../utils/Form";
import useLoading from "../utils/useLoading";
import { ShieldWithCheckmark } from "../utils/Vectors";
import { PaymentDetailsPaymentStep_TierProduct as TierProduct } from "./PaymentDetailsPaymentStep.graphql";
import PaymentStep, { PaymentStepButton } from "./PaymentStep";

export const EXPECTED_TIER_SEARCH_PARAMETER_NAME = "expected_tier";

interface PaymentDetailsPaymentStepProps {
  billSummary: JSX.Element;
  tierProduct: TierProduct;
}

export default function PaymentDetailsPaymentStep(
  props: PaymentDetailsPaymentStepProps
) {
  const { billSummary, tierProduct } = props;

  const pages = usePages();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<null | React.ReactNode>(null);
  const [paymentDetailsComplete, setPaymentDetailsComplete] =
    useState<boolean>(false);
  const [showContactUsModal, setShowContactUsModal] = useState<boolean>(false);

  const [handleSubmit, submitLoading] = useLoading(async () => {
    if (!stripe || !elements) {
      throw new Error("Could not load stripe or elements.");
    }

    const queryParams = new URLSearchParams({
      [EXPECTED_TIER_SEARCH_PARAMETER_NAME]: tierProduct.tier,
    });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${
          pages.Plans.url
        }?${queryParams.toString()}`,
        payment_method_data: {
          billing_details: {
            address: {
              line1: "",
              line2: "",
              city: "",
              state: "",
              country: "GB",
              postal_code: "",
            },
          },
        },
      },
    });

    // This line will only be reached if confirmPayment fails, and we do not go to the return_url.
    setMessage(
      error.message !== undefined ? (
        error.message
      ) : (
        <FormattedMessage
          id="components/subscriptions/PaymentDetailsPaymentStep:unexpectedError"
          defaultMessage="An unexpected error occurred."
        />
      )
    );
  });

  const paymentDetailsPaymentButton: PaymentStepButton = {
    text: (
      <FormattedMessage
        id="components/subscriptions/PaymentDetailsPaymentStep:confirm"
        defaultMessage="Confirm and pay"
      />
    ),
    loading: submitLoading,
    onClick: handleSubmit,
    disabled: !paymentDetailsComplete,
  };

  const paymentDetails = (
    <>
      <h2 className="mb-4">
        <FormattedMessage
          id="components/subscriptions/PaymentDetailsPaymentStep:enterYourBillingDetails"
          defaultMessage="Enter your payment details"
        />
      </h2>
      <h6 className="semi-bold-font mb-3">
        <FormattedMessage
          id="components/subscriptions/PaymentDetailsPaymentStep:title"
          defaultMessage="Payment Method"
        />
      </h6>
      <Form onSubmit={handleSubmit}>
        <PaymentElement
          className={classNames(message ? "mb-2" : "mb-4")}
          options={{ fields: { billingDetails: { address: "never" } } }}
          onChange={(event) => setPaymentDetailsComplete(event.complete)}
        />
        {message && (
          <div
            className="small semi-bold-font text-danger mb-2"
            id="payment-message"
          >
            {message}
          </div>
        )}
      </Form>
      <p className="small text-muted">
        <ShieldWithCheckmark width="14px" height="16.33px" className="mr-2" />
        <FormattedMessage
          id="components/subscriptions/PaymentDetailsPaymentStep:securityGuarantee"
          defaultMessage="All transactions are securely encrypted by Stripe"
        />
      </p>
      <p className="small text-muted pt-1">
        <FormattedMessage
          id="components/subscriptions/PaymentDetailsPaymentStep:contactForAlternativePaymentMethod"
          defaultMessage="If you are unable to pay by card, <contact>contact Foodsteps</contact> to arrange an alternative payment method."
          values={{
            contact: (chunks: React.ReactNode) => (
              <span
                className="action-link"
                onClick={() => setShowContactUsModal(true)}
              >
                {chunks}
              </span>
            ),
          }}
        />
      </p>
      <ContactUsModal
        messageSubject="Arrange an alternative payment method"
        subject={
          <FormattedMessage
            id="components/subscriptions/PaymentDetailsPaymentStep:"
            defaultMessage="Ask about our alternative payment methods"
          />
        }
        show={showContactUsModal}
        onHide={() => setShowContactUsModal(false)}
      />
    </>
  );

  return (
    <PaymentStep
      billSummary={billSummary}
      button={paymentDetailsPaymentButton}
      content={paymentDetails}
    />
  );
}

PaymentDetailsPaymentStep.fragments = {
  tierProduct: gql`
    fragment PaymentDetailsPaymentStep_TierProduct on TierProduct {
      tier
    }
  `,
};
