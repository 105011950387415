import { createContext, useContext, useState } from "react";

import {
  Button,
  ButtonProps,
  SecondaryButton,
  SecondaryButtonProps,
} from "./Button";
import ErrorAlert from "./ErrorAlert";
import useIsUnmounted from "./useIsUnmounted";

interface FormProps {
  children: React.ReactNode;
  className?: string;
  onSubmit: () => Promise<void>;
}

interface FormState {
  dismissError: () => void;
  error: unknown;
  isLoading: boolean;
}

const FormContext = createContext<FormState | null>(null);

export default function Form(props: FormProps) {
  const { children, className, onSubmit } = props;

  const isUnmounted = useIsUnmounted();
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await onSubmit();
    } catch (error) {
      if (!isUnmounted.current) {
        setError(error);
      }
    } finally {
      if (!isUnmounted.current) {
        setIsLoading(false);
      }
    }
  };

  const formState = {
    dismissError: () => setError(null),
    error,
    isLoading,
  };

  return (
    <FormContext.Provider value={formState}>
      <form className={className} onSubmit={handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
}

function useFormState(): FormState {
  const formState = useContext(FormContext);
  if (formState === null) {
    throw new Error("not inside Form");
  } else {
    return formState;
  }
}

interface FormErrorAlertProps {
  className?: string;
  defaultMessage?: React.ReactNode;
}

Form.ErrorAlert = function FormErrorAlert(props: FormErrorAlertProps) {
  const { className, defaultMessage } = props;
  const { dismissError, error } = useFormState();

  if (error) {
    return (
      <ErrorAlert
        className={className}
        defaultMessage={defaultMessage}
        error={error}
        onClose={dismissError}
      />
    );
  } else {
    return null;
  }
};

Form.SecondaryButton = function FormSecondaryButton(
  props: SecondaryButtonProps
) {
  const { isLoading } = useFormState();

  return <SecondaryButton disabled={isLoading} {...props} />;
};

Form.Button = function FormButton(props: ButtonProps) {
  const { isLoading } = useFormState();

  return <Button disabled={isLoading} {...props} />;
};

interface FormSubmitButtonProps {
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  loadingLabel?: React.ReactNode;
  submitLabel: React.ReactNode;
  variant?: ButtonProps["variant"];
}

Form.SubmitButton = function FormSubmitButton(props: FormSubmitButtonProps) {
  const {
    className,
    disabled,
    fullWidth,
    submitLabel,
    variant = "primary",
  } = props;
  const { loadingLabel = submitLabel } = props;
  const { isLoading } = useFormState();

  return (
    <Button
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      loading={isLoading}
      type="submit"
      variant={variant}
    >
      {isLoading ? <>{loadingLabel}…</> : submitLabel}
    </Button>
  );
};
