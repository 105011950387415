import gql from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";

import PaymentStep, { PaymentStepButton } from "./PaymentStep";

interface ConfirmPlanPaymentStepProps {
  PaymentStep: ({
    button,
  }: Pick<Parameters<typeof PaymentStep>[0], "button">) => JSX.Element;
  onNext: (() => void) | null;
}

export default function ConfirmPlanPaymentStep(
  props: ConfirmPlanPaymentStepProps
) {
  const { onNext, PaymentStep } = props;

  const confirmPlanPaymentButton = (
    onNext: (() => void) | null
  ): PaymentStepButton => {
    if (onNext === null) {
      throw new Error(
        "Confirm plan payment step should have a non-null onNext value."
      );
    }
    return {
      text: (
        <FormattedMessage
          id="components/subscriptions/Payment:confirmState/continue"
          defaultMessage="Continue"
        />
      ),
      loading: false,
      onClick: onNext,
      disabled: false,
    };
  };

  return <PaymentStep button={confirmPlanPaymentButton(onNext)} />;
}

ConfirmPlanPaymentStep.fragments = {
  tierProduct: gql`
    fragment ConfirmPlanPaymentStep_TierProduct on TierProduct {
      tier
    }
  `,
  recipeAllowanceProduct: gql`
    fragment ConfirmPlanPaymentStep_RecipeAllowanceProduct on RecipeAllowanceProduct {
      price {
        month {
          gbp
          usd
        }
        year {
          gbp
          usd
        }
      }
      recipeAllowance
    }
  `,
};
