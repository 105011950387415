import { ImpactRating } from "../../../domain/impactRatings";
import assertNever from "../../../util/assertNever";
import { convertSvgToBase64Png } from "../../../util/convertSvgToPng";
import {
  labelEffectTypeToDimensions,
  labelSizeToDimensions,
  labelTypeToDimensions,
} from "../labelDimensions";
import DescriptiveImpactAndScaleLabel, {
  DescriptiveImpactAndScaleLabelProps,
} from "./DescriptiveImpactAndScaleLabel";
import DescriptiveRatingLabel, {
  DescriptiveRatingLabelProps,
} from "./DescriptiveRatingLabel";
import DescriptiveRatingScaleLabel, {
  DescriptiveRatingScaleLabelProps,
} from "./DescriptiveRatingScaleLabel";
import LetterRatingLabel, { LetterRatingLabelProps } from "./LetterRatingLabel";
import RatingScaleLabel, { RatingScaleLabelProps } from "./RatingScaleLabel";
import TitledRatingScaleLabel, {
  TitledRatingScaleLabelProps,
} from "./TitledRatingScaleLabel";

export interface BaseCarbonLabelProps {
  className?: string;
  impactRating: ImpactRating;
  monochrome?: boolean;
  width?: number;
}

export type CarbonLabelProps =
  | ({
      type: "descriptiveImpactAndScale";
    } & DescriptiveImpactAndScaleLabelProps)
  | ({ type: "descriptiveRating" } & DescriptiveRatingLabelProps)
  | ({ type: "descriptiveRatingScale" } & DescriptiveRatingScaleLabelProps)
  | ({ type: "letterRating" } & LetterRatingLabelProps)
  | ({ type: "ratingScale" } & RatingScaleLabelProps)
  | ({ type: "titledRatingScale" } & TitledRatingScaleLabelProps);

export default function CarbonLabel(props: CarbonLabelProps) {
  const { className, impactRating, monochrome = false, type, width } = props;
  switch (type) {
    case "descriptiveImpactAndScale":
      return (
        <DescriptiveImpactAndScaleLabel
          className={className}
          effectType={props.effectType}
          impactMagnitude={props.impactMagnitude}
          impactRating={impactRating}
          monochrome={monochrome}
          width={width}
        />
      );
    case "descriptiveRating":
      return (
        <DescriptiveRatingLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          size={props.size}
          width={width}
        />
      );
    case "descriptiveRatingScale":
      return (
        <DescriptiveRatingScaleLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          width={width}
        />
      );
    case "letterRating":
      return (
        <LetterRatingLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          size={props.size}
          width={width}
        />
      );
    case "ratingScale":
      return (
        <RatingScaleLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          size={props.size}
          width={width}
        />
      );
    case "titledRatingScale":
      return (
        <TitledRatingScaleLabel
          className={className}
          impactRating={impactRating}
          monochrome={monochrome}
          width={width}
        />
      );
  }
}

export async function generateCarbonLabelPngBase64(props: CarbonLabelProps) {
  const { type } = props;
  let dimensions;

  if (type === "descriptiveImpactAndScale") {
    dimensions = labelEffectTypeToDimensions(props.effectType);
  } else if (
    type === "descriptiveRatingScale" ||
    type === "titledRatingScale"
  ) {
    dimensions = labelTypeToDimensions(type);
  } else if (
    type === "descriptiveRating" ||
    type === "letterRating" ||
    type === "ratingScale"
  ) {
    dimensions = labelSizeToDimensions(type, props.size);
  } else {
    assertNever(type, "Invalid label type");
  }

  const carbonLabelSvg = <CarbonLabel {...props} />;

  return await convertSvgToBase64Png({
    height: dimensions.height,
    svgElement: carbonLabelSvg,
    width: dimensions.width,
  });
}
