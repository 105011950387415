import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  UseCollections_AddCollection,
  UseCollections_AddCollectionVariables,
} from "../../data-store/useCollections.graphql";
import { MutationOptions } from "../graphql/useMutation";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import ActionModal from "../utils/ActionModal";
import Form from "../utils/Form";
import * as TextField from "../utils/TextField";

import "./AddTagModal.css";

interface AddTagModalProps {
  addTag: (
    options: MutationOptions<UseCollections_AddCollectionVariables>
  ) => Promise<UseCollections_AddCollection>;
  show: boolean;
  onCancel: () => void;
  onComplete: (newTag: { id: number; name: string }) => void;
}

export default function AddTagModal(props: AddTagModalProps) {
  const { addTag, onCancel, onComplete, show } = props;

  const intl = useIntl();

  const [value, setValue] = useState<TextField.Value>(
    TextField.initialValue("")
  );
  const [organizationId] = useOrganizationId();
  const invalid = TextField.read(value).hasError;

  useEffect(() => {
    setValue(TextField.initialValue(""));
  }, [show]);

  const handleSubmit = async () => {
    if (invalid) {
      throw new Error("Invalid input");
    } else {
      const response = await addTag({
        variables: {
          input: { name: value.text, ownerOrganizationId: organizationId },
        },
      });
      onComplete(response.addRecipeCollection.recipeCollection);
    }
  };

  const title = intl.formatMessage({
    id: "components/tags/AddTagModal:title",
    defaultMessage: "Create a new tag",
  });

  const placeholder = intl.formatMessage({
    id: "components/tags/AddTagModal:placeholder",
    defaultMessage: "e.g. Finger food",
  });

  return (
    <ActionModal className="AddTagModal" show={show} title={title}>
      <Form onSubmit={handleSubmit}>
        <ActionModal.Body>
          <div className="AddTagModal_Instructions text-muted">
            {intl.formatMessage({
              id: "components/tags/AddTagModal:instructions",
              defaultMessage: "What would you like to call your tag?",
            })}
          </div>
          <div className="AddTagModal_TextField">
            <TextField.TextField
              onChange={setValue}
              placeholder={placeholder}
              value={value}
            />
          </div>
        </ActionModal.Body>
        <ActionModal.Footer>
          <div className="AddTagModal_ButtonsContainer">
            <Form.SecondaryButton onClick={onCancel}>
              {intl.formatMessage({
                id: "components/collections/AddTagModal:cancel",
                defaultMessage: "Cancel",
              })}
            </Form.SecondaryButton>
            <Form.SubmitButton
              disabled={invalid}
              loadingLabel={intl.formatMessage({
                id: "components/collections/AddTagModal:saveButton/loadingLabel",
                defaultMessage: "Saving",
              })}
              submitLabel={intl.formatMessage({
                id: "components/collections/AddTagModal:saveButton/submitLabel",
                defaultMessage: "Save",
              })}
            />
          </div>
        </ActionModal.Footer>
      </Form>
    </ActionModal>
  );
}

AddTagModal.fragments = {
  recipeCollection: () => gql`
    fragment AddTagModal_RecipeCollection on RecipeCollection {
      id
      name
    }
  `,
};
