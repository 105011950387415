import VectorProps from "./VectorProps";

export default function ChevronUp(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1-67&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6834 8.65225C11.2474 9.09825 10.6404 9.13325 10.1074 8.65225L6.19941 4.90525L2.29141 8.65225C1.75841 9.13325 1.15041 9.09825 0.717412 8.65225C0.281412 8.20725 0.309412 7.45525 0.717412 7.03725C1.12341 6.61925 5.41241 2.53525 5.41241 2.53525C5.62941 2.31225 5.91441 2.20025 6.19941 2.20025C6.48441 2.20025 6.76941 2.31225 6.98841 2.53525C6.98841 2.53525 11.2754 6.61925 11.6834 7.03725C12.0914 7.45525 12.1194 8.20725 11.6834 8.65225Z"
        fill={fill}
      />
    </svg>
  );
}
