import { FormattedMessage, useIntl } from "react-intl";

import { ParentCollectionsProvider } from "../../../data-store/useParentCollections";
import {
  useCollections,
  useTags,
} from "../../../services/useOrganizationFeatures";
import UserVisibleError from "../../../util/UserVisibleError";
import { useOrganization } from "../../organizations/OrganizationProvider";
import Page from "../../Page";
import { usePages } from "../../pages";
import * as RecipesListPageLocationState from "../../recipes/RecipesListPageLocationState";
import RecipesPanel from "../../recipes/RecipesPanel";
import useRecipeLabel from "../../recipes/useRecipeLabel";

const tracking = { pageName: "shared-products" };

export default function SharedProductsPage() {
  const intl = useIntl();
  const pages = usePages();
  const [organization] = useOrganization();
  const recipeLabel = useRecipeLabel();
  const canUseCollections = useCollections();
  const hasFeatureTags = useTags();

  const recipeQuery = RecipesListPageLocationState.useQueryParams();

  if (organization.parentId === null) {
    throw new UserVisibleError(
      "Cannot display shared products for organizations without a parent."
    );
  }

  const selectCollection = canUseCollections
    ? {
        placeholder: intl.formatMessage({
          id: "components/recipes/SharedProductsPage:selectCollection",
          defaultMessage: "All Shared Collections",
        }),
      }
    : null;

  return (
    <ParentCollectionsProvider>
      <Page tracking={tracking}>
        <Page.Title breadcrumb={pages.SharedProducts.breadcrumb(recipeQuery)} />
        <RecipesPanel
          filterByParentCollections={true}
          newRecipeUrl={undefined}
          noRecipesMessage={
            <FormattedMessage
              id="components/sharing/shared-products/SharedProductsPage:noRecipesMessage"
              defaultMessage="
                        No {recipeLabel} have been shared with you yet.
                      "
              values={{ recipeLabel: recipeLabel.pluralLowercase }}
            />
          }
          noRecipesDueToSearchMessage={
            <FormattedMessage
              id="components/sharing/shared-products/SharedProductsPage:noRecipesDueToSearchMessage"
              defaultMessage="There are no shared {recipesLabel} matching your search."
              values={{ recipesLabel: recipeLabel.pluralLowercase }}
            />
          }
          pageName="Shared Products"
          recipeFilter={{
            ownerOrganizations: { id: organization.parentId },
          }}
          recipeUrl={(recipe) =>
            pages.SharedProduct(hasFeatureTags).url(recipe)
          }
          showAddDropdown={false}
          showCopyToButton={!hasFeatureTags}
          showExportDropdown={false}
          showFilterToRequiresAttention={false}
          showManageTagsButton={false}
          selectCollection={selectCollection}
          includeRecipesUsedAsIngredient={true}
          includeRecipesOnlyAccessibleViaCollection={true}
        />
      </Page>
    </ParentCollectionsProvider>
  );
}
