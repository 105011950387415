import gql from "graphql-tag";

import { ImpactCalculatorErrorKind } from "../../__generated__/globalTypes";
import { useRecipeImpactIsPending_RecipeImpact } from "./useRecipeIsPending.graphql";

export function useRecipeImpactIsPending(
  impact: useRecipeImpactIsPending_RecipeImpact
) {
  return impact.errors.some(
    (error) =>
      error.kind ===
        ImpactCalculatorErrorKind.RECIPE_INGREDIENT_NOT_LINKED_TO_FOOD_CLASS ||
      error.kind ===
        ImpactCalculatorErrorKind.RECIPE_INGREDIENT_LINKED_TO_UNVALIDATED_FOOD_CLASS
  );
}

export const fragments = {
  recipeImpact: gql`
    fragment useRecipeImpactIsPending_RecipeImpact on RecipeImpact {
      errors {
        kind
      }
    }
  `,
};
