import classNames from "classnames";
import gql from "graphql-tag";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useEditProducts } from "../../services/useOrganizationFeatures";
import { IconButton } from "../utils/Button";
import DeleteModal from "../utils/DeleteModal";
import { useIsOverflowing } from "../utils/useIsOverflowing";
import { Delete, Pencil } from "../utils/Vectors";
import "./PackagingComponentTable.css";
import { PackagingComponentMaterialNames } from "./PackagingComponentMaterialNames";
import "./PackagingComponentTable.css";
import { PackagingComponentTable__PackagingComponentV2 as PackagingComponentV2 } from "./PackagingComponentTable.graphql";

interface PackagingComponentTableProps {
  handleDelete: (packagingComponent: PackagingComponentV2) => void;
  handleEdit: (packagingComponent: PackagingComponentV2) => void;
  packagingComponents: Array<PackagingComponentV2>;
}

export default function PackagingComponentTable(
  props: PackagingComponentTableProps
) {
  const { handleDelete, handleEdit, packagingComponents } = props;

  const intl = useIntl();
  const editProducts = useEditProducts();

  const [
    showDeletePackagingComponentModalForComponent,
    setShowDeletePackagingComponentModalForComponent,
  ] = useState<PackagingComponentV2 | null>(null);

  const [isHighlighted, setIsHighlighted] = useState<
    Map<PackagingComponentV2["id"], boolean>
  >(new Map(packagingComponents.map((component) => [component.id, false])));

  const deletingPackagingComponent =
    showDeletePackagingComponentModalForComponent === null
      ? undefined
      : packagingComponents.find(
          (packagingComponent) =>
            packagingComponent.id ===
            showDeletePackagingComponentModalForComponent.id
        );

  const columns = [
    {
      className: "PackagingComponentTable__column__name",
      key: "name",
      label: (
        <FormattedMessage
          id="components/packaging/PackagingComponentTable:columns/name/label"
          defaultMessage="Packaging Components"
        />
      ),
      renderCell: ({
        component,
        highlighted,
      }: {
        component: PackagingComponentV2;
        highlighted: boolean;
      }) => (
        <span
          className={classNames(
            "medium-font PackagingComponentTable__column__name__cell",
            {
              PackagingComponentTable__column__name__cell__highlighted:
                highlighted,
            },
            {
              PackagingComponentTable__column__name__cell__link: editProducts,
            }
          )}
        >
          <>
            {component.name}
            <span
              className={classNames(
                "PackagingComponentTable__column__name__cell__edit ml-2",
                {
                  PackagingComponentTable__column__name__cell__edit__highlighted:
                    highlighted,
                }
              )}
            >
              <IconButton
                display="inline"
                icon={<Pencil fill="var(--foodsteps-turquoise)" width="1rem" />}
              />
            </span>
          </>
        </span>
      ),
    },
    {
      className: "PackagingComponentTable__column__materials",
      key: "materials",
      label: (
        <FormattedMessage
          id="components/packaging/PackagingComponentTable:columns/materials/label"
          defaultMessage="Materials"
        />
      ),
      renderCell: ({
        component,
        overflowRef,
      }: {
        component: PackagingComponentV2;
        overflowRef: Parameters<typeof useIsOverflowing>["0"];
      }) => (
        <PackagingComponentMaterialNames
          overflowRef={overflowRef}
          packagingComponent={component}
        />
      ),
    },
    {
      className: classNames("PackagingComponentTable__column__impact", {
        PackagingComponentTable__column__impact__no_delete_column:
          !editProducts,
      }),
      key: "impact",
      label: (
        <>
          <div className="PackagingComponentTable__header__overflow__left">
            <FormattedMessage
              id="components/packaging/PackagingComponentTable:columns/impact/label"
              defaultMessage="Carbon Footprint"
            />
          </div>
          <div className="PackagingComponentTable__header__overflow__left small mt-2">
            <FormattedMessage
              id="components/packaging/PackagingComponentTable:columns/impact/units"
              defaultMessage="(kg CO2e per component)"
            />
          </div>
        </>
      ),
      renderCell: ({ component }: { component: PackagingComponentV2 }) =>
        component.ghgMagnitude?.toFixed(3),
    },
    {
      className: classNames("PackagingComponentTable__column__weight", {
        PackagingComponentTable__column__weight__no_delete_column:
          !editProducts,
      }),
      key: "weight",
      label: (
        <FormattedMessage
          id="components/packaging/PackagingComponentTable:columns/weight/label"
          defaultMessage="Weight"
        />
      ),
      renderCell: ({ component }: { component: PackagingComponentV2 }) => (
        <>
          {(
            component.componentMaterials
              .map((componentMaterial) => componentMaterial.weightKg)
              .reduce((sum, weightKg) => sum + weightKg, 0) * 1000
          ).toFixed(1)}{" "}
          <FormattedMessage
            id="components/packaging/PackagingComponentTable:unitGrams"
            defaultMessage="g"
          />
        </>
      ),
    },
    ...(editProducts
      ? [
          {
            className:
              "PackagingComponentTable__column__delete d-flex justify-content-end",
            key: "delete",
            label: null,
            renderCell: ({
              component,
            }: {
              component: PackagingComponentV2;
            }) => (
              <IconButton
                icon={
                  <Delete
                    fill="var(--dark-turquoise)"
                    title={intl.formatMessage({
                      id: "components/packaging/PackagingComponentTable:columns/delete/buttonTitle",
                      defaultMessage: "Delete Packaging Component",
                    })}
                    width="1rem"
                  />
                }
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  // Don't also propagate the event to handleEdit.
                  event.stopPropagation();

                  setShowDeletePackagingComponentModalForComponent(component);
                }}
              />
            ),
          },
        ]
      : []),
  ];

  const rowClassName = "d-flex flex-row";

  const handleClick = (packagingComponent: PackagingComponentV2) => {
    if (editProducts) {
      handleEdit(packagingComponent);
    }
  };

  const handleMouseOver = (packagingComponent: PackagingComponentV2) => {
    if (editProducts) {
      setIsHighlighted(new Map(isHighlighted.set(packagingComponent.id, true)));
    }
  };

  const handleMouseOut = (packagingComponent: PackagingComponentV2) => {
    if (editProducts) {
      setIsHighlighted(
        new Map(isHighlighted.set(packagingComponent.id, false))
      );
    }
  };

  return (
    <div className="w-100 PackagingComponentTable__table">
      <div
        className={classNames("PackagingComponentTable__header", rowClassName)}
      >
        {columns.map((column) => (
          <div className={column.className} key={`tableHeader__${column.key}`}>
            <div className="medium-font">{column.label}</div>
          </div>
        ))}
      </div>
      <>
        {packagingComponents.map((packagingComponent) => (
          <React.Fragment key={packagingComponent.id}>
            <hr />
            <div
              className={classNames(
                "PackagingComponentTable__row",
                rowClassName,
                {
                  PackagingComponentTable__row__highlighted:
                    isHighlighted.get(packagingComponent.id) ?? false,
                }
              )}
              onClick={() => handleClick(packagingComponent)}
              onMouseOver={() => handleMouseOver(packagingComponent)}
              onMouseOut={() => handleMouseOut(packagingComponent)}
            >
              {columns.map((column) => (
                <Cell
                  className={column.className}
                  isHighlighted={isHighlighted}
                  key={`${packagingComponent.id}${column.key}`}
                  packagingComponent={packagingComponent}
                  renderCell={column.renderCell}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
      </>
      {deletingPackagingComponent !== undefined && (
        <DeletePackagingComponentModal
          onDelete={async () => handleDelete(deletingPackagingComponent)}
          onHide={() => setShowDeletePackagingComponentModalForComponent(null)}
          packagingComponent={deletingPackagingComponent}
        />
      )}
    </div>
  );
}

interface CellProps {
  className: string;
  isHighlighted: Map<PackagingComponentV2["id"], boolean>;
  packagingComponent: PackagingComponentV2;
  renderCell: ({
    component,
    overflowRef,
    highlighted,
  }: {
    component: PackagingComponentV2;
    overflowRef: Parameters<typeof useIsOverflowing>["0"];
    highlighted: boolean;
  }) => React.ReactNode;
}

function Cell(props: CellProps) {
  const { className, isHighlighted, packagingComponent, renderCell } = props;
  const overflowRef = useRef(null);

  return (
    <div
      className={classNames("PackagingComponentTable__cell", className)}
      ref={overflowRef}
    >
      {renderCell({
        component: packagingComponent,
        overflowRef,
        highlighted: isHighlighted.get(packagingComponent.id) ?? false,
      })}
    </div>
  );
}
interface DeletePackagingComponentModalProps {
  onDelete: () => Promise<void>;
  onHide: () => void;
  packagingComponent: PackagingComponentV2;
}

function DeletePackagingComponentModal(
  props: DeletePackagingComponentModalProps
) {
  const { onDelete, onHide, packagingComponent } = props;

  const intl = useIntl();

  return (
    <DeleteModal
      message={
        <FormattedMessage
          id="components/packaging/PackagingPage:DeletePackagingComponentModal/message"
          defaultMessage="Deleting this packaging component will also remove it from your products."
        />
      }
      name={packagingComponent.name}
      onDelete={onDelete}
      onHide={onHide}
      show
      title={intl.formatMessage(
        {
          id: "components/packaging/PackagingPage:DeletePackagingComponentModal/title",
          defaultMessage:
            "Are you sure you want to delete {packagingComponentName}?",
        },
        {
          packagingComponentName: packagingComponent.name,
        }
      )}
    />
  );
}

PackagingComponentTable.fragments = {
  packagingComponent: gql`
    fragment PackagingComponentTable__PackagingComponentV2 on PackagingComponentV2 {
      componentMaterials {
        id
        material {
          name
        }
        recycledContentFraction
        weightKg
      }
      ghgMagnitude
      id
      name
    }
  `,
};
