import { FormattedMessage, IntlShape } from "react-intl";

import { useFoodManufacturerOrganization } from "../services/useOrganizationFeatures";
import assertNever from "../util/assertNever";
import { FunctionalUnit } from "./functionalUnits";
import { ImpactCategory } from "./impactCategories";
import {
  RelatableEquivalent,
  relatableEquivalents,
} from "./relatableEquivalents";

type Impact = {
  effects: {
    ghgPerKg: number | null | undefined;
    ghgPerRootRecipeServing: number | null | undefined;
    landUsePerKg: number | null | undefined;
    landUsePerRootRecipeServing: number | null | undefined;
    waterUsePerKg: number | null | undefined;
    waterUsePerRootRecipeServing: number | null | undefined;
  } | null;
};

export default interface EffectType {
  decimals: number;
  equivalent?: RelatableEquivalent;
  title: (intl: IntlShape) => string;
  unit: React.ReactNode;
  unitString: (intl: IntlShape) => string;
}

export interface EffectTypeAbsoluteValue extends EffectType {
  get: (impact: Impact | null) => number | null;
  getQuantity: (ingredientImpact: {
    quantity: number | null;
    quantityPerRootRecipeServing: number | null;
  }) => number | null;
  per: (intl: IntlShape) => string;
  impactCategoryUnit: React.ReactNode;
  impactCategoryUnitString: (intl: IntlShape) => string;
}

const ghg: EffectType = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghg/title",
      defaultMessage: "GHG Emissions",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:ghg/unit"
      defaultMessage="kg CO<sub>2</sub>e"
      values={{
        sub: (chunks: React.ReactNode) => <sub>{chunks}</sub>,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghg/unitString",
      defaultMessage: "kg CO\u2082e",
    }),
  decimals: 2,
  equivalent: relatableEquivalents.ghg.smartphoneCharge,
};

const ghgPerKg: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghgPerKg/title",
      defaultMessage: "GHG Intensity",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:ghgPerKg/unit"
      defaultMessage="{ghgUnit} / kg"
      values={{
        ghgUnit: ghg.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:ghgPerKg/unitString",
        defaultMessage: "{ghgUnitString} / kg",
      },
      { ghgUnitString: ghg.unitString(intl) }
    ),
  decimals: 2,
  equivalent: ghg.equivalent,
  get: (impact) => impact?.effects?.ghgPerKg ?? null,
  getQuantity: () => 1, // 1kg
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghgPerKg/per",
      defaultMessage: "kg",
    }),
  impactCategoryUnit: ghg.unit,
  impactCategoryUnitString: ghg.unitString,
};

const ghgPerServing: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghgPerServing/title",
      defaultMessage: "GHG Per Serving",
    }),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghgPerServing/per",
      defaultMessage: "serving",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:ghgPerServing/unit"
      defaultMessage="{ghgUnit} / serving"
      values={{
        ghgUnit: ghg.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:ghgPerServing/unitString",
        defaultMessage: "{ghgUnitString} / serving",
      },
      { ghgUnitString: ghg.unitString(intl) }
    ),
  decimals: 2,
  equivalent: relatableEquivalents.ghg.smartphoneCharge,
  get: (impact) => impact?.effects?.ghgPerRootRecipeServing ?? null,
  getQuantity: (ingredientImpact) =>
    ingredientImpact.quantityPerRootRecipeServing,
  impactCategoryUnit: ghg.unit,
  impactCategoryUnitString: ghg.unitString,
};

const ghgPerProduct: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghgPerProduct/title",
      defaultMessage: "GHG Per Product",
    }),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:ghgPerProduct/per",
      defaultMessage: "product",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:ghgPerProduct/unit"
      defaultMessage="{ghgUnit} / product"
      values={{
        ghgUnit: ghg.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:ghgPerProduct/unitString",
        defaultMessage: "{ghgUnitString} / product",
      },
      { ghgUnitString: ghg.unitString(intl) }
    ),
  decimals: 2,
  equivalent: relatableEquivalents.ghg.smartphoneCharge,
  get: (impact) => impact?.effects?.ghgPerRootRecipeServing ?? null,
  getQuantity: (ingredientImpact) =>
    ingredientImpact.quantityPerRootRecipeServing,
  impactCategoryUnit: ghg.unit,
  impactCategoryUnitString: ghg.unitString,
};

const landUse: EffectType = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUse/title",
      defaultMessage: "Land Use",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:landUse/unit"
      defaultMessage="m<sup>2</sup>year"
      values={{
        sup: (chunks: React.ReactNode) => <sup>{chunks}</sup>,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUse/unitString",
      defaultMessage: "m\u00B2yr",
    }),
  decimals: 2,
};

const landUsePerKg: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUsePerKg/title",
      defaultMessage: "Land Use Intensity",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:landUsePerKg/unit"
      defaultMessage="{landUseUnit} / kg"
      values={{
        landUseUnit: landUse.unit,
      }}
    />
  ),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUsePerKg/per",
      defaultMessage: "kg",
    }),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:landUsePerKg/unitString",
        defaultMessage: "{landUseUnitString} / kg",
      },
      { landUseUnitString: landUse.unitString(intl) }
    ),
  decimals: 2,
  get: (impact) => impact?.effects?.landUsePerKg ?? null,
  getQuantity: () => 1, // 1kg
  impactCategoryUnit: landUse.unit,
  impactCategoryUnitString: landUse.unitString,
};

const getLandUsePerServing = (impact: Impact | null) =>
  impact?.effects?.landUsePerRootRecipeServing ?? null;
const getLandUsePerServingQuantity = (ingredientImpact: {
  quantityPerRootRecipeServing: number | null;
}) => ingredientImpact.quantityPerRootRecipeServing;

const landUsePerServing: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUsePerServing/title",
      defaultMessage: "Land Use Per Serving",
    }),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUsePerServing/per",
      defaultMessage: "serving",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:landUsePerServing/unit"
      defaultMessage="{landUseUnit} / serving"
      values={{
        landUseUnit: landUse.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:landUsePerServing/unitString",
        defaultMessage: "{landUseUnitString} / serving",
      },
      { landUseUnitString: landUse.unitString(intl) }
    ),
  decimals: 2,
  get: getLandUsePerServing,
  getQuantity: getLandUsePerServingQuantity,
  impactCategoryUnit: landUse.unit,
  impactCategoryUnitString: landUse.unitString,
};

const landUsePerProduct: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUsePerProduct/title",
      defaultMessage: "Land Use Per Product",
    }),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:landUsePerProduct/per",
      defaultMessage: "product",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:landUsePerProduct/unit"
      defaultMessage="{landUseUnit} / product"
      values={{
        landUseUnit: landUse.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:landUsePerProduct/unitString",
        defaultMessage: "{landUseUnitString} / product",
      },
      { landUseUnitString: landUse.unitString(intl) }
    ),
  decimals: 2,
  get: getLandUsePerServing,
  getQuantity: getLandUsePerServingQuantity,
  impactCategoryUnit: landUse.unit,
  impactCategoryUnitString: landUse.unitString,
};

const waterUse: EffectType = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUse/title",
      defaultMessage: "Water Use",
    }),
  unit: (
    <FormattedMessage id="domain/EffectType:waterUse/unit" defaultMessage="L" />
  ),
  unitString: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUse/unitString",
      defaultMessage: "L",
    }),
  decimals: 0,
};

const waterUsePerKg: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUsePerKg/title",
      defaultMessage: "Water Use Intensity",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:waterUsePerKg/unit"
      defaultMessage="{waterUseUnit} / kg"
      values={{
        waterUseUnit: waterUse.unit,
      }}
    />
  ),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUsePerKg/per",
      defaultMessage: "kg",
    }),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:waterUsePerKg/unitString",
        defaultMessage: "{waterUseUnitString} / kg",
      },
      { waterUseUnitString: waterUse.unitString(intl) }
    ),
  decimals: 2,
  get: (impact) => impact?.effects?.waterUsePerKg ?? null,
  getQuantity: () => 1, // 1kg
  impactCategoryUnit: waterUse.unit,
  impactCategoryUnitString: waterUse.unitString,
};

const getWaterUsePerServing = (impact: Impact | null) =>
  impact?.effects?.waterUsePerRootRecipeServing ?? null;
const getWaterUsePerServingQuantity = (ingredientImpact: {
  quantityPerRootRecipeServing: number | null;
}) => ingredientImpact.quantityPerRootRecipeServing;

const waterUsePerServing: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUsePerServing/title",
      defaultMessage: "Water Use Per Serving",
    }),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUsePerServing/per",
      defaultMessage: "serving",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:waterUsePerServing/unit"
      defaultMessage="{waterUseUnit} / serving"
      values={{
        waterUseUnit: waterUse.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:waterUsePerServing/unitString",
        defaultMessage: "{waterUseUnitString} / serving",
      },
      { waterUseUnitString: waterUse.unitString(intl) }
    ),
  decimals: 2,
  get: getWaterUsePerServing,
  getQuantity: getWaterUsePerServingQuantity,
  impactCategoryUnit: waterUse.unit,
  impactCategoryUnitString: waterUse.unitString,
};

const waterUsePerProduct: EffectTypeAbsoluteValue = {
  title: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUsePerProduct/title",
      defaultMessage: "Water Use Per Product",
    }),
  per: (intl) =>
    intl.formatMessage({
      id: "domain/EffectType:waterUsePerProduct/per",
      defaultMessage: "product",
    }),
  unit: (
    <FormattedMessage
      id="domain/EffectType:waterUsePerProduct/unit"
      defaultMessage="{waterUseUnit} / product"
      values={{
        waterUseUnit: waterUse.unit,
      }}
    />
  ),
  unitString: (intl) =>
    intl.formatMessage(
      {
        id: "domain/EffectType:waterUsePerProduct/unitString",
        defaultMessage: "{waterUseUnitString} / product",
      },
      { waterUseUnitString: waterUse.unitString(intl) }
    ),
  decimals: 2,
  get: getWaterUsePerServing,
  getQuantity: getWaterUsePerServingQuantity,
  impactCategoryUnit: waterUse.unit,
  impactCategoryUnitString: waterUse.unitString,
};

export const impactCategoryToEffectType = (
  impactCategory: ImpactCategory
): EffectType => {
  if (impactCategory === ImpactCategory.GHG) {
    return ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return waterUse;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory");
  }
};

export const impactCategoryToEffectTypePerKg = (
  impactCategory: ImpactCategory
): EffectTypeAbsoluteValue => {
  if (impactCategory === ImpactCategory.GHG) {
    return ghgPerKg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return landUsePerKg;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return waterUsePerKg;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory");
  }
};

export const useImpactCategoryEffectTypePerPortion = (
  impactCategory: ImpactCategory
): EffectTypeAbsoluteValue => {
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  if (impactCategory === ImpactCategory.GHG) {
    return foodManufacturerOrganization ? ghgPerProduct : ghgPerServing;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return foodManufacturerOrganization ? landUsePerProduct : landUsePerServing;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return foodManufacturerOrganization
      ? waterUsePerProduct
      : waterUsePerServing;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory");
  }
};

export const useEffectTypeForImpactCategoryPerFunctionalUnit = (
  impactCategory: ImpactCategory,
  functionalUnit: FunctionalUnit
) => {
  const impactCategoryEffectTypePerPortion =
    useImpactCategoryEffectTypePerPortion(impactCategory);
  if (functionalUnit === FunctionalUnit.KG) {
    return impactCategoryToEffectTypePerKg(impactCategory);
  } else if (functionalUnit === FunctionalUnit.PORTION) {
    return impactCategoryEffectTypePerPortion;
  } else {
    assertNever(functionalUnit, "Unsupported FunctionalUnit");
  }
};

export const effectTypes = {
  ghg,
  ghgPerKg,
  ghgPerProduct,
  ghgPerServing,
  landUse,
  landUsePerKg,
  landUsePerProduct,
  landUsePerServing,
  waterUse,
  waterUsePerKg,
  waterUsePerServing,
  waterUsePerProduct,
};
