import { useCallbackOne } from "use-memo-one";

import { useDataStore } from ".";
import usePromise from "../components/utils/usePromise";

export default function useAvailableIngredients(
  organizationId: string | null = null
) {
  const dataStore = useDataStore();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchAvailableIngredients(organizationId);
  }, [dataStore, organizationId]);

  return usePromise(load);
}
