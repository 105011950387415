import { useState } from "react";

import UserVisibleError from "../../util/UserVisibleError";
import ActionModal from "./ActionModal";
import { PrimaryButton, SecondaryButton } from "./Button";
import Form from "./Form";
import useId from "./useId";

const stories = {
  name: "ActionModal",
  children: [
    { name: "Simple example", render: () => <ActionModalStory /> },
    { name: "Using <Form>", render: () => <ActionModalWithFormStory /> },
  ],
};

const explanation = (
  <>
    <p>
      We intentionally limit the ways that an action modal can be closed to
      ensure the user makes an active choice. For instance, if the user is
      entering details of a new entity, we want to know whether user is choosing
      to save that new entity, or to cancel, and therefore discarding the
      entered details.
    </p>
    <p>
      To avoid the user from accidentally making the wrong choice, we don't
      allow closing the modal by, for instance, clicking on the backdrop.
      Instead, we expect the caller to provide the relevant choices in the
      modal.
    </p>
  </>
);

function ActionModalStory() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SecondaryButton onClick={() => setShowModal(true)}>
        Add new collection
      </SecondaryButton>
      <ActionModal show={showModal} title="Add collection">
        <ActionModal.Body>{explanation}</ActionModal.Body>
        <ActionModal.Footer>
          <div className="d-flex w-100 justify-content-between">
            <div>
              <SecondaryButton onClick={() => setShowModal(false)}>
                Cancel
              </SecondaryButton>
            </div>
            <div>
              <PrimaryButton onClick={() => setShowModal(false)}>
                Save
              </PrimaryButton>
            </div>
          </div>
        </ActionModal.Footer>
      </ActionModal>
    </>
  );
}

function ActionModalWithFormStory() {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const nameControlId = useId();

  async function handleSubmit() {
    if (name === "") {
      throw new UserVisibleError("Name must be set");
    }
    await sleep(500);
    setShowModal(false);
  }

  return (
    <>
      <SecondaryButton onClick={() => setShowModal(true)}>
        Add new collection
      </SecondaryButton>
      <ActionModal show={showModal} title="Add collection">
        <Form onSubmit={handleSubmit}>
          <ActionModal.Body>
            <div className="form-group">
              <label htmlFor={nameControlId}>Name</label>
              <input
                type="text"
                className="form-control"
                id={nameControlId}
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
            </div>
            {explanation}
          </ActionModal.Body>
          <ActionModal.Footer>
            <div className="d-flex w-100 justify-content-between">
              <div>
                <Form.SecondaryButton onClick={() => setShowModal(false)}>
                  Cancel
                </Form.SecondaryButton>
              </div>
              <div className="d-flex">
                <Form.ErrorAlert className="mr-4" />
                <div>
                  <Form.SubmitButton loadingLabel="Saving" submitLabel="Save" />
                </div>
              </div>
            </div>
          </ActionModal.Footer>
        </Form>
      </ActionModal>
    </>
  );
}

function sleep(interval: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), interval));
}

export default stories;
