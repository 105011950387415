import VectorProps from "./VectorProps";

export default function ChevronRight(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1-67&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.34775 11.6834C2.90175 11.2474 2.86675 10.6404 3.34775 10.1074L7.09475 6.19941L3.34775 2.29141C2.86675 1.75841 2.90175 1.15041 3.34775 0.717412C3.79275 0.281412 4.54475 0.309412 4.96275 0.717412C5.38075 1.12341 9.46475 5.41241 9.46475 5.41241C9.68775 5.62941 9.79975 5.91441 9.79975 6.19941C9.79975 6.48441 9.68775 6.76941 9.46475 6.98841C9.46475 6.98841 5.38075 11.2754 4.96275 11.6834C4.54475 12.0914 3.79275 12.1194 3.34775 11.6834Z"
        fill={fill}
      />
    </svg>
  );
}
