import { LocationDescriptorObject } from "history";
import { useLocation } from "react-router-dom";

import { PackagingComponentsListPageQueryParams } from "../pages";

export function queryParamsToLocationDescriptor(
  pathname: string,
  queryParams: PackagingComponentsListPageQueryParams
): LocationDescriptorObject {
  return { pathname, state: queryParams };
}

export function useQueryParams(): PackagingComponentsListPageQueryParams | null {
  const location = useLocation<PackagingComponentsListPageQueryParams>();
  return location.state !== undefined ? location.state : null;
}
