import VectorProps from "../VectorProps";

export default function Acidification(props: VectorProps) {
  const { width } = props;

  // from https://www.figma.com/file/Z5JvdIZfbok8AxNw5lmpCU/DQS?node-id=15663%3A7005&mode=dev

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2362_2353)">
        <path
          d="M21.9201 32C27.4871 32 32.0001 27.487 32.0001 21.92C32.0001 16.353 27.4871 11.84 21.9201 11.84C16.3531 11.84 11.8401 16.353 11.8401 21.92C11.8401 27.487 16.3531 32 21.9201 32Z"
          fill="#67DBC1"
        />
        <path
          d="M0.880127 31.32H31.1201V21.7632L28.8241 19.8496C27.3745 18.6416 25.2273 18.5536 23.6737 19.64L21.6657 21.0432C20.1873 22.0768 18.1601 22.0528 16.7089 20.9856L14.9681 19.7056C13.4401 18.5824 11.2881 18.6224 9.80653 19.8016L8.64333 20.728C7.14093 21.9248 4.94893 21.9456 3.42093 20.7776L1.04013 18.9568L0.880127 31.3216V31.32Z"
          fill="#00A68F"
        />
        <path
          d="M31.12 32.2H0.880021C0.644821 32.2 0.419221 32.1056 0.254421 31.9376C0.0896207 31.7696 -0.0015793 31.544 2.0702e-05 31.3072L0.160021 18.9424C0.164821 18.6096 0.355221 18.3072 0.654421 18.1616C0.953621 18.016 1.30882 18.0512 1.57442 18.2544L3.95522 20.0752C5.16482 21 6.90562 20.984 8.09442 20.0368L9.25762 19.1104C11.064 17.672 13.6256 17.624 15.488 18.9936L17.2288 20.2736C18.3744 21.1168 19.992 21.1344 21.16 20.3184L23.168 18.9152C25.0608 17.592 27.6192 17.6976 29.3856 19.1712L31.6816 21.0848C31.8816 21.2512 31.9984 21.4992 31.9984 21.76V31.3168C31.9984 31.8032 31.6048 32.1968 31.1184 32.1968L31.12 32.2ZM1.77122 30.44H30.24V22.1744L28.2592 20.5248C27.1184 19.5744 25.4016 19.504 24.176 20.36L22.168 21.7632C20.3904 23.0048 17.9312 22.976 16.1872 21.6928L14.4464 20.4128C13.2416 19.5264 11.5216 19.5584 10.3552 20.488L9.19202 21.4144C7.37922 22.8576 4.72802 22.8832 2.88642 21.4752L1.89762 20.72L1.77122 30.4416V30.44Z"
          fill="black"
        />
        <path
          d="M19.2272 22.6752C18.1504 22.6752 17.0768 22.3472 16.1872 21.6928L14.4464 20.4128C13.2416 19.5264 11.5216 19.5584 10.3552 20.488L9.192 21.4144C7.3792 22.8576 4.728 22.8832 2.8864 21.4752L0.505599 19.6544C0.119999 19.3584 0.0463987 18.8064 0.340799 18.4208C0.636799 18.0352 1.1888 17.9616 1.5744 18.256L3.9552 20.0768C5.1648 21.0016 6.9056 20.9856 8.0944 20.0384L9.2576 19.112C11.064 17.6736 13.6256 17.6256 15.488 18.9952L17.2288 20.2752C18.3744 21.1184 19.992 21.136 21.16 20.32L23.168 18.9168C25.0608 17.5936 27.6192 17.6992 29.3856 19.1728L31.6816 21.0864C32.0544 21.3968 32.1056 21.952 31.7936 22.3264C31.4816 22.6992 30.928 22.7504 30.5536 22.4384L28.2576 20.5248C27.1168 19.5744 25.4 19.504 24.1744 20.36L22.1664 21.7632C21.296 22.3712 20.2592 22.6752 19.2256 22.6752H19.2272Z"
          fill="black"
        />
        <path
          d="M19.2272 28.4016C18.152 28.4016 17.0768 28.0736 16.1872 27.4192L14.4464 26.1392C13.2416 25.2528 11.5216 25.2848 10.3552 26.2144L9.192 27.1408C7.3792 28.584 4.728 28.6096 2.8864 27.2016L0.505599 25.3808C0.119999 25.0848 0.0463987 24.5328 0.340799 24.1472C0.636799 23.7616 1.1888 23.688 1.5744 23.9824L3.9552 25.8032C5.1648 26.728 6.9056 26.712 8.0944 25.7648L9.2576 24.8384C11.064 23.4 13.6256 23.352 15.488 24.7216L17.2288 26.0016C18.3744 26.8448 19.992 26.8624 21.16 26.0464L23.168 24.6432C25.0608 23.32 27.6192 23.4256 29.3856 24.8992L31.6816 26.8128C32.0544 27.1232 32.1056 27.6784 31.7936 28.0528C31.4816 28.4256 30.928 28.4768 30.5536 28.1648L28.2576 26.2512C27.1168 25.3008 25.4 25.2304 24.1744 26.0864L22.1664 27.4896C21.296 28.0976 20.2592 28.4016 19.2256 28.4016H19.2272Z"
          fill="black"
        />
        <path
          d="M5.35997 6.16001C6.818 6.16001 7.99997 4.97804 7.99997 3.52C7.99997 2.06197 6.818 0.880005 5.35997 0.880005C3.90194 0.880005 2.71997 2.06197 2.71997 3.52C2.71997 4.97804 3.90194 6.16001 5.35997 6.16001Z"
          fill="#10675B"
        />
        <path
          d="M5.36009 7.04C3.41929 7.04 1.84009 5.4608 1.84009 3.52C1.84009 1.5792 3.41929 0 5.36009 0C7.30089 0 8.88009 1.5792 8.88009 3.52C8.88009 5.4608 7.30089 7.04 5.36009 7.04ZM5.36009 1.76C4.38889 1.76 3.60009 2.5488 3.60009 3.52C3.60009 4.4912 4.38889 5.28 5.36009 5.28C6.33129 5.28 7.12009 4.4912 7.12009 3.52C7.12009 2.5488 6.33129 1.76 5.36009 1.76Z"
          fill="black"
        />
        <path
          d="M17.84 13.2C19.298 13.2 20.48 12.018 20.48 10.56C20.48 9.10197 19.298 7.92 17.84 7.92C16.3819 7.92 15.2 9.10197 15.2 10.56C15.2 12.018 16.3819 13.2 17.84 13.2Z"
          fill="#10675B"
        />
        <path
          d="M17.8401 14.08C15.8993 14.08 14.3201 12.5008 14.3201 10.56C14.3201 8.61919 15.8993 7.03999 17.8401 7.03999C19.7809 7.03999 21.3601 8.61919 21.3601 10.56C21.3601 12.5008 19.7809 14.08 17.8401 14.08ZM17.8401 8.79999C16.8689 8.79999 16.0801 9.58879 16.0801 10.56C16.0801 11.5312 16.8689 12.32 17.8401 12.32C18.8113 12.32 19.6001 11.5312 19.6001 10.56C19.6001 9.58879 18.8113 8.79999 17.8401 8.79999Z"
          fill="black"
        />
        <path
          d="M24.88 6.48001C26.338 6.48001 27.52 5.29804 27.52 3.84001C27.52 2.38198 26.338 1.20001 24.88 1.20001C23.422 1.20001 22.24 2.38198 22.24 3.84001C22.24 5.29804 23.422 6.48001 24.88 6.48001Z"
          fill="#10675B"
        />
        <path
          d="M24.8801 7.36001C22.9393 7.36001 21.3601 5.78081 21.3601 3.84001C21.3601 1.89921 22.9393 0.320007 24.8801 0.320007C26.8209 0.320007 28.4001 1.89921 28.4001 3.84001C28.4001 5.78081 26.8209 7.36001 24.8801 7.36001ZM24.8801 2.08001C23.9089 2.08001 23.1201 2.86881 23.1201 3.84001C23.1201 4.81121 23.9089 5.60001 24.8801 5.60001C25.8513 5.60001 26.6401 4.81121 26.6401 3.84001C26.6401 2.86881 25.8513 2.08001 24.8801 2.08001Z"
          fill="black"
        />
        <path
          d="M23.4398 21.84C24.8978 21.84 26.0798 20.658 26.0798 19.2C26.0798 17.742 24.8978 16.56 23.4398 16.56C21.9818 16.56 20.7998 17.742 20.7998 19.2C20.7998 20.658 21.9818 21.84 23.4398 21.84Z"
          fill="#DCFFD1"
        />
        <path
          d="M23.4399 22.72C21.4991 22.72 19.9199 21.1408 19.9199 19.2C19.9199 17.2592 21.4991 15.68 23.4399 15.68C25.3807 15.68 26.9599 17.2592 26.9599 19.2C26.9599 21.1408 25.3807 22.72 23.4399 22.72ZM23.4399 17.44C22.4687 17.44 21.6799 18.2288 21.6799 19.2C21.6799 20.1712 22.4687 20.96 23.4399 20.96C24.4111 20.96 25.1999 20.1712 25.1999 19.2C25.1999 18.2288 24.4111 17.44 23.4399 17.44Z"
          fill="black"
        />
        <path
          d="M13.36 28.56C14.818 28.56 16 27.378 16 25.92C16 24.462 14.818 23.28 13.36 23.28C11.9019 23.28 10.72 24.462 10.72 25.92C10.72 27.378 11.9019 28.56 13.36 28.56Z"
          fill="#DCFFD1"
        />
        <path
          d="M13.3601 29.44C11.4193 29.44 9.84009 27.8608 9.84009 25.92C9.84009 23.9792 11.4193 22.4 13.3601 22.4C15.3009 22.4 16.8801 23.9792 16.8801 25.92C16.8801 27.8608 15.3009 29.44 13.3601 29.44ZM13.3601 24.16C12.3889 24.16 11.6001 24.9488 11.6001 25.92C11.6001 26.8912 12.3889 27.68 13.3601 27.68C14.3313 27.68 15.1201 26.8912 15.1201 25.92C15.1201 24.9488 14.3313 24.16 13.3601 24.16Z"
          fill="black"
        />
        <path
          d="M7.4398 15.92C8.89784 15.92 10.0798 14.738 10.0798 13.28C10.0798 11.822 8.89784 10.64 7.4398 10.64C5.98177 10.64 4.7998 11.822 4.7998 13.28C4.7998 14.738 5.98177 15.92 7.4398 15.92Z"
          fill="#10675B"
        />
        <path
          d="M7.43992 16.8C5.49912 16.8 3.91992 15.2208 3.91992 13.28C3.91992 11.3392 5.49912 9.75999 7.43992 9.75999C9.38072 9.75999 10.9599 11.3392 10.9599 13.28C10.9599 15.2208 9.38072 16.8 7.43992 16.8ZM7.43992 11.52C6.46872 11.52 5.67992 12.3088 5.67992 13.28C5.67992 14.2512 6.46872 15.04 7.43992 15.04C8.41112 15.04 9.19992 14.2512 9.19992 13.28C9.19992 12.3088 8.41112 11.52 7.43992 11.52Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2362_2353">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
