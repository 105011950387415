import * as comparators from "../util/comparators";

export interface Unit {
  label: string;
  alternativeLabels?: Array<string>;
  subrecipeOnly: boolean;
  // The value for any unit must match the server's definition
  value: string;
}

export const gramsUnit: Unit = { label: "g", value: "g", subrecipeOnly: false };
export const kilogramsUnit: Unit = {
  label: "kg",
  value: "kg",
  subrecipeOnly: false,
};

export const productMassUnits = [gramsUnit, kilogramsUnit];

const allIngredientQuantityUnits: Array<Unit> = [
  gramsUnit,
  {
    label: "servings",
    value: "serving",
    subrecipeOnly: true,
  },
  kilogramsUnit,
  { label: "fl oz", value: "floz", subrecipeOnly: false },
  { label: "ml", value: "ml", subrecipeOnly: false },
  { label: "cl", value: "cl", subrecipeOnly: false },
  { label: "l", value: "l", subrecipeOnly: false },
  { label: "tbsp", value: "tbsp", subrecipeOnly: false },
  { label: "tsp", value: "tsp", subrecipeOnly: false },
  { label: "cup", value: "cup", subrecipeOnly: false },
  { label: "pint", value: "pt", subrecipeOnly: false },
  {
    label: "each",
    alternativeLabels: ["ea"],
    value: "each",
    subrecipeOnly: false,
  },
  { label: "oz", value: "oz", subrecipeOnly: false },
  { label: "lb", value: "lb", subrecipeOnly: false },
  { label: "gal", value: "gal", subrecipeOnly: false },
];

const simpleIngredientQuantityUnits = allIngredientQuantityUnits.filter(
  (unit) => !unit.subrecipeOnly
);
const subrecipeIngredientQuantityUnits = allIngredientQuantityUnits;

export const subrecipeOnlyQuantityUnits = allIngredientQuantityUnits.filter(
  (unit) => unit.subrecipeOnly
);

export function ingredientQuantityUnits({
  isSubrecipe,
}: {
  isSubrecipe: boolean;
}) {
  if (isSubrecipe) {
    return subrecipeIngredientQuantityUnits;
  } else {
    return simpleIngredientQuantityUnits;
  }
}

const labelsByValue = new Map(
  allIngredientQuantityUnits.map(({ label, value }) => [value, label])
);

export function unitLabel(unitValue: string): string {
  return labelsByValue.get(unitValue) ?? unitValue;
}

// When parsing, a unit is recognised by either its value or its label.
const unitStringForParsingToUnit = (units: Array<Unit>) =>
  new Map(
    units
      .flatMap((unit) =>
        [
          { string: unit.label, unit },
          { string: unit.value, unit },
        ].concat(
          unit.alternativeLabels
            ? unit.alternativeLabels.map((label) => {
                return { string: label, unit };
              })
            : []
        )
      )
      .sort(
        comparators.map(
          ({ string }) => string,
          comparators.stringSensitivityBase
        )
      )
      .map(({ string, unit }) => [string, unit])
  );

const simpleIngredientUnitStringForParsingToUnit = unitStringForParsingToUnit(
  simpleIngredientQuantityUnits
);
const allUnitStringForParsingToUnit = unitStringForParsingToUnit(
  subrecipeIngredientQuantityUnits
);

const unitStringsForParsing = (stringToUnitMap: Map<string, Unit>) =>
  new Set(stringToUnitMap.keys());

export const simpleIngredientUnitStringsForParsing = unitStringsForParsing(
  simpleIngredientUnitStringForParsingToUnit
);
export const allUnitStringsForParsing = unitStringsForParsing(
  allUnitStringForParsingToUnit
);

export function parseUnit(input: string): Unit | null {
  return allUnitStringForParsingToUnit.get(input.toLocaleLowerCase()) ?? null;
}
