import VectorProps from "../VectorProps";

export default function Retail(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Ksp5K23jLgLje03Y-0
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_1831)">
        <path
          d="M10.72 21.12C16.5079 21.12 21.2 16.4279 21.2 10.64C21.2 4.85206 16.5079 0.160004 10.72 0.160004C4.93205 0.160004 0.23999 4.85206 0.23999 10.64C0.23999 16.4279 4.93205 21.12 10.72 21.12Z"
          fill="#67DCC2"
        />
        <path
          d="M26.7966 18.1472V28.6704C26.7966 29.8912 25.8078 30.88 24.587 30.88H7.41419C6.19339 30.88 5.20459 29.8912 5.20459 28.6704V18.1472"
          fill="#00A68F"
        />
        <path
          d="M24.5871 31.76H7.41431C5.71031 31.76 4.32471 30.3744 4.32471 28.6704V18.1472C4.32471 17.6608 4.71831 17.2672 5.20471 17.2672C5.69111 17.2672 6.08471 17.6608 6.08471 18.1472V28.6704C6.08471 29.4032 6.68151 30 7.41431 30H24.5871C25.3199 30 25.9167 29.4032 25.9167 28.6704V18.1472C25.9167 17.6608 26.3103 17.2672 26.7967 17.2672C27.2831 17.2672 27.6767 17.6608 27.6767 18.1472V28.6704C27.6767 30.3744 26.2911 31.76 24.5871 31.76Z"
          fill="black"
        />
        <path
          d="M15.9633 21.4768H16.3153C18.5665 21.4768 20.3937 23.304 20.3937 25.5552V30.8096H11.8833V25.5552C11.8833 23.304 13.7105 21.4768 15.9617 21.4768H15.9633Z"
          fill="#DCFFD1"
        />
        <path
          d="M20.3953 31.6912H11.8849C11.3985 31.6912 11.0049 31.2976 11.0049 30.8112V25.5568C11.0049 22.8224 13.2289 20.5984 15.9633 20.5984H16.3153C19.0497 20.5984 21.2737 22.8224 21.2737 25.5568V30.8112C21.2737 31.2976 20.8801 31.6912 20.3937 31.6912H20.3953ZM12.7649 29.9312H19.5153V25.5568C19.5153 23.7936 18.0801 22.3584 16.3169 22.3584H15.9649C14.2017 22.3584 12.7665 23.7936 12.7665 25.5568V29.9312H12.7649Z"
          fill="black"
        />
        <path
          d="M9.48635 16.1952C9.48635 17.9936 8.02875 19.4528 6.22875 19.4528C6.16475 19.4528 6.10075 19.4512 6.03675 19.448C3.95355 19.328 2.61755 17.16 3.33595 15.2L6.37115 6.92C6.73275 5.936 7.66875 5.28 8.71835 5.28H23.5167C24.5855 5.28 25.5375 5.96 25.8831 6.9728L28.7183 15.288C29.2655 16.8944 28.4687 18.6896 26.8703 19.2624C26.5263 19.3856 26.1567 19.4528 25.7695 19.4528C23.9711 19.4528 22.5119 17.9952 22.5119 16.1952"
          fill="#00A68F"
        />
        <path
          d="M25.7727 20.3328C23.4911 20.3328 21.6351 18.4768 21.6351 16.1952C21.6351 15.7088 22.0287 15.3152 22.5151 15.3152C23.0015 15.3152 23.3951 15.7088 23.3951 16.1952C23.3951 17.5056 24.4623 18.5728 25.7727 18.5728C26.0495 18.5728 26.3199 18.5264 26.5759 18.4336C27.6991 18.0304 28.2879 16.7456 27.8879 15.5712L25.0527 7.25601C24.8287 6.60001 24.2127 6.15841 23.5199 6.15841H8.71989C8.04309 6.15841 7.43189 6.58561 7.19989 7.22081L4.16469 15.5008C3.91349 16.1872 4.00309 16.9648 4.40629 17.5808C4.79189 18.168 5.40469 18.528 6.08949 18.568C6.13589 18.5712 6.18229 18.5728 6.23029 18.5728C7.54069 18.5728 8.60789 17.5056 8.60789 16.1952C8.60789 15.7088 9.00149 15.3152 9.48789 15.3152C9.97429 15.3152 10.3679 15.7088 10.3679 16.1952C10.3679 18.4768 8.51189 20.3328 6.23029 20.3328C6.14869 20.3328 6.06869 20.3312 5.98869 20.3264C4.74229 20.2544 3.62869 19.6064 2.93429 18.5488C2.22709 17.4704 2.06869 16.1056 2.51029 14.8976L5.54549 6.61761C6.03189 5.29121 7.30709 4.40161 8.71829 4.40161H23.5167C24.9631 4.40161 26.2479 5.32161 26.7151 6.68961L29.5503 15.0048C30.2591 17.0832 29.1903 19.3664 27.1679 20.0912C26.7199 20.2512 26.2495 20.3328 25.7695 20.3328H25.7727Z"
          fill="black"
        />
        <path
          d="M20.7216 5.28L22.5152 16.1952C22.5152 17.9936 21.0576 19.4528 19.2576 19.4528C17.4576 19.4528 16 17.9952 16 16.1952V5.28H20.7216Z"
          fill="#10675B"
        />
        <path
          d="M19.2577 20.3328C16.9761 20.3328 15.1201 18.4768 15.1201 16.1952V5.27999C15.1201 4.79359 15.5137 4.39999 16.0001 4.39999H20.7217C21.1521 4.39999 21.5201 4.71199 21.5905 5.13759L23.3841 16.0528C23.3921 16.0992 23.3953 16.1472 23.3953 16.1952C23.3953 18.4768 21.5393 20.3328 19.2577 20.3328ZM16.8801 6.15999V16.1952C16.8801 17.5056 17.9473 18.5728 19.2577 18.5728C20.5681 18.5728 21.5985 17.5424 21.6337 16.2624L19.9745 6.15999H16.8801Z"
          fill="black"
        />
        <path
          d="M9.48633 16.1952C9.48633 17.9936 10.9439 19.4528 12.7439 19.4528C14.5439 19.4528 16.0015 17.9952 16.0015 16.1952"
          fill="#00A68F"
        />
        <path
          d="M12.744 20.3328C10.4672 20.3328 8.61445 18.4848 8.60645 16.2096C8.60645 16.1744 8.60645 16.1392 8.61125 16.104L9.75845 5.1888C9.80965 4.7056 10.2432 4.3568 10.7248 4.4064C11.1776 4.4544 11.5136 4.8352 11.512 5.28C11.512 5.3104 11.512 5.3408 11.5072 5.3728L10.3648 16.2384C10.3872 17.5296 11.4448 18.5728 12.7424 18.5728C14.04 18.5728 15.12 17.5056 15.12 16.1952C15.12 15.7088 15.5136 15.3152 16 15.3152C16.4864 15.3152 16.88 15.7088 16.88 16.1952C16.88 18.4768 15.024 20.3328 12.7424 20.3328H12.744Z"
          fill="black"
        />
        <path
          d="M9.52785 16.1952C9.52785 17.9936 8.07025 19.4528 6.27025 19.4528C6.20625 19.4528 6.14225 19.4512 6.07825 19.448C3.99505 19.328 2.65905 17.16 3.37745 15.2L6.41265 6.92C6.77425 5.936 7.71025 5.28 8.75985 5.28H10.6479L9.52625 16.1952H9.52785Z"
          fill="#10675B"
        />
        <path
          d="M6.27033 20.3328C6.18873 20.3328 6.10873 20.3312 6.02873 20.3264C4.78233 20.2544 3.66873 19.6064 2.97433 18.5488C2.26713 17.4704 2.10873 16.1056 2.55033 14.8976L5.58553 6.61761C6.07193 5.29121 7.34713 4.40161 8.75833 4.40161H10.6463C10.8959 4.40161 11.1327 4.50721 11.2991 4.69121C11.4655 4.87681 11.5471 5.12321 11.5215 5.37121L10.4047 16.2448C10.3791 18.504 8.53273 20.3344 6.26713 20.3344L6.27033 20.3328ZM8.76153 6.16001C8.08473 6.16001 7.47353 6.58721 7.24153 7.22241L4.20633 15.5024C3.95513 16.1888 4.04473 16.9664 4.44793 17.5824C4.83353 18.1696 5.44633 18.5296 6.13113 18.5696C6.17753 18.5728 6.22393 18.5744 6.27193 18.5744C7.58233 18.5744 8.64953 17.5072 8.64953 16.1968C8.64953 16.1664 8.64953 16.136 8.65433 16.1072L9.67513 6.16161H8.76153V6.16001Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2079_1831">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
