import classNames from "classnames";

import VectorProps from "./VectorProps";
import "./Delete.css";

interface DeleteProps extends VectorProps {
  title?: string;
}

export default function Delete(props: DeleteProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from bootstrap-icons
  return (
    <svg
      className={classNames(className, "Delete__svg")}
      height={height ?? width}
      id="deleteIcon"
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
}
