import { useState } from "react";

import * as FloatEditor from "./FloatEditor";

const stories = {
  name: "FloatEditor",
  render: () => <Story />,
};

function Story() {
  const [value, setValue] = useState<FloatEditor.Value>(
    FloatEditor.initialValue(null)
  );

  return (
    <div style={{ width: "120px" }}>
      <FloatEditor.FloatEditor
        value={value}
        onChange={setValue}
        unitLabel={"kg"}
      />
    </div>
  );
}

export default stories;
