import classNames from "classnames";

import { Delete } from "./Vectors";
import ErrorCross from "./Vectors/ErrorCross";

import "./Toast.css";

interface ToastProps {
  className?: string;
  message: React.ReactNode;
  onClose: () => void;
  show: boolean;
  symbol?: React.ReactNode;
  title: React.ReactNode;
}

export default function Toast(props: ToastProps) {
  const { className = "", message, onClose, show, symbol, title } = props;
  return show ? (
    <div className={classNames("Toast", className)}>
      <div className="Toast_Symbol">{symbol}</div>
      <div className="Toast_Text">
        <div className="medium-font">{title}</div>
        <small>{message}</small>
      </div>
      <div onClick={onClose}>
        <Delete width={20} />
      </div>
    </div>
  ) : (
    <></>
  );
}

interface ErrorToastProps {
  message: React.ReactNode;
  onClose: () => void;
  show: boolean;
  title: React.ReactNode;
}

export function ErrorToast(props: ErrorToastProps) {
  const { message, onClose, show, title } = props;
  return (
    <Toast
      className={classNames("Error-Toast")}
      message={message}
      onClose={onClose}
      show={show}
      symbol={<ErrorCross width={20} />}
      title={title}
    />
  );
}
