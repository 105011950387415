import classNames from "classnames";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

import useId from "./useId";
import "./RadioToggleButtonGroup.css";

interface RadioToggleButtonOption<T> {
  accessibilityLabel?: string;
  key: string;
  label: React.ReactNode;
  value: T;
}

interface RadioToggleButtonGroupProps<T> {
  buttonClassName?: string;
  className?: string;
  onChange: (value: T) => void;
  options: Array<RadioToggleButtonOption<T>>;
  size?: "sm" | "lg";
  value: T;
}

export default function RadioToggleButtonGroup<T>(
  props: RadioToggleButtonGroupProps<T>
) {
  const { buttonClassName, className, onChange, options, size, value } = props;

  const name = useId();

  const handleChange = (key: string) => {
    const option = options.find((option) => option.key === key);
    if (option !== undefined) {
      onChange(option.value);
    }
  };

  const selectedOption =
    options.find((option) => option.value === value) ?? options[0];

  return (
    <ToggleButtonGroup
      className={className}
      name={name}
      onChange={handleChange}
      type="radio"
      value={selectedOption.key}
    >
      {options.map((option) => (
        <ToggleButton
          className={classNames(
            buttonClassName,
            "RadioButtonToggleGroup__ToggleButton"
          )}
          key={option.key}
          size={size}
          value={option.key}
          variant={"outline-dark"}
          aria-label={option.accessibilityLabel}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
