import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { useTracking } from "../../tracking";
import * as comparators from "../../util/comparators";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import ActionModal from "../utils/ActionModal";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import Checkbox from "../utils/Checkbox";
import Form from "../utils/Form";
import Select from "../utils/Select";
import {
  IngredientOriginModal_Location as Location,
  IngredientOriginModal_UpdateWeightedLocationOption as UpdateWeightedLocationOption,
  IngredientOriginModal_UpdateWeightedLocationOptionVariables as UpdateWeightedLocationOptionVariables,
  IngredientOriginModal_DeleteWeightedLocationOption as DeleteWeightedLocationOption,
  IngredientOriginModal_DeleteWeightedLocationOptionVariables as DeleteWeightedLocationOptionVariables,
  IngredientOriginModal_WeightedLocationOption as WeightedLocationOption,
} from "./IngredientOriginModal.graphql";
import { averageSourcingLabel, locationsToString } from "./ingredientOrigins";

interface IngredientOriginModalProps {
  foodClassId: number | null;
  foodClassNamesById: Map<number, string>;
  locations: Array<Location>;
  onHide: () => void;
  weightedLocationOption: WeightedLocationOption | null;
  refreshWeightedLocationOptions: () => Promise<void>;
}

export default function IngredientOriginModal(
  props: IngredientOriginModalProps
) {
  const {
    foodClassId,
    foodClassNamesById,
    locations,
    onHide,
    weightedLocationOption,
    refreshWeightedLocationOptions,
  } = props;

  const [editing, setEditing] = useState(false);

  if (foodClassId === null) {
    return null;
  }

  const foodClassName = foodClassNamesById.get(foodClassId);

  if (foodClassName === undefined) {
    return null;
  }

  const getLocationById = (locationId: number) =>
    locations.find((location) => locationId === location.id) ?? null;

  return (
    <ActionModal show={true} title={foodClassName}>
      {editing ? (
        <EditWeightedLocationOptionForm
          backToInspect={() => setEditing(false)}
          foodClassId={foodClassId}
          foodClassName={foodClassName}
          getLocationById={getLocationById}
          locations={locations}
          weightedLocationOption={weightedLocationOption}
          refreshWeightedLocationOptions={refreshWeightedLocationOptions}
        />
      ) : (
        <InspectWeightedLocationOption
          close={() => onHide()}
          editWeightedLocationOption={() => setEditing(true)}
          getLocationById={getLocationById}
          weightedLocationOption={weightedLocationOption}
        />
      )}
    </ActionModal>
  );
}

interface InspectWeightedLocationOptionProps {
  close: () => void;
  editWeightedLocationOption: () => void;
  getLocationById: (locationId: number) => Location | null;
  weightedLocationOption: WeightedLocationOption | null;
}

function InspectWeightedLocationOption(
  props: InspectWeightedLocationOptionProps
) {
  const {
    close,
    editWeightedLocationOption,
    getLocationById,
    weightedLocationOption,
  } = props;

  const intl = useIntl();

  return (
    <>
      <ActionModal.Body>
        <h4>
          <FormattedMessage
            id="components/ingredients/IngredientOriginModal:origin"
            defaultMessage="Origin: {origin}"
            values={{
              origin: locationsToString(
                weightedLocationOption?.locationProportions.map(
                  (locationProportion) =>
                    getLocationById(locationProportion.locationId)
                ),
                intl
              ),
            }}
          />
        </h4>
      </ActionModal.Body>
      <ActionModal.Footer>
        <div className="d-flex w-100 justify-content-between">
          <SecondaryButton onClick={() => close()}>
            <FormattedMessage
              id="components/ingredients/IngredientOriginModal:closeButton"
              defaultMessage="Close"
            />
          </SecondaryButton>
          {(weightedLocationOption?.locationProportions.length ?? 0) <= 1 ? (
            <PrimaryButton onClick={() => editWeightedLocationOption()}>
              <FormattedMessage
                id="components/ingredients/IngredientOriginModal:editOriginButton"
                defaultMessage="Edit Origin"
              />
            </PrimaryButton>
          ) : null}
        </div>
      </ActionModal.Footer>
    </>
  );
}

interface EditWeightedLocationOptionFormProps {
  backToInspect: () => void;
  foodClassId: number;
  foodClassName: string;
  getLocationById: (locationId: number) => Location | null;
  locations: Array<Location>;
  refreshWeightedLocationOptions: () => void;
  weightedLocationOption: WeightedLocationOption | null;
}

function EditWeightedLocationOptionForm(
  props: EditWeightedLocationOptionFormProps
) {
  const {
    backToInspect,
    foodClassId,
    foodClassName,
    getLocationById,
    locations,
    refreshWeightedLocationOptions,
    weightedLocationOption,
  } = props;

  const intl = useIntl();
  const { trackIngredientOriginChanged } = useTracking();

  const [defaultOrigin, setDefaultOrigin] = useState(
    weightedLocationOption === null
  );
  const [location, setLocation] = useState<Location | null>(
    weightedLocationOption?.locationProportions[0] !== undefined
      ? getLocationById(
          weightedLocationOption.locationProportions[0].locationId
        )
      : null
  );
  const [organizationId] = useOrganizationId();

  const [updateWeightedLocationOption] = useMutation<
    UpdateWeightedLocationOption,
    UpdateWeightedLocationOptionVariables
  >(updateWeightedLocationOptionMutation);

  const [deleteWeightedLocationOption] = useMutation<
    DeleteWeightedLocationOption,
    DeleteWeightedLocationOptionVariables
  >(deleteWeightedLocationOptionMutation);

  const history = useHistory();

  const handleSubmit = async () => {
    if (defaultOrigin) {
      await deleteWeightedLocationOption({
        variables: {
          input: {
            foodClassId,
            organizationId,
          },
        },
      });
    } else if (location !== null) {
      await updateWeightedLocationOption({
        variables: {
          input: {
            foodClassId,
            locationProportions: [{ locationId: location.id, proportion: 1.0 }],
            organizationId,
          },
        },
      });
    } else {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/ingredients/IngredientOriginModal:missingOriginErrorMessage",
          defaultMessage: "You must select an origin",
        })
      );
    }

    await refreshWeightedLocationOptions();
    backToInspect();

    // The above refresh call doesn't seem to update the impact of the ingredient, so for now we just refresh the page.
    // Using history.replace instead of location.reload to make it slightly less jarring.
    const currentLocation = history.location;
    history.replace("/");
    history.replace(currentLocation);

    trackIngredientOriginChanged({
      ingredientOrigin: defaultOrigin
        ? "Average Sourcing"
        : location
        ? location.name
        : "",
      ingredientName: foodClassName,
    });
  };

  return (
    <Form onSubmit={() => handleSubmit()}>
      <ActionModal.Body>
        <h4>
          <FormattedMessage
            id="components/ingredients/IngredientOriginModal:originLabel"
            defaultMessage="Origin:"
          />
        </h4>
        <div className="my-3">
          <Checkbox
            checked={defaultOrigin}
            onChange={(checked) => setDefaultOrigin(checked)}
            label={
              <FormattedMessage
                id="components/ingredients/IngredientOriginModal:useAverageSourcing"
                defaultMessage="Use {averageSourcingLabel}"
                values={{ averageSourcingLabel: averageSourcingLabel(intl) }}
              />
            }
          />
        </div>
        <Select<Location>
          disabled={defaultOrigin}
          onChange={(location) => setLocation(location)}
          optionKey={(location) => location.id.toString()}
          options={locations.sort(
            comparators.map(
              (location) => location.name,
              comparators.stringSensitivityBase
            )
          )}
          renderOption={(location) => location.name}
          value={location}
        />
        <p className="mt-4">
          <FormattedMessage
            id="components/ingredients/IngredientOriginModal:ingredientOriginChangesDelayExplanation"
            defaultMessage="
                  Please note that any changes to ingredient origins will take a few
                  minutes to take effect.
                "
          />
        </p>
      </ActionModal.Body>
      <ActionModal.Footer>
        <div className="d-flex w-100 justify-content-between">
          <div>
            <Form.SecondaryButton onClick={() => backToInspect()}>
              <FormattedMessage
                id="components/ingredients/IngredientOriginModal:cancelButton"
                defaultMessage="Cancel"
              />
            </Form.SecondaryButton>
          </div>
          <div>
            <Form.SubmitButton
              loadingLabel={intl.formatMessage({
                id: "components/ingredients/IngredientOriginModal:saveButton/loadingLabel",
                defaultMessage: "Saving",
              })}
              submitLabel={intl.formatMessage({
                id: "components/ingredients/IngredientOriginModal:saveButton/submitLabel",
                defaultMessage: "Save",
              })}
            />
          </div>
        </div>
        <Form.ErrorAlert className="mt-4" />
      </ActionModal.Footer>
    </Form>
  );
}

IngredientOriginModal.fragments = {
  location: gql`
    fragment IngredientOriginModal_Location on Location {
      id
      name
    }
  `,

  weightedLocationOption: gql`
    fragment IngredientOriginModal_WeightedLocationOption on WeightedLocationOption {
      foodClassId
      locationProportions {
        locationId
      }
    }
  `,
};

const updateWeightedLocationOptionMutation = gql`
  mutation IngredientOriginModal_UpdateWeightedLocationOption(
    $input: UpdateWeightedLocationOptionInput!
  ) {
    updateWeightedLocationOption(input: $input) {
      success
    }
  }
`;

const deleteWeightedLocationOptionMutation = gql`
  mutation IngredientOriginModal_DeleteWeightedLocationOption(
    $input: DeleteWeightedLocationOptionInput!
  ) {
    deleteWeightedLocationOption(input: $input) {
      success
    }
  }
`;
