import VectorProps from "../VectorProps";

export default function EndMile(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Ksp5K23jLgLje03Y-0
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_1883)">
        <path
          d="M17.76 24.64C23.548 24.64 28.24 19.9479 28.24 14.16C28.24 8.37205 23.548 3.67999 17.76 3.67999C11.9721 3.67999 7.28003 8.37205 7.28003 14.16C7.28003 19.9479 11.9721 24.64 17.76 24.64Z"
          fill="#67DCC2"
        />
        <path
          d="M29.6641 25.872C30.2817 25.3632 30.6401 24.6064 30.6401 23.8064V23.176C30.6401 21.6272 29.6129 20.2672 28.1249 19.8416L23.6257 18.0752C22.7393 17.8224 21.9905 17.2256 21.5441 16.4192L20.0881 12.0288C19.4769 10.9248 18.3153 10.24 17.0529 10.24H15.0145L7.5265 10.2496C6.4161 10.2496 5.3745 10.784 4.7233 11.6816L1.8609 17.8704C1.4241 18.4736 1.1921 19.2016 1.2017 19.9472L1.2209 21.5392V23.88C1.2225 24.5968 1.7249 25.2144 2.4257 25.3632L7.6801 26.4784H27.4929L29.1201 26.3184L29.6641 25.8704V25.872Z"
          fill="#00A68F"
        />
        <path
          d="M27.4928 27.36H7.68002C7.61922 27.36 7.55682 27.3536 7.49762 27.3408L2.24322 26.2256C1.14402 25.992 0.344018 25.008 0.340818 23.8848V21.5408L0.321618 19.9584C0.310418 19.0608 0.585618 18.1664 1.09602 17.4288L3.92482 11.3136C3.94882 11.2624 3.97762 11.2128 4.01122 11.1664C4.82562 10.0432 6.13922 9.37119 7.52642 9.36959L17.0528 9.35999C18.6336 9.35999 20.0912 10.2192 20.8576 11.6032C20.8832 11.6512 20.9056 11.7008 20.9232 11.752L22.352 16.0592C22.6864 16.624 23.2352 17.048 23.8672 17.2288C23.8944 17.2368 23.92 17.2448 23.9472 17.256L28.4096 19.0064C30.2432 19.5504 31.52 21.2592 31.52 23.1744V23.8048C31.52 24.872 31.048 25.872 30.2224 26.5504L29.6784 26.9984C29.544 27.1088 29.3792 27.1776 29.2048 27.1952L27.5776 27.3552C27.5488 27.3584 27.52 27.36 27.4912 27.36H27.4928ZM7.77282 25.6H27.4512L28.7696 25.4704L29.1072 25.192C29.5232 24.8496 29.7616 24.3456 29.7616 23.8064V23.176C29.7616 22.0272 28.9888 21.0032 27.8848 20.688C27.8576 20.68 27.832 20.672 27.8048 20.6608L23.3424 18.9088C22.2576 18.5872 21.3232 17.8368 20.7744 16.8464C20.7488 16.7984 20.7264 16.7488 20.7088 16.6976L19.28 12.3904C18.8144 11.6048 17.968 11.1216 17.0528 11.1216H15.0144L7.52802 11.1312C6.72962 11.1312 5.97122 11.5072 5.48322 12.1376L2.65922 18.2416C2.63522 18.2928 2.60642 18.3424 2.57282 18.3888C2.24802 18.8368 2.07362 19.3872 2.08002 19.9392L2.09922 21.5312V23.8832C2.09922 24.1808 2.31362 24.4432 2.60642 24.5056L7.77122 25.6016L7.77282 25.6Z"
          fill="black"
        />
        <path
          d="M14.5151 16.8912C14.0287 16.8912 13.6351 16.4976 13.6351 16.0112C13.6351 14.8928 12.8079 14.6592 12.1135 14.6592C11.1119 14.6592 10.6047 15.1136 10.6047 16.0112C10.6047 16.4976 10.2111 16.8912 9.72473 16.8912C9.23833 16.8912 8.84473 16.4976 8.84473 16.0112C8.84473 14.1488 10.1583 12.8992 12.1135 12.8992C14.0687 12.8992 15.3951 14.1504 15.3951 16.0112C15.3951 16.4976 15.0015 16.8912 14.5151 16.8912Z"
          fill="black"
        />
        <path
          d="M8.7998 16.4416H15.4398V19.712C15.4398 20.2848 14.9758 20.7488 14.403 20.7488H9.8366C9.2638 20.7488 8.7998 20.2848 8.7998 19.712V16.4416Z"
          fill="#DCFFD1"
        />
        <path
          d="M14.4031 21.6288H9.83672C8.78072 21.6288 7.91992 20.7696 7.91992 19.712V16.4416C7.91992 15.9552 8.31352 15.5616 8.79992 15.5616H15.4399C15.9263 15.5616 16.3199 15.9552 16.3199 16.4416V19.712C16.3199 20.768 15.4607 21.6288 14.4031 21.6288ZM9.67992 17.3216V19.712C9.67992 19.7984 9.75032 19.8688 9.83672 19.8688H14.4031C14.4895 19.8688 14.5599 19.7984 14.5599 19.712V17.3216H9.67992Z"
          fill="black"
        />
        <path
          d="M25.1454 30.904C27.1062 30.904 28.6958 29.3144 28.6958 27.3536C28.6958 25.3928 27.1062 23.8032 25.1454 23.8032C23.1845 23.8032 21.595 25.3928 21.595 27.3536C21.595 29.3144 23.1845 30.904 25.1454 30.904Z"
          fill="#10675B"
        />
        <path
          d="M25.1455 31.784C22.7023 31.784 20.7151 29.7968 20.7151 27.3536C20.7151 24.9104 22.7023 22.9232 25.1455 22.9232C27.5887 22.9232 29.5759 24.9104 29.5759 27.3536C29.5759 29.7968 27.5887 31.784 25.1455 31.784ZM25.1455 24.6832C23.6735 24.6832 22.4751 25.8816 22.4751 27.3536C22.4751 28.8256 23.6735 30.024 25.1455 30.024C26.6175 30.024 27.8159 28.8256 27.8159 27.3536C27.8159 25.8816 26.6175 24.6832 25.1455 24.6832Z"
          fill="black"
        />
        <path
          d="M7.15343 30.904C9.11426 30.904 10.7038 29.3144 10.7038 27.3536C10.7038 25.3928 9.11426 23.8032 7.15343 23.8032C5.1926 23.8032 3.60303 25.3928 3.60303 27.3536C3.60303 29.3144 5.1926 30.904 7.15343 30.904Z"
          fill="#10675B"
        />
        <path
          d="M7.15354 31.784C4.71034 31.784 2.72314 29.7968 2.72314 27.3536C2.72314 24.9104 4.71034 22.9232 7.15354 22.9232C9.59674 22.9232 11.5839 24.9104 11.5839 27.3536C11.5839 29.7968 9.59674 31.784 7.15354 31.784ZM7.15354 24.6832C5.68154 24.6832 4.48314 25.8816 4.48314 27.3536C4.48314 28.8256 5.68154 30.024 7.15354 30.024C8.62554 30.024 9.82394 28.8256 9.82394 27.3536C9.82394 25.8816 8.62554 24.6832 7.15354 24.6832Z"
          fill="black"
        />
        <path
          d="M16.08 11.12C15.8208 11.12 15.5632 11.0064 15.3904 10.7872L10.64 4.79841C10.3376 4.41761 10.4016 3.86401 10.7824 3.56161C11.1632 3.25921 11.7168 3.32321 12.0192 3.70401L16.7696 9.69281C17.072 10.0736 17.008 10.6272 16.6272 10.9296C16.4656 11.0576 16.272 11.12 16.0816 11.12H16.08Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2079_1883">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
