import VectorProps from "./VectorProps";

export default function Help(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from bootstrap-icons
  return (
    <svg
      className={className}
      onClick={handleClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 15.2C4.02355 15.2 0.8 11.9764 0.8 8C0.8 4.02355 4.02355 0.8 8 0.8C11.9764 0.8 15.2 4.02355 15.2 8C15.2 11.9764 11.9764 15.2 8 15.2Z"
        fill="white"
        stroke={fill}
        strokeWidth="1.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.93954 5.67035C4.8874 5.61691 4.85963 5.54431 4.86279 5.46972C4.97136 3.53143 6.55765 2.85715 8.00565 2.85715C9.60222 2.85715 11.0605 3.69143 11.0605 5.41829C11.0605 6.65143 10.3348 7.23886 9.63879 7.768C8.79651 8.40686 8.48451 8.64572 8.48451 9.46629V9.58629C8.48451 9.74409 8.35659 9.872 8.19879 9.872H7.27193C7.11591 9.87202 6.98872 9.74687 6.98622 9.59086L6.98279 9.34286C6.93936 8.28343 7.54851 7.63086 8.31765 7.072C8.99308 6.56458 9.42051 6.23086 9.42051 5.50515C9.42051 4.56229 8.70279 4.17029 7.91879 4.17029C7.00222 4.17029 6.48793 4.71658 6.38508 5.46629C6.36451 5.62286 6.23879 5.752 6.08108 5.752H5.13822C5.06357 5.75333 4.99168 5.72379 4.93954 5.67035ZM7.79651 13.1154C7.12793 13.1154 6.64222 12.6651 6.64222 12.056C6.64222 11.4251 7.12793 10.9817 7.79536 10.9817C8.49136 10.9817 8.97136 11.4251 8.97136 12.056C8.97136 12.6651 8.49251 13.1154 7.79651 13.1154Z"
        fill={fill}
      />
    </svg>
  );
}
