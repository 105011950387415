import React, { useState } from "react";

import AutoSuggest from "./AutoSuggest";

interface ExampleSuggestion {
  id: string;
  name: string;
  key: number;
}

const allSuggestions: Array<ExampleSuggestion> = [
  { key: 1, id: "_sug1", name: "country" },
  { key: 2, id: "_sug2", name: "cave" },
  { key: 3, id: "_sug3", name: "accent" },
];

const stories = {
  name: "AutoSuggest",
  children: [
    {
      name: "Default AutoSuggest",
      render: () => <AutoSuggestStory />,
    },
    {
      name: "AutoSuggest With Forced Selection",
      render: () => <AutoSuggestStory forceSelection={true} />,
    },
    {
      name: "Disabled AutoSuggest",
      render: () => <AutoSuggestStory disabled={true} />,
    },
    {
      name: "Suggestions Appear After Two Characters AutoSuggest",
      render: () => <AutoSuggestStory suggestionsAppearAfter={2} />,
    },
    {
      name: "Input Error AutoSuggest",
      render: () => <AutoSuggestStory inputError />,
    },
  ],
};

interface AutoSuggestStoryProps {
  disabled?: boolean;
  forceSelection?: boolean;
  inputError?: boolean;
  suggestionsAppearAfter?: number;
}

function AutoSuggestStory(props: AutoSuggestStoryProps) {
  const [value, setValue] = useState<string>("");
  const {
    disabled = false,
    forceSelection = false,
    inputError = false,
    suggestionsAppearAfter = 0,
  } = props;

  return (
    <AutoSuggest<ExampleSuggestion>
      allSuggestions={allSuggestions}
      disabled={disabled}
      forceSelection={forceSelection}
      inputError={inputError}
      onChange={(newValue) => setValue(newValue)}
      placeholder={"type 'c'"}
      suggestionsAppearAfter={suggestionsAppearAfter}
      getSuggestionValue={(suggestion: ExampleSuggestion) => suggestion.name}
      value={value}
    />
  );
}

export default stories;
