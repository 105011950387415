import VectorProps from "./VectorProps";

export default function DecreaseTriangle(props: VectorProps) {
  const { className = "", fill = "currentColor", width } = props;

  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 14 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 12L0.0717964 0L13.9282 0L7 12Z" fill={fill} />
    </svg>
  );
}
