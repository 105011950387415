import VectorProps from "./VectorProps";

export default function Location(props: VectorProps) {
  const { fill = "currentColor", width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=C165tIqPfqxv4MDw-0
  return (
    <svg
      width={width}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34769 19.6191L1.25846 11.0151H1.24923C1.06168 10.7409 0.893077 10.4541 0.744615 10.1568C0.254896 9.18539 -0.000179705 8.11198 9.49881e-08 7.02334C9.49881e-08 5.16064 0.737497 3.37422 2.05025 2.05709C3.36301 0.739957 5.14348 0 7 0C8.85651 0 10.637 0.739957 11.9497 2.05709C13.2625 3.37422 14 5.16064 14 7.02334C14.0021 8.44827 13.5729 9.84024 12.7692 11.0151L7.67077 19.6191C7.60326 19.735 7.50667 19.8312 7.3906 19.898C7.27454 19.9648 7.14305 20 7.00923 20C6.8754 20 6.74391 19.9648 6.62785 19.898C6.51178 19.8312 6.41519 19.735 6.34769 19.6191ZM7 9C8 9 9 8 9 7C9 6 8 5 7 5C6 5 5 6 5 7C5 8 6 9 7 9Z"
        fill={fill}
      />
    </svg>
  );
}
