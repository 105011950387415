import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { SecondaryButton } from "./Button";
import useIsUnmounted from "./useIsUnmounted";

interface LoadMoreButtonProps {
  onClick: null | (() => Promise<void>);
}

export default function LoadMoreButton(props: LoadMoreButtonProps) {
  const { onClick } = props;

  const isUnmounted = useIsUnmounted();
  const [isLoading, setIsLoading] = useState(false);

  function setLoaded() {
    if (!isUnmounted.current) {
      setIsLoading(false);
    }
  }

  const handleClick = async () => {
    setIsLoading(true);
    try {
      if (onClick !== null) {
        await onClick();
      }
    } catch (error) {
      setLoaded();
    } finally {
      setLoaded();
    }
  };

  return onClick === null ? null : (
    <div className="mt-2 text-center">
      <SecondaryButton
        loading={isLoading}
        onClick={() => {
          handleClick();
        }}
      >
        {isLoading ? (
          <FormattedMessage
            id="components/utils/LoadMoreButton:loading"
            defaultMessage="Loading"
          />
        ) : (
          <FormattedMessage
            id="components/utils/LoadMoreButton:loadMore"
            defaultMessage="Load More"
          />
        )}
      </SecondaryButton>
    </div>
  );
}
