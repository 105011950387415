import classNames from "classnames";
import React, { ReactElement, useState } from "react";

import assertNever from "../../util/assertNever";
import TooltipOverlay from "./TooltipOverlay";
import {
  AttentionTriangle,
  ChevronRight,
  FilledCheckmark,
  FilledCircle,
  Private as PrivateIcon,
} from "./Vectors";

export interface Step {
  hasError: boolean;
  label: React.ReactNode;
  locked?: boolean;
  lockedMessage?: React.ReactNode;
  render: (options: {
    onBack: null | (() => void);
    onNext: null | (() => void);
  }) => React.ReactNode;
  renderControls: (options: {
    onBack: null | (() => void);
    onNext: null | (() => void);
  }) => React.ReactNode;
}

interface StepperProps {
  contentClassNames?: string;
  enforceOrder?: boolean;
  steps: Array<Step>;
  navContainer?: (stepperNav: ReactElement) => ReactElement;
  onLockClick?: () => void;
}

type StepState =
  | "active"
  | "complete"
  | "nonNavigable"
  | "hasErrorActive"
  | "hasErrorComplete"
  | "hasErrorNonNavigable";

export default function Stepper(props: StepperProps) {
  const {
    contentClassNames,
    enforceOrder = true,
    navContainer = (stepperNav) => stepperNav,
    onLockClick,
    steps,
  } = props;

  const [selectedStepIndex, setSelectedStepIndex] = useState(0);

  const selectedStep = steps[selectedStepIndex];

  const renderOptions = {
    onBack:
      selectedStepIndex === 0
        ? null
        : () => setSelectedStepIndex(selectedStepIndex - 1),
    onNext:
      selectedStepIndex === steps.length - 1
        ? null
        : () => setSelectedStepIndex(selectedStepIndex + 1),
  };

  return (
    <div className="stepper">
      <div className="d-flex flex-column">
        {navContainer(
          <StepperNav
            enforceOrder={enforceOrder}
            onLockClick={onLockClick}
            onSelectStep={(stepIndex) => setSelectedStepIndex(stepIndex)}
            selectedStepIndex={selectedStepIndex}
            steps={steps}
          />
        )}
        {selectedStep === undefined ? null : (
          <div className={classNames(contentClassNames, "flex-grow-1")}>
            {steps[selectedStepIndex].render(renderOptions)}
          </div>
        )}
      </div>
      {steps[selectedStepIndex].renderControls(renderOptions)}
    </div>
  );
}

interface StepperNavProps {
  enforceOrder: boolean;
  onLockClick?: () => void;
  onSelectStep: (stepIndex: number) => void;
  selectedStepIndex: number;
  steps: Array<Step>;
}

function StepperNav(props: StepperNavProps) {
  const { enforceOrder, onLockClick, onSelectStep, selectedStepIndex, steps } =
    props;

  const isNavigable = (stepIndex: number) =>
    !enforceOrder || stepIndex <= selectedStepIndex;

  const stepState = (stepIndex: number, hasError: boolean) => {
    if (hasError) {
      return selectedStepIndex === stepIndex
        ? "hasErrorActive"
        : isNavigable(stepIndex)
        ? "hasErrorComplete"
        : "hasErrorNonNavigable";
    } else {
      return selectedStepIndex === stepIndex
        ? "active"
        : isNavigable(stepIndex)
        ? "complete"
        : "nonNavigable";
    }
  };

  return (
    <div className="list-group flex-row">
      {steps.map((step, stepIndex) => {
        return (
          <StepperNavStep
            state={stepState(stepIndex, step.hasError)}
            hasError={step.hasError}
            key={stepIndex}
            onClick={() =>
              !step.locked && isNavigable(stepIndex) && onSelectStep(stepIndex)
            }
            stepIndex={stepIndex}
          >
            <div className={classNames({ locked: step.locked })}>
              {stepIndex > 0 ? (
                <ChevronRight className="mx-3" width="11.6px" />
              ) : null}
            </div>
            <NavStepButtonSymbol
              enforceOrder={enforceOrder}
              handleLockClick={onLockClick}
              locked={step.locked}
              lockedMessage={step.lockedMessage}
              state={stepState(stepIndex, step.hasError)}
              stepIndex={stepIndex}
            />
            <div className={classNames({ locked: step.locked })}>
              {step.label}
            </div>
          </StepperNavStep>
        );
      })}
    </div>
  );
}

interface NavStepButtonSymbolProps {
  enforceOrder: boolean;
  handleLockClick?: () => void;
  locked?: boolean;
  lockedMessage?: React.ReactNode;
  state: StepState;
  stepIndex: number;
}

function NavStepButtonSymbol(props: NavStepButtonSymbolProps) {
  const {
    enforceOrder,
    handleLockClick,
    locked,
    lockedMessage,
    state,
    stepIndex,
  } = props;

  const displayedStepNumber = stepIndex + 1;

  if (locked) {
    return (
      <>
        <div>
          <TooltipOverlay
            id="components/utils/Stepper:privateIconTooltip"
            overlay={lockedMessage}
            placement="top"
            style={{ maxWidth: "144px", marginLeft: "-2px" }}
          >
            <PrivateIcon
              className="private-icon"
              width={20}
              handleClick={handleLockClick}
            />
          </TooltipOverlay>
        </div>
      </>
    );
  }

  if (
    state === "hasErrorActive" ||
    state === "hasErrorComplete" ||
    state === "hasErrorNonNavigable"
  ) {
    return <AttentionTriangle width="24px" className="mr-2" />;
  } else if (state === "complete") {
    return enforceOrder ? (
      <FilledCheckmark width="24px" className="mr-2" />
    ) : (
      <FilledCircle
        width="24px"
        className="mr-2"
        content={displayedStepNumber}
      />
    );
  } else if (state === "active") {
    return (
      <FilledCircle
        width="24px"
        className="mr-2"
        content={displayedStepNumber}
      />
    );
  } else if (state === "nonNavigable") {
    return (
      <FilledCircle
        width="24px"
        className="mr-2"
        content={displayedStepNumber}
        fill="#666665"
      />
    );
  } else {
    assertNever(state, `invalid state: ${state}`);
  }
}

interface StepperNavStepProps {
  state: StepState;
  children: React.ReactNode;
  hasError: boolean;
  onClick: () => void;
  stepIndex: number;
}

function StepperNavStep(props: StepperNavStepProps) {
  const { state, children, onClick } = props;

  return (
    <button
      aria-current={
        state === "active" || state === "hasErrorActive" ? "true" : undefined
      }
      className={classNames(
        "medium-font border-0 bg-transparent p-0",
        state === "nonNavigable" || state === "hasErrorNonNavigable"
          ? "text-inactive"
          : state === "complete" || state === "hasErrorComplete"
          ? "action-link"
          : ""
      )}
      onClick={onClick}
    >
      <div className="d-flex align-items-center flex-row">{children}</div>
    </button>
  );
}
