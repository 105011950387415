import { v4 as uuidv4 } from "uuid";

import {
  PackagingComponentMaterialV2,
  PackagingComponentV2,
  PackagingMaterialV2,
} from "../../__generated__/globalTypes";
import { SetRequired } from "../../util/types";

interface PackagingComponentV2Stub extends PackagingComponentV2 {
  componentMaterials: Array<
    SetRequired<PackagingComponentMaterialV2, "recycledContentFraction">
  >;
  ownerOrganizationId: string | null;
  ghgMagnitude: number | null;
}

export function packagingComponentV2Stub(
  args?: Partial<PackagingComponentV2Stub>
): PackagingComponentV2Stub {
  return {
    componentMaterials: args?.componentMaterials ?? [
      packagingComponentMaterialV2Stub(),
    ],
    id: args?.id ?? uuidv4(),
    name: args?.name ?? "<packaging component>",
    ownerOrganizationId: args?.ownerOrganizationId ?? "<owner organization id>",
    ghgMagnitude: args?.ghgMagnitude ?? 0.1,
  };
}

export function packagingComponentMaterialV2Stub(
  args?: Partial<PackagingComponentMaterialV2>
): SetRequired<PackagingComponentMaterialV2, "recycledContentFraction"> {
  const recycledContentFraction = args?.recycledContentFraction ?? null;
  return {
    id: args?.id ?? uuidv4(),
    material: args?.material ?? packagingMaterialV2Stub(),
    recycledContentFraction,
    weightKg: args?.weightKg ?? 0.015,
  };
}

export function packagingMaterialV2Stub(
  args?: Partial<PackagingMaterialV2>
): PackagingMaterialV2 {
  return {
    id: args?.id ?? uuidv4(),
    name: args?.name ?? "<packaging material>",
  };
}
