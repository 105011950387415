import VectorProps from "./VectorProps";

export default function Admin(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Q85QW9IPew7lWUUb-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1632_1474)">
        <path d="M13.8399 12.26C13.2599 12.26 12.7299 11.97 12.4199 11.47L11.7599 10.42L10.5199 10.38C10.2399 10.38 9.95995 10.29 9.71995 10.14C9.33995 9.91 9.21995 9.42 9.44995 9.04C9.67995 8.66 10.1699 8.54 10.5499 8.77L12.2499 8.84C12.5199 8.84 12.7599 8.99 12.8999 9.22L13.7799 10.63L14.6099 10.66L15.5499 9.22C15.6899 8.99 15.9299 8.85 16.1999 8.84L17.8599 8.78L18.3099 8.07L17.5299 6.53C17.4099 6.29 17.4099 6.01 17.5299 5.78L18.3099 4.31L17.9199 3.57L16.1999 3.48C15.9299 3.48 15.6899 3.33 15.5499 3.1L14.6699 1.69L13.8399 1.66L12.8999 3.1C12.7599 3.33 12.5199 3.47 12.2499 3.48L10.5899 3.54L10.1399 4.25L10.9199 5.79C11.0399 6.03 11.0399 6.31 10.9199 6.54L10.1399 8.01C9.92995 8.4 9.44995 8.55 9.05995 8.34C8.66995 8.13 8.51995 7.65 8.72995 7.26L9.30995 6.17L8.72995 5.08C8.45995 4.57 8.46995 3.96 8.75995 3.46L9.14995 2.79C9.43995 2.29 9.95995 1.98 10.5399 1.96L11.7799 1.92L12.4399 0.869996C12.7499 0.379996 13.2799 0.0899963 13.8599 0.0899963H14.6399C15.2199 0.0899963 15.7499 0.379996 16.0599 0.869996L16.7199 1.92L17.9599 1.96C18.5399 1.98 19.0599 2.29 19.3499 2.79L19.7399 3.46C20.0299 3.96 20.0399 4.57 19.7699 5.08L19.1899 6.17L19.7699 7.26C20.0399 7.77 20.0299 8.38 19.7399 8.88L19.3499 9.55C19.0599 10.05 18.5399 10.36 17.9599 10.38L16.7199 10.42L16.0599 11.47C15.7499 11.96 15.2199 12.26 14.6399 12.26H13.8399Z" />
        <path d="M14.2299 8.89C12.7199 8.89 11.4999 7.66 11.4999 6.16C11.4999 4.66 12.7299 3.43 14.2299 3.43C15.7299 3.43 16.9599 4.66 16.9599 6.16C16.9599 7.66 15.7299 8.89 14.2299 8.89ZM14.2299 5.03C13.6099 5.03 13.0999 5.54 13.0999 6.16C13.0999 6.78 13.6099 7.29 14.2299 7.29C14.8499 7.29 15.3599 6.78 15.3599 6.16C15.3599 5.54 14.8499 5.03 14.2299 5.03Z" />
        <path d="M13.3299 19.93H0.869946C0.429946 19.93 0.0699463 19.57 0.0699463 19.13C0.0699463 15.84 2.74995 13.16 6.03995 13.16H8.15995C11.4499 13.16 14.1299 15.84 14.1299 19.13C14.1299 19.57 13.7699 19.93 13.3299 19.93ZM1.73995 18.33H12.4599C12.0799 16.3 10.2999 14.76 8.15995 14.76H6.03995C3.89995 14.76 2.11995 16.3 1.73995 18.33Z" />
        <path d="M7.09995 13.37C5.07995 13.37 3.44995 11.73 3.44995 9.72C3.44995 7.71 5.08995 6.07 7.09995 6.07C9.10995 6.07 10.7499 7.71 10.7499 9.72C10.7499 11.73 9.10995 13.37 7.09995 13.37ZM7.09995 7.66C5.96995 7.66 5.04995 8.58 5.04995 9.72C5.04995 10.86 5.96995 11.77 7.09995 11.77C8.22995 11.77 9.14995 10.85 9.14995 9.72C9.14995 8.59 8.22995 7.66 7.09995 7.66Z" />
      </g>
      <defs>
        <clipPath id="clip0_1632_1474">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
