import VectorProps from "./VectorProps";

export function SystemBoundary(props: VectorProps) {
  const { className, fill = "currentColor", width } = props;
  // From https://www.figma.com/design/iXVTXWqjDqOG6BSlK3Jsx7/Reports?node-id=22672-1586&node-type=instance&m=dev

  return (
    <svg
      className={className}
      width={width}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / Sys. Boundary" clipPath="url(#clip0_22672_1586)">
        <g id="Vector">
          <path
            d="M20 2.41379C20 1.77362 19.7457 1.15966 19.293 0.706984C18.8403 0.25431 18.2264 0 17.5862 0H15.4828C14.9305 0 14.4828 0.447715 14.4828 1V1.06897C14.4828 1.62125 14.9305 2.06897 15.4828 2.06897H17.5862C17.6777 2.06897 17.7654 2.1053 17.83 2.16996C17.8947 2.23463 17.931 2.32234 17.931 2.41379V4.51724C17.931 5.06953 18.3787 5.51724 18.931 5.51724H19C19.5523 5.51724 20 5.06953 20 4.51724V2.41379Z"
            fill={fill}
          />
          <path
            d="M20 17.5862V15.4828C20 14.9305 19.5523 14.4828 19 14.4828H18.931C18.3787 14.4828 17.931 14.9305 17.931 15.4828V17.5862C17.931 17.6777 17.8947 17.7654 17.83 17.83C17.7654 17.8947 17.6777 17.931 17.5862 17.931H15.4828C14.9305 17.931 14.4828 18.3787 14.4828 18.931V19C14.4828 19.5523 14.9305 20 15.4828 20H17.5862C18.2264 20 18.8403 19.7457 19.293 19.293C19.7457 18.8403 20 18.2264 20 17.5862Z"
            fill={fill}
          />
          <path
            d="M0 17.5862C0 18.2264 0.25431 18.8403 0.706984 19.293C1.15966 19.7457 1.77362 20 2.41379 20H4.51724C5.06953 20 5.51724 19.5523 5.51724 19V18.931C5.51724 18.3787 5.06953 17.931 4.51724 17.931H2.41379C2.32234 17.931 2.23463 17.8947 2.16996 17.83C2.1053 17.7654 2.06897 17.6777 2.06897 17.5862V15.4828C2.06897 14.9305 1.62125 14.4828 1.06897 14.4828H1C0.447715 14.4828 0 14.9305 0 15.4828V17.5862Z"
            fill={fill}
          />
          <path
            d="M0 4.51724C0 5.06953 0.447715 5.51724 1 5.51724H1.06897C1.62125 5.51724 2.06897 5.06953 2.06897 4.51724V2.41379C2.06897 2.32234 2.1053 2.23463 2.16996 2.16996C2.23463 2.1053 2.32234 2.06897 2.41379 2.06897H4.51724C5.06953 2.06897 5.51724 1.62125 5.51724 1.06897V1C5.51724 0.447715 5.06953 0 4.51724 0H2.41379C1.77362 0 1.15966 0.25431 0.706984 0.706984C0.25431 1.15966 0 1.77362 0 2.41379V4.51724Z"
            fill={fill}
          />
        </g>
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.03227 6.47243C6.32322 6.80494 6.28953 7.31035 5.95702 7.60129L4.1308 9.19923H15.8725L14.0463 7.60129C13.7138 7.31035 13.6801 6.80494 13.971 6.47243C14.262 6.13992 14.7674 6.10622 15.0999 6.39717L18.0124 8.94562C18.6498 9.5034 18.6499 10.4951 18.0124 11.0528L15.0999 13.6013C14.7674 13.8922 14.262 13.8585 13.971 13.526C13.6801 13.1935 13.7138 12.6881 14.0463 12.3972L15.8725 10.7992H4.1308L5.95702 12.3972C6.28953 12.6881 6.32322 13.1935 6.03227 13.526C5.74133 13.8585 5.23592 13.8922 4.90341 13.6013L1.90488 10.9776C1.31295 10.4596 1.31296 9.53881 1.90488 9.02088L4.90341 6.39717C5.23592 6.10622 5.74133 6.13992 6.03227 6.47243Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_22672_1586">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
