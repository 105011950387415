import { useState } from "react";

import { PrimaryButton } from "../utils/Button";
import ProductLimitModal from "./ProductLimitModal";

const stories = {
  name: "ProductLimitModal",
  render: () => <Story />,
};

function Story() {
  const [show, setShow] = useState(true);

  const handleContact = () => {
    alert("Contact");
  };

  return (
    <>
      <ProductLimitModal
        show={show}
        onClose={() => setShow(false)}
        onContact={() => handleContact()}
      />
      <PrimaryButton onClick={() => setShow(true)}>Show modal</PrimaryButton>
    </>
  );
}

export default stories;
