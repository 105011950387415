import * as graphql from "graphql";
import { useMemo } from "react";
import * as ReactQuery from "react-query";

import * as statuses from "../../util/statuses";
import { useGraphQL } from "./GraphQLProvider";

export default function useQuery<TQuery, TVariables extends {} | null = null>(
  query: graphql.DocumentNode,
  variables: TVariables,
  enabled: boolean = true
): { status: statuses.Status<TQuery>; refresh: () => Promise<void> } {
  const graphQL = useGraphQL();

  const { isLoading, isError, error, data, refetch } = ReactQuery.useQuery(
    [graphql.print(query), variables, graphQL.key],
    () => graphQL.fetch<TQuery, TVariables>({ query, variables }),
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const status = useMemo(() => {
    if (isLoading) {
      return statuses.loading;
    } else if (isError) {
      return statuses.error(error);
    } else if (data === undefined) {
      return statuses.error(new Error("query did not return any data"));
    } else {
      return statuses.success(data as TQuery);
    }
  }, [isLoading, isError, error, data]);

  return {
    status,
    refresh: async () => {
      await refetch();
    },
  };
}
