import classNames from "classnames";
import BootstrapSpinner from "react-bootstrap/Spinner";

interface SpinnerProps {
  className?: string;
  size?: "sm";
}

export default function Spinner(props: SpinnerProps) {
  const { className = "", size } = props;
  return (
    <BootstrapSpinner
      className={classNames("text-primary", className)}
      animation="border"
      size={size}
    />
  );
}
