import VectorProps from "./VectorProps";

export default function Forum(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Q85QW9IPew7lWUUb-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.55998 14.25C3.20998 14.25 2.10998 13.15 2.10998 11.8C2.10998 10.45 3.20998 9.34999 4.55998 9.34999C5.90998 9.34999 7.00998 10.45 7.00998 11.8C7.00998 13.15 5.90998 14.25 4.55998 14.25ZM4.55998 10.95C4.08998 10.95 3.70998 11.33 3.70998 11.8C3.70998 12.27 4.08998 12.65 4.55998 12.65C5.02998 12.65 5.40998 12.27 5.40998 11.8C5.40998 11.33 5.02998 10.95 4.55998 10.95Z" />
      <path d="M5.64998 18.37H1.02998C0.58998 18.37 0.22998 18.01 0.22998 17.57V16.82C0.22998 15.18 1.55998 13.85 3.19998 13.85H5.89998C6.82998 13.85 7.68998 14.27 8.24998 15.01L6.97998 15.99C6.71998 15.65 6.31998 15.46 5.88998 15.46H3.18998C2.44998 15.46 1.83998 16.05 1.81998 16.78H5.62998V18.38L5.64998 18.37Z" />
      <path d="M15.41 14.25C14.06 14.25 12.96 13.15 12.96 11.8C12.96 10.45 14.06 9.34999 15.41 9.34999C16.76 9.34999 17.86 10.45 17.86 11.8C17.86 13.15 16.76 14.25 15.41 14.25ZM15.41 10.95C14.94 10.95 14.56 11.33 14.56 11.8C14.56 12.27 14.94 12.65 15.41 12.65C15.88 12.65 16.26 12.27 16.26 11.8C16.26 11.33 15.88 10.95 15.41 10.95Z" />
      <path d="M18.94 18.37H14.28V16.77H18.14C18.11 16.04 17.51 15.45 16.77 15.45H14.07C13.64 15.45 13.25 15.64 12.98 15.98L11.72 15C12.29 14.27 13.15 13.85 14.07 13.85H16.77C18.41 13.85 19.74 15.18 19.74 16.82V17.57C19.74 18.01 19.38 18.37 18.94 18.37Z" />
      <path d="M9.98998 14.96C8.42998 14.96 7.15998 13.69 7.15998 12.13C7.15998 10.57 8.42998 9.29999 9.98998 9.29999C11.55 9.29999 12.82 10.57 12.82 12.13C12.82 13.69 11.55 14.96 9.98998 14.96ZM9.98998 10.9C9.30998 10.9 8.75998 11.45 8.75998 12.13C8.75998 12.81 9.30998 13.36 9.98998 13.36C10.67 13.36 11.22 12.81 11.22 12.13C11.22 11.45 10.67 10.9 9.98998 10.9Z" />
      <path d="M14.33 19.8H5.64998C5.20998 19.8 4.84998 19.44 4.84998 19V18.08C4.84998 16.16 6.40998 14.6 8.32998 14.6H11.65C13.57 14.6 15.13 16.16 15.13 18.08V19C15.13 19.44 14.77 19.8 14.33 19.8ZM6.44998 18.2H13.53V18.08C13.53 17.04 12.69 16.2 11.65 16.2H8.32998C7.29998 16.2 6.44998 17.04 6.44998 18.08V18.2Z" />
      <path d="M6.84998 9.56999C6.38998 9.56999 5.98998 9.31999 5.77998 8.90999L5.32998 7.99999H2.01998C1.03998 7.98999 0.23998 7.18999 0.23998 6.20999V2.07999C0.23998 1.09999 1.03998 0.299988 2.01998 0.299988H17.96C18.94 0.299988 19.74 1.09999 19.74 2.07999V6.20999C19.74 7.18999 18.94 7.98999 17.96 7.98999H14.67L14.2 8.90999C14 9.31999 13.59 9.56999 13.13 9.56999C12.67 9.56999 12.26 9.31999 12.06 8.90999L11.61 7.99999H8.37998L7.90998 8.90999C7.70998 9.31999 7.29998 9.56999 6.83998 9.56999H6.84998ZM2.01998 1.89999C1.91998 1.89999 1.83998 1.97999 1.83998 2.07999V6.20999C1.83998 6.30999 1.91998 6.38999 2.01998 6.38999H5.30998C5.92998 6.38999 6.47998 6.72999 6.75998 7.28999L6.84998 7.46999L6.93998 7.28999C7.20998 6.73999 7.76998 6.38999 8.38998 6.38999H11.6C12.22 6.38999 12.77 6.72999 13.05 7.28999L13.14 7.46999L13.23 7.28999C13.5 6.73999 14.06 6.38999 14.68 6.38999H17.97C18.07 6.38999 18.15 6.30999 18.15 6.20999V2.07999C18.15 1.97999 18.07 1.89999 17.97 1.89999H2.01998Z" />
    </svg>
  );
}
