import { TextField } from "./TextField";
import { TextInputType } from "./TextInput";
import useId from "./useId";

export interface Value {
  text: string;
  errorMessage?: string;
}

export function validValue(text: string) {
  return { text };
}

export function invalidValue(text: string, errorMessage: string) {
  return { text, errorMessage };
}

interface LabelledTextFieldProps {
  className?: string;
  label: React.ReactNode;
  labelClassName?: string;
  onBlur?: () => void;
  onChange: (value: Value) => void;
  onFocus?: () => void;
  type?: TextInputType;
  placeholder?: string;
  value: Value;
}

export function LabelledTextField(props: LabelledTextFieldProps) {
  const {
    className,
    label,
    labelClassName,
    onBlur,
    onChange,
    onFocus,
    type,
    value,
  } = props;
  const id = useId();

  return (
    <div className="form-group">
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <TextField
        className={className}
        id={id}
        onBlur={onBlur}
        onChange={(newValue) =>
          onChange({ text: newValue.text, errorMessage: value.errorMessage })
        }
        onFocus={onFocus}
        type={type}
        value={{ text: value.text, hasError: value.errorMessage !== undefined }}
      />
      <small className="invalid-feedback">{value.errorMessage}</small>
    </div>
  );
}
