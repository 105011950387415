import VectorProps from "./VectorProps";

export default function TagManagement(props: VectorProps) {
  const { fill = "currentColor", width } = props;

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23103_3250)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.23314 0.843305C8.76104 0.294284 9.48982 -0.0159912 10.2515 -0.0159912H15.7C17.2464 -0.0159912 18.5 1.23761 18.5 2.78401V4.00001C18.5 4.44184 18.1418 4.80001 17.7 4.80001C17.2582 4.80001 16.9 4.44184 16.9 4.00001V2.78401C16.9 2.12127 16.3627 1.58401 15.7 1.58401H10.2515C9.92505 1.58401 9.61271 1.71698 9.38647 1.95228L1.88788 9.75081C1.44648 10.2099 1.44056 10.9338 1.87438 11.4L6.58564 16.463C6.88662 16.7865 6.86841 17.2927 6.54495 17.5937C6.2215 17.8947 5.71529 17.8764 5.41431 17.553L0.703053 12.4899C-0.309214 11.4021 -0.295387 9.71297 0.734549 8.64184L8.23314 0.843305Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 7.30001C14.3372 7.30001 13.8 7.83727 13.8 8.50001C13.8 9.16275 14.3372 9.70001 15 9.70001C15.6627 9.70001 16.2 9.16275 16.2 8.50001C16.2 7.83727 15.6627 7.30001 15 7.30001ZM12.2 8.50001C12.2 6.95361 13.4536 5.70001 15 5.70001C16.5464 5.70001 17.8 6.95361 17.8 8.50001C17.8 10.0464 16.5464 11.3 15 11.3C13.4536 11.3 12.2 10.0464 12.2 8.50001Z"
          fill={fill}
        />
        <path
          d="M17 8.80001C17 8.35818 17.3582 8.00001 17.8 8.00001H19.2C19.6418 8.00001 20 8.35818 20 8.80001C20 9.24184 19.6418 9.60001 19.2 9.60001H17.8C17.3582 9.60001 17 9.24184 17 8.80001Z"
          fill={fill}
        />
        <path
          d="M5.99998 8.80001C5.99998 8.35818 6.35815 8.00001 6.79998 8.00001H10.7C11.1418 8.00001 11.5 8.35818 11.5 8.80001C11.5 9.24184 11.1418 9.60001 10.7 9.60001H6.79998C6.35815 9.60001 5.99998 9.24184 5.99998 8.80001Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9929 15.6881C11.6556 15.6921 12.1961 15.158 12.2 14.4953C12.2039 13.8325 11.6699 13.2921 11.0071 13.2882C10.3444 13.2843 9.80396 13.8183 9.80003 14.4811C9.79611 15.1438 10.3302 15.6842 10.9929 15.6881ZM13.8 14.5047C13.7908 16.0511 12.5298 17.2973 10.9834 17.2881C9.43706 17.279 8.1909 16.0179 8.20006 14.4716C8.20922 12.9252 9.47023 11.679 11.0166 11.6882C12.563 11.6974 13.8091 12.9584 13.8 14.5047Z"
          fill={fill}
        />
        <path
          d="M9.00183 14.1763C8.99921 14.6181 8.63892 14.9742 8.1971 14.9716L6.79712 14.9633C6.3553 14.9607 5.99926 14.6004 6.00188 14.1585C6.0045 13.7167 6.36478 13.3607 6.8066 13.3633L8.20658 13.3716C8.6484 13.3742 9.00444 13.7345 9.00183 14.1763Z"
          fill={fill}
        />
        <path
          d="M20.0016 14.2415C19.999 14.6833 19.6387 15.0393 19.1969 15.0367L15.297 15.0136C14.8552 15.011 14.4991 14.6507 14.5017 14.2089C14.5043 13.7671 14.8646 13.411 15.3065 13.4137L19.2064 13.4368C19.6482 13.4394 20.0042 13.7997 20.0016 14.2415Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_23103_3250">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
