import { ImpactRating } from "../../../../__generated__/globalTypes";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { letterColor } from "./helperFunctions";
import { impactRatingToLetter } from "./translations";

interface TitledRatingScaleProps {
  greyscale?: boolean;
  impactRating: ImpactRating;
  width: number;
  locale: string | null;
}

export default function TitledRatingScale(props: TitledRatingScaleProps) {
  const { greyscale = false, impactRating, width, locale } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 135 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <g clipPath="url(#clip0_148_2255)">
        <path
          d="M36.1401 60.79C52.5927 60.79 65.9301 47.4525 65.9301 31C65.9301 14.5474 52.5927 1.20996 36.1401 1.20996C19.6875 1.20996 6.3501 14.5474 6.3501 31C6.3501 47.4525 19.6875 60.79 36.1401 60.79Z"
          fill={darkTurquoise(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.6601 1.72998C16.8301 4.29998 6.3501 16.42 6.3501 31C6.3501 47.45 19.6901 60.79 36.1401 60.79C40.4901 60.79 44.6301 59.85 48.3601 58.17L30.6601 1.72998Z"
          fill={foodstepsTurquoise(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M11.7199 48.0501H60.5599C63.6799 43.5901 65.6199 38.2301 65.8899 32.4401C64.4999 32.6501 63.2099 33.0901 62.0499 33.7301C61.5799 33.9901 60.9899 33.9901 60.5199 33.7301C58.8999 32.8301 56.9999 32.3101 54.9699 32.3101C52.9399 32.3101 51.0399 32.8301 49.4199 33.7301C48.9499 33.9901 48.3599 33.9901 47.8899 33.7301C46.2699 32.8301 44.3699 32.3101 42.3399 32.3101C40.3099 32.3101 38.4099 32.8301 36.7899 33.7301C36.3199 33.9901 35.7299 33.9901 35.2599 33.7301C33.6399 32.8301 31.7399 32.3101 29.7099 32.3101C27.6799 32.3101 25.7799 32.8301 24.1599 33.7301C23.6899 33.9901 23.0999 33.9901 22.6299 33.7301C21.0099 32.8301 19.1099 32.3101 17.0799 32.3101C15.0499 32.3101 13.1499 32.8301 11.5299 33.7301C11.0599 33.9901 10.4699 33.9901 9.9999 33.7301C8.9099 33.1301 7.6999 32.7001 6.3999 32.4801C6.6799 38.2601 8.6099 43.6001 11.7299 48.0601L11.7199 48.0501Z"
          fill={mint(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M13.2203 47.9701C12.3103 47.9701 11.5703 47.2301 11.5703 46.3201V38.2501C11.5703 37.8501 11.2503 37.5301 10.8503 37.5301H3.01029C2.17029 37.5301 1.43029 37.0301 1.13029 36.2501C0.820286 35.4701 1.02029 34.6001 1.64029 34.0301L7.71029 28.4201L5.33029 28.3901C4.49029 28.3901 3.75029 27.8901 3.45029 27.1101C3.14029 26.3301 3.34029 25.4601 3.96029 24.8901L9.40029 19.8601L7.86029 19.8301C7.02029 19.8301 6.28029 19.3301 5.98029 18.5501C5.68029 17.7701 5.87029 16.9001 6.49029 16.3301L13.9903 9.40011C14.3603 9.05011 14.8503 8.86011 15.3603 8.86011C15.8703 8.86011 16.3603 9.05011 16.7303 9.40011L24.2303 16.3301C24.8403 16.9001 25.0403 17.7701 24.7403 18.5501C24.4403 19.3301 23.7003 19.8301 22.8603 19.8301H21.3403L26.7603 24.8901C27.3703 25.4601 27.5803 26.3301 27.2703 27.1101C26.9603 27.8901 26.2303 28.3901 25.3903 28.3901H23.0203L29.0703 34.0401C29.6803 34.6101 29.8803 35.4801 29.5803 36.2601C29.2703 37.0401 28.5403 37.5401 27.7003 37.5401H19.8503C19.4503 37.5401 19.1303 37.8601 19.1303 38.2601V46.3301C19.1303 47.2401 18.3903 47.9801 17.4803 47.9801H13.2303L13.2203 47.9701Z"
          fill={mint(greyscale)}
        />
        <path
          d="M15.3502 9.8501C15.6002 9.8501 15.8502 9.9401 16.0402 10.1201L23.5402 17.0501C24.2202 17.6801 23.7802 18.8201 22.8502 18.8201H21.3302C20.4002 18.8201 19.9602 19.9601 20.6402 20.5901L26.0802 25.6201C26.7602 26.2501 26.3202 27.3901 25.3902 27.3901H23.0202C22.0902 27.3901 21.6502 28.5301 22.3302 29.1601L28.4002 34.7701C29.0802 35.4001 28.6402 36.5401 27.7102 36.5401H19.8602C18.9102 36.5401 18.1402 37.3101 18.1402 38.2601V46.3301C18.1402 46.6901 17.8502 46.9801 17.4902 46.9801H13.2402C12.8802 46.9801 12.5902 46.6901 12.5902 46.3301V38.2601C12.5902 37.3101 11.8202 36.5401 10.8702 36.5401H3.01017C2.08017 36.5401 1.64017 35.4001 2.32017 34.7701L8.39017 29.1601C9.07017 28.5301 8.63017 27.3901 7.70017 27.3901H5.33017C4.40017 27.3901 3.96017 26.2501 4.64017 25.6201L10.0802 20.5901C10.7602 19.9601 10.3202 18.8201 9.39017 18.8201H7.87017C6.94017 18.8201 6.50017 17.6801 7.18017 17.0501L14.6802 10.1201C14.8802 9.9401 15.1202 9.8501 15.3702 9.8501M15.3502 7.8501C14.5902 7.8501 13.8602 8.1301 13.3002 8.6501L5.80017 15.5901C4.88017 16.4401 4.58017 17.7401 5.04017 18.9101C5.39017 19.8001 6.12017 20.4501 7.00017 20.7101L3.27017 24.1601C2.35017 25.0101 2.05017 26.3101 2.51017 27.4801C2.95017 28.6001 3.99017 29.3401 5.19017 29.4001L0.960173 33.3101C0.0401727 34.1601 -0.259827 35.4601 0.200173 36.6301C0.660173 37.8001 1.76017 38.5501 3.01017 38.5501H10.5802V46.3401C10.5802 47.8001 11.7702 48.9901 13.2302 48.9901H17.4802C18.9402 48.9901 20.1302 47.8001 20.1302 46.3401V38.5501H27.7002C28.9502 38.5501 30.0602 37.8001 30.5102 36.6301C30.9702 35.4601 30.6702 34.1601 29.7502 33.3101L25.5202 29.4001C26.7202 29.3501 27.7602 28.6101 28.2002 27.4801C28.6602 26.3101 28.3602 25.0101 27.4402 24.1601L23.7102 20.7101C24.5902 20.4501 25.3202 19.8001 25.6702 18.9101C26.1302 17.7401 25.8302 16.4401 24.9102 15.5901L17.4102 8.6601C16.8502 8.1401 16.1202 7.8601 15.3602 7.8601L15.3502 7.8501Z"
          fill="black"
        />
      </g>
      <rect x="46" y="1" width="88" height="60" rx="7" fill="white" />
      <rect
        x="46"
        y="1"
        width="88"
        height="60"
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <text
        x={90}
        y={17}
        textAnchor="middle"
        color="black"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={locale === "es-419" ? 11 : 10}
        fill="black"
      >
        {locale === "es-419" ? "Uso de tierra" : "Land use rating"}
      </text>
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <defs>
        <clipPath id="clip0_148_2255">
          <rect width="67" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = letterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 45.5;
  const ratingLetterXA = 66;
  const ratingLetterFontSize = 18;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case ImpactRating.VLOW:
      return (
        <>
          <rect
            x="54"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M82 30H91V48H82V30Z"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.LOW:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="66"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.LOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.MEDIUM:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="78"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.HIGH:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="90"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.VHIGH:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <path
            d="M89 30H98V48H89V30Z"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <rect
            x="102"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}
