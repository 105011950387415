import VectorProps from "./VectorProps";

export default function Scope3Report(props: VectorProps) {
  const { className = "", fill = "currentColor", width } = props;

  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / Scope 3 report">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.7 10C3.7 9.55817 4.05817 9.2 4.5 9.2H7.5C7.94183 9.2 8.3 9.55817 8.3 10C8.3 10.4418 7.94183 10.8 7.5 10.8H4.5C4.05817 10.8 3.7 10.4418 3.7 10Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.7 13C3.7 12.5582 4.05817 12.2 4.5 12.2H8.5C8.94183 12.2 9.3 12.5582 9.3 13C9.3 13.4418 8.94183 13.8 8.5 13.8H4.5C4.05817 13.8 3.7 13.4418 3.7 13Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.7 16C3.7 15.5582 4.05817 15.2 4.5 15.2H10.5C10.9418 15.2 11.3 15.5582 11.3 16C11.3 16.4418 10.9418 16.8 10.5 16.8H4.5C4.05817 16.8 3.7 16.4418 3.7 16Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9822 2.2C12.834 0.960732 11.7792 0 10.5 0H7.5C6.22082 0 5.16602 0.960732 5.01781 2.2H3.59995C2.27447 2.2 1.19995 3.27452 1.19995 4.6V17.4C1.19995 18.7255 2.27447 19.8 3.59995 19.8H14.4C15.7254 19.8 16.8 18.7255 16.8 17.4V14.9975C18.6889 14.1267 20 12.2165 20 10C20 7.78354 18.6889 5.87329 16.8 5.00252V4.6C16.8 3.27452 15.7254 2.2 14.4 2.2H12.9822ZM10.5 1.6H7.5C7.00294 1.6 6.6 2.00294 6.6 2.5C6.6 2.99706 7.00294 3.4 7.5 3.4H10.5C10.9971 3.4 11.4 2.99706 11.4 2.5C11.4 2.00294 10.9971 1.6 10.5 1.6ZM5.36416 3.8H3.59995C3.15812 3.8 2.79995 4.15817 2.79995 4.6V17.4C2.79995 17.8418 3.15812 18.2 3.59995 18.2H14.4C14.8418 18.2 15.2 17.8418 15.2 17.4V15.4559C14.9707 15.485 14.7371 15.5 14.5 15.5C11.4624 15.5 9 13.0376 9 10C9 6.96243 11.4624 4.5 14.5 4.5C14.7365 4.5 14.9694 4.51492 15.198 4.54387C15.1692 4.12823 14.8229 3.8 14.4 3.8H12.6358C12.1969 4.51959 11.4046 5 10.5 5H7.5C6.59544 5 5.80309 4.51959 5.36416 3.8ZM14.5 13.9C16.6539 13.9 18.4 12.1539 18.4 10C18.4 7.84609 16.6539 6.1 14.5 6.1C12.3461 6.1 10.6 7.84609 10.6 10C10.6 12.1539 12.3461 13.9 14.5 13.9Z"
            fill={fill}
          />
          <path
            d="M14.4806 12.6403C12.9576 12.6403 12.2459 11.9362 12.2 10.75H13.5928C13.6387 11.2015 13.853 11.5077 14.3964 11.5077C14.8785 11.5077 15.1158 11.2781 15.1158 10.8878C15.1158 10.4592 14.8862 10.2449 14.3964 10.2449H13.9602V9.27296H14.3658C14.8556 9.27296 15.0928 9.05102 15.0928 8.66837C15.0928 8.33929 14.8785 8.125 14.4653 8.125C14.0443 8.125 13.7994 8.37755 13.7382 8.82143H12.353C12.4219 7.74235 13.1413 7 14.5035 7C15.7892 7 16.5622 7.58929 16.5622 8.5C16.5622 9.11224 16.2102 9.5102 15.6362 9.67857C16.2637 9.80867 16.7 10.2526 16.7 10.9184C16.7 11.9592 15.927 12.6403 14.4806 12.6403Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
