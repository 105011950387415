import { useState } from "react";

import { ComparisonRecipeSelect } from "./ComparisonRecipeSelect";
import { ComparisonImpactValue } from "./ImpactComparison";

const stories = {
  name: "ComparisonRecipeSelect",
  render: () => <Story />,
};

function Story() {
  const [, setValue] = useState<ComparisonImpactValue | null>({ type: "none" });

  return (
    <ComparisonRecipeSelect
      onChange={setValue}
      includePackaging={true}
      selectedRecipe={{ id: 1, name: "Like Nuggets" }}
    />
  );
}

export default stories;
