import Checkbox from "./Checkbox";

const stories = {
  name: "Checkbox",
  children: [
    {
      name: "unchecked",
      render: () => <Checkbox checked={false} />,
    },
    {
      name: "checked",
      render: () => <Checkbox checked={true} />,
    },
    {
      name: "defaultChecked",
      render: () => <Checkbox defaultChecked={true} />,
    },
    {
      name: "label",
      render: () => <Checkbox label={"Tick me"} />,
    },
    {
      name: "disabled",
      render: () => <Checkbox disabled={true} />,
    },
    {
      name: "onChange",
      render: () => (
        <Checkbox
          onChange={(checked) =>
            alert(`Checkbox will be ${checked ? "checked" : "unchecked"}`)
          }
        />
      ),
    },
  ],
};

export default stories;
