import { ImpactRating } from "../../../../__generated__/globalTypes";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  brightTurquoise,
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { letterColor } from "./helperFunctions";
import {
  impactRatingToLetter,
  impactRatingToName,
  landUseString,
} from "./translations";

interface DescriptiveRatingScaleProps {
  greyscale?: boolean;
  impactRating: ImpactRating;
  locale: string | null;
  width: number;
}

export default function DescriptiveRatingScale(
  props: DescriptiveRatingScaleProps
) {
  const { greyscale = false, impactRating, locale, width } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 98 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <rect
        x="1"
        y="27"
        width="96"
        height="130"
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M2 36C2 31.5817 5.58172 28 10 28H88C92.4183 28 96 31.5817 96 36V92H2V36Z"
        fill="white"
      />
      <text
        x={49}
        y={64}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={16}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <text
        x={49}
        y={82}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={13}
        fill="black"
      >
        {landUseString(locale)}
      </text>
      <rect width="94" height="40" transform="translate(2 92)" fill="white" />
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <path
        d="M2 132H96V148C96 152.418 92.4183 156 88 156H10C5.58172 156 2 152.418 2 148V132Z"
        fill={foodstepsTurquoise(greyscale)}
      />
      <path
        d="M12.9453 147.672H15.0639V144.05H18.1372V142.454H15.0639V139.983H18.948V138.322H12.9453V147.672Z"
        fill="white"
      />
      <path
        d="M23.0543 146.378C21.9819 146.378 21.3803 145.593 21.3803 144.285V144.18C21.3803 142.873 22.0081 142.114 23.0543 142.114C24.1136 142.114 24.7282 142.899 24.7282 144.207V144.298C24.7282 145.593 24.1136 146.378 23.0543 146.378ZM23.0412 147.816C25.0944 147.816 26.6506 146.443 26.6506 144.285V144.18C26.6506 142.049 25.1075 140.676 23.0543 140.676C21.0011 140.676 19.4448 142.075 19.4448 144.22V144.324C19.4448 146.456 21.0011 147.816 23.0412 147.816Z"
        fill="white"
      />
      <path
        d="M31.1129 146.378C30.0405 146.378 29.4389 145.593 29.4389 144.285V144.18C29.4389 142.873 30.0667 142.114 31.1129 142.114C32.1722 142.114 32.7868 142.899 32.7868 144.207V144.298C32.7868 145.593 32.1722 146.378 31.1129 146.378ZM31.0998 147.816C33.153 147.816 34.7092 146.443 34.7092 144.285V144.18C34.7092 142.049 33.1661 140.676 31.1129 140.676C29.0597 140.676 27.5034 142.075 27.5034 144.22V144.324C27.5034 146.456 29.0597 147.816 31.0998 147.816Z"
        fill="white"
      />
      <path
        d="M38.5438 147.816C39.5508 147.816 40.3877 147.228 40.7408 146.534V147.672H42.624V137.733H40.7408V141.853C40.3616 141.16 39.6815 140.676 38.583 140.676C36.8698 140.676 35.5751 141.996 35.5751 144.246V144.35C35.5751 146.626 36.8829 147.816 38.5438 147.816ZM39.1061 146.338C38.1645 146.338 37.5106 145.671 37.5106 144.311V144.207C37.5106 142.847 38.0991 142.127 39.1584 142.127C40.1916 142.127 40.7931 142.82 40.7931 144.18V144.285C40.7931 145.671 40.1 146.338 39.1061 146.338Z"
        fill="white"
      />
      <path
        d="M46.7921 147.816C48.5968 147.816 49.6692 147.044 49.6692 145.567C49.6692 144.102 48.7276 143.67 47.0144 143.422C46.0205 143.278 45.6936 143.108 45.6936 142.677C45.6936 142.258 46.0467 141.97 46.6613 141.97C47.3152 141.97 47.6291 142.232 47.7337 142.794H49.473C49.303 141.225 48.1653 140.676 46.6483 140.676C45.2489 140.676 43.9412 141.382 43.9412 142.807C43.9412 144.141 44.6474 144.664 46.4652 144.939C47.446 145.083 47.8514 145.279 47.8514 145.75C47.8514 146.221 47.5114 146.495 46.779 146.495C45.9551 146.495 45.6544 146.142 45.5759 145.527H43.8104C43.8758 147.005 44.9482 147.816 46.7921 147.816Z"
        fill="white"
      />
      <path
        d="M53.3696 147.803C53.8796 147.803 54.2589 147.711 54.5204 147.62V146.155C54.2981 146.247 54.0889 146.286 53.8011 146.286C53.3173 146.286 53.0426 146.024 53.0426 145.475V142.18H54.4812V140.833H53.0426V139.355H51.1595V140.833H50.2832V142.18H51.1595V145.645C51.1595 147.071 51.931 147.803 53.3696 147.803Z"
        fill="white"
      />
      <path
        d="M58.9531 147.816C60.8232 147.816 62.0002 146.992 62.2226 145.514H60.4309C60.3132 146.09 59.8817 146.469 59.0055 146.469C57.9723 146.469 57.3577 145.815 57.3054 144.677H62.2356V144.154C62.2356 141.709 60.6663 140.676 58.9008 140.676C56.913 140.676 55.3829 142.075 55.3829 144.233V144.337C55.3829 146.521 56.8869 147.816 58.9531 147.816ZM57.3315 143.513C57.4754 142.546 58.0508 141.983 58.9008 141.983C59.8032 141.983 60.3263 142.48 60.3917 143.513H57.3315Z"
        fill="white"
      />
      <path
        d="M63.4769 150H65.3732V146.626C65.7263 147.293 66.4978 147.816 67.5571 147.816C69.2572 147.816 70.5389 146.548 70.5389 144.285V144.18C70.5389 141.918 69.2311 140.676 67.5571 140.676C66.5502 140.676 65.7524 141.251 65.3732 141.905V140.833H63.4769V150ZM66.9948 146.338C65.9617 146.338 65.3078 145.671 65.3078 144.298V144.194C65.3078 142.82 65.9878 142.127 66.9817 142.127C67.9364 142.127 68.6164 142.807 68.6164 144.194V144.298C68.6164 145.632 68.041 146.338 66.9948 146.338Z"
        fill="white"
      />
      <path
        d="M74.2885 147.816C76.0932 147.816 77.1656 147.044 77.1656 145.567C77.1656 144.102 76.224 143.67 74.5108 143.422C73.5169 143.278 73.1899 143.108 73.1899 142.677C73.1899 142.258 73.543 141.97 74.1577 141.97C74.8116 141.97 75.1254 142.232 75.2301 142.794H76.9694C76.7994 141.225 75.6616 140.676 74.1446 140.676C72.7453 140.676 71.4375 141.382 71.4375 142.807C71.4375 144.141 72.1437 144.664 73.9615 144.939C74.9424 145.083 75.3478 145.279 75.3478 145.75C75.3478 146.221 75.0077 146.495 74.2754 146.495C73.4515 146.495 73.1507 146.142 73.0722 145.527H71.3068C71.3721 147.005 72.4445 147.816 74.2885 147.816Z"
        fill="white"
      />
      <path
        d="M81.6594 140.848L81.6591 140.611C81.6592 140.475 81.7241 140.381 81.8534 140.33C82.3715 140.125 82.6021 139.528 82.3508 139.044C82.3036 138.953 82.2427 138.871 82.1695 138.799C81.9584 138.59 81.7455 138.383 81.5343 138.174C81.5114 138.152 81.4974 138.149 81.4726 138.172C81.3439 138.291 81.2137 138.408 81.0837 138.526C80.9907 138.61 80.8958 138.692 80.804 138.777C80.6159 138.952 80.5118 139.165 80.5002 139.419C80.4876 139.697 80.5844 139.936 80.7845 140.133C80.8726 140.22 80.9768 140.285 81.0939 140.33C81.2219 140.379 81.2922 140.48 81.2923 140.613C81.2924 140.778 81.2925 140.943 81.2922 141.108C81.2922 141.13 81.2975 141.156 81.2679 141.165C81.2386 141.174 81.2279 141.15 81.2139 141.132C81.0416 140.915 80.8181 140.789 80.5353 140.767C80.4535 140.76 80.4537 140.762 80.4615 140.84L80.4618 140.843C80.4793 141.018 80.5388 141.177 80.6458 141.319C80.7651 141.476 80.9221 141.584 81.1118 141.646C81.2294 141.685 81.2919 141.769 81.2923 141.891C81.2926 141.979 81.2923 142.068 81.2923 142.157C81.2923 142.415 81.1188 142.571 80.8599 142.518C80.9766 142.625 81.061 142.693 81.1715 142.72C81.4565 142.79 81.6728 142.879 82.0895 142.523C81.874 142.564 81.6815 142.371 81.6803 142.157C81.6799 142.054 81.6803 141.985 81.6803 141.882C81.6803 141.767 81.7423 141.683 81.8578 141.646C82.0279 141.591 82.1505 141.498 82.2657 141.365C82.3966 141.213 82.472 141.038 82.4862 140.839C82.4918 140.762 82.4915 140.761 82.4129 140.767C82.2011 140.782 82.0174 140.861 81.8597 140.999C81.8102 141.043 81.767 141.092 81.7272 141.143C81.715 141.159 81.7025 141.171 81.6803 141.164C81.6593 141.157 81.6593 141.14 81.6594 141.123V140.848Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4875 142.58C83.221 142.58 84.6262 141.201 84.6262 139.5C84.6262 137.799 83.221 136.42 81.4875 136.42C79.7541 136.42 78.3489 137.799 78.3489 139.5C78.3489 141.201 79.7541 142.58 81.4875 142.58ZM81.4875 143C83.4573 143 85.0542 141.433 85.0542 139.5C85.0542 137.567 83.4573 136 81.4875 136C79.5177 136 77.9209 137.567 77.9209 139.5C77.9209 141.433 79.5177 143 81.4875 143Z"
        fill="white"
      />
      <g clipPath="url(#clip0_148_945)">
        <path
          d="M41.75 31.5C50.4485 31.5 57.5 24.4485 57.5 15.75C57.5 7.05152 50.4485 0 41.75 0C33.0515 0 26 7.05152 26 15.75C26 24.4485 33.0515 31.5 41.75 31.5Z"
          fill={brightTurquoise(greyscale)}
        />
        <path
          d="M51.1202 44.96C62.0057 44.96 70.8302 36.1356 70.8302 25.25C70.8302 14.3645 62.0057 5.54004 51.1202 5.54004C40.2346 5.54004 31.4102 14.3645 31.4102 25.25C31.4102 36.1356 40.2346 44.96 51.1202 44.96Z"
          fill={darkTurquoise(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.5002 5.87988C38.3402 7.57988 31.4102 15.5999 31.4102 25.2499C31.4102 36.1399 40.2402 44.9599 51.1202 44.9599C54.0002 44.9599 56.7402 44.3399 59.2002 43.2199L47.5002 5.87988Z"
          fill={foodstepsTurquoise(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M34.9599 36.5301H67.2799C69.3499 33.5801 70.6299 30.0301 70.8099 26.2001C69.8899 26.3401 69.0299 26.6301 68.2699 27.0501C67.9599 27.2201 67.5699 27.2201 67.2599 27.0501C66.1899 26.4501 64.9299 26.1101 63.5899 26.1101C62.2499 26.1101 60.9899 26.4601 59.9199 27.0501C59.6099 27.2201 59.2199 27.2201 58.8999 27.0501C57.8299 26.4601 56.5699 26.1101 55.2299 26.1101C53.8899 26.1101 52.6299 26.4601 51.5599 27.0501C51.2499 27.2201 50.8599 27.2201 50.5399 27.0501C49.4699 26.4501 48.2099 26.1101 46.8699 26.1101C45.5299 26.1101 44.2699 26.4601 43.1999 27.0501C42.8899 27.2201 42.4999 27.2201 42.1799 27.0501C41.1099 26.4601 39.8499 26.1101 38.5099 26.1101C37.1699 26.1101 35.9099 26.4601 34.8399 27.0501C34.5299 27.2201 34.1399 27.2201 33.8199 27.0501C33.0999 26.6501 32.2999 26.3701 31.4399 26.2201C31.6299 30.0501 32.8999 33.5801 34.9699 36.5301H34.9599Z"
          fill={mint(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M35.5103 36.3C34.7203 36.3 34.0803 35.66 34.0803 34.87V29.53C34.0803 29.45 34.0203 29.39 33.9403 29.39H28.7503C28.0603 29.39 27.4403 28.97 27.1903 28.33C26.9403 27.69 27.1003 26.96 27.6103 26.49L31.0203 23.34H30.2803C29.5903 23.34 28.9703 22.92 28.7203 22.28C28.4703 21.64 28.6303 20.91 29.1403 20.44L32.1303 17.68H31.9503C31.2603 17.68 30.6403 17.26 30.3903 16.62C30.1403 15.97 30.3003 15.25 30.8103 14.78L35.7703 10.19C36.0803 9.89999 36.4803 9.73999 36.9103 9.73999C37.3403 9.73999 37.7403 9.89999 38.0503 10.19L43.0103 14.78C43.5203 15.25 43.6903 15.97 43.4303 16.62C43.1703 17.27 42.5603 17.68 41.8703 17.68H41.6903L44.6803 20.44C45.1903 20.91 45.3603 21.63 45.1003 22.28C44.8503 22.93 44.2303 23.34 43.5403 23.34H42.8003L46.2103 26.49C46.7203 26.96 46.8903 27.68 46.6303 28.33C46.3703 28.98 45.7603 29.39 45.0703 29.39H39.8803C39.8003 29.39 39.7403 29.45 39.7403 29.53V34.87C39.7403 35.66 39.1003 36.3 38.3103 36.3H35.5003H35.5103Z"
          fill={mint(greyscale)}
        />
        <path
          d="M36.9102 10.73C37.0702 10.73 37.2402 10.79 37.3702 10.91L42.3302 15.5C42.7802 15.92 42.4902 16.67 41.8702 16.67H40.8602C40.2502 16.67 39.9502 17.42 40.4002 17.84L44.0002 21.17C44.4502 21.59 44.1602 22.34 43.5402 22.34H41.9702C41.3602 22.34 41.0602 23.09 41.5102 23.51L45.5302 27.22C45.9802 27.64 45.6902 28.39 45.0702 28.39H39.8802C39.2502 28.39 38.7402 28.9 38.7402 29.53V34.87C38.7402 35.11 38.5502 35.3 38.3102 35.3H35.5002C35.2602 35.3 35.0702 35.11 35.0702 34.87V29.53C35.0702 28.9 34.5602 28.39 33.9302 28.39H28.7502C28.1402 28.39 27.8402 27.64 28.2902 27.22L32.3102 23.51C32.7602 23.09 32.4702 22.34 31.8502 22.34H30.2802C29.6702 22.34 29.3702 21.59 29.8202 21.17L33.4202 17.84C33.8702 17.42 33.5802 16.67 32.9602 16.67H31.9502C31.3402 16.67 31.0402 15.92 31.4902 15.5L36.4502 10.91C36.5802 10.79 36.7402 10.73 36.9102 10.73ZM36.9102 8.72998C36.2302 8.72998 35.5902 8.97998 35.0902 9.43998L30.1302 14.03C29.3202 14.78 29.0502 15.94 29.4602 16.97C29.6302 17.4 29.9002 17.77 30.2402 18.06L28.4602 19.7C27.6502 20.45 27.3802 21.61 27.7902 22.64C28.0102 23.2 28.4002 23.65 28.8802 23.95L26.9302 25.76C26.1202 26.51 25.8502 27.67 26.2602 28.7C26.6602 29.73 27.6402 30.4 28.7502 30.4H33.0802V34.88C33.0802 36.22 34.1702 37.31 35.5102 37.31H38.3202C39.6602 37.31 40.7502 36.22 40.7502 34.88V30.4H45.0802C46.1902 30.4 47.1702 29.73 47.5702 28.7C47.9702 27.67 47.7102 26.51 46.8902 25.76L44.9402 23.95C45.4302 23.65 45.8202 23.2 46.0302 22.64C46.4302 21.61 46.1702 20.45 45.3502 19.7L43.5702 18.06C43.9102 17.78 44.1802 17.41 44.3502 16.97C44.7502 15.94 44.4902 14.78 43.6802 14.03L38.7202 9.43998C38.2202 8.97998 37.5802 8.72998 36.9002 8.72998H36.9102Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_148_945">
          <rect width="46" height="46" fill="white" transform="translate(26)" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);
  const ratingLetterColor = letterColor(impactRating, greyscale);

  const ratingLetterY = 115;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case ImpactRating.VLOW:
      return (
        <>
          <rect
            x="12"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M42 98H51V118H42V98Z"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="54"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="66"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.LOW:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="24"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.LOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="66"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.MEDIUM:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="23"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="36"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.HIGH:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="23"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="35"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="48"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.VHIGH:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="23"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="35"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <path
            d="M47 98H56V118H47V98Z"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <rect
            x="60"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}
