import { TransportStorageMethod } from "../__generated__/globalTypes";
import assertNever from "../util/assertNever";

export const allTransportStorageMethods: Array<TransportStorageMethod> =
  Object.values(TransportStorageMethod);

export function formatTransportStorageMethod(
  transportStorageMethod: TransportStorageMethod
): string {
  switch (transportStorageMethod) {
    case TransportStorageMethod.AMBIENT:
      return "Ambient";
    case TransportStorageMethod.REFRIGERATED:
      return "Refrigerated";
    case TransportStorageMethod.FROZEN:
      return "Frozen";
    default:
      return assertNever(
        transportStorageMethod,
        "unexpected transport storage method: " + transportStorageMethod
      );
  }
}
