import React from "react";
import { FormattedMessage } from "react-intl";

import ActionModal from "../utils/ActionModal";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import { AttentionTriangle } from "../utils/Vectors";

interface ProductLimitModalProps {
  onContact: () => void;
  onClose: () => void;
  show: boolean;
}

export default function ProductLimitModal(props: ProductLimitModalProps) {
  const { onContact, onClose, show } = props;

  return (
    <ActionModal
      headerClassName="pb-1"
      show={show}
      title={
        <div className="d-flex flex-column">
          <AttentionTriangle width={32} className="mb-2" />
          <FormattedMessage
            defaultMessage="You have reached your product limit."
            id="components/sharing/ProductLimitModal:title"
          />
        </div>
      }
    >
      <ActionModal.Body>
        <div className="text-muted">
          <FormattedMessage
            defaultMessage="Contact Foodsteps if you would like to make changes to your plan."
            id="components/sharing/ProductLimitModal:body"
          />
        </div>
      </ActionModal.Body>
      <ActionModal.Footer
        className="d-flex flew-row"
        style={{ justifyContent: "flex-end" }}
      >
        <SecondaryButton className="mr-3" onClick={onClose}>
          <FormattedMessage
            id="components/sharing/ProductLimitModal:close"
            defaultMessage="Close"
          />
        </SecondaryButton>
        <PrimaryButton onClick={onContact}>
          <FormattedMessage
            id="components/sharing/ProductLimitModal:contact"
            defaultMessage="Contact"
          />
        </PrimaryButton>
      </ActionModal.Footer>
    </ActionModal>
  );
}
