import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import assertNever from "../../../util/assertNever";
import useId from "../useId";
import "./SelectCheckbox.css";

export enum SelectAllCheckboxState {
  CHECKED = "CHECKED",
  DISABLED = "DISABLED",
  EMPTY = "EMPTY",
  INDETERMINATE = "INDETERMINATE",
}

interface SelectAllCheckboxProps {
  value: SelectAllCheckboxState;
  onChange: (checked: boolean) => void;
}

export default function SelectAllCheckbox(props: SelectAllCheckboxProps) {
  const { value, onChange } = props;

  const id = useId();
  const cRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (cRef.current === null) {
      return;
    } else if (value === SelectAllCheckboxState.CHECKED) {
      cRef.current.checked = true;
      cRef.current.indeterminate = false;
    } else if (value === SelectAllCheckboxState.EMPTY) {
      cRef.current.checked = false;
      cRef.current.indeterminate = false;
    } else if (value === SelectAllCheckboxState.INDETERMINATE) {
      cRef.current.checked = false;
      cRef.current.indeterminate = true;
    }
  }, [cRef, value]);

  const label = () => {
    if (value === SelectAllCheckboxState.INDETERMINATE) {
      return (
        <FormattedMessage
          id="components/utils/select-all-checkbox/SelectAllCheckbox:label/clearSelection"
          defaultMessage="Clear selection"
        />
      );
    } else if (value === SelectAllCheckboxState.CHECKED) {
      return (
        <FormattedMessage
          id="components/utils/select-all-checkbox/SelectAllCheckbox:label/deselectAll"
          defaultMessage="Deselect all"
        />
      );
    } else if (
      value === SelectAllCheckboxState.EMPTY ||
      value === SelectAllCheckboxState.DISABLED
    ) {
      return (
        <FormattedMessage
          id="components/utils/select-all-checkbox/SelectAllCheckbox:label/selectAll"
          defaultMessage="Select all"
        />
      );
    } else {
      assertNever(value, "Unsupported SelectAllCheckboxState");
    }
  };

  return (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        disabled={value === SelectAllCheckboxState.DISABLED}
        ref={cRef}
        id={id}
        type="checkbox"
        checked={value === SelectAllCheckboxState.CHECKED}
        onChange={(event) => onChange(event.target.checked)}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label()}
      </label>
    </div>
  );
}
