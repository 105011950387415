import { useState } from "react";

import { DangerButton } from "./Button";
import DeleteModal from "./DeleteModal";

const stories = {
  name: "DeleteModal",
  render: () => <DeleteModalStory />,
};

function DeleteModalStory() {
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async () => {
    await sleep(1000);
    setShowModal(false);
  };

  return (
    <>
      <DangerButton onClick={() => setShowModal(true)}>Delete</DangerButton>
      <DeleteModal
        name="Spaghetti"
        onDelete={handleDelete}
        onHide={() => setShowModal(false)}
        show={showModal}
        title="Delete Product"
      />
    </>
  );
}

function sleep(interval: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), interval);
  });
}

export default stories;
