import { useEffect, useState } from "react";

import { LifeCycleStage } from "../recipes/LifeCycleImpacts";
import { getTestLifeCycleStageRoot } from "../recipes/LifeCycleImpactsTestData";
import Sunburst, { TRANSITION_DURATION_MILLISECONDS } from "./Sunburst";

const stories = {
  name: "Sunburst",
  render: () => <Story />,
};

function Story() {
  const root = getTestLifeCycleStageRoot();
  const [hoverStage, setHoverStage] = useState<LifeCycleStage | null>(null);
  const [activeStage, setActiveStage] = useState<LifeCycleStage>(root);
  const [transitioning, setTransitioning] = useState<boolean>(true);

  useEffect(() => {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, TRANSITION_DURATION_MILLISECONDS);
  }, [activeStage]);

  const goBack = () => {
    if (activeStage.parent !== null) {
      setActiveStage(activeStage.parent);
    }
  };

  const handleActiveStageChange = (stage: LifeCycleStage) => {
    if (stage && stage.depth > activeStage.depth && stage.children.length > 0) {
      setActiveStage(stage);
      // setHoverStage(null);
    }
  };

  const handleHover = (stage: LifeCycleStage | null) => {
    if (!transitioning && activeStage !== stage) {
      setHoverStage(stage);
    }
  };

  return (
    <Sunburst
      activeStage={activeStage}
      goBack={goBack}
      hoverStage={hoverStage}
      onActiveStageChange={handleActiveStageChange}
      onHover={handleHover}
      root={root}
      transitioning={transitioning}
    />
  );
}

export default stories;
