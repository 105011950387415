import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import classNames from "classnames";
import { useIntl } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import "./passwordStrength.css";

export enum PasswordStrength {
  None = "None",
  VeryWeak = "Very Weak",
  Weak = "Weak",
  Average = "Average",
  Strong = "Strong",
  VeryStrong = "Very Strong",
}

const MINIMUM_REQUIRED_PASSWORD_STRENGTH_SCORE = 3;

export interface PasswordStrengthResult {
  isValid: boolean;
  strength: PasswordStrength;
  suggestions: Array<string>;
  warning: string | null;
}

function valueToPasswordStrength(value: number): PasswordStrength {
  if (value === 0) {
    return PasswordStrength.VeryWeak;
  } else if (value === 1) {
    return PasswordStrength.Weak;
  } else if (value === 2) {
    return PasswordStrength.Average;
  } else if (value === 3) {
    return PasswordStrength.Strong;
  } else if (value === 4) {
    return PasswordStrength.VeryStrong;
  } else {
    throw new Error("unknown password strength numerical value");
  }
}

export async function useCheckPasswordStrengthPromise(): Promise<
  ({
    password,
    userInputs,
  }: {
    password: string;
    userInputs: Array<string>;
  }) => PasswordStrengthResult
> {
  const zxcvbnCommonPackage = await import(
    /* webpackChunkName: "zxcvbnCommonPackage" */ "@zxcvbn-ts/language-common"
  );
  const zxcvbnEnPackage = await import(
    /* webpackChunkName: "zxcvbnEnPackage" */ "@zxcvbn-ts/language-en"
  );

  const options = {
    dictionary: {
      ...zxcvbnCommonPackage.default.dictionary,
      ...zxcvbnEnPackage.default.dictionary,
    },
    graphs: zxcvbnCommonPackage.default.adjacencyGraphs,
    translations: zxcvbnEnPackage.default.translations,
  };
  zxcvbnOptions.setOptions(options);

  return ({
    password,
    userInputs,
  }: {
    password: string;
    userInputs: Array<string>;
  }) => {
    const passwordStrength = zxcvbn(password, userInputs);

    return {
      isValid:
        passwordStrength.score >= MINIMUM_REQUIRED_PASSWORD_STRENGTH_SCORE,
      strength:
        password === ""
          ? PasswordStrength.None
          : valueToPasswordStrength(passwordStrength.score),
      suggestions: [
        ...(passwordStrength.score < MINIMUM_REQUIRED_PASSWORD_STRENGTH_SCORE &&
        password !== ""
          ? ["Your password is too weak."]
          : []),
        ...passwordStrength.feedback.suggestions,
      ],
      warning:
        passwordStrength.feedback.warning !== ""
          ? passwordStrength.feedback.warning
          : null,
    };
  };
}

export function useYourPasswordIsTooWeakMessage(): string {
  const intl = useIntl();
  return intl.formatMessage({
    id: "util/passwordStrength/yourPasswordIsTooWeakMessage",
    defaultMessage: "Your password is too weak.",
  });
}

interface PasswordStrengthSuggestionsProps {
  className?: string;
  show: boolean;
  suggestions: Array<string>;
}

export function PasswordStrengthSuggestions(
  props: PasswordStrengthSuggestionsProps
) {
  const { className, show, suggestions } = props;

  return (
    <ul
      className={classNames("PasswordStrengthSuggestions", className, {
        PasswordStrengthSuggestions__visible: show,
      })}
    >
      {suggestions.map((suggestion) => (
        <li className="text-muted text-small">
          <small>{suggestion}</small>
        </li>
      ))}
    </ul>
  );
}

export function useIllegalAuthenticatedUserPasswordInputs(): Array<string> {
  const [userInfo] = useUserInfo();

  return [
    userInfo.email,
    userInfo.firstName,
    userInfo.lastName,
    userInfo.username,
    ...userInfo.organizationMemberships.map(
      (organization_membership) => organization_membership.organization.name
    ),
  ];
}
