import gql from "graphql-tag";
import React, { useState } from "react";

import {
  BillingInterval,
  CountryCode,
  Currency,
} from "../../__generated__/globalTypes";
import useQuery from "../graphql/useQuery";
import { useOrganization } from "../organizations/OrganizationProvider";
import StatusDisplay from "../StatusDisplay";
import { BillingAddressPaymentStep } from "./BillingAddressPaymentStep";
import { BillSummary } from "./BillSummary";
import ConfirmPlan from "./ConfirmPlan";
import ConfirmPlanPaymentStep from "./ConfirmPlanPaymentStep";
import {
  Payment_UpcomingInvoiceQuery as UpcomingInvoiceQuery,
  Payment_UpcomingInvoiceQueryVariables as UpcomingInvoiceQueryVariables,
  Payment_TierProduct as TierProduct,
  Payment_RecipeAllowanceProduct as RecipeAllowanceProduct,
} from "./Payment.graphql";
import PaymentDetailsPaymentStep from "./PaymentDetailsPaymentStep";
import PaymentStep from "./PaymentStep";
import PaymentSteps from "./PaymentSteps";
import Plans from "./Plans";
import { PromotionDetails } from "./PlansPage";
import { tierToSubscribableTier } from "./utils";

interface PaymentProps {
  currency: Currency;
  handleChangePlanClicked: () => void;
  handleRecipeAllowanceProductSelect: (
    tierProduct: TierProduct,
    recipeAllowanceProduct: RecipeAllowanceProduct
  ) => void;
  selectedRecipeAllowanceProduct: RecipeAllowanceProduct;
  billingInterval: BillingInterval;
  selectedTierProduct: TierProduct;
  setBillingInterval: React.Dispatch<React.SetStateAction<BillingInterval>>;
}

export default function Payment(props: PaymentProps) {
  const {
    billingInterval,
    currency,
    handleChangePlanClicked,
    handleRecipeAllowanceProductSelect,
    selectedRecipeAllowanceProduct,
    selectedTierProduct,
    setBillingInterval,
  } = props;

  const [organization] = useOrganization();

  const [promotionDetails, setPromotionDetails] =
    useState<PromotionDetails | null>(null);

  const couponId = promotionDetails?.promotion?.couponId ?? null;
  const appliedPromotionCode = promotionDetails?.appliedPromotionCode ?? "";
  const appliedPromotionId =
    promotionDetails?.promotion?.appliedPromotionId ?? null;

  const { status: upcomingInvoiceStatus, refresh: refreshInvoice } = useQuery<
    UpcomingInvoiceQuery,
    UpcomingInvoiceQueryVariables
  >(upcomingInvoiceQuery, {
    billingInterval,
    couponId: couponId ?? null,
    currency,
    defaultTaxCountry: CountryCode.UNITED_KINGDOM,
    organizationId: organization.id,
    recipeAllowance: selectedRecipeAllowanceProduct.recipeAllowance,
    subscribableTier: tierToSubscribableTier(selectedTierProduct.tier),
  });

  const handlePromotionCodeApplied = (promotionDetails: PromotionDetails) => {
    setPromotionDetails(promotionDetails);
  };

  const billSummary = ({
    handlePromotionCodeApplied,
  }: {
    handlePromotionCodeApplied:
      | ((promotionDetails: PromotionDetails) => void)
      | null;
  }) => (
    <StatusDisplay status={upcomingInvoiceStatus} className="w-100 h-100">
      {({ upcomingInvoice }) => (
        <BillSummary
          appliedPromotionCode={appliedPromotionCode}
          billingInterval={billingInterval}
          couponId={couponId}
          currency={currency}
          onApplyPromotionCode={handlePromotionCodeApplied}
          tierProduct={selectedTierProduct}
          invoice={upcomingInvoice}
        />
      )}
    </StatusDisplay>
  );

  const billingAddressPaymentStep = ({
    onNext,
    setClientSecret,
  }: Pick<
    Parameters<typeof BillingAddressPaymentStep>[0],
    "onNext" | "setClientSecret"
  >) => {
    const paymentStep = ({
      button,
      content,
    }: Pick<Parameters<typeof PaymentStep>[0], "button" | "content">) => (
      <PaymentStep
        billSummary={billSummary({ handlePromotionCodeApplied })}
        button={button}
        content={content}
      />
    );

    return (
      <BillingAddressPaymentStep
        appliedPromotionId={appliedPromotionId}
        billingInterval={billingInterval}
        currency={currency}
        PaymentStep={paymentStep}
        onNext={onNext}
        recipeAllowanceProduct={selectedRecipeAllowanceProduct}
        setClientSecret={setClientSecret}
        tierProduct={selectedTierProduct}
        refreshInvoice={refreshInvoice}
      />
    );
  };

  const confirmPlanPaymentStep = ({
    onNext,
  }: {
    onNext: (() => void) | null;
  }) => {
    const paymentStep = ({
      button,
    }: Pick<Parameters<typeof PaymentStep>[0], "button">) => (
      <PaymentStep
        billSummary={billSummary({
          handlePromotionCodeApplied,
        })}
        button={button}
        content={
          <ConfirmPlan
            billingInterval={billingInterval}
            currency={currency}
            onChangePlan={handleChangePlanClicked}
            onRecipeAllowanceProductSelect={(
              recipeAllowanceProduct: RecipeAllowanceProduct
            ) =>
              handleRecipeAllowanceProductSelect(
                selectedTierProduct,
                recipeAllowanceProduct
              )
            }
            recipeAllowanceProducts={
              selectedTierProduct.recipeAllowanceProducts
            }
            selectedRecipeAllowanceProduct={selectedRecipeAllowanceProduct}
            setBillingInterval={setBillingInterval}
            tierProduct={selectedTierProduct}
          />
        }
      />
    );

    return <ConfirmPlanPaymentStep onNext={onNext} PaymentStep={paymentStep} />;
  };

  const paymentDetailsPaymentStep = (
    <PaymentDetailsPaymentStep
      billSummary={billSummary({
        handlePromotionCodeApplied: null,
      })}
      tierProduct={selectedTierProduct}
    />
  );

  return (
    <PaymentSteps
      BillingAddressPaymentStep={billingAddressPaymentStep}
      ConfirmPlanPaymentStep={confirmPlanPaymentStep}
      paymentDetailsPaymentStep={paymentDetailsPaymentStep}
    />
  );
}

const invoice = gql`
  fragment Payment_Invoice on Invoice {
    ...BillSummary_Invoice
  }
  ${BillSummary.fragments.invoice}
`;

const recipeAllowanceProduct = gql`
  fragment Payment_RecipeAllowanceProduct on RecipeAllowanceProduct {
    ...BillingAddressPaymentStep_RecipeAllowanceProduct
    ...ConfirmPlan_RecipeAllowanceProduct
    ...ConfirmPlanPaymentStep_RecipeAllowanceProduct
    ...Plans_RecipeAllowanceProduct
  }

  ${BillingAddressPaymentStep.fragments.recipeAllowanceProduct}
  ${ConfirmPlan.fragments.recipeAllowanceProduct}
  ${ConfirmPlanPaymentStep.fragments.recipeAllowanceProduct}
  ${Plans.fragments.recipeAllowanceProduct}
`;

const tierProduct = gql`
  fragment Payment_TierProduct on TierProduct {
    recipeAllowanceProducts {
      ...Payment_RecipeAllowanceProduct
    }

    ...BillingAddressPaymentStep_TierProduct
    ...BillSummary_TierProduct
    ...ConfirmPlan_TierProduct
    ...ConfirmPlanPaymentStep_TierProduct
    ...PaymentDetailsPaymentStep_TierProduct
  }

  ${BillingAddressPaymentStep.fragments.tierProduct}
  ${BillSummary.fragments.tierProduct}
  ${ConfirmPlan.fragments.tierProduct}
  ${ConfirmPlanPaymentStep.fragments.tierProduct}
  ${PaymentDetailsPaymentStep.fragments.tierProduct}
  ${recipeAllowanceProduct}
`;

Payment.fragments = { invoice, tierProduct, recipeAllowanceProduct };

const upcomingInvoiceQuery = gql`
  query Payment_UpcomingInvoiceQuery(
    $billingInterval: BillingInterval!
    $couponId: String
    $currency: Currency!
    $defaultTaxCountry: CountryCode!
    $organizationId: UUID!
    $recipeAllowance: RecipeAllowance!
    $subscribableTier: SubscribableTier!
  ) {
    upcomingInvoice(
      billingInterval: $billingInterval
      couponId: $couponId
      currency: $currency
      defaultTaxCountry: $defaultTaxCountry
      organizationId: $organizationId
      recipeAllowance: $recipeAllowance
      subscribableTier: $subscribableTier
    ) {
      ...Payment_Invoice
    }
  }
  ${Payment.fragments.invoice}
`;
