import { FormattedMessage, useIntl } from "react-intl";

import {
  useCollections,
  useTags,
} from "../../services/useOrganizationFeatures";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import { recipesOwnedByOrganizationOrInUserGroupFilter } from "../utils/ownedByOrganizationOrInUserGroupFilter";
import RecipesPanel from "./RecipesPanel";
import useRecipeLabel from "./useRecipeLabel";

const tracking = { pageName: "recipes" };

export default function RecipesPage() {
  const intl = useIntl();
  const pages = usePages();
  const [organizationId] = useOrganizationId();
  const recipeLabel = useRecipeLabel();
  const canUseCollections = useCollections();
  const hasFeatureTags = useTags();

  const selectCollection = canUseCollections
    ? {
        placeholder: intl.formatMessage({
          id: "components/recipes/RecipesPage:selectCollection",
          defaultMessage: "All Collections",
        }),
      }
    : null;

  return (
    <Page tracking={tracking}>
      <div className="h-100 d-flex flex-column">
        <Page.Title
          breadcrumb={pages.Recipes(hasFeatureTags).breadcrumb(null)}
        />
        <RecipesPanel
          newRecipeUrl={pages.RecipesNew.url ?? undefined}
          noRecipesMessage={
            <FormattedMessage
              id="components/recipes/RecipesPage:noRecipesMessage"
              defaultMessage="
                      You do not have any saved {recipesLabel}, please add a new {recipeLabel} to
                      get started.
                    "
              values={{
                recipeLabel: recipeLabel.singularLowercase,
                recipesLabel: recipeLabel.pluralLowercase,
              }}
            />
          }
          noRecipesDueToSearchMessage={
            <FormattedMessage
              id="components/recipes/RecipesPage:noRecipesDueToSearchMessage"
              defaultMessage="There are no {recipesLabel} matching your search."
              values={{
                recipesLabel: recipeLabel.pluralLowercase,
              }}
            />
          }
          pageName="Recipes"
          recipeUrl={(recipe) => pages.Recipe(hasFeatureTags).url(recipe)}
          recipeFilter={recipesOwnedByOrganizationOrInUserGroupFilter(
            organizationId
          )}
          showAddDropdown={true}
          showCopyToButton={false}
          showExportDropdown={true}
          showFilterToRequiresAttention={true}
          showManageTagsButton={true}
          selectCollection={selectCollection}
          includeRecipesUsedAsIngredient={true}
          includeRecipesOnlyAccessibleViaCollection={false}
        />
      </div>
    </Page>
  );
}
