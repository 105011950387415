import VectorProps from "./VectorProps";

export default function InformationOutlined(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library: https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=1H2qwzCsmwIbUQn6-0
  return (
    <svg
      className={className}
      fill="none"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.2C4.02355 15.2 0.8 11.9764 0.8 8C0.8 4.02355 4.02355 0.8 8 0.8C11.9764 0.8 15.2 4.02355 15.2 8C15.2 11.9764 11.9764 15.2 8 15.2Z"
        stroke={fill}
        strokeWidth="1.6"
      />
      <path
        d="M7.182 12.867V5.964H8.872V12.867H7.182ZM8.0335 4.69C7.75617 4.69 7.5135 4.61633 7.3055 4.469C7.10183 4.31733 7 4.10717 7 3.8385C7 3.57417 7.10183 3.36833 7.3055 3.221C7.5135 3.07367 7.75617 3 8.0335 3C8.3065 3 8.54483 3.07367 8.7485 3.221C8.95217 3.36833 9.054 3.57417 9.054 3.8385C9.054 4.10717 8.95217 4.31733 8.7485 4.469C8.54483 4.61633 8.3065 4.69 8.0335 4.69Z"
        fill={fill}
      />
    </svg>
  );
}
