import "./SummaryCard.scss";
import classNames from "classnames";

interface LightCardProps {
  children: React.ReactNode;
  className?: string;
}

export default function SummaryCard(props: LightCardProps) {
  const { children, className = "" } = props;

  return <div className={classNames(className, "SummaryCard")}>{children}</div>;
}
