import { useState } from "react";

import { LabelledTextInput } from "./LabelledTextInput";

const stories = {
  name: "LabelledTextInput",
  render: () => <Story />,
};

function Story() {
  const [value, setValue] = useState<string>("");
  return (
    <LabelledTextInput
      label="Input"
      onChange={(input) => setValue(input)}
      value={value}
    />
  );
}

export default stories;
