import gql from "graphql-tag";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { PackagingComponentMaterialV2Input } from "../../../__generated__/globalTypes";
import { usePages } from "../../pages";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult from "../../utils/ReadResult";
import Select from "../../utils/Select";
import TableEditor from "../../utils/TableEditor";
import { InformationOutlined } from "../../utils/Vectors";
import {
  PackagingComponentMaterialEditorSelect_PackagingComponentV2 as PackagingComponentV2,
  PackagingComponentMaterialEditorSelect_PackagingComponentMaterialV2 as PackagingComponentMaterialV2,
  PackagingComponentMaterialEditorSelect_PackagingMaterialV2 as PackagingMaterialV2,
} from "./PackagingComponentMaterialsEditor.graphql";

export const label = <Label />;

function Label() {
  return (
    <FormattedMessage
      id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor:label"
      defaultMessage="Materials"
    />
  );
}

export type Value = Array<EditorComponentMaterial>;

export interface EditorComponentMaterial {
  existingMaterial: PackagingComponentMaterialV2 | null;
  invalidMaterialId: boolean;
  key: string;
  material: PackagingMaterialV2 | null;
  recycledContentPercentage: FloatInput.Value;
  weightGrams: FloatInput.Value;
}

export function initialValue(packagingComponent?: PackagingComponentV2): Value {
  if (packagingComponent === undefined) {
    return [blankComponentMaterial()];
  } else {
    return packagingComponent.componentMaterials.map(existingComponentMaterial);
  }
}

function blankComponentMaterial(): EditorComponentMaterial {
  return {
    existingMaterial: null,
    invalidMaterialId: false,
    key: `new_${uuidv4()}`,
    material: null,
    recycledContentPercentage: FloatInput.initialValue(null),
    weightGrams: FloatInput.initialValue(null),
  };
}

function existingComponentMaterial(
  componentMaterial: PackagingComponentMaterialV2
): EditorComponentMaterial {
  const recycledContentFraction =
    componentMaterial.recycledContentFraction ?? null;
  return {
    existingMaterial: componentMaterial,
    invalidMaterialId: false,
    key: `existing_${componentMaterial.id}`,
    material: componentMaterial.material,
    recycledContentPercentage: FloatInput.initialValue(
      recycledContentFraction === null ? null : recycledContentFraction * 100
    ),
    weightGrams: FloatInput.initialValue(componentMaterial.weightKg * 1000),
  };
}

interface PackagingComponentMaterialsEditorProps {
  availableMaterials: Array<PackagingMaterialV2>;
  onChange: (value: Value) => void;
  value: Value;
}

export function PackagingComponentMaterialsEditor(
  props: PackagingComponentMaterialsEditorProps
) {
  const { availableMaterials, onChange, value } = props;

  const pages = usePages();

  return (
    <>
      <div className="form-group">
        <div className="d-flex align-items-center mb-2 flex-row">
          <label className="h5 mb-0">{label}</label>
          <HelpModalTooltip>
            <HelpModalTooltip.Question
              question={
                <FormattedMessage
                  id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor:materialsHelpModalQuestion"
                  defaultMessage="If you have a packaging component that includes a material that is not on the drop-down list:"
                />
              }
              answer={
                <FormattedMessage
                  id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor:materialsHelpModalAnswer"
                  defaultMessage="
                  <ul>
                    <li>Choose the closest equivalent material from the list</li>
                    <li><a>Contact Foodsteps</a> to let us know about the material (this helps us plan for expanding our list of supported materials in future)</li>
                  </ul>
                  "
                  values={{
                    a: (chunks: React.ReactNode) => (
                      <Link to={pages.ContactUs.url} target="_blank">
                        {chunks}
                      </Link>
                    ),
                    ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
                    li: (chunks: React.ReactNode) => <li>{chunks}</li>,
                  }}
                />
              }
            />
          </HelpModalTooltip>
        </div>

        <p className="text-muted" style={{ width: "70%" }}>
          <FormattedMessage
            id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor:helpText"
            defaultMessage="What is the component made of? And what percentage of each material is produced from recycled materials?"
          />
        </p>

        <p style={{ width: "70%" }}>
          <InformationOutlined width="1rem" />
          <small className="ml-1">
            <FormattedMessage
              id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor:informationTip"
              defaultMessage="If the % recycled field is left empty, we'll use an estimate based on your location."
            />
          </small>
        </p>

        <TableEditor
          blank={blankComponentMaterial}
          onChange={onChange}
          renderRow={({ onChange, onDelete, rowIndex, value: material }) => (
            <PackagingComponentMaterialEditor
              allowDeletion={rowIndex !== 0 || value.length > 1}
              availableMaterials={availableMaterials}
              onChange={onChange}
              onDelete={onDelete}
              value={material}
            />
          )}
          showAddButton={true}
          value={value}
        />
      </div>
    </>
  );
}

interface PackagingComponentMaterialEditorProps {
  allowDeletion: boolean;
  availableMaterials: Array<PackagingMaterialV2>;
  onChange: (componentMaterial: EditorComponentMaterial) => void;
  onDelete: () => void;
  value: EditorComponentMaterial;
}

function PackagingComponentMaterialEditor(
  props: PackagingComponentMaterialEditorProps
) {
  const { allowDeletion, availableMaterials, onChange, onDelete, value } =
    props;

  const intl = useIntl();

  const deleteColumnWidth = "5%";

  return (
    <tr key={value.key}>
      <td style={{ width: "20%" }}>
        <FloatInput.FloatInput
          onChange={(weightGrams) => {
            onChange({ ...value, weightGrams });
          }}
          render={(props) => (
            <div className="input-group">
              <input {...props} />
              <div className="input-group-append">
                <span className="input-group-text text-middle">
                  <FormattedMessage
                    id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor/PackagingComponentMaterialEditor:grams"
                    defaultMessage="g"
                  />
                </span>
              </div>
            </div>
          )}
          value={value.weightGrams}
        />
      </td>
      <td className="pl-1">
        <Select
          hasError={value.invalidMaterialId}
          onChange={(material) =>
            onChange({
              ...value,
              invalidMaterialId: false,
              material,
            })
          }
          options={availableMaterials}
          optionKey={(packagingMaterial) => packagingMaterial.name}
          renderOption={(packagingMaterial) => packagingMaterial.name}
          placeholder={intl.formatMessage({
            id: "components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor/PackagingComponentMaterialEditor:materialNamePlaceholder",
            defaultMessage: "Search for a material...",
          })}
          value={value.material}
        />
      </td>
      <td className="pl-3" style={{ width: "25%" }}>
        <FloatInput.FloatInput
          onChange={(recycledContentPercentage) =>
            onChange({ ...value, recycledContentPercentage })
          }
          render={(props) => (
            <div className="input-group">
              <input {...props} />
              <div className="input-group-append">
                <span className="input-group-text text-middle">
                  <FormattedMessage
                    id="components/packaging/PackagingComponentEditor/PackagingComponentMaterialsEditor/PackagingComponentMaterialEditor:percentRecycled"
                    defaultMessage="% recycled"
                  />
                </span>
              </div>
            </div>
          )}
          value={value.recycledContentPercentage}
        />
      </td>
      {allowDeletion ? (
        <TableEditor.DeleteCell onDelete={onDelete} width={deleteColumnWidth} />
      ) : (
        <td style={{ width: deleteColumnWidth }}></td>
      )}
    </tr>
  );
}

export function read(
  value: Value
): ReadResult<Value, Array<PackagingComponentMaterialV2Input>> {
  const newValue: Array<EditorComponentMaterial> = [];
  const newComponentMaterials: Array<PackagingComponentMaterialV2Input> = [];
  let hasError = false;

  if (value.length === 0) {
    hasError = true;
  }

  for (const componentMaterial of value) {
    const invalidMaterialId = componentMaterial.material === null;

    function recycledContentPercentageValidationCondition(floatValue: number) {
      return floatValue >= 0 && floatValue <= 100;
    }

    const recycledContentPercentage = FloatInput.readAllowEmpty({
      value: componentMaterial.recycledContentPercentage,
      validationCondition: recycledContentPercentageValidationCondition,
    });

    const weightGrams = FloatInput.read({
      value: componentMaterial.weightGrams,
    });

    const newEditorValue: EditorComponentMaterial = {
      ...componentMaterial,
      invalidMaterialId,
      recycledContentPercentage: recycledContentPercentage.value,
      weightGrams: weightGrams.value,
    };

    newValue.push(newEditorValue);

    if (
      invalidMaterialId ||
      recycledContentPercentage.hasError ||
      weightGrams.hasError
    ) {
      hasError = true;
    } else {
      newComponentMaterials.push({
        materialId: componentMaterial.material?.id!,
        recycledContentFraction:
          recycledContentPercentage.input === null
            ? null
            : recycledContentPercentage.input / 100,
        weightKg: weightGrams.input / 1000,
      });
    }
  }

  return {
    hasError,
    input: newComponentMaterials,
    value: newValue,
  };
}

const packagingMaterialV2Fragment = gql`
  fragment PackagingComponentMaterialEditorSelect_PackagingMaterialV2 on PackagingMaterialV2 {
    id
    name
  }
`;

const packagingComponentMaterialV2Fragment = gql`
  fragment PackagingComponentMaterialEditorSelect_PackagingComponentMaterialV2 on PackagingComponentMaterialV2 {
    id
    material {
      ...PackagingComponentMaterialEditorSelect_PackagingMaterialV2
    }
    recycledContentFraction
    weightKg
  }

  ${packagingMaterialV2Fragment}
`;

export const fragments = {
  packagingComponentV2: gql`
    fragment PackagingComponentMaterialEditorSelect_PackagingComponentV2 on PackagingComponentV2 {
      componentMaterials {
        ...PackagingComponentMaterialEditorSelect_PackagingComponentMaterialV2
      }
      id
      name
    }

    ${packagingComponentMaterialV2Fragment}
  `,

  packagingMaterialV2: packagingMaterialV2Fragment,
};
