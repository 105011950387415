import * as graphql from "graphql";
import { useEffect, useMemo } from "react";
import * as ReactQuery from "react-query";

import * as statuses from "../../util/statuses";
import { useGraphQL } from "./GraphQLProvider";

export type PagedQueryResult<TQuery, TNode> = {
  firstPage: TQuery;
  nodes: Array<TNode>;
};

export default function usePagedQuery<
  TQuery,
  TVariables extends { after?: string | null },
  TNode
>(
  query: graphql.DocumentNode,
  variables: TVariables,
  getConnection: (query: TQuery) => Page<TNode>,
  fetchAllPages: boolean = false
): {
  fetchNextPage: null | (() => Promise<void>);
  status: statuses.Status<PagedQueryResult<TQuery, TNode>>;
  refresh: () => Promise<void>;
} {
  const graphQL = useGraphQL();

  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    error,
    data,
    isFetching,
    refetch,
  } = ReactQuery.useInfiniteQuery(
    [graphql.print(query), variables, graphQL.key],
    ({ pageParam }) =>
      graphQL.fetch<TQuery, TVariables>({
        query,
        variables: { ...variables, after: pageParam },
      }),
    {
      getNextPageParam: (lastPage: TQuery, pages: Array<TQuery>) => {
        const connection = getConnection(lastPage);
        if (connection.pageInfo.hasNextPage) {
          return connection.pageInfo.endCursor;
        } else {
          return undefined;
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const status = useMemo(() => {
    if (isLoading || (hasNextPage && fetchAllPages)) {
      return statuses.loading;
    } else if (isError) {
      return statuses.error(error);
    } else if (data === undefined) {
      return statuses.error(new Error("query did not return any data"));
    } else {
      return statuses.success({
        firstPage: data.pages[0],
        nodes: data.pages.flatMap((page) =>
          getConnection(page).edges.map((edge) => edge.node)
        ),
      });
    }
  }, [
    fetchAllPages,
    getConnection,
    hasNextPage,
    isLoading,
    isError,
    error,
    data,
  ]);

  useEffect(() => {
    async function fetchMore() {
      if (!ignore && !isFetching && hasNextPage && fetchAllPages) {
        fetchNextPage();
      }
    }

    let ignore = false;
    fetchMore();
    return () => {
      ignore = true;
    };
  }, [fetchAllPages, fetchNextPage, hasNextPage, isLoading, isFetching]);

  return {
    fetchNextPage:
      hasNextPage && !fetchAllPages
        ? async () => {
            await fetchNextPage();
          }
        : null,
    status,
    refresh: async () => {
      await refetch();
    },
  };
}

export function usePagedQueryFetchAll<
  TQuery,
  TVariables extends { after?: string | null },
  TNode
>(
  query: graphql.DocumentNode,
  variables: TVariables,
  getConnection: (query: TQuery) => Page<TNode>
): {
  status: statuses.Status<PagedQueryResult<TQuery, TNode>>;
  refresh: () => Promise<void>;
} {
  return usePagedQuery(query, variables, getConnection, true);
}

export const extractNodesFromPagedQueryResult = <TQuery, TNode>(
  result: PagedQueryResult<TQuery, TNode>
): Array<TNode> => result.nodes;

interface Page<TNode> {
  edges: Array<Edge<TNode>>;
  pageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
  };
}

interface Edge<TNode> {
  node: TNode;
}
