import { useIntl } from "react-intl";

import { PackagingComponentV2Order } from "../../__generated__/globalTypes";
import { PackagingComponentsListPageQueryParams } from "../pages";
import SearchBox from "../utils/SearchBox";
import Select from "../utils/Select";
import useStateWithDebouncedUpdate from "../utils/useStateWithDebouncedUpdate";

interface PackagingQueryControlsProps {
  onChange: (value: Partial<PackagingComponentsListPageQueryParams>) => void;
  value: PackagingComponentsListPageQueryParams;
}

export function PackagingQueryControls(props: PackagingQueryControlsProps) {
  const { onChange, value } = props;

  const [searchTermText, setSearchTermText] = useStateWithDebouncedUpdate({
    initialValue: value.searchTerm,
    update: (newValue: string) => onChange({ searchTerm: newValue }),
  });

  return (
    <div>
      <div className="d-flex" style={{ gap: "16px" }}>
        <PackagingComponentSearchBox
          onChange={(searchTerm) => setSearchTermText(searchTerm)}
          value={searchTermText}
        />
        <OrderByDropdown
          onChange={(orderBy) => onChange({ orderBy })}
          value={value.orderBy}
        />
      </div>
    </div>
  );
}

interface PackagingComponentSearchBoxProps {
  onChange: (value: string) => void;
  value: string;
}

function PackagingComponentSearchBox(props: PackagingComponentSearchBoxProps) {
  const { onChange, value } = props;

  const intl = useIntl();

  const searchPlaceholder = intl.formatMessage({
    id: "components/packaging/PackagingPage:PackagingComponentSearchBox/searchPlaceholder",
    defaultMessage: "Search packaging",
  });

  return (
    <SearchBox
      onChange={onChange}
      placeholder={searchPlaceholder}
      value={value}
    />
  );
}

interface OrderByDropdownProps {
  onChange: (value: OrderBy) => void;
  value: OrderBy;
}

function OrderByDropdown(props: OrderByDropdownProps) {
  const { onChange, value } = props;

  const intl = useIntl();
  const packagingOrderLabel = usePackagingOrderLabel();

  return (
    <div
      style={{
        flexBasis: "300px",
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <Select<OrderBy>
        className="sort-by"
        isClearable={false}
        onChange={(orderBy) => onChange(orderBy!)}
        optionKey={(orderBy) => orderBy}
        options={orderByOptions}
        renderOption={(orderBy) =>
          intl.formatMessage(
            {
              id: "components/recipes/RecipeQueryControls:sortBy",
              defaultMessage: "Sort by: {order}",
            },
            { order: packagingOrderLabel(orderBy) }
          )
        }
        dropdownArrow="upDown"
        value={value}
      />
    </div>
  );
}

export type OrderBy =
  | PackagingComponentV2Order.NAME_ASC
  | PackagingComponentV2Order.NAME_DESC;

const orderByOptions: Array<OrderBy> = [
  PackagingComponentV2Order.NAME_ASC,
  PackagingComponentV2Order.NAME_DESC,
];

function usePackagingOrderLabel(): (orderBy: OrderBy) => string {
  const intl = useIntl();

  return (orderBy) => {
    switch (orderBy) {
      case PackagingComponentV2Order.NAME_ASC:
        return intl.formatMessage({
          id: "components/packaging/PackagingQueryControls:order/nameAsc/label",
          defaultMessage: "Name: A to Z",
        });

      case PackagingComponentV2Order.NAME_DESC:
        return intl.formatMessage({
          id: "components/packaging/PackagingQueryControls:order/nameDesc/label",
          defaultMessage: "Name: Z to A",
        });
    }
  };
}

export function parsePackagingOrder(rawOrderBy: string): OrderBy | null {
  return (orderByOptions as Array<string>).includes(rawOrderBy)
    ? (rawOrderBy as OrderBy)
    : null;
}
