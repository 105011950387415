import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { BillingInterval, Currency } from "../../__generated__/globalTypes";
import { PricePerBillingInterval } from "../../util/currencyFormatting";
import { LinkButton } from "../utils/Button";
import Card from "../utils/Card";
import RadioButtons from "../utils/RadioButtons";
import { FilledCheckmark } from "../utils/Vectors";
import BillingIntervalToggleLabel from "./BillingIntervalToggleLabel";
import {
  ConfirmPlan_RecipeAllowanceProduct as RecipeAllowanceProduct,
  ConfirmPlan_TierProduct as TierProduct,
} from "./ConfirmPlan.graphql";
import { RecipeAllowanceProductSelect } from "./RecipeAllowanceProductSelect";
import {
  getSellingPoints,
  SellingPoint,
  sellingPointTexts,
} from "./sellingPoints";
import { priceForBillingInterval } from "./utils";

interface ConfirmPlanProps {
  billingInterval: BillingInterval;
  currency: Currency;
  onChangePlan: () => void;
  tierProduct: TierProduct;
  recipeAllowanceProducts: Array<RecipeAllowanceProduct>;
  selectedRecipeAllowanceProduct: RecipeAllowanceProduct;
  setBillingInterval: (billingInterval: BillingInterval) => void;
  onRecipeAllowanceProductSelect: (
    recipeAllowanceProduct: RecipeAllowanceProduct
  ) => void;
}

export default function ConfirmPlan(props: ConfirmPlanProps) {
  const {
    billingInterval,
    currency,
    onChangePlan,
    tierProduct,
    onRecipeAllowanceProductSelect,
    recipeAllowanceProducts,
    selectedRecipeAllowanceProduct,
    setBillingInterval,
  } = props;

  const [billingIntervalHoverValue, setBillingIntervalHoverValue] =
    useState<BillingInterval | null>(null);

  const headingClassName = "semi-bold-font my-3";

  return (
    <>
      <h2 className="mb-4">
        <FormattedMessage
          id="components/subscriptions/ConfirmPlan:confirmYourPlan"
          defaultMessage="Confirm your plan"
        />
      </h2>
      <h6 className={headingClassName}>
        <FormattedMessage
          id="components/subscriptions/ConfirmPlan:payments"
          defaultMessage="Payments"
        />
      </h6>
      <div>
        <RadioButtons
          buttonStyle="square"
          hoverValue={billingIntervalHoverValue}
          inline
          onChange={setBillingInterval}
          optionKey={(o) => o.toString()}
          options={Object.values(BillingInterval)}
          renderOptionLabel={(billingInterval) => (
            <BillingIntervalToggleLabel billingInterval={billingInterval} />
          )}
          setHoverLabel={setBillingIntervalHoverValue}
          value={billingInterval}
        />
      </div>
      <h6 className={headingClassName}>
        <FormattedMessage
          id="components/subscriptions/ConfirmPlan:plan"
          defaultMessage="Plan"
        />
      </h6>
      <div className="mb-5">
        <TierProductCard
          billingInterval={billingInterval}
          currency={currency}
          onChangePlan={onChangePlan}
          tierProduct={tierProduct}
        />
      </div>
      <h6 className={headingClassName}>
        <FormattedMessage
          id="components/subscriptions/ConfirmPlan:recipeAllowance"
          defaultMessage="Product Allowance"
        />
      </h6>
      <RecipeAllowanceProductSelect
        billingInterval={billingInterval}
        currency={currency}
        onChange={onRecipeAllowanceProductSelect}
        value={selectedRecipeAllowanceProduct}
        recipeAllowanceProducts={recipeAllowanceProducts}
      />
    </>
  );
}

interface TierProductCardProps {
  billingInterval: BillingInterval;
  currency: Currency;
  onChangePlan: () => void;
  tierProduct: TierProduct;
}

function TierProductCard(props: TierProductCardProps) {
  const { billingInterval, currency, onChangePlan, tierProduct } = props;

  return (
    <Card className="px-4 pt-4">
      <div className="container p-0">
        <div className="px-3">
          <div className="row">
            <div className="col pl-0">
              <h5 className="medium-font">{tierProduct.name}</h5>
            </div>
            <div className="col p-0 text-right align-text-top">
              <LinkButton onClick={onChangePlan} className="p-0 text-right">
                <FormattedMessage
                  id="components/subscriptions/ConfirmPlan:ProductCard/change"
                  defaultMessage="Change"
                />
              </LinkButton>
            </div>
          </div>
          <div className="row">
            <div className="col px-0">
              <p className="small mb-3">{tierProduct.tagline}</p>
              <div className="small container pl-0">
                <SellingPointList
                  sellingPoints={getSellingPoints(
                    tierProduct.tier,
                    tierProduct.carbonLabels
                  )}
                />
              </div>
              <div className="container p-0">
                <div className="text-right">
                  <h4 className="m-0 pt-1">
                    <PricePerBillingInterval
                      billingInterval={billingInterval}
                      currency={currency}
                      pence={priceForBillingInterval({
                        billingInterval,
                        currency,
                        prices: tierProduct.price,
                      })}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

interface SellingPointListProps {
  sellingPoints: Array<SellingPoint>;
}

function SellingPointList(props: SellingPointListProps) {
  const { sellingPoints } = props;

  return (
    <>
      {sellingPoints.map((sellingPoint: SellingPoint, index: number) => (
        <p className="my-2" key={index}>
          <FilledCheckmark width="24px" className="mr-2" />
          {sellingPointTexts[sellingPoint].name}
        </p>
      ))}
    </>
  );
}

ConfirmPlan.fragments = {
  tierProduct: gql`
    fragment ConfirmPlan_TierProduct on TierProduct {
      carbonLabels
      tagline
      tier
      name
      price {
        month {
          gbp
          usd
        }
        year {
          gbp
          usd
        }
      }
    }
  `,
  recipeAllowanceProduct: gql`
    fragment ConfirmPlan_RecipeAllowanceProduct on RecipeAllowanceProduct {
      ...RecipeAllowanceProductSelect_RecipeAllowanceProduct
    }
    ${RecipeAllowanceProductSelect.fragments.recipeAllowanceProduct}
  `,
};
