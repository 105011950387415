import classNames from "classnames";

interface PanelProps {
  className?: string;
  children: React.ReactNode;
}

export function Panel(props: PanelProps) {
  const { className, children } = props;
  return <div className={classNames(className, "Panel")}>{children}</div>;
}
