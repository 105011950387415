import React from "react";
import { FormattedMessage } from "react-intl";

import { IconButton } from "./Button";
import { Bin, Pencil } from "./Vectors";
import "./RowActions.css";

interface RowActionsProps<T> {
  editingRow: boolean;
  hoverRow: T | null;
  onClickEditRow: (row: T) => void;
  onDelete: (row: T) => void;
  row: T;
}

export default function RowActions<T extends { id: number }>(
  props: RowActionsProps<T>
) {
  const { editingRow, hoverRow, onClickEditRow, onDelete, row } = props;
  const rowHighlighted = row.id === hoverRow?.id;

  let deleteSymbolClassName: string;
  if (rowHighlighted && !editingRow) {
    deleteSymbolClassName = "RowActions_Bin__RowHighlighted";
  } else if (editingRow) {
    deleteSymbolClassName = "RowActions_Bin__EditingRow";
  } else {
    deleteSymbolClassName = "RowActions_Bin";
  }

  return (
    <div className="RowActions">
      {rowHighlighted && !editingRow && (
        <div className="RowActions__Edit" onClick={() => onClickEditRow(row)}>
          <IconButton
            display="inline"
            icon={<Pencil className="RowActions__EditIcon" width={20} />}
          />
          <div className="RowActions__EditText medium-font">
            <FormattedMessage
              id="components/utils/RowActions/RowActions:editRowNameText"
              defaultMessage="Edit"
            />
          </div>
        </div>
      )}
      <Bin
        className={deleteSymbolClassName}
        width={20}
        handleClick={() => onDelete(row)}
        key={`delete_${row.id}`}
      />
    </div>
  );
}
