import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useHistory, useLocation } from "react-router-dom";

import { useShouldHideIngredientsPage } from "../../services/useFeatureFlags";
import {
  useCollections,
  useDashboard,
  useLandUse,
  useProductPackaging,
  useScope3Report,
  useSystemBoundary,
  useTags,
  useViewSharedProducts,
  useWaterUse,
} from "../../services/useOrganizationFeatures";
import Footer from "../footer/Footer";
import { useOrganization } from "../organizations/OrganizationProvider";
import { NAV_ICON_WIDTH, usePages } from "../pages";
import SystemBoundaryDisplay from "../recipes/SystemBoundaryDisplay";
import RotatingChevron from "../utils/RotatingChevron";
import TooltipOverlay from "../utils/TooltipOverlay";
import UpgradeRequestModal from "../utils/UpgradeRequestModal";
import {
  Inputs,
  Private as PrivateIcon,
  Products as ProductsIcon,
} from "../utils/Vectors";
import Report from "../utils/Vectors/Report";
import "./Navbar.css";

export enum NavbarItemType {
  MenuItem,
  SubMenuItem,
}

type NavbarMenuItemLockedMessage = {
  message: React.ReactNode;
  maxWidth?: string;
};

export type NavbarMenuItem = {
  type: NavbarItemType.MenuItem;
  locked?: boolean;
  lockedMessage?: NavbarMenuItemLockedMessage;
  symbol?: React.ReactNode;
  title: string;
  url: string;
};

type NavbarSubmenuId = "products" | "inputs" | "reports";

type NavbarSubMenuItem = {
  type: NavbarItemType.SubMenuItem;
  id: NavbarSubmenuId;
  locked?: boolean;
  lockedMessage?: NavbarMenuItemLockedMessage;
  title: string;
  symbol: React.ReactNode;
  subItems: NavbarMenuItem[];
};

export type NavbarItem = NavbarMenuItem | NavbarSubMenuItem;

interface NavbarProps {
  items?: Array<NavbarItem>;
}

export default function Navbar(props: NavbarProps) {
  const { items } = props;
  const hasFeatureViewSharedProducts = useViewSharedProducts();
  const shouldHideIngredientsPage = useShouldHideIngredientsPage();
  const {
    Dashboard,
    Recipes,
    Collections,
    Ingredients,
    LandUse,
    Packaging,
    Scope3,
    SharedProducts,
    WaterUse,
  } = usePages();
  const [organization] = useOrganization();
  const canViewCollectionsPage = useCollections();
  const canViewDashboardPage = useDashboard();
  const canViewPackagingPage = useProductPackaging();
  const landUse = useLandUse();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const scope3Report = useScope3Report();
  const systemBoundary = useSystemBoundary();
  const hasFeatureTags = useTags();
  const waterUse = useWaterUse();

  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);

  const showSharedProductsPage =
    organization.parentId !== null && hasFeatureViewSharedProducts;

  const productFootprintNavbarItems = (): NavbarItem[] => {
    if (process.env.REACT_APP_MODE === "product-report") {
      return [
        { type: NavbarItemType.MenuItem, ...Dashboard(!canViewDashboardPage) },
        { type: NavbarItemType.MenuItem, ...Recipes(hasFeatureTags) },
        {
          type: NavbarItemType.MenuItem,
          ...Collections(!canViewCollectionsPage),
        },
      ];
    } else {
      const productsMenuItemBase = {
        symbol: <ProductsIcon width={NAV_ICON_WIDTH} />,
        title: intl.formatMessage({
          id: "components/pages:ProductsSubMenuTitle",
          defaultMessage: "Products",
        }),
      };

      let productsMenuItem: NavbarItem;

      if (hasFeatureTags) {
        productsMenuItem = {
          ...productsMenuItemBase,
          type: NavbarItemType.MenuItem,
          url: Recipes(hasFeatureTags).url,
        };
      } else {
        const productsSubItems: Array<NavbarMenuItem> = [
          { type: NavbarItemType.MenuItem, ...Recipes(hasFeatureTags) },
          {
            type: NavbarItemType.MenuItem,
            ...Collections(!canViewCollectionsPage),
          },
        ];
        productsMenuItem = {
          ...productsMenuItemBase,
          type: NavbarItemType.SubMenuItem,
          id: "products",
          subItems: productsSubItems,
        };
      }
      const navbarItems: NavbarItem[] = [
        { type: NavbarItemType.MenuItem, ...Dashboard(!canViewDashboardPage) },

        productsMenuItem,
        {
          id: "inputs",
          type: NavbarItemType.SubMenuItem,
          subItems: shouldHideIngredientsPage
            ? [
                {
                  type: NavbarItemType.MenuItem,
                  ...Packaging(!canViewPackagingPage),
                },
              ]
            : [
                { type: NavbarItemType.MenuItem, ...Ingredients },
                {
                  type: NavbarItemType.MenuItem,
                  ...Packaging(!canViewPackagingPage),
                },
              ],
          symbol: <Inputs width={NAV_ICON_WIDTH} />,
          title: intl.formatMessage({
            id: "components/pages:InputsSubMenuTitle",
            defaultMessage: "Inputs",
          }),
        },
      ];

      if (showSharedProductsPage) {
        navbarItems.push({ type: NavbarItemType.MenuItem, ...SharedProducts });
      }

      return navbarItems;
    }
  };

  const corporateReportingNavbarItems = (): NavbarItem[] => {
    let subItems: Array<NavbarMenuItem> = [
      { type: NavbarItemType.MenuItem, ...Scope3 },
    ];
    if (landUse) {
      subItems.push({ type: NavbarItemType.MenuItem, ...LandUse });
    }
    if (waterUse) {
      subItems.push({ type: NavbarItemType.MenuItem, ...WaterUse });
    }

    return scope3Report
      ? [
          {
            id: "reports",
            title: intl.formatMessage({
              id: "components/pages:ReportsSubMenuTitle",
              defaultMessage: "Reports",
            }),
            type: NavbarItemType.SubMenuItem,
            subItems,
            symbol: <Report width={NAV_ICON_WIDTH} />,
          },
        ]
      : [];
  };

  const isActiveLink = (url: string): boolean => {
    if (url === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(url);
  };

  const isActiveSubmenuItem = (subMenuItem: NavbarSubMenuItem): boolean => {
    return subMenuItem.subItems.some((item) => isActiveLink(item.url));
  };

  const initialOpenSubmenuIds = (): NavbarSubmenuId[] => {
    let id = null;
    [
      ...productFootprintNavbarItems(),
      ...corporateReportingNavbarItems(),
    ].forEach((item) => {
      if (
        item.type === NavbarItemType.SubMenuItem &&
        isActiveSubmenuItem(item)
      ) {
        id = item.id;
      }
    });
    return id ? [id] : [];
  };

  const [openSubmenuIds, setOpenSubmenuIds] = useState<NavbarSubmenuId[]>(
    initialOpenSubmenuIds()
  );

  const handleMenuItemClicked = (page: NavbarMenuItem) => {
    history.push(page.locked ? "#" : page.url);
  };

  const renderMenuItem = (page: NavbarMenuItem, subMenuItem: boolean) => (
    <MenuItem
      key={page.title}
      icon={page.symbol}
      className={classNames({
        Navbar__MenuItem__active: !page.locked && isActiveLink(page.url),
        Navbar__MenuItem__locked: page.locked,
      })}
      onClick={() => handleMenuItemClicked(page)}
    >
      <div className={subMenuItem || !page.symbol ? "w-100 pl-4" : "w-100"}>
        {page.title}
      </div>
      {page.locked && (
        <div className="private-icon">
          <TooltipOverlay
            id="components/navigation/Navbar:privateIconTooltip"
            overlay={page.lockedMessage?.message}
            placement="right"
            style={{ maxWidth: page.lockedMessage?.maxWidth ?? "188px" }}
          >
            <PrivateIcon
              width={16}
              handleClick={() => setShowUpgradeRequestModal(true)}
            />
          </TooltipOverlay>
        </div>
      )}
    </MenuItem>
  );

  const handleSubmenuClick = (submenuId: NavbarSubmenuId) => {
    if (openSubmenuIds.includes(submenuId)) {
      setOpenSubmenuIds(openSubmenuIds.filter((_) => _ !== submenuId));
    } else {
      setOpenSubmenuIds([...openSubmenuIds, submenuId]);
    }
  };

  const renderNavbarItem = (page: NavbarItem) => {
    if (page.type === NavbarItemType.MenuItem) {
      return renderMenuItem(page, false);
    } else {
      return (
        <SubMenu
          key={page.title}
          onClick={() => handleSubmenuClick(page.id)}
          className={classNames("Navbar__SubMenu", {
            Navbar__SubMenu__closed_and_active:
              !openSubmenuIds.includes(page.id) && isActiveSubmenuItem(page),
          })}
          label={
            <>
              <div className="SubMenu__Label__Title">{page.title}</div>
              <div className="SubMenu__Label__Chevron__Container">
                <RotatingChevron expanded={openSubmenuIds.includes(page.id)} />
              </div>
            </>
          }
          defaultOpen={isActiveSubmenuItem(page)}
          icon={page.symbol}
        >
          {page.subItems.map((page) => {
            return renderMenuItem(page, true);
          })}
        </SubMenu>
      );
    }
  };

  const corporateReportingSectionIsActive = () => {
    return corporateReportingNavbarItems().some((item) => {
      return (
        item.type === NavbarItemType.SubMenuItem && isActiveSubmenuItem(item)
      );
    });
  };

  return (
    <>
      <Sidebar className="Navbar__Sidebar Navbar_X">
        <div className="Navbar_MenuAndFooterContainer">
          <Menu className="p-3">
            {items ? (
              items.map(renderNavbarItem)
            ) : (
              <>
                <div className="small-copy-medium text-muted Navbar_SectionHeader">
                  <FormattedMessage
                    id="components/navigation/Navbar:productFootprints"
                    defaultMessage="Product Footprints"
                  />
                </div>
                {productFootprintNavbarItems().map(renderNavbarItem)}
                <SystemBoundaryDisplay
                  className="Navbar_SystemBoundaryDisplay"
                  inactive={corporateReportingSectionIsActive()}
                  systemBoundary={systemBoundary}
                />
                {scope3Report && (
                  <>
                    <hr />
                    <div className="small-copy-medium text-muted Navbar_SectionHeader">
                      <FormattedMessage
                        id="components/navigation/Navbar:corporateReporting"
                        defaultMessage="Corporate Reporting"
                      />
                    </div>
                    {corporateReportingNavbarItems().map(renderNavbarItem)}
                  </>
                )}
              </>
            )}
          </Menu>
          <Footer />
        </div>
      </Sidebar>
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </>
  );
}
