import AccountDropdown from "./AccountDropdown";

const stories = {
  name: "AccountDropdown",
  render: () => <Story />,
};

function Story() {
  return <AccountDropdown />;
}

export default stories;
