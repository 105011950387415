import gql from "graphql-tag";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { useTracking } from "../../tracking";
import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Form from "../utils/Form";
import {
  AddCollectionModal_AddRecipeCollection as AddRecipeCollection,
  AddCollectionModal_AddRecipeCollectionVariables as AddRecipeCollectionVariables,
  AddCollectionModal_RecipeCollection as RecipeCollection,
} from "./AddCollectionModal.graphql";

interface AddCollectionModalProps {
  onCreated?: (newCollection: RecipeCollection) => void;
  onHide: () => void;
  refreshCollections: () => void;
  show: boolean;
}

interface AddCollectionState {
  newCollection: string;
  success: boolean;
}

const defaultState = {
  newCollection: "",
  success: false,
};

export default function AddCollectionModal(props: AddCollectionModalProps) {
  const { onCreated, onHide, refreshCollections, show } = props;

  const intl = useIntl();
  const [organizationId] = useOrganizationId();
  const { trackCollectionCreationCompleted } = useTracking();

  const [state, setState] = useState<AddCollectionState>(defaultState);

  const updateState = (update: Partial<AddCollectionState>) =>
    setState((state) => ({ ...state, ...update }));

  const [addRecipeCollection] = useMutation<
    AddRecipeCollection,
    AddRecipeCollectionVariables
  >(addRecipeCollectionMutation);

  async function sendAddCollectionMutation({ name }: { name: string }) {
    const response = await addRecipeCollection({
      variables: {
        input: { name, ownerOrganizationId: organizationId },
      },
    });
    return response.addRecipeCollection.recipeCollection;
  }

  const saveNewCollection = async (): Promise<RecipeCollection> => {
    const response = await sendAddCollectionMutation({
      name: state.newCollection,
    });
    await refreshCollections();
    if (onCreated !== undefined) {
      onCreated(response);
    }
    updateState({
      success: true,
    });
    return response;
  };

  const handleSubmit = async () => {
    let { newCollection } = state;
    if (newCollection === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/collections/AddCollectionModal:missingCollectionNameErrorMessage",
          defaultMessage: "Collection name cannot be blank.",
        })
      );
    } else {
      const newCollection = await saveNewCollection();
      trackCollectionCreationCompleted({
        collectionId: newCollection.id,
        collectionName: newCollection.name,
      });
    }
  };

  return (
    <Modal
      onHide={() => {
        setState(defaultState);
        return onHide();
      }}
      show={show}
      aria-labelledby="contained-modal-title-vcenter rounded-lg"
      centered
      className="border-0"
    >
      <Modal.Header
        className="justify-content-center medium-font border-0 py-2"
        closeButton
      >
        <h5 className="medium-font m-0 pt-3 text-center">
          <FormattedMessage
            id="components/collections/AddCollectionModal:title"
            defaultMessage="Create New Collection"
          />
        </h5>
      </Modal.Header>
      {state.success ? (
        <h6 className="py-5 text-center">
          <FormattedMessage
            id="components/collections/AddCollectionModal:successMessage"
            defaultMessage="Collection Successfully Created."
          />
        </h6>
      ) : (
        <Form className="col-7 my-4 mx-auto pb-3" onSubmit={handleSubmit}>
          <div className="form-group m-0 pt-2">
            <input
              autoComplete="off"
              name="newCollection"
              placeholder={intl.formatMessage({
                id: "components/collections/AddCollectionModal:collectionNamePlaceholder",
                defaultMessage: "Collection Name",
              })}
              type="text"
              className="form-control m-0"
              onChange={(e) => updateState({ newCollection: e.target.value })}
            />
          </div>
          <Form.ErrorAlert className="mt-4" />
          <div className="mt-4 text-right">
            <Form.SubmitButton
              loadingLabel={intl.formatMessage({
                id: "components/collections/AddCollectionModal:saveButton/loadingLabel",
                defaultMessage: "Saving",
              })}
              submitLabel={intl.formatMessage({
                id: "components/collections/AddCollectionModal:saveButton/submitLabel",
                defaultMessage: "Save",
              })}
            />
          </div>
        </Form>
      )}
    </Modal>
  );
}

export const addRecipeCollectionMutation = gql`
  mutation AddCollectionModal_AddRecipeCollection(
    $input: AddRecipeCollectionInput!
  ) {
    addRecipeCollection(input: $input) {
      recipeCollection {
        ...AddCollectionModal_RecipeCollection
      }
      success
    }
  }

  fragment AddCollectionModal_RecipeCollection on RecipeCollection {
    id
    name
    viewerHasPermissionUpdate
  }
`;
