import { useState } from "react";

import {
  BillingInterval,
  Currency,
  RecipeAllowance,
  RecipeAllowanceProduct,
  Tier,
  TierProduct,
} from "../../__generated__/globalTypes";
import ConfirmPlan from "./ConfirmPlan";

const recipeAllowanceProducts: Array<RecipeAllowanceProduct> = [
  {
    recipeAllowance: RecipeAllowance.TEN,
    price: {
      month: { gbp: 10000, usd: 13000 },
      year: { gbp: 108000, usd: 140400 },
    },
  },
  {
    recipeAllowance: RecipeAllowance.FIFTY,
    price: {
      month: { gbp: 50000, usd: 53000 },
      year: { gbp: 540000, usd: 702000 },
    },
  },
  {
    recipeAllowance: RecipeAllowance.ONE_HUNDRED,
    price: {
      month: { gbp: 200000, usd: 260000 },
      year: { gbp: 2160000, usd: 2808000 },
    },
  },
];

const product: TierProduct = {
  id: "tierProductId",
  name: "Share",
  price: {
    month: { gbp: 25000, usd: 32500 },
    year: { gbp: 270000, usd: 351000 },
  },
  recipeAllowanceProducts,
  tier: Tier.FOOD_SERVICE_PREMIUM,
  tagline: "A tagline explaining the plan",
  carbonLabels: true,
};

const stories = {
  name: "ConfirmPlan",
  render: () => <Story />,
};

function Story() {
  const [selectedRecipeAllowanceProduct, setSelectedRecipeAllowanceProduct] =
    useState<RecipeAllowanceProduct>(recipeAllowanceProducts[0]);

  const handleRecipeAllowanceProductSelect = (
    recipeAllowanceProduct: RecipeAllowanceProduct | null
  ) => {
    if (recipeAllowanceProduct !== null) {
      setSelectedRecipeAllowanceProduct(recipeAllowanceProduct);
    }
  };

  const [billingInterval, setBillingInterval] = useState<BillingInterval>(
    BillingInterval.MONTH
  );

  return (
    <ConfirmPlan
      billingInterval={billingInterval}
      currency={Currency.GBP}
      onChangePlan={() => alert("Change plan")}
      tierProduct={product}
      recipeAllowanceProducts={recipeAllowanceProducts}
      onRecipeAllowanceProductSelect={handleRecipeAllowanceProductSelect}
      selectedRecipeAllowanceProduct={selectedRecipeAllowanceProduct}
      setBillingInterval={setBillingInterval}
    />
  );
}

export default stories;
