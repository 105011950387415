export default class UserVisibleError extends Error {
  public readonly messageNode: React.ReactNode;

  constructor(message: string, messageNode?: React.ReactNode) {
    super(message);
    this.messageNode = messageNode ?? message;
  }

  static isUserVisibleError(value: unknown): value is UserVisibleError {
    return value instanceof UserVisibleError;
  }
}
