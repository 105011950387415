import VectorProps from "./VectorProps";

export default function DoubleTick(props: VectorProps) {
  const { fill = "currentColor", width } = props;
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8186 5.90608L12.7186 15.7461C12.63 15.8655 12.5176 15.9654 12.3885 16.0394C12.2595 16.1134 12.1165 16.1599 11.9686 16.1761H11.8686C11.6021 16.1757 11.3454 16.0758 11.1486 15.8961L7.88861 12.8961L6.01861 15.5161C5.93479 15.6412 5.82381 15.7457 5.69394 15.8219C5.56408 15.8981 5.41869 15.9439 5.26861 15.9561H5.16861C4.90424 15.9603 4.64896 15.8596 4.45861 15.6761L0.358614 11.9161C0.251586 11.8266 0.164462 11.7157 0.102843 11.5906C0.0412235 11.4654 0.00647163 11.3288 0.000820807 11.1894C-0.00483002 11.05 0.0187453 10.911 0.0700315 10.7812C0.121318 10.6515 0.19918 10.5339 0.298614 10.4361C0.388088 10.3291 0.498966 10.2419 0.624121 10.1803C0.749276 10.1187 0.885938 10.0839 1.02533 10.0783C1.16471 10.0726 1.30374 10.0962 1.43347 10.1475C1.5632 10.1988 1.68077 10.2766 1.77861 10.3761L5.01861 13.3261L11.3986 4.45608C11.4744 4.33919 11.5737 4.23942 11.6903 4.16316C11.8069 4.0869 11.9381 4.03584 12.0755 4.01324C12.213 3.99065 12.3537 3.99702 12.4885 4.03195C12.6234 4.06688 12.7494 4.1296 12.8586 4.21608C12.977 4.29178 13.0781 4.39159 13.1554 4.509C13.2326 4.62641 13.2843 4.75878 13.3069 4.89748C13.3295 5.03619 13.3227 5.1781 13.2868 5.31398C13.2509 5.44986 13.1868 5.57665 13.0986 5.68608L9.09861 11.1761L11.6786 13.5561L18.1186 4.67608C18.1952 4.55996 18.2951 4.46108 18.4121 4.38578C18.5291 4.31048 18.6604 4.2604 18.7978 4.23876C18.9352 4.21711 19.0757 4.22437 19.2101 4.26007C19.3445 4.29577 19.47 4.35912 19.5786 4.44608C19.6955 4.52185 19.7953 4.6212 19.8715 4.73777C19.9478 4.85434 19.9989 4.98556 20.0215 5.12301C20.044 5.26047 20.0377 5.40112 20.0027 5.53597C19.9678 5.67082 19.9051 5.79688 19.8186 5.90608Z"
        fill={fill}
      />
      <defs></defs>
    </svg>
  );
}
