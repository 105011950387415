import { gql } from "graphql-tag";
import { FormattedMessage } from "react-intl";

import ReadResult from "../../utils/ReadResult";
import * as TextField from "../../utils/TextField";
import useId from "../../utils/useId";
import RecipeEditorLabel from "./RecipeEditorLabel";
import { RecipeNameEditor_Recipe as Recipe } from "./RecipeNameEditor.graphql";

export const label = <Label />;

function Label() {
  return (
    <FormattedMessage
      id="components/recipes/RecipeEditor/RecipeNameEditor:label"
      defaultMessage="Name"
    />
  );
}

export interface Value {
  code: string;
  name: TextField.Value;
}

export interface Input {
  code: string | null;
  name: string;
}

interface RecipeNameEditorProps {
  onChange: (value: Value) => void;
  value: Value;
  placeholder: string;
}

export function RecipeNameEditor(props: RecipeNameEditorProps) {
  const { onChange, placeholder, value } = props;

  const nameInputId = useId();

  return (
    <>
      <div className="form-group" style={{ width: "560px" }}>
        <RecipeEditorLabel htmlFor={nameInputId}>{label}</RecipeEditorLabel>
        <TextField.TextField
          autoComplete="off"
          autoFocus
          id={nameInputId}
          onChange={(name) => onChange({ ...value, name })}
          placeholder={placeholder}
          value={value.name}
        />
      </div>
    </>
  );
}

export function initialValue(recipe: Recipe | undefined): Value {
  if (recipe === undefined) {
    return {
      code: "",
      name: TextField.initialValue(""),
    };
  } else {
    return {
      code: recipe.code ?? "",
      name: TextField.initialValue(recipe.name),
    };
  }
}

export function read(value: Value): ReadResult<Value, Input> {
  const nameReadResult = TextField.read(value.name);

  const newValue = { ...value, name: nameReadResult.value };

  if (nameReadResult.hasError) {
    return { hasError: true, value: newValue };
  } else {
    return {
      hasError: false,
      value: newValue,
      input: {
        code: value.code || null,
        name: nameReadResult.input,
      },
    };
  }
}

export const fragments = {
  recipe: gql`
    fragment RecipeNameEditor_Recipe on Recipe {
      code
      name
    }
  `,
};
