import { SecondaryButton } from "./Button";
import Stepper, { Step } from "./Stepper";

const stories = {
  name: "Stepper",
  children: [
    {
      name: "Default",
      render: () => <StepperStory />,
    },
    {
      name: "Unordered",
      render: () => <StepperStory enforceOrder={false} />,
    },
    {
      name: "Error state",
      render: () => <StepperStory hasError={true} />,
    },
  ],
};

interface StepperStoryProps {
  enforceOrder?: boolean;
  hasError?: boolean;
}

function StepperStory(props: StepperStoryProps) {
  const { enforceOrder, hasError = false } = props;

  const steps: Array<Step> = [
    {
      hasError,
      label: "Confirm your plan",
      render: () => <p className="mt-3">This is the first step.</p>,
      renderControls: ({ onBack, onNext }) => (
        <div className="text-right">
          {onNext !== null && (
            <SecondaryButton onClick={onNext}>Next</SecondaryButton>
          )}
        </div>
      ),
    },
    {
      hasError,
      label: "Set up your billing details",
      render: () => <p className="mt-3">This is the second step.</p>,
      renderControls: ({ onBack, onNext }) => (
        <div className="text-right">
          {onNext !== null && (
            <SecondaryButton onClick={onNext}>Next</SecondaryButton>
          )}
        </div>
      ),
    },
    {
      hasError,
      label: "Third Step",
      render: () => <p className="mt-3">This is the third step.</p>,
      renderControls: () => null,
    },
  ];
  return (
    <div className="m-4">
      <Stepper enforceOrder={enforceOrder} steps={steps} />
    </div>
  );
}

export default stories;
