import { gql } from "graphql-tag";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import * as comparators from "../../../util/comparators";
import sort from "../../../util/sort";
import * as FloatInput from "../../utils/FloatInput";
import ReadResult from "../../utils/ReadResult";
import Select from "../../utils/Select";
import TableEditor from "../../utils/TableEditor";
import { RecipeStorageEditor_StorageMethod as StorageMethod } from "./RecipeStorageEditor.graphql";

interface StoragePenalty {
  daysInStorage: number;
  id: number;
  storageMethod: StorageMethod;
}

export type Value = Array<EditorStoragePenalty>;

interface EditorStoragePenalty {
  key: string;
  storageMethod: StorageMethod | null;
  existingStoragePenalty: StoragePenalty | null;
  daysInStorage: FloatInput.Value;
  invalidStorageMethod: boolean;
}

let nextNewPenaltyId = 1;

export function initialBlankValue() {
  return [blankPenalty()];
}

export function initialValue(
  penalties: Array<StoragePenalty> | undefined
): Value {
  return penalties === undefined || penalties.length === 0
    ? initialBlankValue()
    : penalties.map(existingPenalty);
}

function blankPenalty(): EditorStoragePenalty {
  return {
    key: "new_" + nextNewPenaltyId++,
    storageMethod: null,
    existingStoragePenalty: null,
    daysInStorage: FloatInput.initialValue(null),
    invalidStorageMethod: false,
  };
}

function existingPenalty(penalty: StoragePenalty): EditorStoragePenalty {
  return {
    key: "existing_" + penalty.id,
    storageMethod: penalty.storageMethod,
    existingStoragePenalty: penalty,
    daysInStorage: FloatInput.initialValue(penalty.daysInStorage),
    invalidStorageMethod: false,
  };
}

interface StoragePenaltyInput {
  daysInStorage: number;
  id: number | null;
  storageMethodId: number;
}

export function read(
  value: Value
): ReadResult<Value, Array<StoragePenaltyInput>> {
  let hasError = false;
  const newValue: Array<EditorStoragePenalty> = [];
  const inputs: Array<StoragePenaltyInput> = [];
  for (const penalty of value) {
    const daysInStorage = FloatInput.read({ value: penalty.daysInStorage });
    if (penalty.storageMethod === null) {
      newValue.push({
        ...penalty,
        invalidStorageMethod: true,
        daysInStorage: daysInStorage.value,
      });
      hasError = true;
    } else {
      newValue.push({ ...penalty, daysInStorage: daysInStorage.value });

      if (daysInStorage.hasError) {
        hasError = true;
      } else {
        inputs.push({
          storageMethodId: penalty.storageMethod.id,
          id:
            penalty.existingStoragePenalty === null
              ? null
              : penalty.existingStoragePenalty.id,
          daysInStorage: daysInStorage.input,
        });
      }
    }
  }
  return { hasError, value: newValue, input: inputs };
}

interface RecipeStorageEditorProps {
  storageMethods: Array<StorageMethod>;
  onChange: (value: Value) => void;
  value: Value;
}

export function RecipeStorageEditor(props: RecipeStorageEditorProps) {
  const { storageMethods, onChange, value } = props;

  const handleStoragePenaltyChange = (value: Value) => {
    onChange(
      value.map((penalty) => {
        return {
          ...penalty,
          invalidStorageMethod: false,
          daysInStorage: { ...penalty.daysInStorage, isInvalid: false },
        };
      })
    );
  };

  return (
    <div className="w-75">
      <TableEditor
        blank={blankPenalty}
        onChange={handleStoragePenaltyChange}
        renderRow={({ onChange, onDelete, rowIndex, value }) => (
          <RecipeStoragePenaltyEditor
            canDelete={rowIndex !== 0 || storageMethods.length > 1}
            storageMethods={storageMethods}
            onChange={onChange}
            onDelete={onDelete}
            value={value}
          />
        )}
        showAddButton={true}
        value={value}
      />
    </div>
  );
}

interface RecipeStoragePenaltyEditorProps {
  canDelete: boolean;
  storageMethods: Array<StorageMethod>;
  onChange: (value: EditorStoragePenalty) => void;
  onDelete: () => void;
  value: EditorStoragePenalty;
}

function RecipeStoragePenaltyEditor(props: RecipeStoragePenaltyEditorProps) {
  const { canDelete, storageMethods, onChange, onDelete, value } = props;

  const deleteColumnWidth = "20px";

  return (
    <tr>
      <td style={{ width: "384px" }}>
        <StorageMethodSelect
          isInvalid={value.invalidStorageMethod}
          storageMethods={storageMethods}
          onChange={(storageMethod) => onChange({ ...value, storageMethod })}
          value={value.storageMethod}
        />
      </td>
      <td className="pl-3" style={{ width: "160px" }}>
        <div className="input-group">
          <FloatInput.FloatInput
            onChange={(daysInStorage) => onChange({ ...value, daysInStorage })}
            value={value.daysInStorage}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FormattedMessage
                id="components/recipes/RecipeEditor/RecipeStorageEditor:days"
                defaultMessage="days"
              />
            </span>
          </div>
        </div>
      </td>
      {canDelete ? (
        <TableEditor.DeleteCell
          className="pl-2"
          onDelete={onDelete}
          width={deleteColumnWidth}
        />
      ) : (
        <td style={{ width: deleteColumnWidth }}></td>
      )}
    </tr>
  );
}

interface StorageMethodSelectProps {
  isInvalid: boolean;
  storageMethods: Array<StorageMethod>;
  onChange: (value: StorageMethod | null) => void;
  value: StorageMethod | null;
}

function StorageMethodSelect(props: StorageMethodSelectProps) {
  const { isInvalid, storageMethods, onChange, value } = props;

  const sortedStorageMethods = useMemo(
    () =>
      sort(
        storageMethods,
        comparators.map(
          (storageMethod) => storageMethod.name,
          comparators.stringSensitivityBase
        )
      ),
    [storageMethods]
  );

  return (
    <Select
      className={isInvalid ? "is-invalid" : ""}
      onChange={(value) => onChange(value)}
      optionKey={storageMethodKey}
      options={sortedStorageMethods}
      renderOption={(storageMethod) => storageMethod.name}
      value={value}
    />
  );
}

function storageMethodKey(storageMethod: StorageMethod) {
  return storageMethod.id.toString();
}

export const fragments = {
  storageMethod: gql`
    fragment RecipeStorageEditor_StorageMethod on StorageMethod {
      id
      name
    }
  `,
};
