import { useState } from "react";

import RadioButtons from "./RadioButtons";

const stories = {
  name: "RadioButtons",
  children: [
    { name: "Default Radio Buttons", render: () => <Story /> },
    {
      name: "Inline Radio Buttons",
      render: () => <Story inline />,
    },
    {
      name: "Square Radio Buttons",
      render: () => <Story buttonStyle="square" />,
    },
    {
      name: "Square Inline Radio Buttons",
      render: () => <Story buttonStyle="square" inline />,
    },
  ],
};

interface Fruit {
  id: number;
  name: string;
}

const options = [
  { id: 1, name: "Apples" },
  { id: 2, name: "Bananas" },
  { id: 3, name: "Coconuts" },
];

function fruitKey(fruit: Fruit) {
  return fruit.id.toString();
}

function renderFruitLabel(fruit: Fruit) {
  return fruit.name;
}

interface StoryProps {
  buttonStyle?: "square" | "minimal";
  inline?: boolean;
}

function Story(props: StoryProps) {
  const { buttonStyle, inline } = props;

  const [value, setValue] = useState<Fruit | null>(null);
  const [hoverValue, setHoverValue] = useState<Fruit | null>(null);

  return (
    <RadioButtons
      buttonStyle={buttonStyle}
      hoverValue={hoverValue}
      inline={inline}
      onChange={(newValue) => setValue(newValue)}
      optionKey={fruitKey}
      options={options}
      renderOptionLabel={renderFruitLabel}
      setHoverLabel={setHoverValue}
      value={value}
    />
  );
}

export default stories;
