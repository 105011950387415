import actionModalStories from "./ActionModal.stories";
import autoSuggestStories from "./AutoSuggest.stories";
import backButtonStories from "./BackButton.stories";
import breadcrumbStories from "./Breadcrumb.stories";
import buttonStories from "./Button.stories";
import cardStories from "./Card.stories";
import checkboxStories from "./Checkbox.stories";
import deleteModalStories from "./DeleteModal.stories";
import dropdownStories from "./Dropdown.stories";
import errorAlertStories from "./ErrorAlert.stories";
import errorBoundaryStories from "./ErrorBoundary.stories";
import fileInputButtonStories from "./FileInputButton.stories";
import fitToSpaceStories from "./FitToSpace.stories";
import floatEditorStories from "./FloatEditor.stories";
import formStories from "./Form.stories";
import gridListToggleStories from "./GridListToggle.stories";
import helpModalTooltipStories from "./HelpModalTooltip.stories";
import informationModalTooltipStories from "./InformationModalTooltip.stories";
import labelledTextInputStories from "./LabelledTextInput.stories";
import panelStories from "./Panel.stories";
import passwordStrengthBarStories from "./PasswordStrengthBar.stories";
import plainTextStories from "./PlainText.stories";
import progressBarStories from "./ProgressBar.stories";
import questionMarkTooltipOverlayStories from "./QuestionMarkTooltipOverlay.stories";
import radioButtonsStories from "./RadioButtons.stories";
import radioToggleButtonGroupStories from "./RadioToggleButtonGroup.stories";
import rotatingChevronStories from "./RotatingChevron.stories";
import scrollTableStories from "./ScrollTable.stories";
import searchBoxStories from "./SearchBox.stories";
import selectAllCheckboxStories from "./select-all-checkbox/SelectAllCheckbox.stories";
import selectStories from "./Select.stories";
import selectFieldStories from "./SelectField.stories";
import spinnerStories from "./Spinner.stories";
import splitButtonStories from "./SplitButton.stories";
import stepperStories from "./Stepper.stories";
import summaryCardStories from "./SummaryCard.stories";
import sunburstStories from "./Sunburst.stories";
import tableStories from "./Table.stories";
import textFieldStories from "./TextField.stories";
import toastStories from "./Toast.stories";
import tooltipOverlayStories from "./TooltipOverlay.stories";
import upgradeRequestModalStories from "./UpgradeRequestModal.stories";
import vectorStories from "./Vectors/stories";
import weightInputStories from "./WeightInput.stories";

const stories = {
  name: "utils",
  children: [
    actionModalStories,
    autoSuggestStories,
    backButtonStories,
    breadcrumbStories,
    buttonStories,
    cardStories,
    checkboxStories,
    deleteModalStories,
    dropdownStories,
    errorAlertStories,
    errorBoundaryStories,
    fileInputButtonStories,
    fitToSpaceStories,
    gridListToggleStories,
    floatEditorStories,
    formStories,
    helpModalTooltipStories,
    informationModalTooltipStories,
    labelledTextInputStories,
    panelStories,
    passwordStrengthBarStories,
    plainTextStories,
    progressBarStories,
    questionMarkTooltipOverlayStories,
    radioButtonsStories,
    radioToggleButtonGroupStories,
    rotatingChevronStories,
    scrollTableStories,
    searchBoxStories,
    selectStories,
    selectAllCheckboxStories,
    selectFieldStories,
    spinnerStories,
    splitButtonStories,
    stepperStories,
    summaryCardStories,
    sunburstStories,
    tableStories,
    textFieldStories,
    toastStories,
    tooltipOverlayStories,
    upgradeRequestModalStories,
    vectorStories,
    weightInputStories,
  ],
};

export default stories;
