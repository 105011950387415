import classNames from "classnames";
import isFunction from "lodash/isFunction";
import React from "react";

import assertNever from "../../util/assertNever";
import {
  Button,
  ButtonLink,
  ButtonLinkProps,
  ButtonProps,
} from "../utils/Button";
import Dropdown, { DropdownProps } from "../utils/Dropdown";

import "./RecipesDropdown.css";

const ICON_WIDTH = 20;

type RecipesDropdownIcon = ({
  className,
  width,
}: {
  className?: string;
  width: number;
}) => JSX.Element;

export interface RecipesDropdownItem {
  action:
    | { type: "link"; to: ButtonLinkProps["to"] }
    | { type: "button"; onClick: NonNullable<ButtonProps["onClick"]> };
  Icon: RecipesDropdownIcon;
  render:
    | React.ReactNode
    | ((
        Component: ({
          children,
        }: {
          children: React.ReactNode;
        }) => React.ReactElement
      ) => React.ReactNode);
}

interface RecipesDropdownProps {
  className?: string;
  disabled?: boolean;
  Icon: RecipesDropdownIcon;
  items: Array<RecipesDropdownItem>;
  title: React.ReactNode;
  variant: DropdownProps["variant"];
}

export default function RecipesDropdown(props: RecipesDropdownProps) {
  const { className, disabled, Icon, items, title, variant } = props;

  const icon = <Icon width={ICON_WIDTH} />;

  return (
    <Dropdown
      alignRight
      className={classNames("RecipesDropdown px-3", className)}
      disabled={disabled}
      menuClassName="RecipesDropdown__menu"
      title={title}
      icon={icon}
      variant={variant}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <Dropdown.Divider />}

          <RecipesDropdownButton
            className="RecipesDropdown__dropdownItem"
            item={item}
          />
        </React.Fragment>
      ))}
    </Dropdown>
  );
}

interface RecipesDropdownButtonProps {
  className?: string;
  item: RecipesDropdownItem;
}

export function RecipesDropdownButton(props: RecipesDropdownButtonProps) {
  const { className, item } = props;

  const buttonProps = {
    className,
    icon: (
      <item.Icon className="RecipesDropdownButton__Icon" width={ICON_WIDTH} />
    ),
    variant: "link" as const,
  };

  const ItemButton = ({ children }: { children: React.ReactNode }) =>
    item.action.type === "link" ? (
      <ButtonLink to={item.action.to} {...buttonProps}>
        {children}
      </ButtonLink>
    ) : item.action.type === "button" ? (
      <Button onClick={item.action.onClick} {...buttonProps}>
        {children}
      </Button>
    ) : (
      assertNever(
        item.action,
        `unexpected recipes dropdown item action: ${item.action}`
      )
    );

  return isFunction(item.render) ? (
    item.render(ItemButton)
  ) : (
    <ItemButton>{item.render}</ItemButton>
  );
}
