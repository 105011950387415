import {
  RecipeCollectionFilter,
  RecipeFilter,
} from "../../__generated__/globalTypes";

export const collectionsOwnedByOrganizationOrInUserGroupFilter = (
  organizationId: string
): RecipeCollectionFilter => {
  return {
    anyOf: [
      { ownerOrganizations: { id: organizationId } },
      { includeCollectionsInUserGroup: true },
    ],
  };
};

export const recipesOwnedByOrganizationOrInUserGroupFilter = (
  organizationId: string
): RecipeFilter => {
  return {
    anyOf: [
      {
        collections: {
          anyOf: [
            { ownerOrganizations: { id: organizationId } },
            { includeCollectionsInUserGroup: true },
          ],
        },
      },
      {
        isInSomeCollection: false,
        ownerOrganizations: { id: organizationId },
      },
    ],
  };
};
