import { ImpactRating } from "../../../../__generated__/globalTypes";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { letterColor } from "./helperFunctions";
import { impactRatingToLetter } from "./translations";

interface RatingScaleProps {
  greyscale?: boolean;
  impactRating: ImpactRating;
  width: number;
}

export default function RatingScale(props: RatingScaleProps) {
  const { greyscale = false, impactRating, width } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 108 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <g clipPath="url(#clip0_148_1613)">
        <path
          d="M24.9098 40.8599C35.8782 40.8599 44.7698 31.9683 44.7698 20.9999C44.7698 10.0315 35.8782 1.13989 24.9098 1.13989C13.9414 1.13989 5.0498 10.0315 5.0498 20.9999C5.0498 31.9683 13.9414 40.8599 24.9098 40.8599Z"
          fill={darkTurquoise(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2598 1.47998C12.0398 3.19998 5.0498 11.28 5.0498 21C5.0498 31.97 13.9398 40.86 24.9098 40.86C27.8098 40.86 30.5698 40.23 33.0498 39.11L21.2598 1.47998Z"
          fill={foodstepsTurquoise(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M8.63008 32.3601H41.1901C43.2701 29.3801 44.5601 25.8101 44.7401 21.9501C43.8201 22.0901 42.9501 22.3801 42.1801 22.8101C41.8701 22.9801 41.4701 22.9801 41.1601 22.8101C40.0801 22.2101 38.8201 21.8601 37.4601 21.8601C36.1001 21.8601 34.8401 22.2101 33.7601 22.8101C33.4501 22.9801 33.0501 22.9801 32.7401 22.8101C31.6601 22.2101 30.4001 21.8601 29.0401 21.8601C27.6801 21.8601 26.4201 22.2101 25.3401 22.8101C25.0301 22.9801 24.6301 22.9801 24.3201 22.8101C23.2401 22.2101 21.9801 21.8601 20.6201 21.8601C19.2601 21.8601 18.0001 22.2101 16.9201 22.8101C16.6001 22.9801 16.2101 22.9801 15.9001 22.8101C14.8201 22.2101 13.5601 21.8601 12.2001 21.8601C10.8401 21.8601 9.58008 22.2101 8.50008 22.8101C8.18008 22.9801 7.79008 22.9801 7.48008 22.8101C6.75008 22.4101 5.94008 22.1201 5.08008 21.9701C5.27008 25.8201 6.56008 29.3801 8.63008 32.3601Z"
          fill={mint(greyscale)}
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M9.63013 32.2099C8.84013 32.2099 8.20013 31.5699 8.20013 30.7799V25.3999C8.20013 25.3199 8.13013 25.2499 8.05013 25.2499H2.82013C2.12013 25.2499 1.51013 24.8299 1.26013 24.1799C1.01013 23.5299 1.17013 22.8099 1.68013 22.3299L5.13013 19.1399H4.37013C3.67013 19.1399 3.06013 18.7199 2.81013 18.0699C2.56013 17.4199 2.72013 16.6999 3.23013 16.2199L6.25013 13.4199H6.05013C5.35013 13.4199 4.74013 12.9999 4.49013 12.3499C4.24013 11.6999 4.40013 10.9799 4.91013 10.4999L9.91013 5.87993C10.2201 5.58993 10.6301 5.42993 11.0501 5.42993C11.4701 5.42993 11.8801 5.58993 12.1901 5.87993L17.1901 10.4999C17.7001 10.9699 17.8701 11.6999 17.6101 12.3499C17.3601 12.9999 16.7401 13.4199 16.0501 13.4199H15.8501L18.8701 16.2199C19.3801 16.6899 19.5501 17.4199 19.2901 18.0699C19.0401 18.7199 18.4201 19.1399 17.7301 19.1399H16.9701L20.4201 22.3299C20.9301 22.7999 21.1001 23.5299 20.8401 24.1799C20.5901 24.8299 19.9701 25.2499 19.2801 25.2499H14.0501C13.9701 25.2499 13.9001 25.3199 13.9001 25.3999V30.7799C13.9001 31.5699 13.2601 32.2099 12.4701 32.2099H9.64013H9.63013Z"
          fill={mint(greyscale)}
        />
        <path
          d="M11.0502 6.45996C11.2202 6.45996 11.3802 6.51996 11.5102 6.63996L16.5102 11.26C16.9602 11.68 16.6702 12.44 16.0502 12.44H15.0302C14.4102 12.44 14.1102 13.2 14.5702 13.62L18.1902 16.97C18.6402 17.39 18.3502 18.15 17.7302 18.15H16.1502C15.5302 18.15 15.2302 18.91 15.6902 19.33L19.7302 23.07C20.1802 23.49 19.8902 24.25 19.2702 24.25H14.0402C13.4102 24.25 12.8902 24.76 12.8902 25.4V30.78C12.8902 31.02 12.7002 31.21 12.4602 31.21H9.63019C9.39019 31.21 9.20019 31.02 9.20019 30.78V25.4C9.20019 24.77 8.69019 24.25 8.05019 24.25H2.82019C2.20019 24.25 1.90019 23.49 2.36019 23.07L6.40019 19.33C6.85019 18.91 6.56019 18.15 5.94019 18.15H4.36019C3.74019 18.15 3.44019 17.39 3.90019 16.97L7.52019 13.62C7.97019 13.2 7.68019 12.44 7.06019 12.44H6.04019C5.42019 12.44 5.12019 11.68 5.58019 11.26L10.5802 6.63996C10.7102 6.51996 10.8802 6.45996 11.0402 6.45996M11.0502 4.45996C10.3702 4.45996 9.73019 4.70996 9.23019 5.16996L4.23019 9.78996C3.41019 10.54 3.15019 11.7 3.55019 12.73C3.72019 13.17 4.00019 13.54 4.34019 13.83L2.54019 15.5C1.72019 16.25 1.46019 17.41 1.86019 18.44C2.08019 19.01 2.48019 19.47 2.97019 19.76L0.99019 21.59C0.17019 22.34 -0.0898104 23.5 0.31019 24.53C0.71019 25.56 1.69019 26.23 2.81019 26.23H7.19019V30.75C7.19019 32.09 8.28019 33.18 9.62019 33.18H12.4502C13.7902 33.18 14.8802 32.09 14.8802 30.75V26.23H19.2602C20.3702 26.23 21.3502 25.56 21.7602 24.53C22.1602 23.49 21.9002 22.34 21.0802 21.59L19.1002 19.76C19.6002 19.46 19.9902 19.01 20.2102 18.44C20.6102 17.41 20.3502 16.25 19.5302 15.5L17.7302 13.83C18.0802 13.55 18.3502 13.17 18.5202 12.73C18.9202 11.7 18.6602 10.54 17.8402 9.78996L12.8402 5.16996C12.3402 4.70996 11.7002 4.45996 11.0202 4.45996H11.0502Z"
          fill="black"
        />
      </g>
      <rect x="31" y="1" width="76" height="40" rx="7" fill="white" />
      <rect
        x="31"
        y="1"
        width="76"
        height="40"
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <defs>
        <clipPath id="clip0_148_1613">
          <rect width="46" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = letterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 26.7;
  const ratingLetterXA = 49;
  const ratingLetterFontSize = 16;
  const distanceBetweenRatingLetters = 10;

  switch (impactRating) {
    case ImpactRating.VLOW:
      return (
        <>
          <rect
            x="39"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M62 13H70V29H62V13Z"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="72"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case ImpactRating.LOW:
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="49"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor(ImpactRating.LOW)}
            stroke="black"
            strokeWidth="2"
          />
          <rect
            x="72"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case ImpactRating.MEDIUM:
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="59"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            stroke="black"
            strokeWidth="2"
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case ImpactRating.HIGH:
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="58"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="69"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case ImpactRating.VHIGH:
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="58"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <path
            d="M68 13H76V29H68V13Z"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <rect
            x="79"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}
