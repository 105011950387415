import minBy from "lodash/minBy";
import CloseButton from "react-bootstrap/CloseButton";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";

import { MaintenanceWindow, useDataStore } from "../data-store";
import { useLocalStorageState } from "../services/LocalStorageService";
import StatusDisplay from "./StatusDisplay";
import usePromise from "./utils/usePromise";
import "./MaintenanceWindowNotice.css";

interface ContainerProps {
  children: React.ReactNode;
}

export function Container(props: ContainerProps) {
  const { children } = props;

  const { fetchMaintenanceWindows } = useDataStore();
  const [maintenanceWindowsStatus] = usePromise(fetchMaintenanceWindows);

  return (
    <StatusDisplay status={maintenanceWindowsStatus}>
      {(maintenanceWindows) => {
        const nextMaintenanceWindow = minBy(
          maintenanceWindows,
          (window) => window.start
        );
        if (nextMaintenanceWindow == null) {
          return children;
        } else if (nextMaintenanceWindow.isActive) {
          return (
            <MaintenanceInProgressBanner
              maintenanceWindow={nextMaintenanceWindow}
            />
          );
        } else {
          return (
            <div className="h-100 d-flex flex-column">
              <UpcomingMaintenanceBanner
                maintenanceWindow={nextMaintenanceWindow}
              />
              <div className="flex-fit">{children}</div>
            </div>
          );
        }
      }}
    </StatusDisplay>
  );
}

interface MaintenanceInProgressBannerProps {
  maintenanceWindow: MaintenanceWindow;
}

function MaintenanceInProgressBanner(props: MaintenanceInProgressBannerProps) {
  const { maintenanceWindow } = props;
  return (
    <Banner>
      <FormattedMessage
        defaultMessage="
          Maintenance in progress.
          The platform will be unavailable until {end}.
        "
        id="components/MaintenanceWindowNotice:currentMessage"
        values={{
          end: (
            <>
              <FormattedDate value={maintenanceWindow.end} />{" "}
              <FormattedTime value={maintenanceWindow.end} />
            </>
          ),
        }}
      />
    </Banner>
  );
}

interface UpcomingMaintenanceBannerProps {
  maintenanceWindow: MaintenanceWindow;
}

function UpcomingMaintenanceBanner(props: UpcomingMaintenanceBannerProps) {
  const { maintenanceWindow } = props;

  const [noticeClosed, setNoticeClosed] = useLocalStorageState(
    `upcoming_maintenance_notice_closed/${maintenanceWindow.start.getTime()}_${maintenanceWindow.end.getTime()}`
  );

  const handleClose = () => setNoticeClosed("true");

  return noticeClosed ? null : (
    <Banner>
      <div>
        <FormattedMessage
          defaultMessage="
          Upcoming scheduled maintenance: {start} to {end}.
          The platform will be unavailable during the maintenance.
        "
          id="components/MaintenanceWindowNotice:upcomingMessage"
          values={{
            start: (
              <>
                <FormattedDate value={maintenanceWindow.start} />{" "}
                <FormattedTime value={maintenanceWindow.start} />
              </>
            ),
            end: (
              <>
                <FormattedDate value={maintenanceWindow.end} />{" "}
                <FormattedTime value={maintenanceWindow.end} />
              </>
            ),
          }}
        />
      </div>

      <div>
        <CloseButton onClick={handleClose} aria-label="Close" />
      </div>
    </Banner>
  );
}

interface BannerProps {
  children: React.ReactNode;
}

function Banner(props: BannerProps) {
  const { children } = props;

  return (
    <div className="MaintenanceWindowNotice__Banner d-flex justify-content-between align-items-center flex-row px-4 py-3">
      {children}
    </div>
  );
}
