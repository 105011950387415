import classNames from "classnames";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { Currency } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import { useFormatCurrency } from "../../util/currencyFormatting";
import { LinkButton, PrimaryButton } from "../utils/Button";
import TextInput from "../utils/TextInput";
import useToggle from "../utils/useToggle";
import { ChevronUp, PromoCodeIcon } from "../utils/Vectors";
import { Chevron as ChevronDown } from "../utils/Vectors";

interface PromotionCodeSectionProps {
  appliedPromotionCode?: string;
  currency: Currency;
  discountAmount?: number;
  handleRemove: (() => void) | null;
  handleSubmit: (promotionCode: string | null) => Promise<void>;
  hasError: boolean;
  type: "applied" | "entry";
}

export default function PromotionCodeSection(props: PromotionCodeSectionProps) {
  const {
    appliedPromotionCode,
    currency,
    discountAmount,
    handleSubmit,
    handleRemove,
    hasError,
    type,
  } = props;
  const [promotionCode, setPromotionCode] = useState<string>("");
  const [showPromotionCodeEntry, toggleShowPromotionCodeEntry] =
    useToggle(false);

  const Chevron = showPromotionCodeEntry ? ChevronUp : ChevronDown;

  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  if (type === "entry") {
    return (
      <div>
        <hr className="mb-3" />
        <LinkButton onClick={toggleShowPromotionCodeEntry} className="mb-0 p-0">
          <div>
            <FormattedMessage
              id="components/subscriptions/PromotionCodeSection:gotAPromotionCodeMessage"
              defaultMessage="Got a promo code?"
            />
            <span className="pl-2">
              <Chevron className="semi-bold-font" width={9.75} />
            </span>
          </div>
        </LinkButton>
        {showPromotionCodeEntry && (
          <Row className={classNames("pr-3", hasError ? "pt-2" : "py-2")}>
            <Col className="w-100 pr-2">
              <TextInput
                hasError={hasError}
                onChange={(value) => setPromotionCode(value)}
                placeholder={intl.formatMessage({
                  id: "components/subscriptions/PromotionCodeSection:promotionCodeInputPlaceholder",
                  defaultMessage: "Promo Code",
                })}
                type="search"
                value={promotionCode}
              />
            </Col>
            <Col className="col-auto p-0">
              <div className="p-0">
                <PrimaryButton
                  className={classNames("text-center")}
                  disabled={promotionCode === ""}
                  fullWidth
                  onClick={() => handleSubmit(promotionCode)}
                >
                  <FormattedMessage
                    id="components/subscriptions/PromotionCodeSection:applyButton/submitLabel"
                    defaultMessage="Apply"
                  />
                </PrimaryButton>
              </div>
            </Col>
            <Row>
              <Col>
                {hasError && (
                  <div className="small text-danger pl-3 pt-3">
                    <FormattedMessage
                      id="components/subscriptions/PromotionCodeSection:promotionCodeInputErrorMessage"
                      defaultMessage="You entered an invalid promo code. Please check the code and try again."
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        )}
      </div>
    );
  } else if (type === "applied") {
    return (
      <div>
        <hr className="mb-3" />
        <Row className="pr-3">
          <Col>
            <div className="semi-bold-font pt-1">
              <FormattedMessage
                id="components/subscriptions/PromotionCodeSection:appliedPromotionCode"
                defaultMessage="Promo"
              />
              <span className="ml-1">
                (<PromoCodeIcon width={16} className="ml-1" />{" "}
                {appliedPromotionCode?.toUpperCase()})
              </span>
            </div>
          </Col>
          <Col className="col-3 fa-align-right p-0">
            <h4 className="mb-0 text-right">
              {intl.formatMessage(
                {
                  id: "components/subscriptions/PromotionCodeSection:appliedPromotionCodeDiscount",
                  defaultMessage: "- {formattedPrice}",
                },
                {
                  formattedPrice: formatCurrency(currency, discountAmount ?? 0),
                }
              )}
            </h4>
          </Col>
        </Row>
        {handleRemove !== null ? (
          <Row>
            <Col>
              <LinkButton onClick={handleRemove} className="border-0 p-0 pt-2">
                <FormattedMessage
                  id="components/subscriptions/PromotionCodeSection:removePromotionCode"
                  defaultMessage="Remove"
                />
              </LinkButton>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  } else {
    assertNever(type, "Unrecognised type");
  }
}
