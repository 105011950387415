import React from "react";

import "./SearchBox.css";
import { Search } from "./Vectors";

interface SearchBoxProps {
  autoFocus?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  placeholder: string;
  value: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export default function SearchBox(props: SearchBoxProps) {
  const {
    autoFocus = false,
    disabled = false,
    onChange,
    placeholder,
    value,
    onKeyDown,
    onClick,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="searchbox">
      <form
        autoComplete="off"
        className=""
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="input-field">
          <div className="icon-wrap ml-2">
            <Search className="SearchBox__Icon" width={20} />
          </div>
          <input
            disabled={disabled}
            autoFocus={autoFocus}
            id="search"
            type="text"
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            onKeyDown={onKeyDown}
            onClick={onClick}
          />
        </div>
      </form>
    </div>
  );
}
