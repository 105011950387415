import VectorProps from "./VectorProps";

export default function Shared(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=djPELc7GvHaLzkcp-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4671 2.56766C10.4671 1.63244 11.225 0.872445 12.1623 0.872445H14.8217C15.7569 0.872445 16.5169 1.63038 16.5169 2.56766V3.85493C16.5169 4.28613 16.1674 4.63568 15.7362 4.63568C15.305 4.63568 14.9554 4.28613 14.9554 3.85493V2.56766C14.9554 2.49386 14.8956 2.43395 14.8217 2.43395H12.1623C12.0885 2.43395 12.0286 2.49375 12.0286 2.56766V3.85493C12.0286 4.28613 11.679 4.63568 11.2478 4.63568C10.8166 4.63568 10.4671 4.28613 10.4671 3.85493V2.56766Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58735 2.56766C5.58735 1.63244 6.34528 0.872445 7.28256 0.872445H9.942C10.8772 0.872445 11.6372 1.63038 11.6372 2.56766V3.85493C11.6372 4.28613 11.2877 4.63568 10.8565 4.63568C10.4253 4.63568 10.0757 4.28613 10.0757 3.85493V2.56766C10.0757 2.49386 10.0159 2.43395 9.942 2.43395H7.28256C7.20876 2.43395 7.14885 2.49375 7.14885 2.56766V3.65393C7.14885 4.08513 6.7993 4.43468 6.3681 4.43468C5.9369 4.43468 5.58735 4.08513 5.58735 3.65393V2.56766Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.93725C0 0.868211 0.866376 0 1.93725 0H4.88167C5.9507 0 6.81891 0.866376 6.81891 1.93725V2.87513C6.81956 2.8881 6.81989 2.90112 6.81989 2.91417C6.81989 3.12166 6.98801 3.2899 7.19563 3.2899H17.9222C18.9913 3.2899 19.8595 4.15628 19.8595 5.22715V18.0628C19.8595 19.1318 18.9931 20 17.9222 20H1.93725C0.86821 20 0 19.1336 0 18.0628V1.93725ZM1.93725 1.56151C1.72963 1.56151 1.56151 1.72975 1.56151 1.93725V18.0628C1.56151 18.2704 1.72975 18.4385 1.93725 18.4385H17.9222C18.1298 18.4385 18.298 18.2703 18.298 18.0628V5.22715C18.298 5.01954 18.1297 4.85141 17.9222 4.85141H7.19563C6.14103 4.85141 5.28475 4.00938 5.25898 2.96275C5.25793 2.9463 5.25741 2.92977 5.25741 2.91319V1.93725C5.25741 1.72963 5.08916 1.56151 4.88167 1.56151H1.93725Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3416 15.0725C12.7405 15.0725 13.0523 14.7537 13.0523 14.3747C13.0523 13.9958 12.7405 13.677 12.3416 13.677C11.9427 13.677 11.6309 13.9958 11.6309 14.3747C11.6309 14.7537 11.9427 15.0725 12.3416 15.0725ZM14.6138 14.3747C14.6138 15.6289 13.59 16.634 12.3416 16.634C11.0931 16.634 10.0694 15.6289 10.0694 14.3747C10.0694 14.2842 10.0747 14.195 10.0851 14.1073C9.44777 13.7849 8.7375 13.4263 8.09996 13.1054C7.69211 13.4876 7.14263 13.7211 6.54096 13.7211C5.2925 13.7211 4.26877 12.716 4.26877 11.4619C4.26877 10.2077 5.2925 9.20264 6.54096 9.20264C7.12079 9.20264 7.65214 9.41944 8.05497 9.77729L10.0751 8.7094C10.0713 8.65617 10.0694 8.60242 10.0694 8.5482C10.0694 7.29406 11.0931 6.28898 12.3416 6.28898C13.59 6.28898 14.6138 7.29406 14.6138 8.5482C14.6138 9.80235 13.59 10.8074 12.3416 10.8074C11.7099 10.8074 11.1357 10.5501 10.7226 10.1334L8.79226 11.1538C8.80603 11.2545 8.81314 11.3573 8.81314 11.4619C8.81314 11.5456 8.80858 11.6282 8.7997 11.7094C9.44143 12.0324 10.1566 12.3935 10.7972 12.7176C11.2035 12.3434 11.7471 12.1155 12.3416 12.1155C13.59 12.1155 14.6138 13.1206 14.6138 14.3747ZM6.54096 12.1596C6.93989 12.1596 7.25164 11.8408 7.25164 11.4619C7.25164 11.0829 6.93989 10.7641 6.54096 10.7641C6.14203 10.7641 5.83028 11.0829 5.83028 11.4619C5.83028 11.8408 6.14203 12.1596 6.54096 12.1596ZM12.3416 9.24592C12.7405 9.24592 13.0523 8.92713 13.0523 8.5482C13.0523 8.16927 12.7405 7.85049 12.3416 7.85049C11.9427 7.85049 11.6309 8.16927 11.6309 8.5482C11.6309 8.92713 11.9426 9.24592 12.3416 9.24592Z"
      />
    </svg>
  );
}
