import VectorProps from "./VectorProps";

export default function SelectedCsvData(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library
  // https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA?node-id=628%3A684&main-component=1&fuid=1161596729658558833
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24027_36857)">
        <path
          d="M9.98002 13.7H4.93002C4.49002 13.7 4.13002 13.34 4.13002 12.9V11.28C4.13002 10.84 4.49002 10.48 4.93002 10.48C5.37002 10.48 5.73002 10.84 5.73002 11.28V12.1H9.18002V11.28C9.18002 10.84 9.54002 10.48 9.98002 10.48C10.42 10.48 10.78 10.84 10.78 11.28V12.9C10.78 13.34 10.42 13.7 9.98002 13.7Z"
          fill={fill}
        />
        <path
          d="M14.02 17.1H9.97002C9.53002 17.1 9.17002 16.74 9.17002 16.3V12.9C9.17002 12.46 9.53002 12.1 9.97002 12.1H14.02C15.01 12.1 15.82 12.91 15.82 13.9V15.3C15.82 16.29 15.01 17.1 14.02 17.1ZM10.77 15.5H14.02C14.13 15.5 14.22 15.41 14.22 15.3V13.9C14.22 13.79 14.13 13.7 14.02 13.7H10.77V15.5Z"
          fill={fill}
        />
        <path
          d="M9.98002 17.1H5.93002C4.94002 17.1 4.13002 16.29 4.13002 15.3V12.9C4.13002 12.46 4.49002 12.1 4.93002 12.1H9.98002C10.42 12.1 10.78 12.46 10.78 12.9V16.3C10.78 16.74 10.42 17.1 9.98002 17.1ZM5.73002 13.7V15.3C5.73002 15.41 5.82002 15.5 5.93002 15.5H9.18002V13.7H5.73002Z"
          fill={fill}
        />
        <path
          d="M16.73 19.8H3.31002C1.63002 19.8 0.26002 18.43 0.26002 16.75V11.28C0.26002 10.84 0.620019 10.48 1.06002 10.48C1.50002 10.48 1.86002 10.84 1.86002 11.28V16.75C1.86002 17.55 2.51002 18.2 3.31002 18.2H16.72C17.52 18.2 18.17 17.55 18.17 16.75V3.01997C18.17 2.21997 17.52 1.56997 16.72 1.56997H11.71C11.27 1.56997 10.91 1.20997 10.91 0.769971C10.91 0.329971 11.27 -0.0300293 11.71 -0.0300293H16.72C18.4 -0.0300293 19.77 1.33997 19.77 3.01997V16.75C19.77 18.43 18.4 19.8 16.72 19.8H16.73Z"
          fill={fill}
        />
        <path
          d="M18.96 6.11997H11.71C11.27 6.11997 10.91 5.75997 10.91 5.31997C10.91 4.87997 11.27 4.51997 11.71 4.51997H18.96C19.4 4.51997 19.76 4.87997 19.76 5.31997C19.76 5.75997 19.4 6.11997 18.96 6.11997Z"
          fill={fill}
        />
        <path
          d="M7.69002 9.44997H1.82002C0.83002 9.44997 0.0200195 8.64997 0.0200195 7.64997V1.78997C0.0200195 0.789971 0.83002 -0.0100293 1.82002 -0.0100293H7.69002C8.68002 -0.0100293 9.49002 0.799971 9.49002 1.78997V7.65997C9.49002 8.64997 8.68002 9.45997 7.69002 9.45997V9.44997ZM1.82002 1.58997C1.71002 1.58997 1.62002 1.67997 1.62002 1.78997V7.65997C1.62002 7.76997 1.71002 7.85997 1.82002 7.85997H7.69002C7.80002 7.85997 7.89002 7.76997 7.89002 7.65997V1.78997C7.89002 1.67997 7.80002 1.58997 7.69002 1.58997H1.82002Z"
          fill={fill}
        />
        <path
          d="M4.12002 6.79997C4.12002 6.79997 4.12002 6.79997 4.11002 6.79997C3.88002 6.79997 3.67002 6.68997 3.52002 6.51997L2.34002 5.13997C2.05002 4.79997 2.09002 4.29997 2.43002 4.00997C2.77002 3.71997 3.27002 3.75997 3.56002 4.09997L4.16002 4.79997L5.96002 2.84997C6.26002 2.51997 6.77002 2.49997 7.09002 2.79997C7.42002 3.09997 7.44002 3.60997 7.14002 3.92997L4.73002 6.54997C4.58002 6.71997 4.36002 6.80997 4.14002 6.80997L4.12002 6.79997Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_24027_36857">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
