import { CarbonLabelVectorProps } from "../../CarbonLabelVectorProps";
import {
  LETTER_RATING_BASE_SVG_WIDTH_PIXELS,
  LETTER_RATING_HEIGHT_TO_WIDTH_RATIO,
} from "../../constants";

export default function LetterRatingLabelHigh(props: CarbonLabelVectorProps) {
  const {
    className = "",
    handleClick = () => null,
    strokeWidth,
    width,
  } = props;
  // Design in https://www.figma.com/file/uUnr9LO10uWP7AQcqxb50z/Foodsteps-Carbon-Labels?type=design&node-id=534-60&t=3EeNAvWalE5zVE9R-0
  const scaleFactor = width / LETTER_RATING_BASE_SVG_WIDTH_PIXELS;
  const height = width * LETTER_RATING_HEIGHT_TO_WIDTH_RATIO;
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      onClick={handleClick}
      viewBox={`0 0 ${width / scaleFactor} ${height / scaleFactor}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_534_94)">
        <path
          d="M16.1594 31.0644C24.3134 31.0644 30.9235 24.4543 30.9235 16.3003C30.9235 8.14625 24.3134 1.53613 16.1594 1.53613C8.00543 1.53613 1.39531 8.14625 1.39531 16.3003C1.39531 24.4543 8.00543 31.0644 16.1594 31.0644Z"
          fill="#979797"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M28.6725 13.4645C28.2048 13.2306 27.737 13.2306 27.0353 13.9322C26.7371 14.2304 26.8014 14.517 26.8014 15.1017C26.8014 15.2841 26.6163 16.1281 26.4 16.2711C25.6 16.8 24.2322 16.5377 23.644 16.2711C23.1107 16.029 22.2968 16.1132 22.0068 16.6219C21.539 17.4405 21.8898 18.2591 22.7084 18.61C23.1891 18.8158 24.2913 18.8 24.4 20C24.485 20.9385 22.9423 21.0658 22.8254 22.3522C22.7084 23.6385 21.7857 24.2092 21.3694 25.2103C20.9531 26.2113 20.7941 26.7656 20.2947 27.417C19.6223 28.2929 18.4107 28.7139 17.3395 28.4438C16.9396 28.342 16.5549 28.1479 16.2765 27.8427C15.4474 26.9364 15.8134 25.512 15.6708 24.2911C15.555 23.3088 15.058 22.3756 14.3084 21.7312C13.4863 21.0249 12.4197 20.6857 11.476 20.1536C10.5323 19.6215 9.97678 18.8743 9.96275 17.7902C9.94871 16.6956 10.3428 15.3706 11.3661 14.9836C12.3893 14.5965 14.5142 14.4842 15.576 14.7497C16.9794 15.1005 18.8692 15.2128 19.6691 14.165C20.0047 13.7252 20.0889 12.9979 19.5521 12.5277C18.6166 11.7091 17.3302 12.7616 16.3946 12.4108C15.7023 12.1512 15.5304 10.9923 14.4066 11.0075C13.2828 11.0227 12.5823 11.0776 11.8338 11.8261C11.1322 12.5277 9.76745 12.5137 9.37803 11.4753C9.0272 10.5397 9.72418 10.0673 10.1966 9.7211C10.6691 9.37495 10.9915 8.91724 11.1306 8.34773C11.2066 8.03549 11.0152 6.68057 11.9508 6.21279C13.4044 5.48657 13.5085 7.73306 14.7574 7.26529C15.693 6.91445 15.431 5.96955 15.693 5.51113C16.1608 4.69253 16.7385 4.59313 17.3302 4.3417C18.5862 3.80727 19.4235 3.31143 20.0222 2.0461C25.9887 3.65875 30.4571 8.91653 30.8898 15.2841C30.8898 15.2841 29.1415 13.6972 28.6737 13.4633L28.6725 13.4645Z"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.71875 10.1865C2.74331 10.1842 2.76904 10.183 2.79359 10.1795C4.01331 10.0228 5.03189 9.91285 5.98732 9.13752C6.95678 8.35049 7.50993 7.28981 7.85842 6.33087C8.10049 5.66429 7.71341 5.06788 7.33334 4.46562C5.35232 5.94613 3.75604 7.91195 2.71992 10.1865H2.71875Z"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.93285 22.703C4.78199 21.9756 4.6136 21.4084 4.34813 20.715C4.04291 19.9198 4.13997 18.9222 4.12243 18.0709C4.11425 17.6744 3.9973 16.7389 3.64647 16.2711C3.04071 15.463 2.47704 14.9847 1.50407 14.5064C1.43273 15.0947 1.39531 15.6922 1.39531 16.3004C1.39531 19.8344 2.63842 23.0784 4.70949 25.6208C4.70949 25.6208 4.7703 25.5401 4.88841 25.3787C5.46261 24.5612 5.13633 23.6807 4.93285 22.703Z"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="21"
        y="1"
        width="24"
        height="30"
        rx="5"
        fill="#414141"
        stroke="black"
        strokeWidth={strokeWidth}
      />
      <path
        d="M30.5241 20.48H31.8441C34.7241 20.48 36.0841 18.86 36.0841 15.9V15.74C36.0841 12.8 34.8241 11.22 31.8041 11.22H30.5241V20.48ZM27.2841 23V8.7H31.9641C36.9841 8.7 39.4641 11.42 39.4641 15.7V15.86C39.4641 20.14 36.9641 23 31.9441 23H27.2841Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_534_94">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
