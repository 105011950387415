import { DataQualityScore } from "../../../domain/DataQualityScore";
import { ImpactCategory } from "../../../domain/impactCategories";
import assertNever from "../../../util/assertNever";

export default function impactCategoryDataQualityScore(
  dataQualityScore: DataQualityScore,
  impactCategory: ImpactCategory
): number {
  if (impactCategory === ImpactCategory.GHG) {
    return dataQualityScore.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return dataQualityScore.land_use;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return dataQualityScore.water_use;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory.");
  }
}
