import VectorProps from "../VectorProps";

export default function WaterUse(props: VectorProps) {
  const { width } = props;

  // from https://www.figma.com/file/Z5JvdIZfbok8AxNw5lmpCU/DQS?node-id=15663%3A7005&mode=dev

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1199 21.28C26.9078 21.28 31.5999 16.588 31.5999 10.8C31.5999 5.01206 26.9078 0.320007 21.1199 0.320007C15.3319 0.320007 10.6399 5.01206 10.6399 10.8C10.6399 16.588 15.3319 21.28 21.1199 21.28Z"
        fill="#67DBC1"
      />
      <path
        d="M4.0176 13.0512C4.0176 9.3104 8.3216 1.72 8.3216 1.72C8.7856 0.707203 10.376 0.707203 10.84 1.72C10.84 1.72 15.144 9.1824 15.144 13.0512C15.144 16.1248 12.6528 18.9984 9.5792 18.9984C6.5056 18.9984 4.0144 16.1248 4.0144 13.0512H4.0176Z"
        fill="#00A68F"
      />
      <path
        d="M9.58236 19.8784C7.86716 19.8784 6.23996 19.1296 4.99836 17.7712C3.81596 16.4768 3.13916 14.7568 3.13916 13.0512C3.13916 9.2048 7.20156 1.9168 7.54236 1.3152C7.91036 0.552002 8.68796 0.0800018 9.58396 0.0800018C10.48 0.0800018 11.256 0.550402 11.624 1.312C11.9744 1.9248 16.0288 9.0848 16.0288 13.0512C16.0288 14.7568 15.3504 16.4768 14.1696 17.7712C12.928 19.1296 11.3008 19.8784 9.58556 19.8784H9.58236ZM9.58236 1.84C9.41436 1.84 9.20636 1.904 9.12316 2.0864C9.11196 2.1088 9.10076 2.1312 9.08796 2.1536C9.07676 2.1728 8.01276 4.0544 6.96476 6.36C5.61116 9.3344 4.89756 11.6496 4.89756 13.0528C4.89756 15.752 7.08636 18.12 9.58236 18.12C12.0784 18.12 14.2672 15.752 14.2672 13.0528C14.2672 9.464 10.1216 2.2336 10.08 2.16C10.0672 2.136 10.0544 2.112 10.0416 2.0864C9.95836 1.9056 9.75036 1.84 9.58236 1.84Z"
        fill="black"
      />
      <path
        d="M8.9535 22.6672C8.9535 17.4 15.0143 6.71199 15.0143 6.71199C15.6671 5.28639 17.9087 5.28639 18.5615 6.71199C18.5615 6.71199 24.6223 17.2192 24.6223 22.6672C24.6223 26.9936 21.1151 31.04 16.7871 31.04C12.4591 31.04 8.9519 26.9936 8.9519 22.6672H8.9535Z"
        fill="#DCFFD1"
      />
      <path
        d="M16.7872 31.92C14.4752 31.92 12.2768 30.9072 10.5968 29.0704C8.99202 27.3136 8.07202 24.9792 8.07202 22.6672C8.07202 17.32 13.8016 7.0688 14.2304 6.3088C14.6848 5.3552 15.6608 4.7632 16.7872 4.7632C17.9136 4.7632 18.8864 5.3536 19.3408 6.3056C19.784 7.0768 25.5008 17.1488 25.5008 22.6672C25.5008 24.9792 24.5808 27.312 22.976 29.0704C21.2976 30.9088 19.0992 31.92 16.7872 31.92ZM16.7872 6.5232C16.44 6.5232 16.0016 6.6688 15.8144 7.0784C15.8032 7.1008 15.792 7.1232 15.7792 7.1456C15.72 7.2512 9.83202 17.6912 9.83202 22.6672C9.83202 26.6592 13.0816 30.16 16.7872 30.16C20.4928 30.16 23.7408 26.6592 23.7408 22.6672C23.7408 17.5184 17.8576 7.2544 17.7968 7.152C17.784 7.128 17.7712 7.104 17.7584 7.0784C17.5712 6.6688 17.1328 6.5232 16.7856 6.5232H16.7872Z"
        fill="black"
      />
    </svg>
  );
}
