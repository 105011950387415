import { FormattedMessage } from "react-intl";

import { LinkButton } from "../utils/Button";
import useRecipeLabel from "./useRecipeLabel";

import "./SelectAllCard.css";

interface SelectAllCardProps {
  includingUnseen: boolean;
  numberOfExcludedRecipes: number;
  numberOfRecipesOnPage: number;
  setIncludingUnseen: () => void;
  totalRecipesCount: number;
}

export default function SelectAllCard(props: SelectAllCardProps) {
  const {
    includingUnseen,
    numberOfExcludedRecipes,
    numberOfRecipesOnPage,
    setIncludingUnseen,
    totalRecipesCount,
  } = props;

  const recipeLabel = useRecipeLabel();

  const text = () => {
    if (includingUnseen) {
      if (numberOfExcludedRecipes === 0) {
        return (
          <FormattedMessage
            id="components/recipes/SelectAllCard:all"
            defaultMessage="All <medium>{recipesCount} {recipesLabel}</medium> are selected"
            values={{
              recipesCount: totalRecipesCount,
              recipesLabel: recipeLabel.pluralLowercase,
              medium: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
            }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id="components/recipes/SelectAllCard:notAll"
            defaultMessage="<medium>{recipesCount} {recipesLabel}</medium> are selected"
            values={{
              recipesCount: totalRecipesCount - numberOfExcludedRecipes,
              recipesLabel: recipeLabel.pluralLowercase,
              medium: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
            }}
          />
        );
      }
    } else {
      return (
        <FormattedMessage
          id="components/recipes/SelectAllCard:allInView"
          defaultMessage="All <medium>{recipesCount} {recipesLabel}</medium> in view are selected"
          values={{
            recipesCount: numberOfRecipesOnPage,
            recipesLabel: recipeLabel.pluralLowercase,
            medium: (chunks: React.ReactNode) => (
              <span className="medium-font">{chunks}</span>
            ),
          }}
        />
      );
    }
  };

  return (
    <div className="select-all-card">
      <div className="first-item">{text()}</div>
      <LinkButton className="p-0" onClick={setIncludingUnseen}>
        {includingUnseen ? (
          <FormattedMessage
            id="components/recipes/RecipeQueryControls:ClearSelection"
            defaultMessage="Clear selection"
          />
        ) : (
          <FormattedMessage
            id="components/recipes/RecipeQueryControls:SelectAllRecipes"
            defaultMessage="Select all {totalRecipesCount} {recipesLabel}"
            values={{
              recipesLabel: recipeLabel.pluralLowercase,
              totalRecipesCount,
            }}
          />
        )}
      </LinkButton>
    </div>
  );
}
