import { createContext, useContext, useState } from "react";

export interface ToastNotification {
  message: string | React.ReactNode;
  title: string | React.ReactNode;
}

interface ToastNotificationState {
  toastNotification: ToastNotification | null;
  showToastNotification: (notification: ToastNotification) => void;
  closeToastNotification: () => void;
}

const ToastNotificationContext = createContext<
  ToastNotificationState | undefined
>(undefined);

interface ToastNotificationProviderProps {
  children: React.ReactNode;
}

export default function ToastNotificationProvider(
  props: ToastNotificationProviderProps
) {
  const { children } = props;
  const [toastNotification, setToastNotification] =
    useState<ToastNotification | null>(null);

  const showToastNotification = (notification: ToastNotification) => {
    setToastNotification(notification);
  };

  const closeToastNotification = () => {
    setToastNotification(null);
  };

  return (
    <ToastNotificationContext.Provider
      value={{
        toastNotification,
        showToastNotification,
        closeToastNotification,
      }}
    >
      {children}
    </ToastNotificationContext.Provider>
  );
}

export function useToastNotification(): ToastNotificationState {
  const result = useContext(ToastNotificationContext);
  if (result === undefined) {
    throw new Error("missing provider for ToastNotificationContext");
  } else {
    return result;
  }
}
