import classNames from "classnames";

interface RecipeEditorLabelProps {
  children: React.ReactNode;
  className?: string;
  htmlFor?: React.LabelHTMLAttributes<HTMLLabelElement>["htmlFor"];
}

export default function RecipeEditorLabel(props: RecipeEditorLabelProps) {
  const { children, className, htmlFor } = props;

  return (
    <label
      className={classNames("h5 medium-font", className)}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}
