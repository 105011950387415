import {
  BillingInterval,
  Currency,
  RecipeAllowance,
  RecurringPrice,
  RecurringPrices,
  SubscribableTier,
  Tier,
} from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";

export function recipeAllowanceToNumber(
  recipeAllowance: RecipeAllowance
): number {
  if (recipeAllowance === RecipeAllowance.FIVE) {
    return 5;
  } else if (recipeAllowance === RecipeAllowance.TEN) {
    return 10;
  } else if (recipeAllowance === RecipeAllowance.FIFTY) {
    return 50;
  } else if (recipeAllowance === RecipeAllowance.ONE_HUNDRED) {
    return 100;
  } else if (recipeAllowance === RecipeAllowance.FIVE_HUNDRED) {
    return 500;
  } else if (recipeAllowance === RecipeAllowance.ONE_THOUSAND) {
    return 1000;
  } else {
    assertNever(recipeAllowance, "Invalid recipe allowance");
  }
}

export function tierToSubscribableTier(tier: Tier): SubscribableTier {
  if (tier === Tier.FOOD_SERVICE_BASIC) {
    return SubscribableTier.FOOD_SERVICE_BASIC;
  } else if (tier === Tier.FOOD_SERVICE_PREMIUM) {
    return SubscribableTier.FOOD_SERVICE_PREMIUM;
  } else {
    throw new Error(`tier is not subscribable: ${tier}`);
  }
}

export function deserializeTier(serializedTier: string): Tier | undefined {
  if (serializedTier === Tier.FOOD_SERVICE_FREE) {
    return Tier.FOOD_SERVICE_FREE;
  } else if (serializedTier === Tier.FOOD_SERVICE_BASIC) {
    return Tier.FOOD_SERVICE_BASIC;
  } else if (serializedTier === Tier.FOOD_SERVICE_PREMIUM) {
    return Tier.FOOD_SERVICE_PREMIUM;
  } else if (serializedTier === Tier.FOOD_SERVICE_ENTERPRISE) {
    return Tier.FOOD_SERVICE_ENTERPRISE;
  } else {
    return undefined;
  }
}

function priceForCurrency({
  price,
  currency,
}: {
  price: RecurringPrice;
  currency: Currency;
}): number {
  if (currency === Currency.GBP) {
    return price.gbp;
  } else if (currency === Currency.USD) {
    return price.usd;
  } else {
    assertNever(currency, "unknown currency");
  }
}

export function priceForBillingInterval({
  billingInterval,
  currency,
  prices,
}: {
  billingInterval: BillingInterval;
  prices: RecurringPrices;
  currency: Currency;
}): number {
  if (billingInterval === BillingInterval.MONTH) {
    return priceForCurrency({ currency, price: prices.month });
  } else if (billingInterval === BillingInterval.YEAR) {
    return priceForCurrency({ currency, price: prices.year });
  } else {
    assertNever(billingInterval, "unknown billing interval");
  }
}
