import VectorProps from "./VectorProps";

export default function Tag(props: VectorProps) {
  const { fill = "currentColor", width } = props;
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2.23636C20 1.64324 19.7645 1.07443 19.3454 0.655033C18.9263 0.23564 18.3578 2.76396e-05 17.7651 2.76396e-05H11.3798C10.9179 -0.00180536 10.4602 0.0875487 10.0329 0.262974C9.60559 0.4384 9.21705 0.69645 8.88953 1.02235L1.0356 8.88144C0.7074 9.20802 0.446947 9.5963 0.26922 10.024C0.0914934 10.4516 0 10.9102 0 11.3734C0 11.8365 0.0914934 12.2951 0.26922 12.7228C0.446947 13.1504 0.7074 13.5387 1.0356 13.8653L6.14385 18.9769C6.80714 19.6324 7.70186 20 8.63412 20C9.56639 20 10.4611 19.6324 11.1244 18.9769L18.9783 11.1178C19.304 10.79 19.5619 10.4013 19.7372 9.97365C19.9125 9.54605 20.0018 9.08805 20 8.62586V2.23636ZM18.244 8.85549C18.2466 9.06499 18.2053 9.2727 18.1228 9.46527C18.0403 9.65785 17.9185 9.83102 17.7651 9.97365L9.75155 18.0159C9.60823 18.1687 9.43513 18.2905 9.24294 18.3738C9.05075 18.457 8.84355 18.5 8.63412 18.5C8.4247 18.5 8.21749 18.457 8.0253 18.3738C7.83311 18.2905 7.66001 18.1687 7.51669 18.0159L2 12.5C1.84729 12.3566 1.72558 12.1834 1.64238 11.9911C1.55918 11.7987 1.51625 11.5914 1.51625 11.3818C1.51625 11.1723 1.55918 10.9649 1.64238 10.7726C1.72558 10.5803 1.84729 10.4071 2 10.2637L10.2082 2.23636C10.3507 2.08289 10.5238 1.96097 10.7162 1.87844C10.9087 1.79591 11.1162 1.75459 11.3256 1.75714L17.9247 1.75374C17.9679 1.7486 18.0117 1.75333 18.0527 1.76757C18.0938 1.7818 18.1311 1.80518 18.1619 1.83594C18.1926 1.86669 18.216 1.90403 18.2302 1.94513C18.2444 1.98623 18.2491 2.03002 18.244 2.07321V8.85549Z"
        fill={fill}
      />
    </svg>
  );
}
