import { useOrganizationOrNull } from "./organizations/OrganizationProvider";

export function useHasRecipePermissionAddAndEditProductsFeature(): boolean {
  const [organization] = useOrganizationOrNull();
  return (
    organization !== null &&
    organization.viewerHasRecipePermissionAdd &&
    organization.features.editProducts
  );
}

export function useHasRecipeCollectionPermissionAdd(): boolean {
  const [organization] = useOrganizationOrNull();
  return (
    organization !== null && organization.viewerHasRecipeCollectionPermissionAdd
  );
}
