import classNames from "classnames";

export type TextInputType = "text" | "password" | "search";

interface TextInputProps {
  autoComplete?: "off";
  autoFocus?: boolean;
  className?: string;
  hasError?: boolean;
  id?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  type?: TextInputType;
  value: string;
}

export default function TextInput(props: TextInputProps) {
  const {
    autoComplete,
    autoFocus,
    className,
    hasError,
    id,
    onBlur = () => {},
    onChange,
    onFocus = () => {},
    placeholder,
    type = "text",
    value,
  } = props;
  return (
    <input
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      id={id}
      type={type}
      onBlur={onBlur}
      onChange={(event) => onChange(event.target.value)}
      onFocus={onFocus}
      className={classNames("form-control", className, {
        "is-invalid": hasError,
      })}
      placeholder={placeholder}
      value={value}
    />
  );
}
