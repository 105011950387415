import classNames from "classnames";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import "../../App.scss";
import { useSession } from "../../sessions";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { LinkButton } from "../utils/Button";
import Form from "../utils/Form";
import {
  PasswordStrengthSuggestions,
  useCheckPasswordStrengthPromise,
} from "../utils/passwordStrength";
import PasswordStrengthBar from "../utils/PasswordStrengthBar";
import useId from "../utils/useId";
import usePromise from "../utils/usePromise";
import "./ResetPassword.css";

export default function ResetPassword() {
  const { resetPassword } = useSession();
  const pages = usePages();
  const { uid, token } = pages.ResetPassword.useParams();

  const [state, setState] = useState({
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [resetSuccessful, setResetSuccessful] = useState<boolean>(false);
  const [showNewResetPasswordLink, setShowNewResetPasswordLink] =
    useState<boolean>(false);
  const [checkPasswordStrengthStatus] = usePromise(
    useCheckPasswordStrengthPromise
  );
  const newPasswordComponentId = useId();
  const [showPasswordStrengthBar, setShowPasswordStrengthBar] = useState(false);

  const inputForm = () => {
    let newPasswordData = {
      newPassword: state.newPassword,
      newPasswordConfirm: state.newPasswordConfirm,
      userIdToken: uid,
      challengeToken: token,
    };

    return (
      <StatusDisplay status={checkPasswordStrengthStatus}>
        {(checkPasswordStrength) => {
          const passwordStrength = checkPasswordStrength({
            password: state.newPassword,
            userInputs: [],
          });

          return (
            <Form
              className="col-7 mx-auto mt-2 p-0"
              onSubmit={async () => {
                try {
                  await resetPassword(newPasswordData);
                  setResetSuccessful(true);
                  setShowNewResetPasswordLink(false);
                } catch (e) {
                  setShowNewResetPasswordLink(true);
                  throw e;
                }
              }}
            >
              <label
                className={classNames(
                  "new-password-label ResetPassword_PasswordStrengthBar float-right mb-2",
                  {
                    ResetPassword_PasswordStrengthBar__visible:
                      showPasswordStrengthBar,
                  }
                )}
                htmlFor={newPasswordComponentId}
              >
                <PasswordStrengthBar
                  barClassName="ResetPassword__passwordStrengthIndicatorBar"
                  strength={passwordStrength.strength}
                  style={{
                    minHeight: "2em",
                  }}
                />
              </label>

              <input
                className="form-control no-radius-bottom m-0 bg-white"
                id={newPasswordComponentId}
                onBlur={() =>
                  state.newPassword === "" && setShowPasswordStrengthBar(false)
                }
                onChange={(event) =>
                  setState({ ...state, newPassword: event.target.value })
                }
                onFocus={() => setShowPasswordStrengthBar(true)}
                placeholder="New password"
                type="password"
                value={state.newPassword}
              />

              <input
                placeholder="Confirm new password"
                type="password"
                onChange={(event) =>
                  setState({ ...state, newPasswordConfirm: event.target.value })
                }
                value={state.newPasswordConfirm}
                className="form-control no-radius-top border-top-0 h6 small m-0 bg-white"
              />

              <PasswordStrengthSuggestions
                className="mt-3"
                show
                suggestions={passwordStrength.suggestions}
              />

              <Form.ErrorAlert className="mt-4" />

              {showNewResetPasswordLink && (
                <a
                  className="d-flex mt-4"
                  style={{ justifyContent: "center" }}
                  href={`${
                    (window as any).FOODSTEPS_PLATFORM_URI
                  }forgot-password`}
                >
                  <FormattedMessage
                    id="sessions/ServerSessionProvider:newResetPasswordLink"
                    defaultMessage="Request a new password reset email"
                  />
                </a>
              )}

              <div className="mt-4">
                <Form.SubmitButton
                  fullWidth
                  loadingLabel="Updating"
                  submitLabel="Change password"
                />
              </div>
            </Form>
          );
        }}
      </StatusDisplay>
    );
  };

  return (
    <>
      <div className="container-fluid bg-light vh-100 pt-5">
        <div className="row vh-50 justify-content-center pt-5">
          <div className="col-lg-5 col-md-8 rounded-lg bg-white p-5 shadow">
            <h2 className="mb-0 text-center">Reset your password</h2>
            <hr className="m-3" />
            <div className="col justify-content-center">
              {resetSuccessful ? (
                <p className="text-success my-0 text-center">
                  Password updated
                  <br />
                  <Link to="">
                    <LinkButton size="sm">Log in</LinkButton>
                  </Link>
                </p>
              ) : (
                inputForm()
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
