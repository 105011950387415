import VectorProps from "./VectorProps";

export default function AttentionTriangle(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?type=design&node-id=1-67&mode=design&t=7qxAY35ywzlCPP0p-0
  return (
    <svg
      className={className}
      fill="none"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06719 3.10345C8.92621 1.63218 11.0738 1.63218 11.9328 3.10345L18.6977 14.6897C19.5567 16.1609 18.4829 18 16.7648 18H3.23515C1.5171 18 0.443308 16.1609 1.30234 14.6897L8.06719 3.10345Z"
        fill="#F0E52B"
      />
      <path
        d="M10 15.1715C9.40659 15.1715 8.92 14.7358 8.92 14.1854C8.92 13.6235 9.40659 13.1878 10 13.1878C10.5934 13.1878 11.08 13.6235 11.08 14.1854C11.08 14.7358 10.5934 15.1715 10 15.1715ZM9.31165 12.5112L9.05055 6.86957H10.9732L10.7121 12.5112H9.31165Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0533 2.73569L19.5344 13.8391C20.8492 16.0918 19.2373 19 16.4811 19H3.51895C0.762735 19 -0.849228 16.0918 0.465638 13.8391L6.94669 2.73569C8.29751 0.421439 11.7025 0.421435 13.0533 2.73569ZM11.8517 3.3908C11.0287 1.98084 8.97126 1.98084 8.14827 3.39081L1.66722 14.4943C0.844227 15.9042 1.87297 17.6667 3.51895 17.6667H16.4811C18.127 17.6667 19.1558 15.9042 18.3328 14.4943L11.8517 3.3908Z"
        fill="black"
      />
    </svg>
  );
}
