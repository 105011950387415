import classNames from "classnames";

import { flattenStatuses, Status } from "../util/statuses";
import ErrorAlert from "./utils/ErrorAlert";
import Spinner from "./utils/Spinner";
import "./StatusDisplay.css";

interface StatusDisplayProps<T> {
  children: (value: T) => React.ReactNode;
  className?: string;
  status: Status<T>;
}

export default function StatusDisplay<T>(props: StatusDisplayProps<T>) {
  const { children, className, status } = props;

  if (status.type === "success") {
    return <>{children(status.value)}</>;
  } else if (status.type === "loading") {
    return (
      <div
        className={classNames("StatusDisplay_loading text-center", className)}
      >
        <Spinner />
      </div>
    );
  } else {
    return <ErrorAlert error={status.error} />;
  }
}

interface StatusDisplayManyProps<T extends Array<unknown>> {
  children: (...value: T) => React.ReactNode;
  statuses: { [K in keyof T]: Status<T[K]> };
}

StatusDisplay.Many = function <T extends Array<unknown>>(
  props: StatusDisplayManyProps<T>
) {
  const { children, statuses } = props;
  const status = flattenStatuses<T>(statuses);

  return (
    <StatusDisplay status={status}>
      {(value) => children(...value)}
    </StatusDisplay>
  );
};
