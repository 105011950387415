import Breadcrumb from "./Breadcrumb";

const stories = {
  name: "Breadcrumb",
  render: () => (
    <Breadcrumb
      pages={[
        { title: "One", url: "/one" },
        { title: "Two", url: "/one/two" },
        { title: "Three", url: "/one/two/three" },
      ]}
    />
  ),
};

export default stories;
