import { Tag } from "./Tag";

const stories = {
  name: "Tag",
  render: () => (
    <div className="m-2">
      <Tag text={"Tag Name"} />
    </div>
  ),
};

export default stories;
