import VectorProps from "./VectorProps";

export default function CsvData(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library
  // https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA?node-id=628%3A684&main-component=1&fuid=1161596729658558833
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 200 200"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m146.13,115h-40.47v-23.85c0-5.92-4.82-10.74-10.74-10.74h-40.84c-5.92,0-10.74,4.82-10.74,10.74v58.7c0,5.92,4.82,10.74,10.74,10.74h92.06c5.92,0,10.74-4.82,10.74-10.74v-24.1c0-5.92-4.82-10.74-10.74-10.74Zm-91.8-23.59h40.33v23.59h-40.33v-23.59Zm0,34.59h40.21v23.59h-40.21v-23.59Zm91.54,23.59h-40.21v-23.59h40.21v23.59Z" />
      <path d="m168.74,1.81H32.36C16.72,1.81,4,14.54,4,30.18v139.65c0,15.64,12.72,28.36,28.36,28.36h136.37c15.64,0,28.36-12.72,28.36-28.36V30.18c0-15.64-12.72-28.36-28.36-28.36ZM32.36,12.81h136.37c9.57,0,17.36,7.79,17.36,17.36v17.87H15v-17.87c0-9.57,7.79-17.36,17.36-17.36Zm136.37,174.38H32.36c-9.57,0-17.36-7.79-17.36-17.36V59.05h171.1v110.78c0,9.57-7.79,17.36-17.36,17.36Z" />
    </svg>
  );
}
