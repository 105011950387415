import useDataQualityRatingToString from "./useDataQualityScoreRatingToString";

export enum DataQualityScoreRating {
  UNSUITABLE = "UNSUITABLE",
  POOR = "POOR",
  FAIR = "FAIR",
  GOOD = "GOOD",
  VERY_GOOD = "VERY_GOOD",
}

export default function useDataQualityScoreToRatingString(): (
  score: number
) => string {
  const dataQualityRatingToString = useDataQualityRatingToString();
  return (score: number) => {
    if (score <= 5 && score > 4) {
      return dataQualityRatingToString(DataQualityScoreRating.VERY_GOOD);
    } else if (score <= 4 && score > 3) {
      return dataQualityRatingToString(DataQualityScoreRating.GOOD);
    } else if (score <= 3 && score > 2) {
      return dataQualityRatingToString(DataQualityScoreRating.FAIR);
    } else if (score <= 2 && score > 1) {
      return dataQualityRatingToString(DataQualityScoreRating.POOR);
    } else if (score <= 1 && score >= 0) {
      return dataQualityRatingToString(DataQualityScoreRating.UNSUITABLE);
    } else {
      throw new Error("Invalid data quality score");
    }
  };
}
