import { EffectType } from "../../__generated__/globalTypes";
import { FunctionalUnit } from "../../domain/functionalUnits";
import assertNever from "../../util/assertNever";
import { LabelSizeOption, LabelType } from "./labelTypes";

export type CarbonLabelDisplayedSizeOption =
  | "Original"
  | "Medium"
  | "Large"
  | "Extra Large";

export type CarbonLabelDisplayedType =
  | "Descriptive Impact & Scale"
  | "Descriptive Rating"
  | "Descriptive Rating Scale"
  | "Letter Rating"
  | "Rating Scale"
  | "Titled Rating Scale";

export const ghgMagnitude = (
  isHotDrink: boolean,
  effects: {
    ghgPerRootRecipeServing: number | null;
    ghgPerKg: number | null;
  } | null
): number => {
  return isHotDrink ? effects!.ghgPerRootRecipeServing! : effects!.ghgPerKg!;
};

export const landUseMagnitude = (
  isHotDrink: boolean,
  effects: {
    landUsePerRootRecipeServing: number | null;
    landUsePerKg: number | null;
  } | null
): number | null => {
  return isHotDrink
    ? effects!.landUsePerRootRecipeServing
    : effects!.landUsePerKg;
};

export const effectType = (isHotDrink: boolean): EffectType => {
  return isHotDrink ? EffectType.GHG_PER_SERVING : EffectType.GHG_PER_KG;
};

export const functionalUnit = (isHotDrink: boolean): FunctionalUnit => {
  return isHotDrink ? FunctionalUnit.PORTION : FunctionalUnit.KG;
};

export function labelSizeToDisplayedSize(
  labelSize: LabelSizeOption
): CarbonLabelDisplayedSizeOption {
  if (labelSize === "small") {
    return "Original";
  } else if (labelSize === "medium") {
    return "Medium";
  } else if (labelSize === "large") {
    return "Large";
  } else if (labelSize === "x-large") {
    return "Extra Large";
  } else {
    assertNever(labelSize, "Unsupported LabelSizeOption");
  }
}

export function labelTypeToDisplayedType(
  labelType: LabelType
): CarbonLabelDisplayedType {
  if (labelType === "descriptiveImpactAndScale") {
    return "Descriptive Impact & Scale";
  } else if (labelType === "descriptiveRating") {
    return "Descriptive Rating";
  } else if (labelType === "descriptiveRatingScale") {
    return "Descriptive Rating Scale";
  } else if (labelType === "letterRating") {
    return "Letter Rating";
  } else if (labelType === "ratingScale") {
    return "Rating Scale";
  } else if (labelType === "titledRatingScale") {
    return "Titled Rating Scale";
  } else {
    assertNever(labelType, "Unsupported LabelType");
  }
}
