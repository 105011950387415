import gql from "graphql-tag";
import { useHistory } from "react-router";

import { useEditProducts } from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import * as statuses from "../../util/statuses";
import useMutation from "../graphql/useMutation";
import usePagedQuery from "../graphql/usePagedQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { PackagingComponentsListPageQueryParams, usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { PrimaryButtonLink } from "../utils/Button";
import Card from "../utils/Card";
import Add from "../utils/Vectors/Add";
import PackagingComponentTable from "./PackagingComponentTable";
import {
  PackagingPage_PackagingComponentsQuery as PackagingComponentsQuery,
  PackagingPage_PackagingComponentsQueryVariables as PackagingComponentsQueryVariables,
  PackagingPage_DeletePackagingComponentV2 as DeletePackagingComponentV2,
  PackagingPage_DeletePackagingComponentV2Variables as DeletePackagingComponentV2Variables,
  PackagingPage__PackagingComponentV2 as PackagingComponentV2,
} from "./PackagingPage.graphql";
import * as PackagingPageLocationState from "./PackagingPageLocationState";
import { PackagingQueryControls } from "./PackagingQueryControls";
import "./PackagingPage.css";

export const packagingComponentPageSize = 10000;

const tracking = { pageName: "packaging" };

interface PackagingPageProps {}

export function PackagingPage(props: PackagingPageProps) {
  const history = useHistory();
  const pages = usePages();
  const [queryParams, setQueryParams] =
    pages.PackagingComponentsListPage.useQueryParams();
  const [organizationId] = useOrganizationId();
  const editProducts = useEditProducts();

  const packagingComponentsFilter = {
    organizationId,
    search: queryParams.searchTerm === "" ? undefined : queryParams.searchTerm,
  };

  const {
    status: packagingComponentsStatus,
    refresh: refreshPackagingComponentsStatus,
  } = usePagedQuery<
    PackagingComponentsQuery,
    PackagingComponentsQueryVariables,
    PackagingComponentsQuery["packagingComponents"]["edges"][0]["node"]
  >(
    packagingComponentsQuery,
    {
      first: 10000,
      after: null,
      filter: packagingComponentsFilter,
      orderBy: queryParams.orderBy,
    },
    (queryResult) => queryResult.packagingComponents
  );

  const { trackPackagingComponentDeleted } = useTracking();

  const [callDeletePackagingComponentMutation] = useMutation<
    DeletePackagingComponentV2,
    DeletePackagingComponentV2Variables
  >(deletePackagingComponentMutation);

  async function handleDelete(packagingComponent: PackagingComponentV2) {
    await callDeletePackagingComponentMutation({
      variables: { input: { id: packagingComponent.id } },
    });
    trackPackagingComponentDeleted({});
    await refreshPackagingComponentsStatus();
  }

  return (
    <Page tracking={tracking}>
      <div className="d-flex flex-column h-100">
        <Page.Title breadcrumb={pages.Packaging().breadcrumb()} />
        <div className="d-flex justify-content-between mb-3 flex-row">
          <PackagingQueryControls
            onChange={setQueryParams}
            value={queryParams}
          />
          {editProducts && (
            <AddPackagingComponentButton queryParams={queryParams} />
          )}
        </div>
        <Card className="PackagingPage__packagingComponentTableCard">
          <StatusDisplay
            status={statuses.map(
              packagingComponentsStatus,
              (status) => status.nodes
            )}
          >
            {(packagingComponents) => (
              <PackagingComponentTable
                handleDelete={handleDelete}
                handleEdit={(component) =>
                  history.push(
                    PackagingPageLocationState.queryParamsToLocationDescriptor(
                      pages.PackagingComponentsEdit.url({
                        id: component.id,
                      }),
                      queryParams
                    )
                  )
                }
                packagingComponents={packagingComponents}
              />
            )}
          </StatusDisplay>
        </Card>
      </div>
    </Page>
  );
}

interface AddPackagingComponentButtonProps {
  queryParams: PackagingComponentsListPageQueryParams;
}

function AddPackagingComponentButton(props: AddPackagingComponentButtonProps) {
  const { queryParams } = props;

  const pages = usePages();

  return (
    <PrimaryButtonLink
      icon={<Add width="1.2em" />}
      to={PackagingPageLocationState.queryParamsToLocationDescriptor(
        pages.PackagingComponentsNew.url,
        queryParams
      )}
    >
      {pages.addPackagingComponentTitle}
    </PrimaryButtonLink>
  );
}

PackagingPage.fragments = {
  packagingComponent: gql`
    fragment PackagingPage__PackagingComponentV2 on PackagingComponentV2 {
      id
    }
  `,
};

const packagingComponentsQuery = gql`
  query PackagingPage_PackagingComponentsQuery(
    $filter: PackagingComponentV2Filter
    $orderBy: PackagingComponentV2Order
    $after: String
    $first: Int
  ) {
    packagingComponents(
      filter: $filter
      orderBy: $orderBy
      after: $after
      first: $first
    ) {
      edges {
        node {
          ...PackagingComponentTable__PackagingComponentV2
          ...PackagingPage__PackagingComponentV2
        }
      }

      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${PackagingComponentTable.fragments.packagingComponent}
  ${PackagingPage.fragments.packagingComponent}
`;

const deletePackagingComponentMutation = gql`
  mutation PackagingPage_DeletePackagingComponentV2(
    $input: DeletePackagingComponentV2Input!
  ) {
    deletePackagingComponentV2(input: $input) {
      success
    }
  }
`;
