import VectorProps from "../VectorProps";

export default function Farm(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=9G2APmKSJ08dQcJ1-0
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_1593)">
        <path
          d="M14.0801 28.8C19.868 28.8 24.5601 24.1079 24.5601 18.32C24.5601 12.5321 19.868 7.84 14.0801 7.84C8.29215 7.84 3.6001 12.5321 3.6001 18.32C3.6001 24.1079 8.29215 28.8 14.0801 28.8Z"
          fill="#67DCC2"
        />
        <path
          d="M21.1392 2.53922H22.504V3.90402C22.504 6.11202 20.712 7.90402 18.504 7.90402H17.1392V6.53922C17.1392 4.33122 18.9312 2.53922 21.1392 2.53922Z"
          fill="#DCFFD1"
        />
        <path
          d="M18.5041 8.78401H17.1393C16.6529 8.78401 16.2593 8.39041 16.2593 7.90401V6.53921C16.2593 3.84801 18.4481 1.65921 21.1393 1.65921H22.5041C22.9905 1.65921 23.3841 2.05281 23.3841 2.53921V3.90401C23.3841 6.59521 21.1953 8.78401 18.5041 8.78401ZM18.0193 7.02401H18.5041C20.2241 7.02401 21.6241 5.62401 21.6241 3.90401V3.41921H21.1393C19.4193 3.41921 18.0193 4.81921 18.0193 6.53921V7.02401Z"
          fill="black"
        />
        <path
          d="M13.8655 7.9024H12.5007C10.2927 7.9024 8.50068 6.1104 8.50068 3.9024V2.5376H9.86548C12.0735 2.5376 13.8655 4.3296 13.8655 6.5376V7.9024Z"
          fill="#DCFFD1"
        />
        <path
          d="M13.8639 8.78401H12.4991C9.80794 8.78401 7.61914 6.59521 7.61914 3.90401V2.53921C7.61914 2.05281 8.01274 1.65921 8.49914 1.65921H9.86394C12.5551 1.65921 14.7439 3.84801 14.7439 6.53921V7.90401C14.7439 8.39041 14.3503 8.78401 13.8639 8.78401ZM9.37914 3.41921V3.90401C9.37914 5.62401 10.7791 7.02401 12.4991 7.02401H12.9839V6.53921C12.9839 4.81921 11.5839 3.41921 9.86394 3.41921H9.37914Z"
          fill="black"
        />
        <path
          d="M21.1392 9.38721H22.504V10.752C22.504 12.96 20.712 14.752 18.504 14.752H17.1392V13.3872C17.1392 11.1792 18.9312 9.38721 21.1392 9.38721Z"
          fill="#DCFFD1"
        />
        <path
          d="M18.5041 15.632H17.1393C16.6529 15.632 16.2593 15.2384 16.2593 14.752V13.3872C16.2593 10.696 18.4481 8.5072 21.1393 8.5072H22.5041C22.9905 8.5072 23.3841 8.9008 23.3841 9.3872V10.752C23.3841 13.4432 21.1953 15.632 18.5041 15.632ZM18.0193 13.872H18.5041C20.2241 13.872 21.6241 12.472 21.6241 10.752V10.2672H21.1393C19.4193 10.2672 18.0193 11.6672 18.0193 13.3872V13.872Z"
          fill="black"
        />
        <path
          d="M13.8655 14.752H12.5007C10.2927 14.752 8.50068 12.96 8.50068 10.752V9.38721H9.86548C12.0735 9.38721 13.8655 11.1792 13.8655 13.3872V14.752Z"
          fill="#DCFFD1"
        />
        <path
          d="M13.8639 15.632H12.4991C9.80794 15.632 7.61914 13.4432 7.61914 10.752V9.3872C7.61914 8.9008 8.01274 8.5072 8.49914 8.5072H9.86394C12.5551 8.5072 14.7439 10.696 14.7439 13.3872V14.752C14.7439 15.2384 14.3503 15.632 13.8639 15.632ZM9.37914 10.2672V10.752C9.37914 12.472 10.7791 13.872 12.4991 13.872H12.9839V13.3872C12.9839 11.6672 11.5839 10.2672 9.86394 10.2672H9.37914Z"
          fill="black"
        />
        <path
          d="M21.1392 16.2352H22.504V17.6C22.504 19.808 20.712 21.6 18.504 21.6H17.1392V20.2352C17.1392 18.0272 18.9312 16.2352 21.1392 16.2352Z"
          fill="#DCFFD1"
        />
        <path
          d="M18.5041 22.48H17.1393C16.6529 22.48 16.2593 22.0864 16.2593 21.6V20.2352C16.2593 17.544 18.4481 15.3552 21.1393 15.3552H22.5041C22.9905 15.3552 23.3841 15.7488 23.3841 16.2352V17.6C23.3841 20.2912 21.1953 22.48 18.5041 22.48ZM18.0193 20.72H18.5041C20.2241 20.72 21.6241 19.32 21.6241 17.6V17.1152H21.1393C19.4193 17.1152 18.0193 18.5152 18.0193 20.2352V20.72Z"
          fill="black"
        />
        <path
          d="M13.8655 21.6H12.5007C10.2927 21.6 8.50068 19.808 8.50068 17.6V16.2352H9.86548C12.0735 16.2352 13.8655 18.0272 13.8655 20.2352V21.6Z"
          fill="#DCFFD1"
        />
        <path
          d="M13.8639 22.48H12.4991C9.80794 22.48 7.61914 20.2912 7.61914 17.6V16.2352C7.61914 15.7488 8.01274 15.3552 8.49914 15.3552H9.86394C12.5551 15.3552 14.7439 17.544 14.7439 20.2352V21.6C14.7439 22.0864 14.3503 22.48 13.8639 22.48ZM9.37914 17.1152V17.6C9.37914 19.32 10.7791 20.72 12.4991 20.72H12.9839V20.2352C12.9839 18.5152 11.5839 17.1152 9.86394 17.1152H9.37914Z"
          fill="black"
        />
        <path
          d="M15.5023 31.28C15.0159 31.28 14.6223 30.8864 14.6223 30.4V1.76C14.6223 1.2736 15.0159 0.880005 15.5023 0.880005C15.9887 0.880005 16.3823 1.2736 16.3823 1.76V30.4C16.3823 30.8864 15.9887 31.28 15.5023 31.28Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2079_1593">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
