import { IntlShape } from "react-intl";

export const averageSourcingLabel = (intl: IntlShape) =>
  intl.formatMessage({
    id: "components/ingredients/ingredientOrigins:averageSourcingLabel",
    defaultMessage: "Average Sourcing",
  });

export const locationsToString = (
  locations: Array<any> | undefined,
  intl: IntlShape
): string =>
  locations
    ?.filter((location) => location !== null)
    .map((location) => location!.name)
    .sort()
    .join(", ") ?? averageSourcingLabel(intl);
