import carbonLabelStories from "./carbon-label/stories";
import carbonLabelSalesPromptStories from "./CarbonLabelsSalesPrompt.stories";
import recipePageCarbonLabelStories from "./RecipePageCarbonLabel.stories";

const stories = {
  name: "labels",
  children: [
    carbonLabelSalesPromptStories,
    carbonLabelStories,
    recipePageCarbonLabelStories,
  ],
};

export default stories;
