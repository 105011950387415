import { useState } from "react";

import { DangerButton } from "./Button";
import ErrorBoundary from "./ErrorBoundary";

const stories = {
  name: "ErrorBoundary",
  render: () => <Story />,
};

function Story() {
  const [hasError, setHasError] = useState(false);

  function handleClick() {
    setHasError(true);
  }

  return (
    <ErrorBoundary>
      <DangerButton onClick={handleClick}>Don't press this</DangerButton>
      {hasError && <BrokenComponent />}
    </ErrorBoundary>
  );
}

function BrokenComponent(): never {
  throw new Error("render failed");
}

export default stories;
