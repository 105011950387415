import { InputMaybe } from "../__generated__/globalTypes";

export interface GenericFilter {
  allOf?: InputMaybe<InputMaybe<GenericFilter>[]>;
  anyOf?: InputMaybe<InputMaybe<GenericFilter>[]>;
  [key: string]: any;
}

export const combineFiltersAnd = (
  filter1: GenericFilter,
  filter2: GenericFilter
) => {
  let allOf = undefined;
  let anyOf = undefined;
  if (filter1.allOf && filter2.allOf) {
    allOf = [...filter1.allOf, ...filter2.allOf];
  } else {
    allOf = filter1.allOf || filter2.allOf || undefined;
  }
  if (filter1.anyOf && filter2.anyOf) {
    allOf = [
      ...(allOf || []),
      { anyOf: filter1.anyOf },
      { anyOf: filter2.anyOf },
    ];
  } else {
    anyOf = filter1.anyOf || filter2.anyOf || undefined;
  }
  let result = { ...filter1, ...filter2 };
  if (allOf) {
    result = { ...result, allOf };
  } else {
    delete result.allOf;
  }
  if (anyOf) {
    result = { ...result, anyOf };
  } else {
    delete result.anyOf;
  }
  return result;
};

// could create combineFiltersOr if needed
