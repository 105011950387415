import gql from "graphql-tag";
import { useEffect } from "react";
import { useHistory } from "react-router";

import { useTags } from "../../services/useOrganizationFeatures";
import * as statuses from "../../util/statuses";
import useQuery from "../graphql/useQuery";
import Page from "../Page";
import { usePages } from "../pages";
import RecipePageContent from "../recipes/RecipePageContent";
import * as RecipePageControls from "../recipes/RecipePageControls";
import * as RecipesListPageLocationState from "../recipes/RecipesListPageLocationState";
import StatusDisplay from "../StatusDisplay";
import {
  CollectionRecipePage_Query as Query,
  CollectionRecipePage_QueryVariables as QueryVariables,
} from "./CollectionRecipePage.graphql";

const collectionRecipePage = { pageName: "collection-recipe" };

export default function RecipePage() {
  const history = useHistory();
  const pages = usePages();
  const hasFeatureTags = useTags();
  const { collectionId, recipeId } = pages
    .CollectionRecipe(hasFeatureTags)
    .useParams();

  const { refresh: refreshRecipe, status: queryStatus } = useQuery<
    Query,
    QueryVariables
  >(recipeGraphqlQuery, {
    recipeCollectionId: collectionId,
    recipeId,
  });

  const recipeQuery = RecipesListPageLocationState.useQueryParams();

  useEffect(() => {
    if (statuses.isSuccess(queryStatus)) {
      const { recipe, recipeCollection } = queryStatus.value;
      if (recipeCollection === null && recipe !== null) {
        history.push(pages.Recipe(hasFeatureTags).url(recipe));
      }
    }
  }, [history, pages, queryStatus, hasFeatureTags]);

  const [controlsState, setControlsState] = RecipePageControls.useState();

  return (
    <Page tracking={collectionRecipePage} contentHasPadding={false}>
      <StatusDisplay status={queryStatus}>
        {({ recipe, recipeCollection }) => (
          <RecipePageContent
            breadcrumb={pages
              .CollectionRecipe(hasFeatureTags)
              .breadcrumb(recipeCollection, recipe, recipeQuery)}
            recipe={recipe}
            recipePageControlsState={[controlsState, setControlsState]}
            refreshRecipe={refreshRecipe}
          />
        )}
      </StatusDisplay>
    </Page>
  );
}

const recipeGraphqlQuery = gql`
  query CollectionRecipePage_Query($recipeCollectionId: Int!, $recipeId: Int!) {
    recipeCollection(filter: { ids: [$recipeCollectionId] }) {
      id
      name
    }

    recipe(filter: { ids: [$recipeId] }) {
      ...RecipePageContent_Recipe
    }
  }

  ${RecipePageContent.fragments.recipe}
`;
