import VectorProps from "./VectorProps";

export default function Table(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library
  // https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?node-id=1-67&t=cBog0J1q2hvV18KE-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 0C0.559644 0 0 0.559644 0 1.25C0 1.94036 0.559644 2.5 1.25 2.5H18.75C19.4404 2.5 20 1.94036 20 1.25C20 0.559644 19.4404 0 18.75 0H1.25ZM1.25 5.75001C0.559644 5.75001 0 6.30965 0 7.00001C0 7.69036 0.559644 8.25001 1.25 8.25001H18.75C19.4404 8.25001 20 7.69036 20 7.00001C20 6.30965 19.4404 5.75001 18.75 5.75001H1.25ZM0 12.75C0 12.0597 0.559644 11.5 1.25 11.5H18.75C19.4404 11.5 20 12.0597 20 12.75C20 13.4404 19.4404 14 18.75 14H1.25C0.559644 14 0 13.4404 0 12.75ZM1.25 17.25C0.559644 17.25 0 17.8097 0 18.5C0 19.1904 0.559644 19.75 1.25 19.75H18.75C19.4404 19.75 20 19.1904 20 18.5C20 17.8097 19.4404 17.25 18.75 17.25H1.25Z"
      />
    </svg>
  );
}
