import classNames from "classnames";
import BaseModal from "react-bootstrap/Modal";

import "./ActionModal.css";

interface ActionModalProps {
  children: React.ReactNode;
  className?: string;
  onExited?: () => void;
  show: boolean;
  size?: "lg" | "sm" | "xl";
  title: React.ReactNode;
  headerClassName?: string;
  headerContent?: React.ReactNode;
}

export default function ActionModal(props: ActionModalProps) {
  const {
    children,
    className,
    headerClassName,
    headerContent,
    onExited,
    size = "lg",
    show,
    title,
  } = props;
  return (
    <BaseModal
      className={classNames("ActionModal", className)}
      backdrop="static"
      onExited={onExited}
      show={show}
      size={size}
    >
      {title !== null && title !== undefined && (
        <BaseModal.Header className={headerClassName}>
          <BaseModal.Title>
            <h3 className="mb-0">{title}</h3>
          </BaseModal.Title>
          {headerContent ?? null}
        </BaseModal.Header>
      )}
      <div className={classNames({ "pt-4": !title })}>{children}</div>
    </BaseModal>
  );
}

ActionModal.Body = BaseModal.Body;
ActionModal.Footer = BaseModal.Footer;
