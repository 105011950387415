import React from "react";

import { PrimaryButton } from "../utils/Button";
import Card from "../utils/Card";

export interface PaymentStepButton {
  disabled: boolean;
  text: React.ReactNode;
  loading: boolean;
  onClick: () => void;
}

interface PaymentStepProps {
  billSummary: JSX.Element;
  button: PaymentStepButton;
  content: React.ReactNode;
}

export default function PaymentStep(props: PaymentStepProps) {
  const { billSummary, button, content } = props;
  return (
    <div className="container pb-5">
      <div className="row my-4 pb-5 pt-3">
        <div className="col-5 p-0">{content}</div>
        <div className="col-1" />
        <div className="col-6 p-0">
          <Card className="p-4">
            <div className="container p-3">
              <div className="mb-3">{billSummary}</div>
              <PrimaryButton
                fullWidth
                disabled={button.disabled}
                onClick={button.onClick}
                loading={button.loading}
              >
                {button.text}
              </PrimaryButton>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
