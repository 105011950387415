import { useState } from "react";

import { GridListToggle, State } from "./GridListToggle";

function Story() {
  const [state, setState] = useState<State>("grid");
  return <GridListToggle setState={setState} state={state} />;
}

const stories = {
  name: "GridListToggle",
  render: () => <Story />,
};

export default stories;
