import VectorProps from "./VectorProps";

export default function Download(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?t=pBvU7W0Vi0zA0c6f-0
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      onClick={handleClick}
    >
      <g clipPath="url(#clip0_22669_52018)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.0001 13.1C1.49715 13.1 1.9001 13.5029 1.9001 14V17.2C1.9001 17.697 2.30304 18.1 2.8001 18.1H17.2001C17.6972 18.1 18.1001 17.697 18.1001 17.2V14C18.1001 13.5029 18.503 13.1 19.0001 13.1C19.4972 13.1 19.9001 13.5029 19.9001 14V17.2C19.9001 18.6911 18.6913 19.9 17.2001 19.9H2.8001C1.30893 19.9 0.100098 18.6911 0.100098 17.2V14C0.100098 13.5029 0.503041 13.1 1.0001 13.1Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0001 0.0999756C10.4972 0.0999756 10.9001 0.502919 10.9001 0.999976V13C10.9001 13.497 10.4972 13.9 10.0001 13.9C9.50304 13.9 9.1001 13.497 9.1001 13V0.999976C9.1001 0.502919 9.50304 0.0999756 10.0001 0.0999756Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.82743 9.40205C5.15766 9.03054 5.72652 8.99708 6.09803 9.32731L10.0001 12.7958L13.9022 9.32731C14.2737 8.99708 14.8425 9.03054 15.1728 9.40205C15.503 9.77355 15.4695 10.3424 15.098 10.6726L10.598 14.6726C10.257 14.9758 9.74317 14.9758 9.40217 14.6726L4.90217 10.6726C4.53067 10.3424 4.4972 9.77355 4.82743 9.40205Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_22669_52018">
          <rect width="20" height="20" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}
