import classNames from "classnames";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import BootstrapSplitButton from "react-bootstrap/SplitButton";

import { Button, ButtonProps } from "./Button";
import useId from "./useId";
import "./SplitButton.css";

interface Item {
  label: string;
  onClick: () => void;
}

interface SplitButtonProps {
  items: Array<Item>;
  variant: ButtonProps["variant"];
}

export default function SplitButton(props: SplitButtonProps) {
  const { items, variant } = props;

  const [hover, setHover] = useState<boolean>(false);

  const id = useId();

  if (items.length === 0) {
    return null;
  } else if (items.length === 1) {
    return (
      <Button onClick={items[0].onClick} variant={variant}>
        {items[0].label}
      </Button>
    );
  } else {
    return (
      <div
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <BootstrapSplitButton
          className={classNames({ SplitButton__hover: hover })}
          id={id}
          menuAlign="right"
          onClick={items[0].onClick}
          title={<div className="medium-font">{items[0].label}</div>}
          variant={variant}
        >
          {items.slice(1).map((item, itemIndex) => (
            <Dropdown.Item key={itemIndex} onClick={item.onClick}>
              {item.label}
            </Dropdown.Item>
          ))}
        </BootstrapSplitButton>
      </div>
    );
  }
}
