import VectorProps from "./VectorProps";

export default function CircularArrow(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?node-id=1239%3A1722&mode=dev
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 184">
        <path
          id="Vector"
          d="M8.90753 15.332C12.816 15.332 16 12.1895 16 8.33203C16 4.47455 12.816 1.33203 8.90753 1.33203C7.29019 1.33203 5.77404 1.84744 4.51054 2.82841C4.19042 3.07777 4.13991 3.54331 4.39255 3.85926C4.6452 4.1752 5.1169 4.22505 5.43702 3.9757C6.43095 3.19423 7.62712 2.79512 8.90744 2.79512C11.9904 2.79512 14.5006 5.27259 14.5006 8.31531C14.5006 11.358 11.9904 13.8355 8.90744 13.8355C5.89188 13.8355 3.44911 11.4745 3.31433 8.54809H4.54411C4.99901 8.54809 5.2854 8.04925 5.03261 7.66686L3.045 4.67398C2.80915 4.32475 2.30371 4.32475 2.08467 4.67398L0.0968199 7.68348C-0.155832 8.06588 0.113625 8.56472 0.585316 8.56472H1.832C1.94999 12.3224 5.08342 15.3319 8.90762 15.3319L8.90753 15.332Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
