import gql from "graphql-tag";
import React, { useContext, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useCallbackOne } from "use-memo-one";

import {
  useCheckPasswordStrengthPromise,
  useYourPasswordIsTooWeakMessage,
} from "../components/utils/passwordStrength";
import useGetter from "../components/utils/useGetter";
import * as featureFlags from "../domain/featureFlags";
import {
  createAuthenticatedHttpClient,
  createUnauthenticatedHttpClient,
} from "../services/api/Client";
import { createFetchGraphQL } from "../services/api/RemoteDataStoreProvider";
import { useLocalStorageState } from "../services/LocalStorageService";
import {
  ImpersonatedUser,
  LogoutListener,
  marshallResetPasswordData,
  ResetPasswordData,
  Session,
  SessionProvider,
} from "../sessions";
import UserVisibleError from "../util/UserVisibleError";
import {
  ServerSessionProvider_UserInfo as UserInfo,
  ServerSessionProvider_UserInfoQuery as UserInfoQuery,
  ServerSessionProvider_UserInfoQueryVariables as UserInfoQueryVariables,
} from "./ServerSessionProvider.graphql";

const GetAccessTokenContext = React.createContext<() => Promise<string>>(() =>
  Promise.reject(new Error("Provider for getAccessToken not set"))
);

interface SessionState {
  accessToken: string;
  expiresAt: number;
  userInfo: UserInfo;
}

interface SessionProviderProps {
  children: React.ReactNode;
}

export default function ServerSessionProvider(props: SessionProviderProps) {
  const { children } = props;
  const [sessionState, setSessionState] = useState<SessionState | null>(null);
  const getCurrentAccessToken = useGetter(
    sessionState === null ? null : sessionState.accessToken
  );
  const [impersonatedUserIdString, setImpersonatedUserIdString] =
    useLocalStorageState("impersonated_user_id");
  const [impersonatedExternalUserId, setImpersonatedExternalUserId] =
    useLocalStorageState("impersonated_external_user_id");
  const logoutListeners = useRef<Array<LogoutListener>>([]);

  const impersonatedUserId =
    impersonatedUserIdString === null
      ? null
      : tryParseInt(impersonatedUserIdString);
  const setImpersonatedUser = useCallbackOne(
    (impersonatedUser: ImpersonatedUser | null) => {
      unstable_batchedUpdates(() => {
        setImpersonatedUserIdString(
          impersonatedUser === null ? null : impersonatedUser.id.toString()
        );
        setImpersonatedExternalUserId(
          impersonatedUser === null ? null : impersonatedUser.externalId
        );
      });
    },
    [setImpersonatedUserIdString, setImpersonatedExternalUserId]
  );

  const impersonatedUser =
    impersonatedUserId === null || impersonatedExternalUserId === null
      ? null
      : {
          id: impersonatedUserId,
          externalId: impersonatedExternalUserId,
        };

  const setToken = useCallbackOne(async (token: string) => {
    const httpClient = createAuthenticatedHttpClient({
      getAccessToken: async () => token,
      key: null,
      impersonatedUserId: null,
    });
    const fetchGraphQL = createFetchGraphQL(httpClient);
    const userInfoQueryResult = await fetchGraphQL<
      UserInfoQuery,
      UserInfoQueryVariables
    >({
      query: gql`
        query ServerSessionProvider_UserInfoQuery {
          viewer {
            ...ServerSessionProvider_UserInfo
          }
        }

        fragment ServerSessionProvider_UserInfo on User {
          externalId
          featureFlags
          id
        }
      `,
      variables: {},
    });

    const response = await httpClient.request({
      method: "GET",
      path: "oauth2/tokeninfo",
    });
    if (response.status !== 200) {
      throw new Error("bad response");
    }
    const expiresIn: number = (await response.json())["expiresIn"];

    setSessionState({
      accessToken: token,
      expiresAt: Date.now() + expiresIn * 1000,
      userInfo: userInfoQueryResult.viewer,
    });
  }, []);

  const logout = useCallbackOne(() => {
    unstable_batchedUpdates(() => {
      setImpersonatedUser(null);
      setSessionState(null);
      for (const listener of logoutListeners.current) {
        listener();
      }
      window.location.href = `${(window as any).FOODSTEPS_PLATFORM_URI}logout`;
    });
  }, [setImpersonatedUser]);

  useEffect(() => {
    if (sessionState !== null) {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, Math.max(0, sessionState.expiresAt - Date.now() - 1000 * 30));
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [sessionState]);

  const addLogoutListener = useCallbackOne((listener: LogoutListener) => {
    logoutListeners.current.push(listener);
    return () => {
      const index = logoutListeners.current.indexOf(listener);
      if (index !== -1) {
        logoutListeners.current.splice(index, 1);
      }
    };
  }, []);

  const getAccessToken = useCallbackOne(async () => {
    const token = getCurrentAccessToken();
    if (token === null) {
      throw new Error("token is null");
    } else {
      return token;
    }
  }, [getCurrentAccessToken]);

  const userInfo = sessionState === null ? null : sessionState.userInfo;
  const userFeatureFlags = userInfo === null ? [] : userInfo.featureFlags;

  const resetPassword = useResetPassword();

  const session: Session = {
    addLogoutListener,
    impersonate: (impersonatedUser: ImpersonatedUser | null) => {
      setImpersonatedUser(impersonatedUser);
    },
    impersonatedUser,
    setToken,
    logout,
    resetPassword,
    userId: userInfo === null ? null : userInfo.id,
    externalUserId: userInfo === null ? null : userInfo.externalId,
    userIsStaff: userFeatureFlags.includes(featureFlags.isStaff),
    isExternalUser: userFeatureFlags.includes(featureFlags.isExternalUser),
  };

  return (
    <GetAccessTokenContext.Provider value={getAccessToken}>
      <SessionProvider value={session}>{children}</SessionProvider>
    </GetAccessTokenContext.Provider>
  );
}

export function useGetAccessToken() {
  return useContext(GetAccessTokenContext);
}

class BadResetPasswordStatus extends UserVisibleError {}

function useResetPassword(): (
  newPasswordData: ResetPasswordData
) => Promise<void> {
  const checkPasswordStrengthPromise = useCheckPasswordStrengthPromise();
  const yourPasswordIsTooWeakMessage = useYourPasswordIsTooWeakMessage();

  return async (newPasswordData: ResetPasswordData) => {
    if (newPasswordData.newPassword !== newPasswordData.newPasswordConfirm) {
      throw new UserVisibleError("Passwords do not match");
    } else if (newPasswordData.newPassword === "") {
      throw new UserVisibleError("You must enter a password");
    } else {
      const checkPasswordStrengthResult = (await checkPasswordStrengthPromise)({
        password: newPasswordData.newPassword,
        userInputs: [],
      });
      if (!checkPasswordStrengthResult.isValid) {
        throw new UserVisibleError(
          checkPasswordStrengthResult.warning ?? yourPasswordIsTooWeakMessage
        );
      }
    }

    const httpClient = createUnauthenticatedHttpClient();
    const requestBody = marshallResetPasswordData(newPasswordData);
    const response = await httpClient.request({
      method: "POST",
      path: "api/v1/reset-password-confirm/",
      body: requestBody,
    });
    if (response.status !== 200) {
      if (response.status === 404) {
        throw new BadResetPasswordStatus(
          `Failed to update password (code ${response.status})`,
          (
            <FormattedMessage
              id="sessions/ServerSessionProvider:404Error"
              defaultMessage="Failed to update password as this link has expired."
            />
          )
        );
      }

      let message: string;
      try {
        let responseJson = await response.json();
        if (responseJson.message !== undefined) {
          message = responseJson.message;
        } else {
          message = JSON.stringify(responseJson);
        }
      } catch {
        message = `code ${response.status}`;
      }
      throw new Error(message);
    }
  };
}

function tryParseInt(value: string): number | null {
  const result = parseInt(value, 10);
  return isNaN(result) ? null : result;
}
