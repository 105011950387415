import FitToSpace from "./FitToSpace";

const stories = {
  name: "FitToSpace",
  render: () => (
    <FitToSpace>
      {({ width, height }) => (
        <p>
          {width} x {height}
        </p>
      )}
    </FitToSpace>
  ),
};

export default stories;
