import { useIntl } from "react-intl";

import Page from "./Page";

const notFoundPageTracking = { pageName: "not-found" };

export default function NotFoundPage() {
  const intl = useIntl();

  return (
    <Page tracking={notFoundPageTracking}>
      <Page.Title
        breadcrumb={[
          {
            title: intl.formatMessage({
              id: "components/NotFoundPage:title",
              defaultMessage: "Page not found",
            }),
          },
        ]}
      />
    </Page>
  );
}
