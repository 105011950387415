import { gql } from "graphql-tag";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage, useIntl } from "react-intl";

import UserVisibleError from "../../util/UserVisibleError";
import useMutation from "../graphql/useMutation";
import Page from "../Page";
import { usePages } from "../pages";
import Form from "../utils/Form";
import useId from "../utils/useId";
import {
  ContactUsPage_SendUserMessage as SendUserMessage,
  ContactUsPage_SendUserMessageVariables as SendUserMessageVariables,
} from "./ContactUsPage.graphql";

const tracking = { pageName: "contact" };

export default function ContactUsPage() {
  const pages = usePages();

  return (
    <Page tracking={tracking}>
      <Page.Title breadcrumb={pages.ContactUs.breadcrumb()} />
      <ContactUsPageForm />
    </Page>
  );
}

const sendUserMessageMutation = gql`
  mutation ContactUsPage_SendUserMessage($input: SendUserMessageInput!) {
    sendUserMessage(input: $input) {
      success
    }
  }
`;

function ContactUsPageForm() {
  const intl = useIntl();
  const subjectInputId = useId();
  const [subject, setSubject] = useState("");
  const messageInputId = useId();
  const [message, setMessage] = useState("");
  const [hasSent, setHasSent] = useState(false);

  const [sendUserMessage] = useMutation<
    SendUserMessage,
    SendUserMessageVariables
  >(sendUserMessageMutation);

  const handleSubmit = async () => {
    if (subject === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/account/ContactUsPage:missingSubjectErrorMessage",
          defaultMessage: "Subject is required.",
        })
      );
    }
    if (message === "") {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/account/ContactUsPage:missingMessageErrorMessage",
          defaultMessage: "Message is required.",
        })
      );
    }

    await sendUserMessage({ variables: { input: { subject, body: message } } });

    setHasSent(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <p>
        <FormattedMessage
          id="components/account/ContactUsPage:queriesOrFeedback"
          defaultMessage="If you have any queries about the platform or would like to provide us with any feedback, please do not hesitate to send us a message here."
        />
      </p>
      <p className="mt-4">
        <FormattedMessage
          id="components/account/ContactUsPage:response"
          defaultMessage="Your message will be picked up by one of our team and we will get back to you as soon as we can."
        />
      </p>
      <div className="form-group">
        <label htmlFor={subjectInputId}>
          <FormattedMessage
            id="components/account/ContactUsPage:subjectLabel"
            defaultMessage="Subject"
          />
        </label>
        <input
          className="form-control"
          disabled={hasSent}
          id={subjectInputId}
          onChange={(event) => setSubject(event.target.value)}
          value={subject}
        />
      </div>
      <div className="form-group">
        <label htmlFor={messageInputId}>
          <FormattedMessage
            id="components/account/ContactUsPage:messageLabel"
            defaultMessage="Message"
          />
        </label>
        <textarea
          className="form-control"
          disabled={hasSent}
          id={messageInputId}
          onChange={(event) => setMessage(event.target.value)}
          value={message}
        />
      </div>
      <Form.ErrorAlert className="mb-4" />
      {hasSent && (
        <Alert variant="success">
          <FormattedMessage
            id="components/account/ContactUsPage:messageSent"
            defaultMessage="Message sent."
          />
        </Alert>
      )}
      <Form.SubmitButton
        disabled={hasSent}
        loadingLabel={intl.formatMessage({
          id: "components/account/ContactUsPage:sendButton/loadingLabel",
          defaultMessage: "Sending",
        })}
        submitLabel={intl.formatMessage({
          id: "components/account/ContactUsPage:sendButton/submitLabel",
          defaultMessage: "Send",
        })}
      />
    </Form>
  );
}
