import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useTracking } from "../../../tracking";
import { TrustedExternalLink } from "../../utils/Button";
import Checkbox from "../../utils/Checkbox";
import Form from "../../utils/Form";

export interface ConfirmSignUpFormProps {
  onComplete: (
    emailMarketingConsent: boolean,
    trackingProviderDistinctId: string
  ) => Promise<void>;
  parentOrganizationName: string | null;
}

export default function ConfirmSignUpForm(props: ConfirmSignUpFormProps) {
  const { onComplete, parentOrganizationName } = props;

  const intl = useIntl();
  const { getDistinctId, trackSignUpAcknowledgementSubmitted } = useTracking();

  const [emailMarketingConsent, setEmailMarketingConsent] =
    useState<boolean>(false);
  const [signUpAgreement, setSignUpAgreement] = useState<boolean>(false);
  const [
    acknowledgeReferrerParentOrganization,
    setAcknowledgeReferrerParentOrganization,
  ] = useState(false);

  const handleSubmit = async () => {
    trackSignUpAcknowledgementSubmitted();
    const distinctId = getDistinctId();
    if (distinctId === undefined) {
      throw new Error("No Tracking Provider distinct ID was provided");
    }
    await onComplete(emailMarketingConsent, distinctId);
  };

  const hasAcknowledgedReferrerWillBeParentOrganization =
    parentOrganizationName === null || acknowledgeReferrerParentOrganization;

  return (
    <Form onSubmit={handleSubmit}>
      <Checkbox
        containerClassName="mb-4"
        defaultChecked={emailMarketingConsent}
        label={
          <FormattedMessage
            id="components/account/sign-up/ConfirmSignUpForm:emailMarketingConsent"
            defaultMessage="Keep me updated on Foodsteps’ product updates and industry news. I can opt out at any time."
          />
        }
        onChange={(emailMarketingConsent) =>
          setEmailMarketingConsent(emailMarketingConsent)
        }
      />

      <Checkbox
        containerClassName="mb-4"
        defaultChecked={signUpAgreement}
        label={
          <FormattedMessage
            id="components/account/sign-up/ConfirmSignUpForm:signupAgreement"
            defaultMessage="I have read and agree to Foodsteps’ <PrivacyLink>Privacy Policy</PrivacyLink> and the <TermsAndConditionsLink>Terms and Conditions</TermsAndConditionsLink>."
            values={{
              PrivacyLink: (chunks: React.ReactNode) => (
                <TrustedExternalLink to="https://foodsteps.earth/legal/privacy-policy/">
                  {chunks}
                </TrustedExternalLink>
              ),
              TermsAndConditionsLink: (chunks: React.ReactNode) => (
                <TrustedExternalLink to="https://www.foodsteps.earth/legal/platform-terms-conditions">
                  {chunks}
                </TrustedExternalLink>
              ),
            }}
          />
        }
        onChange={(signUpAgreement) => setSignUpAgreement(signUpAgreement)}
      />

      {parentOrganizationName !== null && (
        <Checkbox
          defaultChecked={acknowledgeReferrerParentOrganization}
          label={
            <FormattedMessage
              id="components/account/sign-up/ConfirmSignUpForm:acknowledgeReferrerParentOrganization"
              defaultMessage="I understand that I am signing up to Foodsteps as a part of the {organizationName} account and {organizationName} will have access to my Foodsteps data."
              values={{ organizationName: parentOrganizationName }}
            />
          }
          onChange={(acknowledgement) =>
            setAcknowledgeReferrerParentOrganization(acknowledgement)
          }
        />
      )}
      <div className="mt-4">
        <Form.SubmitButton
          disabled={
            !signUpAgreement || !hasAcknowledgedReferrerWillBeParentOrganization
          }
          fullWidth
          loadingLabel={intl.formatMessage({
            id: "components/account/sign-up/ConfirmSignUpForm:signUpButton/loadingLabel",
            defaultMessage: "Signing Up",
          })}
          submitLabel={intl.formatMessage({
            id: "components/account/sign-up/ConfirmSignUpForm:signUpButton/submitLabel",
            defaultMessage: "Sign Up",
          })}
        />
      </div>
      <Form.ErrorAlert className="mt-4" />
    </Form>
  );
}
