import { useMemoOne } from "use-memo-one";

import { ImpactCategory } from "../../domain/impactCategories";
import { recipeColourLists } from "../graphs/colors";
import { createRecipeGroup } from "../graphs/RecipeGroup";
import { getImpactPerKg } from "./DashboardPage";
import GraphCard, { GraphType } from "./GraphCard";
import { Recipe } from "./useDashboardPageData";

interface RecipeImpactChartCardProps {
  impactCategory: ImpactCategory;
  recipes: Array<Recipe>;
  meanImpactPerKg: string;
  recipeLabel: string;
}

export default function RecipeImpactChartCard(
  props: RecipeImpactChartCardProps
) {
  const { impactCategory, meanImpactPerKg, recipes, recipeLabel } = props;

  const recipeGroups = useMemoOne(() => {
    return recipes.flatMap((recipe) => {
      const impactPerKg = getImpactPerKg(recipe, impactCategory);
      return impactPerKg == null
        ? []
        : [
            createRecipeGroup({
              id: recipe.id,
              name: recipe.name,
              recipes: [
                {
                  impactPerKg,
                  name: recipe.name,
                },
              ],
            }),
          ];
    });
  }, [recipes, impactCategory]);

  return (
    <GraphCard
      colorLists={recipeColourLists}
      defaultGraphType={GraphType.BAR}
      impactCategory={impactCategory}
      title={recipeLabel}
      mean={meanImpactPerKg}
      recipeCount={recipes.length}
      recipeGroups={recipeGroups}
    />
  );
}
