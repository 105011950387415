import classNames from "classnames";
import React, { useEffect } from "react";

import { useTracking, PageViewTracking } from "../tracking";
import Header from "./Header";
import Navbar, { NavbarItem } from "./navigation/Navbar";
import SplitRow from "./SplitRow";
import Breadcrumb, { BreadcrumbPage } from "./utils/Breadcrumb";
import ErrorBoundary from "./utils/ErrorBoundary";
import "./Page.css";

interface PageProps {
  children?: React.ReactNode;
  contentContainerRef?: any;
  contentHasPadding?: boolean;
  navItems?: Array<NavbarItem>;
  showNav?: boolean;
  tracking?: PageViewTracking;
}

export default function Page(props: PageProps) {
  const {
    children,
    contentHasPadding = true,
    contentContainerRef,
    showNav = true,
    navItems,
    tracking,
  } = props;

  const { trackPageViewed } = useTracking();

  const mainContent = <ErrorBoundary>{children}</ErrorBoundary>;

  useEffect(() => {
    if (tracking === undefined) {
      return;
    }

    trackPageViewed(tracking);
  }, [trackPageViewed, tracking]);

  return (
    <div className="d-flex flex-column h-100 w-100">
      <Header showNavbarBorder={showNav} />
      {showNav ? (
        <SplitRow
          className="bg-light"
          left={<Navbar items={navItems} />}
          right={
            <div className={contentHasPadding ? "p-4" : "p-0"}>
              {mainContent}
            </div>
          }
          style={{ overflowY: "scroll", flex: "auto" }}
        />
      ) : (
        <div
          ref={contentContainerRef}
          className="bg-light"
          style={{ overflowY: "scroll", flex: "auto" }}
        >
          {mainContent}
        </div>
      )}
    </div>
  );
}

interface PageTitleProps {
  breadcrumb: Array<BreadcrumbPage>;
  inlineComponentEnd?: React.ReactNode;
  inlineComponentStart?: React.ReactNode;
  title?: string;
  titleTextClassName?: string;
}

function PageTitle(props: PageTitleProps) {
  const {
    breadcrumb,
    inlineComponentEnd,
    inlineComponentStart,
    title,
    titleTextClassName = "",
  } = props;

  const finalTitle =
    title === undefined ? breadcrumb[breadcrumb.length - 1].title : title;

  return (
    <>
      <Breadcrumb pages={breadcrumb} />
      <div className="PageTitle_InlineAndFinalTitleContainer">
        {inlineComponentStart && (
          <div className="my-auto">{inlineComponentStart}</div>
        )}
        {finalTitle && (
          <h1 className={classNames(titleTextClassName, "my-auto")}>
            {finalTitle}
          </h1>
        )}
        {inlineComponentEnd && (
          <div className="my-auto">{inlineComponentEnd}</div>
        )}
      </div>
    </>
  );
}

Page.Title = PageTitle;
