import { useState } from "react";

import { Button } from "../Button";
import SelectAllCheckbox, { SelectAllCheckboxState } from "./SelectAllCheckbox";

const stories = {
  name: "SelectAllCheckbox",
  render: () => <Story />,
};

function Story() {
  const [value, setValue] = useState<SelectAllCheckboxState>(
    SelectAllCheckboxState.EMPTY
  );

  const handleChange = (checked: boolean) => {
    if (checked) {
      setValue(SelectAllCheckboxState.CHECKED);
    } else {
      setValue(SelectAllCheckboxState.EMPTY);
    }
  };

  return (
    <div className="m-3">
      <SelectAllCheckbox value={value} onChange={handleChange} />
      <Button
        className="mt-4"
        fullWidth={false}
        variant="secondary"
        onClick={() => setValue(SelectAllCheckboxState.INDETERMINATE)}
      >
        Show indeterminate state
      </Button>
    </div>
  );
}

export default stories;
