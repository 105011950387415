import { FormattedMessage, useIntl } from "react-intl";

import ReadResult from "../../utils/ReadResult";
import * as TextField from "../../utils/TextField";

export type Value = TextField.Value;

export interface Input {
  name: string;
}

interface SiteNameEditorProps {
  onChange: (value: Value) => void;
  value: Value;
}

export function SiteNameEditor(props: SiteNameEditorProps) {
  const { onChange, value } = props;

  const intl = useIntl();

  return (
    <>
      <div className="form-group" style={{ width: "70%" }}>
        <label className="medium-font" htmlFor="siteNameEditor">
          <h4>
            <FormattedMessage
              id="components/recipes/ProductEditor/SiteNameEditor:label"
              defaultMessage="Name"
            />
          </h4>
        </label>
        <TextField.TextField
          autoComplete="off"
          autoFocus
          id="siteNameEditor"
          onChange={onChange}
          placeholder={intl.formatMessage({
            defaultMessage: "e.g. Cauli Bite Factory",
            id: "components/recipes/ProductEditor/SiteNameEditor:placeholder",
          })}
          value={value}
        />
      </div>
    </>
  );
}

export function initialValue(): Value {
  // TODO: Use saved site name if available.
  return TextField.initialValue("");
}

export function read(value: Value): ReadResult<Value, Input> {
  const nameReadResult = TextField.read(value);

  const newValue = { ...value, name: nameReadResult.value };

  if (nameReadResult.hasError) {
    return { hasError: true, value: newValue };
  } else {
    return {
      hasError: false,
      value: newValue,
      input: {
        name: nameReadResult.input,
      },
    };
  }
}
