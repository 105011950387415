import VectorProps from "./VectorProps";
// rename icon/close
export default function Close(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1-67&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.41393 15.5276C4.06246 15.1761 4.06246 14.6063 4.41393 14.2548L14.4548 4.21387C14.8063 3.8624 15.3762 3.8624 15.7276 4.21387C16.0791 4.56534 16.0791 5.13519 15.7276 5.48666L5.68672 15.5276C5.33525 15.8791 4.7654 15.8791 4.41393 15.5276Z"
        fill={fill}
      />
      <path
        d="M4.27239 4.21386C4.62386 3.86239 5.19371 3.86239 5.54518 4.21386L15.5861 14.2548C15.9376 14.6062 15.9376 15.1761 15.5861 15.5276C15.2346 15.879 14.6648 15.879 14.3133 15.5276L4.27239 5.48665C3.92092 5.13518 3.92092 4.56533 4.27239 4.21386Z"
        fill={fill}
      />
    </svg>
  );
}
