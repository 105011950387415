import { useState } from "react";

import SearchBox from "./SearchBox";

const stories = {
  name: "SearchBox",
  children: [
    { name: "Normal", render: () => <Story /> },
    { name: "Disabled", render: () => <Story disabled /> },
  ],
};

interface StoryProps {
  disabled?: boolean;
}
function Story(props: StoryProps) {
  const { disabled = false } = props;
  const [value, setValue] = useState<string>("");

  return (
    <SearchBox
      disabled={disabled}
      onChange={setValue}
      placeholder={"Enter text..."}
      value={value}
    />
  );
}

export default stories;
