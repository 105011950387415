export default interface RecipeGroup {
  id: number;
  name: string;
  recipes: Array<Recipe>;
}

interface Recipe {
  impactPerKg: number;
  name: string;
}

export type { Recipe as RecipeGroupRecipe };

export function createRecipeGroup({
  id,
  name,
  recipes,
}: {
  id: number;
  name: string;
  recipes: Array<Recipe>;
}): RecipeGroup {
  return {
    id,
    name,
    recipes,
  };
}
