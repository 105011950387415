import VectorProps from "./VectorProps";

export default function Filter(props: VectorProps) {
  const { className, fill = "currentColor", width } = props;

  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.61658 19.7C7.90675 19.8792 8.23793 19.9813 8.57865 19.9964C8.91937 20.0116 9.25831 19.9394 9.56325 19.7867L12.2299 18.4533C12.5614 18.2871 12.8401 18.032 13.0349 17.7165C13.2298 17.4009 13.3331 17.0375 13.3333 16.6667V10.83L18.0799 6.08C18.4551 5.70529 18.6661 5.19692 18.6666 4.66667V2C18.6666 1.46957 18.4559 0.960859 18.0808 0.585786C17.7057 0.210713 17.197 0 16.6666 0H3.33325C2.80282 0 2.29411 0.210713 1.91904 0.585786C1.54397 0.960859 1.33325 1.46957 1.33325 2V4.66667C1.33372 5.19692 1.54474 5.70529 1.91992 6.08L6.66659 10.83V18C6.66696 18.3408 6.75443 18.6759 6.9207 18.9734C7.08696 19.271 7.3265 19.5211 7.61658 19.7ZM3.33325 4.66667V2H16.6666V4.66667L11.3333 10V16.6667L8.66659 18V10L3.33325 4.66667Z"
        fill={fill}
      />
    </svg>
  );
}
