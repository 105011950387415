import { FormattedMessage } from "react-intl";

import InformationModalTooltip from "../InformationModalTooltip";
import "./DQSInformationModalTooltip.css";
import useDataQualityRatingToString from "./useDataQualityScoreRatingToString";
import { DataQualityScoreRating } from "./useDataQualityScoreToRatingString";

export default function DQSInformationModalTooltip() {
  const dataQualityRatingToString = useDataQualityRatingToString();
  return (
    <InformationModalTooltip
      body={
        <>
          <FormattedMessage
            id="components/recipes/DQSInformationModalTooltip:body"
            defaultMessage="
                        <p>The Data Quality Score (DQS) measures the quality of the data available to Foodsteps to assess the impact of a product or ingredient. A lower DQS usually indicates a lack of life cycle assessment studies available globally for particular ingredients.</p>
                        <p>There is a separate data quality score for each impact category for each product and ingredient.</p>
                        <p>Foodsteps considers 3 factors when calculating data quality for each activity in the food production system:</p>
                        <ul>
                            <li><b>Technological</b> - How closely the food items referenced in LCA studies match the actual food items used in the activity. For example, for a “Brown Rice Protein” ingredient, an assessment incorporating a large number of studies specific to “Brown Rice Protein” would have a higher technological score than an assessment incorporating a small number of studies on “Rice Protein”</li>
                            <li><b>Geographical</b> - How closely the locations referenced in LCA studies match the actual location of the activity. For example, an assessment for a food item processed in France would have a higher geographical score if it was based on a larger number of studies specific to France, than a smaller number of studies specific to other countries.</li>
                            <li><b>Temporal</b> - How closely the time period of LCA studies matches the time of the actual assessment. For example, a recent assessment would have a higher temporal score if it incorporated a higher number of recent studies.</li>
                        </ul>
                        <p>A separate value is calculated for each factor, and these values are combined into a single data quality score.</p>
                        <b>Data Quality Ratings</b>
                        "
            values={{
              b: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
              p: (chunks: React.ReactNode) => <p>{chunks}</p>,
              ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
              li: (chunks: React.ReactNode) => <li>{chunks}</li>,
            }}
          />
          <div className="DQSInformationModalTooltip_DQSRangeBoxesContainer">
            <DQSRangeBox
              label={dataQualityRatingToString(
                DataQualityScoreRating.UNSUITABLE
              )}
              lowerBound={0}
              upperBound={1}
            />
            <DQSRangeBox
              label={dataQualityRatingToString(DataQualityScoreRating.POOR)}
              lowerBound={1}
              upperBound={2}
            />
            <DQSRangeBox
              label={dataQualityRatingToString(DataQualityScoreRating.FAIR)}
              lowerBound={2}
              upperBound={3}
            />
            <DQSRangeBox
              label={dataQualityRatingToString(DataQualityScoreRating.GOOD)}
              lowerBound={3}
              upperBound={4}
            />
            <DQSRangeBox
              label={dataQualityRatingToString(
                DataQualityScoreRating.VERY_GOOD
              )}
              lowerBound={4}
              upperBound={5}
            />
          </div>
        </>
      }
      size="xl"
      title={
        <FormattedMessage
          id="components/recipes/DQSInformationModalTooltip:title"
          defaultMessage="Data Quality Scores"
        />
      }
    />
  );
}

interface DQSRangeBoxProps {
  label: React.ReactNode;
  lowerBound: number;
  upperBound: number;
}

function DQSRangeBox(props: DQSRangeBoxProps) {
  const { label, lowerBound, upperBound } = props;

  return (
    <div className="DQSInformationModalTooltip_DQSRangeBox">
      {label} {lowerBound} - {upperBound}
    </div>
  );
}
