import VectorProps from "./VectorProps";

export default function Pending(props: VectorProps) {
  const { className = "", fill = "currentColor", width } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=6yvM1O7kPoWObKMP-0
  return (
    <svg
      className={className}
      fill={fill}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1239_1724)">
        <path
          d="M8.90826 15.3328C12.8167 15.3328 16.0007 12.1903 16.0007 8.33278C16.0007 4.4753 12.8167 1.33278 8.90826 1.33278C7.29092 1.33278 5.77477 1.84819 4.51127 2.82916C4.19115 3.07852 4.14064 3.54406 4.39329 3.86001C4.64593 4.17595 5.11763 4.2258 5.43775 3.97645C6.43168 3.19498 7.62786 2.79587 8.90818 2.79587C11.9911 2.79587 14.5013 5.27333 14.5013 8.31606C14.5013 11.3588 11.9911 13.8362 8.90818 13.8362C5.89261 13.8362 3.44984 11.4752 3.31507 8.54884H4.54485C4.99974 8.54884 5.28613 8.05 5.03334 7.6676L3.04573 4.67473C2.80988 4.3255 2.30444 4.3255 2.08541 4.67473L0.0975523 7.68423C-0.1551 8.06662 0.114357 8.56547 0.586048 8.56547H1.83273C1.95072 12.3232 5.08415 15.3327 8.90836 15.3327L8.90826 15.3328Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1239_1724">
          <rect width="16" height="16" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}
