import VectorProps from "./VectorProps";

export default function PendingImpact(props: VectorProps) {
  const { className, height, width } = props;
  // From https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=vWs9AudvnGembMCh-0
  return (
    <svg
      className={className}
      width={width}
      height={height ?? width}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1629_1420)">
        <path
          d="M24.8091 48.8777C38.2041 48.8777 49.0628 38.019 49.0628 24.624C49.0628 11.2291 38.2041 0.3703 24.8091 0.3703C11.4142 0.3703 0.55542 11.2291 0.55542 24.624C0.55542 38.019 11.4142 48.8777 24.8091 48.8777Z"
          fill="#16DFC0"
        />
        <path
          d="M43.1219 17.0952C41.5383 14.3523 37.5792 14.3523 35.9957 17.0952L8.81096 64.1829C7.22746 66.9258 9.20695 70.3543 12.3741 70.3543H66.7435C69.9106 70.3543 71.8901 66.9258 70.3066 64.1829L43.1219 17.0952Z"
          fill="#FCED00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.9639 17.7638C40.895 15.9123 38.2226 15.9123 37.1537 17.7638L9.969 64.8515C8.90014 66.7029 10.2363 69.0171 12.3741 69.0171H66.7435C68.8813 69.0171 70.2175 66.7029 69.1486 64.8515L41.9639 17.7638ZM34.8377 16.4267C36.9359 12.7923 42.1817 12.7923 44.2799 16.4267L71.4647 63.5144C73.5628 67.1487 70.94 71.6914 66.7435 71.6914H12.3741C8.17765 71.6914 5.55483 67.1487 7.65298 63.5144L34.8377 16.4267Z"
          fill="black"
        />
        <path
          d="M39.0665 61.7143C37.3799 61.7143 36 60.3096 36 58.5331C36 56.7153 37.3799 55.3107 39.0665 55.3107C40.7915 55.3107 42.1714 56.7153 42.1714 58.5331C42.1714 60.3096 40.7915 61.7143 39.0665 61.7143ZM37.2266 52.5426L36.5366 31.8857H41.7114L40.9831 52.5426H37.2266Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1629_1420">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
