import Page from "../Page";
import { buildPath, usePages } from "../pages";
import NewPackagingComponentEditor from "./NewPackagingComponentEditor";
import * as PackagingPageLocationState from "./PackagingPageLocationState";

const newPackagingComponentPageTracking = {
  pageName: "new-packaging-component",
};

export default function NewPackagingComponentPage() {
  const pages = usePages();
  const packagingComponentQuery = PackagingPageLocationState.useQueryParams();

  return (
    <Page tracking={newPackagingComponentPageTracking}>
      <NewPackagingComponentEditor
        parentUrl={buildPath(
          pages.Packaging().url,
          pages.PackagingComponentsListPage.stringifyQueryParams(
            packagingComponentQuery
          )
        )}
      />
    </Page>
  );
}
