import VectorProps from "../VectorProps";

export default function Packaging(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from Foodsteps design library: https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?node-id=1-67&t=fK7PVc1de8AaJeL8-0
  return (
    <svg
      className={className}
      fill="currentColor"
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_1709)">
        <path
          d="M10.72 21.12C16.5079 21.12 21.2 16.4279 21.2 10.64C21.2 4.85206 16.5079 0.160004 10.72 0.160004C4.93205 0.160004 0.23999 4.85206 0.23999 10.64C0.23999 16.4279 4.93205 21.12 10.72 21.12Z"
          fill="#67DCC2"
        />
        <path
          d="M8.97595 10.056C8.48955 10.056 8.09595 9.66241 8.09595 9.17601C8.09595 8.32001 8.24795 7.48161 8.54715 6.68001C8.71675 6.22562 9.22395 5.99361 9.67995 6.16481C10.1343 6.33441 10.3663 6.84162 10.1951 7.29762C9.96955 7.90082 9.85595 8.53281 9.85595 9.17761C9.85595 9.66402 9.46235 10.0576 8.97595 10.0576V10.056Z"
          fill="black"
        />
        <path
          d="M9.23853 26.3776L16.1601 26.96L21.7601 30L24.6401 25.1616V13.3648C24.6401 12.2816 24.2513 11.2352 23.5457 10.4144L21.0289 7.4896C20.4657 6.8352 20.1313 6.0128 20.0769 5.1504L20.0337 4.4704"
          fill="#10675B"
        />
        <path
          d="M21.7601 30.88C21.6177 30.88 21.4737 30.8464 21.3409 30.7728L15.9025 27.8208L9.16489 27.2544C8.68009 27.2144 8.32169 26.7888 8.36169 26.304C8.40169 25.8192 8.82409 25.456 9.31209 25.5008L16.2337 26.0832C16.3553 26.0928 16.4721 26.128 16.5793 26.1872L21.4353 28.824L23.7585 24.92V13.3648C23.7585 12.4928 23.4449 11.6496 22.8769 10.9888L20.3601 8.06399C19.6753 7.26879 19.2625 6.25279 19.1969 5.20639L19.1537 4.52639C19.1233 4.04159 19.4913 3.62399 19.9777 3.59359C20.4625 3.56639 20.8801 3.93119 20.9105 4.41759L20.9537 5.09759C20.9953 5.76479 21.2577 6.41119 21.6945 6.91679L24.2113 9.84159C25.0545 10.8208 25.5185 12.0736 25.5185 13.3664V25.1632C25.5185 25.3216 25.4753 25.4768 25.3953 25.6128L22.5153 30.4512C22.3505 30.7264 22.0593 30.8816 21.7585 30.8816L21.7601 30.88Z"
          fill="black"
        />
        <path
          d="M20.048 5.47361V4.61761C20.048 4.16961 19.6848 3.80801 19.2384 3.80801H10.5024C10.0544 3.80801 9.6928 4.17121 9.6928 4.61761V5.46081C9.6928 6.01601 9.5936 6.56641 9.3984 7.08641L6.112 11.7824C5.8496 12.4816 5.7168 13.224 5.7168 13.9696V29.3152C5.7168 30.0016 6.2736 30.5584 6.96 30.5584H20.7504C21.4368 30.5584 21.9936 30.0016 21.9936 29.3152V13.952C21.9936 13.216 21.864 12.4864 21.6096 11.7952L20.3328 7.07521C20.144 6.56161 20.048 6.01921 20.048 5.47361Z"
          fill="#00A68F"
        />
        <path
          d="M20.7505 31.44H6.96011C5.78891 31.44 4.83691 30.4864 4.83691 29.3168V13.9712C4.83691 13.1152 4.98891 12.2768 5.28811 11.4752C5.31371 11.4064 5.34891 11.3392 5.39051 11.2784L8.60971 6.67841C8.74411 6.28641 8.81131 5.87681 8.81131 5.46081V4.61761C8.81131 3.68481 9.56971 2.92801 10.5009 2.92801H19.2369C20.1697 2.92801 20.9265 3.68641 20.9265 4.61761V5.47361C20.9265 5.91841 21.0049 6.35521 21.1585 6.77121C21.1681 6.79521 21.1761 6.82081 21.1825 6.84481L22.4497 11.5296C22.7313 12.3088 22.8737 13.1232 22.8737 13.952V29.3152C22.8737 30.4864 21.9201 31.4384 20.7505 31.4384V31.44ZM6.89931 12.192C6.69771 12.7648 6.59531 13.3616 6.59531 13.9712V29.3168C6.59531 29.5168 6.75851 29.68 6.95851 29.68H20.7489C20.9489 29.68 21.1121 29.5168 21.1121 29.3168V13.9536C21.1121 13.32 21.0017 12.696 20.7825 12.1008C20.7729 12.0768 20.7649 12.0512 20.7585 12.0272L19.4913 7.34241C19.2753 6.74241 19.1649 6.11361 19.1649 5.47361V4.68641H10.5681V5.46081C10.5681 6.12321 10.4497 6.77441 10.2177 7.39361C10.1921 7.46241 10.1569 7.52961 10.1153 7.59041L6.89611 12.192H6.89931Z"
          fill="black"
        />
        <path
          d="M10.0747 16.8528L9.98489 16.596C9.86074 16.2411 10.0483 15.8518 10.4032 15.7276L10.66 15.6378C13.5128 14.6399 16.64 16.1462 17.638 18.9991L17.7278 19.2558C17.8519 19.6107 17.6644 20.0001 17.3094 20.1243L17.0527 20.2141C14.1998 21.212 11.0727 19.7057 10.0747 16.8528Z"
          fill="#DCFFD1"
        />
        <path
          d="M15.2415 21.4064C14.2991 21.4064 13.3615 21.1936 12.4879 20.7728C10.9583 20.0352 9.80633 18.7472 9.24633 17.144L9.15673 16.888C8.87193 16.0752 9.30233 15.1824 10.1151 14.8976L10.3711 14.808C11.9743 14.248 13.6991 14.344 15.2287 15.0816C16.7583 15.8192 17.9103 17.1072 18.4703 18.7104L18.5599 18.9664C18.8447 19.7792 18.4143 20.672 17.6015 20.9568L17.3455 21.0464C16.6591 21.2864 15.9487 21.4064 15.2431 21.4064H15.2415ZM10.8831 16.4944L10.9071 16.5648C11.3119 17.7232 12.1455 18.656 13.2511 19.1888C14.3567 19.7216 15.6047 19.792 16.7647 19.3872L16.8335 19.3632L16.8095 19.2944C16.4047 18.136 15.5711 17.2032 14.4655 16.6704C13.3583 16.1376 12.1119 16.0672 10.9519 16.472L10.8831 16.496V16.4944Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2079_1709">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
