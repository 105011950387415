import VectorProps from "./VectorProps";

export default function Private(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?type=design&node-id=2436-2751&mode=design&t=4a3Igplx3STGs0T5-4
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.696 8.35294C13.6758 8.12477 13.5135 7.89913 13.3033 7.81601C13.0637 7.72112 12.8221 7.63329 12.5793 7.55126V4.73024C12.5789 2.12165 10.4573 0 7.8492 0C5.24078 0 3.11913 2.12165 3.11913 4.73024V7.55108C2.87629 7.63311 2.63472 7.72094 2.39514 7.81583C2.18453 7.8995 2.02245 8.12477 2.00217 8.35276H2V8.40075V8.49166V14.3736C2 14.6177 2.17004 14.8695 2.39496 14.9587C4.13849 15.6494 5.97365 16 7.8492 16C9.72438 16 11.5595 15.6494 13.3029 14.9587C13.5284 14.8691 13.698 14.6177 13.698 14.3736V8.49184V8.40093V8.35294H13.696ZM10.9525 7.1027C9.93662 6.88467 8.89916 6.77457 7.84883 6.77457C6.7987 6.77457 5.76123 6.88467 4.74532 7.1027V4.73024C4.74532 3.01877 6.13736 1.62619 7.84865 1.62619C9.56031 1.62619 10.9525 3.01877 10.9525 4.73024V7.1027Z"
        fill={fill}
      />
    </svg>
  );
}
