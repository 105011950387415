import { useEffect } from "react";
import { useLocation } from "react-router";

import { useSession } from "../sessions";

/**
 * Call f whenever we transition to a different page.
 *
 * A transition is triggered by a change in any of:
 *   * The current pathname
 *   * The logged in user ID
 *   * The impersonated user ID
 *
 * Note that just using the current pathname means that we ignore the query
 * string. The assumption is that a change in a query string (for instance,
 * changing the ordering of recipes on the recipe page) represents a
 * transition on the same page, rather than viewing a different page.
 */
export default function useOnPageTransition(f: () => void) {
  const location = useLocation();
  const session = useSession();

  const { impersonatedUser, userId } = session;

  useEffect(() => {
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonatedUser?.id, location.pathname, userId]);

  return null;
}
