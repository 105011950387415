import { useState } from "react";

import PackagingSwitch from "./PackagingSwitch";

const stories = {
  name: "PackagingSwitch",
  children: [
    {
      name: "Enabled",
      render: () => <EnabledStory />,
    },
    {
      name: "Disabled and on",
      render: () => <DisabledAndOnCheckedStory />,
    },
    {
      name: "Disabled and off",
      render: () => <EnabledAndCheckedStory />,
    },
  ],
};

function EnabledStory() {
  const [checked, setChecked] = useState(false);
  return (
    <>
      <p>Packaging is {checked ? "on" : "off"}.</p>
      <PackagingSwitch checked={false} onChange={setChecked} />
    </>
  );
}

function DisabledAndOnCheckedStory() {
  return (
    <>
      <PackagingSwitch checked={true} disabled={true} />
    </>
  );
}

function EnabledAndCheckedStory() {
  return (
    <>
      <PackagingSwitch checked={false} disabled={true} />
    </>
  );
}

export default stories;
