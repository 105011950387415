import React from "react";

interface SplitRowProps {
  className?: string;
  left: React.ReactNode;
  right: React.ReactNode;
  showBorder?: boolean;
  style?: React.CSSProperties;
}

export default function SplitRow(props: SplitRowProps) {
  const { className, left, right, showBorder = true, style } = props;

  return (
    <div className={`d-flex ${className}`} style={style}>
      <div
        style={{
          width: 250,
          position: "sticky",
          top: 0,
          flex: "initial",
          borderRight: showBorder ? "1px solid var(--interface-grey)" : "none",
        }}
      >
        {left}
      </div>
      <div
        style={{
          minWidth: 0,
          flex: 1,
        }}
      >
        {right}
      </div>
    </div>
  );
}
