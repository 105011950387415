import * as Vectors from "./index";

const stories = {
  name: "Illustrations",
  children: [
    ...Object.entries(Vectors).map(([vectorName, Vector]) => ({
      name: vectorName,
      render: () => {
        return <Vector width={32} />;
      },
    })),
  ],
};

export default stories;
