import { useState } from "react";

import { PrimaryButton } from "./Button";
import RotatingChevron from "./RotatingChevron";

const stories = {
  name: "RotatingChevron",
  render: () => <Story />,
};

function Story() {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <PrimaryButton onClick={() => setExpanded(!expanded)}>
        Rotate
      </PrimaryButton>
      <RotatingChevron className="ml-3" expanded={expanded} />
    </>
  );
}

export default stories;
