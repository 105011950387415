import { gql } from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";

import Checkbox from "../../utils/Checkbox";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult from "../../utils/ReadResult";
import useRecipeLabel from "../useRecipeLabel";
import { RecipeIsHotDrinkEditor_Recipe as Recipe } from "./RecipeIsHotDrinkEditor.graphql";

export type Value = {
  isHotDrink: boolean;
};

export function initialValue(recipe: Recipe | undefined): Value {
  if (recipe === undefined) {
    return {
      isHotDrink: false,
    };
  } else {
    return {
      isHotDrink: recipe.isHotDrink,
    };
  }
}

export function read(value: Value): ReadResult<Value, boolean> {
  return {
    hasError: false,
    value,
    input: value.isHotDrink,
  };
}

interface RecipeIsHotDrinkEditorProps {
  value: Value;
  onChange: (value: Value) => void;
}

export function RecipeIsHotDrinkEditor(props: RecipeIsHotDrinkEditorProps) {
  const { onChange, value } = props;

  const recipeLabel = useRecipeLabel();

  return (
    <div className="form-group">
      <div className="d-flex flex-row">
        <p className="text-muted">
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeIsHotDrinkEditor:helpText"
            defaultMessage="Is your {recipeLabel} a hot drink, e.g. tea or coffee?"
            values={{ recipeLabel: recipeLabel.singularLowercase }}
          />
        </p>
        <HelpModalTooltip>
          <HelpModalTooltip.Question
            question={
              <FormattedMessage
                id="components/recipes/RecipeEditor/RecipeIsHotDrinkEditor/HelpModalTooltip:question"
                defaultMessage="Why does this matter?"
              />
            }
            answer={
              <FormattedMessage
                id="components/recipes/RecipeEditor/RecipeIsHotDrinkEditor/HelpModalTooltip:answer"
                defaultMessage="
                  <p>The standard Foodsteps A-E carbon rating is based on carbon intensity, measured in kilograms of carbon dioxide equivalent <medium>per kilogram of food</medium>. This allows for a fair comparison between meals with different serving sizes (for example, a small starter and large main).</p>
                  <p>For drinks such as coffee which are often diluted, this doesn’t provide a useful comparison, because adding liquid usually reduces this metric, creating the false impression that the environmental impact is also reduced.</p>
                  <p>For hot drinks we instead use a different rating based on the metric “kilograms of carbon dioxide equivalent <medium>per serving</medium>”, which allows you to more easily compare the impact of an espresso and a flat white (for example).</p>
                  "
                values={{
                  p: (chunks: React.ReactNode) => <p>{chunks}</p>,
                  medium: (chunks: React.ReactNode) => (
                    <span className="medium-font">{chunks}</span>
                  ),
                }}
              />
            }
          />
        </HelpModalTooltip>
      </div>
      <Checkbox
        id="isHotDrinkEditor"
        defaultChecked={value.isHotDrink}
        label={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeIsHotDrinkEditor/Checkbox:label"
            defaultMessage="Yes, this {recipeLabel} is a hot drink"
            values={{ recipeLabel: recipeLabel.singularLowercase }}
          />
        }
        onChange={(isHotDrink) => onChange({ isHotDrink })}
      />
    </div>
  );
}

export const fragments = {
  recipe: gql`
    fragment RecipeIsHotDrinkEditor_Recipe on Recipe {
      isHotDrink
    }
  `,
};
