import { useUserInfoOrNull } from "../data-store/useUserInfo";
import * as featureFlags from "../domain/featureFlags";

function useFeatureFlags(): Array<string> {
  const userInfo = useUserInfoOrNull();
  return userInfo === null ? [] : userInfo.featureFlags;
}

export function useFeatureFlag(name: string): boolean {
  const featureFlags = useFeatureFlags();
  return featureFlags.includes(name);
}

export function useIsStaff(): boolean {
  return useFeatureFlag(featureFlags.isStaff);
}

export function useShouldHideIngredientsPage(): boolean {
  return useFeatureFlag(featureFlags.noIngredientsPage);
}
