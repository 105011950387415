import { ImpactRating } from "../../../../domain/impactRatings";

// We haven't found a way to download label SVGs while still using intl yet.
// Until we work out how to do that, we will have to translate the strings we need for labels here.
export function impactRatingToLetter(impactRating: ImpactRating) {
  switch (impactRating) {
    case "VLOW":
      return "A";
    case "LOW":
      return "B";
    case "MEDIUM":
      return "C";
    case "HIGH":
      return "D";
    case "VHIGH":
      return "E";
  }
}

export function impactRatingToName(
  impactRating: ImpactRating,
  locale: string | null
) {
  switch (impactRating) {
    case "VLOW":
      return locale === "es-419" ? "Muy Bajo" : "Very Low";
    case "LOW":
      return locale === "es-419" ? "Bajo" : "Low";
    case "MEDIUM":
      return locale === "es-419" ? "Mediano" : "Medium";
    case "HIGH":
      return locale === "es-419" ? "Alto" : "High";
    case "VHIGH":
      return locale === "es-419" ? "Muy Alto" : "Very High";
  }
}

export function landUseString(locale: string | null) {
  return locale === "es-419" ? "Uso de tierra" : "Land use";
}

export function landUsePerKgUnit(locale: string | null) {
  return locale === "es-419" ? "m\u00B2año / kg" : "m\u00B2year / kg";
}

export function landUseUnit(locale: string | null) {
  return locale === "es-419" ? "m\u00B2año" : "m\u00B2year";
}

export function serving(locale: string | null) {
  return locale === "es-419" ? "m\u00B2año / kg" : "serving";
}
