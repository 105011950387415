import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Stepper, { Step } from "../utils/Stepper";
import { BillingAddressPaymentStep } from "./BillingAddressPaymentStep";
import ConfirmPlanPaymentStep from "./ConfirmPlanPaymentStep";
import StripeElementsPaymentProvider from "./StripeElementsProvider";

interface PaymentProps {
  BillingAddressPaymentStep: ({
    onNext,
    setClientSecret,
  }: Pick<
    Parameters<typeof BillingAddressPaymentStep>[0],
    "onNext" | "setClientSecret"
  >) => JSX.Element;
  ConfirmPlanPaymentStep: ({
    onNext,
  }: Pick<
    Parameters<typeof ConfirmPlanPaymentStep>[0],
    "onNext"
  >) => JSX.Element;
  paymentDetailsPaymentStep: React.ReactNode;
}

export default function PaymentSteps(props: PaymentProps) {
  const {
    BillingAddressPaymentStep,
    ConfirmPlanPaymentStep,
    paymentDetailsPaymentStep,
  } = props;

  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const confirmPlanStep: Step = {
    hasError: false,
    label: (
      <FormattedMessage
        id="components/subscriptions/Payment:confirmYourPlan"
        defaultMessage="Confirm your plan"
      />
    ),
    render: ({ onNext }) => <ConfirmPlanPaymentStep onNext={onNext} />,
    renderControls: () => null,
  };

  const billingAddressStep: Step = {
    hasError: false,
    label: (
      <FormattedMessage
        id="components/subscriptions/Payment:enterYourBillingAddress"
        defaultMessage="Enter your billing address"
      />
    ),
    render: ({ onNext }) => {
      return (
        <BillingAddressPaymentStep
          onNext={onNext}
          setClientSecret={setClientSecret}
        />
      );
    },
    renderControls: () => null,
  };

  const paymentDetailsStep: Step = {
    hasError: false,
    label: (
      <FormattedMessage
        id="components/subscriptions/Payment:enterYourPaymentDetails"
        defaultMessage="Enter your payment details"
      />
    ),
    render: () => {
      if (clientSecret === null) {
        throw new Error(
          "Cannot load payment details if client secret is null."
        );
      }
      return (
        <StripeElementsPaymentProvider clientSecret={clientSecret}>
          {paymentDetailsPaymentStep}
        </StripeElementsPaymentProvider>
      );
    },
    renderControls: () => null,
  };

  const steps: Array<Step> = [
    confirmPlanStep,
    billingAddressStep,
    paymentDetailsStep,
  ];

  return (
    <div className="container">
      <Stepper steps={steps} />
    </div>
  );
}
