import VectorProps from "./VectorProps";

export default function ArrowRight(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?node-id=1192%3A1721&mode=dev
  return (
    <svg
      className={className}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3945 3.21296C10.1103 3.49693 10.1103 3.95765 10.3945 4.2416L13.5164 7.36357H0.727155C0.325593 7.36357 0 7.68941 0 8.09098C0 8.49255 0.325587 8.81838 0.727155 8.81838H13.5169L10.3945 11.9408C10.1103 12.2248 10.1103 12.6855 10.3945 12.9695C10.6784 13.2534 11.1389 13.2534 11.4231 12.9695L15.787 8.60553C16.071 8.32156 16.071 7.86084 15.787 7.57689L11.4231 3.21296C11.1389 2.92901 10.6784 2.92901 10.3945 3.21296Z"
        fill={fill}
      />
    </svg>
  );
}
