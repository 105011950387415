import { DuplicateRecipePrompt } from "./UploadRecipesPage";

const stories = {
  name: "UploadRecipesPage",
  children: [
    {
      name: "DuplicateRecipePrompt",
      render: () => (
        <DuplicateRecipePrompt
          onKeepBoth={() => alert("Keep Both")}
          onStop={() => alert("Stop")}
          onReplace={() => alert("Replace")}
          onSkipDuplicates={() => alert("Skip Duplicates")}
        />
      ),
    },
  ],
};

export default stories;
