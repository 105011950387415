import classNames from "classnames";
import React from "react";

interface CardTitleProps {
  center?: boolean;
  shortBottomMargin?: boolean;
  title: React.ReactNode;
}

export default function CardTitle(props: CardTitleProps) {
  const { center = false, shortBottomMargin = false, title } = props;

  return (
    <h3
      className={classNames({
        "text-center": center,
        "mb-2": shortBottomMargin,
        "mb-4": !shortBottomMargin,
      })}
    >
      {title}
    </h3>
  );
}
