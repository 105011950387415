import { gql } from "graphql-tag";
import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSelect from "react-select";

import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult from "../../utils/ReadResult";
import TooltipOverlay from "../../utils/TooltipOverlay";
import useId from "../../utils/useId";
import { Private as PrivateIcon } from "../../utils/Vectors";
import CollectionSelect from "./CollectionSelect";
import {
  RecipeCollectionsEditor_Collection as Collection,
  RecipeCollectionsEditor_Recipe as Recipe,
} from "./RecipeCollectionsEditor.graphql";
import RecipeEditorLabel from "./RecipeEditorLabel";
import "./RecipeCollectionsEditor.css";

export const label = (
  <FormattedMessage
    id="components/recipes/RecipeEditor/RecipeCollectionsEditor:label"
    defaultMessage="Collections"
  />
);

export type Value = Array<number>;

interface RecipeCollectionsEditorProps {
  onChange: (collectionIds: Array<number>) => void;
  possibleCollections: Array<Collection>;
  refreshPossibleCollections: () => void;
  value: Array<number>;
}

export function RecipeCollectionsEditor(props: RecipeCollectionsEditorProps) {
  const { onChange, possibleCollections, refreshPossibleCollections, value } =
    props;

  const id = useId();

  function handleChange(selectedCollections: ReadonlyArray<Collection>) {
    const collectionIds = selectedCollections.map(({ id }) => id);
    onChange(collectionIds);
  }

  const selectedCollections = value.flatMap((collectionId) => {
    const collection = possibleCollections.find(
      ({ id }) => collectionId === id
    );
    return collection === undefined ? [] : [collection];
  });

  return (
    <div className="form-group">
      <RecipeEditorLabel htmlFor={id}>{label}</RecipeEditorLabel>
      <HelpModalTooltip
        title={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeCollectionsEditor:collectionsTooltipModal/title"
            defaultMessage="Collections"
          />
        }
      >
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeCollectionsEditor:collectionsTooltipModal/text"
          defaultMessage="A collection is an optional way of categorising your products. Products can belong to multiple collections."
        />
      </HelpModalTooltip>
      <CollectionSelect
        collections={possibleCollections}
        id={id}
        onChange={handleChange}
        refreshCollections={refreshPossibleCollections}
        value={selectedCollections}
      />
    </div>
  );
}

export function initialValue(recipe: Recipe | undefined): Value {
  return recipe === undefined
    ? []
    : recipe.collections.map((collection) => collection.id);
}

export function read(value: Value): ReadResult<Value, Array<number>> {
  return { hasError: false, value, input: value };
}

interface LockedRecipeCollectionsEditorProps {
  lockedMessage?: React.ReactNode;
  onLockClick: () => void;
}

export function LockedRecipeCollectionsEditor(
  props: LockedRecipeCollectionsEditorProps
) {
  const { lockedMessage, onLockClick } = props;

  const id = useId();

  return (
    <div className="form-group">
      <div className="form-row ml-0">
        <RecipeEditorLabel htmlFor={id}>{label}</RecipeEditorLabel>
        <div className="collections-private-icon">
          <TooltipOverlay
            id="components/recipes/RecipeEditor/LockedRecipeCollectionsEditor:privateIconTooltip"
            overlay={lockedMessage}
            placement="right"
            style={{ maxWidth: "186px", marginLeft: "-2px" }}
          >
            <PrivateIcon width={20} handleClick={onLockClick} />
          </TooltipOverlay>
        </div>
      </div>
      <ReactSelect
        className="locked-collections-select"
        placeholder={
          <FormattedMessage
            id="components/recipes/RecipeEditor/LockedRecipeCollectionsEditor:lockedCollectionsEditor/placeholder"
            defaultMessage="e.g. Finger Food"
          />
        }
        styles={{
          control: () => ({
            backgroundColor: "white",
            border: "1px solid #CED4DA",
            borderRadius: "4px",
            display: "flex",
          }),
        }}
        isDisabled={true}
      />
    </div>
  );
}

export const fragments = {
  collection: gql`
    fragment RecipeCollectionsEditor_Collection on RecipeCollection {
      ...CollectionSelect_Collection
    }

    ${CollectionSelect.fragments.collection}
  `,

  recipe: gql`
    fragment RecipeCollectionsEditor_Recipe on Recipe {
      collections {
        id
      }
    }
  `,
};
