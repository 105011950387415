import {
  AddButton,
  DangerButton,
  IconButton,
  IconButtonProps,
  LightButton,
  LinkButton,
  PlusButton,
  PrimaryButton,
  PrimaryButtonLink,
  SecondaryButton,
  SecondaryButtonLink,
} from "./Button";
import { Delete } from "./Vectors";
import DownloadIcon from "./Vectors/Download";

const variants = [
  { name: "PrimaryButton", Component: PrimaryButton },
  { name: "SecondaryButton", Component: SecondaryButton },
  { name: "DangerButton", Component: DangerButton },
  { name: "LinkButton", Component: LinkButton },
];

const buttonStories = variants.map(({ name, Component }) => ({
  name,
  children: [
    {
      name: "Default props",
      render: () => <Component>Download</Component>,
    },
    {
      name: "disabled",
      render: () => <Component disabled>Download</Component>,
    },
    {
      name: "onClick",
      render: () => (
        <Component onClick={() => alert("Clicked!")}>Download</Component>
      ),
    },
    {
      name: "icon",
      render: () => (
        <Component icon={<DownloadIcon width={16} />}>Download</Component>
      ),
    },
    {
      name: "loading",
      render: () => <Component loading>Downloading…</Component>,
    },
    {
      name: "Small",
      render: () => <Component size="sm">Download</Component>,
    },
    {
      name: "Custom className",
      render: () => <Component className="p-5">Download</Component>,
    },
  ],
}));

const linkVariants = [
  { name: "PrimaryButtonLink", Component: PrimaryButtonLink },
  { name: "SecondaryButtonLink", Component: SecondaryButtonLink },
];

const buttonLinkStories = linkVariants.map(({ name, Component }) => ({
  name,
  children: [
    {
      name: "Default props",
      render: () => <Component to="/">Download</Component>,
    },
    {
      name: "disabled",
      render: () => (
        <Component disabled to="/">
          Download
        </Component>
      ),
    },
    {
      name: "target",
      render: () => (
        <Component target="_blank" to="/">
          {" "}
          Download
        </Component>
      ),
    },
    {
      name: "external",
      render: () => (
        <Component external to="/">
          Download
        </Component>
      ),
    },
    {
      name: "external and target",
      render: () => (
        <Component external target="_blank" to="/">
          Download
        </Component>
      ),
    },
    {
      name: "icon",
      render: () => (
        <Component icon={<DownloadIcon width={16} />} to="/">
          Download
        </Component>
      ),
    },
  ],
}));

const IconButtonStory = (props: Omit<IconButtonProps, "icon">) => {
  return <IconButton icon={<Delete width="1em" />} {...props} />;
};

const iconButtonStories = {
  name: "IconButton",
  children: [
    {
      name: "Default props",
      render: () => <IconButtonStory />,
    },
    {
      name: "disabled",
      render: () => <IconButtonStory disabled />,
    },
    {
      name: "onClick",
      render: () => <IconButtonStory onClick={() => alert("Clicked!")} />,
    },
    {
      name: "disabled + onClick",
      render: () => (
        <IconButtonStory disabled onClick={() => alert("Clicked!")} />
      ),
    },
    {
      name: "Custom className",
      render: () => <IconButtonStory className="p-5" />,
    },
  ],
};

const lightButtonStories = {
  name: "LightButton",
  children: [
    {
      name: "Default",
      render: () => (
        <LightButton onClick={() => alert("Clicked")}>Download</LightButton>
      ),
    },
    {
      name: "Icon",
      render: () => (
        <LightButton
          onClick={() => alert("Clicked")}
          icon={<DownloadIcon width={16} />}
        >
          Download
        </LightButton>
      ),
    },
    {
      name: "AddButton",
      render: () => <AddButton onClick={() => alert("Add")}>Add</AddButton>,
    },
  ],
};

const stories = {
  name: "Button",
  children: [
    {
      name: "PlusButton",
      render: () => <PlusButton />,
    },
    ...buttonStories,
    ...buttonLinkStories,
    iconButtonStories,
    lightButtonStories,
  ],
};

export default stories;
