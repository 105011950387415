import classNames from "classnames";

import { Unit } from "../../domain/units";
import * as FloatInput from "./FloatInput";
import "./WeightInput.css";

export interface Value {
  quantity: FloatInput.Value;
  unit: string;
}

export function initialValue(
  quantity: number | null,
  unit: string | null
): Value {
  return {
    quantity: FloatInput.initialValue(quantity),
    unit: unit ?? "kg",
  };
}

interface WeightInputProps {
  className?: string;
  value: Value;
  onChange: (value: Value) => void;
  units: Array<Unit>;
}

export function WeightInput(props: WeightInputProps) {
  const { className, value, onChange, units } = props;

  const handleQuantityChange = (quantity: FloatInput.Value) => {
    onChange({ ...value, quantity });
  };

  const handleUnitChange = (unit: string) => {
    onChange({ ...value, unit });
  };

  return (
    <div className={classNames(className, "WeightInput_Container")}>
      <FloatInput.FloatInput
        className="w-50"
        onChange={handleQuantityChange}
        value={value.quantity}
      />
      <select
        id="physicalInputEditorUnit"
        className="form-control w-50 ml-2 text-right"
        onChange={(event) => handleUnitChange(event.target.value)}
        value={value.unit}
      >
        {units.map((unit) => (
          <option key={unit.value} value={unit.value}>
            {unit.label}
          </option>
        ))}
      </select>
    </div>
  );
}
