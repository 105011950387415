import InformationModalTooltip from "./InformationModalTooltip";

const stories = {
  name: "InformationModalTooltip",
  render: () => (
    <InformationModalTooltip
      title={"Title"}
      body={
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
        "          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n" +
        "          minim veniam, quis nostrud exercitation ullamco laboris nisi ut\n" +
        "          aliquip ex ea commodo consequat. Duis aute irure dolor in\n" +
        "          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla\n" +
        "          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in\n" +
        "          culpa qui officia deserunt mollit anim id est laborum."
      }
    />
  ),
};

export default stories;
