import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import EffectType from "../../domain/EffectType";
import { useTags } from "../../services/useOrganizationFeatures";
import { usePages } from "../pages";
import { LinkButton } from "../utils/Button";

interface RecipeBreakdownProps {
  recipeId: number | undefined;
  recipeName: string | undefined;
  recipeImpact: string | undefined;
  recipeLabel: React.ReactNode;
  impactAdjective: string;
  impactUnit: EffectType["unit"];
}

export default function RecipeBreakdown(props: RecipeBreakdownProps) {
  const {
    impactUnit,
    impactAdjective,
    recipeId,
    recipeName = "...",
    recipeImpact = "...",
    recipeLabel,
  } = props;

  const history = useHistory();
  const pages = usePages();
  const hasFeatureTags = useTags();

  return (
    <>
      <h4
        className="medium-font text-center"
        style={{ paddingBottom: "30px", marginBottom: "0px" }}
      >
        <FormattedMessage
          id="components/dashboard/RecipeBreakdown:title"
          defaultMessage="{impactAdjective} Impact {recipeLabel}"
          values={{
            impactAdjective,
            recipeLabel,
          }}
        />
      </h4>
      {recipeId ? (
        <LinkButton
          onClick={() => {
            history.push(`${pages.Recipes(hasFeatureTags).url}/${recipeId}`);
          }}
        >
          {recipeName}
        </LinkButton>
      ) : (
        <h6 className="font-italic font-weight-normal py-3 text-center">
          {recipeName}
        </h6>
      )}
      <h5 className="medium-font mb-0 text-center">{recipeImpact}</h5>
      <h6 className="small text-center">{impactUnit}</h6>
    </>
  );
}
