import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { useTags } from "../../services/useOrganizationFeatures";
import useQuery from "../graphql/useQuery";
import Page from "../Page";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import {
  EditRecipeQuery,
  EditRecipeQueryVariables,
} from "./EditRecipePage.graphql";
import ProductEditor from "./ProductEditor/ProductEditor";
import useRecipeLabel from "./useRecipeLabel";

export default function EditRecipePage() {
  const pages = usePages();
  const hasFeatureTags = useTags();

  const { recipeId } = pages.Recipe(hasFeatureTags).useParams();
  const recipeLabel = useRecipeLabel();

  const parentUrl = pages.Recipe(hasFeatureTags).url({ id: recipeId });

  const { status: recipeDataStatus } = useQuery<
    EditRecipeQuery,
    EditRecipeQueryVariables
  >(recipeQuery(), { recipeId });

  return (
    <Page tracking={{ pageName: "edit-recipe" }} showNav={false}>
      <StatusDisplay status={recipeDataStatus}>
        {({ recipe }) =>
          recipe === null ? (
            <p>
              <FormattedMessage
                id="components/recipes/EditRecipePage:recipeNotFoundMessage"
                defaultMessage="{recipeLabel} not found."
                values={{ recipeLabel: recipeLabel.singularUppercase }}
              />
            </p>
          ) : (
            <ProductEditor recipe={recipe} parentUrl={parentUrl} />
          )
        }
      </StatusDisplay>
    </Page>
  );
}

function recipeQuery() {
  return gql`
    query EditRecipeQuery($recipeId: Int!) {
      recipe(filter: { ids: [$recipeId] }) {
        ...ProductEditor_Recipe
      }
    }

    ${ProductEditor.fragments.recipe()}
  `;
}
