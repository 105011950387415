import { useOrganizationId } from "../organizations/OrganizationProvider";
import { PrimaryButton } from "../utils/Button";
import { EditTagsButton } from "./EditTagsButton";

const stories = {
  name: "EditTagsButton",
  render: () => <Story />,
};

function Story() {
  const [organizationId] = useOrganizationId();

  return (
    <EditTagsButton
      control={(className, disabled, icon, label, onClick) => (
        <PrimaryButton
          className={className}
          disabled={disabled}
          icon={icon}
          onClick={onClick}
        >
          {label}
        </PrimaryButton>
      )}
      onCollectionsDeleted={(ids) =>
        alert(`tags '${[...ids].join(",")}' has been deleted`)
      }
      selectedAll={false}
      recipeFilter={{ ownerOrganizations: { id: organizationId } }}
    />
  );
}

export default stories;
