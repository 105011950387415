import VectorProps from "./VectorProps";

export default function Pencil(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from Foodsteps design library: https://www.figma.com/file/rLrIcFwMEs8d85RV3WYykA/Foodsteps-2023-Design-Library?node-id=1-67&t=yj2URITvmTHYKfAa-4
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.4545 0.985153L1.33037 10.107C1.24787 10.1893 1.18907 10.2927 1.16057 10.4058L0.027321 14.9258C-0.0391296 15.1832 0.0171214 15.457 0.179871 15.6673C0.342621 15.8776 0.593551 16.0007 0.859507 16.001C0.931956 16.0013 1.00426 15.9926 1.07446 15.9752L5.59026 14.8463V14.8462C5.70336 14.8177 5.80671 14.7589 5.88921 14.6764L15.0153 5.54572C15.6376 4.94469 15.9924 4.11879 15.9999 3.25361C16.0074 2.38843 15.667 1.55654 15.0552 0.94483C14.4435 0.332969 13.6116 -0.00736694 12.7464 0.000120995C11.8812 0.00762083 11.0553 0.362368 10.4543 0.984727L10.4545 0.985153ZM2.79263 10.4706L9.7879 3.47537L12.5254 6.21285L5.53011 13.2081L2.79263 10.4706ZM2.17762 11.6727L4.328 13.8231L1.45717 14.5436L2.17762 11.6727ZM14.1034 4.63447L13.4368 5.30105L10.6994 2.56372L11.3659 1.89714H11.3661C11.8551 1.40816 12.5677 1.2172 13.2357 1.39614C13.9038 1.57509 14.4255 2.09678 14.6044 2.7649C14.7834 3.43286 14.5924 4.14548 14.1034 4.6345L14.1034 4.63447Z" />
    </svg>
  );
}
