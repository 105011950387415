import { gql } from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { CookingPenaltyInput } from "../../../__generated__/globalTypes";
import { useAccurateCookingImpacts } from "../../../services/useOrganizationFeatures";
import { usePages } from "../../pages";
import * as FloatInput from "../../utils/FloatInput";
import HelpModalTooltip from "../../utils/HelpModalTooltip";
import ReadResult, { combineReadResults } from "../../utils/ReadResult";
import useRecipeLabel from "../useRecipeLabel";
import {
  RecipeCookingEditor_CookingImpact as CookingImpact,
  RecipeCookingEditor_Recipe as Recipe,
} from "./RecipeCookingEditor.graphql";
import * as RecipeCookingPenaltiesEditor from "./RecipeCookingPenaltiesEditor";
import RecipeEditorLabel from "./RecipeEditorLabel";
import * as RecipeServingsPerCookingBatchEditor from "./RecipeServingsPerCookingBatchEditor";

export const label = (
  <FormattedMessage
    id="components/recipes/RecipeEditor/RecipeCookingEditor:label"
    defaultMessage="Cooking"
  />
);

export interface Value {
  cookingPenaltiesEditorValue: RecipeCookingPenaltiesEditor.Value;
  servingsPerCookingBatch: RecipeServingsPerCookingBatchEditor.Value;
}

export function initialValue(
  recipe: Recipe | undefined,
  accurateCookingImpacts: boolean,
  isFoodManufacturerOrganization: boolean
): Value {
  return {
    cookingPenaltiesEditorValue: RecipeCookingPenaltiesEditor.initialValue(
      recipe,
      accurateCookingImpacts,
      isFoodManufacturerOrganization
    ),
    servingsPerCookingBatch: RecipeServingsPerCookingBatchEditor.initialValue(
      recipe,
      accurateCookingImpacts
    ),
  };
}

interface Input {
  cookingPenalties: Array<CookingPenaltyInput>;
  isCooked: boolean;
  servingsPerCookingBatch: number | null;
}

export function read(value: Value): ReadResult<Value, Input> {
  const result = combineReadResults({
    cookingPenaltiesEditorValue: RecipeCookingPenaltiesEditor.read(
      value.cookingPenaltiesEditorValue
    ),
    servingsPerCookingBatch: RecipeServingsPerCookingBatchEditor.read(
      value.servingsPerCookingBatch
    ),
  });

  return result.hasError
    ? result
    : {
        ...result,
        input: {
          cookingPenalties:
            result.input.cookingPenaltiesEditorValue.cookingPenalties,
          isCooked: result.input.cookingPenaltiesEditorValue.isCooked,
          servingsPerCookingBatch: result.input.servingsPerCookingBatch,
        },
      };
}

interface RecipeCookingEditorProps {
  cookingImpacts: Array<CookingImpact>;
  onChange: (value: Value) => void;
  servings: FloatInput.Value;
  value: Value;
}

export function RecipeCookingEditor(props: RecipeCookingEditorProps) {
  const { cookingImpacts, onChange, servings, value } = props;

  const hasAccurateCookingImpacts = useAccurateCookingImpacts();
  const recipeLabel = useRecipeLabel();

  return (
    <div className="form-group">
      <RecipeEditorLabel>{label}</RecipeEditorLabel>
      {hasAccurateCookingImpacts && <CookingHelpModal />}
      <p className="text-muted">
        <FormattedMessage
          defaultMessage="How is your {recipeLabel} cooked?"
          id="components/recipes/RecipeEditor/RecipeCookingEditor:subheading"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      </p>
      <div className="form-group">
        <RecipeCookingPenaltiesEditor.RecipeCookingPenaltiesEditor
          cookingImpacts={cookingImpacts}
          onChange={(cookingPenaltiesEditorValue) =>
            onChange({ ...value, cookingPenaltiesEditorValue })
          }
          value={value.cookingPenaltiesEditorValue}
        />
      </div>

      {value.servingsPerCookingBatch.type === "accurate cooking impacts" ? (
        <RecipeServingsPerCookingBatchEditor.RecipeServingsPerCookingBatchEditor
          onChange={(servingsPerCookingBatch) =>
            onChange({ ...value, servingsPerCookingBatch })
          }
          servings={servings}
          value={value.servingsPerCookingBatch}
        />
      ) : null}
    </div>
  );
}

function CookingHelpModal() {
  const pages = usePages();

  return (
    <HelpModalTooltip
      title={
        <FormattedMessage
          id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:cookingPenaltiesTooltipModal/title"
          defaultMessage="Cooking FAQs"
        />
      }
    >
      <HelpModalTooltip.Question
        answer={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:cookingHelpModal/cookingMethodNotListed/answer"
            defaultMessage="
                If your cooking method isn't listed, please
                <a>get in touch</a>.
              "
            values={{
              a: (chunks: React.ReactNode) => (
                <a href={pages.ContactUs.url} rel="noreferrer" target="_blank">
                  {chunks}
                </a>
              ),
            }}
          />
        }
        question={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:cookingHelpModal/cookingMethodNotListed/question"
            defaultMessage="What should I do if my cooking method isn't listed?"
          />
        }
      />
      <HelpModalTooltip.Question
        answer={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:cookingHelpModal/preheating/answer"
            defaultMessage='
            Preheating times are already included, so you should just enter the
            time used for cooking. For instance, if a product requires an oven to
            be preheated, and then used to cook for fifteen minutes, just enter
            "15".
          '
          />
        }
        question={
          <FormattedMessage
            id="components/recipes/RecipeEditor/RecipeCookingPenaltiesEditor:cookingHelpModal/preheating/question"
            defaultMessage="Should I include preheating times?"
          />
        }
      />
    </HelpModalTooltip>
  );
}

export const fragments = {
  cookingImpact: gql`
    fragment RecipeCookingEditor_CookingImpact on CookingImpact {
      ...RecipeCookingPenaltiesEditor_CookingImpact
    }

    ${RecipeCookingPenaltiesEditor.fragments.cookingImpact}
  `,

  recipe: gql`
    fragment RecipeCookingEditor_Recipe on Recipe {
      ...RecipeCookingPenaltiesEditor_Recipe
      ...RecipeServingsPerCookingBatchEditor_Recipe
    }

    ${RecipeCookingPenaltiesEditor.fragments.recipe}
    ${RecipeServingsPerCookingBatchEditor.fragments.recipe}
  `,
};
