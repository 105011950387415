import ScrollTable from "./ScrollTable";
import * as Vectors from "./Vectors/index";

const stories = {
  name: "ScrollTable",
  children: [
    { name: "Normal", render: () => <Story /> },
    { name: "Empty", render: () => emptyTable },
  ],
};

function Story() {
  const columns = [
    {
      key: "name",
      header: "Name",
      renderCell: (person: Person) => person.name,
    },
    {
      key: "email",
      header: "Email",
      renderCell: (person: Person) => person.email,
    },
    {
      key: "icon",
      header: "Icon",
      renderCell: (person: Person) => person.icon,
      centered: true,
    },
  ];

  return (
    <div className="m-3" style={{ height: 400 }}>
      <ScrollTable<Person>
        emptyState={"No data."}
        rows={rows}
        columns={columns}
        rowKey={(person) => person.email}
      />
    </div>
  );
}

interface Person {
  id: number;
  name: string;
  email: string;
  icon: React.ReactNode;
}

const people: Array<Person> = [
  {
    id: 1,
    name: "Fichard",
    email: "zichard@foodsteps.earth",
    icon: <Vectors.Ingredients width={20} />,
  },
  {
    id: 2,
    name: "Riktor",
    email: "riktor@foodsteps.earth",
    icon: <Vectors.Generate width={20} />,
  },
  {
    id: 3,
    name: "Loss",
    email: "loss@foodsteps.earth",
    icon: <Vectors.TagManagement width={20} />,
  },
  {
    id: 4,
    name: "Zingal",
    email: "zingal@foodsteps.earth",
    icon: <Vectors.House width={20} />,
  },
  {
    id: 5,
    name: "Sobbie",
    email: "sobbie@foodsteps.earth",
    icon: <Vectors.PromoCodeIcon width={20} />,
  },
  {
    id: 6,
    name: "Ymily",
    email: "ymily@foodsteps.earth",
    icon: <Vectors.Generate width={20} />,
  },
  {
    id: 7,
    name: "Gaul",
    email: "gaul@foodsteps.earth",
    icon: <Vectors.Flame width={20} />,
  },
  {
    id: 8,
    name: "Wo",
    email: "wo@foodsteps.earth",
    icon: <Vectors.Private width={20} />,
  },
];

const rows = people.concat(people).concat(people).concat(people).concat(people);

const emptyTable = (
  <div className="m-3" style={{ height: 300 }}>
    <ScrollTable
      rows={[]}
      emptyState={"No data."}
      rowKey={(row) => row}
      columns={[
        { header: "Column 1", renderCell: (row) => row, key: "1" },
        { header: "Column 2", renderCell: (row) => row, key: "2" },
      ]}
    />
  </div>
);

export default stories;
