import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useIntl } from "react-intl";

import * as monitoring from "../../monitoring";
import UserVisibleError from "../../util/UserVisibleError";

interface ErrorAlertProps {
  className?: string;
  defaultMessage?: React.ReactNode;
  onClose?: () => void;
  error: unknown;
}

export default function ErrorAlert(props: ErrorAlertProps) {
  const { className = "", defaultMessage, onClose, error } = props;
  const intl = useIntl();

  useEffect(() => {
    if (error && !UserVisibleError.isUserVisibleError(error)) {
      console.error(error);
      monitoring.reportError(error);
    }
  }, [error]);

  if (error) {
    const errorMessage = UserVisibleError.isUserVisibleError(error)
      ? error.messageNode
      : defaultMessage ??
        intl.formatMessage(
          {
            id: "components/utils/ErrorAlert:defaultMessage",
            defaultMessage: "Error encountered: {error}",
          },
          { error: (error as Error).message }
        );
    return (
      <Alert
        className={`m-0 ${className}`}
        dismissible={onClose !== undefined}
        onClose={onClose}
        variant="danger"
      >
        {errorMessage}
      </Alert>
    );
  } else {
    return null;
  }
}
