import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { RecipeCoProductInput } from "../../../__generated__/globalTypes";
import * as FloatInput from "../../utils/FloatInput";
import ReadResult, { combineReadResultsArray } from "../../utils/ReadResult";
import TableEditor from "../../utils/TableEditor";
import * as TextField from "../../utils/TextField";
import * as CoProductEditor from "./CoProductEditor";
import "./CoProductsEditor.css";
import { PhysicalOutputEditor_Recipe } from "./PhysicalOutputEditor.graphql";

export type Value = Array<CoProductEditor.Value>;

export function initialValue(
  recipe: PhysicalOutputEditor_Recipe | null
): Value {
  if (recipe === null) {
    return [];
  } else {
    return recipe.coProducts.map((coProduct) => {
      return {
        economicValuePerKg: FloatInput.initialValue(
          coProduct.economicValuePerKg
        ),
        key: `existing_${uuidv4()}`,
        name: TextField.initialValue(coProduct.name),
        weightQuantity: FloatInput.initialValue(coProduct.massQuantity),
        weightUnit: coProduct.massUnit.toString(),
      };
    });
  }
}

export function read(
  value: Value
): ReadResult<Value, Array<RecipeCoProductInput>> {
  return combineReadResultsArray(value.map(CoProductEditor.readCoProductInput));
}

interface CoProductsEditorProps {
  value: Value;
  onChange: (value: Value) => void;
}

export function CoProductsEditor(props: CoProductsEditorProps) {
  const { value, onChange } = props;
  return (
    <div className="CoProductsEditor">
      <div className="medium-font">
        <FormattedMessage
          defaultMessage="Co-Products"
          id="components/recipes/ProductEditor/CoProductsEditor:title"
        />
      </div>
      <TableEditor
        blank={CoProductEditor.blankValue}
        value={value}
        onChange={onChange}
        renderRow={({ onChange, onDelete, rowIndex, value }) => (
          <CoProductEditor.CoProductEditor
            value={value}
            onChange={(value) => onChange({ ...value })}
            onDelete={onDelete}
          />
        )}
        showAddButton={true}
      />
    </div>
  );
}
