import { useState } from "react";
import { FormattedMessage } from "react-intl";

import ActionModal from "./ActionModal";
import { IconButton, PrimaryButton } from "./Button";
import QuestionMarkTooltip from "./QuestionMarkTooltip";

import "./HelpModalTooltip.css";

interface HelpModalTooltipProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

export default function HelpModalTooltip(props: HelpModalTooltipProps) {
  const { children, title } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span
        className="ml-2"
        style={{ position: "relative", bottom: "0.125rem" }}
      >
        <IconButton
          icon={<QuestionMarkTooltip />}
          onClick={() => setShowModal(true)}
        />
      </span>
      <ActionModal show={showModal} title={title}>
        <ActionModal.Body>{children}</ActionModal.Body>
        <ActionModal.Footer>
          <div className="mr-auto">
            <PrimaryButton onClick={() => setShowModal(false)}>
              <FormattedMessage
                id="components/recipes/RecipeEditor/HelpModalTooltip:closeButton"
                defaultMessage="Got it"
              />
            </PrimaryButton>
          </div>
        </ActionModal.Footer>
      </ActionModal>
    </>
  );
}

interface HelpModalTooltipQuestionProps {
  answer: React.ReactNode;
  question: React.ReactNode;
}

function HelpModalTooltipQuestion(props: HelpModalTooltipQuestionProps) {
  const { answer, question } = props;

  return (
    <>
      <p className="HelpModalTooltipQuestion__question semi-bold-font mb-2 pt-2">
        {question}
      </p>
      <div className="mb-2">{answer}</div>
    </>
  );
}

HelpModalTooltip.Question = HelpModalTooltipQuestion;
