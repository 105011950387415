import classNames from "classnames";
import Table from "react-bootstrap/Table";

import "./ScrollTable.css";

export interface Column<T> {
  key: string;
  header: React.ReactNode;
  renderCell: (value: T) => React.ReactNode;
  align?: "left" | "center" | "right";
  width?: string;
}

interface ScrollTableProps<T> {
  columns: Array<Column<T>>;
  emptyState: React.ReactNode;
  rowKey: (value: T) => string | number;
  rows: Array<T>;
  hoverRow?: T | null;
  setHoverRow?: (row: T | null) => void;
}

export default function ScrollTable<T extends { id: number }>(
  props: ScrollTableProps<T>
) {
  const { columns, emptyState, rowKey, rows, hoverRow, setHoverRow } = props;

  const alignMentClassnames = (column: Column<T>) =>
    classNames({
      "text-center": column.align === "center",
      "text-right": column.align === "right",
    });

  return (
    <div className="ScrollTable">
      <Table>
        <thead>
          <tr>
            {columns.map((column: Column<T>) => (
              <th
                className={alignMentClassnames(column)}
                style={
                  column.width !== undefined ? { width: column.width } : {}
                }
                key={column.key}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="ScrollTable_EmptyStateContainer">
                  {emptyState}
                </div>
              </td>
            </tr>
          ) : (
            rows.map((row: T) => (
              <tr
                key={rowKey(row)}
                className={classNames({
                  row__highlighted: hoverRow?.id === row.id,
                })}
                onMouseOver={setHoverRow ? () => setHoverRow(row) : undefined}
                onMouseLeave={setHoverRow ? () => setHoverRow(null) : undefined}
              >
                {columns.map((column) => (
                  <td
                    key={`${rowKey(row)} ${column.key}`}
                    className={alignMentClassnames(column)}
                    style={
                      column.width !== undefined ? { width: column.width } : {}
                    }
                  >
                    {column.renderCell(row)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}
