import { FormattedMessage } from "react-intl";

import UserVisibleError from "./UserVisibleError";

export class BlankCompulsoryFieldError extends UserVisibleError {
  public readonly columnName: string;
  public readonly rowNumber: number;

  constructor(columnName: string, rowNumber: number) {
    super(
      "The " + columnName + " column had a blank value on row " + rowNumber,
      <FormattedMessage
        id="util/csv-errors:blankCompulsoryFieldErrorMessage"
        defaultMessage="The <b>{columnName}</b> column had a blank value on <b>row {rowNumber}</b>. Please provide a value for all rows in this column."
        values={{
          b: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          columnName,
          rowNumber,
        }}
      />
    );
    this.columnName = columnName;
    this.rowNumber = rowNumber;
  }
}

export class ParseBooleanError extends UserVisibleError {
  public readonly input: string;
  public readonly columnName: string;
  public readonly rowNumber: number;

  constructor(input: string, columnName: string, rowNumber: number) {
    super(
      "Could not parse boolean from: " + input,
      <FormattedMessage
        id="util/csv-errors:parseBooleanErrorMessage"
        defaultMessage="The value in the <medium>{columnName}</medium> column on <medium>row {rowNumber}</medium> must be 'yes' or 'no', but was: <em>{input}</em>."
        values={{
          medium: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
          input,
          columnName,
          rowNumber,
        }}
      />
    );
    this.input = input;
    this.columnName = columnName;
    this.rowNumber = rowNumber;
  }
}

export class ParseFloatError extends UserVisibleError {
  public readonly input: string;
  public readonly columnName: string;
  public readonly rowNumber: number;

  constructor(input: string, columnName: string, rowNumber: number) {
    super(
      "Could not parse float from: " + input,
      <FormattedMessage
        id="util/csv-errors:parseFloatErrorMessage"
        defaultMessage="There was a non-numeric value in the <b>{columnName}</b> column on <b>row {rowNumber}</b>: <em>{input}</em>."
        values={{
          b: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
          input,
          columnName,
          rowNumber,
        }}
      />
    );
    this.input = input;
    this.columnName = columnName;
    this.rowNumber = rowNumber;
  }
}

export class CannotBeZeroError extends UserVisibleError {
  public readonly columnName: string;
  public readonly rowNumber: number;

  constructor(columnName: string, rowNumber: number) {
    super(
      `Value for column name ${columnName} must be non-zero.`,
      <FormattedMessage
        id="util/csv-errors:cannotBeZeroError"
        defaultMessage="The <b>{columnName}</b> column had a value of zero on <b>row {rowNumber}</b>. Please provide a non-zero value for all rows in this column."
        values={{
          b: (chunks: React.ReactNode) => (
            <span className="medium-font">{chunks}</span>
          ),
          columnName,
          rowNumber,
        }}
      />
    );
    this.columnName = columnName;
    this.rowNumber = rowNumber;
  }
}
