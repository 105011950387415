import classNames from "classnames";
import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import TooltipOverlay from "../utils/TooltipOverlay";
import { Link, BrokenLink } from "../utils/Vectors";
import { RecipeIngredientLinking_RecipeIngredient as RecipeIngredient } from "./RecipeIngredientLinking.graphql";
import "./RecipeIngredientLinking.css";

interface IconProps {
  className?: string;
  ingredient: RecipeIngredient;
}

export function Icon(props: IconProps) {
  const { className, ingredient } = props;
  const finalClassName = classNames(className, textClassName(ingredient));

  return hasLinkedFoodClass(ingredient) ? (
    <LinkedIcon className={finalClassName} />
  ) : (
    <UnlinkedIcon className={finalClassName} />
  );
}

function textClassName(ingredient: RecipeIngredient): string {
  return hasLinkedFoodClass(ingredient)
    ? ""
    : "RecipeIngredientLinking__unlinked";
}

function hasLinkedFoodClass(ingredient: RecipeIngredient): boolean {
  return ingredient.foodClassId !== null;
}

interface RawIconProps {
  className?: string;
}

function UnlinkedIcon(props: RawIconProps) {
  const { className } = props;

  return (
    <TooltipOverlay
      overlay={
        <FormattedMessage
          id="components/recipes/RecipeIngredientsTable:unlinkedIconTooltipMessage"
          defaultMessage="This is a new ingredient that needs adding to our database!"
        />
      }
      id="components/recipes/RecipeIngredientsTable:unlinkedIconTooltip"
    >
      <BrokenLink className={className} width={16} />
    </TooltipOverlay>
  );
}

function LinkedIcon(props: RawIconProps) {
  const { className } = props;

  return (
    <TooltipOverlay
      overlay={
        <FormattedMessage
          id="components/recipes/RecipeIngredientsTable:linkedIconTooltipMessage"
          defaultMessage="We have found this ingredient in our database."
        />
      }
      id="components/recipes/RecipeIngredientsTable:linkedIconTooltip"
    >
      <Link className={className} width={16} />
    </TooltipOverlay>
  );
}

export const fragments = {
  recipeIngredient: gql`
    fragment RecipeIngredientLinking_RecipeIngredient on RecipeIngredient {
      foodClassId
    }
  `,
};
