import VectorProps from "./VectorProps";

export default function Report(props: VectorProps) {
  const { className = "", fill = "currentColor", width } = props;

  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4992 1.6H8.49922C8.00216 1.6 7.59922 2.00294 7.59922 2.5C7.59922 2.99706 8.00216 3.4 8.49922 3.4H11.4992C11.9963 3.4 12.3992 2.99706 12.3992 2.5C12.3992 2.00294 11.9963 1.6 11.4992 1.6ZM8.49922 0C7.11851 0 5.99922 1.11929 5.99922 2.5C5.99922 3.88071 7.11851 5 8.49922 5H11.4992C12.8799 5 13.9992 3.88071 13.9992 2.5C13.9992 1.11929 12.8799 0 11.4992 0H8.49922Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19922 4.1C2.19922 2.77452 3.27374 1.7 4.59922 1.7H6.49922V3.3H4.59922C4.15739 3.3 3.79922 3.65817 3.79922 4.1V17.4C3.79922 17.8418 4.15739 18.2 4.59922 18.2H15.3992C15.841 18.2 16.1992 17.8418 16.1992 17.4V4.1C16.1992 3.65817 15.841 3.3 15.3992 3.3H12.9992V1.7H15.3992C16.7247 1.7 17.7992 2.77452 17.7992 4.1V17.4C17.7992 18.7255 16.7247 19.8 15.3992 19.8H4.59922C3.27374 19.8 2.19922 18.7255 2.19922 17.4V4.1Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19922 8.5C5.19922 8.05817 5.55739 7.7 5.99922 7.7H9.99922C10.441 7.7 10.7992 8.05817 10.7992 8.5C10.7992 8.94183 10.441 9.3 9.99922 9.3H5.99922C5.55739 9.3 5.19922 8.94183 5.19922 8.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19922 11.5C5.19922 11.0582 5.55739 10.7 5.99922 10.7H11.9992C12.441 10.7 12.7992 11.0582 12.7992 11.5C12.7992 11.9418 12.441 12.3 11.9992 12.3H5.99922C5.55739 12.3 5.19922 11.9418 5.19922 11.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19922 14.5C5.19922 14.0582 5.55739 13.7 5.99922 13.7H13.9992C14.441 13.7 14.7992 14.0582 14.7992 14.5C14.7992 14.9418 14.441 15.3 13.9992 15.3H5.99922C5.55739 15.3 5.19922 14.9418 5.19922 14.5Z"
        fill={fill}
      />
    </svg>
  );
}
