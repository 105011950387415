import VectorProps from "../VectorProps";

export default function SmartphoneCharges(props: VectorProps) {
  const { width } = props;

  // from https://www.figma.com/file/Z5JvdIZfbok8AxNw5lmpCU/DQS?node-id=15663%3A7005&mode=dev

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5376 25.44C24.6672 25.44 24.7744 25.3344 24.7744 25.2032V23.28H21.4144V25.2032C21.4144 25.3328 21.52 25.44 21.6512 25.44H24.5392H24.5376Z"
        fill="#10675B"
      />
      <path
        d="M22.2943 24.16V24.56H23.8943V24.16H22.2943ZM21.1807 22.4H25.0079C25.3647 22.4 25.6543 22.6896 25.6543 23.0464V25.2032C25.6543 25.8192 25.1551 26.32 24.5375 26.32H21.6495C21.0335 26.32 20.5327 25.8208 20.5327 25.2032V23.0464C20.5327 22.6896 20.8223 22.4 21.1791 22.4H21.1807Z"
        fill="black"
      />
      <path
        d="M17.9102 30.1104C20.2046 30.1104 22.1358 29.2688 23.1742 27.784C24.4878 25.9072 23.6414 23.4144 23.6046 23.3104C23.4446 22.8512 22.9422 22.6096 22.4846 22.7696C22.0254 22.9296 21.7838 23.432 21.9438 23.8896C21.9486 23.904 22.5262 25.6432 21.7342 26.776C20.7966 28.1152 18.803 28.4528 17.2478 28.3232C15.5822 28.184 14.2126 27.848 13.0254 24.9472C11.8206 22.0032 8.77582 21.472 7.33422 21.6048C6.85102 21.648 6.49422 22.0768 6.53742 22.5616C6.58062 23.0448 7.00782 23.4016 7.49102 23.3584C7.62062 23.3488 10.3902 23.1568 11.3966 25.6144C12.955 29.424 15.1582 29.9168 17.1006 30.0784C17.3742 30.1008 17.6446 30.112 17.9102 30.112V30.1104Z"
        fill="black"
      />
      <path
        d="M12.2944 21.12C6.50647 21.12 1.81441 16.4279 1.81441 10.64C1.81441 4.85206 6.50647 0.160004 12.2944 0.160004C18.0824 0.160004 22.7744 4.85206 22.7744 10.64C22.7744 16.4279 18.0824 21.12 12.2944 21.12Z"
        fill="#67DCC2"
      />
      <path
        d="M19.0496 2.57919H26.7648C27.8446 2.57919 28.72 3.45457 28.72 4.53439V22.0272C28.72 23.107 27.8446 23.9824 26.7648 23.9824H19.0496C17.9697 23.9824 17.0944 23.107 17.0944 22.0272V4.53439C17.0944 3.45457 17.9697 2.57919 19.0496 2.57919Z"
        fill="#DCFFD1"
      />
      <path
        d="M19.0495 24.8608H26.7647C28.3279 24.8608 29.5999 23.5888 29.5999 22.0256V4.53442C29.5999 2.97122 28.3279 1.69922 26.7647 1.69922H19.0495C17.4863 1.69922 16.2143 2.97122 16.2143 4.53442V22.0272C16.2143 23.5904 17.4863 24.8624 19.0495 24.8624V24.8608ZM26.7647 3.45922C27.3567 3.45922 27.8399 3.94082 27.8399 4.53442V22.0272C27.8399 22.6208 27.3583 23.1024 26.7647 23.1024H19.0495C18.4575 23.1024 17.9743 22.6208 17.9743 22.0272V4.53442C17.9743 3.94082 18.4559 3.45922 19.0495 3.45922H26.7647Z"
        fill="black"
      />
      <path
        d="M19.1167 3.26559H26.6975C26.6975 3.96319 26.1311 4.52959 25.4335 4.52959H20.3791C19.6815 4.52959 19.1151 3.96319 19.1151 3.26559H19.1167Z"
        fill="black"
      />
      <path
        d="M6.06231 26.64C6.80791 26.64 7.41431 26.0336 7.41431 25.288V19.5136C7.41431 18.768 6.80791 18.1616 6.06231 18.1616H1.74871C1.51991 18.1616 1.33591 18.3472 1.33591 18.5744V26.2272C1.33591 26.456 1.52151 26.64 1.74871 26.64H6.06231Z"
        fill="#10675B"
      />
      <path
        d="M2.21432 19.04V25.76H6.06232C6.32312 25.76 6.53432 25.5472 6.53432 25.288V19.5136C6.53432 19.2528 6.32152 19.0416 6.06232 19.0416H2.21432M1.74712 17.2816H6.06072C7.29432 17.2816 8.29272 18.2816 8.29272 19.5136V25.288C8.29272 26.5216 7.29272 27.52 6.06072 27.52H1.74712C1.03352 27.52 0.454324 26.9408 0.454324 26.2272V18.5744C0.454324 17.8592 1.03352 17.2816 1.74712 17.2816Z"
        fill="black"
      />
      <path
        d="M22.9122 18.96C22.5538 18.96 22.205 18.8112 21.957 18.552L20.4834 17.0128C20.117 16.6304 20.0146 16.0672 20.2226 15.5808C20.4306 15.0928 20.9074 14.7792 21.437 14.7792H21.485L21.8418 14.0528H20.9714C20.4546 14.0528 19.981 13.7904 19.7074 13.352C19.4338 12.9136 19.405 12.3728 19.6338 11.9088L20.9874 9.1424C21.2082 8.6928 21.6738 8.4016 22.1746 8.4016H22.8162C23.2754 8.4016 23.6946 8.6336 23.9378 9.024C24.181 9.4144 24.205 9.8928 24.0034 10.304L23.7442 10.8336H24.6354C25.1538 10.8336 25.6274 11.096 25.901 11.5376C26.1746 11.9776 26.2002 12.5184 25.9714 12.984L25.0066 14.9344C25.2674 15.0736 25.4786 15.2976 25.6002 15.5824C25.8082 16.0704 25.7058 16.632 25.3394 17.0144L23.8658 18.5536C23.6178 18.8128 23.2706 18.9616 22.9106 18.9616L22.9122 18.96Z"
        fill="#00A68F"
      />
      <path
        d="M22.8162 9.28C23.1426 9.28 23.3554 9.6224 23.213 9.9152L22.4866 11.3968C22.4146 11.5424 22.5218 11.712 22.6834 11.712H24.6354C25.0882 11.712 25.3826 12.1872 25.1826 12.592L23.6674 15.6576H24.3858C24.7746 15.6576 24.973 16.1232 24.7042 16.4048L23.2306 17.944C23.1442 18.0352 23.0274 18.08 22.9122 18.08C22.797 18.08 22.6802 18.0352 22.5938 17.944L21.1202 16.4048C20.8514 16.1248 21.0498 15.6576 21.4386 15.6576H22.0354L23.0994 13.4864C23.1714 13.3408 23.0642 13.1712 22.9026 13.1712H20.9714C20.5202 13.1712 20.2258 12.6992 20.4242 12.2928L21.7778 9.5264C21.8514 9.3744 22.005 9.2784 22.1746 9.2784H22.8162M22.8162 7.5184H22.1746C21.3394 7.5184 20.5634 8.0032 20.197 8.752L18.8434 11.5184C18.4818 12.2576 18.525 13.1152 18.9618 13.8144C19.1762 14.1568 19.4658 14.432 19.8034 14.624C19.6434 14.8016 19.5122 15.0064 19.4146 15.2336C19.0674 16.0448 19.2386 16.9824 19.8482 17.6208L21.3218 19.16C21.7346 19.592 22.3138 19.84 22.9122 19.84C23.5106 19.84 24.0898 19.592 24.5026 19.16L25.9762 17.6208C26.5874 16.9824 26.757 16.0464 26.4098 15.2336C26.3282 15.0432 26.2226 14.8688 26.0962 14.7136L26.7602 13.3712C27.1266 12.632 27.085 11.7712 26.6482 11.0704C26.2786 10.4752 25.6802 10.0848 25.0034 9.9792C25.0626 9.4896 24.9538 8.9888 24.6834 8.5552C24.2786 7.9056 23.581 7.5184 22.8162 7.5184Z"
        fill="black"
      />
    </svg>
  );
}
