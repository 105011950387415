import { useState } from "react";

import UserVisibleError from "../../util/UserVisibleError";
import Form from "./Form";
import useId from "./useId";

const stories = {
  name: "Form",
  render: () => <Story />,
};

function Story() {
  const errorInputId = useId();
  const [error, setError] = useState(false);
  const nameInputId = useId();
  const [name, setName] = useState("");

  const handleSubmit = async () => {
    if (name === "") {
      throw new UserVisibleError("Name is required.");
    }
    await sleep(2000);
    if (error) {
      throw new Error("Error!");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor={nameInputId}>Name</label>
        <input
          className="form-control"
          id={nameInputId}
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
      </div>
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={errorInputId}
          onChange={(event) => setError(event.target.checked)}
          checked={error}
        />
        <label className="form-check-label" htmlFor={errorInputId}>
          Error
        </label>
      </div>
      <Form.ErrorAlert className="mb-4" />
      <Form.SubmitButton loadingLabel="Saving" submitLabel="Save" />
    </Form>
  );
}

function sleep(interval: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), interval);
  });
}

export default stories;
