import { useState, ReactElement } from "react";

import Select, { GroupedSelect, MultiSelect, Option } from "./Select";
import { Search } from "./Vectors";

interface ExampleObject {
  name: string;
  id: string;
}

const defaultOptions: Array<ExampleObject> = [
  { name: "Country", id: "country" },
  { name: "Cave", id: "cave" },
  { name: "Accent", id: "accent" },
];

async function loadOptions(input: string): Promise<Array<ExampleObject>> {
  await new Promise((resolve) => setTimeout(() => resolve(null), 500));

  return defaultOptions.filter((option) =>
    option.name.toLowerCase().includes(input.toLowerCase())
  );
}

const stories = {
  name: "Select",
  children: [
    {
      name: "Default Select",
      render: () => <SelectStory />,
    },
    {
      name: "Disabled Select",
      render: () => <SelectStory disabled />,
    },
    {
      name: "Select in error state",
      render: () => <SelectStory hasError />,
    },
    {
      name: "Non-clearable Select",
      render: () => <SelectStory isClearable={false} />,
    },
    {
      name: "Select with dynamic options",
      render: () => <SelectStory options={loadOptions} />,
    },
    {
      name: "Select with DownUpArrow icon",
      render: () => <SelectStory dropdownArrow="upDown" />,
    },
    {
      name: "Select with no dropdown arrow icon",
      render: () => <SelectStory dropdownArrow="none" />,
    },
    {
      name: "Multi Select",
      render: () => <MultiSelectStory />,
    },
    {
      name: "Disabled Multi Select",
      render: () => <MultiSelectStory disabled />,
    },
    {
      name: "Multi Select with dynamic options",
      render: () => <MultiSelectStory options={loadOptions} />,
    },
    {
      name: "GroupedSelect",
      render: () => <GroupedSelectStory />,
    },
    {
      name: "GroupedSelect in error state",
      render: () => <GroupedSelectStory hasError={true} />,
    },
    {
      name: "GroupedSelect with icon",
      render: () => <GroupedSelectStory icon={<Search width="1rem" />} />,
    },
  ],
};

interface SelectStoryProps {
  disabled?: boolean;
  hasError?: boolean;
  isClearable?: boolean;
  dropdownArrow?: "normal" | "upDown" | "none";
  options?: (input: string) => Promise<Array<ExampleObject>>;
}

function SelectStory(props: SelectStoryProps) {
  const {
    disabled,
    hasError,
    isClearable,
    dropdownArrow = "normal",
    options = defaultOptions,
  } = props;
  const [value, setValue] = useState<ExampleObject | null>(null);

  return (
    <div className="m-3">
      <Select<ExampleObject>
        disabled={disabled}
        hasError={hasError}
        isClearable={isClearable}
        dropdownArrow={dropdownArrow}
        onChange={(value) => {
          setValue(value);
        }}
        optionKey={(exampleObject) => exampleObject.id}
        options={options}
        renderOption={(exampleObject) => exampleObject.name}
        value={value}
      />
    </div>
  );
}

function MultiSelectStory(props: SelectStoryProps) {
  const { disabled, options = defaultOptions } = props;
  const [values, setValues] = useState<ReadonlyArray<ExampleObject>>([]);

  return (
    <div className="m-3">
      <MultiSelect<ExampleObject>
        disabled={disabled}
        onChange={(values) => {
          setValues(values);
        }}
        optionKey={(exampleObject) => exampleObject.id}
        options={options}
        renderOption={(exampleObject) => exampleObject.name}
        value={values}
      />
    </div>
  );
}

interface GroupedSelectStoryProps {
  hasError?: boolean;
  icon?: ReactElement;
}

function GroupedSelectStory(props: GroupedSelectStoryProps) {
  const { hasError, icon } = props;

  const [value, setValue] = useState<Option<number> | null>(null);

  const handleChange = (option: Option<number> | null) => {
    setValue(option);
  };

  return (
    <div className="m-3">
      <GroupedSelect<number>
        hasError={hasError}
        icon={icon}
        groups={[
          {
            label: "Group 1",
            options: [
              { label: "Option 1", value: 1 },
              { label: "Option 2", value: 2 },
              { label: "Option 3", value: 3 },
            ],
          },
          {
            label: "Group 2",
            options: [
              { label: "Option 4", value: 4 },
              { label: "Option 5", value: 5 },
              { label: "Option 6", value: 6 },
            ],
          },
        ]}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default stories;
