// This method is adapted from a StackOverflow post
// https://stackoverflow.com/questions/3975499/convert-svg-to-image-jpeg-png-etc-in-the-browser
import { renderToStaticMarkup } from "react-dom/server";

export async function convertSvgToBase64Png({
  height,
  svgElement,
  width,
}: {
  height: number;
  svgElement: JSX.Element;
  width: number;
}): Promise<string> {
  // TODO: Avoid importing react-dom/server when we have upgraded react to ^18.0.0
  //  https://react.dev/reference/react-dom/server/renderToString#removing-rendertostring-from-the-client-code
  // Render React element to an HTML string
  const svgHtmlString = renderToStaticMarkup(svgElement);

  const svgData = encodeAsUTF8(svgHtmlString);
  const image = await loadImage(svgData);

  const canvas = drawCanvasImage(image, height, width);

  const dataUrl = canvas.toDataURL(`image/png`, 1.0);

  return getBase64FromDataUrl(dataUrl);
}

// Encode URI data as UTF8 data:
function encodeAsUTF8(elementString: string) {
  const dataHeader = "data:image/svg+xml;charset=utf-8";
  return `${dataHeader},${encodeURIComponent(elementString)}`;
}

function drawCanvasImage(
  image: HTMLOrSVGImageElement,
  height: number,
  width: number
) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d")!.drawImage(image, 0, 0);
  return canvas;
}

function getBase64FromDataUrl(dataUrl: string) {
  const index = dataUrl.indexOf("base64,") + "base64,".length;
  return dataUrl.substring(index);
}

// This function returns a Promise whenever the image is loaded
const loadImage = async (url: string): Promise<HTMLOrSVGImageElement> => {
  const image = document.createElement("img");
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = url;
  });
};
