import VectorProps from "./VectorProps";

export default function Doughnut(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from icons-8
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 128 128"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 64 6 C 62.3 6 61 7.3 61 9 L 61 34 C 61 35.7 62.3 37 64 37 C 78.9 37 91 49.1 91 64 C 91 68.8 89.700781 73.499609 87.300781 77.599609 C 86.900781 78.299609 86.8 79.100391 87 79.900391 C 87.2 80.700391 87.700391 81.299219 88.400391 81.699219 L 110 94.199219 C 110.5 94.499219 111 94.599609 111.5 94.599609 C 112.5 94.599609 113.49961 94.099609 114.09961 93.099609 C 119.19961 84.299609 122 74.2 122 64 C 122 32 96 6 64 6 z M 52.072266 10.402344 C 51.850781 10.405469 51.625391 10.4375 51.400391 10.5 C 27.100391 16.2 9 38 9 64 C 9 69.4 9.7992188 74.6 11.199219 79.5 C 11.799219 81.3 13.899609 82.199219 15.599609 81.199219 L 32.900391 71.300781 C 34.000391 70.700781 34.500391 69.500781 34.400391 68.300781 C 34.100391 66.900781 34 65.4 34 64 C 34 51.3 41.899609 40.4 53.099609 36 C 54.299609 35.6 55 34.399219 55 33.199219 L 55 13.400391 C 55 11.737891 53.622656 10.380469 52.072266 10.402344 z M 67 12.099609 C 94.3 13.699609 116 36.3 116 64 C 116 72.2 114.10039 80.2 110.40039 87.5 L 93.900391 77.900391 C 95.900391 73.600391 97 68.8 97 64 C 97 46.8 83.8 32.699609 67 31.099609 L 67 12.099609 z M 38.101562 75.927734 C 37.520312 75.883203 36.925391 76.000781 36.400391 76.300781 L 14.699219 88.599609 C 13.999219 88.999609 13.500781 89.600391 13.300781 90.400391 C 13.100781 91.200391 13.199609 91.999219 13.599609 92.699219 C 23.899609 110.79922 43.2 122 64 122 C 78.8 122 92.799609 116.50039 103.59961 106.40039 C 104.79961 105.30039 104.89922 103.39922 103.69922 102.19922 C 102.59922 100.89922 100.7 100.9 99.5 102 C 89.8 111 77.2 116 64 116 C 46.4 116 30.000391 107.00078 20.400391 92.300781 L 36.900391 82.900391 C 43.100391 91.700391 53.2 97 64 97 C 65.7 97 67 95.7 67 94 C 67 92.3 65.7 91 64 91 C 54.3 91 45.4 85.800391 40.5 77.400391 C 40 76.525391 39.070313 76.001953 38.101562 75.927734 z M 81 86 A 3 3 0 0 0 78 89 A 3 3 0 0 0 81 92 A 3 3 0 0 0 84 89 A 3 3 0 0 0 81 86 z"></path>
    </svg>
  );
}
