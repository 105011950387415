import classNames from "classnames";

import VectorProps from "./VectorProps";
import "./HotDrink.css";

export default function HotDrink(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from bootstrap-icons
  return (
    <svg
      className={classNames(className, "HotDrink__svg")}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" />
      <g clipPath="url(#clip0_619_720)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7769 23.98H17.606C18.7875 23.98 19.7741 23.0906 19.8993 21.9133L20.1621 19.4509C21.9965 19.2952 23.5244 17.8486 23.7217 15.9473C23.9181 14.0546 22.7295 12.3319 20.9793 11.7932L21.1134 10.5368C21.1703 10.0037 20.9968 9.47066 20.64 9.07158C20.2831 8.6725 19.7713 8.44446 19.2367 8.44446H10.1462C9.61159 8.44446 9.09976 8.6725 8.74291 9.07158C8.38463 9.46923 8.2126 10.0037 8.26947 10.5368L9.48363 21.9133C9.60874 23.0921 10.5954 23.98 11.7769 23.98ZM20.8077 13.4017L20.3337 17.8429C21.2957 17.6311 22.0592 16.8196 22.1663 15.7848C22.2728 14.7557 21.6976 13.8108 20.8077 13.4017ZM9.90588 10.1192C9.94143 10.0793 10.021 10.0123 10.1462 10.0123H19.2367C19.3618 10.0123 19.4415 10.0793 19.477 10.1192C19.5125 10.1591 19.5708 10.2446 19.558 10.37L18.3439 21.7466C18.3041 22.1257 17.987 22.4122 17.606 22.4122H11.7769C11.3973 22.4122 11.0788 22.1257 11.039 21.7466L9.82485 10.37C9.81205 10.2446 9.87034 10.1591 9.90588 10.1192Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_619_720">
          <rect
            width="16"
            height="16"
            fill="currentColor"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
