import { MutableRefObject, useLayoutEffect, useState } from "react";
import { useCallbackOne } from "use-memo-one";

export function useIsOverflowing<T extends HTMLElement | null>(
  ref: MutableRefObject<T>,
  callback?: (hasOverflow: boolean) => void
): [boolean | undefined, () => void] {
  const [isOverflowing, setIsOverflowing] = useState<boolean | undefined>(
    undefined
  );

  const trigger = useCallbackOne(() => {
    if (ref.current === null) {
      return;
    }

    const hasOverflow =
      ref.current.scrollHeight > ref.current.clientHeight ||
      ref.current.scrollWidth > ref.current.clientWidth;
    setIsOverflowing(hasOverflow);

    if (callback !== undefined) {
      callback(hasOverflow);
    }
  }, [callback, ref]);

  useLayoutEffect(() => {
    if (ref.current === null) {
      return;
    }

    if ("ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(ref.current);
    }

    trigger();
  }, [callback, ref, trigger]);

  return [isOverflowing, trigger];
}
