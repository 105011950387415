import VectorProps from "./VectorProps";

export default function BackArrow(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2889 0.275664C12.6564 0.643216 12.6564 1.23914 12.2889 1.60669L5.89558 8L12.2889 14.3933C12.6564 14.7609 12.6564 15.3568 12.2889 15.7243C11.9213 16.0919 11.3254 16.0919 10.9579 15.7243L3.89904 8.66551C3.53149 8.29796 3.53149 7.70204 3.89904 7.33449L10.9579 0.275664C11.3254 -0.0918881 11.9213 -0.0918881 12.2889 0.275664Z"
        fill={fill}
      />
    </svg>
  );
}
