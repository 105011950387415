import * as graphql from "graphql";
import { useCallbackOne } from "use-memo-one";

import { useGraphQL } from "./GraphQLProvider";

export interface MutationOptions<TVariables extends {} | null> {
  variables: TVariables;
}

export default function useMutation<
  TQuery,
  TVariables extends {} | null = null
>(
  query: graphql.DocumentNode
): [(options: MutationOptions<TVariables>) => Promise<TQuery>] {
  const graphQL = useGraphQL();

  const mutate = useCallbackOne(
    ({ variables }: MutationOptions<TVariables>) =>
      graphQL.fetch<TQuery, TVariables>({ query, variables }),
    [graphQL, query]
  );

  return [mutate];
}
