import { useState } from "react";

import Form from "./Form";
import Select from "./Select";
import * as SelectField from "./SelectField";

const stories = {
  name: "SelectField",
  render: () => <Story />,
};

function Story() {
  const [value, setValue] = useState<SelectField.Value<TransportMode>>(
    SelectField.blankValue()
  );

  async function handleSubmit() {
    const readResult = SelectField.read(value);
    setValue(readResult.value);
    alert("Read result:\n\n" + JSON.stringify(readResult));
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-group">
        <SelectField.SelectField
          onChange={(value) => setValue(value)}
          renderSelect={(selectProps) => (
            <TransportModeSelect options={transportModes} {...selectProps} />
          )}
          value={value}
        />
      </div>

      <div className="form-group">
        <Form.SubmitButton submitLabel="Read" />
      </div>
    </Form>
  );
}

interface TransportMode {
  id: number;
  name: string;
}

const transportModes = [
  { id: 1, name: "Road" },
  { id: 2, name: "Rail" },
];

function transportModeKey(transportMode: TransportMode) {
  return transportMode.id.toString();
}

function renderTransportMode(transportMode: TransportMode) {
  return transportMode.name;
}

interface TransportModeProps {
  hasError: boolean;
  onChange: (value: TransportMode | null) => void;
  options: Array<TransportMode>;
  value: TransportMode | null;
}

function TransportModeSelect(props: TransportModeProps) {
  const { hasError, onChange, options, value } = props;

  return (
    <Select
      hasError={hasError}
      onChange={onChange}
      options={options}
      optionKey={transportModeKey}
      renderOption={renderTransportMode}
      value={value}
    />
  );
}

export default stories;
