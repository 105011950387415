import * as ColourTitledRatingScaleLabels from "../../utils/Vectors/carbon-labels/colour/titled-rating-scale";
import { TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS } from "../../utils/Vectors/carbon-labels/constants";
import * as MonochromeTitledRatingScaleLabels from "../../utils/Vectors/carbon-labels/monochrome/titled-rating-scale";
import { BaseCarbonLabelProps } from "./CarbonLabel";

export interface TitledRatingScaleLabelProps extends BaseCarbonLabelProps {}

export default function TitledRatingScaleLabel(
  props: TitledRatingScaleLabelProps
) {
  const { className, impactRating, monochrome, width } = props;
  const TitledRatingScaleLabels = monochrome
    ? MonochromeTitledRatingScaleLabels
    : ColourTitledRatingScaleLabels;

  const labelWidth = width ?? TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS;
  switch (impactRating) {
    case "VLOW":
      return (
        <TitledRatingScaleLabels.TitledRatingScaleLabelVLow
          className={className}
          width={labelWidth}
        />
      );
    case "LOW":
      return (
        <TitledRatingScaleLabels.TitledRatingScaleLabelLow
          className={className}
          width={labelWidth}
        />
      );
    case "MEDIUM":
      return (
        <TitledRatingScaleLabels.TitledRatingScaleLabelMedium
          className={className}
          width={labelWidth}
        />
      );
    case "HIGH":
      return (
        <TitledRatingScaleLabels.TitledRatingScaleLabelHigh
          className={className}
          width={labelWidth}
        />
      );
    case "VHIGH":
      return (
        <TitledRatingScaleLabels.TitledRatingScaleLabelVHigh
          className={className}
          width={labelWidth}
        />
      );
  }
}
