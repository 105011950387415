import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { IconButton } from "../utils/Button";
import TooltipOverlay from "../utils/TooltipOverlay";
import { CircularArrow, Pencil } from "../utils/Vectors";
import { UploadedRecipe } from "./UploadRecipesPage";
import "./UploadedRecipesTable.css";

const Pending = () => {
  return (
    <span>
      <CircularArrow className="mr-2" fill="blue" width={16} />
      <FormattedMessage
        id="components/recipes/UploadedRecipesTable:pendingMessage"
        defaultMessage="Pending"
      />
    </span>
  );
};

interface UploadedRecipesTableProps {
  recipePageUrl: (recipe: { id: number }) => string;
  recipes: Array<UploadedRecipe>;
}

export default function UploadedRecipesTable(props: UploadedRecipesTableProps) {
  const { recipePageUrl, recipes } = props;

  const intl = useIntl();

  const high = intl.formatMessage({
    id: "components/recipes/UploadedRecipesTable:impact/highDescriptor",
    defaultMessage: "High",
  });
  const low = intl.formatMessage({
    id: "components/recipes/UploadedRecipesTable:impact/lowDescriptor",
    defaultMessage: "Low",
  });

  function weightToRepresentation(weight: number, className?: string) {
    return (
      <span className={className}>
        {(weight * 1000).toFixed(1)}
        <FormattedMessage
          id="components/recipes/UploadedRecipesTable:unitGrams"
          defaultMessage="g"
        />
      </span>
    );
  }

  const columns = [
    {
      className: "column__name",
      key: "name",
      label: (
        <FormattedMessage
          id="components/recipes/UploadRecipesPage:UploadedRecipesTable/columns/name/label"
          defaultMessage="Name"
        />
      ),
      renderCell: ({
        recipe,
        highlighted,
      }: {
        recipe: UploadedRecipe;
        highlighted: boolean;
      }) => {
        const highlightCellClassName = classNames(
          "medium-font column__name__cell",
          {
            cell__highlighted: highlighted,
          }
        );
        return (
          <span className={highlightCellClassName}>
            <>
              <span
                className={classNames("action-link", highlightCellClassName)}
              >
                {recipe.parsedRecipe.name}
              </span>
              <span
                className={classNames("cell__edit ml-2", {
                  cell__edit__highlighted: highlighted,
                })}
              >
                <IconButton
                  display="inline"
                  icon={
                    <Pencil fill="var(--foodsteps-turquoise)" width="1rem" />
                  }
                />
              </span>
            </>
          </span>
        );
      },
    },
    {
      className: "column__weight",
      key: "weight",
      label: (
        <>
          <FormattedMessage
            id="components/recipes/UploadedRecipesTable:columns/weight/label"
            defaultMessage="Weight"
          />
          <div className="small">
            <FormattedMessage
              id="components/recipes/UploadedRecipesTable:columns/weight/units"
              defaultMessage="(per serving)"
            />
          </div>
        </>
      ),
      renderCell: ({ recipe }: { recipe: UploadedRecipe }) => (
        <>
          {recipe.weightKgPerServing === null ? (
            <Pending />
          ) : recipe.weightTooHigh || recipe.weightTooLow ? (
            <TooltipOverlay
              id={`components/recipes/UploadedRecipesTable:weight/${recipe.id}`}
              overlay={
                <FormattedMessage
                  id="components/recipes/UploadedRecipesTable:weight/tooltipOverlay"
                  defaultMessage="{descriptor} weight per serving"
                  values={{
                    descriptor: recipe.weightTooHigh ? high : low,
                  }}
                />
              }
              placement="top"
            >
              {weightToRepresentation(
                recipe.weightKgPerServing,
                "yellow-warning-box"
              )}
            </TooltipOverlay>
          ) : (
            weightToRepresentation(recipe.weightKgPerServing)
          )}
        </>
      ),
    },
    {
      className: "column__impact",
      key: "impact",
      label: (
        <>
          <FormattedMessage
            id="components/recipes/UploadedRecipesTable:columns/impact/label"
            defaultMessage="Carbon Footprint"
          />
          <div className="small">
            <FormattedMessage
              id="components/recipes/UploadedRecipesTable:columns/impact/units"
              defaultMessage="(kg CO2e per serving)"
            />
          </div>
        </>
      ),
      renderCell: ({ recipe }: { recipe: UploadedRecipe }) => (
        <>
          {recipe.ghgPerRootRecipeServing === null ? (
            <Pending />
          ) : recipe.impactTooHigh || recipe.impactTooLow ? (
            <TooltipOverlay
              id={`components/recipes/UploadedRecipesTable:impact/${recipe.id}`}
              overlay={
                <FormattedMessage
                  id="components/recipes/UploadedRecipesTable:impact/tooltipOverlay"
                  defaultMessage="Unusually {descriptor} carbon footprint"
                  values={{
                    descriptor: recipe.impactTooHigh
                      ? high.toLocaleLowerCase()
                      : low.toLocaleLowerCase(),
                  }}
                />
              }
              placement="top"
            >
              <span className="yellow-warning-box">
                {recipe.ghgPerRootRecipeServing.toFixed(3)}
              </span>
            </TooltipOverlay>
          ) : (
            recipe.ghgPerRootRecipeServing.toFixed(3)
          )}
        </>
      ),
    },
  ];

  const [isHighlighted, setIsHighlighted] = useState<
    Map<UploadedRecipe["id"], boolean>
  >(new Map(recipes.map((recipe) => [recipe.id, false])));

  const rowClassName = "d-flex flex-row";

  return (
    <div className="UploadedRecipesTable">
      <div className="w-100 table">
        <div className={classNames("header", rowClassName)}>
          {columns.map((column) => (
            <div
              className={column.className}
              key={`tableHeader__${column.key}`}
            >
              <div className="medium-font">{column.label}</div>
            </div>
          ))}
        </div>
        <>
          {recipes.map((recipe) => (
            <React.Fragment key={recipe.id}>
              <hr />
              <div
                className={classNames("table-row", rowClassName, {
                  row__highlighted: isHighlighted.get(recipe.id) ?? false,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(recipePageUrl(recipe), "_blank");
                }}
                onMouseOver={() => {
                  setIsHighlighted(new Map(isHighlighted.set(recipe.id, true)));
                }}
                onMouseOut={() => {
                  setIsHighlighted(
                    new Map(isHighlighted.set(recipe.id, false))
                  );
                }}
              >
                {columns.map((column) => (
                  <Cell
                    className={column.className}
                    isHighlighted={isHighlighted}
                    key={`${recipe.id}${column.key}`}
                    recipe={recipe}
                    renderCell={column.renderCell}
                  />
                ))}
              </div>
            </React.Fragment>
          ))}
        </>
      </div>
    </div>
  );
}

interface CellProps {
  className: string;
  isHighlighted: Map<UploadedRecipe["id"], boolean>;
  recipe: UploadedRecipe;
  renderCell: ({
    recipe,
    highlighted,
  }: {
    recipe: UploadedRecipe;
    highlighted: boolean;
  }) => React.ReactNode;
}

function Cell(props: CellProps) {
  const { className, isHighlighted, recipe, renderCell } = props;

  return (
    <div className={classNames("cell", className)}>
      {renderCell({
        recipe,
        highlighted: isHighlighted.get(recipe.id) ?? false,
      })}
    </div>
  );
}
