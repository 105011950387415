import { CSSProperties } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import "./TooltipOverlay.css";

export interface TooltipOverlayProps {
  children: React.ReactNode;
  id: string;
  overlay: React.ReactNode;
  placement?: Placement;
  style?: CSSProperties;
}

export default function TooltipOverlay(props: TooltipOverlayProps) {
  const { children, id, overlay, placement, style } = props;

  const styleBase = {
    backgroundColor: "transparent",
    fontSize: "13px",
  };

  const className =
    placement === "top"
      ? "tooltip-top"
      : placement === "right"
      ? "tooltip-right"
      : "";

  return (
    <OverlayTrigger
      delay={{ show: 800, hide: 0 }}
      placement={placement ?? "auto"}
      overlay={
        <Tooltip
          className={className}
          style={{ ...styleBase, ...style }}
          id={id}
        >
          <div className="tooltip-text">{overlay}</div>
        </Tooltip>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
}
