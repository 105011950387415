import LargeNumber from "../typography/LargeNumber";
import SummaryCard from "../utils/SummaryCard";
import "./ReportSummaryCard.css";

interface ReportSummaryCardProps {
  Icon: (props: { width: number }) => JSX.Element;
  title: React.ReactNode;
  unit?: React.ReactNode;
  value: number | string;
}

export default function ReportSummaryCard(props: ReportSummaryCardProps) {
  const { Icon, title, unit, value } = props;

  return (
    <SummaryCard className="ReportSummaryCard">
      <Icon width={32} />
      <div>
        <div className="medium-font">{title}</div>
        <div className="ReportSummaryCard_TotalImpact">
          <LargeNumber>{value}</LargeNumber>
          <small>{unit}</small>
        </div>
      </div>
    </SummaryCard>
  );
}
