import gql from "graphql-tag";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { usePages } from "../pages";
import useRecipeLabel from "../recipes/useRecipeLabel";
import { Panel } from "../utils/Panel";
import { PagedTable, TableColumn } from "../utils/Table";
import { Delete } from "../utils/Vectors/";
import { CollectionTable_Collection as Collection } from "./CollectionsTable.graphql";
import "./CollectionsTable.css";

const collectionKey = (collection: Collection) => collection.id;

interface CollectionTableProps {
  collections: Array<Collection>;
  delete: (collection: Collection) => void;
  onCollectionsLoadMore: null | (() => Promise<void>);
}

export default function CollectionTable(props: CollectionTableProps) {
  const { collections } = props;
  const intl = useIntl();
  const pages = usePages();
  const recipeLabel = useRecipeLabel();

  const columns: Array<TableColumn<Collection>> = [
    {
      key: "collectionName",
      label: intl.formatMessage({
        id: "components/collections/CollectionsTable:columns/collectionName/label",
        defaultMessage: "Collection",
      }),
      renderCell: (collection: Collection, { className }) => (
        <Link
          className={`d-block ${className ?? ""}`}
          to={pages.Collection.url(collection)}
        >
          {collection.name}
        </Link>
      ),
    },
    {
      align: "right",
      key: "recipeCount",
      label: intl.formatMessage(
        {
          id: "components/collections/CollectionsTable:columns/collectionName/recipeCount",
          defaultMessage: "Number of {recipeLabelPlural}",
        },
        { recipeLabelPlural: recipeLabel.pluralUppercase }
      ),
      renderCell: (collection: Collection) => collection.recipeCount,
    },
    ...(collections.some((collection) => collection.viewerHasPermissionUpdate)
      ? [
          {
            key: "delete",
            label: "",
            renderCell: (collection: Collection) =>
              collection.viewerHasPermissionUpdate ? (
                <Delete
                  title={intl.formatMessage({
                    id: "components/collections/CollectionsTable:columns/delete/buttonTitle",
                    defaultMessage: "Delete Collection",
                  })}
                  handleClick={() => props.delete(collection)}
                  width={20}
                />
              ) : null,
            align: "right" as const,
          },
        ]
      : []),
  ];

  return (
    <div className="d-flex" style={{ minHeight: 0 }}>
      <Panel className="CollectionsTable_Panel">
        <PagedTable
          columns={columns}
          onLoadMore={props.onCollectionsLoadMore}
          rowKey={collectionKey}
          rows={collections}
        />
      </Panel>
    </div>
  );
}

CollectionTable.fragments = {
  collections: gql`
    fragment CollectionTable_Collection on RecipeCollection {
      id
      name
      recipeCount
      viewerHasPermissionUpdate
    }
  `,
};
