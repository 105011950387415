import gql from "graphql-tag";
import sum from "lodash/sum";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useImpactCategoryEffectTypePerPortion } from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import { impactRatingToColorRgb } from "../../domain/impactRatings";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
  useFoodManufacturerOrganization,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import UserVisibleError from "../../util/UserVisibleError";
import { qualitativeDataColors } from "../graphs/colors";
import ErrorAlert from "../utils/ErrorAlert";
import { Panel } from "../utils/Panel";
import FunctionalUnitDropdown from "./FunctionalUnitDropdown";
import ImpactCategoryToggle, {
  ImpactCategoryToggleType,
} from "./ImpactCategoryToggle";
import {
  IngredientImpactBreakdown_Recipe as Recipe,
  IngredientImpactBreakdown_RecipeImpact as RecipeImpact,
} from "./IngredientImpactBreakdown.graphql";
import IngredientImpactChart from "./IngredientImpactChart";
import RecipeIngredientsTable from "./RecipeIngredientsTable";
import { recipePageTracking } from "./RecipePage";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";

const chartName = "Ingredient Breakdown"; // For tracking.

interface IngredientImpactBreakdownProps {
  impact: RecipeImpact;
  nullProductWeightErrorMessage: string;
  productWeightKgPerPortion: number | null;
  recipe: Recipe;
}

export default function IngredientImpactBreakdown(
  props: IngredientImpactBreakdownProps
) {
  const {
    impact,
    nullProductWeightErrorMessage,
    productWeightKgPerPortion,
    recipe,
  } = props;

  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const impactCategoryEffectTypePerPortion =
    useImpactCategoryEffectTypePerPortion;
  const { trackFunctionalUnitSet, trackImpactCategorySet } = useTracking();
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;

  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const [functionalUnit, setFunctionalUnit] = useState<FunctionalUnit>(
    FunctionalUnit.KG
  );

  const effectType = impactCategoryEffectTypePerPortion(impactCategory);

  const totalIngredientImpactMagnitude = sum(
    impact.ingredients.map((ingredientImpact) =>
      effectType.get(ingredientImpact)
    )
  );

  const ingredientIdsToImpactRatingColor = new Map(
    impact.ingredients.map((ingredient) => [
      ingredient.ingredientId,
      impactRatingToColorRgb(ingredient.impactRating ?? null),
    ])
  );

  const ingredientIdsToQualitativeDataColor = new Map(
    impact.ingredients.map((ingredient, index) => [
      ingredient.ingredientId,
      qualitativeDataColors[index % qualitativeDataColors.length],
    ])
  );

  const ingredientIdsToColor =
    impactCategory === ImpactCategory.GHG
      ? ingredientIdsToImpactRatingColor
      : ingredientIdsToQualitativeDataColor;

  const handleFunctionalUnitChange = (functionalUnit: FunctionalUnit) => {
    setFunctionalUnit(functionalUnit);
    trackFunctionalUnitSet({
      chart: chartName,
      functionalUnit,
      foodManufacturerOrganization,
    });
  };

  const handleImpactCategoryChange = (impactCategory: ImpactCategory) => {
    setImpactCategory(impactCategory);
    trackImpactCategorySet({
      chart: chartName,
      pageName: recipePageTracking.pageName,
      impactCategory,
    });
  };

  const impactCategoryDisabled = (impactCategory: ImpactCategory): boolean => {
    const impactMagnitude =
      impactCategoryEffectTypePerPortion(impactCategory).get(impact);
    return (
      recipeImpactIsUnavailable(impact, impactCategory) ||
      impactMagnitude === 0 ||
      impactMagnitude === null
    );
  };

  return (
    <Panel>
      <>
        <div
          className="d-flex mb-4"
          style={{
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <div className="d-flex mb-0 flex-row">
            {additionalImpactCategories ? (
              <>
                <ImpactCategoryToggle
                  onChange={handleImpactCategoryChange}
                  options={availableImpactCategories}
                  selectedImpactCategory={impactCategory}
                  type={ImpactCategoryToggleType.TITLE}
                  disabled={impactCategoryDisabled}
                />
                <h3>
                  <div className="ml-2">
                    <FormattedMessage
                      id="components/recipes/RecipeBreakdown:breakdownByIngredient"
                      defaultMessage="Breakdown by Ingredient"
                    />
                  </div>
                </h3>
              </>
            ) : (
              <h3 className="mb-0">
                <FormattedMessage
                  id="components/recipes/RecipeBreakdown:ingredientBreakdownTitleNoToggle"
                  defaultMessage="Impact Breakdown of GHG Emissions"
                />
              </h3>
            )}
          </div>
          <FunctionalUnitDropdown
            selectedFunctionalUnit={functionalUnit}
            onChange={handleFunctionalUnitChange}
          />
        </div>
        {productWeightKgPerPortion === null ? (
          <ErrorAlert
            error={new UserVisibleError(nullProductWeightErrorMessage)}
          />
        ) : (
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              gap: "43px",
              minHeight: "416px",
            }}
          >
            <div style={{ flexGrow: 1, minWidth: "380px" }}>
              <IngredientImpactChart
                functionalUnit={functionalUnit}
                impactCategory={impactCategory}
                ingredients={impact.ingredients}
                ingredientIdsToColor={ingredientIdsToColor}
                perPortionEffectType={effectType}
                productWeightKgPerPortion={productWeightKgPerPortion}
                totalIngredientImpactMagnitude={totalIngredientImpactMagnitude}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <div className="mb-2">
                <RecipeIngredientsTable
                  functionalUnit={functionalUnit}
                  perPortionEffectType={effectType}
                  impact={impact}
                  impactCategory={impactCategory}
                  ingredientIdsToQualitativeDataColor={
                    ingredientIdsToQualitativeDataColor
                  }
                  productWeightKgPerPortion={productWeightKgPerPortion}
                  recipe={recipe}
                  totalIngredientImpactMagnitude={
                    totalIngredientImpactMagnitude
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    </Panel>
  );
}

IngredientImpactBreakdown.fragments = {
  recipe: gql`
    fragment IngredientImpactBreakdown_Recipe on Recipe {
      ...RecipeIngredientsTable_Recipe
    }
    ${RecipeIngredientsTable.fragments.recipe}
  `,
  recipeImpact: gql`
    fragment IngredientImpactBreakdown_RecipeImpact on RecipeImpact {
      ingredients {
        ...IngredientImpactChart_RecipeIngredientImpact
      }
      weightKgPerServing
      unavailableLandUse
      unavailableWaterUse
      ...RecipeIngredientsTable_RecipeImpact
    }
    ${RecipeIngredientsTable.fragments.recipeImpact}
    ${IngredientImpactChart.fragments.recipeIngredientImpact}
  `,
};
