import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useTracking } from "../../tracking";
import useMutation from "../graphql/useMutation";
import usePagedQuery from "../graphql/usePagedQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import { useHasRecipeCollectionPermissionAdd } from "../permissions";
import StatusDisplay from "../StatusDisplay";
import { SecondaryButton } from "../utils/Button";
import DeleteModal from "../utils/DeleteModal";
import { collectionsOwnedByOrganizationOrInUserGroupFilter } from "../utils/ownedByOrganizationOrInUserGroupFilter";
import Add from "../utils/Vectors/Add";
import AddCollectionModal from "./AddCollectionModal";
import {
  CollectionsPage_Collection as Collection,
  CollectionsPage_CollectionsQuery as CollectionsQuery,
  CollectionsPage_CollectionsQueryVariables as CollectionsQueryVariables,
  CollectionsPage_DeleteCollection as DeleteCollection,
  CollectionsPage_DeleteCollectionVariables as DeleteCollectionVariables,
} from "./CollectionsPage.graphql";
import CollectionsTable from "./CollectionsTable";

const tracking = { pageName: "collections" };

export default function CollectionsPage() {
  const hasRecipeCollectionPermissionAdd =
    useHasRecipeCollectionPermissionAdd();
  const [organizationId] = useOrganizationId();

  const {
    fetchNextPage,
    status: collectionsQueryStatus,
    refresh: refreshCollections,
  } = usePagedQuery<CollectionsQuery, CollectionsQueryVariables, Collection>(
    collectionsQuery,
    {
      first: 20,
      filter: collectionsOwnedByOrganizationOrInUserGroupFilter(organizationId),
    },
    collectionsQueryGetCollections
  );

  const [deleteRecipeCollection] = useMutation<
    DeleteCollection,
    DeleteCollectionVariables
  >(deleteRecipeCollectionMutation);

  const pages = usePages();

  return (
    <Page tracking={tracking}>
      <div className="d-flex flex-column h-100">
        <div className="d-flex justify-content-between flex-row">
          <div>
            <Page.Title breadcrumb={pages.Collections().breadcrumb()} />
          </div>
          <div className="mt-auto">
            {hasRecipeCollectionPermissionAdd ? (
              <AddCollectionButton refreshCollections={refreshCollections} />
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column flex-fit">
          <StatusDisplay status={collectionsQueryStatus}>
            {(collectionsQuery) => (
              <CollectionsPanel
                collections={collectionsQuery.nodes}
                onCollectionsLoadMore={
                  fetchNextPage === null ? null : () => fetchNextPage()
                }
                onDeleteCollection={async (collectionId) => {
                  await deleteRecipeCollection({
                    variables: { recipeCollectionId: collectionId },
                  });
                }}
                refreshCollections={refreshCollections}
              />
            )}
          </StatusDisplay>
        </div>
      </div>
    </Page>
  );
}

interface AddCollectionButtonProps {
  refreshCollections: () => void;
}

function AddCollectionButton(props: AddCollectionButtonProps) {
  const { refreshCollections } = props;

  const [showModal, setShowModal] = useState(false);
  const { trackCollectionCreationStarted } = useTracking();

  return (
    <>
      <SecondaryButton
        icon={<Add width={"1.2em"} />}
        onClick={() => {
          trackCollectionCreationStarted();
          setShowModal(true);
        }}
      >
        <FormattedMessage
          id="components/collections/CollectionsPage:AddCollectionButton/label"
          defaultMessage="New Collection"
        />
      </SecondaryButton>

      <AddCollectionModal
        refreshCollections={() => refreshCollections()}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

interface CollectionsPanelProps {
  collections: Array<Collection>;
  onCollectionsLoadMore: null | (() => Promise<void>);
  onDeleteCollection: (collectionId: number) => Promise<void>;
  refreshCollections: () => void;
}

function CollectionsPanel(props: CollectionsPanelProps) {
  const {
    collections,
    onCollectionsLoadMore,
    onDeleteCollection,
    refreshCollections,
  } = props;
  const [showDeleteCollectionModalForId, setShowDeleteCollectionModalForId] =
    useState<null | number>(null);

  const { trackCollectionDeleted } = useTracking();

  const deletingCollection =
    showDeleteCollectionModalForId === null
      ? undefined
      : collections.find(
          (collection) => collection.id === showDeleteCollectionModalForId
        );

  const handleDelete = async (collectionId: number) => {
    const collectionName =
      collections.find((collection) => collection.id === collectionId)?.name ??
      "";

    trackCollectionDeleted({ collectionId, collectionName });

    await onDeleteCollection(collectionId);
    await refreshCollections();
  };

  return (
    <>
      <CollectionsTable
        collections={collections}
        delete={(collection) =>
          setShowDeleteCollectionModalForId(collection.id)
        }
        onCollectionsLoadMore={onCollectionsLoadMore}
      />
      {deletingCollection !== undefined && (
        <DeleteCollectionModal
          collection={deletingCollection}
          onDelete={() => handleDelete(deletingCollection.id)}
          onHide={() => setShowDeleteCollectionModalForId(null)}
        />
      )}
    </>
  );
}

interface DeleteCollectionModalProps {
  collection: Collection;
  onDelete: () => Promise<void>;
  onHide: () => void;
}

function DeleteCollectionModal(props: DeleteCollectionModalProps) {
  const { collection, onDelete, onHide } = props;

  const intl = useIntl();

  return (
    <DeleteModal
      name={collection.name}
      onDelete={onDelete}
      onHide={onHide}
      show
      title={intl.formatMessage({
        id: "components/collections/CollectionsPage:DeleteCollectionModal/title",
        defaultMessage: "Delete Collection",
      })}
    />
  );
}

const collectionsQuery = gql`
  query CollectionsPage_CollectionsQuery(
    $after: String
    $first: Int!
    $filter: RecipeCollectionFilter
  ) {
    recipeCollections(after: $after, first: $first, filter: $filter) {
      edges {
        node {
          ...CollectionsPage_Collection
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment CollectionsPage_Collection on RecipeCollection {
    id
    ...CollectionTable_Collection
  }

  ${CollectionsTable.fragments.collections}
`;

function collectionsQueryGetCollections(data: CollectionsQuery) {
  return data.recipeCollections;
}

const deleteRecipeCollectionMutation = gql`
  mutation CollectionsPage_DeleteCollection($recipeCollectionId: Int!) {
    deleteRecipeCollection(input: { id: $recipeCollectionId }) {
      success
    }
  }
`;
