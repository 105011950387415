import HelpModalTooltip from "./HelpModalTooltip";

const stories = {
  name: "HelpModalTooltip",
  render: () => (
    <HelpModalTooltip title="Cooking FAQs">
      <HelpModalTooltip.Question
        question="What should I do if my cooking method isn't listed?"
        answer="If your cooking method isn't listed, please get in touch"
      />
      <HelpModalTooltip.Question
        question="Should I include preheating times?"
        answer='Preheating times are already included, so you should just enter the
        time used for cooking. For instance, if a product requires an oven to
        be preheated, and then used to cook for fifteen minutes, just enter
        "15".'
      />
    </HelpModalTooltip>
  ),
};

export default stories;
