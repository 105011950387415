import React, { useContext } from "react";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import { useMemoOne } from "use-memo-one";

import { useDataStore } from "../../data-store";
import useOnPageTransition from "../useOnPageTransition";
import FetchGraphQL from "./FetchGraphQL";

interface GraphQLProviderProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();

export default function GraphQLProvider(props: GraphQLProviderProps) {
  const { children } = props;

  const dataStore = useDataStore();

  const graphQL = useMemoOne(() => {
    return {
      fetch: dataStore.fetchGraphQL,
      key: dataStore.key,
    };
  }, [dataStore]);

  return (
    <GraphQLContext.Provider value={graphQL}>
      <QueryClientProvider client={queryClient}>
        <ResetStoreOnLocationChange />
        {children}
      </QueryClientProvider>
    </GraphQLContext.Provider>
  );
}

function ResetStoreOnLocationChange() {
  const queryClient = useQueryClient();

  useOnPageTransition(() => {
    queryClient.resetQueries();
  });

  return null;
}

const GraphQLContext = React.createContext<null | GraphQL>(null);

interface GraphQL {
  fetch: FetchGraphQL;
  key: unknown;
}

export function useGraphQL(): GraphQL {
  const graphQL = useContext(GraphQLContext);
  if (graphQL === null) {
    throw new Error("missing provider for GraphQLContext");
  } else {
    return graphQL;
  }
}
