import classNames from "classnames";

interface ExtraLargeNumberProps {
  children: React.ReactNode;
  className?: string;
}

export default function ExtraLargeNumber(props: ExtraLargeNumberProps) {
  const { children, className = "" } = props;
  return (
    <div className={classNames(className, "ExtraLargeNumber")}>{children}</div>
  );
}
