import gql from "graphql-tag";

import useQuery from "../graphql/useQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import {
  collectionsOwnedByOrganizationOrInUserGroupFilter,
  recipesOwnedByOrganizationOrInUserGroupFilter,
} from "../utils/ownedByOrganizationOrInUserGroupFilter";
import {
  DashboardPageQuery,
  DashboardPage_Recipe as Recipe,
  DashboardPage_RecipeCollection as RecipeCollection,
  DashboardPageQueryVariables,
} from "./useDashboardPageData.graphql";

export function useDashboardPageData() {
  const [organizationId] = useOrganizationId();

  return useQuery<DashboardPageQuery, DashboardPageQueryVariables>(
    dashboardPageQuery,
    {
      // TODO: fetch these collections with the useCollections context provider
      // once we remove support for shared collections via User Groups
      collectionFilter:
        collectionsOwnedByOrganizationOrInUserGroupFilter(organizationId),
      recipeFilter: {
        usedAsIngredient: false,
        ...recipesOwnedByOrganizationOrInUserGroupFilter(organizationId),
      },
    }
  );
}

export type { DashboardPageQuery, Recipe, RecipeCollection };

const dashboardPageQuery = gql`
  query DashboardPageQuery(
    $collectionFilter: RecipeCollectionFilter!
    $recipeFilter: RecipeFilter!
  ) {
    recipeCollections(first: 10000, filter: $collectionFilter) {
      edges {
        node {
          ...DashboardPage_RecipeCollection
        }
      }
    }

    recipes(first: 10000, filter: $recipeFilter) {
      edges {
        node {
          ...DashboardPage_Recipe
        }
      }
    }
  }

  fragment DashboardPage_Recipe on Recipe {
    collections {
      id
    }
    dietaryCategories
    id
    impactCached {
      ghgPerKg
      impactRating
      landUsePerKg
      waterUsePerKg
    }
    name
  }

  fragment DashboardPage_RecipeCollection on RecipeCollection {
    id
    name
  }
`;
