import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AvailableIngredient from "../../../domain/AvailableIngredient";
import {
  useCollections,
  useTags,
} from "../../../services/useOrganizationFeatures";
import scrollToTop from "../../../util/scrollToTop";
import * as FloatInput from "../../utils/FloatInput";
import { Panel } from "../../utils/Panel";
import UpgradeRequestModal from "../../utils/UpgradeRequestModal";
import CollectionSelect from "../RecipeEditor/CollectionSelect";
import * as RecipeCollectionsEditor from "../RecipeEditor/RecipeCollectionsEditor";
import * as RecipeIngredientsEditor from "../RecipeEditor/RecipeIngredientsEditor";
import * as RecipeNameEditor from "../RecipeEditor/RecipeNameEditor";
import * as RecipeTagsEditor from "../RecipeTagsEditor";
import useRecipeLabel from "../useRecipeLabel";
import "./ProductDetails.css";
import { ProductDetails_Collection as Collection } from "./ProductDetails.graphql";
import { ProductEditorState } from "./ProductEditor";
import * as ProductWeightEditor from "./ProductWeightEditor";

export type ProductDetailsState = Pick<
  ProductEditorState,
  "name" | "collectionIds" | "ingredients" | "productWeight"
>;

interface ProductDetailsProps {
  availableIngredients: Array<AvailableIngredient>;
  collections: Collection[];
  contentContainerRef?: any;
  refreshCollections: () => Promise<void>;
  state: ProductDetailsState;
  onChange: (newState: ProductDetailsState) => void;
}

export function ProductDetails(props: ProductDetailsProps) {
  const {
    availableIngredients,
    collections,
    contentContainerRef,
    refreshCollections,
    state,
    onChange,
  } = props;
  const recipeLabel = useRecipeLabel();
  const intl = useIntl();
  const canUseCollections = useCollections();
  const hasTagsFeature = useTags();
  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);

  useEffect(() => {
    scrollToTop(contentContainerRef);
  }, [contentContainerRef]);

  const ingredientsByKey = new Map(
    state.ingredients.map((ingredient) => [ingredient.key, ingredient])
  );

  const recipeIngredientsEditorInstructions = intl.formatMessage({
    defaultMessage:
      "Add all inputs used in your product. Include any water that contributes to the final product (such as for boiling or steaming), but don’t include any water or other inputs (such as cleaning products) used in the maintenance of machinery.",
    id: "components/recipes/ProductEditor/ProductDetails:RecipeIngredientsEditor/instructions",
  });

  const collectionsEditor = () => {
    if (hasTagsFeature) {
      return (
        <RecipeTagsEditor.RecipeTagsEditor
          selectedIds={state.collectionIds}
          onChange={(collectionIds) => onChange({ ...state, collectionIds })}
        />
      );
    } else if (canUseCollections) {
      return (
        <RecipeCollectionsEditor.RecipeCollectionsEditor
          onChange={(collectionIds) => onChange({ ...state, collectionIds })}
          possibleCollections={collections}
          refreshPossibleCollections={refreshCollections}
          value={state.collectionIds}
        />
      );
    } else {
      return (
        <RecipeCollectionsEditor.LockedRecipeCollectionsEditor
          lockedMessage={
            <FormattedMessage
              id="components/recipes/ProductEditor/ProductDetails:collectionsLockedMessage"
              defaultMessage="Categorise your products into collections"
            />
          }
          onLockClick={() => setShowUpgradeRequestModal(true)}
        />
      );
    }
  };

  return (
    <>
      <Panel className="ProductDetails__Panel mb-4">
        <h3>
          <FormattedMessage
            id="components/recipes/ProductEditor/ProductDetails:productOverview"
            defaultMessage="{recipeLabel} Overview"
            values={{ recipeLabel: recipeLabel.singularUppercase }}
          />
        </h3>
        <RecipeNameEditor.RecipeNameEditor
          onChange={(name) => onChange({ ...state, name })}
          value={state.name}
          placeholder={intl.formatMessage({
            defaultMessage: "e.g. Cauli Bites",
            id: "components/recipes/ProductEditor/ProductDetails:namePlaceholder",
          })}
        />
        {collectionsEditor()}
        <ProductWeightEditor.ProductWeightEditor
          onChange={(productWeight) => onChange({ ...state, productWeight })}
          value={state.productWeight}
        />
      </Panel>
      <Panel className="ProductDetails__Panel">
        <RecipeIngredientsEditor.RecipeIngredientsEditor
          availableIngredients={availableIngredients}
          canDeleteAllIngredients={true}
          existingRecipe={undefined}
          instructions={recipeIngredientsEditorInstructions}
          showQuantity={false}
          onChange={(ingredients) =>
            onChange({
              ...state,
              ingredients: ingredients.map((ingredient) => ({
                ...ingredient,
                unitProcess: {
                  processLoss:
                    ingredientsByKey.get(ingredient.key)?.unitProcess
                      ?.processLoss ?? FloatInput.initialValue(null),
                },
              })),
            })
          }
          value={state.ingredients}
        />
      </Panel>
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </>
  );
}

export const fragments = {
  collection: gql`
    fragment ProductDetails_Collection on RecipeCollection {
      ...CollectionSelect_Collection
      ...RecipeTagsEditor_Collection
    }

    ${CollectionSelect.fragments.collection}
    ${RecipeTagsEditor.fragments.collection}
  `,
};
