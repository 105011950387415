import classNames from "classnames";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Switch from "react-switch";
import rgbHex from "rgb-hex";

import {
  darkTurquoiseCol,
  foodstepsTurquoiseCol,
  greyCol,
} from "../graphs/colors";
import TooltipOverlay, { TooltipOverlayProps } from "../utils/TooltipOverlay";
import useId from "../utils/useId";
import Packaging from "../utils/Vectors/Packaging";

import "./PackagingSwitch.css";

interface PackagingSwitchProps {
  checked: boolean;
  disabled?: boolean;
  toolTipOverlayPropsWhenDisabled?: Pick<
    TooltipOverlayProps,
    "placement" | "overlay"
  >;
  whenAvailableLabelText?: boolean;
  onChange?: (checked: boolean) => void;
}

export default function PackagingSwitch(props: PackagingSwitchProps) {
  const {
    checked: initiallyChecked,
    disabled,
    onChange: callersOnChange = () => {},
    toolTipOverlayPropsWhenDisabled,
    whenAvailableLabelText = false,
  } = props;

  const foodstepsTurquoiseHex =
    "#" + rgbHex(foodstepsTurquoiseCol).slice(0, -2);
  const darkTurquoiseHex = "#" + rgbHex(darkTurquoiseCol).slice(0, -2);
  const greyHex = "#" + rgbHex(greyCol).slice(0, -2);

  const [checked, setChecked] = useState(initiallyChecked);
  const [onColor, setOnColor] = useState(darkTurquoiseHex);

  const onChange = (checked: boolean) => {
    setChecked(checked);
    callersOnChange(checked);
  };

  const packagingIcon = (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Packaging className={"packaging-icon"} width={20} />
    </div>
  );

  const packagingSwitch = (
    <div
      className="packaging-switch-only-container"
      onMouseLeave={() => setOnColor(darkTurquoiseHex)}
      onMouseOver={() => setOnColor(foodstepsTurquoiseHex)}
    >
      <Switch
        activeBoxShadow={""}
        name="switch"
        checked={checked}
        checkedIcon={false}
        // we have to set different classes based on state instead of using the :disabled pseudo selector
        // because the Switch component puts the input element after the visual switch elements
        // and so they cannot be selected from it with the css sibling combinator
        className={classNames("packaging-switch", {
          "packaging-switch-disabled": disabled,
          "packaging-switch-enabled": !disabled,
        })}
        disabled={disabled || false}
        handleDiameter={32}
        height={40}
        onChange={onChange}
        uncheckedIcon={false}
        checkedHandleIcon={packagingIcon}
        uncheckedHandleIcon={packagingIcon}
        // these properties have to be defined in hex, not rgb
        offColor={greyHex}
        onColor={onColor}
        width={64}
      />
    </div>
  );
  const packagingSwitchOverlayId = useId();

  return (
    <>
      <div className="packaging-switch-container d-flex flex-row">
        {disabled && toolTipOverlayPropsWhenDisabled ? (
          <TooltipOverlay
            id={packagingSwitchOverlayId}
            {...toolTipOverlayPropsWhenDisabled}
          >
            {packagingSwitch}
          </TooltipOverlay>
        ) : (
          packagingSwitch
        )}
        <label
          className={classNames("mb-0", {
            "when-available-label-text my-auto": whenAvailableLabelText,
          })}
          htmlFor="switch"
        >
          {checked ? (
            <>
              {whenAvailableLabelText ? (
                <FormattedMessage
                  id="components/packaging/PackagingSwitch:includePackagingWhenAvailableLabel"
                  defaultMessage="Include packaging (when available)"
                />
              ) : (
                <FormattedMessage
                  id="components/packaging/PackagingSwitch:includePackagingLabel"
                  defaultMessage="Include packaging"
                />
              )}
            </>
          ) : (
            <FormattedMessage
              id="components/packaging/PackagingSwitch:excludePackagingLabel"
              defaultMessage="Exclude packaging"
            />
          )}
        </label>
      </div>
    </>
  );
}
