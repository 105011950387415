import classNames from "classnames";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FormattedMessage } from "react-intl";

import { FunctionalUnit } from "../../domain/functionalUnits";
import { useFoodManufacturerOrganization } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import "./FunctionalUnitDropdown.css";
import RotatingChevron from "../utils/RotatingChevron";

interface FunctionUnitDropdownProps {
  selectedFunctionalUnit: FunctionalUnit;
  onChange: (functionUnit: FunctionalUnit) => void;
}

export default function FunctionalUnitDropdown(
  props: FunctionUnitDropdownProps
) {
  const { selectedFunctionalUnit, onChange } = props;

  const foodManufacturerOrganization = useFoodManufacturerOrganization();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const renderOption = (value: FunctionalUnit) => {
    if (value === FunctionalUnit.KG) {
      return (
        <FormattedMessage
          defaultMessage="Per kg of product"
          id="components/recipes/FunctionalUnitDropdown:perKgOfProduct"
        />
      );
    } else if (value === FunctionalUnit.PORTION) {
      return foodManufacturerOrganization ? (
        <FormattedMessage
          defaultMessage="Per product"
          id="components/recipes/FunctionalUnitDropdown:perProduct"
        />
      ) : (
        <FormattedMessage
          defaultMessage="Per serving"
          id="components/recipes/FunctionalUnitDropdown:perServing"
        />
      );
    } else {
      assertNever(value, "Unsupported FunctionalUnit");
    }
  };

  const handleChange = (value: FunctionalUnit | null) => {
    if (value === null) {
      throw new Error("value should not be clearable.");
    }
    onChange(value);
  };

  return (
    <Dropdown
      className="FunctionalUnitDropdown"
      show={showDropdown}
      onToggle={(show: boolean) => setShowDropdown(show)}
    >
      <Dropdown.Toggle
        className="FunctionalUnitDropdown__Toggle"
        variant="link"
      >
        <div className="medium-font mb-0">
          {renderOption(selectedFunctionalUnit)}
        </div>
        <div className="my-auto ml-2">
          <RotatingChevron expanded={showDropdown} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="FunctionalUnitDropdown__Menu" alignRight>
        {Object.values(FunctionalUnit).map((functionalUnit, index) => (
          <Dropdown.Item
            className={classNames("FunctionalUnitDropdown__Item", {
              FunctionalUnitDropdown__Item__Active:
                functionalUnit === selectedFunctionalUnit,
              FunctionalUnitDropdown__Item__First: index === 0,
              FunctionalUnitDropdown__Item__Last:
                index === Object.values(FunctionalUnit).length - 1,
            })}
            key={index}
            onSelect={() => handleChange(functionalUnit)}
          >
            {renderOption(functionalUnit)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
