import gql from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { useFoodManufacturerOrganization } from "../../services/useOrganizationFeatures";
import useQuery from "../graphql/useQuery";
import Page from "../Page";
import { usePages } from "../pages";
import NewRecipeEditor from "../recipes/NewRecipeEditor";
import ProductEditor from "../recipes/ProductEditor/ProductEditor";
import * as RecipesListPageLocationState from "../recipes/RecipesListPageLocationState";
import StatusDisplay from "../StatusDisplay";
import {
  NewCollectionRecipePage_Query as Query,
  NewCollectionRecipePage_QueryVariables as QueryVariables,
} from "./NewCollectionRecipePage.graphql";

const newCollectionRecipePageTracking = { pageName: "new-collection-recipe" };

export default function NewCollectionRecipePage() {
  const pages = usePages();

  const { collectionId } = pages.CollectionRecipesNew.useParams();
  const { status: queryStatus } = useQuery<Query, QueryVariables>(pageQuery, {
    recipeCollectionId: collectionId,
  });

  const recipeQuery = RecipesListPageLocationState.useQueryParams();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const parentUrl = `${pages.Collection.url({
    id: collectionId,
  })}?${pages.RecipesListPage.stringifyQueryParams(recipeQuery)}`;

  return (
    <Page
      tracking={newCollectionRecipePageTracking}
      showNav={!foodManufacturerOrganization}
    >
      <StatusDisplay status={queryStatus}>
        {({ recipeCollection }) => {
          return (
            <>
              {recipeCollection === null ? (
                <p className="mt-4">
                  <FormattedMessage
                    id="components/collections/NewCollectionRecipePage:collectionNotFound"
                    defaultMessage="Collection not found."
                  />
                </p>
              ) : foodManufacturerOrganization ? (
                <ProductEditor parentUrl={parentUrl} />
              ) : (
                <NewRecipeEditor parentUrl={parentUrl} />
              )}
            </>
          );
        }}
      </StatusDisplay>
    </Page>
  );
}

const pageQuery = gql`
  query NewCollectionRecipePage_Query($recipeCollectionId: Int!) {
    recipeCollection(filter: { ids: [$recipeCollectionId] }) {
      id
      name
    }
  }
`;
