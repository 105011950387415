import classNames from "classnames";

import VectorProps from "./VectorProps";
import "./FilledCircle.css";

interface FilledCircleProps extends VectorProps {
  content?: string | number;
  textColor?: string;
}

export default function FilledCircle(props: FilledCircleProps) {
  const {
    className = "",
    fill = "var(--foodsteps-turquoise)",
    handleClick = () => null,
    height,
    width,
    content = "",
    textColor = "white",
  } = props;
  return (
    <div
      onClick={handleClick}
      className={classNames(className, "FilledCircle")}
      style={{
        height: height ?? width,
        width,
        lineHeight: height ?? width,
        backgroundColor: fill,
        color: textColor,
      }}
    >
      {content}
    </div>
  );
}
