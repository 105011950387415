import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import useIsAdmin from "../../data-store/useIsAdmin";
import useUserInfo from "../../data-store/useUserInfo";
import { useFoodManufacturerOrganization } from "../../services/useOrganizationFeatures";
import { useLogout } from "../../sessions";
import { useTracking } from "../../tracking";
import { useAdminPages } from "../admin/adminPages";
import SelectOrganizationModal from "../organizations/SelectOrganizationModal";
import { usePages } from "../pages";
import usePlansEnabled from "../subscriptions/usePlansEnabled";
import RotatingChevron from "../utils/RotatingChevron";
import {
  Plans,
  Profile,
  Forum,
  Help,
  ContactSupport,
  SignOut,
  Admin,
} from "../utils/Vectors";
import "./AccountDropdown.css";

const ACCOUNT_DROPDOWN_ICON_WIDTH = 20;

export default function AccountDropdown() {
  const adminPages = useAdminPages();
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const logout = useLogout();
  const pages = usePages();
  const [{ firstName, lastName, organizationMemberships }] = useUserInfo();
  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();
  const plansEnabled = usePlansEnabled();
  const { trackAccessCommunityPlatform } = useTracking();

  const [showSelectOrganizationModal, setShowSelectOrganizationModal] =
    useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const initials = firstName.charAt(0) + lastName.charAt(0);

  const handleFoodstepsForumClicked = () => {
    trackAccessCommunityPlatform();
    window.open("https://foodsteps.circle.so", "_blank");
  };

  const handleHelpClicked = () => {
    trackAccessCommunityPlatform();
    if (isFoodManufacturerOrganization) {
      window.open(
        "https://foodsteps.circle.so/c/product-tutorials-food-manufacturers/",
        "_blank"
      );
    } else {
      window.open(
        "https://foodsteps.circle.so/c/product-tutorials-food-service/",
        "_blank"
      );
    }
  };

  const items = (
    <>
      <AccountDropdownItem
        as="link"
        to={pages.AccountDetails.url}
        icon={<Profile width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
        title={pages.AccountDetails.title}
      />
      {organizationMemberships.length > 1 && (
        <>
          <AccountDropdownItem
            as="button"
            onClick={() => {
              setShowSelectOrganizationModal(true);
            }}
            icon={null}
            title={
              <FormattedMessage
                id="components/Header:changeOrganization"
                defaultMessage="Select organisation"
              />
            }
          />
          <SelectOrganizationModal
            show={showSelectOrganizationModal}
            onHide={() => setShowSelectOrganizationModal(false)}
          />
        </>
      )}
      <AccountDropdownDivider />
      {plansEnabled && (
        <AccountDropdownItem
          as="link"
          to={pages.Plans.url}
          icon={<Plans width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
          title={pages.Plans.title}
        />
      )}
      <AccountDropdownItem
        as="button"
        onClick={() => handleFoodstepsForumClicked()}
        icon={<Forum width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
        title={
          <FormattedMessage
            defaultMessage="Foodsteps Forum"
            id="components/Header:foodstepsForum"
          />
        }
      />
      <AccountDropdownItem
        as="button"
        onClick={() => handleHelpClicked()}
        icon={<Help width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
        title={
          <FormattedMessage defaultMessage="Help" id="components/Header:help" />
        }
      />
      <AccountDropdownItem
        as="link"
        to={pages.ContactUs.url}
        icon={<ContactSupport width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
        title={pages.ContactUs.title}
      />
      <AccountDropdownDivider />
      {isAdmin ? (
        <AccountDropdownItem
          as="link"
          to={adminPages.AdminIndex.url}
          icon={<Admin width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
          title={adminPages.AdminIndex.title}
        />
      ) : null}
      <AccountDropdownItem
        as="button"
        onClick={() => {
          logout();
          history.push(pages.Dashboard().url);
        }}
        icon={<SignOut width={ACCOUNT_DROPDOWN_ICON_WIDTH} />}
        title={
          <FormattedMessage
            id="components/Header:signOut"
            defaultMessage="Sign out"
          />
        }
      />
    </>
  );

  return (
    <Dropdown
      alignRight={true}
      show={showDropdown}
      onToggle={(show: boolean) => setShowDropdown(show)}
      className="foodsteps-dropdown h-100"
    >
      <Dropdown.Toggle
        className="AccountDropdown_Toggle medium-font d-flex"
        variant="link"
      >
        <div className="AccountDropdown_InitialsContainer">
          <div className="AccountDropdown_Initials">{initials}</div>
        </div>
        <div className="my-auto ml-2">
          <RotatingChevron expanded={showDropdown} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="AccountDropdown_Menu mt-2">
        {items}
      </Dropdown.Menu>
    </Dropdown>
  );
}

type AccountDropdownItemProps =
  | {
      as: "button";
      onClick: () => void;
      icon: React.ReactNode;
      title: React.ReactNode;
    }
  | { as: "link"; to: string; icon: React.ReactNode; title: React.ReactNode };

function AccountDropdownItem(props: AccountDropdownItemProps) {
  const content = (
    <div className="d-flex flex-row">
      <div className="pr-2">{props.icon}</div>
      <div>{props.title}</div>
    </div>
  );

  if (props.as === "button") {
    return (
      <Dropdown.Item
        className="AccountDropdown_Item"
        as="button"
        onClick={props.onClick}
      >
        {content}
      </Dropdown.Item>
    );
  } else {
    return (
      <Dropdown.Item className="AccountDropdown_Item" as={Link} to={props.to}>
        {content}
      </Dropdown.Item>
    );
  }
}

function AccountDropdownDivider() {
  return <hr className="AccountDropdown_Divider" />;
}
