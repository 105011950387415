import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SupportedRegion } from "../../../data-store";
import UserVisibleError from "../../../util/UserVisibleError";
import Form from "../../utils/Form";
import useId from "../../utils/useId";
import { SupportedRegionSelect } from "./SupportedRegionSelect";

interface RegionSelectionFormProps {
  onComplete: (selectedRegion: SupportedRegion) => void;
}

export default function RegionSelectionForm(props: RegionSelectionFormProps) {
  const { onComplete } = props;

  const intl = useIntl();
  const [selectedRegion, setSelectedRegion] = useState<SupportedRegion | null>(
    null
  );
  const selectId = useId();

  async function handleSubmit() {
    if (selectedRegion === null) {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/account/sign-up/RegionSelectionForm:countryNotSelected",
          defaultMessage: "Country not selected",
        })
      );
    }

    onComplete(selectedRegion);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <label htmlFor={selectId} className="medium-font">
        <FormattedMessage
          id="components/account/sign-up/RegionSelectionForm:question"
          defaultMessage="In which country is most of your food consumed?"
        />
      </label>
      <SupportedRegionSelect
        id={selectId}
        onChange={setSelectedRegion}
        value={selectedRegion}
      />

      <div className="mt-4 mb-3">
        <Form.SubmitButton
          fullWidth
          submitLabel={
            <FormattedMessage
              id="components/account/sign-up/RegionSelectionForm:nextButton/submitLabel"
              defaultMessage="Next"
            />
          }
          disabled={selectedRegion === null}
        />
      </div>

      <Form.ErrorAlert />
    </Form>
  );
}
