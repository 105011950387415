import VectorProps from "./VectorProps";

export default function SignOut(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Q85QW9IPew7lWUUb-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1632_1496)">
        <path d="M10.36 19.91H3.20005C1.46005 19.91 0.0500488 18.5 0.0500488 16.76V3.23C0.0500488 1.5 1.47005 0.0899963 3.20005 0.0899963H10.36C12.1 0.0899963 13.51 1.5 13.51 3.24V16.77C13.51 18.51 12.1 19.92 10.36 19.92V19.91ZM3.20005 1.69C2.35005 1.69 1.65005 2.38 1.65005 3.24V16.77C1.65005 17.62 2.34005 18.32 3.20005 18.32H10.36C11.21 18.32 11.91 17.63 11.91 16.77V3.23C11.91 2.38 11.22 1.68 10.36 1.68H3.20005V1.69Z" />
        <path d="M16.16 17.06C15.96 17.06 15.75 16.98 15.59 16.83C15.28 16.52 15.28 16.01 15.59 15.7L18.2901 13L15.59 10.3C15.28 9.99 15.28 9.48 15.59 9.17C15.9 8.86 16.41 8.86 16.72 9.17L19.51 11.96C19.7901 12.24 19.9401 12.61 19.9401 13.01C19.9401 13.41 19.7901 13.78 19.51 14.06L16.72 16.85C16.56 17.01 16.36 17.08 16.15 17.08L16.16 17.06Z" />
        <path d="M12.57 14.96C11.7 14.96 10.82 14.86 9.95005 14.62C9.17005 14.41 8.48005 14.09 7.90005 13.69C6.60005 14.24 4.94005 14.16 3.89005 13.07C3.48005 12.64 3.07005 12 2.86005 11.47C2.47005 10.48 2.39005 9.58 2.30005 8.61C2.26005 8.17 2.58005 7.76 3.02005 7.72C3.46005 7.67 3.85005 7.98 3.89005 8.42V8.46C3.98005 9.38 4.04005 10.1 4.35005 10.88C4.48005 11.22 4.78005 11.68 5.05005 11.96C5.43005 12.36 6.03005 12.48 6.61005 12.4C6.42005 12.11 6.26005 11.81 6.15005 11.49C5.51005 9.68 6.10005 7.45 7.06005 6.49C8.08005 5.47 9.41005 5.76 10.23 6.45C11.01 7.11 11.28 8.36 10.96 9.87C10.67 11.22 10.09 11.97 9.35005 12.68C9.67005 12.84 10.01 12.97 10.39 13.08C13.02 13.8 15.88 12.99 18.08 12.21C18.5 12.06 18.95 12.28 19.1 12.69C19.25 13.11 19.03 13.56 18.62 13.71C16.89 14.33 14.78 14.95 12.59 14.95L12.57 14.96ZM8.62005 7.43C8.48005 7.43 8.33005 7.48 8.18005 7.63C7.60005 8.21 7.24005 9.82 7.65005 10.96C7.74005 11.21 7.88005 11.46 8.06005 11.68C8.79005 11.02 9.17005 10.54 9.38005 9.54C9.62005 8.4 9.38005 7.83 9.18005 7.67C9.07005 7.58 8.86005 7.43 8.61005 7.43H8.62005Z" />
      </g>
      <defs>
        <clipPath id="clip0_1632_1496">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
