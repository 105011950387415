import { gql } from "graphql-tag";

import { RecipePackagingComponentV2Input } from "../../../__generated__/globalTypes";
import ReadResult, { combineReadResultsArray } from "../../utils/ReadResult";
import TableEditor from "../../utils/TableEditor";
import * as PackagingComponentEditor from "./PackagingComponentEditor";
import {
  PackagingComponentsEditor_PackagingComponent as PackagingComponent,
  PackagingComponentsEditor_Recipe as Recipe,
} from "./PackagingComponentsEditor.graphql";
import "./PackagingComponentsEditor.css";

export type Value = Array<PackagingComponentEditor.Value>;

export function initialValue(recipe: Recipe | undefined): Value {
  if (recipe === undefined || recipe.packagingComponentsV2.length === 0) {
    return [];
  } else {
    return recipe.packagingComponentsV2.map((packagingComponent) =>
      PackagingComponentEditor.existingValue(packagingComponent)
    );
  }
}

export function read(
  value: Value
): ReadResult<Value, Array<RecipePackagingComponentV2Input>> {
  return combineReadResultsArray(value.map(PackagingComponentEditor.read));
}

interface PackagingComponentsEditorProps {
  onChange: (value: Value) => void;
  packagingComponents: Array<PackagingComponent>;
  value: Value;
}

export function PackagingComponentsEditor(
  props: PackagingComponentsEditorProps
) {
  const { onChange, value, packagingComponents } = props;

  return (
    <div className="PackagingComponentsEditor_Container">
      <PackagingComponentEditor.PackagingComponentEditorHeader />
      <TableEditor
        blank={PackagingComponentEditor.blankValue}
        value={value}
        onChange={onChange}
        renderRow={({
          onChange,
          onDelete,
          rowIndex,
          value: packagingComponentValue,
        }) => (
          <PackagingComponentEditor.PackagingComponentEditor
            value={packagingComponentValue}
            onChange={(value) => onChange({ ...value })}
            onDelete={onDelete}
            packagingComponents={packagingComponents}
          />
        )}
        showAddButton={true}
      />
    </div>
  );
}

export const fragments = {
  packagingComponent: gql`
    fragment PackagingComponentsEditor_PackagingComponent on PackagingComponentV2 {
      ...PackagingComponentEditor_PackagingComponent
    }

    ${PackagingComponentEditor.fragments.packagingComponent}
  `,
  recipe: gql`
    fragment PackagingComponentsEditor_Recipe on Recipe {
      packagingComponentsV2 {
        ...PackagingComponentEditor_RecipePackagingComponent
      }
    }

    ${PackagingComponentEditor.fragments.recipePackagingComponent}
  `,
};
