import debounce from "lodash/debounce";
import React, { useRef, useState } from "react";
import { useCallbackOne, useMemoOne } from "use-memo-one";

import useLazyRef from "./useLazyRef";

interface FitToSpaceProps {
  children: (value: Dimensions) => React.ReactNode;
}

interface Dimensions {
  width: number;
  height: number;
}

export default function FitToSpace(props: FitToSpaceProps) {
  const { children } = props;

  const resizeObserver = useLazyRef<ResizeObserver>(
    () => new ResizeObserver(() => updateDimensions())
  );
  const containerNodeRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  const updateDimensions = useMemoOne(
    () =>
      debounce(
        () => {
          const node = containerNodeRef.current;
          if (node !== null) {
            setDimensions({
              width: node.offsetWidth,
              height: node.offsetHeight,
            });
          }
        },
        500,
        { leading: true, trailing: true }
      ),
    []
  );

  const containerRef = useCallbackOne(
    (node: HTMLDivElement | null) => {
      if (node === containerNodeRef.current) {
        return;
      }

      if (containerNodeRef.current !== null) {
        resizeObserver.unobserve(containerNodeRef.current);
      }

      if (node !== null) {
        resizeObserver.observe(node);
      }
      containerNodeRef.current = node;
      updateDimensions();
    },
    [resizeObserver, updateDimensions]
  );

  return (
    <div className="h-100 position-relative" ref={containerRef}>
      <div className="position-absolute">
        {dimensions !== null && children(dimensions)}
      </div>
    </div>
  );
}
