import VectorProps from "./VectorProps";

export default function Chevron(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=72f2wmhXB9zB9ud7-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.316587 3.34775C0.752587 2.90175 1.35959 2.86675 1.89259 3.34775L5.80059 7.09475L9.70859 3.34775C10.2416 2.86675 10.8496 2.90175 11.2826 3.34775C11.7186 3.79275 11.6906 4.54475 11.2826 4.96275C10.8766 5.38075 6.58759 9.46475 6.58759 9.46475C6.37059 9.68775 6.08559 9.79975 5.80059 9.79975C5.51559 9.79975 5.23059 9.68775 5.01159 9.46475C5.01159 9.46475 0.724587 5.38075 0.316587 4.96275C-0.091413 4.54475 -0.119413 3.79275 0.316587 3.34775Z"
        fill={fill}
      />
    </svg>
  );
}
