import { CsvData, Pencil, Plus, Share } from "../utils/Vectors";
import RecipesDropdown from "./RecipesDropdown";

const stories = {
  name: "RecipesDropdown",
  children: [
    {
      name: "default",
      render: () => (
        <RecipesDropdown
          Icon={Plus}
          items={[
            {
              action: { type: "link", to: "bla" },
              Icon: CsvData,
              render: "Link button",
            },
            {
              action: { type: "button", onClick: () => alert("Clicked") },
              Icon: Share,
              render: "Regular button",
            },
            {
              action: { type: "button", onClick: () => alert("Clicked") },
              Icon: Pencil,
              render: "Another button",
            },
          ]}
          title="Add some stuff"
          variant="primary"
        />
      ),
    },
    {
      name: "disabled",
      render: () => (
        <RecipesDropdown
          disabled
          Icon={Plus}
          items={[]}
          title="Add some stuff"
          variant="primary"
        />
      ),
    },
  ],
};

export default stories;
