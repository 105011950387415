import VectorProps from "../VectorProps";

export default function Waste(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    width,
  } = props;
  // from https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1501-1661&mode=design&t=Ksp5K23jLgLje03Y-0
  return (
    <svg
      className={className}
      enableBackground="new 0 0 32 32"
      fill={fill}
      onClick={handleClick}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_2035)">
        <path
          d="M20.6399 21.28C26.4279 21.28 31.1199 16.588 31.1199 10.8C31.1199 5.01206 26.4279 0.320007 20.6399 0.320007C14.852 0.320007 10.1599 5.01206 10.1599 10.8C10.1599 16.588 14.852 21.28 20.6399 21.28Z"
          fill="#67DCC2"
        />
        <path
          d="M21.0742 3.23177L16.0065 2.35825C14.8805 2.16417 13.8104 2.91961 13.6164 4.04557L11.6812 15.272C11.4872 16.398 12.2426 17.4681 13.3686 17.6622L18.4362 18.5357C19.5622 18.7298 20.6323 17.9743 20.8264 16.8484L22.7615 5.62192C22.9556 4.49596 22.2001 3.42585 21.0742 3.23177Z"
          fill="#10675B"
        />
        <path
          d="M18.7921 19.448C18.6241 19.448 18.4545 19.4336 18.2849 19.4048L13.2177 18.5312C12.4417 18.3968 11.7633 17.9696 11.3105 17.3264C10.8561 16.6832 10.6801 15.9008 10.8145 15.1248L12.7505 3.89919C13.0273 2.29759 14.5537 1.21919 16.1569 1.49439L21.2241 2.36799C22.8257 2.64479 23.9057 4.17279 23.6289 5.77439L21.6929 17C21.5585 17.776 21.1313 18.4544 20.4881 18.9088C19.9857 19.264 19.3969 19.4496 18.7937 19.4496L18.7921 19.448ZM15.6529 3.20959C15.0849 3.20959 14.5809 3.61919 14.4817 4.19679L12.5457 15.4224C12.4913 15.736 12.5633 16.0512 12.7457 16.3104C12.9281 16.5696 13.2017 16.7424 13.5153 16.7968L18.5825 17.6704C18.8961 17.7248 19.2113 17.6528 19.4705 17.4704C19.7297 17.288 19.9025 17.0144 19.9569 16.7008L21.8929 5.47519C22.0049 4.82879 21.5697 4.21279 20.9233 4.10239L15.8561 3.22879C15.7889 3.21759 15.7201 3.21119 15.6529 3.21119V3.20959Z"
          fill="black"
        />
        <path
          d="M11.415 1.65702L7.19252 3.32713C5.97556 3.80847 5.37921 5.18521 5.86055 6.40217L7.58951 10.7735C8.07084 11.9904 9.44759 12.5868 10.6645 12.1054L14.8871 10.4353C16.104 9.95399 16.7004 8.57725 16.219 7.36029L14.4901 2.98899C14.0087 1.77203 12.632 1.17569 11.415 1.65702Z"
          fill="#DCFFD1"
        />
        <path
          d="M9.79674 13.1504C8.50234 13.1504 7.27674 12.3712 6.77114 11.096L5.04154 6.7232C4.72154 5.9168 4.73594 5.0336 5.08154 4.2368C5.42714 3.44 6.06074 2.8256 6.86714 2.5072L11.0911 0.8368C12.7567 0.1776 14.6479 0.996799 15.3071 2.6624L17.0367 7.0352C17.3567 7.8416 17.3423 8.7248 16.9967 9.5216C16.6511 10.3184 16.0175 10.9328 15.2111 11.2512L10.9871 12.9216C10.5967 13.0768 10.1935 13.1488 9.79514 13.1488L9.79674 13.1504ZM12.2879 2.368C12.1023 2.368 11.9167 2.4032 11.7391 2.4736L7.51514 4.144C7.14554 4.2896 6.85434 4.5712 6.69594 4.9376C6.53754 5.3024 6.53114 5.7072 6.67834 6.0768L8.40794 10.4496C8.55514 10.8192 8.83514 11.1104 9.20154 11.2688C9.56634 11.4272 9.97114 11.4336 10.3407 11.2864L14.5647 9.616C14.9343 9.4704 15.2255 9.1888 15.3839 8.8224C15.5423 8.4576 15.5487 8.0528 15.4015 7.6832L13.6719 3.3104C13.5247 2.9408 13.2447 2.6496 12.8783 2.4912C12.6879 2.4096 12.4879 2.368 12.2863 2.368H12.2879Z"
          fill="black"
        />
        <path
          d="M19.8478 30.8H6.67184C4.98704 30.8 3.62064 29.4336 3.62064 27.7488L2.01904 7.42239H24.499L22.899 27.7488C22.899 29.4336 21.5326 30.8 19.8478 30.8Z"
          fill="#00A68F"
        />
        <path
          d="M19.848 31.68H6.67196C4.51676 31.68 2.75996 29.936 2.74076 27.7856L1.14236 7.4912C1.12316 7.2464 1.20636 7.0048 1.37276 6.824C1.53916 6.6432 1.77436 6.5408 2.01916 6.5408H24.4992C24.744 6.5408 24.9792 6.6432 25.1456 6.824C25.312 7.0048 25.3952 7.2464 25.376 7.4912L23.7792 27.7856C23.76 29.936 22.0032 31.68 19.848 31.68ZM2.97276 8.3024L4.49756 27.68C4.49916 27.7024 4.50076 27.7264 4.50076 27.7488C4.50076 28.9456 5.47516 29.92 6.67196 29.92H19.8496C21.0464 29.92 22.0208 28.9456 22.0208 27.7488C22.0208 27.7264 22.0208 27.7024 22.024 27.68L23.5488 8.3024H2.97276Z"
          fill="black"
        />
        <path
          d="M13.2609 31.2432C12.7745 31.2432 12.3809 30.8496 12.3809 30.3632V11.84C12.3809 11.3536 12.7745 10.96 13.2609 10.96C13.7473 10.96 14.1409 11.3536 14.1409 11.84V30.3632C14.1409 30.8496 13.7473 31.2432 13.2609 31.2432Z"
          fill="black"
        />
        <path
          d="M16.3743 31.2432C16.3583 31.2432 16.3407 31.2432 16.3247 31.2432C15.8399 31.216 15.4671 30.8016 15.4943 30.3168L16.5199 11.7936C16.5471 11.3088 16.9615 10.936 17.4463 10.9632C17.9311 10.9904 18.3039 11.4048 18.2767 11.8896L17.2511 30.4128C17.2255 30.8816 16.8367 31.2448 16.3727 31.2448L16.3743 31.2432Z"
          fill="black"
        />
        <path
          d="M19.472 31.2C19.448 31.2 19.424 31.2 19.4 31.1968C18.9152 31.1568 18.5552 30.7328 18.5936 30.248L20.0944 11.768C20.1344 11.2832 20.5584 10.9232 21.0432 10.9616C21.528 11.0016 21.888 11.4256 21.8496 11.9104L20.3488 30.3904C20.312 30.8512 19.9264 31.1984 19.472 31.1984V31.2Z"
          fill="black"
        />
        <path
          d="M10.2352 31.2432C9.77124 31.2432 9.38404 30.88 9.35684 30.4112L8.33124 11.888C8.30404 11.4032 8.67524 10.9872 9.16164 10.9616C9.64484 10.9312 10.0624 11.3056 10.088 11.792L11.1136 30.3152C11.1408 30.8 10.7696 31.216 10.2832 31.2416C10.2672 31.2416 10.2496 31.2416 10.2336 31.2416L10.2352 31.2432Z"
          fill="black"
        />
        <path
          d="M7.03516 31.2C6.58076 31.2 6.19676 30.8512 6.15836 30.392L4.65756 11.912C4.61756 11.4272 4.97916 11.0032 5.46396 10.9632C5.94716 10.9248 6.37276 11.2848 6.41276 11.7696L7.91356 30.2496C7.95356 30.7344 7.59196 31.1584 7.10716 31.1984C7.08316 31.2 7.05916 31.2016 7.03516 31.2016V31.2Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2079_2035">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
