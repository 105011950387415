import { FormattedMessage, useIntl } from "react-intl";

import { PackagingComponentV2 } from "../../../__generated__/globalTypes";
import ReadResult from "../../utils/ReadResult";
import * as TextField from "../../utils/TextField";
import useId from "../../utils/useId";

export const label = <Label />;

function Label() {
  return (
    <FormattedMessage
      id="components/packaging/PackagingComponentEditor/PackagingComponentNameEditor:label"
      defaultMessage="Title"
    />
  );
}

export interface Value {
  name: TextField.Value;
}

export interface Input {
  name: string;
}

export function initialValue(packagingComponent?: PackagingComponentV2): Value {
  if (packagingComponent === undefined) {
    return {
      name: TextField.initialValue(""),
    };
  } else {
    return {
      name: TextField.initialValue(packagingComponent.name),
    };
  }
}

export function read(value: Value): ReadResult<Value, Input> {
  const nameReadResult = TextField.read(value.name);

  const newValue = { ...value, name: nameReadResult.value };

  if (nameReadResult.hasError) {
    return { hasError: true, value: newValue };
  } else {
    return {
      hasError: false,
      value: newValue,
      input: {
        name: nameReadResult.input,
      },
    };
  }
}

interface PackagingComponentNameEditorProps {
  onChange: (value: Value) => void;
  value: Value;
}

export function PackagingComponentNameEditor(
  props: PackagingComponentNameEditorProps
) {
  const { onChange, value } = props;

  const intl = useIntl();
  const nameInputId = useId();

  const placeholder = intl.formatMessage({
    id: "components/packaging/PackagingComponentEditor/PackagingComponentNameEditor:placeholder",
    defaultMessage: "Give your packaging component a name",
  });

  return (
    <>
      <div className="form-group" style={{ width: "70%" }}>
        <label className="h5" htmlFor={nameInputId}>
          {label}
        </label>
        <TextField.TextField
          autoComplete="off"
          id={nameInputId}
          onChange={(name) => onChange({ ...value, name })}
          placeholder={placeholder}
          value={value.name}
        />
      </div>
    </>
  );
}
