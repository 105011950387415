import classNames from "classnames";

import Chevron from "./Vectors/Chevron";
import "./RotatingChevron.css";

interface RotatingChevronProps {
  className?: string;
  expanded: boolean;
}

export default function RotatingChevron(props: RotatingChevronProps) {
  const { className, expanded } = props;
  return (
    <Chevron
      className={classNames("RotatingChevron", className, {
        RotatingChevron_expanded: expanded,
      })}
      width={12}
    />
  );
}
