import { useIntl } from "react-intl";

import EffectType from "../../domain/EffectType";
import { useAdditionalImpactCategories } from "../../services/useOrganizationFeatures";
import InfoCard from "./InfoCard";

interface InformationBannerProps {
  effectType: EffectType;
  meanImpactPerUnit: string;
  numberOfRecipes: number;
  veryLowScores: number;
}

export default function InformationBanner(props: InformationBannerProps) {
  const { effectType, meanImpactPerUnit, veryLowScores, numberOfRecipes } =
    props;

  const intl = useIntl();
  const additionalImpactCategories = useAdditionalImpactCategories();
  return (
    <div className="row mb-4">
      <div className="col">
        <InfoCard
          title={intl.formatMessage(
            {
              id: "components/dashboard/InformationBanner:averageLabel",
              defaultMessage: "Average {effectType}",
            },
            { effectType: effectType.title(intl) }
          )}
        >
          <h5 className="font-weight-medium my-auto pr-2">
            {meanImpactPerUnit}
          </h5>
          <h6 className="font-weight-normal my-auto pt-1 pr-3">
            {effectType.unit}
          </h6>
        </InfoCard>
      </div>
      <div className="col">
        {!additionalImpactCategories && (
          <InfoCard
            title={intl.formatMessage({
              id: "components/dashboard/InformationBanner:proportionOfVeryLowScoresLabel",
              defaultMessage: "Proportion of 'Very Low' Scores",
            })}
          >
            <h5 className="font-weight-normal my-auto ml-auto pr-3">
              {veryLowScores}/{numberOfRecipes}
            </h5>
          </InfoCard>
        )}
      </div>
    </div>
  );
}
