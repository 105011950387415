import SummaryCard from "./SummaryCard";

const stories = {
  name: "LightCard",
  render: () => (
    <div className="w-25 m-3">
      <SummaryCard>
        <h1>Title</h1>
        <div>Body with some text and other words.</div>
      </SummaryCard>
    </div>
  ),
};

export default stories;
