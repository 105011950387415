import { useState } from "react";

import { PrimaryButton } from "../utils/Button";
import AddTagModal from "./AddTagModal";

const stories = {
  name: "AddTagModal",
  render: () => <Story />,
};

function Story() {
  const [showModal, setShowModal] = useState<boolean>(true);
  return (
    <>
      <PrimaryButton onClick={() => setShowModal(true)}>
        Show modal
      </PrimaryButton>
      <AddTagModal
        addTag={(options) =>
          Promise.resolve({
            addRecipeCollection: {
              success: true,
              recipeCollection: {
                id: Math.floor(Math.random() * 10000),
                name: options.variables.input.name,
              },
            },
          })
        }
        show={showModal}
        onCancel={() => setShowModal(false)}
        onComplete={(newTagId) => alert(`Created new tag with ID ${newTagId}`)}
      />
    </>
  );
}

export default stories;
