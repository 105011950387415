import { Button } from "./Button";
import TooltipOverlay from "./TooltipOverlay";
import { Flame } from "./Vectors";

const stories = {
  name: "TooltipOverlay",
  children: [
    {
      name: "Icon",
      render: () => (
        <TooltipOverlay overlay="Flame on!" id="tooltipId">
          <Flame width={16} />
        </TooltipOverlay>
      ),
    },
    {
      name: "Button",
      render: () => (
        <TooltipOverlay overlay="baby one more time" id="tooltipId">
          <Button variant="primary">Hit me</Button>
        </TooltipOverlay>
      ),
    },
  ],
};

export default stories;
