import React from "react";
import {
  FormattedNumber,
  FormatNumberOptions,
  useIntl,
  FormattedMessage,
} from "react-intl";

import {
  BillingInterval,
  Currency as GraphqlCurrency,
} from "../__generated__/globalTypes";
import assertNever from "./assertNever";

export function penceToPounds(priceInPence: number) {
  return priceInPence / 100;
}

// This had to be typed using Pick to satisfy type-checker
// that the splat into FormattedNumber is valid.
export const defaultCurrencyFormattingOptions: Pick<
  FormatNumberOptions,
  "style" | "currencyDisplay"
> = {
  style: "currency",
  currencyDisplay: "narrowSymbol",
};

interface CurrencyProps {
  currency: GraphqlCurrency;
  hasAsterisk?: boolean;
  maximumFractionDigits?: number;
  pence: number;
}

export function Currency(props: CurrencyProps) {
  const { currency, hasAsterisk = false, maximumFractionDigits, pence } = props;

  return (
    <>
      <FormattedNumber
        currency={currency}
        {...defaultCurrencyFormattingOptions}
        value={penceToPounds(pence)}
        maximumFractionDigits={maximumFractionDigits}
      />
      {hasAsterisk ? (
        <FormattedMessage
          id="util/currencyFormatting:currencyAsterisk"
          defaultMessage="*"
        />
      ) : null}
    </>
  );
}

type PricePerBillingIntervalProps = {
  billingInterval: BillingInterval;
  currency: GraphqlCurrency;
} & CurrencyProps;

export function PricePerBillingInterval(props: PricePerBillingIntervalProps) {
  const {
    billingInterval,
    currency,
    hasAsterisk,
    pence,
    maximumFractionDigits = 0,
  } = props;

  return (
    <FormattedMessage
      id="util/currencyFormatting:pricePerMonth"
      defaultMessage="{formattedPrice} <small>/ {billingInterval}</small>"
      values={{
        billingInterval: (
          <BillingIntervalDisplay billingInterval={billingInterval} />
        ),
        formattedPrice: (
          <span>
            <Currency
              currency={currency}
              hasAsterisk={hasAsterisk}
              maximumFractionDigits={maximumFractionDigits}
              pence={pence}
            />
          </span>
        ),
        small: (children: React.ReactNode) => <small>{children}</small>,
      }}
    />
  );
}

type CurrencyFormatOptions = Omit<CurrencyProps, "pence">;

export function useFormatCurrency(): (
  currency: GraphqlCurrency,
  pence: number,
  options?: CurrencyFormatOptions
) => string {
  const intl = useIntl();

  return (
    currency: GraphqlCurrency,
    pence: number,
    options?: CurrencyFormatOptions
  ) =>
    intl.formatNumber(penceToPounds(pence), {
      currency,
      ...defaultCurrencyFormattingOptions,
      ...options,
    });
}

export function useFormatPricePerBillingInterval(): (
  billingInterval: BillingInterval,
  currency: GraphqlCurrency,
  pence: number,
  options?: CurrencyFormatOptions
) => string {
  const formatCurrency = useFormatCurrency();
  const intl = useIntl();
  const billingIntervalToString = useBillingIntervalToString();

  return (
    billingInterval: BillingInterval,
    currency: GraphqlCurrency,
    pence: number,
    options?: CurrencyFormatOptions
  ) =>
    intl.formatMessage(
      {
        id: "util/currencyFormatting:useFormatPricePerBillingInterval",
        defaultMessage: "{formattedPrice}/{billingInterval}",
      },
      {
        billingInterval: billingIntervalToString({
          billingInterval,
          type: "noun",
        }),
        formattedPrice: formatCurrency(currency, pence, options),
      }
    );
}

export function useBillingIntervalToString(): ({
  billingInterval,
  type = "noun",
}: {
  billingInterval: BillingInterval;
  type?: "adjective" | "noun" | "pluralNoun";
}) => string {
  const intl = useIntl();

  function billingIntervalToString({
    billingInterval,
    type = "noun",
  }: {
    billingInterval: BillingInterval;
    type?: "adjective" | "noun" | "pluralNoun";
  }): string {
    if (billingInterval === BillingInterval.MONTH) {
      if (type === "adjective") {
        return intl.formatMessage({
          id: monthDisplay.monthly.id,
          defaultMessage: monthDisplay.monthly.defaultMessage,
        });
      } else if (type === "noun") {
        return intl.formatMessage({
          id: monthDisplay.month.id,
          defaultMessage: monthDisplay.month.defaultMessage,
        });
      } else if (type === "pluralNoun") {
        return intl.formatMessage({
          id: monthDisplay.months.id,
          defaultMessage: monthDisplay.months.defaultMessage,
        });
      } else {
        assertNever(type, "unknown month display type");
      }
    } else if (billingInterval === BillingInterval.YEAR) {
      if (type === "adjective") {
        return intl.formatMessage({
          id: yearDisplay.yearly.id,
          defaultMessage: yearDisplay.yearly.defaultMessage,
        });
      } else if (type === "noun") {
        return intl.formatMessage({
          id: yearDisplay.year.id,
          defaultMessage: yearDisplay.year.defaultMessage,
        });
      } else if (type === "pluralNoun") {
        return intl.formatMessage({
          id: yearDisplay.years.id,
          defaultMessage: yearDisplay.years.defaultMessage,
        });
      } else {
        assertNever(type, "unknown month display type");
      }
    } else {
      assertNever(billingInterval, "unknown billing interval");
    }
  }

  return billingIntervalToString;
}

interface BillingIntervalDisplayProps {
  billingInterval: BillingInterval;
  type?: "adjective" | "noun" | "pluralNoun";
}

export function BillingIntervalDisplay(props: BillingIntervalDisplayProps) {
  const { billingInterval, type = "noun" } = props;

  if (billingInterval === BillingInterval.MONTH) {
    return <Month type={type} />;
  } else if (billingInterval === BillingInterval.YEAR) {
    return <Year type={type} />;
  } else {
    assertNever(billingInterval, "unexpected billing interval");
  }
}

export function Month({
  type,
}: Required<Pick<BillingIntervalDisplayProps, "type">>) {
  if (type === "adjective") {
    return (
      <FormattedMessage
        id={monthDisplay.monthly.id}
        defaultMessage={monthDisplay.monthly.defaultMessage}
      />
    );
  } else if (type === "noun") {
    return (
      <FormattedMessage
        id={monthDisplay.month.id}
        defaultMessage={monthDisplay.month.defaultMessage}
      />
    );
  } else if (type === "pluralNoun") {
    return (
      <FormattedMessage
        id={monthDisplay.months.id}
        defaultMessage={monthDisplay.months.defaultMessage}
      />
    );
  } else {
    assertNever(type, "unknown month display type");
  }
}

export function Year({
  type,
}: Required<Pick<BillingIntervalDisplayProps, "type">>) {
  if (type === "adjective") {
    return (
      <FormattedMessage
        id={yearDisplay.yearly.id}
        defaultMessage={yearDisplay.yearly.defaultMessage}
      />
    );
  } else if (type === "noun") {
    return (
      <FormattedMessage
        id={yearDisplay.year.id}
        defaultMessage={yearDisplay.year.defaultMessage}
      />
    );
  } else if (type === "pluralNoun") {
    return (
      <FormattedMessage
        id={yearDisplay.years.id}
        defaultMessage={yearDisplay.years.defaultMessage}
      />
    );
  } else {
    assertNever(type, "unknown year display type");
  }
}

const monthDisplay = {
  month: {
    id: "util/currencyFormatting:month",
    defaultMessage: "month",
  },
  months: { id: "util/currencyFormatting:months", defaultMessage: "months" },
  monthly: { id: "util/currencyFormatting:monthly", defaultMessage: "monthly" },
};

const yearDisplay = {
  year: { id: "util/currencyFormatting:year", defaultMessage: "year" },
  years: { id: "util/currencyFormatting:years", defaultMessage: "years" },
  yearly: { id: "util/currencyFormatting:yearly", defaultMessage: "yearly" },
};
